import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
export const RFMChartDropdown = ({ activeItem, onChangeActiveItem, shouldDisplayProfit, }) => {
    var _a;
    const { t } = useTranslation();
    const items = [
        { id: 'clientsCount', label: t('RFM_CHART_CLIENTS') },
        { id: 'purchasesCount', label: t('RFM_CHART_PURCHASES') },
        shouldDisplayProfit
            ? { id: 'profit', label: t('RFM_CHART_PROFIT') }
            : { id: 'revenue', label: t('RFM_CHART_REVENUE') },
        { id: 'arppu', label: t('RFM_CHART_ARPPU') },
        { id: 'avgSale', label: t('RFM_CHART_AVG_SALE') },
    ].map((item) => (Object.assign(Object.assign({}, item), { onClick: () => onChangeActiveItem === null || onChangeActiveItem === void 0 ? void 0 : onChangeActiveItem(item.id) })));
    const dropdownTitle = (_a = items.find((i) => i.id === activeItem)) === null || _a === void 0 ? void 0 : _a.label;
    return (_jsx(Dropdown, { items: items, title: dropdownTitle, placement: 'bottom-start', size: 'medium' }));
};
