var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { convertFlowToRowTable } from './convertFlowToRowTable';
import { convertMailingToRowTable } from './convertMailingToRowTable';
export const convertFlowsToTable = (options) => {
    const { flows, actions } = options, otherOptions = __rest(options, ["flows", "actions"]);
    const tableBodies = {};
    flows.forEach((flow) => {
        const flowBody = `flow-${flow.id}`;
        const flowMailingsBody = `flow-${flow.id}-mailings`;
        tableBodies[flowBody] = {
            rows: [],
            childGroupId: flow.mailings.length ? flowMailingsBody : undefined,
        };
        tableBodies[flowMailingsBody] = { type: 'sub-group', rows: [] };
        tableBodies[flowBody].rows.push(convertFlowToRowTable(Object.assign({ flow, actions: actions === null || actions === void 0 ? void 0 : actions(flow) }, otherOptions)));
        if (flow.mailings.length)
            flow.mailings.forEach((mailing) => {
                tableBodies[flowMailingsBody].rows.push(convertMailingToRowTable(Object.assign({ mailing }, otherOptions)));
            });
    });
    return tableBodies;
};
