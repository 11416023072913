import { jsx as _jsx } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import { ChannelEmailAlt } from '@/icons/ChannelEmailAlt';
import { ChannelSMSAlt } from '@/icons/ChannelSMSAlt';
import { ChannelViberAlt } from '@/icons/ChannelViberAlt';
import { ClickHand } from '@/icons/ClickHand';
import { Edit } from '@/icons/Edit';
import { Gears } from '@/icons/Gears';
import { Purchase } from '@/icons/Purchase';
import styles from './styles.module.scss';
export const EVENT_PURCHASE_TYPES = {
    2: { title: 'PURCHASE_RETURN', Icon: _jsx(Purchase, {}) },
    12: { title: 'Создал заказ', Icon: _jsx(Purchase, {}) },
    13: { title: 'Покупка' },
    14: { title: 'FRIEND_PURCHASE', Icon: _jsx(Purchase, {}) },
    15: { title: 'PURCHASE_REVERT', color: 'red', Icon: _jsx(Purchase, {}) },
    21: { title: 'Счётчик предложений увеличен', color: '' },
    22: { title: 'Счётчик предложений истёк', color: '' },
    23: { title: 'Выдан купон на предложение', color: '' },
};
export const EVENT_BONUS_TYPES = {
    0: { title: 'Применено на покупку', color: 'red', Icon: _jsx(BonusMovement, {}) },
    1: {
        title: 'Собрано на покупку',
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
    },
    2: { title: 'Срок действия истек', color: 'red', Icon: _jsx(BonusMovement, {}) },
    3: { title: 'Бонусы отменены', color: 'red', Icon: _jsx(BonusMovement, {}) },
    4: {
        title: 'Начислены бонусы',
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        tooltipTitle: 'ACCRUED_TO_EMPLOYEES',
    },
    5: {
        title: 'Отозваны бонусы',
        color: 'red',
        Icon: _jsx(BonusMovement, {}),
        tooltipTitle: 'WRITTEN_OFF_BY_EMPLOYEE',
    },
    6: {
        title: 'Восстановлены бонусы',
        color: 'gray',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#828282' }),
    },
    7: {
        title: 'Отменены бонусы за отменённую покупку',
        Icon: _jsx(BonusMovement, {}),
        color: 'red',
    },
    8: {
        title: 'Возвращены применённые бонусы за отменённую покупку',
        color: 'gray',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#828282' }),
    },
    9: {
        title: 'Собрано вознаграждение за покупку друга',
        color: 'green',
        Icon: _jsx(BonusMovement, {}),
        tooltipTitle: 'FRIENDS_BONUS',
    },
};
export const EVENT_LOYALTY_TYPES = {
    5: { title: 'LEAVE_SEGMENT', Icon: _jsx(Gears, { fill: '#3A3A3A', size: 20 }) },
    6: {
        title: 'Присоединиться к сегменту',
        Icon: _jsx(Gears, { fill: '#3A3A3A', size: 20 }),
    },
    9: { title: 'LEVEL_UP', Icon: _jsx(Gears, { fill: '#3A3A3A', size: 20 }) },
    17: {
        title: 'CREATED_WALLET_CARD',
        Icon: _jsx(Gears, { fill: '#3A3A3A', size: 20 }),
    },
    18: {
        title: 'DELETED_WALLET_CARD',
        color: 'red',
        Icon: _jsx(Gears, { fill: '#3A3A3A', size: 20 }),
    },
};
export const EVENT_CLIENT_UPDATE_TYPES = {
    0: {
        title: 'Создание',
        tooltipTitle: 'CREATED_BY_EMPLOYEE',
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
    },
    1: {
        title: 'Изменены данные',
        tooltipTitle: 'UPDATED_BY_EMPLOYEE',
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
    },
    2: { title: 'Изменены данные (клиентом)', Icon: _jsx(Edit, { fill: '#3A3A3A' }) },
    3: {
        title: 'Удаление',
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        tooltipTitle: 'DELETED_BY_EMPLOYEE',
        color: 'red',
    },
    4: {
        title: 'Заблокирован',
        tooltipTitle: 'BLOCKED_BY_EMPLOYEE',
        color: 'red',
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
    },
    5: {
        title: 'Разблокирован',
        tooltipTitle: 'UNBLOCKED_BY_EMPLOYEE',
        color: 'red',
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
    },
    6: { title: 'Создание (клиентом)', Icon: _jsx(Edit, { fill: '#3A3A3A' }) },
    7: { title: 'Удаление (клиентом)', Icon: _jsx(Edit, { fill: '#3A3A3A' }) },
};
export const EVENT_MAILING_TYPES = {
    sending_click: { title: 'SENDING_CLICK', Icon: _jsx(ClickHand, {}) },
    sending_email: { title: 'SENDING_EMAIL', Icon: _jsx(ChannelEmailAlt, {}) },
    sending_sms: { title: 'SENDING_SMS', Icon: _jsx(ChannelSMSAlt, {}) },
    sending_viber: { title: 'SENDING_VIBER', Icon: _jsx(ChannelViberAlt, {}) },
};
export const ALL_DATA_TYPES = [
    'purchase',
    'bonus',
    'client_update',
    'loyalty',
    'mailing',
];
export const INIT_ACTIVE_BUTTONS = {
    all: true,
    purchase: false,
    bonus: false,
    loyalty: false,
    client_update: false,
    mailing: false,
};
