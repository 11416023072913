export const customJS = (variableIds) => `
var placeholders = [${variableIds.map((v) => '"' + v + '"').join(',')}];
var editor = document.getElementById('editor');

var removePlaceholderWrappers = function (n) {
    var f = n.getElementsByClassName('cl-ph');
    for (var j = 0; j < f.length; j++) {
        f[j].outerHTML = f[j].innerHTML;
    }
    n.classList.remove("ph-added");
    n.classList.add("ph-removed");
};

var addPlaceholderWrappers = function (n) {
    var r = new RegExp('!(' + placeholders.join('|') + ')(?![^<>]*>)', 'g');
    var found = false;
    var dst = n.innerHTML.replace(r, function (s) {
        found = true;
        return '<span class="cl-ph">' + s + '</span>';
    });
    if (found) {
        n.innerHTML = dst;
    }
    n.classList.remove("ph-removed");
    n.classList.add("ph-added");
};

var callback = function () {
  var toRemove = editor.querySelectorAll(
      '.u_content_button.blockbuilder-inline-editor:not(.ph-removed), ' +
      '.u_content_heading.blockbuilder-inline-editor:not(.ph-removed), ' +
      '.u_content_text.blockbuilder-inline-editor:not(.ph-removed)'
  );
  var toAdd = editor.querySelectorAll(
      '.u_content_button:not(.blockbuilder-inline-editor):not(.ph-added), ' +
      '.u_content_heading:not(.blockbuilder-inline-editor):not(.ph-added), ' +
      '.u_content_text:not(.blockbuilder-inline-editor):not(.ph-added)'
  );
  for (var i = 0; i < toRemove.length; i++) {
      removePlaceholderWrappers(toRemove[i]);
  }
  for (i = 0; i < toAdd.length; i++) {
      addPlaceholderWrappers(toAdd[i]);
  }
};

setInterval(callback, 50);

unlayer.registerTool({
    type: "custom_html",
    category: "contents",
    label: "Html",
    icon: [
        '<svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg" role="img" class="svg-inline--fa fa-code fa-w-18 fa-3x" data-icon="code" data-prefix="fal" aria-hidden="true"><path d="M228.5 511.8l-25-7.1c-3.2-.9-5-4.2-4.1-7.4L340.1 4.4c.9-3.2 4.2-5 7.4-4.1l25 7.1c3.2.9 5 4.2 4.1 7.4L235.9 507.6c-.9 3.2-4.3 5.1-7.4 4.2zm-75.6-125.3l18.5-20.9c1.9-2.1 1.6-5.3-.5-7.1L49.9 256l121-102.5c2.1-1.8 2.4-5 .5-7.1l-18.5-20.9c-1.8-2.1-5-2.3-7.1-.4L1.7 252.3c-2.3 2-2.3 5.5 0 7.5L145.8 387c2.1 1.8 5.3 1.6 7.1-.5zm277.3.4l144.1-127.2c2.3-2 2.3-5.5 0-7.5L430.2 125.1c-2.1-1.8-5.2-1.6-7.1.4l-18.5 20.9c-1.9 2.1-1.6 5.3.5 7.1l121 102.5-121 102.5c-2.1 1.8-2.4 5-.5 7.1l18.5 20.9c1.8 2.1 5 2.3 7.1.4z" fill="currentColor"></path></svg>',
    ],
    values: {
        html: "<strong>Hello, world!</strong>"
    },
    options: {
        html: {
            title: "HTML",
            position: 1,
            options: {
                html: {
                    label: "",
                    defaultValue: "Untitled",
                    widget: "html"
                }
            }
        }
    },
    renderer: {
        Viewer: unlayer.createViewer({
            render: function (values) {
                var div = document.createElement('div');
                div.innerHTML = values.html;
                addPlaceholderWrappers(div);
                return div;
            }
        }),
        exporters: {
            web: function (values) {
                return values.html;
            },
            email: function (values) {
                return values.html;
            }
        }
    }
});
`;
