var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { LogoLarge } from '@/icons/wallet-card/apple/LogoLarge';
import { scaleImage } from '@/utils/scaleImage';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import styles from '../../styles.module.scss';
export const BannerApple = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setAppleCardIsFlipped, markReadyToSave, uploadImage, setIsElementClick, appleBanner, setAppleBanner, setGoogleBanner, } = useWalletCardForm();
    const [applyBothBanner, setApplyBothBanner] = useState(false);
    const handleFocus = () => {
        setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (banner) => {
        setAppleBanner((appleBanner) => (Object.assign(Object.assign({}, appleBanner), banner)));
    };
    const handleChangeGoogleBanner = (image, response) => {
        if (applyBothBanner)
            setGoogleBanner((banner) => (Object.assign(Object.assign({}, banner), { image, imageUploadResponse: response, imageRejectReason: '' })));
        setApplyBothBanner(false);
    };
    const handleApplyImage = (image) => __awaiter(void 0, void 0, void 0, function* () {
        handleChange({ image, imageLoading: !!image, imageRejectReason: '' });
        if (!image) {
            markReadyToSave();
            return;
        }
        try {
            const imageSmall = yield scaleImage(image);
            const [imageUploadResponse, imageSmallUploadResponse] = yield Promise.all([uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(image), uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(imageSmall)]);
            handleChange({ imageUploadResponse, imageSmallUploadResponse });
            handleChangeGoogleBanner(image, imageUploadResponse);
        }
        catch (e) {
            // do nothing
        }
        finally {
            handleChange({ imageLoading: false });
            markReadyToSave();
        }
    });
    return (_jsxs("div", Object.assign({ className: classNames(styles.control, styles.smallGap) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'apple-banner' }, { children: t('Баннер') })), _jsxs("div", Object.assign({ className: styles.uploadWrapper }, { children: [_jsx(ImageCropInput, { id: 'apple-banner', value: appleBanner.image, onChange: handleApplyImage, onReject: (reason) => {
                            handleChange({ image: null, imageRejectReason: reason || '' });
                        }, previewWidth: 280, previewHeight: 106, constraints: {
                            mimeType: 'image/png',
                            extensions: ['.png'],
                            maxSize: 10000000,
                            dimensions: { width: 1125, height: 433 },
                        }, outputMimeType: 'png', description: 'PNG, ' + t('от') + ' ' + t('1125×433, до 10мб'), hasError: !!appleBanner.imageRejectReason, cropModalIcon: _jsx(LogoLarge, {}), cropModalTitle: t('Баннер для Apple'), cropModalFooter: _jsx(Checkbox, Object.assign({ checked: applyBothBanner, onChange: (e) => setApplyBothBanner(e.target.checked), theme: 'dark' }, { children: t('и для Google') })), onFocus: handleFocus }), _jsx(ImageHelp, { dimensions: '1125\u00D7433', rejectReason: appleBanner.imageRejectReason, isLoading: appleBanner.imageLoading })] }))] })));
};
