import { useGetDashboardStatsQuery } from '@/api/query/dashboard';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useDashboardStats = () => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data: stats, isFetching } = useGetDashboardStatsQuery({
        periodStart,
        periodEnd,
    });
    return { stats, isLoading: isFetching };
};
