export const rejectUploadReason = (reason, format = '', dimensions = '', isMinimumDimensions = false) => {
    switch (reason) {
        case 'file-invalid-type': {
            if (format)
                return `Необходим формат ${format}`;
            break;
        }
        case 'file-too-large': {
            return 'Слишком большой файл';
        }
        case 'file-invalid-dimensions': {
            if (dimensions === 'square')
                return 'Только квадратная картинка';
            return dimensions
                ? isMinimumDimensions
                    ? `Размер должен быть не менее ${dimensions}`
                    : `Размер должен быть ${dimensions}`
                : 'Неверный размер';
        }
        default: {
            return 'Неверный файл';
        }
    }
};
