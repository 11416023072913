import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { SegmentFilterInput } from '@/components/SegmentFilterInput/SegmentFilterInput';
import { CHECKBOXES_HAS_SEARCH_THRESHOLD } from './constants';
import { Wrapper } from './Wrapper';
export const CheckboxesSegments = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f;
    const [draft, setDraft] = useState(item);
    useEffect(() => setDraft(Object.assign(Object.assign({}, draft), { options: item.options })), [item.options]);
    useEffect(() => {
        if (!item.options)
            item.loadOptions();
    }, []);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (itemIds) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value: itemIds }) }));
    };
    const handleApply = () => onApply(draft);
    const hasSearch = !!item.options &&
        Object.keys(item.options).length > CHECKBOXES_HAS_SEARCH_THRESHOLD;
    const isValid = !!((_b = (_a = draft.value) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length);
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: ((_c = draft.value) === null || _c === void 0 ? void 0 : _c.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, isLoading: !item.options, count: (_e = (_d = draft.value) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.length }, { children: _jsx(SegmentFilterInput, { segments: item.options || [], includeIds: ((_f = draft.value) === null || _f === void 0 ? void 0 : _f.value) || [], onChangeIncludeIds: handleChangeValue, hasSearch: hasSearch, hasTabs: false }) })));
});
