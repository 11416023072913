var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { getErrorMessage } from '@/api/query';
import { useBlockClientMutation, useUnblockClientMutation, } from '@/api/query/client';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useBlockClient = (client) => {
    const addToast = useToastContext();
    const [blockClient, { isLoading: isBlocking }] = useBlockClientMutation();
    const [unblockClient, { isLoading: isUnblocking }] = useUnblockClientMutation();
    const handleBlockClient = useCallback((comment) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield blockClient({ clientId: client.id, comment });
        if ('error' in result)
            addToast({
                message: getErrorMessage(result.error),
                type: 'error',
            });
    }), [client.id]);
    const handleUnblockClient = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield unblockClient({ clientId: client.id });
        if ('error' in result)
            addToast({
                message: getErrorMessage(result.error),
                type: 'error',
            });
    }), [client.id]);
    return {
        blockClient: handleBlockClient,
        unblockClient: handleUnblockClient,
        isBlocking,
        isUnblocking,
    };
};
