var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './styles.module.scss';
export const InputButton = forwardRef((_a, ref) => {
    var { wrapperClassName, rightElement, isInline, hasError, disabled, className } = _a, props = __rest(_a, ["wrapperClassName", "rightElement", "isInline", "hasError", "disabled", "className"]);
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.error]: hasError,
        }) }, { children: _jsxs("div", Object.assign({ className: classNames(styles.inputWrapper, {
                [styles.disabled]: disabled,
                [styles.inline]: isInline,
                [styles.rightElement]: !!rightElement,
            }, wrapperClassName) }, { children: [_jsx("button", Object.assign({ ref: ref, className: classNames(styles.input, styles.inputButton, className), disabled: disabled }, props)), !!rightElement && _jsx("div", Object.assign({ className: styles.right }, { children: rightElement }))] })) })));
});
