import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { AccentText } from '@/components/AccentText/AccentText';
import { Table } from '@/components/Table/Table';
import { TableSkeleton } from '@/components/TableSkeleton/TableSkeleton';
import { Cancelled } from '@/icons/status/Cancelled';
import { Planned } from '@/icons/status/Planned';
import { getClientFullName } from '@/utils/getClientFullName';
import { money } from '@/utils/numbers';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { usePurchasesPage } from './context/usePurchasesPage';
import styles from './styles.module.scss';
export const OrdersTable = () => {
    const { data, selectedOrder, modals } = usePurchasesPage();
    const handleRowClick = useCallback((row, index) => {
        selectedOrder.setIndex(index);
        modals.orders.detailsModal.open();
    }, []);
    const isLoading = data.count.isFetching || data.orders.isFetching;
    if (data.orders.isLoading)
        return _jsx(TableSkeleton, {});
    return (_jsx(OrdersTableCommon, { orders: data.orders.items, onRowClick: handleRowClick, isLoading: isLoading }));
};
export const OrdersCancelledTable = () => {
    const { data, selectedOrderCancelled, modals } = usePurchasesPage();
    const handleRowClick = useCallback((row, index) => {
        selectedOrderCancelled.setIndex(index);
        modals.ordersCancelled.detailsModal.open();
    }, []);
    const isLoading = data.count.isFetching || data.ordersCancelled.isFetching;
    if (data.ordersCancelled.isLoading)
        return _jsx(TableSkeleton, {});
    return (_jsx(OrdersTableCommon, { orders: data.ordersCancelled.items, onRowClick: handleRowClick, isLoading: isLoading }));
};
const OrdersTableCommon = ({ orders, onRowClick, isLoading, }) => {
    const { t } = useTranslation();
    const { currency } = useCurrentUser();
    const columns = [
        {
            id: 'status',
        },
        {
            id: 'executedAt',
            title: t('Дата заказа'),
        },
        {
            id: 'phoneNumber',
            title: t('Телефон'),
        },
        {
            id: 'clientName',
            title: t('Имя клиента'),
        },
        {
            id: 'shop',
            title: t('Точка продажи'),
            tooltip: t('Место, где был совершен заказ'),
        },
        {
            id: 'paidAmount',
            title: t('Сумма заказа'),
            align: 'right',
        },
        {
            id: 'bonusesApplied',
            title: _jsxs("span", Object.assign({ className: styles.noWrap }, { children: ["\u2013 ", t('Бонусы')] })),
            align: 'right',
        },
        {
            id: 'bonusesCollected',
            title: _jsxs("span", Object.assign({ className: styles.noWrap }, { children: ["+ ", t('Бонусы')] })),
            align: 'right',
        },
    ];
    const rows = orders.map((order) => {
        const bonusesApplied = order.bonusesApplied || 0;
        const bonusesCollected = order.bonusesCollected || 0;
        return {
            id: order.id,
            cells: [
                _jsx("div", Object.assign({ className: styles.status }, { children: order.status === 0 ? _jsx(Planned, {}) : _jsx(Cancelled, {}) }), 'status'),
                moment(order.executedAt).format('DD.MM.YYYY HH:mm'),
                _jsx("span", Object.assign({ className: styles.nowrap }, { children: formatPhoneNumber(order.client.phoneNumber) }), 'phoneNumber'),
                getClientFullName(order.client),
                order.shop.name,
                order.remainingAmount && order.remainingAmount !== '0' ? (money({ input: order.remainingAmount, currency })) : (_jsx("span", Object.assign({ className: styles.noValue }, { children: "\u2013" }), 'remainingAmount')),
                _jsx(AccentText, Object.assign({ accent: bonusesApplied > 0 ? 'danger' : 'default' }, { children: bonusesApplied > 0 ? -bonusesApplied : 0 }), 'bonusesApplied'),
                _jsx(AccentText, Object.assign({ accent: bonusesCollected > 0 ? 'success' : 'default' }, { children: bonusesCollected }), 'bonusesCollected'),
            ],
        };
    });
    return (_jsx(Table, { className: classNames(styles.ordersTable), rows: rows, columns: columns, isLoading: isLoading, onRowClick: onRowClick, hasEmptyState: true }));
};
