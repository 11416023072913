import { useTranslation } from 'react-i18next';
export const useEmptyFormDefaultValues = (rfmSegment) => {
    const { t } = useTranslation();
    let defaultValues;
    if (rfmSegment) {
        const configuration = [
            {
                code: 'clientRfmSegments',
                text: `${t('RFM сегменты')}: ${t(rfmSegment.name)}`,
                rawConditions: {
                    op: 'include',
                    checkboxes: { [rfmSegment.id]: true },
                },
            },
        ];
        defaultValues = { recipientFilters: configuration };
    }
    return defaultValues;
};
