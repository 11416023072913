import { createNode } from '../createNode';
export const createConditionNode = (data) => {
    return createNode({
        type: 'conditions',
        data,
        position: { x: 0, y: 0 },
        style: { opacity: 0 },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateConditionsNodeData = (data, t) => {
    var _a;
    const errors = {};
    if (!((_a = data.configuration) === null || _a === void 0 ? void 0 : _a.length))
        errors.configuration = t('Добавьте хотя бы одно условие');
    return errors;
};
