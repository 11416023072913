import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MailingChannelsTabs } from '@/components/MailingChannelsTabs/MailingChannelsTabs';
import styles from '../styles.module.scss';
export const FormMailingChannelTabs = ({ channelsEnabled, activeStep = 'main', onChangeActiveStep, }) => {
    const { t } = useTranslation();
    const { control, formState, setValue } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channelsError = errors.channels;
    const error = (channelsError === null || channelsError === void 0 ? void 0 : channelsError.message) || '';
    const validate = (value) => !(value === null || value === void 0 ? void 0 : value.length) ? t('Активируйте хотя бы один из каналов') : true;
    return (_jsx("div", Object.assign({ className: styles.channelsTabs }, { children: _jsx(Controller, { name: 'channels', control: control, rules: { validate }, render: ({ field }) => (_jsx(MailingChannelsTabs, { activeChannels: field.value || [], enabledChannels: channelsEnabled || [], onChangeActiveChannels: (channels) => {
                    if (!channels.includes(activeStep))
                        onChangeActiveStep('main');
                    setValue('channels', channels, {
                        shouldValidate: isSubmitted,
                    });
                }, activeTab: activeStep, onTabClick: onChangeActiveStep, error: error })) }) })));
};
