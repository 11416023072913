var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useAutocomplete } from '../context/useAutocomplete';
export const useAutocompleteInput = (type) => {
    const context = useAutocomplete();
    const { getInputProps, getPopupIndicatorProps, asSelect } = context;
    const _a = (getInputProps === null || getInputProps === void 0 ? void 0 : getInputProps()) || {}, { onFocus: handleFocus, onMouseDown: handleMouseDown } = _a, inputProps = __rest(_a, ["onFocus", "onMouseDown"]);
    const buttonProps = inputProps;
    // чтобы клик работал как тоглер
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { onClick } = (getPopupIndicatorProps === null || getPopupIndicatorProps === void 0 ? void 0 : getPopupIndicatorProps()) || {};
        if (asSelect || type === 'button')
            onClick === null || onClick === void 0 ? void 0 : onClick(e);
    };
    return {
        context,
        inputProps,
        buttonProps,
        handleClick,
        handleFocus,
        handleMouseDown,
    };
};
