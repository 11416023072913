import { createContext } from 'react';
const noop = () => undefined;
const noopNull = () => null;
const noopFalse = () => false;
export const InteractiveFlowContext = createContext({
    triggerType: 0,
    triggerLabel: '',
    triggerFilters: [],
    emailReceivedTriggers: [],
    channelReceivedTriggers: [],
    scheduleTriggers: [],
    newStepPopup: {
        selectedNodeId: '',
        selectedHandleId: '',
        selectedEdgeId: '',
        isOpenForEdge: noopFalse,
        isOpenForNode: noopFalse,
        toggleForEdge: noop,
        toggleForNode: noop,
        close: noop,
        reset: noop,
    },
    selectedNode: {
        uuid: '',
        type: '',
        getData: noopNull,
        handleIdx: null,
        selectUUID: noop,
        reset: noop,
        update: noop,
    },
    layout: {
        isLayoutUpdateRequested: false,
        forceLayout: noop,
        resetLayoutRequest: noop,
    },
});
