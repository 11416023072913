import { jsx as _jsx } from "react/jsx-runtime";
import { LinkNode } from '@lexical/link';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { $getNodeByKey } from 'lexical';
export const LinkPlugin = ({ onClickLink }) => {
    return (_jsx(NodeEventPlugin, { nodeType: LinkNode, eventType: 'click', eventListener: (event, editor, nodeKey) => {
            const node = $getNodeByKey(nodeKey);
            if (node)
                onClickLink(node);
        } }));
};
