import { composeSavedFilterText } from './composeSavedFilterText';
export const convertLeafToSavedFilter = (leaf) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    const savedFilter = {
        code: leaf.id,
        text: composeSavedFilterText(leaf),
        rawConditions: {
            op: (leaf.template === 'checkboxes-segments' && leaf.useBothOperations) ||
                leaf.template === 'counter'
                ? 'include'
                : ((_a = leaf.value) === null || _a === void 0 ? void 0 : _a.operator) || 'include',
        },
    };
    if (leaf.code) {
        savedFilter.code = leaf.code;
        savedFilter.id = leaf.id;
    }
    if (leaf.extra)
        savedFilter.rawConditions = Object.assign(Object.assign({}, savedFilter.rawConditions), leaf.extra);
    if (leaf.template === 'radios')
        savedFilter.rawConditions.radios = ((_c = (_b = leaf.value) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.toString()) || '';
    if (leaf.template === 'checkboxes-simple' ||
        leaf.template === 'checkboxes-loader' ||
        leaf.template === 'checkboxes-paginated-loader')
        savedFilter.rawConditions.checkboxes = (_e = (_d = leaf.value) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
    if (leaf.template === 'checkboxes-tree-loader') {
        savedFilter.rawConditions.branchCheckboxes =
            (_h = (_g = (_f = leaf.value) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.branches) === null || _h === void 0 ? void 0 : _h.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        savedFilter.rawConditions.leafCheckboxes =
            (_l = (_k = (_j = leaf.value) === null || _j === void 0 ? void 0 : _j.value) === null || _k === void 0 ? void 0 : _k.leaves) === null || _l === void 0 ? void 0 : _l.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        savedFilter.rawConditions.branchPrefix = leaf.branchPrefix;
        savedFilter.rawConditions.leafPrefix = leaf.leafPrefix;
    }
    if (leaf.template === 'checkboxes-segments' && !leaf.useBothOperations)
        savedFilter.rawConditions.checkboxes = (_o = (_m = leaf.value) === null || _m === void 0 ? void 0 : _m.value) === null || _o === void 0 ? void 0 : _o.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
    if (leaf.template === 'checkboxes-segments' && leaf.useBothOperations) {
        savedFilter.rawConditions.op = 'include';
        savedFilter.rawConditions.checkboxes = (_q = (_p = leaf.value) === null || _p === void 0 ? void 0 : _p.includeValues) === null || _q === void 0 ? void 0 : _q.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        if ((_r = leaf.value) === null || _r === void 0 ? void 0 : _r.excludeValues) {
            savedFilter.rawConditions.not = { op: 'exclude' };
            savedFilter.rawConditions.not.checkboxes =
                (_s = leaf.value) === null || _s === void 0 ? void 0 : _s.excludeValues.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: true })), {});
        }
    }
    if (leaf.template === 'checkboxes-loader' ||
        leaf.template === 'checkboxes-paginated-loader' ||
        leaf.template === 'checkboxes-tree-loader' ||
        (leaf.template === 'checkboxes-segments' && !leaf.useBothOperations))
        savedFilter.rawConditions.searchText = (_t = leaf.value) === null || _t === void 0 ? void 0 : _t.search;
    if (leaf.template === 'numRange') {
        savedFilter.rawConditions.from = (_u = leaf.value) === null || _u === void 0 ? void 0 : _u.min;
        savedFilter.rawConditions.to = (_v = leaf.value) === null || _v === void 0 ? void 0 : _v.max;
        if (savedFilter.code.startsWith('counter_'))
            savedFilter.rawConditions.offerId = (_w = leaf.extra) === null || _w === void 0 ? void 0 : _w.offerId;
    }
    if (leaf.template === 'birthdateDays') {
        savedFilter.rawConditions.before = (_x = leaf.value) === null || _x === void 0 ? void 0 : _x.before;
        savedFilter.rawConditions.after = (_y = leaf.value) === null || _y === void 0 ? void 0 : _y.after;
    }
    if (leaf.template === 'textField')
        savedFilter.rawConditions.textValue = (_z = leaf.value) === null || _z === void 0 ? void 0 : _z.value;
    if (leaf.template === 'date') {
        savedFilter.rawConditions.day = (_0 = leaf.value) === null || _0 === void 0 ? void 0 : _0.day;
        savedFilter.rawConditions.month = (_1 = leaf.value) === null || _1 === void 0 ? void 0 : _1.month;
        savedFilter.rawConditions.year = (_2 = leaf.value) === null || _2 === void 0 ? void 0 : _2.year;
    }
    if (leaf.template === 'dateRange') {
        savedFilter.rawConditions.dateFrom = (_3 = leaf.value) === null || _3 === void 0 ? void 0 : _3.dateFrom;
        savedFilter.rawConditions.dateTo = (_4 = leaf.value) === null || _4 === void 0 ? void 0 : _4.dateTo;
    }
    if (leaf.template === 'counter') {
        savedFilter.rawConditions.name = (_5 = leaf.value) === null || _5 === void 0 ? void 0 : _5.name;
        savedFilter.rawConditions.requiredValue = (_6 = leaf.value) === null || _6 === void 0 ? void 0 : _6.requiredValue;
        savedFilter.rawConditions.applyOn = (_7 = leaf.value) === null || _7 === void 0 ? void 0 : _7.applyOn;
        savedFilter.rawConditions.periodType = (_8 = leaf.value) === null || _8 === void 0 ? void 0 : _8.periodType;
        savedFilter.rawConditions.periodValue = (_9 = leaf.value) === null || _9 === void 0 ? void 0 : _9.periodValue;
        savedFilter.rawConditions.periodUnit = (_10 = leaf.value) === null || _10 === void 0 ? void 0 : _10.periodUnit;
    }
    return savedFilter;
};
