import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { YesNoInput } from '@/components/FilterListInput/YesNoInput';
import { EmailNotClicked } from '@/icons/flow/EmailNotClicked';
import { EmailNotPurchased } from '@/icons/flow/EmailNotPurchased';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { findEdgesFromNode, findNodeByUUID } from '../../nodes/utils';
import { NodeFormDrawer } from '../NodeFormDrawer/NodeFormDrawer';
import commonStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const ChannelReceivedForm = () => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const isOpen = selectedNode.type === 'channel-received';
    const handleUpdate = (conditions) => {
        selectedNode.update({ conditions });
    };
    const handleClose = () => {
        selectedNode.reset();
    };
    let header = '';
    let icon = _jsx(_Fragment, {});
    switch (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) {
        case 'delivered_channel': {
            header = 'Доставлено';
            icon = _jsx(EmailNotClicked, {});
            break;
        }
        case 'purchased_channel': {
            header = 'Сделал покупку';
            icon = _jsx(EmailNotPurchased, {});
            break;
        }
    }
    const thisNode = findNodeByUUID(flow, selectedNode.uuid);
    const edgesFromThisNode = findEdgesFromNode(flow, thisNode);
    const nonDeletableHandles = edgesFromThisNode
        .map((edge) => edge.sourceHandle || null)
        .filter(notNull);
    return (_jsxs(NodeFormDrawer, Object.assign({ icon: _jsx("div", Object.assign({ className: styles.icon }, { children: icon })), title: t(header), open: isOpen, onClose: handleClose, isLarge: true }, { children: [_jsx(YesNoInput, { value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.conditions, onChange: handleUpdate, canDeleteNo: !nonDeletableHandles.some((h) => h.startsWith('no')), canDeleteYes: !nonDeletableHandles.some((h) => h.startsWith('yes')) }), !!(errors === null || errors === void 0 ? void 0 : errors.conditions) && (_jsx("div", Object.assign({ className: classNames(commonStyles.error, commonStyles.mt12) }, { children: errors.conditions })))] })));
};
