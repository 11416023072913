import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { BulletList } from '@/icons/BulletList';
import { notNull } from '@/utils/notNull';
import { money, numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import styles from './styles.module.scss';
export const GiftCardsTable = memo(({ items, total, currency, isProfitVisible, isLoading = false, onCardNameClick, onCardEditClick, onCardExportClick, onCardDeleteClick, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasGiftCardInstancesManage = userHasAccessTo('GIFTCARDS_INSTANCES_MANAGE');
    const userHasFullManage = userHasAccessTo([
        'GIFTCARDS_MANAGE',
        'GIFTCARDS_INSTANCES_MANAGE',
    ]);
    const columns = useMemo(() => [
        {
            id: 'name',
            title: t('Название'),
        },
        {
            id: 'generatedCount',
            title: t('Выпущено'),
            align: 'right',
        },
        {
            id: 'activated',
            title: t('Активировано'),
            align: 'right',
        },
        {
            id: 'used',
            title: t('Погашено'),
            align: 'right',
        },
        {
            id: 'expired',
            title: t('Истекло'),
            align: 'right',
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t('TOOLTIP_ROI_REVENUE'),
            align: 'right',
        },
        {
            id: 'profit-or-revenue',
            title: isProfitVisible ? t('Прибыль') : t('Выручка'),
            align: 'right',
            tooltip: isProfitVisible
                ? t('Валовая прибыль с покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.')
                : t('Сумма покупок за период (исключая возвраты), а также прибыль за счет истекших подарочных карт и сгоревших остатков на подарочных картах.'),
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            align: 'right',
            tooltip: t('Сумма списанных бонусов и затрат на рассылки, в которых распространялась эта карта'),
        },
        { id: 'instance-list-button' },
        {
            id: 'actions',
        },
    ], [t]);
    const rows = useMemo(() => {
        const rows = items.map((item) => ({
            id: item.id,
            cells: [
                _jsxs("span", { children: [item.name, _jsx("span", Object.assign({ className: styles.amount }, { children: money({ input: item.amount, currency }) }))] }, 'name'),
                _jsx("span", { children: numberRound(item.generatedCount) }, 'generatedCount'),
                _jsxs("span", { children: [numberRound(Number(item.generatedAmount) > 0
                            ? (Number(item.activatedAmount) * 100) /
                                Number(item.generatedAmount)
                            : 0), "%", ' ', item.activatedCount > 0 && (_jsxs("span", Object.assign({ className: styles.count }, { children: ["(", item.activatedCount, ")"] })))] }, 'activated'),
                _jsxs("span", { children: [numberRound(Number(item.generatedAmount) > 0
                            ? (Number(item.usedAmount) * 100) / Number(item.generatedAmount)
                            : 0), "%", ' ', item.fullyUsedCount > 0 && (_jsxs("span", Object.assign({ className: styles.count }, { children: ["(", item.fullyUsedCount, ")"] })))] }, 'used'),
                _jsxs("span", { children: [numberRound(Number(item.generatedAmount) > 0
                            ? (Number(item.expiredAmount) * 100) /
                                Number(item.generatedAmount)
                            : 0), "%", ' ', item.expiredCount > 0 && (_jsxs("span", Object.assign({ className: styles.count }, { children: ["(", item.expiredCount, ")"] })))] }, 'expired'),
                _jsxs("span", { children: [numberRound(roi(isProfitVisible ? item.profit : item.revenue, item.expenses)), "%"] }, 'roi'),
                _jsx(ProfitRevenue, { shouldDisplayProfit: isProfitVisible, profit: Number(item.profit), revenue: Number(item.revenue), currency: currency }, 'profit-or-revenue'),
                _jsx(Expenses, { expenses: Number(item.expenses), currency: currency }, 'expenses'),
                _jsx("span", Object.assign({ className: classNames(styles.instanceListButton, {
                        [styles.disabled]: !userHasGiftCardInstancesManage,
                    }), onClick: (e) => {
                        e.stopPropagation();
                        if (!e.ctrlKey && userHasGiftCardInstancesManage) {
                            e.preventDefault();
                            onCardNameClick(item.id);
                        }
                    } }, { children: _jsx(Tooltip, Object.assign({ title: !userHasGiftCardInstancesManage &&
                            t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx("div", Object.assign({ className: styles.iconWrapper }, { children: _jsx(BulletList, {}) })) })) }), 'instance-list-button'),
            ],
            actionsCellClassName: styles.actionsColumn,
            actions: [
                {
                    id: 'edit',
                    tooltip: !userHasFullManage && t('Недоступно для вашей роли'),
                    label: t('Редактировать'),
                    onClick: () => onCardEditClick(item.id),
                    isDisabled: !userHasFullManage,
                },
                {
                    id: 'export',
                    tooltip: !userHasFullManage && t('Недоступно для вашей роли'),
                    label: t('Выгрузить коды в файл'),
                    onClick: () => onCardExportClick(item),
                    shouldDisplay: item.type === 'print',
                    isDisabled: !userHasGiftCardInstancesManage,
                },
                {
                    id: 'delete',
                    tooltip: !userHasFullManage && t('Недоступно для вашей роли'),
                    label: t('Удалить'),
                    onClick: () => onCardDeleteClick(item),
                    isDanger: true,
                    isDisabled: !userHasFullManage,
                },
            ],
        }));
        return rows;
    }, [t, items, total, userHasAccessTo]);
    const totalRows = useMemo(() => {
        if (!total)
            return undefined;
        return [
            {
                id: 'total',
                cells: [
                    _jsx("span", Object.assign({ className: styles.total }, { children: t('Итого за период') }), 'total'),
                    '',
                    '',
                    '',
                    '',
                    _jsxs("span", { children: [numberRound(roi(isProfitVisible ? total.profit : total.revenue, total.expenses)), "%"] }, 'roi'),
                    _jsx(ProfitRevenue, { shouldDisplayProfit: isProfitVisible, profit: Number(total.profit), revenue: Number(total.revenue), currency: currency }, 'profit-or-revenue'),
                    _jsx(Expenses, { expenses: Number(total.expenses), currency: currency }, 'expenses'),
                    userHasAccessTo('GIFTCARDS_INSTANCES_MANAGE') ? '' : null,
                    _jsx("span", {}, 'actions'),
                ].filter(notNull),
            },
        ];
    }, [total, userHasAccessTo]);
    const handleRowClick = useCallback((row) => onCardEditClick(Number(row.id)), [onCardEditClick]);
    return (_jsx(Table, { className: styles.table, cellClassNames: { 8: styles.instanceListColumn }, columns: columns.filter(notNull), rows: rows, total: totalRows, isLoading: isLoading, hasEmptyState: true, onRowClick: handleRowClick }));
});
