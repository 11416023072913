import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { ExportTemplateType } from '@/components/ExportTemplatesTable/ExportTemplatesTable';
import { useExportXlsx } from './useExportXlsx';
export const useExportActions = ({ filters, searchText, }) => {
    const navigate = useNavigate();
    const exportPurchases = useExportXlsx({
        type: ExportTemplateType.PURCHASE,
        filters,
        searchText: searchText || undefined,
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    return {
        exportPurchases,
    };
};
