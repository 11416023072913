import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { TableEmpty } from '@/components/TableEmpty/TableEmpty';
import styles from './styles.module.scss';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
export const Table = memo(({ className, cellClassNames, columns, rows: providedRows, total = [], size = 'normal', isLoading = false, hasEmptyState = false, isFixed = false, needAdaptive = true, onRowClick, onRowEnter, onRowLeave, hasClicksOnSubRows = false, }) => {
    const [dropdownOpenRowId, setDropdownOpenRowId] = useState(null);
    const [expandedBodies, setExpandedBodies] = useState([]);
    const rowGroups = Array.isArray(providedRows)
        ? { ['main-table-body']: { rows: providedRows } }
        : providedRows;
    const firstBody = rowGroups[Object.keys(rowGroups)[0]];
    const commonBodyProps = {
        columns,
        cellClassNames,
        needAdaptive,
        onRowEnter,
        onRowLeave,
        activeRowId: dropdownOpenRowId,
        onChangeActiveRowId: setDropdownOpenRowId,
    };
    if (hasEmptyState && !isLoading && !(firstBody === null || firstBody === void 0 ? void 0 : firstBody.rows.length))
        return _jsx(TableEmpty, {});
    return (_jsxs("table", Object.assign({ className: classNames(className, styles.table, {
            [styles.small]: size === 'small',
            [styles.loading]: isLoading,
            [styles.fixed]: isFixed,
        }) }, { children: [_jsx(TableHead, { columns: columns, needAdaptive: needAdaptive }), Object.entries(rowGroups).map(([bodyId, body]) => (_jsx(TableBody, Object.assign({ id: bodyId, className: classNames({
                    [styles.parent]: !!body.childGroupId,
                    [styles.parentExpanded]: !!body.childGroupId &&
                        expandedBodies.includes(body.childGroupId),
                    [styles.subGroup]: body.type === 'sub-group',
                    [styles.expanded]: expandedBodies.includes(bodyId),
                }), type: body.type, rows: body.rows, childGroup: body.childGroupId ? rowGroups[body.childGroupId] : undefined, onToggleChild: () => {
                    if (!body.childGroupId)
                        return;
                    if (expandedBodies.includes(body.childGroupId))
                        setExpandedBodies(expandedBodies.filter((id) => id !== body.childGroupId));
                    else
                        setExpandedBodies([...expandedBodies, body.childGroupId]);
                }, childGroupExpanded: !!body.childGroupId && expandedBodies.includes(body.childGroupId), onRowClick: onRowClick, hasClicksOnSubRows: hasClicksOnSubRows }, commonBodyProps), bodyId))), _jsx(TableBody, Object.assign({ rows: total }, commonBodyProps))] })));
});
