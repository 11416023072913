import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { PageTitle } from '@/app/layout/AppLayout/PageTitle';
import { Button } from '@/components/Button2/Button';
import { Plus } from '@/icons/Plus';
import { ReportsNavButton } from './ReportsNavButton';
import styles from './styles.module.scss';
export const ReportsNav = memo(({ items, activeItemId, rightElement, onSelectItem, onRenameItem, onSaveItem, onDeleteItem, onAddItem, }) => {
    const addButton = (_jsx(Button, { type: 'button', icon: _jsx(Plus, {}), onClick: onAddItem }));
    const nav = (_jsx(_Fragment, { children: items.map((item, index) => {
            const button = (_jsx(ReportsNavButton, { title: item.title, isActive: item.id === activeItemId, onSelect: () => onSelectItem(item.id), onRename: () => onRenameItem(activeItemId), onSave: () => onSaveItem(activeItemId), onDelete: () => onDeleteItem(activeItemId) }, item.id));
            if (index === items.length - 1)
                return (_jsxs("div", Object.assign({ className: styles.addButtonWrapper }, { children: [button, addButton] }), item.id));
            return button;
        }) }));
    return (_jsxs("div", Object.assign({ className: styles.wraper }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: [_jsx(PageTitle, {}), rightElement] })), _jsx("div", Object.assign({ className: styles.nav }, { children: nav }))] })));
});
