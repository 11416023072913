var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorMessage } from '@/api/query';
import { usePublishWalletCardsMutation } from '@/api/query/wallet-cards';
export const useWalletCardPublish = () => {
    const [publishCards, { isLoading: isPublishing }] = usePublishWalletCardsMutation();
    const publish = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield publishCards();
        if ('error' in result) {
            const status = (_a = result.error) === null || _a === void 0 ? void 0 : _a.status;
            if (status !== 200)
                return 'server-errors';
            return getErrorMessage(result.error);
        }
        else
            return '';
    });
    return { publish, isPublishing };
};
