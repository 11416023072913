import { jsx as _jsx } from "react/jsx-runtime";
import { useGetClients } from '@/hooks/useGetClients';
import { FormPurchaseModal } from './FormPurchaseModal';
import { useConfirmPurchaseTicket } from './useConfirmPurchaseTicket';
import { useDeletePurchase } from './useDeletePurchase';
import { useGetProducts } from './useGetProducts';
import { useSetPurchase } from './useSetPurchase';
import { useShops } from './useShops';
import { useUpdatePurchase } from './useUpdatePurchase';
export const FormPurchaseModalContainer = ({ isOpen, onClose, currency, selectedPurchase = null, selectedClient = null, }) => {
    const shops = useShops();
    const getClients = useGetClients();
    const getProducts = useGetProducts();
    const setPurchase = useSetPurchase();
    const confirmPurchaseTicket = useConfirmPurchaseTicket();
    const updatePurchase = useUpdatePurchase();
    const deletePurchase = useDeletePurchase();
    return (_jsx(FormPurchaseModal, { isOpen: isOpen, onClose: onClose, shops: shops, currency: currency, getClients: getClients, getProducts: getProducts, setPurchase: setPurchase, confirmPurchaseTicket: confirmPurchaseTicket, updatePurchase: updatePurchase, onDelete: deletePurchase, selectedPurchase: selectedPurchase, selectedClient: selectedClient }));
};
