export const RFMSegmentCodeMap = {
    rfm_vip: 'VIP',
    rfm_pot_vip: 'potentialVIP',
    rfm_loyal: 'loyal',
    rfm_new: 'newcomers',
    rfm_maybe_rtl_vip: 'leavingVIP',
    rfm_pot_loyal: 'potentialLoyal',
    rfm_maybe_rtl: 'outflowPossible',
    rfm_rtl_vip: 'outflowVIP',
    rfm_rtl: 'outflow',
    rfm_rtl_new: 'oneTimeClients',
    rfm_lost: 'ultraflow',
};
export const RFMSegmentIdMap = {
    VIP: 'rfm_vip',
    potentialVIP: 'rfm_pot_vip',
    loyal: 'rfm_loyal',
    newcomers: 'rfm_new',
    leavingVIP: 'rfm_maybe_rtl_vip',
    potentialLoyal: 'rfm_pot_loyal',
    outflowPossible: 'rfm_maybe_rtl',
    outflowVIP: 'rfm_rtl_vip',
    outflow: 'rfm_rtl',
    oneTimeClients: 'rfm_rtl_new',
    ultraflow: 'rfm_lost',
    ultraLargeSales: 'ultralarge_amount',
    ultraActiveCustomers: 'ultralarge_count',
    possibleFraud: 'fraud',
    wholesalers: 'wholesale',
};
export const getRFMSegmentId = (code) => RFMSegmentCodeMap[code || ''];
export const getRFMSegmentCode = (id) => RFMSegmentIdMap[id];
