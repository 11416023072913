import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import styles from '../styles.module.scss';
import { GOAL_TYPES } from './FormMailingGoalType';
export const FormMailingGoalDaysTo = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, formState } = useFormContext();
    const { errors } = formState;
    const goalType = watch('goalType');
    if (goalType === GOAL_TYPES.DISCARD)
        return null;
    return (_jsx(FormFieldRow, Object.assign({ label: t('в течение'), labelFor: 'goalDaysTo', labelWidth: labelWidth, help: (_a = errors.goalDaysTo) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.goalDaysTo }, { children: _jsx(Controller, { name: 'goalDaysTo', control: control, rules: {
                required: {
                    value: true,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                min: {
                    value: 1,
                    message: t('MIN_DAYS_ERROR_MESSAGE', { amount: 1, days: 1 }),
                },
                max: {
                    value: 999,
                    message: t('MAX_DAYS_ERROR_MESSAGE', { amount: 999, days: 999 }),
                },
            }, render: ({ field }) => (_jsxs("div", Object.assign({ className: styles.goalDaysToWrapper }, { children: [_jsx(Input, Object.assign({}, field, { wrapperClassName: styles.goalDaysToInput, id: 'goalDaysTo', type: 'number', inputMode: 'numeric', preventChars: [',', '.'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.goalDaysTo })), _jsx("div", Object.assign({ className: styles.goalDaysToDescription }, { children: t('MAILING_GOAL_DAYS_TO', { count: Number(field.value) || 0 }) }))] }))) }) })));
};
