import { $insertNodes } from 'lexical';
import { $createSpaceNode } from './SpaceNode';
function insertSpaceOnSpace(e, editor) {
    e.preventDefault();
    editor.update(() => {
        const node = $createSpaceNode();
        $insertNodes([node]);
    });
    return true;
}
export default insertSpaceOnSpace;
