import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { FormOfferActiveToggle } from './controls/FormOfferActiveToggle';
import styles from './styles.module.scss';
export const FormOfferFooter = ({ isEdit, onDelete, isSubmitting, setActive, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasOffersManage = userHasAccessTo('OFFERS_MANAGE');
    return (_jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsxs("div", Object.assign({ className: styles.toolbar }, { children: [isEdit && (_jsx(Tooltip, Object.assign({ placement: 'top', title: userHasOffersManage ? undefined : t('Недоступно для вашей роли') }, { children: _jsx(TrashButton, { disabled: !userHasOffersManage, tooltip: userHasOffersManage
                                ? t('Удалить акцию')
                                : t('Недоступно для вашей роли'), onClick: onDelete }) }))), _jsx(Tooltip, Object.assign({ placement: 'top', title: userHasOffersManage ? undefined : t('Недоступно для вашей роли') }, { children: _jsx("span", { children: _jsx(FormOfferActiveToggle, { disabled: !userHasOffersManage, setActive: setActive }) }) }))] })), _jsx(Tooltip, Object.assign({ placement: 'top', title: userHasOffersManage ? undefined : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: !userHasOffersManage || isSubmitting, size: 'large' }, { children: isEdit ? t('Сохранить изменения') : t('Создать акцию') })) }))] })));
};
