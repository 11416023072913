var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/Switch/Switch';
export const FormOfferActiveToggle = ({ disabled = false, setActive, }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const [isLoading, setIsLoading] = useState(false);
    const checked = watch('isActive');
    const handleChange = (checked) => __awaiter(void 0, void 0, void 0, function* () {
        let ok = true;
        if (setActive) {
            setIsLoading(true);
            ok = yield setActive(checked);
            setIsLoading(false);
        }
        if (ok)
            setValue('isActive', checked);
    });
    return (_jsx(Switch, { label: checked ? t('Включена') : t('Остановлена'), checked: checked, onChange: handleChange, disabled: disabled || isLoading }));
};
