import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { Mail } from '@/icons/Mail';
import { emailPattern } from '@/utils/email';
import { numberRound } from '@/utils/numbers';
import styles from '../styles.module.scss';
export const FormUsersEmail = ({ isRequired = false, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, Object.assign({ label: t('Email'), labelFor: 'email', controlSize: '250', help: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.email, isRequired: isRequired }, { children: _jsx(Controller, { name: 'email', control: control, rules: {
                maxLength: {
                    value: 200,
                    message: t('FIELD_MAX_LENGTH_ERROR', {
                        amount: numberRound(200),
                        count: 200,
                    }),
                },
                required: {
                    value: isRequired,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                pattern: {
                    value: emailPattern,
                    message: t('неверный Email'),
                },
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'email', type: 'email', placeholder: t('name@mail.com'), leftIcon: _jsx(Mail, { className: styles.mailInputIcon }), hasError: !!errors.email }))) }) })));
};
