import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetGiftCardInstanceListQuery, useGetGiftCardQuery, } from '@/api/query/giftCard';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { FormGiftCardGenerateModal } from '@/forms/FormGiftCardGenerate/FormGiftCardGenerateModal';
import { useGenerateGiftCards } from '../GiftCards/useGenerateGiftCards';
import { ROUTES } from '../routes';
import { GiftCardInstancesPanel } from './GiftCardInstancesPanel';
import { useActivate } from './useActivate';
import { useToggleBlock } from './useToggleBlock';
export const GiftCardsInstances = () => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const params = useParams();
    const navigate = useNavigate();
    const giftCardId = Number(params.giftCardId);
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const onGoToExports = () => navigate(ROUTES.EXPORTS);
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleToggleModal = useCallback(() => setModalIsOpen(!modalIsOpen), [modalIsOpen]);
    const fetchParams = { giftCardId, limit, offset, searchText };
    const { data: giftCard } = useGetGiftCardQuery(giftCardId);
    const { data: instances, isLoading, isFetching, } = useGetGiftCardInstanceListQuery(fetchParams, {
        refetchOnMountOrArgChange: true,
    });
    const activate = useActivate();
    const toggleBlock = useToggleBlock(giftCard);
    const { generateGiftCards, isSubmitting, error } = useGenerateGiftCards({
        onSuccess: onGoToExports,
    });
    const handleApplySearch = (searchText) => {
        setSearchText(searchText);
        setOffset(0);
    };
    const handleChangeOffset = (offset) => {
        setOffset(offset);
    };
    return (_jsxs(Container, { children: [_jsx(SearchPanel, { placeholder: t('Штрих-код'), onApplySearch: handleApplySearch, hasAutoFocus: true, hasBottomMargin: true, hasMaxWidth: true }), _jsx(GiftCardInstancesPanel, { titleTotal: (giftCard === null || giftCard === void 0 ? void 0 : giftCard.generatedCount) || 0, hasExportDropdown: (giftCard === null || giftCard === void 0 ? void 0 : giftCard.type) === 'print', onExportCodes: handleToggleModal, paginationTotal: searchText ? (instances === null || instances === void 0 ? void 0 : instances.length) || 0 : (giftCard === null || giftCard === void 0 ? void 0 : giftCard.generatedCount) || 0, offset: offset, limit: limit, onChangeOffset: handleChangeOffset, items: instances || [], currency: currency, onActivateInstance: activate, onToggleBlockInstance: toggleBlock, isLoading: !giftCard || isLoading, isFetching: isFetching }), giftCard && (_jsx(FormGiftCardGenerateModal, { isOpen: modalIsOpen, onClose: handleToggleModal, card: giftCard, onSubmit: (payload) => generateGiftCards(Object.assign({ id: giftCard.id }, payload)), isSubmitting: isSubmitting, error: error, currency: currency }))] }));
};
