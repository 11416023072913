import { findAll } from 'highlight-words-core';
import { escapeHtml } from './escapeHtml';
export const highlightText = (textToHighlight, search, tagName = 'mark') => {
    if (!textToHighlight)
        return '';
    const searchWords = (Array.isArray(search) ? search : [search || ''])
        .map((w) => w.trim())
        .filter(Boolean);
    const chunks = findAll({
        searchWords,
        textToHighlight,
        autoEscape: true,
    });
    const highlightedText = chunks
        .map((chunk) => {
        const { start, end, highlight } = chunk;
        const part = textToHighlight.slice(start, end);
        return highlight ? `<${tagName}>${part}</${tagName}>` : part;
    })
        .join('');
    return highlightedText;
};
export const exactMatch = (textToHighlight, search, tagName = 'mark') => {
    if (textToHighlight === search)
        return `<${tagName}>${escapeHtml(textToHighlight)}</${tagName}>`;
    else
        return escapeHtml(textToHighlight);
};
