import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import styles from './styles.module.scss';
export const NumberRangeInput = memo(({ min, minError, onChangeMin, max, maxError, onChangeMax, inputPostfix, pluralPostfix, inputRightText, helpConstraints, onKeyDown, }) => {
    const { t } = useTranslation();
    const handleChangeMin = useCallback((e) => {
        onChangeMin(e.target.value);
    }, [onChangeMin]);
    const handleChangeMax = useCallback((e) => {
        onChangeMax(e.target.value);
    }, [onChangeMax]);
    let help = t('Укажите хотя бы одно значение');
    if (typeof (helpConstraints === null || helpConstraints === void 0 ? void 0 : helpConstraints.minValue) !== 'undefined')
        help += ` ${t('от')} ${helpConstraints.minValue}`;
    if (typeof (helpConstraints === null || helpConstraints === void 0 ? void 0 : helpConstraints.maxValue) !== 'undefined')
        help += ` ${t('до')} ${helpConstraints.maxValue}`;
    const postfix = inputPostfix ? (_jsx("span", Object.assign({ className: styles.label }, { children: pluralPostfix
            ? t(inputPostfix, { count: max ? Number(max) : Number(min) })
            : inputPostfix }))) : null;
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.inputs }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'number-range-min' }, { children: t('от') })), _jsxs("div", Object.assign({ className: classNames(styles.input, {
                            [styles.fixed]: pluralPostfix,
                        }) }, { children: [_jsx(Input, { id: 'number-range-min', value: min, hasError: !!minError, onChange: handleChangeMin, rightText: inputRightText, type: 'number', inputMode: 'numeric', shouldHideSpinButtons: true, onKeyDown: onKeyDown }), minError && _jsx("div", Object.assign({ className: styles.error }, { children: minError }))] })), _jsx("label", Object.assign({ className: styles.label, htmlFor: 'number-range-max' }, { children: t('до') })), _jsxs("div", Object.assign({ className: classNames(styles.input, {
                            [styles.fixed]: pluralPostfix,
                        }) }, { children: [_jsx(Input, { id: 'number-range-max', value: max, hasError: !!maxError, onChange: handleChangeMax, rightText: inputRightText, type: 'number', inputMode: 'numeric', shouldHideSpinButtons: true, onKeyDown: onKeyDown }), maxError && _jsx("div", Object.assign({ className: styles.error }, { children: maxError }))] })), postfix] })), _jsx(InputInfo, { children: help })] }));
});
