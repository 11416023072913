import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useGetBrandQuery } from '@/api/query/brand';
import { useGetUserQuery } from '@/api/query/user';
import { ROUTES } from './routes';
export const Protect = ({ from }) => {
    const { data: user, isFetching: isLoadingUser } = useGetUserQuery();
    const { isFetching: isLoadingBrand } = useGetBrandQuery(undefined, {
        skip: !(user === null || user === void 0 ? void 0 : user.isLoggedIn),
    });
    const location = useLocation();
    if (isLoadingUser || isLoadingBrand)
        return _jsx(Outlet, {});
    const isProtected = from === 'guest' ? !(user === null || user === void 0 ? void 0 : user.isLoggedIn) : user === null || user === void 0 ? void 0 : user.isLoggedIn;
    const to = from === 'guest' ? ROUTES.LOGIN : ROUTES.ROOT;
    const state = from === 'guest' ? { from: location } : undefined;
    if (isProtected)
        return _jsx(Navigate, { to: to, state: state, replace: true });
    return _jsx(Outlet, {});
};
