import { createSlice } from '@reduxjs/toolkit';
import { findTreeLoaderItem } from '@/services/filters/findTreeLoaderItem';
import { FetchStatus } from '@/store/slice';
import * as thunks from '@/store/thunk/filters';
import { processPaginatedThunks, processSimpleThunks } from '@/utils/reducers';
const filtersSlice = createSlice({
    name: 'filters',
    initialState: {
        clientLevelOptions: { status: FetchStatus.Idle },
        automaticMailingOptions: { status: FetchStatus.Idle },
        manualMailingOptions: { status: FetchStatus.Idle },
        offerOptions: { status: FetchStatus.Idle },
        promocodeFriendOptions: { status: FetchStatus.Idle },
        promocodeOptions: { status: FetchStatus.Idle },
        shopOptions: { status: FetchStatus.Idle },
        cashierOptions: { status: FetchStatus.Idle },
        categoryOptions: { status: FetchStatus.Idle },
        productOptions: { status: FetchStatus.Idle },
        productPaginatedOptions: { status: FetchStatus.Idle },
        segmentOptions: { status: FetchStatus.Idle },
        rfmSegmentOptions: { status: FetchStatus.Idle },
        extraFields: { status: FetchStatus.Idle },
        productProperties: { status: FetchStatus.Idle },
        productAttributesKeys: { status: FetchStatus.Idle },
        shopGroupOptions: { status: FetchStatus.Idle },
    },
    reducers: {},
    extraReducers: (builder) => {
        processSimpleThunks(builder, {
            clientLevelOptions: thunks.getClientLevelOptionsThunk,
            automaticMailingOptions: thunks.getAutomaticMailingOptionsThunk,
            manualMailingOptions: thunks.getManualMailingOptionsThunk,
            offerOptions: thunks.getOfferOptionsThunk,
            promocodeFriendOptions: thunks.getPromocodeFriendOptionsThunk,
            promocodeOptions: thunks.getPromocodeOptionsThunk,
            shopOptions: thunks.getShopOptionsThunk,
            segmentOptions: thunks.getSegmentOptionsThunk,
            rfmSegmentOptions: thunks.getRFMSegmentOptionsThunk,
            productProperties: thunks.getProductPropertiesThunk,
            productAttributesKeys: thunks.getProductAttributesKeysThunk,
            shopGroupOptions: thunks.getShopGroupOptionsThunk,
        });
        processPaginatedThunks(builder, {
            cashierOptions: {
                thunk: thunks.getCashierOptionsThunk,
                sortKey: 'code',
            },
            categoryOptions: {
                thunk: thunks.getCategoryOptionsThunk,
                sortKey: 'externalId',
            },
            productPaginatedOptions: {
                thunk: thunks.getProductOptionsThunk,
                sortKey: 'externalId',
            },
        });
        // --- getBrandExtraFieldOptionsThunk --------------------------------------
        builder.addCase(thunks.getBrandExtraFieldOptionsThunk.pending, (state) => {
            state.extraFields.status = FetchStatus.Loading;
        });
        builder.addCase(thunks.getBrandExtraFieldOptionsThunk.fulfilled, (state, action) => {
            const { fieldName } = action.meta.arg;
            if (!state.extraFields.data)
                state.extraFields.data = {};
            state.extraFields.status = FetchStatus.Finished;
            state.extraFields.data[fieldName] = action.payload || [];
        });
        builder.addCase(thunks.getBrandExtraFieldOptionsThunk.rejected, (state, action) => {
            state.extraFields.status = FetchStatus.Error;
            state.extraFields.error = action.error;
        });
        // --- getProductsTreeThunk ------------------------------------------------
        builder.addCase(thunks.getProductsTreeThunk.pending, (state) => {
            state.productOptions.status = FetchStatus.Loading;
            if (state.productOptions.data)
                state.productOptions.data.isLoadingMoreData = true;
        });
        builder.addCase(thunks.getProductsTreeThunk.fulfilled, (state, action) => {
            var _a, _b, _c;
            const results = ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.results) || [];
            const moreResultsPerBranch = ((_b = action.payload) === null || _b === void 0 ? void 0 : _b.moreResultsPerBranch) || {};
            const rootId = ((_c = action.payload) === null || _c === void 0 ? void 0 : _c.rootId) || 0;
            const catalogs = results.filter((i) => i.type === 'catalog');
            const categories = results.filter((i) => i.type === 'category');
            const products = results.filter((i) => i.type === 'product');
            const slice = state.productOptions;
            slice.status = FetchStatus.Finished;
            slice.data = {
                rootId,
                items: [],
                hasMoreData: moreResultsPerBranch[rootId],
                isLoadingMoreData: false,
            };
            catalogs.forEach((catalog) => {
                var _a, _b;
                (_b = (_a = slice.data) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.push({
                    id: catalog.id.toString(),
                    branchId: catalog.id.toString(),
                    label: catalog.title,
                    items: [],
                    isCatalog: true,
                    offset: 0,
                    hasMoreData: moreResultsPerBranch[catalog.id],
                });
            });
            categories.sort((a, b) => {
                const aChildren = categories.filter((i) => i.parentId === a.id).length;
                const bChildren = categories.filter((i) => i.parentId === b.id).length;
                return bChildren - aChildren;
            });
            categories.forEach((category) => {
                var _a;
                const parent = findTreeLoaderItem(slice.data, category.parentId.toString());
                const items = parent ? parent.items : (_a = slice.data) === null || _a === void 0 ? void 0 : _a.items;
                if (items)
                    items.push({
                        id: category.externalId,
                        branchId: category.id.toString(),
                        label: category.title,
                        items: [],
                        isCategory: true,
                        offset: 0,
                        hasMoreData: moreResultsPerBranch[category.id],
                    });
            });
            products.forEach((product) => {
                var _a;
                const parent = findTreeLoaderItem(slice.data, product.parentId.toString());
                const items = parent ? parent.items : (_a = slice.data) === null || _a === void 0 ? void 0 : _a.items;
                if (items)
                    items.push({
                        id: product.externalId,
                        label: product.title,
                        items: null,
                    });
            });
        });
        builder.addCase(thunks.getProductsTreeThunk.rejected, (state, action) => {
            state.productOptions.status = FetchStatus.Error;
            state.productOptions.error = action.error;
        });
        // --- getProductsBranchThunk ----------------------------------------------
        builder.addCase(thunks.getProductsBranchThunk.pending, (state, action) => {
            const { parentId } = action.meta.arg;
            const slice = state.productOptions;
            if (parentId && parentId !== slice.data.rootId) {
                const item = findTreeLoaderItem(slice.data, parentId.toString());
                if (item)
                    item.isLoadingMoreData = true;
            }
            else
                slice.data.isLoadingMoreData = true;
        });
        builder.addCase(thunks.getProductsBranchThunk.fulfilled, (state, action) => {
            var _a, _b;
            const { parentId, limit, offset } = action.meta.arg;
            const results = ((_a = action.payload) === null || _a === void 0 ? void 0 : _a.results) || [];
            const moreResults = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.moreResults;
            const categories = results.filter((i) => i.type === 'category');
            const products = results.filter((i) => i.type === 'product');
            const slice = state.productOptions;
            const parent = parentId && parentId !== slice.data.rootId
                ? findTreeLoaderItem(slice.data, parentId.toString())
                : slice.data;
            if (!(parent === null || parent === void 0 ? void 0 : parent.items))
                return;
            parent.offset = offset;
            parent.hasMoreData = moreResults;
            parent.isLoadingMoreData = false;
            parent.items.splice(offset, limit, ...categories.map((category) => ({
                id: category.externalId,
                branchId: category.id.toString(),
                label: category.title,
                items: [],
                isCategory: true,
            })), ...products.map((product) => ({
                id: product.externalId,
                label: product.title,
                items: null,
            })));
        });
        builder.addCase(thunks.getProductsBranchThunk.rejected, (state, action) => {
            state.productOptions.status = FetchStatus.Error;
            state.productOptions.error = action.error;
        });
    },
});
export default filtersSlice.reducer;
