var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api as queryApi } from '@/api/query';
import { pushError } from '@/store/slice/httpError';
export const createSimpleThunk = (typePrefix, api) => createAsyncThunk(typePrefix, (payload, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const { data } = yield api(payload);
        return data.data;
    }
    catch (e) {
        const status = ((_a = e === null || e === void 0 ? void 0 : e.request) === null || _a === void 0 ? void 0 : _a.status) || 500;
        thunkApi.dispatch(pushError({ status }));
        if (status === 401)
            thunkApi.dispatch(queryApi.util.invalidateTags(['CurrentUser', 'CurrentBrand']));
        thunkApi.rejectWithValue(e);
    }
}));
