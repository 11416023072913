import { getMovementStatus as status } from '@/components/RFMMovementsTable/RFMMovementStatus';
import { calculatePercent } from '@/utils/calculatePercent';
const count = (m) => m.count;
const sum = [(p, c) => p + c, 0];
export const getChartData = (rfmSegments, movements, chartType, shouldUseTotalChartMax) => {
    const data = {};
    const createBar = (movements, type, max) => {
        const total = movements.map(count).reduce(...sum);
        const segments = movements.map((m) => {
            const from = rfmSegments.find((s) => s.code === m.from);
            const to = rfmSegments.find((s) => s.code === m.to);
            if (!from || !to)
                return null;
            const color = type === 'from' ? from.color : to.color;
            const width = calculatePercent(m.count, max);
            return { color, width };
        });
        return {
            count: total,
            segments: segments.filter(Boolean),
        };
    };
    const getBarsForSegment = (filterLeft, filterRight, leftColor, rightColor, providedMax) => {
        const left = movements.filter(filterLeft);
        const totalLeft = left.map(count).reduce(...sum);
        const right = movements.filter(filterRight);
        const totalRight = right.map(count).reduce(...sum);
        const max = providedMax || Math.max(totalLeft, totalRight);
        return {
            left: left.length ? createBar(left, leftColor, max) : undefined,
            right: right.length ? createBar(right, rightColor, max) : undefined,
        };
    };
    const getMaxForSegment = (filterLeft, filterRight) => {
        const left = movements.filter(filterLeft);
        const totalLeft = left.map(count).reduce(...sum);
        const right = movements.filter(filterRight);
        const totalRight = right.map(count).reduce(...sum);
        return Math.max(totalLeft, totalRight);
    };
    const alls = [];
    const froms = [];
    const tos = [];
    for (let i = 0; i < rfmSegments.length; i += 1) {
        const segment = rfmSegments[i];
        if (!segment.code)
            continue;
        alls.push(getMaxForSegment((m) => m.from === segment.code, (m) => m.to === segment.code));
        froms.push(getMaxForSegment((m) => m.to === segment.code && status(m.from, m.to) === 'positive', (m) => m.to === segment.code && status(m.from, m.to) === 'negative'));
        tos.push(getMaxForSegment((m) => m.from === segment.code && status(m.from, m.to) === 'positive', (m) => m.from === segment.code && status(m.from, m.to) === 'negative'));
    }
    for (let i = 0; i < rfmSegments.length; i += 1) {
        const segment = rfmSegments[i];
        if (!segment.code)
            continue;
        if (chartType === 'all') {
            const { left, right } = getBarsForSegment((m) => m.from === segment.code, (m) => m.to === segment.code, 'to', 'from', shouldUseTotalChartMax ? Math.max(...alls) : undefined);
            data[segment.code] = { left, right };
        }
        if (chartType === 'from') {
            const { left, right } = getBarsForSegment((m) => m.to === segment.code && status(m.from, m.to) === 'positive', (m) => m.to === segment.code && status(m.from, m.to) === 'negative', 'from', 'from', shouldUseTotalChartMax ? Math.max(...froms) : undefined);
            data[segment.code] = { left, right };
        }
        if (chartType === 'to') {
            const { left, right } = getBarsForSegment((m) => m.from === segment.code && status(m.from, m.to) === 'positive', (m) => m.from === segment.code && status(m.from, m.to) === 'negative', 'to', 'to', shouldUseTotalChartMax ? Math.max(...tos) : undefined);
            data[segment.code] = { left, right };
        }
    }
    return data;
};
