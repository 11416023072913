import { buildAppNav } from './layout/AppNav/AppNavData';
export const availableRoutes = (brand, userHasAccessTo) => {
    var _a;
    if (!brand)
        return {};
    const availableRoutes = buildAppNav(false, brand, userHasAccessTo)
        .flatMap((group) => {
        return group.flatMap((item) => item.items);
    })
        .filter((item) => item.shouldDisplay && item.route);
    return {
        firstAvailableRoute: (_a = availableRoutes === null || availableRoutes === void 0 ? void 0 : availableRoutes[0]) === null || _a === void 0 ? void 0 : _a.route,
        availableRoutesList: availableRoutes || [],
    };
};
