import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getProductAttributesKeysSelector, getProductPropertiesSelector, getProductsOptionsSelector, } from '@/store/selectors/filters';
import { getProductAttributesValuesSelector } from '@/store/selectors/productAttributes';
import { getProductAttributesKeysThunk, getProductAttributesValuesThunk, getProductPropertiesThunk, getProductsBranchThunk, getProductsTreeThunk, } from '@/store/thunk/filters';
import { buildProductAttributes } from '../buildProductAttributes';
import { buildProductProperties } from '../buildProductProperties';
export const useConditionsProductFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getProductPropertiesThunk());
        dispatch(getProductAttributesKeysThunk());
    }, []);
    const { currency } = useCurrentUser();
    const products = useAppSelector(getProductsOptionsSelector);
    const productProperties = useAppSelector(getProductPropertiesSelector);
    const productAttributesKeys = useAppSelector(getProductAttributesKeysSelector);
    const productAttributesValues = useAppSelector(getProductAttributesValuesSelector);
    const purchaseItemBranch = {
        type: 'branch',
        id: 'purchaseItemBranch',
        label: t('Товар'),
        isInitiallyExpanded: true,
        items: [
            {
                type: 'leaf',
                id: 'purchaseItem',
                label: t('Наименование'),
                filterTitle: t('Товар'),
                template: 'checkboxes-tree-loader',
                loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                options: products,
            },
            {
                type: 'leaf',
                id: 'purchaseItemSku',
                label: t('Артикул'),
                template: 'textField',
            },
            {
                type: 'leaf',
                id: 'purchaseItemPrice',
                label: t('Цена'),
                template: 'numRange',
            },
            {
                type: 'leaf',
                id: 'purchaseItemMarkup',
                label: t('Наценка, %'),
                template: 'numRange',
            },
            {
                type: 'leaf',
                id: 'purchaseItemBasicDiscount',
                label: t('Переоценка, %'),
                template: 'numRange',
            },
            {
                type: 'leaf',
                id: 'purchaseItemDiscount',
                label: t('Скидка кассы, %'),
                template: 'numRange',
            },
            {
                type: 'branch',
                id: 'productProperties',
                label: t('Свойства'),
                items: buildProductProperties(productProperties.data || [], t),
            },
            {
                type: 'branch',
                id: 'productAttributes',
                label: t('Атрибуты'),
                items: buildProductAttributes(productAttributesKeys, productAttributesValues, (args) => dispatch(getProductAttributesValuesThunk(args))),
            },
        ],
        isHiddenIfUsed: [],
    };
    const conditionsBranch = {
        type: 'branch',
        id: 'conditionsBranch',
        label: t('Условия'),
        isInitiallyExpanded: true,
        items: [
            {
                type: 'leaf',
                id: 'purchaseItemCount',
                label: t('Количество'),
                template: 'numRange',
            },
            {
                type: 'leaf',
                id: 'purchaseItemAmount',
                label: t('Сумма'),
                template: 'numRange',
                inputPostfix: currency,
                composePostfixToText: true,
            },
        ],
        isHiddenIfNotUsed: [
            'purchaseItem',
            'purchaseItemSku',
            'purchaseItemPrice',
            'purchaseItemMarkup',
            'purchaseItemBasicDiscount',
            'purchaseItemDiscount',
            (code) => code.startsWith('purchaseItemProperty'),
            (code) => code.startsWith('purchaseItemAttribute'),
        ],
    };
    const tree = [conditionsBranch, purchaseItemBranch];
    return tree;
};
