import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import styles from './styles.module.scss';
export const BannerDropdown = () => {
    var _a;
    const { t } = useTranslation();
    const { bannerType, setBannerType, markReadyToSave } = useWalletCardForm();
    const items = [
        {
            id: 'simple',
            label: t('с обычной картинкой'),
            isSelected: bannerType === 'simple',
            onClick: () => {
                setBannerType('simple');
                markReadyToSave();
            },
        },
        {
            id: 'with-text',
            label: t('с текстом'),
            isSelected: bannerType === 'with-text',
            onClick: () => {
                setBannerType('with-text');
                markReadyToSave();
            },
        },
        {
            id: 'with-counter',
            label: t('со счетчиком'),
            isSelected: bannerType === 'with-counter',
            onClick: () => {
                setBannerType('with-counter');
                markReadyToSave();
            },
        },
    ];
    return (_jsx(Dropdown, { items: items, titleClassName: styles.bannerDropdownTitle, title: (_a = items.find((i) => i.isSelected)) === null || _a === void 0 ? void 0 : _a.label, placement: 'bottom-start' }));
};
