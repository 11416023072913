import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGetBrandPromocodesQuery } from '@/api/query/promocodes';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_SHARED } from '@/const/promocode';
import styles from './styles.module.scss';
export const AutocompletePromocodes = ({ value, onChange, }) => {
    const { t } = useTranslation();
    const { data = [] } = useGetBrandPromocodesQuery();
    const shared = data.filter((p) => p.codeType === PROMOCODE_TYPE_SHARED);
    const friends = data.filter((p) => p.codeType === PROMOCODE_TYPE_FRIEND);
    const options = [
        {
            id: 'group-shared',
            label: t('Общие'),
            isGroup: true,
            isVisible: shared.length > 0,
        },
        ...shared.map((p) => ({
            id: p.id.toString(),
            label: p.code,
            isGroup: false,
            isVisible: true,
        })),
        {
            id: 'group-friends',
            label: t('Приведи друга'),
            isGroup: true,
            isVisible: friends.length > 0,
        },
        ...friends.map((p) => ({
            id: p.id.toString(),
            label: `${p.code}-XXX-XXX`,
            isGroup: false,
            isVisible: true,
        })),
    ].filter((i) => i.isVisible);
    return (_jsx(Autocomplete, { wrapperClassName: styles.autocomplete, options: options, value: options.find((opt) => opt.id === value) || null, onChange: (item) => {
            if (!(item === null || item === void 0 ? void 0 : item.isGroup))
                onChange(data.find((p) => p.id === Number(item === null || item === void 0 ? void 0 : item.id)) || null);
        }, getOptionKey: (opt) => opt.id, getOptionLabel: (opt) => opt.label, isOptionGroup: (opt) => opt.isGroup, isOptionGroupItem: (opt) => !opt.isGroup, hasResetButton: false, disablePortal: true }, options.length));
};
