var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage, getErrorObject } from '@/api/query';
import { useDeletePromocodeMutation } from '@/api/query/promocodes';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useDeletePromocode = ({ section, codeTypeShared, onSuccess, }) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deletePromocode] = useDeletePromocodeMutation();
    return (code) => __awaiter(void 0, void 0, void 0, function* () {
        const name = code.codeType !== codeTypeShared ? `${code.code}-XXX-XXX` : code.code;
        const confirmTitle = section === 'common'
            ? t('Удалить промокод {{name}}?', { name })
            : t('Удалить код приведи друга {{name}}?', { name });
        const confirmAgreeLabel = section === 'common' ? t('Удалить промокод') : t('Удалить код');
        const successMessage = section === 'common' ? t('Промокод удалён') : t('Код удалён');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deletePromocode(code.id);
        const errorObject = getErrorObject(result);
        const errorMessage = getErrorMessage(result);
        if (errorObject || errorMessage)
            if (errorObject)
                addToast({
                    type: 'error',
                    title: errorObject.message,
                    message: errorObject.hint,
                    linkText: errorObject.objectName,
                    linkHref: errorObject.href,
                });
            else
                addToast({ type: 'error', message: errorMessage });
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
};
