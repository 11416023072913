import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { GiftCardWarning } from '../../Warnings/GiftCardWarning';
import { PromocodeWarning } from '../../Warnings/PromocodeWarning';
import formStyles from '../styles.module.scss';
import { useCalculate } from '../useCalculate';
export const PushFormText = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const { triggerType, selectedNode, validationErrorMap } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const calculate = useCalculate();
    const [pushText, setPushText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.pushText) || '');
    const updatePushText = (pushText) => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { pushText }),
        });
        calculate({ pushText }).then((response) => {
            selectedNode.update({
                push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { pushText, pushTextLength: response.pushTextLength }),
            });
        });
    };
    const handleUpdatePushText = () => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.pushText) !== pushText)
            updatePushText(pushText);
    };
    const editorContext = {
        channel: 'push',
        triggerType: triggerType,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
    };
    return (_jsxs("div", { children: [_jsx(MessageEditor, { initialValue: pushText, onChange: setPushText, onBlur: handleUpdatePushText, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.pushText) }), (errors === null || errors === void 0 ? void 0 : errors.pushText) ? (_jsx("div", Object.assign({ className: formStyles.error }, { children: errors.pushText }))) : (_jsx("div", Object.assign({ className: formStyles.help }, { children: t('Длина:') + ' ' + (((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _b === void 0 ? void 0 : _b.pushTextLength) || 0) }))), _jsx(PromocodeWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: pushText }), _jsx(GiftCardWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: pushText })] }));
};
