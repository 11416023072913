import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { Email } from '@/icons/wallet-card/google/Email';
import { Globe } from '@/icons/wallet-card/google/Globe';
import { Phone } from '@/icons/wallet-card/google/Phone';
import { isValidEmail } from '@/utils/email';
import { isValidPhoneNumber } from '@/utils/phoneNumber';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { DragDrop } from '../../helpers/DragDrop';
import styles from '../../styles.module.scss';
export const BacksideContacts = () => {
    const { t } = useTranslation();
    const { activeCardType, setActiveCardType, setAppleCardIsFlipped, setGoogleCardIsFlipped, markReadyToSave, backside, setBackside, setIsElementClick, countryCode = '', } = useWalletCardForm();
    const handleFocus = () => {
        if (activeCardType === 'notify')
            setActiveCardType('apple');
        setAppleCardIsFlipped(true);
        setGoogleCardIsFlipped(true);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (index, newItem) => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { contacts: backside.contacts.map((item, i) => i === index ? newItem : item) })));
    };
    const handleReorder = (contacts) => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { contacts })));
        markReadyToSave();
        handleFocus();
    };
    return (_jsx(DragDrop, { draggableClassName: styles.contactItem, items: backside.contacts, getItemKey: (item, i) => `item-${i}`, renderItem: (item, i) => {
            let icon = _jsx(Globe, {});
            if (item.type === 'email')
                icon = _jsx(Email, {});
            if (item.type === 'phone')
                icon = _jsx(Phone, {});
            return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.contactIcon }, { children: icon })), _jsx("div", Object.assign({ className: styles.contactTitleInput }, { children: _jsx(Input, { id: `backside-contact-${i}-title`, placeholder: t('название'), value: item.title, onChange: (e) => {
                                handleChange(i, Object.assign(Object.assign({}, item), { title: e.target.value }));
                            }, onFocus: handleFocus, onBlur: markReadyToSave }) })), _jsx("div", Object.assign({ className: styles.contactContentInput }, { children: _jsx(Input, { id: `backside-contact-${i}-value`, placeholder: t('значение'), value: item.content, onChange: (e) => {
                                const content = e.target.value;
                                const isEmail = isValidEmail(content);
                                const isPhone = isValidPhoneNumber(content, countryCode);
                                const type = isEmail
                                    ? 'email'
                                    : isPhone
                                        ? 'phone'
                                        : 'link';
                                handleChange(i, Object.assign(Object.assign({}, item), { type, content }));
                            }, onFocus: handleFocus, onBlur: markReadyToSave }) }))] }));
        }, createItem: () => ({ title: '', content: '' }), onReorder: handleReorder }));
};
