var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from '@/hooks/useDebounce';
import { AutocompleteContext, } from './AutocompleteContext';
export const AutocompleteProvider = (_a) => {
    var { children, options: optionsProvider } = _a, contextValueFromProps = __rest(_a, ["children", "options"]);
    const { id, value, onChange, getOptionKey, getOptionLabel, debounceDelay, shouldLoadInitialData, } = contextValueFromProps;
    const [searchText, setSerchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(Array.isArray(optionsProvider) ? optionsProvider : []);
    const wrapperRef = useRef(null);
    const handleGetOptions = useCallback((searchText) => __awaiter(void 0, void 0, void 0, function* () {
        if (Array.isArray(optionsProvider))
            return;
        setLoading(true);
        const options = yield optionsProvider(searchText);
        setOptions(options);
        setLoading(false);
    }), []);
    useDebounce({
        value: searchText,
        callback: handleGetOptions,
        delay: debounceDelay,
    });
    useEffect(() => {
        if (shouldLoadInitialData)
            handleGetOptions('');
    }, []);
    const autocompleteInstance = useAutocomplete({
        id,
        value,
        onChange: (_, value) => onChange(value),
        options,
        isOptionEqualToValue: (option, value) => getOptionKey(option) === getOptionKey(value),
        getOptionLabel,
        filterOptions: Array.isArray(optionsProvider)
            ? undefined
            : (option) => option,
        inputValue: searchText,
        onInputChange: (e, value) => setSerchText(value),
        openOnFocus: true,
    });
    const filteredOptions = autocompleteInstance.groupedOptions;
    const contextValue = Object.assign(Object.assign(Object.assign({}, contextValueFromProps), { options,
        filteredOptions,
        searchText,
        loading,
        wrapperRef }), autocompleteInstance);
    return (_jsx(AutocompleteContext.Provider, Object.assign({ value: contextValue }, { children: children })));
};
