export const fileToDataURL = (file) => {
    return new Promise((resolve) => {
        if (!file) {
            resolve('');
            return;
        }
        if (file instanceof File) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const dataURI = reader.result;
                resolve(dataURI);
            });
            reader.readAsDataURL(file);
            return;
        }
        resolve(file.url);
    });
};
