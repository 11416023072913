import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormMailingViberButton = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, trigger, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const viberUrl = watch('viberUrl');
    const viberImage = watch('viberImage');
    const isRequired = (channels === null || channels === void 0 ? void 0 : channels.includes('viber')) && (!!viberUrl || !!viberImage);
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const maxLength = 20;
    useEffect(() => {
        if (isSubmitted)
            trigger('viberButton');
    }, [isSubmitted, viberUrl, viberImage]);
    return (_jsx(FormFieldRow, Object.assign({ label: t('Кнопка'), labelFor: 'viberButton', labelWidth: labelWidth, controlSize: '200', help: (_a = errors.viberButton) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.viberButton, isRequired: isRequired }, { children: _jsx(Controller, { name: 'viberButton', control: control, rules: {
                validate: validateTrimValue,
                maxLength: (channels === null || channels === void 0 ? void 0 : channels.includes('viber'))
                    ? {
                        value: maxLength,
                        message: t('FIELD_MAX_LENGTH_ERROR', {
                            amount: numberRound(maxLength),
                            count: maxLength,
                        }),
                    }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'viberButton', placeholder: t('введите текст кнопки'), hasError: !!errors.viberButton, autoFocus: true }))) }) })));
};
