import { jsx as _jsx } from "react/jsx-runtime";
import { EditorToolbar as EditorToolbarComponent } from '@/components/rich-text/EditorToolbar/EditorToolbar';
import { EmojiPopup } from '../EmojiPlugin/EmojiPopup';
import { LinkPopup } from '../LinkPlugin/LinkPopup';
import { useLinkPopup } from '../LinkPlugin/useLinkPopup';
import { VarsPopup } from '../VariablePlugin/VarsPopup';
export const EditorToolbar = ({ variables, varsPopupType, hasSmallVarsPopupIcon = false, hasLinkSupport, hasEmojiSupport, isRounded, currency, }) => {
    const { title, setTitle, link, setLink, linkActive, highlightActiveLink, resetLinks, } = useLinkPopup();
    return (_jsx(EditorToolbarComponent, { emojiPopup: hasEmojiSupport ? _jsx(EmojiPopup, {}) : undefined, varsPopup: _jsx(VarsPopup, { type: varsPopupType, variables: variables, currency: currency }), hasSmallVarsPopupIcon: hasSmallVarsPopupIcon, linkPopup: hasLinkSupport ? (_jsx(LinkPopup, { title: title, onChangeTitle: setTitle, link: link, onChangeLink: setLink })) : undefined, linkActive: linkActive, onOpenLinkPopup: highlightActiveLink, onCloseLinkPopup: resetLinks, isRounded: isRounded }));
};
