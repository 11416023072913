import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { FormUsersAvailableTo } from './controls/FormUsersAvailableTo';
import { FormUsersComment } from './controls/FormUsersComment';
import { FormUsersCopyUserData } from './controls/FormUsersCopyUserData';
import { FormUsersEmail } from './controls/FormUsersEmail';
import { FormUsersName } from './controls/FormUsersName';
import { FormUsersPassword } from './controls/FormUsersPassword';
import { FormUsersPasswordType, PASSWORD_CREATE_OPTIONS, PASSWORD_EDIT_OPTIONS, PASSWORD_TYPES, } from './controls/FormUsersPasswordType';
import { FormUsersRole } from './controls/FormUsersRole';
import { FormUsersShopConditions } from './controls/FormUsersShopConditions';
import { FormUsersUsername } from './controls/FormUsersUsername';
export const FormUsers = memo(({ id, onSubmit, setHasFormChanged, defaultValues = {}, roles, isEdit }) => {
    var _a;
    const { t } = useTranslation();
    const methods = useForm({
        defaultValues: Object.assign(Object.assign({}, defaultValues), { operatorRoleId: (_a = defaultValues.operatorRole) === null || _a === void 0 ? void 0 : _a.id }),
    });
    const { isSubmitting, dirtyFields } = methods.formState;
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [isSubmitting, dirtyFields]);
    const [passwordType, setPasswordType] = useState(isEdit ? PASSWORD_EDIT_OPTIONS[0].id : PASSWORD_CREATE_OPTIONS[0].id);
    const isPasswordSetManually = passwordType === PASSWORD_TYPES.SET_MANUALLY;
    const isPasswordSendUpdateLink = passwordType === PASSWORD_TYPES.SEND_UPDATE_LINK;
    const isPasswordSetByUser = passwordType === PASSWORD_TYPES.SET_BY_USER;
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", Object.assign({ id: id, onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Для входа в систему') }, { children: [_jsx(FormUsersUsername, { isEdit: isEdit }), _jsx(FormUsersEmail, { isRequired: isPasswordSendUpdateLink || isPasswordSetByUser }), _jsx(FormUsersPasswordType, { value: passwordType, onChange: setPasswordType, isEdit: isEdit }), isPasswordSetManually && (_jsxs(_Fragment, { children: [_jsx(FormUsersPassword, { isEdit: isEdit }), _jsx(FormUsersCopyUserData, {})] }))] })), _jsxs(FormFieldset, Object.assign({ title: t('Для отображения в системе') }, { children: [_jsx(FormUsersName, {}), _jsx(FormUsersRole, { roles: roles }), _jsx(FormUsersShopConditions, {}), _jsx(FormUsersAvailableTo, {}), _jsx(FormUsersComment, {})] }))] })) })));
});
