import { api } from './';
export const bonusApi = api.injectEndpoints({
    endpoints: (build) => ({
        correctClientBonuses: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/bonus/correct',
                body,
            }),
            invalidatesTags: ['Client', 'ClientList'],
        }),
    }),
});
export const { useCorrectClientBonusesMutation } = bonusApi;
