import { useCallback, useState } from 'react';
export const useModalState = () => {
    const [purchaseDetailsModalIsOpen, setPurchaseDetailsModalIsOpen] = useState(false);
    const [addPurchaseModalIsOpen, setAddPurchaseModalIsOpen] = useState(false);
    const [orderDetailsModalIsOpen, setOrderDetailsModalIsOpen] = useState(false);
    const [orderCancelledDetailsModalIsOpen, setOrderCancelledDetailsModalIsOpen,] = useState(false);
    return {
        purchases: {
            detailsModal: {
                isOpen: purchaseDetailsModalIsOpen,
                open: useCallback(() => setPurchaseDetailsModalIsOpen(true), []),
                close: useCallback(() => setPurchaseDetailsModalIsOpen(false), []),
            },
            addModal: {
                isOpen: addPurchaseModalIsOpen,
                open: useCallback(() => setAddPurchaseModalIsOpen(true), []),
                close: useCallback(() => setAddPurchaseModalIsOpen(false), []),
            },
        },
        orders: {
            detailsModal: {
                isOpen: orderDetailsModalIsOpen,
                open: useCallback(() => setOrderDetailsModalIsOpen(true), []),
                close: useCallback(() => setOrderDetailsModalIsOpen(false), []),
            },
        },
        ordersCancelled: {
            detailsModal: {
                isOpen: orderCancelledDetailsModalIsOpen,
                open: useCallback(() => setOrderCancelledDetailsModalIsOpen(true), []),
                close: useCallback(() => setOrderCancelledDetailsModalIsOpen(false), []),
            },
        },
    };
};
