import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import styles from '../styles.module.scss';
export const FormUsersPasswordType = ({ value, onChange, isEdit, }) => {
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const passwordTypeOptions = isEdit
        ? PASSWORD_EDIT_OPTIONS
        : PASSWORD_CREATE_OPTIONS;
    const isPasswordSetManually = value === PASSWORD_TYPES.SET_MANUALLY;
    const isPasswordSendUpdateLink = value === PASSWORD_TYPES.SEND_UPDATE_LINK;
    const isPasswordSetByUser = value === PASSWORD_TYPES.SET_BY_USER;
    useEffect(() => {
        setValue('isSendToEmail', isPasswordSendUpdateLink || isPasswordSetByUser);
    }, [isPasswordSendUpdateLink, isPasswordSetByUser]);
    return (_jsx(FormFieldRow, Object.assign({ label: t('Пароль'), labelFor: 'password', controlSize: '250', help: (isPasswordSendUpdateLink || isPasswordSetByUser) &&
            t('На почту пользователя отправится ссылка на создание пароля'), helpSize: '250', helpClassName: styles.passwordTypeHelpText, isRequired: isPasswordSetManually }, { children: _jsx(Autocomplete, { className: styles.shortAutocomplete, value: passwordTypeOptions.find((item) => item.id === value) || null, onChange: (value) => {
                if (value)
                    onChange(value.id);
            }, options: passwordTypeOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => t(option.label), hasResetButton: false, asSelect: true }) })));
};
export const PASSWORD_TYPES = {
    SET_BY_USER: 'setByUser',
    SET_MANUALLY: 'setManually',
    NO_CHANGE: 'noChange',
    SEND_UPDATE_LINK: 'sendUpdateLink',
};
export const PASSWORD_CREATE_OPTIONS = [
    {
        id: PASSWORD_TYPES.SET_BY_USER,
        label: 'задает пользователь',
    },
    {
        id: PASSWORD_TYPES.SET_MANUALLY,
        label: 'задать вручную',
    },
];
export const PASSWORD_EDIT_OPTIONS = [
    {
        id: PASSWORD_TYPES.NO_CHANGE,
        label: 'без изменений',
    },
    {
        id: PASSWORD_TYPES.SEND_UPDATE_LINK,
        label: 'отправить ссылку для обновления',
    },
    {
        id: PASSWORD_TYPES.SET_MANUALLY,
        label: 'задать новый вручную',
    },
];
