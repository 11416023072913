import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { WalletCardField } from '@/components/wallet-cards/WalletCardField/WalletCardField';
import styles from './styles.module.scss';
export const GoogleWalletCardFields = ({ fields, onElementClick, clicksDisabled = false, }) => {
    const firstRow = [1, 2, 3];
    const secondRow = [4, 5, 6];
    const thirdRow = [7, 8, 9];
    const renderRow = (row) => {
        const content = row.map((n) => {
            const key = `field-${n}`;
            const field = fields === null || fields === void 0 ? void 0 : fields[key];
            if (!(field === null || field === void 0 ? void 0 : field.title) && !(field === null || field === void 0 ? void 0 : field.value))
                return null;
            return (_jsx(WalletCardClickableElement, Object.assign({ onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(key), disabled: clicksDisabled }, { children: _jsx(WalletCardField, { title: field === null || field === void 0 ? void 0 : field.title, value: field === null || field === void 0 ? void 0 : field.value }) }), key));
        });
        return _jsx("div", Object.assign({ className: styles.fieldsRow }, { children: content }));
    };
    const hasField = (n) => {
        const key = `field-${n}`;
        const field = fields === null || fields === void 0 ? void 0 : fields[key];
        return !!(field === null || field === void 0 ? void 0 : field.title) || !!(field === null || field === void 0 ? void 0 : field.value);
    };
    const hasSecondRow = secondRow.some(hasField);
    const hasThirdRow = thirdRow.some(hasField);
    const hasFirstRow = firstRow.some(hasField);
    const hasRows = hasFirstRow || hasSecondRow || hasThirdRow;
    return hasRows ? (_jsxs("div", Object.assign({ className: styles.fieldsWrapper }, { children: [hasFirstRow ? renderRow(firstRow) : null, hasSecondRow ? renderRow(secondRow) : null, hasThirdRow ? renderRow(thirdRow) : null] }))) : (_jsx("div", Object.assign({ className: styles.fieldsWrapper }, { children: _jsxs("div", Object.assign({ className: classNames(styles.fieldsRow, styles.exampleFieldsRow) }, { children: [_jsx(WalletCardClickableElement, Object.assign({ onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('field-1'), disabled: clicksDisabled }, { children: _jsx(WalletCardField, {}) }), 'field-1'), _jsx(WalletCardClickableElement, Object.assign({ onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('field-2'), disabled: clicksDisabled }, { children: _jsx(WalletCardField, {}) }), 'field-2'), _jsx(WalletCardClickableElement, Object.assign({ onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('field-3'), disabled: clicksDisabled }, { children: _jsx(WalletCardField, {}) }), 'field-3')] })) })));
};
