import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLazyGetMailingStatsQuery } from '@/api/query/mailings';
import { useModalLoading } from '@/hooks/useModalLoading';
import { getPresetPeriod, PresetPeriod } from '@/utils/getPresetPeriod';
const month = getPresetPeriod(PresetPeriod.Month);
const defaultStart = month[0].toDate();
const defaultEnd = month[1].toDate();
export const useMailingModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [mailing, setMailing] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [periodStart, setPeriodStart] = useState(defaultStart);
    const [periodEnd, setPeriodEnd] = useState(defaultEnd);
    const [fetchStats, { data: stats, isFetching }] = useLazyGetMailingStatsQuery();
    const openModal = (m) => {
        setMailing(m);
        setIsLoading(true);
        fetchStats({
            id: m.id,
            periodStart: moment(periodStart).format(),
            periodEnd: moment(periodEnd).format(),
        });
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const changePeriod = (start, end) => {
        if (start)
            setPeriodStart(start);
        if (end)
            setPeriodEnd(end);
        if (mailing === null || mailing === void 0 ? void 0 : mailing.id)
            fetchStats({
                id: mailing.id,
                periodStart: moment(start || periodStart).format(),
                periodEnd: moment(end || periodEnd).format(),
            });
    };
    useModalLoading(isLoading);
    useEffect(() => {
        if (!isFetching)
            setIsLoading(false);
    }, [isFetching]);
    return {
        isModalOpen: isOpen,
        openModal,
        closeModal,
        selectedMailing: !isLoading ? mailing : null,
        stats,
        periodStart,
        periodEnd,
        changePeriod,
        isStatsLoading: isFetching,
    };
};
