import { $getSelection, $isRangeSelection, } from 'lexical';
import { textToNodes } from '../../utils/textToNodes';
const pasteVariables = (variables) => (event, editor) => {
    const selection = $getSelection();
    if (!selection || !$isRangeSelection(selection))
        return false;
    const clipboardData = event instanceof InputEvent || event instanceof KeyboardEvent
        ? null
        : event.clipboardData;
    if (!clipboardData)
        return false;
    event.preventDefault();
    editor.update(() => {
        const text = clipboardData.getData('Text');
        selection.insertNodes(textToNodes(text, variables));
    });
    return true;
};
export default pasteVariables;
