import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SegmentList } from '@/components/SegmentList/SegmentList';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
const MAX_SEGMENTS_TO_SHOW = 3;
export const MailingRecipients = ({ recipients, excludedRecipients, onSegmentClick, }) => {
    var _a;
    const { t } = useTranslation();
    const shouldDisplayExcluded = excludedRecipients &&
        excludedRecipients.segments &&
        excludedRecipients.segments.length > 0;
    const hasSegments = !!((_a = recipients === null || recipients === void 0 ? void 0 : recipients.segments) === null || _a === void 0 ? void 0 : _a.length);
    const shouldDisplaySegmentBadges = hasSegments && recipients.segments.length < MAX_SEGMENTS_TO_SHOW;
    const shouldDisplayFilters = !(recipients === null || recipients === void 0 ? void 0 : recipients.all) &&
        (recipients === null || recipients === void 0 ? void 0 : recipients.filters) &&
        (!recipients.segments || recipients.filters.configuration.length > 1);
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [(recipients === null || recipients === void 0 ? void 0 : recipients.all) && _jsx("span", { children: t('Все клиенты') }), !(recipients === null || recipients === void 0 ? void 0 : recipients.all) && hasSegments && (_jsxs("span", { children: [shouldDisplaySegmentBadges && (_jsx(SegmentList, { segments: recipients.segments, onSegmentClick: onSegmentClick, hasMaxWidth: false })), !shouldDisplaySegmentBadges && (_jsx(Tooltip, Object.assign({ title: _jsx(SegmentList, { segments: recipients.segments, onSegmentClick: onSegmentClick }), placement: 'top' }, { children: _jsx("span", Object.assign({ className: classNames(styles.tooltipTarget, styles.nowrap) }, { children: t('MAILING_RECIPIENTS_SEGMENTS', {
                                amount: numberRound(recipients.segments.length),
                                count: recipients.segments.length,
                            }) })) })))] })), shouldDisplayExcluded && (_jsx(Tooltip, Object.assign({ title: _jsx(SegmentList, { segments: excludedRecipients.segments, onSegmentClick: onSegmentClick }), placement: 'top' }, { children: _jsx("span", Object.assign({ className: styles.tooltipTarget }, { children: t('исключения') })) }))), shouldDisplayFilters && (_jsx(Tooltip, Object.assign({ title: _jsx("ul", Object.assign({ className: styles.filters }, { children: recipients.filters.configuration
                        .filter((i) => { var _a; return (_a = i === null || i === void 0 ? void 0 : i.text) === null || _a === void 0 ? void 0 : _a.trim(); })
                        .map(({ text }) => (_jsx("li", { dangerouslySetInnerHTML: { __html: text } }, text))) })), placement: 'top' }, { children: _jsx("span", Object.assign({ className: styles.tooltipTarget }, { children: t('фильтры') })) })))] })));
};
