import { useTranslation } from 'react-i18next';
import { TRIGGER_TYPE_ABANDONED_CART, TRIGGER_TYPE_BONUS_ACTIVATE, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, TRIGGER_TYPE_PRICE_IN_CART_DROPPED, TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED, TRIGGER_TYPE_PURCHASE, TRIGGER_TYPE_RETURN, } from '@/const/mailing-triggers';
import { dateSample } from '../utils/dateSample';
// CLIENT ----------------------------------------------------------------------
const useClientVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('ИмяКлиента'),
            label: t('CUSTOMER_NAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_NAME'),
            group: 'client',
            key: 'clientName',
        },
        {
            id: t('ИмяОтчествоКлиента'),
            label: t('CUSTOMER_FULLNAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_FULLNAME'),
            group: 'client',
            key: 'clientNamePatronymic',
        },
        {
            id: t('ФамилияКлиента'),
            label: t('CUSTOMER_LASTNAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_LASTNAME'),
            group: 'client',
            key: 'clientSurname',
        },
        {
            id: t('ОтчествоКлиента'),
            label: t('CUSTOMER_MIDDLENAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_MIDDLENAME'),
            group: 'client',
            key: 'clientPatronymic',
        },
    ];
};
// CHILD -----------------------------------------------------------------------
const useChildVariables = ({ triggerType, all, }) => {
    const { t, i18n } = useTranslation();
    const childVariables = [
        {
            id: t('ИмяРебенкаКто'),
            label: t('Имя ребенка в им. падеже (кто?)'),
            sample: t('VAR_SAMPLE_CHILD_NAME'),
            group: 'client',
            key: 'childName',
        },
    ];
    if (i18n.language === 'ru')
        childVariables.push({
            id: t('ИмяРебенкаКому'),
            label: t('Имя ребенка в дат. падеже (кому?)'),
            sample: 'Кате',
            group: 'client',
            key: 'childNameDat',
        }, {
            id: t('ИмяРебенкаКого'),
            label: t('Имя ребенка в винит. падеже (кого?)'),
            sample: 'Катю',
            group: 'client',
            key: 'childNameVin',
        });
    return triggerType === TRIGGER_TYPE_CHILD_BIRTHDAY || all
        ? childVariables
        : [];
};
// BONUSES ---------------------------------------------------------------------
const useBonusVariables = ({ triggerType, bonusesAmount, all, }) => {
    const { t } = useTranslation();
    const bonusVariables = [];
    if (triggerType === TRIGGER_TYPE_BONUS_EXPIRING || all)
        bonusVariables.push({
            id: t('СгораетБонусов'),
            label: t('сгорает бонусов'),
            sample: 150,
            group: 'bonus',
            key: 'expiringBonusAmount',
        });
    bonusVariables.push({
        id: t('ВсегоБонусов'),
        label: t('всего бонусов'),
        sample: 600,
        group: 'bonus',
        key: 'clientBonuses',
    }, {
        id: t('БлижайшаяДатаСгоранияБонусов'),
        label: t('ближайшая дата сгорания бонусов'),
        sample: dateSample,
        sampleType: 'date',
        group: 'bonus',
        key: 'expressExpiredAt',
    });
    if (triggerType === TRIGGER_TYPE_PURCHASE ||
        triggerType === TRIGGER_TYPE_BONUS_ACTIVATE ||
        all)
        bonusVariables.push({
            id: t('НачисленоБонусовЗаПокупку'),
            label: t('начислено бонусов за покупку'),
            sample: 150,
            group: 'bonus',
            key: 'purchaseBonusAmount',
        }, {
            id: t('ДатаСгоранияБонусовЗаПокупку'),
            label: t('дата сгорания бонусов за покупку'),
            sample: dateSample,
            sampleType: 'date',
            group: 'bonus',
            key: 'purchaseBonusExpiredAt',
        });
    if (triggerType === TRIGGER_TYPE_PURCHASE || all)
        bonusVariables.push({
            id: t('СуммаДоУровня'),
            label: t('сумма покупок до следующего уровня'),
            sample: 2350,
            sampleType: 'price',
            group: 'bonus',
            key: 'amountToNextLevel',
        });
    if (triggerType === TRIGGER_TYPE_RETURN || all)
        bonusVariables.push({
            id: t('ОтмененоБонусовЗаВозврат'),
            label: t('отменено бонусов за возврат'),
            sample: 150,
            group: 'bonus',
            key: 'returnBonusesCancelled',
        });
    if (Number(bonusesAmount) > 0 || all)
        bonusVariables.push({
            id: t('НачисленоБонусовПоощрения'),
            label: t('начислено бонусов поощрения'),
            sample: 150,
            group: 'bonus',
            key: 'bonusAmount',
        }, {
            id: t('ДатаСгоранияБонусовПоощрения'),
            label: t('дата сгорания бонусов поощрения'),
            sample: dateSample,
            sampleType: 'date',
            group: 'bonus',
            key: 'bonusExpiredAt',
        });
    return bonusVariables;
};
// PRODUCT ---------------------------------------------------------------------
const useProductVariables = ({ channel, triggerType, recommendationType, all, }) => {
    const { t } = useTranslation();
    const productVariables = [];
    if (!all && channel !== 'email')
        return productVariables;
    const productTitle = {
        id: t('НазваниеТовара'),
        label: t('название товара'),
        sample: t('VAR_SAMPLE_PRODUCT_TITLE'),
        group: 'products',
        key: 'productTitle',
    };
    const productImage = {
        id: t('КартинкаТовара'),
        label: t('картинка товара'),
        sample: 'https://s.maxma.com/m/product_image/product1.jpg',
        sampleType: 'image',
        group: 'products',
        key: 'productImage',
    };
    const productPrice = {
        id: t('ЦенаТовара'),
        label: t('цена товара'),
        sample: 6899,
        sampleType: 'price',
        group: 'products',
        key: 'productPrice',
    };
    const productUrl = {
        id: t('СсылкаНаТовар'),
        label: t('ссылка на товар'),
        sample: 'https://maxma.com',
        sampleType: 'link',
        group: 'products',
        key: 'productUrl',
    };
    const productOldPrice = {
        id: t('СтараяЦенаТовара'),
        label: t('старая цена товара'),
        sample: 7399,
        sampleType: 'price',
        group: 'products',
        key: 'productOldPrice',
    };
    if (triggerType == TRIGGER_TYPE_ABANDONED_CART ||
        triggerType == TRIGGER_TYPE_PRICE_IN_CART_DROPPED ||
        triggerType == TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED ||
        all)
        productVariables.push(productTitle, productImage, productPrice, productUrl);
    if (triggerType == TRIGGER_TYPE_PRICE_IN_CART_DROPPED ||
        triggerType == TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED ||
        all)
        productVariables.push(productOldPrice);
    if (recommendationType)
        productVariables.push(Object.assign(Object.assign({}, productTitle), { group: 'recommendedProducts' }), Object.assign(Object.assign({}, productImage), { group: 'recommendedProducts' }), Object.assign(Object.assign({}, productPrice), { group: 'recommendedProducts' }), Object.assign(Object.assign({}, productUrl), { group: 'recommendedProducts' }));
    return productVariables;
};
// OTHER -----------------------------------------------------------------------
const useOtherVariables = ({ channel, hasWalletFeature, promocodeId, giftCardId, all, }) => {
    const { t } = useTranslation();
    const otherVariables = [];
    if (promocodeId || all)
        otherVariables.push({
            id: t('Промокод'),
            label: t('промокод'),
            sample: 'CODE-XXX',
            key: 'promocode',
        });
    if (!(!hasWalletFeature || channel === 'push' || channel === 'email') || all)
        otherVariables.push({
            id: t('СсылкаЭлКошелька'),
            label: t('ссылка на эл.кошелек'),
            sample: 'cl7.ru/no3B',
            sampleType: 'link',
            key: 'walletsShortLink',
        });
    if (giftCardId || all)
        otherVariables.push({
            id: t('КодПодарочнойКарты'),
            label: t('код подарочной карты'),
            sample: 'T2FXXXX',
            key: 'giftCard',
        });
    return otherVariables;
};
export const useMessageEditorVariables = (context = {}) => {
    const { t } = useTranslation();
    const { triggerType, rewards, channel, hasWalletFeature, recommendationType, } = context;
    const { bonusesAmount, promocodeId, giftCardId } = rewards || {};
    const clientVariables = useClientVariables();
    const childVariables = useChildVariables({ triggerType });
    const allChildVariables = useChildVariables({ all: true });
    const bonusVariables = useBonusVariables({ triggerType, bonusesAmount });
    const allBonusVariables = useBonusVariables({ all: true });
    const productVariables = useProductVariables({
        triggerType,
        channel,
        recommendationType,
    });
    const allProductVariables = useProductVariables({ all: true });
    const otherVariables = useOtherVariables({
        channel,
        hasWalletFeature,
        promocodeId,
        giftCardId,
    });
    const allOtherVariables = useOtherVariables({ all: true });
    return {
        groups: [
            clientVariables.length
                ? { title: t('клиент'), variables: clientVariables }
                : null,
            childVariables.length
                ? { title: t('ребенок'), variables: childVariables }
                : null,
            bonusVariables.length
                ? { title: t('бонусы'), variables: bonusVariables }
                : null,
            productVariables.length
                ? { title: t('товар'), variables: productVariables }
                : null,
            ...otherVariables,
        ].filter(Boolean),
        variables: [
            ...clientVariables,
            ...childVariables,
            ...bonusVariables,
            ...productVariables,
            ...otherVariables,
        ],
        allVariables: [
            ...clientVariables,
            ...allChildVariables,
            ...allBonusVariables,
            ...allProductVariables,
            ...allOtherVariables,
        ],
    };
};
