const TRIGGER_TYPE_SEGMENT_JOIN = 0;
const TRIGGER_TYPE_SEGMENT_LEAVE = 1;
const TRIGGER_TYPE_BONUS_ACTIVATE = 2;
const TRIGGER_TYPE_RETURN = 3;
const TRIGGER_TYPE_BIRTHDAY = 4;
const TRIGGER_TYPE_BONUS_EXPIRING = 5;
const TRIGGER_TYPE_PURCHASE = 6;
const TRIGGER_TYPE_CHILD_BIRTHDAY = 7;
const TRIGGER_TYPE_ABANDONED_CART = 8;
const TRIGGER_TYPE_PRODUCT_VIEW = 9;
const TRIGGER_TYPE_PRICE_IN_CART_DROPPED = 10;
const TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED = 11;
export const triggerDescriptions = {
    [TRIGGER_TYPE_SEGMENT_JOIN]: 'Попадание в сегмент',
    [TRIGGER_TYPE_SEGMENT_LEAVE]: 'Покидание сегмента',
    [TRIGGER_TYPE_BONUS_ACTIVATE]: 'Начисление бонусов за покупку',
    [TRIGGER_TYPE_RETURN]: 'Возврат товара',
    [TRIGGER_TYPE_BIRTHDAY]: 'День рождения',
    [TRIGGER_TYPE_BONUS_EXPIRING]: 'Сгорание бонусов',
    [TRIGGER_TYPE_PURCHASE]: 'Совершение покупки',
    [TRIGGER_TYPE_CHILD_BIRTHDAY]: 'День рождения ребёнка',
    [TRIGGER_TYPE_ABANDONED_CART]: 'Брошенная корзина',
    [TRIGGER_TYPE_PRODUCT_VIEW]: 'Просмотр товара или категории',
    [TRIGGER_TYPE_PRICE_IN_CART_DROPPED]: 'Цена на товар в корзине снижена',
    [TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED]: 'Цена на товар в избранном снижена',
};
