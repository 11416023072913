import { jsx as _jsx } from "react/jsx-runtime";
import { Billing } from '@/app/pages/Billing/Billing';
import { BillingInvoices } from '@/app/pages/BillingInvoices/BillingInvoices';
import { Clients } from '@/app/pages/Clients/Clients';
import { Dashboard } from '@/app/pages/Dashboard/Dashboard';
import { Events } from '@/app/pages/Events/Events';
import { Exports } from '@/app/pages/Exports/Exports';
import { Flows } from '@/app/pages/Flows/Flows';
import { FlowsForm } from '@/app/pages/FlowsForm/FlowsForm';
import { GiftCards } from '@/app/pages/GiftCards/GiftCards';
import { GiftCardsForm } from '@/app/pages/GiftCardsForm/GiftCardsForm';
import { GiftCardsInstances } from '@/app/pages/GiftCardsInstances/GiftCardsInstances';
import { Imports } from '@/app/pages/Imports/Imports';
import { ImportFormContainer } from '@/app/pages/ImportsForm/ImportFormContainer';
import { Mailings } from '@/app/pages/Mailings/Mailings';
import { MailingsForm } from '@/app/pages/MailingsForm/MailingsForm';
import { Offers } from '@/app/pages/Offers/Offers';
import { OffersForm } from '@/app/pages/OffersForm/OffersForm';
import { Promocodes } from '@/app/pages/Promocodes/Promocodes';
import { PromocodesForm } from '@/app/pages/PromocodesForm/PromocodesForm';
import { Purchases } from '@/app/pages/Purchases/Purchases';
import { Reports } from '@/app/pages/Reports/Reports';
import { RFM } from '@/app/pages/RFM/RFM';
import { ROUTES } from '@/app/pages/routes';
import { Segments } from '@/app/pages/Segments/Segments';
import { Shops } from '@/app/pages/Shops/Shops';
import { Users } from '@/app/pages/Users/Users';
import { WalletCards } from '@/app/pages/WalletCards/WalletCards';
import { WalletCardsForm } from '@/app/pages/WalletCardsForm/WalletCardsForm';
import { WalletGeoPushForm } from '@/app/pages/WalletGeoPushForm/WalletGeoPushForm';
export const routesList = (hasInn, userHasAccessTo) => [
    //  Analytics
    {
        iaAvailable: userHasAccessTo('DASHBOARD_VIEW'),
        path: ROUTES.DASHBOARD,
        element: _jsx(Dashboard, {}),
    },
    {
        iaAvailable: userHasAccessTo('SMARTRFM_VIEW'),
        path: ROUTES.RFM,
        element: _jsx(RFM, {}),
    },
    {
        iaAvailable: userHasAccessTo(['REPORTS_MANAGE', 'REPORTS_VIEW']),
        path: ROUTES.REPORTS,
        element: _jsx(Reports, {}),
    },
    {
        iaAvailable: userHasAccessTo('EXPORTS_MANAGE'),
        path: ROUTES.EXPORTS,
        element: _jsx(Exports, {}),
    },
    //  Imports
    {
        iaAvailable: true,
        path: ROUTES.IMPORTS,
        element: _jsx(Imports, {}),
    },
    {
        iaAvailable: true,
        path: ROUTES.IMPORTS_EDIT,
        element: _jsx(ImportFormContainer, {}),
    },
    //  Customers
    {
        iaAvailable: true,
        path: ROUTES.EVENTS,
        element: _jsx(Events, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'CLIENTS_LIST_OWN',
            'CLIENTS_LIST',
            'CLIENTS_MANAGE_OWN',
            'CLIENTS_MANAGE',
        ]),
        path: ROUTES.CLIENTS,
        element: _jsx(Clients, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'CLIENTS_SEGMENTS_LIST',
            'CLIENTS_SEGMENTS_MANAGE',
        ]),
        path: ROUTES.SEGMENTS,
        element: _jsx(Segments, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'CLIENTS_PURCHASES_LIST',
            'CLIENTS_PURCHASES_MANAGE',
        ]),
        path: ROUTES.PURCHASES,
        element: _jsx(Purchases, {}),
    },
    {
        iaAvailable: userHasAccessTo(['FLOWS_LIST', 'FLOWS_MANAGE']),
        path: ROUTES.FLOWS,
        element: _jsx(Flows, {}),
    },
    {
        iaAvailable: userHasAccessTo(['FLOWS_LIST', 'FLOWS_MANAGE']),
        path: ROUTES.FLOWS_CREATE,
        element: _jsx(FlowsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo(['FLOWS_LIST', 'FLOWS_MANAGE']),
        path: ROUTES.FLOWS_EDIT,
        element: _jsx(FlowsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'AUTO_MAILINGS_LIST',
            'AUTO_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_AUTOMATIC,
        element: _jsx(Mailings, { isManual: false }),
    },
    {
        iaAvailable: userHasAccessTo([
            'AUTO_MAILINGS_LIST',
            'AUTO_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_AUTOMATIC_CREATE,
        element: _jsx(MailingsForm, { isManual: false }),
    },
    {
        iaAvailable: userHasAccessTo([
            'AUTO_MAILINGS_LIST',
            'AUTO_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_AUTOMATIC_EDIT,
        element: _jsx(MailingsForm, { isManual: false }),
    },
    //  Manual Mailings
    {
        iaAvailable: userHasAccessTo([
            'MANUAL_MAILINGS_LIST',
            'MANUAL_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_MANUAL,
        element: _jsx(Mailings, { isManual: true }),
    },
    {
        iaAvailable: userHasAccessTo([
            'MANUAL_MAILINGS_LIST',
            'MANUAL_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_MANUAL_CREATE,
        element: _jsx(MailingsForm, { isManual: true }),
    },
    {
        iaAvailable: userHasAccessTo([
            'MANUAL_MAILINGS_LIST',
            'MANUAL_MAILINGS_MANAGE',
        ]),
        path: ROUTES.MAILINGS_MANUAL_EDIT,
        element: _jsx(MailingsForm, { isManual: true }),
    },
    //  Wallet Cards
    {
        iaAvailable: userHasAccessTo(['WALLET_CARDS_LIST', 'WALLET_CARDS_MANAGE']),
        path: ROUTES.WALLET_CARDS,
        element: _jsx(WalletCards, {}),
    },
    {
        iaAvailable: userHasAccessTo(['WALLET_CARDS_LIST', 'WALLET_CARDS_MANAGE']),
        path: ROUTES.WALLET_CARDS_CREATE,
        element: _jsx(WalletCardsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo(['WALLET_CARDS_LIST', 'WALLET_CARDS_MANAGE']),
        path: ROUTES.WALLET_CARDS_EDIT,
        element: _jsx(WalletCardsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo(['WALLET_CARDS_LIST', 'WALLET_CARDS_MANAGE']),
        path: ROUTES.GEO_PUSH_CREATE,
        element: _jsx(WalletGeoPushForm, {}),
    },
    {
        iaAvailable: userHasAccessTo(['WALLET_CARDS_LIST', 'WALLET_CARDS_MANAGE']),
        path: ROUTES.GEO_PUSH_EDIT,
        element: _jsx(WalletGeoPushForm, {}),
    },
    //  Offers
    {
        iaAvailable: userHasAccessTo(['OFFERS_LIST', 'OFFERS_MANAGE']),
        path: ROUTES.OFFERS,
        element: _jsx(Offers, {}),
    },
    {
        iaAvailable: userHasAccessTo(['OFFERS_LIST', 'OFFERS_MANAGE']),
        path: ROUTES.OFFERS_CREATE,
        element: _jsx(OffersForm, {}),
    },
    {
        iaAvailable: userHasAccessTo(['OFFERS_LIST', 'OFFERS_MANAGE']),
        path: ROUTES.OFFERS_EDIT,
        element: _jsx(OffersForm, {}),
    },
    //  Promocodes
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES,
        element: _jsx(Promocodes, { isFriend: false }),
    },
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES_CREATE,
        element: _jsx(PromocodesForm, { isFriend: false }),
    },
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES_EDIT,
        element: _jsx(PromocodesForm, { isFriend: false }),
    },
    //  Friend Promocodes
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES_FRIEND,
        element: _jsx(Promocodes, { isFriend: true }),
    },
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES_FRIEND_CREATE,
        element: _jsx(PromocodesForm, { isFriend: true }),
    },
    {
        iaAvailable: userHasAccessTo(['PROMOCODES_LIST', 'PROMOCODES_MANAGE']),
        path: ROUTES.PROMOCODES_FRIEND_EDIT,
        element: _jsx(PromocodesForm, { isFriend: true }),
    },
    //  Gift Cards
    {
        iaAvailable: userHasAccessTo([
            'GIFTCARDS_LIST',
            'GIFTCARDS_INSTANCES_MANAGE',
            'GIFTCARDS_MANAGE',
        ]),
        path: ROUTES.GIFT_CARDS,
        element: _jsx(GiftCards, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'GIFTCARDS_LIST',
            'GIFTCARDS_INSTANCES_MANAGE',
            'GIFTCARDS_MANAGE',
        ]),
        path: ROUTES.GIFT_CARDS_CREATE,
        element: _jsx(GiftCardsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'GIFTCARDS_LIST',
            'GIFTCARDS_INSTANCES_MANAGE',
            'GIFTCARDS_MANAGE',
        ]),
        path: ROUTES.GIFT_CARDS_EDIT,
        element: _jsx(GiftCardsForm, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'GIFTCARDS_LIST',
            'GIFTCARDS_INSTANCES_MANAGE',
            'GIFTCARDS_MANAGE',
        ]),
        path: ROUTES.GIFT_CARDS_INSTANCES,
        element: _jsx(GiftCardsInstances, {}),
    },
    //  Billing
    {
        iaAvailable: userHasAccessTo('BILLING_VIEW'),
        path: ROUTES.BILLING,
        element: _jsx(Billing, {}),
    },
    {
        iaAvailable: hasInn && userHasAccessTo('BILLING_INVOICES_MANAGE'),
        path: ROUTES.BILLING_INVOICES,
        element: _jsx(BillingInvoices, {}),
    },
    //  Settings
    {
        iaAvailable: userHasAccessTo([
            'OPERATORS_LIST',
            'OPERATORS_MANAGE',
            'OPERATORS_ADMIN_MANAGE',
        ]),
        path: ROUTES.SETTINGS_USERS,
        element: _jsx(Users, {}),
    },
    {
        iaAvailable: userHasAccessTo([
            'SHOPS_LIST',
            'SHOPS_OWN_MANAGE',
            'SHOPS_MANAGE',
        ]),
        path: ROUTES.SETTINGS_SHOPS,
        element: _jsx(Shops, {}),
    },
];
