import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormPurchaseWithDetailsAmont = memo(({ currency, totalAmount, totalDiscount, promocodeAmountSum, giftCardDiscount, bonusesApplied, bonusesCollected, paidAmount, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.infoItems }, { children: [_jsxs("div", Object.assign({ className: styles.infoItemsLeft }, { children: [_jsxs("div", Object.assign({ className: styles.infoItem }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Подарочная карта') })), _jsx("span", Object.assign({ className: classNames(styles.infoItemValue) }, { children: money({
                                    input: giftCardDiscount || 0,
                                    withFraction: true,
                                    currency,
                                    removeFractionZeros: true,
                                }) }))] })), _jsxs("div", Object.assign({ className: styles.infoItem }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Промокод') })), _jsx("span", Object.assign({ className: classNames(styles.infoItemValue) }, { children: money({
                                    input: promocodeAmountSum || '0',
                                    withFraction: true,
                                    currency,
                                    removeFractionZeros: true,
                                }) }))] })), _jsxs("div", Object.assign({ className: styles.infoItem }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Оплачено бонусами') })), _jsx("span", Object.assign({ className: classNames(styles.infoItemValue, styles.infoItemRed) }, { children: bonusesApplied }))] }))] })), _jsxs("div", Object.assign({ className: styles.infoItemsRight }, { children: [totalAmount && (_jsxs("div", Object.assign({ className: styles.infoItem }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Сумма до скидки') })), _jsx("span", Object.assign({ className: styles.infoItemValue }, { children: money({
                                    input: totalAmount,
                                    withFraction: true,
                                    removeFractionZeros: true,
                                    currency,
                                }) }))] }))), _jsxs("div", Object.assign({ className: styles.infoItem }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Скидки') })), _jsx("span", Object.assign({ className: styles.infoItemValue }, { children: totalDiscount === '0'
                                    ? '0'
                                    : `- ${money({
                                        input: totalDiscount || '0',
                                        withFraction: true,
                                        removeFractionZeros: true,
                                        currency,
                                    })}` }))] })), _jsxs("div", Object.assign({ className: styles.amountAfterDiscounts }, { children: [_jsx("span", Object.assign({ className: styles.infoItemTitle }, { children: t('Сумма после скидок') })), _jsx("span", Object.assign({ className: styles.infoItemValue }, { children: money({
                                    input: paidAmount || '0',
                                    withFraction: true,
                                    removeFractionZeros: true,
                                    currency,
                                }) })), _jsx("span", Object.assign({ className: styles.bonusesCollected }, { children: `+ ${t('BONUSES_AMOUNT', {
                                    amount: bonusesCollected,
                                    count: bonusesCollected,
                                })}` }))] }))] }))] })));
});
