import { $createSpaceNode } from './SpaceNode';
function findAndTransformSpace(node) {
    const text = node.getTextContent();
    for (let i = 0; i < text.length; i++)
        if (text[i] === ' ' || text[i] === '\u00a0') {
            let targetNode;
            if (i === 0) {
                ;
                [targetNode] = node.splitText(i + 1);
            }
            else {
                ;
                [, targetNode] = node.splitText(i, i + 1);
            }
            const spaceNode = $createSpaceNode();
            targetNode.replace(spaceNode);
            return spaceNode;
        }
    return null;
}
function transformTextNodeToSpace(node) {
    let targetNode = node;
    while (targetNode !== null) {
        if (!targetNode.isSimpleText())
            return;
        targetNode = findAndTransformSpace(targetNode);
    }
}
export default transformTextNodeToSpace;
