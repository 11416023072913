var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Arrow } from '@/icons/Arrow';
import { Cancel } from '@/icons/Cancel';
import { Cart } from '@/icons/header/Cart';
import { useDeletePurchase } from '../FormPurchase/useDeletePurchase';
import { useShops } from '../FormPurchase/useShops';
import { useUpdatePurchase } from '../FormPurchase/useUpdatePurchase';
import { FormPurchaseWithDetails } from './FormPurchaseWithDetails';
import styles from './styles.module.scss';
import { useCashiers } from './useCashiers';
const FORM_ID = 'purchase-with-details-form';
export const FormPurchaseWithDetailsModal = memo((_a) => {
    var { isOpen, onClose, selectedPurchase, setSelectedPurchaseIndex, selectedPurchaseIndex, purchasesListLength } = _a, props = __rest(_a, ["isOpen", "onClose", "selectedPurchase", "setSelectedPurchaseIndex", "selectedPurchaseIndex", "purchasesListLength"]);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const shops = useShops();
    const updatePurchase = useUpdatePurchase();
    const deletePurchase = useDeletePurchase();
    const getCashiers = useCashiers();
    const handleDelete = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedPurchase || !deletePurchase)
            return;
        setIsSubmitting(true);
        const result = yield deletePurchase({
            purchase: selectedPurchase,
            txid: selectedPurchase.txid,
        });
        if (result)
            onClose === null || onClose === void 0 ? void 0 : onClose({ deleted: true });
        setIsSubmitting(false);
    }), [selectedPurchase, deletePurchase, onClose]);
    const footer = (_jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsxs("div", Object.assign({ className: styles.footerButtons }, { children: [_jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ form: FORM_ID, type: 'submit', disabled: !userHasPurchasesManage || isSubmitting, size: 'large' }, { children: t('Сохранить изменения') })) })), _jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'button', theme: 'danger', icon: _jsx(Cancel, {}), disabled: !userHasPurchasesManage || isSubmitting, onClick: handleDelete }, { children: t('Удалить покупку') })) }))] })), _jsxs("div", Object.assign({ className: classNames(styles.footerButtons, styles.footerNavButtons) }, { children: [_jsx(Button, Object.assign({ size: 'large', disabled: hasFormChanged || selectedPurchaseIndex === 0, type: 'button', onClick: () => {
                                    if (selectedPurchaseIndex !== null)
                                        setSelectedPurchaseIndex(selectedPurchaseIndex - 1);
                                } }, { children: _jsx(Arrow, {}) })), _jsx(Button, Object.assign({ size: 'large', disabled: hasFormChanged ||
                                    purchasesListLength - 1 === selectedPurchaseIndex, type: 'button', onClick: () => {
                                    if (selectedPurchaseIndex !== null)
                                        setSelectedPurchaseIndex(selectedPurchaseIndex + 1);
                                } }, { children: _jsx(Arrow, {}) }))] }))] }))] }));
    return (_jsx(Modal, Object.assign({ isOnMountFocus: true, isOpen: isOpen, onClose: onClose, titleIcon: _jsx(Cart, {}), title: t('Покупка'), footer: footer, modalClassName: styles.modal, bodyClassName: styles.modalBody, footerClassName: styles.modalFooter, size: '1024', isForm: true, isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormPurchaseWithDetails, Object.assign({ id: FORM_ID, selectedPurchase: selectedPurchase, onChangeSubmitting: setIsSubmitting, onUpdated: () => onClose === null || onClose === void 0 ? void 0 : onClose({ updated: true }), onError: setError, setHasFormChanged: setHasFormChanged, shops: shops, updatePurchase: updatePurchase, getCashiers: getCashiers }, props)) })));
});
