import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Shop } from '@/icons/Shop';
import { compileFilters } from '@/services/filters/compileFilters';
import { useUserShopFilters } from '@/services/filters/data/useUserShopFilters';
import styles from '../styles.module.scss';
export const FormUsersShopConditions = () => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const shopConditions = watch('shopConditions');
    const hasShopConditions = !!(shopConditions === null || shopConditions === void 0 ? void 0 : shopConditions.configuration.length);
    const [shouldDisplayCustomBadge, setShouldDisplayCustomBadge] = useState(!hasShopConditions);
    const customBadge = { id: 'all', label: t('Все точки') };
    const handleUpdateShopConditions = (value) => {
        setValue('shopConditions', {
            conditions: compileFilters(value),
            configuration: value,
        }, { shouldDirty: true });
        if (!value.length)
            setShouldDisplayCustomBadge(true);
    };
    const filtersTree = useUserShopFilters([
        {
            id: 'all-shops',
            label: t('Все точки'),
            type: 'custom',
            onClick: () => handleUpdateShopConditions([]),
        },
    ], shopConditions === null || shopConditions === void 0 ? void 0 : shopConditions.configuration);
    return (_jsx(FormFieldRow, Object.assign({ label: _jsxs("div", Object.assign({ className: styles.shopsLabel }, { children: [_jsx(Shop, { size: 14 }), t('Точки продаж')] })), tooltip: t('Пользователь будет иметь доступ к данным только по перечисленным точкам продаж') }, { children: _jsx(Controller, { name: 'shopConditions', control: control, render: ({ field }) => {
                var _a;
                return (_jsx(FiltersInput, { filtersTree: filtersTree, value: ((_a = field.value) === null || _a === void 0 ? void 0 : _a.configuration) || [], onUpdate: handleUpdateShopConditions, customBadges: shouldDisplayCustomBadge ? [customBadge] : [], shouldDisplayActions: !shouldDisplayCustomBadge, shouldDisplayFilterBadges: !shouldDisplayCustomBadge, onRemoveCustomBadge: () => {
                        setShouldDisplayCustomBadge(false);
                    }, hasHangingOperator: false, hasResetButton: false, selectedCode: 'shop' }));
            } }) })));
};
