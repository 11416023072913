import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { Clock } from '@/icons/flow/Clock';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodeDeleteButton } from '../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodePlusButton } from '../helpers/NodePlusButton/NodePlusButton';
import styles from './styles.module.scss';
import { useDelayContent } from './useDelayContent';
export const DelayNode = ({ id, data, }) => {
    const flow = useReactFlow();
    const { selectedNode } = useInteractiveFlow();
    const { offsetSign = 0, offsetUnit = 'day', offsetValue = 1 } = data;
    const delayContent = useDelayContent(offsetSign, offsetUnit, offsetValue);
    const thisNode = flow.getNode(id);
    const handleClick = useCallback(() => {
        if (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid)
            selectedNode.selectUUID(thisNode.uuid);
    }, [thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid, selectedNode.selectUUID]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.interactive, { [styles.selected]: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid) }, 'nodrag', 'nopan'), onClick: handleClick }, { children: [_jsx(Clock, {}), _jsx("span", Object.assign({ className: styles.content }, { children: delayContent }))] })), _jsx(NodePlusButton, { nodeId: id }), _jsx(NodeDeleteButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
