export const goalDescriptions = {
    0: 'Без подсчета',
    1: 'Подтверждение подписки на рассылку',
    2: 'Дозаполнение хотя бы 1 поля анкеты',
    3: 'Совершение покупки',
    4: '100% доставка рассылки',
    5: 'Прохождение online-опроса',
    6: 'Установка карты Wallet',
    7: 'Переход по ссылке',
};
