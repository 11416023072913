import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useDashboardStats } from '@/app/pages/Dashboard/useDashboardStats';
import { PointsStatusPanel } from './PointsStatusPanel';
export const PointsStatusPanelContainer = () => {
    const [scale, setScale] = useState('period');
    const { stats: data, isLoading } = useDashboardStats();
    const period = (data === null || data === void 0 ? void 0 : data.bonusHistory.period) || [0, 0, 0];
    const allTime = (data === null || data === void 0 ? void 0 : data.bonusHistory.allTime) || [0, 0, 0];
    const notSpentValue = scale === 'period' ? period[0] : allTime[0];
    const redeemedValue = scale === 'period' ? period[1] : allTime[1];
    const expiredOrCancelledValue = scale === 'period' ? period[2] : allTime[2];
    return (_jsx(PointsStatusPanel, { selectedScaleId: scale, onChangeScale: setScale, notSpentValue: notSpentValue || 0, redeemedValue: redeemedValue || 0, expiredOrCancelledValue: expiredOrCancelledValue || 0, isLoading: isLoading }));
};
