var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';
export const Radio = memo((_a) => {
    var { iconClassName, hasError = false, children, className, disabled } = _a, props = __rest(_a, ["iconClassName", "hasError", "children", "className", "disabled"]);
    return (_jsxs("label", Object.assign({ className: classNames(styles.label, {
            [styles.empty]: !children,
            [styles.disabled]: disabled,
        }, className) }, { children: [_jsx("input", Object.assign({ className: styles.input, type: 'radio', disabled: disabled }, props)), _jsx("div", Object.assign({ className: classNames(styles.icon, iconClassName, {
                    [styles.error]: hasError,
                }) }, { children: _jsx("span", { className: styles.circle }) })), _jsx("span", Object.assign({ className: styles.text }, { children: children }))] })));
});
