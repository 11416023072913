var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '@/components/Pagination/Pagination';
import { TableHeaderDropdown } from '@/components/TableHeaderDropdown/TableHeaderDropdown';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { numberRound } from '@/utils/numbers';
import { GiftCardInstancesTable, } from './GiftCardInstancesTable';
export const GiftCardInstancesPanel = memo((_a) => {
    var { titleTotal, paginationTotal, hasExportDropdown, onExportCodes, offset, limit, onChangeOffset, isLoading = false, isFetching = false } = _a, props = __rest(_a, ["titleTotal", "paginationTotal", "hasExportDropdown", "onExportCodes", "offset", "limit", "onChangeOffset", "isLoading", "isFetching"]);
    const { t } = useTranslation();
    const settingsDropdown = hasExportDropdown && (_jsx(TableHeaderDropdown, { items: [
            {
                id: 'export-codes',
                label: t('Выгрузить коды в файл'),
                onClick: onExportCodes,
            },
        ] }));
    const pagination = (_jsx(Pagination, { total: paginationTotal, offset: offset, limit: limit, onChangeOffset: onChangeOffset }));
    return (_jsx(TabsPanel, Object.assign({ tabs: [
            {
                id: 'gift-card-instances',
                label: t('Выпущено карт:') + ' ' + numberRound(titleTotal),
            },
        ], rightContent: settingsDropdown, footer: pagination, isSkeleton: isLoading }, { children: _jsx(GiftCardInstancesTable, Object.assign({}, props, { isLoading: isFetching })) })));
});
