import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { DropdownChevron } from '@/components/Dropdown2/DropdownChevron';
import styles from './styles.module.scss';
export const ReportsNavButton = ({ title, isActive, onSelect, onRename, onSave, onDelete, }) => {
    const { t } = useTranslation();
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const isUserCanManageReports = userHasAccessTo('REPORTS_MANAGE');
    useEffect(() => {
        if (!isActive)
            setDropdownIsOpen(false);
    }, [isActive]);
    const button = (_jsxs(Button, Object.assign({ className: classNames(styles.item, {
            [styles.active]: isActive,
            [styles.blocked]: !isUserCanManageReports,
        }), type: 'button', onClick: onSelect, isOutlined: !isActive, isActive: dropdownIsOpen, disabled: !isUserCanManageReports && isActive }, { children: [title, isUserCanManageReports && (_jsx("div", Object.assign({ className: classNames(styles.chevron, {
                    [styles.active]: isActive || dropdownIsOpen,
                }) }, { children: _jsx(DropdownChevron, { isActive: dropdownIsOpen }) })))] })));
    const dropdown = (_jsx(Dropdown, Object.assign({ items: [
            {
                id: 'rename',
                label: t('Переименовать'),
                onClick: onRename,
            },
            {
                id: 'save',
                label: t('Сохранить'),
                onClick: onSave,
            },
            {
                id: 'delete',
                label: t('Удалить'),
                onClick: onDelete,
                isDanger: true,
            },
        ], onOpen: () => setDropdownIsOpen(true), onClose: () => setDropdownIsOpen(false), offset: [0, -10] }, { children: button })));
    return isActive ? dropdown : button;
};
