import { useEffect } from 'react';
export const useModalLoading = (isLoading) => {
    useEffect(() => {
        const body = document.body;
        if (isLoading)
            body.classList.add('modal-loading');
        else
            body.classList.remove('modal-loading');
    }, [isLoading]);
};
