import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { forwardRef, useCallback, useState } from 'react';
import { Button } from '@/components/Button2/Button';
import { Plus } from '@/icons/Plus';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { useAvailableSteps, useAvailableTriggers, } from '../../../hooks/useAvailableSteps';
import { NewStepPopup } from '../../../nodes/NewStepNode/NewStepPopup';
import { flowHasNewStepNode } from '../../utils';
import styles from './styles.module.scss';
export const NodePlusButton = ({ nodeId = '', handleId = '', buttonIsActive = false, }) => {
    const flow = useReactFlow();
    const { newStepPopup } = useInteractiveFlow();
    const [plusButton, setPlusButton] = useState(null);
    const clickedNode = flow.getNode(nodeId);
    const availableTriggerFilters = useAvailableTriggers(clickedNode);
    const availableSteps = useAvailableSteps(clickedNode);
    if (flowHasNewStepNode(flow))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(NewStepPopup, { isOpen: newStepPopup.isOpenForNode(nodeId, handleId), onClose: newStepPopup.close, anchorEl: plusButton, triggerFilters: availableTriggerFilters.availableTriggerFilters, isChannelTriggers: availableTriggerFilters.isChannelTriggers, isEmailTriggers: availableTriggerFilters.isEmailTriggers, steps: availableSteps }), _jsx(PlusButton, { ref: setPlusButton, onClick: () => newStepPopup.toggleForNode(nodeId, handleId), isPinned: newStepPopup.isOpenForNode(nodeId, handleId), isActive: buttonIsActive })] }));
};
const PlusButton = forwardRef(({ isActive = false, isPinned = false, onClick }, ref) => {
    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick === null || onClick === void 0 ? void 0 : onClick();
    }, [onClick]);
    return (_jsx(Button, { ref: ref, className: classNames(styles.button, { [styles.active]: isActive, [styles.pinned]: isPinned }, 'nodrag', 'nopan'), type: 'button', theme: 'primary', icon: _jsx(Plus, {}), isActive: isActive || isPinned, onClick: handleClick }));
});
