import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { AutocompleteFreeSolo } from '@/components/Autocomplete/variants/AutocompleteFreeSolo';
import { Input } from '@/components/Input/Input';
import { useDebounce } from '@/hooks/useDebounce';
export const QtyInput = memo(({ value, onChange, suggestions = ['1', '2', '3', '4', '5', '6', '10', '0.1', '0.2', '0.5'], }) => {
    const [draft, setDraft] = useState(value);
    useEffect(() => setDraft(value), [value]);
    useDebounce({
        value: draft,
        callback: onChange,
    });
    const handleChangeInput = useCallback((e) => {
        setDraft(e.target.value);
    }, []);
    const handleChangeAutocomplete = useCallback((value, fromSuggest) => {
        setDraft(value);
        if (fromSuggest)
            onChange(value);
    }, [onChange]);
    const inputProps = {
        type: 'number',
        inputMode: 'numeric',
        placeholder: '-',
        preventNegativeNumbers: true,
        shouldHideSpinButtons: true,
    };
    return suggestions.length > 0 ? (_jsx(AutocompleteFreeSolo, Object.assign({}, inputProps, { value: draft, onChange: handleChangeAutocomplete, options: suggestions }))) : (_jsx(Input, Object.assign({}, inputProps, { value: draft, onChange: handleChangeInput })));
});
