import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/store';
import { appPeriodSelector } from '@/store/selectors/appPeriod';
import { addToLS } from '@/utils/LS';
import { createHref, ROUTES } from '../routes';
export const useOpenPurchaseFilter = () => {
    var _a;
    const { t } = useTranslation();
    const period = useAppSelector(appPeriodSelector);
    const purchaseUrl = createHref(ROUTES.PURCHASES) +
        '?periodStart=' +
        period.start.format('YYYY-MM-DD') +
        '&periodEnd=' +
        period.end.format('YYYY-MM-DD');
    const openPurchaseFilter = (offer) => {
        const filters = [
            {
                code: 'purchaseOffer',
                text: t('Акции') + ': ' + offer.title,
                rawConditions: {
                    checkboxes: {
                        [offer.id]: true,
                    },
                    op: 'include',
                },
            },
        ];
        addToLS({ key: 'purchaseFilters', value: { filters, touched: true } });
        window.open(purchaseUrl, '_blank');
    };
    const origin = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin;
    return {
        purchaseUrl: purchaseUrl.replace(origin, ''),
        openPurchaseFilter,
    };
};
