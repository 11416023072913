var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import './styles.scss';
import classNames from 'classnames';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import { memo } from 'react';
import BaseDatePicker, { registerLocale, } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { Arrow } from '@/icons/Arrow';
import { Calendar } from '@/icons/Calendar';
import { MonthDropdown } from './MonthDropdown';
import styles from './styles.module.scss';
import { YearDropdown } from './YearDropdown';
registerLocale('ru', ru);
export const DateInput = memo((_a) => {
    var { calendarRef, hasIcon = true, hasCalendarHidden, hasDayNamesHidden = false, hasYearDropdownHidden = false, hasConstraints = false, hasError, startYear, endYear, calendarClassName, locale, dateFormat, placeholderText, customInput, renderCustomHeader, renderDayContents, showPopperArrow = false, autoComplete, minDate, maxDate } = _a, props = __rest(_a, ["calendarRef", "hasIcon", "hasCalendarHidden", "hasDayNamesHidden", "hasYearDropdownHidden", "hasConstraints", "hasError", "startYear", "endYear", "calendarClassName", "locale", "dateFormat", "placeholderText", "customInput", "renderCustomHeader", "renderDayContents", "showPopperArrow", "autoComplete", "minDate", "maxDate"]);
    const { i18n, t } = useTranslation();
    const input = customInput || (_jsx(Input, { leftIcon: hasIcon ? _jsx(Calendar, {}) : undefined, hasError: hasError }));
    const header = renderCustomHeader ||
        (({ date, monthDate, changeMonth, increaseMonth, decreaseMonth, changeYear, }) => {
            const isOutLeftRange = hasConstraints &&
                !!minDate &&
                moment(date).subtract(1, 'month').isSameOrBefore(moment(minDate));
            const isOutRightrange = hasConstraints &&
                !!maxDate &&
                moment(date).add(1, 'month').isSameOrAfter(moment(maxDate));
            const handleDecreaseMonth = () => {
                if (isOutLeftRange)
                    return;
                decreaseMonth();
            };
            const handleIncreaseMonth = () => {
                if (isOutRightrange)
                    return;
                increaseMonth();
            };
            return (_jsxs("div", Object.assign({ className: styles.yearMonthHeader }, { children: [_jsxs("div", { children: [_jsx(MonthDropdown, { monthDate: monthDate, onChange: changeMonth }), !hasYearDropdownHidden && (_jsxs(_Fragment, { children: [' ', _jsx(YearDropdown, { startYear: startYear, endYear: endYear, monthDate: monthDate, onChange: changeYear })] }))] }), _jsxs("div", Object.assign({ className: styles.monthNav }, { children: [_jsx("button", Object.assign({ className: styles.monthNavButton, type: 'button', onClick: handleDecreaseMonth, disabled: isOutLeftRange }, { children: _jsx(Arrow, {}) })), _jsx("button", Object.assign({ className: styles.monthNavButton, type: 'button', onClick: handleIncreaseMonth, disabled: isOutRightrange }, { children: _jsx(Arrow, {}) }))] }))] })));
        });
    const day = renderDayContents ||
        ((day) => {
            return _jsx("div", Object.assign({ className: styles.dayInner }, { children: day }));
        });
    return (_jsx(BaseDatePicker, Object.assign({ ref: calendarRef, calendarClassName: classNames(styles.calendar, {
            [styles.hidden]: hasCalendarHidden,
            [styles.noDayNames]: hasDayNamesHidden,
        }, calendarClassName), locale: locale || i18n.language, dateFormat: dateFormat || 'dd.MM.yyyy', placeholderText: typeof placeholderText === 'undefined'
            ? t('дд.мм.гггг')
            : placeholderText, customInput: input, minDate: minDate, maxDate: maxDate, renderCustomHeader: header, renderDayContents: day, showPopperArrow: showPopperArrow, autoComplete: autoComplete || 'off', dayClassName: (date) => {
            const dayName = moment(date).locale('en').format('ddd').toLowerCase();
            const isFirst = Number(moment(date).format('D')) === 1;
            const isLast = Number(moment(date).format('D')) === moment(date).daysInMonth();
            return classNames(styles[`day-${dayName}`], {
                [styles.first]: isFirst,
                [styles.last]: isLast,
            });
        }, calendarStartDay: 1 }, props)));
});
