export const buildProductProperties = (productProperties, t) => {
    const leaves = [];
    productProperties.forEach((prop) => {
        const common = {
            type: 'leaf',
            id: `purchaseItemProperty.${prop.externalId}`,
            code: 'purchaseItemProperty',
            label: prop.title,
            extra: {
                propertyId: prop.externalId,
                valueType: prop.valueType,
            },
        };
        switch (prop.valueType) {
            case 1:
            case 2: {
                leaves.push(Object.assign(Object.assign({}, common), { template: 'numRange' }));
                break;
            }
            case 3: {
                leaves.push(Object.assign(Object.assign({}, common), { template: 'checkboxes-simple', options: Object.assign({}, prop.valueOptions) }));
                break;
            }
            case 4: {
                leaves.push(Object.assign(Object.assign({}, common), { template: 'radios', options: {
                        0: t('Нет'),
                        1: t('Да'),
                    } }));
                break;
            }
            case 0:
            default: {
                leaves.push(Object.assign(Object.assign({}, common), { template: 'textField' }));
                break;
            }
        }
    });
    return leaves;
};
