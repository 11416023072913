var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getErrorMessage } from '@/api/query';
import { useGetUserQuery, useLoginMutation, } from '@/api/query/user';
import { ROUTES } from '../routes';
const NEEDS_2FA_CODE_EXPIRED_TIMEOUT = 10; // minutes
export const useLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { refetch: loadUser } = useGetUserQuery(undefined, { skip: true });
    const [sendCredentials] = useLoginMutation();
    const [error, setError] = useState('');
    const [needs2fa, setNeeds2fa] = useState(false);
    const [needs2faExpiredAt, setNeeds2faExpiredAt] = useState();
    const [needs2faCodeSentToEmail, setNeeds2faCodeSentToEmail] = useState();
    const resetNeeds2fa = useCallback(() => {
        setNeeds2fa(false);
        setNeeds2faExpiredAt(undefined);
        setNeeds2faCodeSentToEmail(undefined);
    }, []);
    const login = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setError('');
        const result = yield sendCredentials(payload);
        const error = getErrorMessage(result);
        const data = 'data' in result ? result.data : undefined;
        const needs2fa = !!(data === null || data === void 0 ? void 0 : data['2faCodeSentToEmail']);
        if (error) {
            setError(error);
            return;
        }
        if (needs2fa) {
            setNeeds2fa(true);
            setNeeds2faExpiredAt(moment().add(NEEDS_2FA_CODE_EXPIRED_TIMEOUT, 'minutes').format());
            setNeeds2faCodeSentToEmail(data['2faCodeSentToEmail']);
            return;
        }
        if (!('error' in result)) {
            loadUser();
            navigate(ROUTES.ROOT, {
                replace: true,
                state: { from: (_a = location.state) === null || _a === void 0 ? void 0 : _a.from },
            });
        }
    });
    return {
        login,
        loginError: error,
        needs2fa,
        needs2faExpiredAt,
        needs2faCodeSentToEmail,
        resetNeeds2fa,
    };
};
