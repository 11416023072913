import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { confirmable, createConfirmation, } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Modal } from '@/components/Modal/Modal';
import { Cancel } from '@/icons/Cancel';
import styles from './styles.module.scss';
export const ConfirmDialog = ({ disagreeLabel, agreeLabel, agreeButtonSize = 'normal', disagreeButtonSize = 'extra-large', showDisagreeLabel = true, isDanger = false, isDangerCancel = false, hasIcon = true, proceedClose = false, text, align = 'center', show, proceed, confirmation, }) => {
    const { t } = useTranslation();
    const handleClose = () => proceed(proceedClose ? 'close' : false);
    const handleCancel = () => proceed(false);
    const handleAgree = () => proceed('agree');
    const icon = hasIcon ? _jsx(Cancel, {}) : undefined;
    return (_jsxs(Modal, Object.assign({ className: classNames(styles.modal, styles[align]), isOpen: show, onClose: handleClose, bodyClassName: styles.body, size: '600' }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: confirmation })), !!text && _jsx("div", Object.assign({ className: styles.content }, { children: text })), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsx(Button, Object.assign({ theme: isDanger ? 'danger' : 'primary', icon: isDanger ? icon : undefined, size: agreeButtonSize || (isDanger ? 'normal' : 'extra-large'), onClick: handleAgree, autoFocus: isDangerCancel }, { children: agreeLabel || 'OK' })), showDisagreeLabel && (_jsx(Button, Object.assign({ theme: isDangerCancel ? 'danger' : 'primary', icon: isDangerCancel ? icon : undefined, size: disagreeButtonSize, onClick: handleCancel, autoFocus: !isDangerCancel }, { children: disagreeLabel || t('Отмена') })))] }))] })));
};
export const confirm = createConfirmation(confirmable(ConfirmDialog));
