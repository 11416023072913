var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Back } from '@/icons/Back';
import { Draft } from '@/icons/status/Draft';
import { RenameFlowForm } from './RenameFlowForm';
import styles from './styles.module.scss';
export const FlowFormHeader = ({ flow, onCreateFlow, isCreatingFlow, onUpdateFlow, isUpdatingFlow, deleteButtonLabel, onDeleteFlow, isDeletingFlow, userHasFlowsManage, rightContent, onGoBack, isDraft = false, }) => {
    const { t } = useTranslation();
    const [renameMode, setRenameMode] = useState(false);
    if (!flow)
        return (_jsx(RenameFlowForm, { onSubmit: onCreateFlow, onCancel: onGoBack, disabled: isCreatingFlow }));
    if (renameMode)
        return (_jsx(RenameFlowForm, { defaultTitle: flow.title, onSubmit: (title) => __awaiter(void 0, void 0, void 0, function* () {
                yield onUpdateFlow(flow.id, title);
                setRenameMode(false);
            }), onCancel: () => setRenameMode(false), disabled: isUpdatingFlow }));
    return (_jsxs("div", Object.assign({ className: classNames(styles.form, styles.edit) }, { children: [_jsxs("div", Object.assign({ className: styles.titleWrapper }, { children: [_jsx("button", Object.assign({ className: styles.back, type: 'button', onClick: onGoBack }, { children: _jsx(Back, {}) })), _jsx("div", Object.assign({ className: styles.title }, { children: flow.title })), userHasFlowsManage && (_jsx(Dropdown, { triggerClassName: styles.dropdown, placement: 'bottom-start', items: [
                            {
                                id: 'rename',
                                label: t('Переименовать'),
                                onClick: () => setRenameMode(true),
                                isDisabled: isDeletingFlow,
                            },
                            {
                                id: 'delete',
                                label: deleteButtonLabel || t('Удалить'),
                                onClick: () => onDeleteFlow(flow),
                                isDanger: true,
                                isDisabled: isDeletingFlow,
                            },
                        ] })), isDraft && (_jsxs("div", Object.assign({ className: styles.draft }, { children: [_jsx(Draft, {}), t('Черновик')] })))] })), rightContent] })));
};
