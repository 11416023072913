import { jsx as _jsx } from "react/jsx-runtime";
import { EntryFormContainer } from '@/components/EntryFormContainer/EntryFormContainer';
import { FormLogin } from '@/forms/FormForEntry/FormLogin/FormLogin';
import { FormLogin2fa } from '@/forms/FormLogin2fa/FormLogin2fa';
import { useLogin } from './useLogin';
import { useVerify2fa } from './useVerify2fa';
export const Login = () => {
    const { login, loginError, needs2fa, needs2faExpiredAt, needs2faCodeSentToEmail, resetNeeds2fa, } = useLogin();
    const { verify2fa, verify2faError, resetVerify2faError } = useVerify2fa();
    const handleResetForm = () => {
        resetNeeds2fa();
        resetVerify2faError();
    };
    return (_jsx(EntryFormContainer, { children: needs2fa ? (_jsx(FormLogin2fa, { onSubmit: verify2fa, codeSentToEmail: needs2faCodeSentToEmail, codeExpiredAt: needs2faExpiredAt, error: verify2faError, onGoBack: handleResetForm, onTimerExpire: handleResetForm })) : (_jsx(FormLogin, { onSubmit: login, error: loginError })) }));
};
