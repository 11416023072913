import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImportMappingSelect } from '@/components/ImportMappingSelect/ImportMappingSelect';
import { Table } from '@/components/Table/Table';
import { inverse } from '@/utils/arrays';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const ImportFormTable = ({ importData, importPreview, clientExtraFields, isLoading, onChangeImportData, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const previewRows = inverse(importPreview.previewRows);
    const skipHeader = (_a = importData.skipHeader) !== null && _a !== void 0 ? _a : 1;
    let displayRows = importPreview.totalRowsCount - skipHeader;
    displayRows = displayRows > 0 ? displayRows : 0;
    const displayRowsTitle = t('IMPORT_LINES', {
        count: displayRows,
        amount: numberRound(displayRows),
    });
    const tableColumns = [
        {
            id: 'header',
            title: t('Заголовки из файла'),
            align: 'right',
        },
        {
            id: 'field',
            title: t('Поле из системы'),
            className: styles.mapping,
        },
        {
            id: 'data',
            title: t('Данные из файла') + ` (${displayRowsTitle})`,
            span: (_b = previewRows[0]) === null || _b === void 0 ? void 0 : _b.length,
        },
    ];
    if (skipHeader === 0)
        tableColumns.shift();
    const tableRows = previewRows.map((row, rowIndex) => {
        var _a;
        const header = row.slice(0, skipHeader).slice(-1);
        const data = row.slice(skipHeader).map((item) => item || '–');
        return {
            id: `row-${rowIndex}`,
            cells: [
                ...header,
                _jsx(ImportMappingSelect, { type: importData.type, value: ((_a = importData.columnMapping) === null || _a === void 0 ? void 0 : _a[rowIndex]) || '', onChange: (value) => {
                        const mapping = [...(importData.columnMapping || [])];
                        mapping[rowIndex] = value;
                        onChangeImportData === null || onChangeImportData === void 0 ? void 0 : onChangeImportData(Object.assign(Object.assign({}, importData), { columnMapping: mapping }));
                    }, clientExtraFields: clientExtraFields }, 'mapping'),
                ...data,
            ],
        };
    });
    return (_jsx(Table, { className: classNames(styles.table, {
            [styles.hasHeader]: skipHeader > 0,
        }), cellClassNames: skipHeader ? { 1: styles.mapping } : { 0: styles.mapping }, columns: tableColumns, rows: tableRows, isLoading: isLoading }));
};
