import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxSelect, } from '@/components/CheckboxSelect/CheckboxSelect';
import { formatDay } from '@/utils/dates';
export const WEEKDAY_MON = 1;
export const WEEKDAY_TUE = 2;
export const WEEKDAY_WED = 4;
export const WEEKDAY_THU = 8;
export const WEEKDAY_FRI = 16;
export const WEEKDAY_SAT = 32;
export const WEEKDAY_SUN = 64;
export const DAYS = {
    WEEKDAY_MON,
    WEEKDAY_TUE,
    WEEKDAY_WED,
    WEEKDAY_THU,
    WEEKDAY_FRI,
    WEEKDAY_SAT,
    WEEKDAY_SUN,
};
export const DaysSelect = memo(({ id, value, onChange, hasReset = true, hasError = false, listWidth }) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const options = [
        { value: WEEKDAY_MON, label: formatDay(1, locale) },
        { value: WEEKDAY_TUE, label: formatDay(2, locale) },
        { value: WEEKDAY_WED, label: formatDay(3, locale) },
        { value: WEEKDAY_THU, label: formatDay(4, locale) },
        { value: WEEKDAY_FRI, label: formatDay(5, locale) },
        { value: WEEKDAY_SAT, label: formatDay(6, locale) },
        { value: WEEKDAY_SUN, label: formatDay(7, locale) },
    ];
    const dayLabels = {
        [WEEKDAY_MON]: formatDay(1, locale, true),
        [WEEKDAY_TUE]: formatDay(2, locale, true),
        [WEEKDAY_WED]: formatDay(3, locale, true),
        [WEEKDAY_THU]: formatDay(4, locale, true),
        [WEEKDAY_FRI]: formatDay(5, locale, true),
        [WEEKDAY_SAT]: formatDay(6, locale, true),
        [WEEKDAY_SUN]: formatDay(7, locale, true),
    };
    let text = hasReset ? t('в любой день') : '';
    const sorted = [...value].sort((a, b) => a - b);
    if (value.length > 0 && hasReset) {
        if (sorted.length > 1)
            text = t('в любые');
        else if ([WEEKDAY_WED, WEEKDAY_FRI, WEEKDAY_SAT].includes(sorted[0]))
            text = t('в любую');
        else if (sorted[0] === WEEKDAY_SUN)
            text = t('в любое');
        else
            text = t('в любой');
        text += ' ' + sorted.map((i) => dayLabels[i]).join(', ');
    }
    if (value.length > 0 && !hasReset)
        text = sorted.map((i) => dayLabels[i]).join(', ');
    return (_jsx(CheckboxSelect, { id: id, text: text, options: options, value: value, onChange: onChange, resetItemLabel: hasReset ? t('в любой день') : '', hasResetButton: hasReset, hasError: hasError, listWidth: listWidth }));
});
