import { maxmaApi, } from '@/api';
export function getClientLevelListApi() {
    return maxmaApi.get('/brand/levels');
}
export function getCashierListApi(config) {
    return maxmaApi.post('/brand/cashiers', config);
}
export function getCategoryListApi(config) {
    return maxmaApi.post('/brand/categories', config);
}
export function getProductsTreeApi(config) {
    return maxmaApi.post('/brand/products-tree', config);
}
export function getProductsBranchApi(config) {
    return maxmaApi.post('/brand/products-branch', Object.assign(Object.assign({}, config), { parentId: config.parentId || null }));
}
export function getBrandExtraFieldOptionsApi({ fieldName, }) {
    return maxmaApi.get(`/brand/client-xf-options/${fieldName}`);
}
export function getProductListApi(config) {
    return maxmaApi.post('/brand/products', config);
}
export function getSegmentListApi() {
    return maxmaApi.get('/brand/segments');
}
export function getRFMSegmentListApi() {
    return maxmaApi.get('/brand/rfm-segments');
}
export function getProductProperties() {
    return maxmaApi.get('/brand/product-properties');
}
export function getProductAttributesKeys() {
    return maxmaApi.get('/brand/product-attribute-keys');
}
export function getProductAttributesValues(config) {
    return maxmaApi.post('/brand/product-attribute-values', config);
}
