var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { useUploadImageMutation } from '@/api/query/image';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useUploadImage = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [uploadImage] = useUploadImageMutation();
    return (file, forEmail) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield uploadImage({ file, forEmail });
        if ('error' in result) {
            addToast({ type: 'error', message: t('Не удалось загрузить картинку') });
            return;
        }
        return result.data;
    });
};
