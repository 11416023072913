const find = (item, id) => {
    if (item.id === id || item.branchId === id)
        return item;
    if (item.items)
        for (let i = 0; i < item.items.length; i += 1) {
            const found = find(item.items[i], id);
            if (found !== null)
                return found;
        }
    return null;
};
export const findTreeLoaderItem = (options, id) => {
    if (!options.items)
        return null;
    for (let i = 0; i < options.items.length; i += 1) {
        const found = find(options.items[i], id);
        if (found)
            return found;
    }
    return null;
};
