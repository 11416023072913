import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { truncateSegmentLabel } from '@/components/SegmentBadge/truncateSegmentLabel';
import { Segment } from '@/icons/filters/Segment';
import { fgColor } from '@/utils/colors';
import styles from './styles.module.scss';
export const FiltersSegment = forwardRef(({ segment, isActive, onClick }, ref) => {
    return (_jsxs("button", Object.assign({ ref: ref, className: classNames(styles.segment, {
            [styles.active]: isActive,
        }), style: {
            background: segment.color,
            color: fgColor(segment.color),
        }, onClick: onClick }, { children: [_jsx("span", Object.assign({ className: styles.segmentIcon }, { children: _jsx(Segment, {}) })), truncateSegmentLabel(segment.name)] })));
});
