import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const RenameFlowForm = ({ defaultTitle = '', onSubmit, onCancel, disabled, }) => {
    const { t } = useTranslation();
    const titleInput = useRef(null);
    const [title, setTitle] = useState(defaultTitle);
    const [hasError, setHasError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleSubmit = () => {
        var _a;
        setIsSubmitted(true);
        if (!title.trim()) {
            setHasError(true);
            (_a = titleInput.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        onSubmit(title);
    };
    useEffect(() => {
        setTitle(defaultTitle);
    }, [defaultTitle]);
    return (_jsxs("div", Object.assign({ className: styles.form }, { children: [_jsx(Input, { ref: titleInput, wrapperClassName: styles.input, type: 'text', placeholder: t('Название цепочки'), value: title, onChange: (e) => {
                    setTitle(e.target.value);
                    setHasError(isSubmitted ? !e.target.value.trim() : false);
                }, onKeyUp: (e) => {
                    if (!disabled && e.code === 'Enter')
                        handleSubmit();
                }, hasError: hasError, autoFocus: true }), _jsx(Button, Object.assign({ type: 'button', theme: 'primary', onClick: handleSubmit, disabled: disabled }, { children: t('Сохранить цепочку') })), _jsx(Button, Object.assign({ type: 'button', theme: 'default', onClick: onCancel, disabled: disabled }, { children: t('Отмена') }))] })));
};
