import { Chart } from 'chart.js';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
export const useChart = (config) => {
    const { i18n } = useTranslation();
    const canvas = useRef(null);
    useEffect(() => {
        let chart = null;
        const buildChart = () => {
            moment.locale(i18n.language);
            if (canvas.current)
                chart = new Chart(canvas.current, config);
        };
        const destroyChart = () => {
            if (chart)
                chart.destroy();
        };
        buildChart();
        return destroyChart;
    }, [config, i18n.language]);
    return canvas;
};
