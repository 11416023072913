import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useGenerateSampleText } from '@/components/rich-text/WalletCardFieldEditor/useGenerateSampleText';
import { AppleWalletCard } from '@/components/wallet-cards/AppleWalletCard/AppleWalletCard';
import { GoogleWalletCard } from '@/components/wallet-cards/GoogleWalletCard/GoogleWalletCard';
import { getFontSize } from '@/components/wallet-cards/WalletCardForm/helpers/FontSizeOptions';
import { WalletCardNotify } from '@/components/wallet-cards/WalletCardNotify/WalletCardNotify';
import { WalletCardPhoneLoading } from '@/components/wallet-cards/WalletCardPhone/WalletCardPhoneLoading';
import { ROUTES } from '../routes';
import { useCounters } from '../WalletCardsForm/useCounters';
import { useDefaultActiveWalletCard } from './useDefaultActiveWalletCard';
import { useLevels } from './useLevels';
import { useWalletCardList } from './useWalletCardList';
export const ActiveCard = ({ cardType, cardLevel: providedLevel, notifyMessageText, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { levels, hasNoLevels } = useLevels();
    const { data, isLoading } = useWalletCardList();
    const counters = useCounters();
    const cardLevel = providedLevel !== undefined ? providedLevel : levels[0].id;
    const cardLevelName = (_a = levels.find((level) => level.id === cardLevel)) === null || _a === void 0 ? void 0 : _a.name;
    const defaultCard = useDefaultActiveWalletCard(cardLevel);
    const card = (data === null || data === void 0 ? void 0 : data.walletCards.find((card) => card.level === cardLevel)) || defaultCard;
    const { currency } = useCurrentUser();
    const generateSampleText = useGenerateSampleText(hasNoLevels ? '' : cardLevelName, currency);
    const handleElementClick = (type) => (element) => {
        if (card) {
            const path = card.id === -1
                ? generatePath(ROUTES.WALLET_CARDS_CREATE, {
                    levelId: card.level,
                })
                : generatePath(ROUTES.WALLET_CARDS_EDIT, {
                    levelId: card.level,
                    cardId: card.id,
                });
            navigate(path, {
                state: {
                    walletCardActiveType: type,
                    walletCardActiveElement: element,
                },
            });
        }
    };
    const background = (_b = card === null || card === void 0 ? void 0 : card.configuration) === null || _b === void 0 ? void 0 : _b.backgroundColor;
    const color = (_c = card === null || card === void 0 ? void 0 : card.configuration) === null || _c === void 0 ? void 0 : _c.textColor;
    const header = (_d = card === null || card === void 0 ? void 0 : card.configuration) === null || _d === void 0 ? void 0 : _d.header;
    const banner = (_e = card === null || card === void 0 ? void 0 : card.configuration) === null || _e === void 0 ? void 0 : _e.banner;
    const notification = (_f = card === null || card === void 0 ? void 0 : card.configuration) === null || _f === void 0 ? void 0 : _f.notification;
    const backside = (_g = card === null || card === void 0 ? void 0 : card.configuration) === null || _g === void 0 ? void 0 : _g.backside;
    const counter = ((_j = (_h = card.configuration) === null || _h === void 0 ? void 0 : _h.banner) === null || _j === void 0 ? void 0 : _j.type) === 'with-counter'
        ? counters.find((counter) => { var _a, _b, _c; return counter.offerId === ((_c = (_b = (_a = card.configuration) === null || _a === void 0 ? void 0 : _a.banner) === null || _b === void 0 ? void 0 : _b.counter) === null || _c === void 0 ? void 0 : _c.offerId); })
        : undefined;
    const counterTickImageUrl = (_o = (_m = (_l = (_k = card.configuration) === null || _k === void 0 ? void 0 : _k.banner) === null || _l === void 0 ? void 0 : _l.counter) === null || _m === void 0 ? void 0 : _m.tickImage) === null || _o === void 0 ? void 0 : _o.url;
    const counterBlankImageUrl = (_s = (_r = (_q = (_p = card.configuration) === null || _p === void 0 ? void 0 : _p.banner) === null || _q === void 0 ? void 0 : _q.counter) === null || _r === void 0 ? void 0 : _r.blankImage) === null || _s === void 0 ? void 0 : _s.url;
    const apple = (_jsx(AppleWalletCard, { background: background, color: color, headerImageUrl: (_u = (_t = header === null || header === void 0 ? void 0 : header.apple.logo) === null || _t === void 0 ? void 0 : _t[1]) === null || _u === void 0 ? void 0 : _u.url, header: header === null || header === void 0 ? void 0 : header.apple.title, fields: convertAppleFields(generateSampleText, card), bannerImageUrl: (_w = (_v = banner === null || banner === void 0 ? void 0 : banner.apple.image) === null || _v === void 0 ? void 0 : _v[1]) === null || _w === void 0 ? void 0 : _w.url, bannerImageTitle: (banner === null || banner === void 0 ? void 0 : banner.type) === 'with-text'
            ? generateSampleText((banner === null || banner === void 0 ? void 0 : banner.apple.title) || '', (banner === null || banner === void 0 ? void 0 : banner.apple.titleVars) || undefined)
            : undefined, bannerImageTitleSize: getFontSize(banner === null || banner === void 0 ? void 0 : banner.apple.titleSize), bannerImageTitleColor: (banner === null || banner === void 0 ? void 0 : banner.apple.titleColor) || '', bannerImageDescription: (banner === null || banner === void 0 ? void 0 : banner.type) === 'with-text'
            ? generateSampleText((banner === null || banner === void 0 ? void 0 : banner.apple.description) || '', (banner === null || banner === void 0 ? void 0 : banner.apple.descriptionVars) || undefined)
            : undefined, bannerImageDescriptionSize: getFontSize(banner === null || banner === void 0 ? void 0 : banner.apple.descriptionSize), bannerImageDescriptionColor: (banner === null || banner === void 0 ? void 0 : banner.apple.descriptionColor) || '', backsideTitle: backside === null || backside === void 0 ? void 0 : backside.title, details: convertDetails(generateSampleText, card), contacts: convertContacts(card), barCode: ((_x = card === null || card === void 0 ? void 0 : card.configuration) === null || _x === void 0 ? void 0 : _x.barcode) || undefined, previewKey: card === null || card === void 0 ? void 0 : card.id.toString(), onElementClick: handleElementClick('apple'), counter: counter, counterTickImageUrl: counterTickImageUrl, counterBlankImageUrl: counterBlankImageUrl }));
    const google = (_jsx(GoogleWalletCard, { background: (_y = card === null || card === void 0 ? void 0 : card.configuration) === null || _y === void 0 ? void 0 : _y.backgroundColor, headerImageUrl: (_z = header === null || header === void 0 ? void 0 : header.google.logo) === null || _z === void 0 ? void 0 : _z.url, header: header === null || header === void 0 ? void 0 : header.google.title, title: header === null || header === void 0 ? void 0 : header.google.programName, fields: convertGoogleFields(generateSampleText, card), details: convertDetails(generateSampleText, card), contacts: convertContacts(card), backsideImageUrl: (_0 = banner === null || banner === void 0 ? void 0 : banner.google.backsideImage) === null || _0 === void 0 ? void 0 : _0.url, bannerImageUrl: (_1 = banner === null || banner === void 0 ? void 0 : banner.google.image) === null || _1 === void 0 ? void 0 : _1.url, bannerImageTitle: (banner === null || banner === void 0 ? void 0 : banner.type) === 'with-text'
            ? generateSampleText((banner === null || banner === void 0 ? void 0 : banner.google.title) || '', (banner === null || banner === void 0 ? void 0 : banner.google.titleVars) || undefined)
            : undefined, bannerImageTitleSize: getFontSize(banner === null || banner === void 0 ? void 0 : banner.google.titleSize), bannerImageTitleColor: (banner === null || banner === void 0 ? void 0 : banner.google.titleColor) || '', bannerImageDescription: (banner === null || banner === void 0 ? void 0 : banner.type) === 'with-text'
            ? generateSampleText((banner === null || banner === void 0 ? void 0 : banner.google.description) || '', (banner === null || banner === void 0 ? void 0 : banner.google.descriptionVars) || undefined)
            : undefined, bannerImageDescriptionSize: getFontSize(banner === null || banner === void 0 ? void 0 : banner.google.descriptionSize), bannerImageDescriptionColor: (banner === null || banner === void 0 ? void 0 : banner.google.descriptionColor) || '', barCode: ((_2 = card === null || card === void 0 ? void 0 : card.configuration) === null || _2 === void 0 ? void 0 : _2.barcode) || undefined, onElementClick: handleElementClick('google'), counter: counter, counterTickImageUrl: counterTickImageUrl, counterBlankImageUrl: counterBlankImageUrl }));
    const notify = (_jsx(WalletCardNotify, { logoImageUrl: (_4 = (_3 = notification === null || notification === void 0 ? void 0 : notification.appleLogo) === null || _3 === void 0 ? void 0 : _3[1]) === null || _4 === void 0 ? void 0 : _4.url, logoBackground: background, messageTitle: notification === null || notification === void 0 ? void 0 : notification.title, messageText: typeof notifyMessageText === 'undefined'
            ? t('WALLET_CARD_MESSAGE_EXAMPLE')
            : notifyMessageText, onElementClick: handleElementClick('notify') }));
    if (isLoading)
        return _jsx(WalletCardPhoneLoading, {});
    return (_jsxs(_Fragment, { children: [cardType === 'apple' && apple, cardType === 'google' && google, cardType === 'notify' && notify] }));
};
const convertDetails = (generateSampleText, card) => {
    var _a;
    const backside = (_a = card === null || card === void 0 ? void 0 : card.configuration) === null || _a === void 0 ? void 0 : _a.backside;
    return backside === null || backside === void 0 ? void 0 : backside.fields.map((f) => ({
        title: f.label || '',
        content: generateSampleText(f.value || '', f.vars || undefined),
    }));
};
const convertContacts = (card) => {
    var _a;
    const backside = (_a = card === null || card === void 0 ? void 0 : card.configuration) === null || _a === void 0 ? void 0 : _a.backside;
    return backside === null || backside === void 0 ? void 0 : backside.contacts.map((c) => {
        var _a, _b, _c;
        return ({
            title: c.label || '',
            content: ((_a = c.value) === null || _a === void 0 ? void 0 : _a.replace('tel:', '').replace('mailto:', '')) || '',
            type: ((_b = c.value) === null || _b === void 0 ? void 0 : _b.startsWith('tel:'))
                ? 'phone'
                : ((_c = c.value) === null || _c === void 0 ? void 0 : _c.startsWith('mailto:'))
                    ? 'email'
                    : 'link',
        });
    });
};
const convertAppleFields = (generateSampleText, card) => {
    var _a, _b;
    const header = (_a = card === null || card === void 0 ? void 0 : card.configuration) === null || _a === void 0 ? void 0 : _a.header;
    const fields = (_b = card === null || card === void 0 ? void 0 : card.configuration) === null || _b === void 0 ? void 0 : _b.fields;
    return {
        'field-1': {
            title: (header === null || header === void 0 ? void 0 : header.apple.firstField.label) || '',
            value: generateSampleText((header === null || header === void 0 ? void 0 : header.apple.firstField.value) || '', (header === null || header === void 0 ? void 0 : header.apple.firstField.vars) || undefined),
        },
        'field-2': {
            title: (header === null || header === void 0 ? void 0 : header.apple.secondField.label) || '',
            value: generateSampleText((header === null || header === void 0 ? void 0 : header.apple.secondField.value) || '', (header === null || header === void 0 ? void 0 : header.apple.secondField.vars) || undefined),
        },
        'field-3': {
            title: (fields === null || fields === void 0 ? void 0 : fields.apple.first.label) || '',
            value: generateSampleText((fields === null || fields === void 0 ? void 0 : fields.apple.first.value) || '', (fields === null || fields === void 0 ? void 0 : fields.apple.first.vars) || undefined),
        },
        'field-4': {
            title: (fields === null || fields === void 0 ? void 0 : fields.apple.second.label) || '',
            value: generateSampleText((fields === null || fields === void 0 ? void 0 : fields.apple.second.value) || '', (fields === null || fields === void 0 ? void 0 : fields.apple.second.vars) || undefined),
        },
        'field-5': {
            title: (fields === null || fields === void 0 ? void 0 : fields.apple.third.label) || '',
            value: generateSampleText((fields === null || fields === void 0 ? void 0 : fields.apple.third.value) || '', (fields === null || fields === void 0 ? void 0 : fields.apple.third.vars) || undefined),
        },
        'field-6': {
            title: (fields === null || fields === void 0 ? void 0 : fields.apple.fourth.label) || '',
            value: generateSampleText((fields === null || fields === void 0 ? void 0 : fields.apple.fourth.value) || '', (fields === null || fields === void 0 ? void 0 : fields.apple.fourth.vars) || undefined),
        },
    };
};
export const convertGoogleFields = (generateSampleText, card) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    const fields = (_a = card === null || card === void 0 ? void 0 : card.configuration) === null || _a === void 0 ? void 0 : _a.fields;
    return {
        'field-1': {
            title: ((_b = fields === null || fields === void 0 ? void 0 : fields.google.first) === null || _b === void 0 ? void 0 : _b.label) || '',
            value: generateSampleText(((_c = fields === null || fields === void 0 ? void 0 : fields.google.first) === null || _c === void 0 ? void 0 : _c.value) || '', ((_d = fields === null || fields === void 0 ? void 0 : fields.google.first) === null || _d === void 0 ? void 0 : _d.vars) || undefined),
        },
        'field-2': {
            title: ((_e = fields === null || fields === void 0 ? void 0 : fields.google.second) === null || _e === void 0 ? void 0 : _e.label) || '',
            value: generateSampleText(((_f = fields === null || fields === void 0 ? void 0 : fields.google.second) === null || _f === void 0 ? void 0 : _f.value) || '', ((_g = fields === null || fields === void 0 ? void 0 : fields.google.second) === null || _g === void 0 ? void 0 : _g.vars) || undefined),
        },
        'field-3': {
            title: ((_h = fields === null || fields === void 0 ? void 0 : fields.google.third) === null || _h === void 0 ? void 0 : _h.label) || '',
            value: generateSampleText(((_j = fields === null || fields === void 0 ? void 0 : fields.google.third) === null || _j === void 0 ? void 0 : _j.value) || '', ((_k = fields === null || fields === void 0 ? void 0 : fields.google.third) === null || _k === void 0 ? void 0 : _k.vars) || undefined),
        },
        'field-4': {
            title: ((_l = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _l === void 0 ? void 0 : _l.label) || '',
            value: generateSampleText(((_m = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _m === void 0 ? void 0 : _m.value) || '', ((_o = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _o === void 0 ? void 0 : _o.vars) || undefined),
        },
        'field-5': {
            title: ((_p = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _p === void 0 ? void 0 : _p.label) || '',
            value: generateSampleText(((_q = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _q === void 0 ? void 0 : _q.value) || '', ((_r = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _r === void 0 ? void 0 : _r.vars) || undefined),
        },
        'field-6': {
            title: ((_s = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _s === void 0 ? void 0 : _s.label) || '',
            value: generateSampleText(((_t = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _t === void 0 ? void 0 : _t.value) || '', ((_u = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _u === void 0 ? void 0 : _u.vars) || undefined),
        },
        'field-7': {
            title: ((_v = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _v === void 0 ? void 0 : _v.label) || '',
            value: generateSampleText(((_w = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _w === void 0 ? void 0 : _w.value) || '', ((_x = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _x === void 0 ? void 0 : _x.vars) || undefined),
        },
        'field-8': {
            title: ((_y = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _y === void 0 ? void 0 : _y.label) || '',
            value: generateSampleText(((_z = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _z === void 0 ? void 0 : _z.value) || '', ((_0 = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _0 === void 0 ? void 0 : _0.vars) || undefined),
        },
        'field-9': {
            title: ((_1 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _1 === void 0 ? void 0 : _1.label) || '',
            value: generateSampleText(((_2 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _2 === void 0 ? void 0 : _2.value) || '', ((_3 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _3 === void 0 ? void 0 : _3.vars) || undefined),
        },
    };
};
