import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TextArea } from '@/components/Input/TextArea';
import { Modal } from '@/components/Modal/Modal';
import { addToLS, getFromLS } from '@/utils/LS';
import styles from './styles.module.scss';
export const TestMessageModal = memo(({ type, onSendClick, isOpen, onClose, error, isLoading }) => {
    const { t } = useTranslation();
    const targetsLSKey = type === 'email' ? 'testEmails' : 'testPhones';
    const targetsFromLS = getFromLS({ key: targetsLSKey }) || [];
    const [value, setValue] = useState(targetsFromLS.join('\n'));
    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);
    const handleSendClick = useCallback(() => {
        const targets = value
            .split('\n')
            .map((t) => t.trim())
            .filter(Boolean);
        onSendClick(targets);
        addToLS({ key: targetsLSKey, value: targets });
    }, [value, onSendClick, targetsLSKey]);
    let title = t('Тестовое сообщение');
    let placeholder = t('Укажите номер телефона');
    let help = '';
    switch (type) {
        case 'push':
        case 'viber': {
            title = type === 'push' ? t('Тестовый Push') : t('Тестовый Viber');
            placeholder = t('Укажите номер телефона');
            help = t('Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, и у него должна быть установлена карта Wallet');
            break;
        }
        case 'telegram': {
            title = t('Тестовый Telegram');
            placeholder = t('Укажите номер телефона');
            help = t('Можете указать несколько номеров, по одному на каждую строку, в любом формате. Номер должен быть в базе клиентов, а у клиента должен быть начат диалог с Telegram-ботом');
            break;
        }
        case 'sms': {
            title = t('Тестовое SMS');
            placeholder = t('Укажите номер телефона');
            help = t('Можете указать несколько номеров, по одному на каждую строку, в любом формате');
            break;
        }
        case 'email': {
            title = t('Тестовый Email');
            placeholder = t('Укажите email');
            help = t('Можете указать несколько адресов, по одному на каждую строку');
            break;
        }
    }
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), controlClassName: classNames(styles.errorText), asErrorMessage: true, asModalFooter: true, hasLabelHidden: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'button', theme: 'primary', disabled: isLoading || !value.trim(), size: 'large', onClick: handleSendClick }, { children: t('Отправить') })) }))] }), size: '550', hasNoPadding: true }, { children: _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: styles.input }, { children: _jsx(TextArea, { value: value, onChange: handleChange, placeholder: placeholder, rows: 6, autoFocus: true }) })), _jsx("div", Object.assign({ className: styles.help }, { children: help }))] })) })));
});
