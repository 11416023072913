import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@/store/slice';
import * as thunks from '@/store/thunk/billing';
import { processSimpleThunks } from '@/utils/reducers';
const billingSlice = createSlice({
    name: 'billing',
    initialState: {
        paymentPostponedUntil: { status: FetchStatus.Idle },
    },
    reducers: {},
    extraReducers: (builder) => {
        processSimpleThunks(builder, {
            paymentPostponedUntil: thunks.postponePayment,
        });
    },
});
export default billingSlice.reducer;
