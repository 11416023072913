import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Container } from '@/components/Container/Container';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Document } from '@/icons/Document';
import { InvoicesTable } from './InvoicesTable';
export const InvoicesPanel = memo(({ items, currency, onRequestInvoiceClick }) => {
    const { t } = useTranslation();
    return (_jsx(Container, { children: _jsx(TabsPanel, Object.assign({ tabs: [
                {
                    id: 'invoices',
                    label: t('Актуальные счета'),
                    count: items.length,
                },
            ], rightContent: _jsx(Button, Object.assign({ icon: _jsx(Document, {}), onClick: onRequestInvoiceClick, theme: 'primary', size: 'extra-large', hasLargeIconMargin: true }, { children: t('Запросить счет') })) }, { children: _jsx(InvoicesTable, { items: items, currency: currency }) })) }));
});
