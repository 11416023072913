var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSetGeoPushActiveMutation } from '@/api/query/wallet-cards';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useSetGeoPushActive = (geoPushId) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [setGeoPushActive] = useSetGeoPushActiveMutation();
    const setActive = (isActive) => __awaiter(void 0, void 0, void 0, function* () {
        if (!geoPushId)
            return true;
        const result = yield setGeoPushActive({ id: geoPushId, isActive });
        const wasError = 'error' in result;
        if (wasError)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else
            addToast({ type: 'success', message: t('Геопуш изменен') });
        return !wasError;
    });
    return setActive;
};
