var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ELK from 'elkjs/lib/elk.bundled.js';
const elk = new ELK();
export const layoutOptions = {
    'elk.algorithm': 'mrtree',
    'elk.direction': 'RIGHT',
    'elk.spacing.nodeNode': '50',
};
export const layoutFlow = (initialNodes, initialEdges) => __awaiter(void 0, void 0, void 0, function* () {
    const { nodes, edges } = sortNodesForLayout(initialNodes, initialEdges);
    const graph = {
        id: 'trigger',
        layoutOptions,
        children: nodes.map((node) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, node), { width: ((_a = node.measured) === null || _a === void 0 ? void 0 : _a.width) || 0, height: ((_b = node.measured) === null || _b === void 0 ? void 0 : _b.height) || 0 }));
        }),
        edges: edges.map((edge) => (Object.assign(Object.assign({}, edge), { sources: [edge.source], targets: [edge.target] }))),
    };
    const layout = yield elk.layout(graph);
    if (layout.children && layout.edges) {
        layout.children.forEach((child) => {
            const node = child;
            node.position = { x: child.x || 0, y: child.y || 0 };
            node.style = Object.assign(Object.assign({}, node.style), { opacity: 1 });
        });
        layout.edges.forEach((e) => {
            const edge = e;
            edge.style = Object.assign(Object.assign({}, edge.style), { opacity: 1 });
        });
        return {
            nodes: layout.children,
            edges: layout.edges,
        };
    }
});
const sortNodesForLayout = (initialNodes, initialEdges) => {
    const reversedNodes = [...initialNodes].reverse();
    const reversedEdges = [...initialEdges].reverse();
    const edgesWithHandles = reversedEdges.filter((e) => !!e.sourceHandle);
    const edgesWithoutHandles = reversedEdges.filter((e) => !e.sourceHandle);
    edgesWithHandles.sort((a, b) => {
        let aHandle = a.source + '--' + a.sourceHandle;
        const aCounter = Number(a.id.split('--')[2] || '');
        let bHandle = b.source + '--' + b.sourceHandle;
        const bCounter = Number(b.id.split('--')[2] || '');
        if (aHandle.includes('--yes-'))
            aHandle = aHandle.replace('--yes-', '');
        if (aHandle.includes('--no-'))
            aHandle = aHandle.replace('--no-', '');
        if (bHandle.includes('--yes-'))
            bHandle = bHandle.replace('--yes-', '');
        if (bHandle.includes('--no-'))
            bHandle = bHandle.replace('--no-', '');
        if (aHandle === bHandle)
            return aCounter - bCounter;
        if (aHandle > bHandle)
            return 1;
        else
            return -1;
    });
    edgesWithoutHandles.sort((a, b) => {
        const aCounter = Number(a.id.split('--')[2] || '');
        const bCounter = Number(b.id.split('--')[2] || '');
        return aCounter - bCounter;
    });
    const nodes = reversedNodes;
    const edges = [...edgesWithHandles, ...edgesWithoutHandles];
    return { nodes, edges };
};
