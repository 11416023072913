import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { TRIGGER_TYPE_ABANDONED_CART } from '@/const/mailing-triggers';
import { Cart } from '@/icons/header/Cart';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const EmailFormAbandonedCard = () => {
    const { t } = useTranslation();
    const { triggerType } = useInteractiveFlow();
    if (triggerType !== TRIGGER_TYPE_ABANDONED_CART)
        return null;
    return (_jsx("div", Object.assign({ className: styles.emailBlockPanel }, { children: _jsxs("div", Object.assign({ className: styles.emailBlockCheckboxWrapper }, { children: [_jsxs(Checkbox, Object.assign({ align: 'center', checked: true, disabled: true }, { children: [_jsx(Cart, { className: styles.icon }), t('Товары из брошенной корзины')] })), _jsx(HelpTooltip, { className: styles.emailBlockTooltip, title: t('EMAIL_INCLUDE_ABANDONED_CART'), placement: 'right' })] })) })));
};
