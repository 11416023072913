export const INIT_SECTIONS_STATE = {
    analytics: false,
    clients: false,
    tools: false,
    billing: false,
    settings: false,
};
export const ACCESS_OPTIONS = {
    NO_ACCESS: null,
    ACCESS: 'access',
    EDITING: 'editing',
    ALL_CLIENTS: 'all clients',
    OWN_CLIENTS: 'own clients',
    ALL_USERS: 'all users',
    ALL_USERS_EXEPTS_ADMINS: 'all users except admins',
};
export const SECTIONS = [
    { label: 'Аналитика', id: 'analytics' },
    { label: 'Клиенты', id: 'clients' },
    { label: 'Инструменты', id: 'tools' },
    { label: 'Биллинг', id: 'billing' },
    { label: 'Настройки', id: 'settings' },
];
export const ANALYTICS_FIELDS = [
    'DASHBOARD_VIEW',
    'SMARTRFM_VIEW',
    'reports',
    'EXPORTS',
];
export const CLIENTS_FIELDS = [
    'clientList',
    'clientListOwn',
    'CLIENTS_BONUSES_MANAGE',
    'CLIENTS_SEGMENTS',
    'CLIENTS_PURCHASES',
    'CLIENTS_PERSONAL_DATA_VIEW',
];
export const BILLING_FIELDS = ['BILLING_VIEW', 'BILLING_INVOICES_MANAGE'];
export const SETTINGS_FIELDS = ['operatorsList', 'operatorsListOwn', 'SHOPS'];
export const TOOLS_FIELDS = [
    'FLOWS',
    'AUTO_MAILINGS',
    'MANUAL_MAILINGS',
    'WALLET_CARDS',
    'OFFERS',
    'PROMOCODES',
    'GIFTCARDS',
    'GIFTCARDS_INSTANCES_MANAGE',
];
