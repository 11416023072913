var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorMessage } from '@/api/query';
import { useCreateExportMutation, } from '@/api/query/exports';
import { ExportTemplateType } from '@/components/ExportTemplatesTable/ExportTemplatesTable';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useCreateExport = ({ onSuccess }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const addToast = useToastContext();
    const [createExport] = useCreateExportMutation();
    const handleCreateExport = (type) => __awaiter(void 0, void 0, void 0, function* () {
        let payload = { type, periodStart, periodEnd };
        if (type === ExportTemplateType.CLIENT)
            payload = {
                type,
                filters: {
                    loyaltyCreatedAtGe: periodStart,
                    loyaltyCreatedAtLe: periodEnd,
                },
            };
        if (type === ExportTemplateType.CLIENT_CHILDREN)
            payload = { type };
        const result = yield createExport(payload);
        if ('error' in result)
            addToast({
                type: 'error',
                message: getErrorMessage(result.error),
            });
        else
            onSuccess();
    });
    return handleCreateExport;
};
