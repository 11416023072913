import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { notNull } from '@/utils/notNull';
import { usePurchasesPage } from './context/usePurchasesPage';
import { OrdersCancelledTable, OrdersTable } from './OrdersTable';
import { PurchasesPagination } from './PurchasesPagination';
import { PurchasesTable } from './PurchasesTable';
import { PurchasesToolbar } from './PurchasesToolbar';
export const PurchasesPanel = () => {
    const { t } = useTranslation();
    const { tabs: tabsState, data } = usePurchasesPage();
    const tabs = [
        {
            id: 'purchases',
            label: t('Завершенные'),
            count: data.count.purchase,
        },
        data.count.order
            ? {
                id: 'orders',
                label: t('В работе'),
                count: data.count.order,
            }
            : null,
        data.count.orderCancelled
            ? {
                id: 'orders-cancelled',
                label: t('Отмененные'),
                count: data.count.orderCancelled,
            }
            : null,
    ].filter(notNull);
    return (_jsx(TabsPanel, Object.assign({ tabs: tabs, activeTabId: tabsState.activeTab, onChangeActiveTab: tabsState.setActiveTab, rightToolbar: _jsx(PurchasesToolbar, {}), footer: _jsx(PurchasesPagination, {}), isSkeleton: data.count.isLoading || data.purchases.isLoading }, { children: tabsState.activeTab === 'purchases' ? (_jsx(PurchasesTable, {})) : tabsState.activeTab === 'orders' ? (_jsx(OrdersTable, {})) : (_jsx(OrdersCancelledTable, {})) })));
};
