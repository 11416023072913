var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSetWalletCardMutation } from '@/api/query/wallet-cards';
import { useToastContext } from '@/components/Toast/ToastContext';
import { convertFormValuesToWalletCard } from './convertFormValuesToWalletCard';
export const useSaveWalletCard = (id, level, showSuccessMessage = false) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [setCard, { isLoading }] = useSetWalletCardMutation();
    const saveCard = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const successMessage = t('Карта сохранена');
        const configuration = convertFormValuesToWalletCard(values);
        const result = yield setCard({ id, level, configuration });
        if ('error' in result) {
            const { status } = result.error;
            if (status === 200)
                addToast({ type: 'error', message: getErrorMessage(result.error) });
        }
        else if (showSuccessMessage)
            addToast({ type: 'success', message: successMessage });
    });
    return {
        saveCard,
        isSubmitting: isLoading,
    };
};
