var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSegmentListShortQuery } from '@/api/query/segment';
import { SegmentBadgeInfo } from '@/components/SegmentBadgeInfo/SegmentBadgeInfo';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { getBadgeColor, SegmentBadge } from './SegmentBadge';
import styles from './styles.module.scss';
import { useSegmentConfiguration } from './useSegmentConfiguration';
function getScrollParent(node) {
    var _a;
    if (node == null || node.tagName === 'BODY')
        return null;
    if (node.scrollHeight > node.clientHeight ||
        ((_a = node.parentNode) === null || _a === void 0 ? void 0 : _a.tagName) === 'BODY')
        return node;
    return getScrollParent(node.parentNode);
}
export const SegmentBadgeTooltip = (_a) => {
    var { onClose, container, placement = 'top' } = _a, badgeProps = __rest(_a, ["onClose", "container", "placement"]);
    const { children } = badgeProps;
    const { data, isLoading } = useGetSegmentListShortQuery();
    const { t } = useTranslation();
    const scrollParent = getScrollParent(container || null);
    const segment = useMemo(() => (data === null || data === void 0 ? void 0 : data.find(({ id }) => { var _a; return String((_a = badgeProps.segment) === null || _a === void 0 ? void 0 : _a.id) === String(id); })) ||
        badgeProps.segment, [data, badgeProps.segment]);
    const badgeColor = getBadgeColor(segment);
    const segmentConfiguration = useSegmentConfiguration(segment);
    useEffect(() => {
        const handler = () => onClose === null || onClose === void 0 ? void 0 : onClose();
        scrollParent === null || scrollParent === void 0 ? void 0 : scrollParent.addEventListener('scroll', handler);
        return () => scrollParent === null || scrollParent === void 0 ? void 0 : scrollParent.removeEventListener('scroll', handler);
    }, [scrollParent, onClose]);
    return (_jsx(Tooltip, Object.assign({ className: styles.tooltip, size: 'medium', title: isLoading ? (t('Загрузка...')) : segmentConfiguration ? (_jsx(SegmentBadgeInfo, { configuration: segmentConfiguration, color: badgeColor, title: _jsxs(_Fragment, { children: [(segment === null || segment === void 0 ? void 0 : segment.type) === 'rfm' && (_jsx("span", Object.assign({ className: styles.rfmLabel }, { children: "RFM" }))), children] }) })) : null, textAlign: 'center', placement: placement, PopperProps: scrollParent ? { container: scrollParent } : undefined, open: true, onClose: onClose }, { children: _jsx(SegmentBadge, Object.assign({}, badgeProps, { segment: segment })) })));
};
