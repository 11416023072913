import { useGetFlowListQuery } from '@/api/query/flows';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useData = ({ searchText }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data = [], isLoading, isFetching, } = useGetFlowListQuery({ searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    return {
        data,
        isLoading,
        isFetching,
    };
};
