import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
export const FormMailingViberURL = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, trigger, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const viberButton = watch('viberButton');
    const viberImage = watch('viberImage');
    const isRequired = (channels === null || channels === void 0 ? void 0 : channels.includes('viber')) && (!!viberButton || !!viberImage);
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    useEffect(() => {
        if (isSubmitted)
            trigger('viberUrl');
    }, [isSubmitted, viberButton, viberImage]);
    return (_jsx(FormFieldRow, Object.assign({ label: t('Ссылка'), labelFor: 'viberUrl', labelWidth: labelWidth, controlSize: '360', help: (_a = errors.viberUrl) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.viberUrl, isRequired: isRequired }, { children: _jsx(Controller, { name: 'viberUrl', control: control, rules: {
                validate: validateTrimValue,
                pattern: (channels === null || channels === void 0 ? void 0 : channels.includes('viber'))
                    ? {
                        value: /^(tel:[+\d]+|(http:\/\/|https:\/\/)?[^:\s]+)$/,
                        message: t('Неверный формат'),
                    }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'viberUrl', placeholder: t('ссылка из кнопки и/или картинки'), hasError: !!errors.viberUrl, autoFocus: true }))) }) })));
};
