import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { TextArea } from '@/components/Input/TextArea';
import styles from './styles.module.scss';
export const FormClientBlock = memo(({ onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, setFocus, } = useForm();
    useEffect(() => {
        setFocus('comment');
    }, []);
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'comment' }, { children: [t('Причина блокировки'), "*"] })), _jsx(Controller, { name: 'comment', control: control, rules: { required: true }, render: ({ field }) => (_jsx(TextArea, Object.assign({ className: classNames(styles.input, {
                                [styles.error]: !!errors.comment,
                            }), id: 'comment', placeholder: t('например: в связи с подозрительными операциями'), rows: 3 }, field))) })] })), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsx(Button, Object.assign({ className: styles.cancel, onClick: onCancel }, { children: t('Отмена') })), _jsx(Button, Object.assign({ className: styles.submit, theme: 'danger' }, { children: t('Заблокировать') }))] }))] })));
});
