var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { ImageCropInput, } from '@/components/ImageCropInput/ImageCropInput';
import { useUploadImage } from '@/hooks/useUploadImage';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const TelegramFormImage = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const uploadImage = useUploadImage();
    const [error, setError] = useState('');
    const resetImage = () => {
        selectedNode.update({
            telegram: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram), { telegramImage: undefined, telegramImageId: undefined, telegramImageFile: undefined }),
        });
    };
    const saveUploadedImage = (file, response) => {
        selectedNode.update({
            telegram: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram), { telegramImage: response, telegramImageId: response.id, telegramImageFile: file }),
        });
    };
    const saveLocalFile = (file) => {
        selectedNode.update({
            telegram: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram), { telegramImage: undefined, telegramImageId: undefined, telegramImageFile: file }),
        });
    };
    const handleUpdateTelegramImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        if (!file) {
            resetImage();
            return;
        }
        const response = yield uploadImage(file);
        if (response)
            saveUploadedImage(file, response);
        else
            saveLocalFile(file);
    });
    const handleResetTelegramImage = (reason) => {
        setError('');
        const message = rejectUploadReason(reason);
        if (message)
            setError(t(message));
        resetImage();
    };
    return (_jsxs("div", { children: [_jsx(ImageCropInput, { className: styles.telegramImage, value: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramImageFile) ||
                    ((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _b === void 0 ? void 0 : _b.telegramImage) ||
                    null, onChange: handleUpdateTelegramImage, onReject: handleResetTelegramImage, previewWidth: 326, previewHeight: 160, maintainPreviewAspectRatio: 'height', description: 'JPEG, ' + t('до 1 Мб'), constraints: {
                    mimeType: 'image/jpeg',
                    extensions: ['.jpg', '.jpeg'],
                    maxSize: 1000000,
                    shape: 'full',
                }, outputMimeType: 'jpeg', cropModalTitle: t('Картинка'), theme: 'outlined', hasError: !!error }), _jsx("div", Object.assign({ className: formStyles.error }, { children: error }))] }));
};
