import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import styles from '../styles.module.scss';
export const FormMailingEmailSubject = ({ brand, currency, labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, watch, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const isRequired = channels === null || channels === void 0 ? void 0 : channels.includes('email');
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const editorContext = {
        channel: 'email',
        triggerType: watch('triggerType'),
        rewards: watch('rewards'),
        hasWalletFeature: brandHasWalletFeature(brand),
    };
    return (_jsx(FormFieldRow, Object.assign({ label: t('Тема письма'), labelFor: 'emailSubject', labelWidth: labelWidth, controlSize: '450', hasError: !!errors.emailSubject, help: (_a = errors.emailSubject) === null || _a === void 0 ? void 0 : _a.message, isRequired: isRequired }, { children: _jsx(Controller, { name: 'emailSubject', control: control, rules: {
                validate: validateTrimValue,
            }, render: ({ field }) => (_jsx(MessageEditor, { className: styles.emailSubjectEditor, initialValue: field.value || '', onChange: (text) => {
                    setValue('emailSubject', text, {
                        shouldValidate: isSubmitted,
                        shouldDirty: true,
                    });
                }, placeholder: t('введите тему письма'), context: editorContext, currency: currency, hasError: !!errors.emailSubject, hasAutoFocus: true })) }) })));
};
