import { jsx as _jsx } from "react/jsx-runtime";
import { useGetRFMTableQuery } from '@/api/query/rfm';
import { RFMTable } from './RFMTable';
export const RFMTableContainer = ({ onCreateReportClick, currency, shouldDisplayProfit, isRFMEnabled, }) => {
    const { data } = useGetRFMTableQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const getSegmentData = (code) => {
        const segment = data === null || data === void 0 ? void 0 : data.statsPerSegment.find((s) => s.code === code);
        return {
            revenue: segment === null || segment === void 0 ? void 0 : segment.revenue,
            purchasesCount: segment === null || segment === void 0 ? void 0 : segment.purchaseCount,
            arppu: segment === null || segment === void 0 ? void 0 : segment.arppu,
            avgSale: segment === null || segment === void 0 ? void 0 : segment.avgAmount,
            clientsCount: segment === null || segment === void 0 ? void 0 : segment.clients,
            clientsPercent: segment === null || segment === void 0 ? void 0 : segment.clientsPercent,
        };
    };
    return (_jsx(RFMTable, { data: {
            VIP: getSegmentData('rfm_vip'),
            potentialVIP: getSegmentData('rfm_pot_vip'),
            loyal: getSegmentData('rfm_loyal'),
            newcomers: getSegmentData('rfm_new'),
            leavingVIP: getSegmentData('rfm_maybe_rtl_vip'),
            potentialLoyal: getSegmentData('rfm_pot_loyal'),
            outflowPossible: getSegmentData('rfm_maybe_rtl'),
            outflowVIP: getSegmentData('rfm_rtl_vip'),
            outflow: getSegmentData('rfm_rtl'),
            oneTimeClients: getSegmentData('rfm_rtl_new'),
            ultraflow: getSegmentData('rfm_lost'),
        }, onCreateReportClick: onCreateReportClick, currency: currency, shouldDisplayProfit: shouldDisplayProfit, isLocked: !isRFMEnabled }));
};
