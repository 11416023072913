import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { FormForEntry } from '../FormForEntry';
import { FormLoginTitle } from './FormLoginTitle';
export const FormLogin = ({ error, onSubmit }) => {
    const { t } = useTranslation();
    return (_jsx(FormForEntry, { firstField: {
            name: 'username',
            placeholder: t('логин'),
            type: 'text',
        }, secondField: {
            name: 'password',
            placeholder: t('пароль'),
            type: 'password',
        }, submitButtonText: t('Войти'), error: error, FormTitle: _jsx(FormLoginTitle, {}), onSubmit: onSubmit }));
};
