import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { Promocode } from '@/components/Promocode/Promocode';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import { PROMOCODE_TYPE_SHARED } from '@/const/promocode';
export const getNodeLabel = ({ isSegmentLinksEnabled, dimensions, item, lang, onSegmentClick, t, }) => {
    const dimension = dimensions[item.level - 1];
    const defaultLabel = t ? t(item.node.label) : item.node.label;
    const formatDate = (lang, format, date) => moment(date).locale(lang).format(format);
    switch (dimension) {
        case 'year': {
            return formatDate(lang, 'YYYY', item.node.groupKey);
        }
        case 'month': {
            const year = formatDate(lang, 'YYYY', item.node.groupKey);
            const month = formatDate(lang, 'MMMM', item.node.groupKey);
            return `${year}, ${month.slice(0, 1).toUpperCase()}${month.slice(1)}`;
        }
        case 'promocode': {
            const promocode = item.node.groupObject;
            return promocode ? (_jsx(Promocode, { code: promocode.code, isPersonal: promocode.codeType !== PROMOCODE_TYPE_SHARED })) : (defaultLabel);
        }
        case 'segment':
        case 'rfm': {
            const segment = item.node.groupObject;
            return segment ? (_jsx(SegmentBadgeContainer, Object.assign({ onClick: isSegmentLinksEnabled ? () => onSegmentClick(segment) : undefined, segment: segment }, { children: segment.name }))) : (defaultLabel);
        }
        default: {
            return defaultLabel;
        }
    }
};
