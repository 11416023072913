var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import { AlertPopupLayout } from './AlertPopupLayout';
import styles from './styles.module.scss';
export const AlertPopup = (_a) => {
    var { isOpen = false, onClose, anchorEl, onAgree, onCancel } = _a, otherProps = __rest(_a, ["isOpen", "onClose", "anchorEl", "onAgree", "onCancel"]);
    const handleAgree = () => {
        onAgree === null || onAgree === void 0 ? void 0 : onAgree();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleCancel = () => {
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    return (_jsx(Popover, Object.assign({ open: isOpen, anchorEl: anchorEl, onClose: onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        }, className: styles.popover, transitionDuration: 200, disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true }, { children: _jsx(AlertPopupLayout, Object.assign({}, otherProps, { onAgree: handleAgree, onCancel: handleCancel })) })));
};
