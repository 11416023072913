import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormPurchaseWithDetailsModal } from '@/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsModal';
import { usePurchasesPage } from './context/usePurchasesPage';
export const PurchasesDetailsModal = () => {
    const { currency } = useCurrentUser();
    const { data, pagination, selectedPurchase, modals } = usePurchasesPage();
    return (_jsx(FormPurchaseWithDetailsModal, { isOpen: modals.purchases.detailsModal.isOpen, onClose: (result) => {
            modals.purchases.detailsModal.close();
            if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.deleted))
                if (pagination.purchases.offset !== 0)
                    pagination.purchases.resetOffset();
                else
                    data.purchases.refetch();
        }, currency: currency, selectedPurchase: selectedPurchase.data, setSelectedPurchaseIndex: selectedPurchase.setIndex, selectedPurchaseIndex: selectedPurchase.index, purchasesListLength: data.purchases.items.length }));
};
