const DEFAULT_PREFIX = 'Wel';
export const addToLS = (options) => {
    const { prefix = DEFAULT_PREFIX, key, value } = options;
    try {
        window.localStorage.setItem(prefix + key, JSON.stringify(value));
    }
    catch (e) {
        // do nothing
    }
};
export const getFromLS = (options) => {
    const { prefix = DEFAULT_PREFIX, key } = options;
    const value = window.localStorage.getItem(prefix + key);
    if (!value)
        return null;
    try {
        return JSON.parse(value);
    }
    catch (e) {
        return null;
    }
};
export const removeFromLS = (options) => {
    const key = typeof options === 'string'
        ? options
        : (options.prefix || DEFAULT_PREFIX) + options.key;
    window.localStorage.removeItem(key);
};
