var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { ImageCropInput, } from '@/components/ImageCropInput/ImageCropInput';
import { useUploadImage } from '@/hooks/useUploadImage';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const PushFormImage = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const uploadImage = useUploadImage();
    const [error, setError] = useState('');
    const resetImage = () => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { walletImage: undefined, walletImageId: undefined, walletImageFile: undefined }),
        });
    };
    const saveUploadedImage = (file, response) => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { walletImage: response, walletImageId: response.id, walletImageFile: file }),
        });
    };
    const saveLocalFile = (file) => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { walletImage: undefined, walletImageId: undefined, walletImageFile: file }),
        });
    };
    const handleUpdatePushImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        if (!file) {
            resetImage();
            return;
        }
        const response = yield uploadImage(file);
        if (response)
            saveUploadedImage(file, response);
        else
            saveLocalFile(file);
    });
    const handleResetPushImage = (reason) => {
        setError('');
        const message = rejectUploadReason(reason, '', '1125×433', true);
        if (message)
            setError(t(message));
        resetImage();
    };
    return (_jsxs("div", { children: [_jsx(ImageCropInput, { value: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.walletImageFile) ||
                    ((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _b === void 0 ? void 0 : _b.walletImage) ||
                    null, onChange: handleUpdatePushImage, onReject: handleResetPushImage, previewWidth: 326, previewHeight: 122, description: 'PNG, ' + t('от') + ' ' + t('1125×433, до 10мб'), constraints: {
                    mimeType: 'image/png',
                    extensions: ['.png'],
                    maxSize: 10000000,
                    dimensions: { width: 1125, height: 433 },
                }, outputMimeType: 'png', hasError: !!error, cropModalTitle: t('Картинка на Wallet-карте'), theme: 'outlined' }), _jsx("div", Object.assign({ className: formStyles.error }, { children: error }))] }));
};
