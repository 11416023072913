import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const FormReportName = memo(({ id, defaultName = '', onSubmit }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, } = useForm({ defaultValues: { title: defaultName } });
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: t('Редактирование отчета') })), _jsx(FormFieldRow, Object.assign({ controlSize: '250', help: (_a = errors.title) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.title, hasNoMargin: true, hasLabelHidden: true }, { children: _jsx(Controller, { name: 'title', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { placeholder: t('введите название отчета'), autoFocus: true, hasError: !!errors.title }))) }) }))] })));
});
