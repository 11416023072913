import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBrandQuery } from '@/api/query/brand';
import { useCurrentUser } from '@/app/useCurrentUser';
export const useChatSupport = () => {
    const { user, brand } = useCurrentUser();
    const { isFetching: isLoadingBrand } = useGetBrandQuery(undefined, {
        skip: !(user === null || user === void 0 ? void 0 : user.isLoggedIn),
    });
    const { i18n } = useTranslation();
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (!isLoadingBrand && user && brand) {
            const isEmployee = (user === null || user === void 0 ? void 0 : user.isSuperUser) || (user === null || user === void 0 ? void 0 : user.isSuperAdmin);
            // формируем уникальный идентификатор для суперпользователя без данных о бренде
            const visitorId = isEmployee
                ? user === null || user === void 0 ? void 0 : user.user.id
                : (brand === null || brand === void 0 ? void 0 : brand.brand.globalKey) + ':' + (user === null || user === void 0 ? void 0 : user.user.id);
            const visitorData = {
                name: user.user.name || '',
                email: user.user.email || '',
                custom: {
                    role: user.user.role,
                    company: isEmployee ? undefined : brand === null || brand === void 0 ? void 0 : brand.brand.name,
                    website: isEmployee ? undefined : (_a = brand === null || brand === void 0 ? void 0 : brand.brand.settings) === null || _a === void 0 ? void 0 : _a.website,
                    cf_7392: `${(_b = brand.accountManager) === null || _b === void 0 ? void 0 : _b.name}, ${(_c = brand.accountManager) === null || _c === void 0 ? void 0 : _c.email}`,
                    cf_7393: (_d = brand.projectManager) === null || _d === void 0 ? void 0 : _d.username,
                    cf_7394: brand.brand.status,
                    cf_7395: brand.balance.monthlyPrice,
                    cf_7396: (_e = brand.brand.extraFields) === null || _e === void 0 ? void 0 : _e.posSoftware.join(', '),
                },
            };
            if (window.chaport) {
                window.chaport.on('ready', function () {
                    var _a, _b;
                    if ((_a = window.chaport) === null || _a === void 0 ? void 0 : _a.setVisitorData)
                        (_b = window.chaport) === null || _b === void 0 ? void 0 : _b.setVisitorData(visitorData);
                });
                // запускаем chaport, добавляем идентификатор пользователя
                window.chaport.q('startSession', {
                    id: visitorId,
                    token: visitorId,
                });
                window.chaport.q('setVisitorData', [visitorData]);
            }
        }
    }, [user, brand, isLoadingBrand, i18n.language]);
    useEffect(() => {
        var _a;
        // останавливаем сессию как только пользователь вышел
        if (!user && ((_a = window === null || window === void 0 ? void 0 : window.chaport) === null || _a === void 0 ? void 0 : _a.stopSession))
            window.chaport.stopSession();
    }, [user]);
};
