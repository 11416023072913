import { createSlice } from '@reduxjs/toolkit';
const walletSlice = createSlice({
    name: 'wallet',
    initialState: {
        publishButtonClicked: false,
    },
    reducers: {
        clickPublishButton(state) {
            state.publishButtonClicked = true;
        },
    },
});
export default walletSlice.reducer;
export const { clickPublishButton } = walletSlice.actions;
