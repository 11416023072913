import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EmptyControl.module.scss';
export const EmptyControl = memo(({ isLoading }) => {
    const { t } = useTranslation();
    return (_jsx("div", Object.assign({ className: styles.empty }, { children: isLoading
            ? t('Загружается...')
            : t('Выберите категорию фильтра слева') })));
});
