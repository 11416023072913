import { maxmaApi } from '@/api';
// getDashboardListApi ---------------------------------------------------------
export function getDashboardListApi() {
    return maxmaApi.get('/dashboard/list');
}
// addDashboardApi -------------------------------------------------------------
export function addDashboardApi(data) {
    return maxmaApi.post('/dashboard/add', data);
}
// deleteDashboardApi ----------------------------------------------------------
export function deleteDashboardApi(id) {
    return maxmaApi.post(`/dashboard/delete/${id}`);
}
// renameDashboardApi ----------------------------------------------------------
export function renameDashboardApi({ id, title, }) {
    return maxmaApi.post(`/dashboard/rename/${id}`, { title });
}
// saveDashboardApi ----------------------------------------------------------
export function saveDashboardApi(data) {
    return maxmaApi.post(`/dashboard/set/${data.id}`, data);
}
export function getSingleValueApi(payload) {
    return maxmaApi.post('/dashboard/get-single-value', payload);
}
export function getChartDataApi(payload) {
    return maxmaApi.post('/dashboard/get-chart-data', payload);
}
export function getTableDataApi(payload) {
    return maxmaApi.post('/dashboard/get-table-data', payload);
}
