import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { MailingRewardsList } from '@/components/MailingRewardsList/MailingRewardsList';
import styles from '../styles.module.scss';
export const OffersForm = ({ type = 'add', offerId: defaultOfferId, expireInDays: defaultExpireInDays, onChange, offers = [], disabledItemIds = [], }) => {
    const { t } = useTranslation();
    const [offerId, setOfferId] = useState(defaultOfferId);
    const [expireInDays, setExpireInDays] = useState(defaultExpireInDays);
    useEffect(() => {
        setOfferId(defaultOfferId);
        setExpireInDays(defaultExpireInDays);
    }, [defaultOfferId, defaultExpireInDays]);
    const isButtonDisabled = typeof offerId === 'undefined';
    const handleApply = () => {
        const expireInDaysNumber = Number(expireInDays);
        if (!isButtonDisabled)
            onChange(offerId, expireInDaysNumber > 999 ? '999' : expireInDays);
    };
    return (_jsxs("div", Object.assign({ className: styles.formWrapper }, { children: [_jsx("div", Object.assign({ className: classNames(styles.form, styles.hasBorder) }, { children: _jsxs("div", { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'mailing-rewards-offer-expire-in-days' }, { children: t('Срок действия акции') })), _jsx("div", Object.assign({ className: styles.offerExpireInDaysInput }, { children: _jsx(Input, { id: 'mailing-rewards-offer-expire-in-days', type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, value: expireInDays, onChange: (e) => setExpireInDays(e.target.value), onBlur: (e) => {
                                    const value = Number(e.target.value);
                                    if (value > 999)
                                        setExpireInDays('999');
                                }, onKeyUp: (e) => {
                                    if (e.code === 'Enter')
                                        handleApply();
                                }, rightText: t('DAYS_EXPIRING', {
                                    count: Number(expireInDays) || 0,
                                }), autoFocus: true }) })), _jsx("div", Object.assign({ className: styles.help }, { children: t('с момента рассылки') }))] }) })), _jsx("div", Object.assign({ className: styles.list }, { children: _jsx(MailingRewardsList, { onItemClick: setOfferId, items: offers, selectedItemId: offerId, disabledItemIds: disabledItemIds, hasSearch: false }) })), _jsx("div", Object.assign({ className: classNames(styles.formFooter, styles.hasBorder) }, { children: _jsx(Button, Object.assign({ type: 'button', theme: 'primary', size: 'full-width', disabled: isButtonDisabled, onClick: handleApply }, { children: type === 'add' ? t('Добавить') : t('Изменить') })) }))] })));
};
