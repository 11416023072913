import { money, numberRound } from '@/utils/numbers';
import { jsonHandler } from './jsonHandler';
export const jsonToSampleText = (editorState, variables, overrides = {}, currency) => {
    return jsonHandler(editorState, {
        onVariable: (serialized) => {
            var _a;
            const variable = variables.find((v) => v.id === serialized.id);
            const variableKey = variable === null || variable === void 0 ? void 0 : variable.key;
            if (variableKey !== undefined && overrides[variableKey])
                return overrides[variableKey];
            if (variable && variable.sample) {
                let sample = variable.sample.toString();
                if (typeof variable.sample === 'object' &&
                    variable.sampleType === 'date') {
                    let format = 'DD MMM';
                    if (typeof ((_a = serialized.payload.params) === null || _a === void 0 ? void 0 : _a.dateFormat) === 'string')
                        format = serialized.payload.params.dateFormat;
                    const lang = document.documentElement.lang;
                    sample = variable.sample.locale(lang).format(format);
                }
                if (typeof variable.sample === 'number' &&
                    variable.sampleType === 'price')
                    sample = money({ input: variable.sample, currency }) || '';
                if (typeof variable.sample === 'number' &&
                    variable.sampleType !== 'price')
                    sample = numberRound(variable.sample);
                return sample;
            }
            else
                return `!${serialized.id}`;
        },
    });
};
