import { addToLS } from '@/utils/LS';
export const registerLS = (editor, key) => {
    if (!key)
        return;
    editor.addEventListener('design:updated', () => {
        editor.exportHtml(function (data) {
            addToLS({ key, value: JSON.stringify(data.design) });
        });
    });
};
