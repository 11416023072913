import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useGetOfferQuery } from '@/api/query/offers';
import { DAYS } from '@/components/DaysSelect/DaysSelect';
export const convertOfferToFormValues = (offer, tz) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    // _configurationProduct отдельный фильтр в UI формы -- "В чеке есть"
    // выцепляем его из общего шейпа данных по фильтрам, чтобы показать отдельно
    // от фильтра "Условия по покупке"
    const _configurationProduct = ((_b = (_a = offer.conditions) === null || _a === void 0 ? void 0 : _a.configuration) === null || _b === void 0 ? void 0 : _b.find((i) => i.code === '_configurationProduct')) || {};
    const _conditionsProduct = _configurationProduct.value;
    let conditionsProduct = _conditionsProduct === null || _conditionsProduct === void 0 ? void 0 : _conditionsProduct.map((filters) => ({ filters }));
    // раньше "В чеке есть" и "Условия по покупке" были одним фильтром
    // purchaseProduct -- ключ фильтра по товару в старом шейпе данных
    // меняем его на новый тип
    const purchaseProduct = (_d = (_c = offer.conditions) === null || _c === void 0 ? void 0 : _c.configuration) === null || _d === void 0 ? void 0 : _d.find((i) => i.code === 'purchaseProduct');
    if (purchaseProduct) {
        if (!conditionsProduct)
            conditionsProduct = [];
        conditionsProduct.push({
            filters: [Object.assign(Object.assign({}, purchaseProduct), { code: 'purchaseItem' })],
        });
    }
    const conditions = (_f = (_e = offer.conditions) === null || _e === void 0 ? void 0 : _e.configuration) === null || _f === void 0 ? void 0 : _f.filter((i) => i.code !== 'purchaseProduct' && i.code !== '_configurationProduct');
    const growingDiscount = (_g = offer.growingDiscount) === null || _g === void 0 ? void 0 : _g.map((i) => ({
        to: i.to || 0,
        amount: i.amount,
    }));
    let availableFrom = offer.availableFrom;
    let availableTo = offer.availableTo;
    if (availableFrom && tz)
        availableFrom = moment(availableFrom).tz(tz).format('YYYY-MM-DD');
    if (availableTo && tz)
        availableTo = moment(availableTo).tz(tz).format('YYYY-MM-DD');
    return Object.assign(Object.assign({}, offer), { growingDiscount,
        conditionsProduct,
        conditions, itemConditions: (_h = offer.itemConditions) === null || _h === void 0 ? void 0 : _h.configuration, recipientTags: offer.recipients, recipientFilters: (_k = (_j = offer.recipients) === null || _j === void 0 ? void 0 : _j.filters) === null || _k === void 0 ? void 0 : _k.configuration, weekdays: offer.weekdays
            ? [
                offer.weekdays & DAYS.WEEKDAY_MON ? DAYS.WEEKDAY_MON : null,
                offer.weekdays & DAYS.WEEKDAY_TUE ? DAYS.WEEKDAY_TUE : null,
                offer.weekdays & DAYS.WEEKDAY_WED ? DAYS.WEEKDAY_WED : null,
                offer.weekdays & DAYS.WEEKDAY_THU ? DAYS.WEEKDAY_THU : null,
                offer.weekdays & DAYS.WEEKDAY_FRI ? DAYS.WEEKDAY_FRI : null,
                offer.weekdays & DAYS.WEEKDAY_SAT ? DAYS.WEEKDAY_SAT : null,
                offer.weekdays & DAYS.WEEKDAY_SUN ? DAYS.WEEKDAY_SUN : null,
            ].filter(Boolean)
            : [], timeFrom: offer.timeFrom
            ? moment(offer.timeFrom, 'HH:mm:ss').format('HH:mm')
            : undefined, timeTo: offer.timeTo
            ? moment(offer.timeTo, 'HH:mm:ss').format('HH:mm')
            : undefined, limitMax: offer.limitMax === null ? undefined : offer.limitMax, limitPeriod: offer.limitPeriod === null ? undefined : offer.limitPeriod, eachNOpted: !!offer.eachN, eachN: offer.eachN || undefined, notMoreNOpted: !!offer.notMoreN, notMoreN: offer.notMoreN || undefined, cheapestOpted: offer.cheapest, spreadDiscountOpted: offer.spreadDiscount, availableFrom,
        availableTo });
};
export const useOfferData = (offerId, rfmSegment, tz) => {
    const { t } = useTranslation();
    const { data: selectedOffer, isLoading } = useGetOfferQuery({ id: offerId || 0 }, { skip: !offerId });
    const defaultValues = {};
    if (rfmSegment) {
        const configuration = [
            {
                code: 'clientRfmSegments',
                text: `${t('RFM сегменты')}: ${t(rfmSegment.name)}`,
                rawConditions: {
                    op: 'include',
                    checkboxes: { [rfmSegment.id]: true },
                },
            },
        ];
        defaultValues.recipientFilters = configuration;
    }
    return {
        selectedOffer,
        defaultValues: selectedOffer
            ? convertOfferToFormValues(selectedOffer, tz)
            : defaultValues,
        isLoading: offerId ? isLoading : false,
    };
};
