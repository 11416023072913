import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBarList, } from '@/components/HorizontalBarList/HorizontalBarList';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { calculatePercent } from '@/utils/calculatePercent';
import styles from './styles.module.scss';
export const DataCompletenessPanel = memo(({ total, optedForSMS, optedForTelegram, appleWalletCard, androidWalletCard, doubleOptedInEmail, optedForEmail, nameFilled, emailFilled, birthdayFilled, genderFilled, isLoading = false, }) => {
    const { t } = useTranslation();
    const title = t('Качество клиентской базы');
    const tooltip = t('Показатель процента заполнения полей, важных для коммуникации с клиентами');
    const color = '#89BEE3';
    const bars = [
        {
            id: 'optedForSMS',
            label: t('Согласны на рассылку SMS'),
            color,
            value: optedForSMS,
            percent: calculatePercent(optedForSMS, total, 0),
        },
        {
            id: 'optedForEmailTotal',
            label: t('Согласны на рассылку Email'),
            composition: [
                {
                    id: 'optedForEmail',
                    value: optedForEmail - doubleOptedInEmail,
                    percent: calculatePercent(optedForEmail, total, 0),
                },
                {
                    id: 'doubleOptedInEmail',
                    value: doubleOptedInEmail,
                    tooltip: t('Подтвердили через Double Opt-In'),
                    percent: calculatePercent(doubleOptedInEmail, total, 0),
                },
            ],
            value: optedForEmail,
            percent: calculatePercent(optedForEmail, total, 0),
        },
        {
            id: 'optedForTelegram',
            label: t('Доступен Telegram'),
            color,
            value: optedForTelegram,
            percent: calculatePercent(optedForTelegram, total, 0),
        },
        {
            id: 'nameFilled',
            label: t('Заполнено имя'),
            color,
            value: nameFilled,
            percent: calculatePercent(nameFilled, total, 0),
        },
        {
            id: 'walletCard',
            label: t('Установлена Wallet-карта'),
            composition: [
                {
                    id: 'appleWalletCard',
                    tooltip: `Apple (${calculatePercent(appleWalletCard, total, 0)}%)`,
                    value: appleWalletCard,
                    percent: calculatePercent(appleWalletCard, total, 0),
                },
                {
                    id: 'androidWalletCard',
                    value: androidWalletCard,
                    tooltip: `Android (${calculatePercent(androidWalletCard, total, 0)}%)`,
                    percent: calculatePercent(androidWalletCard, total, 0),
                },
            ],
            value: appleWalletCard + androidWalletCard,
            percent: calculatePercent(appleWalletCard + androidWalletCard, total, 0),
        },
        {
            id: 'emailFilled',
            label: t('Заполнен Email'),
            color,
            value: emailFilled,
            percent: calculatePercent(emailFilled, total, 0),
        },
        {
            id: 'birthdayFilled',
            label: t('Заполнен день рождения'),
            color,
            value: birthdayFilled,
            percent: calculatePercent(birthdayFilled, total, 0),
        },
        {
            id: 'genderFilled',
            label: t('Заполнен пол'),
            color,
            value: genderFilled,
            percent: calculatePercent(genderFilled, total, 0),
        },
    ];
    return (_jsx(StatsPanel, Object.assign({ title: title, tooltip: tooltip, isLoading: isLoading }, { children: _jsx("div", Object.assign({ className: styles.list }, { children: _jsx(HorizontalBarList, { items: bars }) })) })));
});
