import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { CheckboxSearchList } from '@/components/CheckboxSearchList/CheckboxSearchList';
import { Wrapper } from './Wrapper';
export const CheckboxesPaginatedLoader = memo(({ item, onApply, onBack, limit = 50 }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [draft, setDraft] = useState(item);
    useEffect(() => setDraft(Object.assign(Object.assign({}, draft), { options: item.options })), [item.options]);
    const { options, offset = 0, hasMoreData = false, isLoadingMoreData = false, } = item.options;
    const handleLoadFirstPage = () => {
        var _a, _b;
        item.loadOptions({
            ids: (_a = draft.value) === null || _a === void 0 ? void 0 : _a.value,
            searchText: (_b = draft.value) === null || _b === void 0 ? void 0 : _b.search,
            limit,
            offset: 0,
        });
    };
    const handleLoadNextPage = () => {
        var _a;
        item.loadOptions({
            ids: [],
            searchText: (_a = draft.value) === null || _a === void 0 ? void 0 : _a.search,
            limit,
            offset: offset + limit,
        });
    };
    useEffect(() => {
        handleLoadFirstPage();
    }, [(_a = draft.value) === null || _a === void 0 ? void 0 : _a.search]);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (value) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value }) }));
    };
    const handleChangeSearch = (search) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { search }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = !!((_c = (_b = draft.value) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.length);
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: ((_d = draft.value) === null || _d === void 0 ? void 0 : _d.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, isLoading: !options, count: (_f = (_e = draft.value) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.length }, { children: _jsx(CheckboxSearchList, { items: Object.entries(options || {}).map(([optValue, optLabel]) => ({
                id: optValue,
                label: optLabel,
            })), selectedItemIds: ((_h = (_g = draft.value) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.map((i) => i.toString())) || [], onChange: handleChangeValue, hasMoreData: hasMoreData, onLoadMoreData: handleLoadNextPage, isLoadingMoreData: isLoadingMoreData, search: (_j = draft.value) === null || _j === void 0 ? void 0 : _j.search, onChangeSearch: handleChangeSearch, hasSearch: true }) })));
});
