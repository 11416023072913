var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { FormUsers } from './FormUsers';
import styles from './styles.module.scss';
export const FormUsersModal = memo((_a) => {
    var { id = 'user-form', isOpen, onClose, isLoading, error } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "isLoading", "error"]);
    const { isEdit } = props;
    const { t } = useTranslation();
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasOperatorsManage = userHasAccessTo([
        'OPERATORS_ADMIN_MANAGE',
        'OPERATORS_MANAGE',
    ]);
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: t('Пользователь'), size: '770', isForm: true, hasNoPadding: true, footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Tooltip, Object.assign({ title: !userHasOperatorsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: isEdit ? (_jsx(Button, Object.assign({ type: 'submit', form: id, disabled: !userHasOperatorsManage || isLoading, size: 'large' }, { children: t('Сохранить изменения') }))) : (_jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', disabled: !userHasOperatorsManage || isLoading, size: 'extra-large' }, { children: t('Создать пользователя') }))) })) }))] }), isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormUsers, Object.assign({ setHasFormChanged: setHasFormChanged, id: id }, props)) })));
});
