import { useCallback, useState } from 'react';
export const useNewStepPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedNodeId, setSelectedNodeId] = useState('');
    const [selectedHandleId, setSelectedHandleId] = useState('');
    const [selectedEdgeId, setSelectedEdgeId] = useState('');
    const open = useCallback((nodeId, handleId, edgeId) => {
        setIsOpen(true);
        setSelectedNodeId(nodeId);
        setSelectedHandleId(handleId);
        setSelectedEdgeId(edgeId);
    }, []);
    const close = useCallback(() => {
        setIsOpen(false);
    }, []);
    const reset = useCallback(() => {
        setIsOpen(false);
        setSelectedNodeId('');
        setSelectedHandleId('');
        setSelectedEdgeId('');
    }, []);
    const isOpenForEdge = useCallback((edgeId) => {
        return isOpen && selectedEdgeId === edgeId;
    }, [isOpen, selectedEdgeId]);
    const isOpenForNode = useCallback((nodeId, handleId) => {
        return (isOpen && selectedNodeId === nodeId && selectedHandleId === handleId);
    }, [isOpen, selectedNodeId, selectedHandleId]);
    const toggleForEdge = useCallback((edgeId) => {
        if (isOpenForEdge(edgeId))
            close();
        else
            open('', '', edgeId);
    }, [isOpenForEdge, open, close]);
    const toggleForNode = useCallback((nodeId, handleId) => {
        if (isOpenForNode(nodeId, handleId))
            close();
        else
            open(nodeId, handleId, '');
    }, [isOpenForNode, open, close]);
    return {
        selectedNodeId,
        selectedHandleId,
        selectedEdgeId,
        isOpenForEdge,
        isOpenForNode,
        close,
        toggleForEdge,
        toggleForNode,
        reset,
    };
};
