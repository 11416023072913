import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const EmailFormSender = () => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const { brand } = useCurrentUser();
    const emailSenders = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.mailing) === null || _b === void 0 ? void 0 : _b.emailSenders) || [];
    const shouldDisplaySelect = emailSenders.length > 1;
    const defaultEmailSender = emailSenders.find((sender) => sender.isDefault) || emailSenders[0];
    const emailFromName = (defaultEmailSender === null || defaultEmailSender === void 0 ? void 0 : defaultEmailSender.name) || ((_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.settings) === null || _d === void 0 ? void 0 : _d.emailFromName) || '';
    const emailFrom = (defaultEmailSender === null || defaultEmailSender === void 0 ? void 0 : defaultEmailSender.email) || ((_f = (_e = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _e === void 0 ? void 0 : _e.settings) === null || _f === void 0 ? void 0 : _f.emailFrom) || '';
    const handleUpdateSender = (sender) => {
        selectedNode.update({
            email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { emailSenderId: sender === null || sender === void 0 ? void 0 : sender.id }),
        });
    };
    if (!shouldDisplaySelect)
        return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp) }, { children: t('От кого') })), _jsxs("div", Object.assign({ className: styles.sender }, { children: [emailFromName, " <", emailFrom, ">"] }))] }));
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-email-sender' }, { children: t('От кого') })), _jsx(Autocomplete, { id: 'channel-form-email-sender', value: emailSenders.find((item) => { var _a; return item.id === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.emailSenderId); }) || null, onChange: handleUpdateSender, options: emailSenders, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true })] }));
};
