import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { OTPInput } from '@/components/OTPInput/OTPInput';
import { Timer } from '@/components/Timer/Timer';
import { Arrow } from '@/icons/Arrow';
import { FormForEntryPanel } from '../FormForEntry/FormForEntryPanel';
import { FormLogin2faTitle } from './FormLogin2faTitle';
import styles from './styles.module.scss';
export const FormLogin2fa = ({ onSubmit, codeSentToEmail, codeExpiredAt, error, onGoBack, onTimerExpire, shouldUseSimpleInput = false, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { isSubmitting, errors }, } = useForm({
        defaultValues: {
            code: '',
        },
    });
    const validateTrimValue = (value) => !value.trim() ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormForEntryPanel, Object.assign({ title: _jsx(FormLogin2faTitle, {}) }, { children: _jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("div", Object.assign({ className: styles.description }, { children: t('LOGIN_2FA_DESCRIPTION', { email: codeSentToEmail }) })), _jsx(FormFieldRow, Object.assign({ className: styles.control, help: (_a = errors.code) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.code, controlSize: shouldUseSimpleInput ? '160' : undefined, hasLabelHidden: true }, { children: _jsx(Controller, { name: 'code', control: control, rules: {
                            minLength: {
                                value: 6,
                                message: t('FIELD_MIN_LENGTH_ERROR', { amount: 6, count: 6 }),
                            },
                            maxLength: {
                                value: 6,
                                message: t('FIELD_MAX_LENGTH_ERROR', { amount: 6, count: 6 }),
                            },
                            validate: validateTrimValue,
                        }, render: ({ field }) => shouldUseSimpleInput ? (_jsx(Input, Object.assign({ id: 'code' }, field, { placeholder: '\u0425\u0425\u0425\u0425\u0425\u0425', hasError: !!errors.code, autoFocus: true }))) : (_jsx(OTPInput, Object.assign({ id: 'code' }, field, { hasError: !!errors.code, autoFocus: true }))) }) })), !!codeExpiredAt && (_jsxs("div", Object.assign({ className: styles.timerWrapper }, { children: [t('Код действует'), ' ', _jsx(Timer, { className: styles.timer, expiredAt: codeExpiredAt, onExpire: onTimerExpire })] }))), error && (_jsx(FormFieldRow, Object.assign({ className: styles.error, asErrorMessage: true, hasLabelHidden: true }, { children: error }))), _jsx(Button, Object.assign({ className: styles.submit, type: 'submit', theme: 'primary', disabled: isSubmitting, size: 'full-width' }, { children: t('Отправить') })), _jsx(Button, Object.assign({ type: 'button', size: 'full-width', onClick: onGoBack, icon: _jsx("div", Object.assign({ className: styles.arrow }, { children: _jsx(Arrow, {}) })) }, { children: t('Войти в другой аккаунт') }))] })) })));
};
