import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const FormCounterName = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const { errors } = formState;
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormFieldRow, Object.assign({ className: styles.row, label: t('Название'), labelFor: 'name', labelWidth: labelWidth, labelClassName: styles.label, help: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.name, tooltip: t('COUNTER_NAME_HELP'), tooltipPlacement: 'top', tooltipPosition: 'control' }, { children: _jsx(Controller, { name: 'name', control: control, rules: { validate: validateTrimValue }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('например, фишки кофе'), hasError: !!errors.name }))) }) })));
};
