export const getFirstLeafCode = (filtersTree) => {
    if (!filtersTree.length)
        return '';
    if (filtersTree[0].type === 'branch' &&
        filtersTree[0].items.length === 1 &&
        filtersTree[0].items[0].type === 'leaf')
        return filtersTree[0].items[0].id;
    if (filtersTree[0].type === 'leaf' && filtersTree.length === 1)
        return filtersTree[0].id;
    return '';
};
