import { $createTextNode, $getSelection, $isRangeSelection, } from 'lexical';
import { $isLinkNode } from './LinkNode';
export const deleteLink = (editor) => {
    editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const nodes = selection.getNodes();
            for (let i = 0; i < nodes.length; i += 1) {
                const node = nodes[i];
                if ($isLinkNode(node))
                    node.replace($createTextNode(node.getTextContent()));
            }
        }
        editor.focus();
    });
};
