import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { convertFormValuesToMailing } from '@/app/pages/MailingsForm/convertFormValuesToMailing';
import { TRIGGER_TYPE_BONUS_ACTIVATE, TRIGGER_TYPE_PURCHASE, } from '@/const/mailing-triggers';
export const useCheckZeroTotalBonuses = (type, segments = []) => {
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    return (text) => {
        var _a, _b, _c, _d, _e;
        const values = getValues();
        const m = convertFormValuesToMailing(type, values);
        let missingCondition = (_a = m.recipients) === null || _a === void 0 ? void 0 : _a.all;
        let haveBonusesFilter = false;
        Object.keys((_b = m.recipients) === null || _b === void 0 ? void 0 : _b.filters.conditions).forEach((key) => {
            if (key === 'loyaltyBonusesGe')
                haveBonusesFilter = true;
        });
        if (!haveBonusesFilter)
            if (((_c = m.recipients) === null || _c === void 0 ? void 0 : _c.segments.length) === 0)
                missingCondition = true;
            else
                segments.forEach((s) => {
                    var _a, _b;
                    if (((_a = m.recipients) === null || _a === void 0 ? void 0 : _a.segments.indexOf(s.id)) !== -1 ||
                        ((_b = m.recipients) === null || _b === void 0 ? void 0 : _b.segments.indexOf(String(s.id))) !== -1) {
                        let found = false;
                        Object.keys(s.conditions).forEach((k) => {
                            // include segment with bonuses >= X
                            if (k === 'loyaltyBonusesGe')
                                found = true;
                        });
                        Object.keys(s.conditions._not || {}).forEach((k) => {
                            // include segment with NOT(bonuses <= X)
                            if (k === 'loyaltyBonusesLe')
                                found = true;
                        });
                        if (!found)
                            // if have at least one segment without bonuses filter
                            missingCondition = true;
                    }
                });
        let hasExclusion = false;
        Object.keys(((_d = m.recipients) === null || _d === void 0 ? void 0 : _d.filters.conditions._not) || {}).forEach((k) => {
            // have filter NOT(bonuses >= X)
            if (k === 'loyaltyBonusesGe')
                hasExclusion = true;
        });
        if (!hasExclusion && (((_e = m.excludeRecipients) === null || _e === void 0 ? void 0 : _e.segments) || []).length > 0)
            segments.forEach((s) => {
                var _a, _b;
                if (((_a = m.excludeRecipients) === null || _a === void 0 ? void 0 : _a.segments.indexOf(s.id)) != -1 ||
                    ((_b = m.excludeRecipients) === null || _b === void 0 ? void 0 : _b.segments.indexOf(String(s.id))) != -1) {
                    Object.keys(s.conditions).forEach(function (k) {
                        // exclude segment with bonuses <= X
                        if (k === 'loyaltyBonusesLe')
                            hasExclusion = true;
                    });
                    Object.keys(s.conditions._not || {}).forEach(function (k) {
                        // exclude segment with NOT(bonuses >= X)
                        if (k === 'loyaltyBonusesGe')
                            hasExclusion = true;
                    });
                }
            });
        if (missingCondition &&
            !hasExclusion &&
            !(m.promocodeId ||
                m.giftCardId ||
                m.bonusesAmount ||
                m.triggerType == String(TRIGGER_TYPE_BONUS_ACTIVATE) ||
                m.triggerType == String(TRIGGER_TYPE_PURCHASE))) {
            const ph = '!' + t('ВсегоБонусов');
            if ((text === null || text === void 0 ? void 0 : text.indexOf(ph)) !== -1)
                return true;
        }
        return false;
    };
};
