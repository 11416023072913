export const calculatePercent = (value, total, noTotalValue = 100) => {
    if (!Number(total))
        return noTotalValue;
    return Math.round((Number(value) / Number(total)) * 100);
};
export const pct = (numerator, denominator) => {
    const n = Number(numerator);
    const d = Number(denominator);
    if (isNaN(n) || isNaN(d) || d == 0)
        return 0;
    return Math.round((n * 1000) / d) / 10.0;
};
