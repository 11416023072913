import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import styles from './styles.module.scss';
export const DropdownPills = memo(({ items, selectedItemId, onChange }) => {
    const [isActive, setIsActive] = useState(false);
    const selectedItem = items.find((item) => item.id === selectedItemId);
    return (_jsx(Dropdown, { triggerClassName: classNames(styles.trigger, {
            [styles.active]: isActive,
        }), titleClassName: styles.title, chevronClassName: styles.chevron, title: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label, items: items.map((item) => (Object.assign(Object.assign({}, item), { isSelected: item.id === selectedItemId, onClick: () => {
                onChange(item.id);
            } }))), onOpen: () => setIsActive(true), onClose: () => setIsActive(false) }));
});
