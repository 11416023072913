import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, PASTE_COMMAND } from 'lexical';
import { useEffect } from 'react';
import pasteVariables from './pasteVariables';
export function ClipboardPlugin({ variables }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(PASTE_COMMAND, pasteVariables(variables), COMMAND_PRIORITY_LOW);
    }, [editor]);
    return null;
}
