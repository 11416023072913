var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCounterListQuery } from '@/api/query/counters';
import { useCurrentUser } from '@/app/useCurrentUser';
import { ClientAddModal } from '@/components/ClientModal/ClientAddModal';
import { ClientEditForm } from '@/components/ClientModal/ClientEditForm';
import { ClientEditModal } from '@/components/ClientModal/ClientEditModal';
import { ClientOverviewPanelContainer } from '@/components/ClientOverviewPanel/ClientOverviewPanelContainer';
import { Container } from '@/components/Container/Container';
import { CurrentUserFranchisePicker } from '@/components/CurrentUserFranchisePicker/CurrentUserFranchisePicker';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { FiltersPanel } from '@/components/FiltersPanel/FiltersPanel';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { FormClientBonusesModalContainer } from '@/forms/FormClientBonuses/FormClientBonusesModalContainer';
import { FormPurchaseModalContainer } from '@/forms/FormPurchase/FormPurchaseModalContainer';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
import { ROUTES } from '../routes';
import { ClientsPanel } from './ClientsPanel';
import styles from './styles.module.scss';
import { useClientModal } from './useClientModal';
import { useData } from './useData';
import { useExportXlsx } from './useExportXlsx';
import { useFiltersState } from './useFiltersState';
import { useSaveSegment } from './useSaveSegment';
export const Clients = () => {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand, user } = useCurrentUser();
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const countryCode = brand === null || brand === void 0 ? void 0 : brand.brand.defaultCountry;
    const isChildrenEnabled = brand === null || brand === void 0 ? void 0 : brand.brand.features.children;
    const extraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const { data: counters = [] } = useGetCounterListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const openAddModal = useCallback(() => setAddModalIsOpen(true), []);
    const closeAddModal = useCallback(() => setAddModalIsOpen(false), []);
    const [bonusModalIsOpen, setBonusModalIsOpen] = useState(false);
    const openBonusModal = useCallback(() => setBonusModalIsOpen(true), []);
    const closeBonusModal = useCallback(() => setBonusModalIsOpen(false), []);
    const [purchaseFormClient, setPurchaseFormClient] = useState(null);
    const [purchaseFormOpen, setPurchaseFormOpen] = useState(false);
    const openPurchaseForm = useCallback(() => setPurchaseFormOpen(true), []);
    const closePurchaseForm = useCallback(() => setPurchaseFormOpen(false), []);
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const { segment, filters, compiledFilters, updateFilters, reset, untouchLS } = useFiltersState({ currency });
    const filtersTree = useClientFilters(extraFields, counters, [], filters === null || filters === void 0 ? void 0 : filters.filters);
    const { saveSegment, isAdding: isAddingSegment, isSetting: isSettingSegment, } = useSaveSegment({
        onSuccess: (segment) => untouchLS(segment),
    });
    const { count, data, isLoading, isFetching, refetchClients } = useData({
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : undefined,
        searchText,
        limit,
        offset,
    });
    const exportXlsx = useExportXlsx({
        searchText: searchText || undefined,
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : {},
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    const { client: selectedClient, openOverview, openEditForm, isClientModalOpen, openClientModal, closeClientModal, clientModalActiveTab, changeModalActiveTab, } = useClientModal();
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const handleUpdateFilters = useCallback((filters) => {
        setOffset(0);
        updateFilters(filters);
    }, []);
    const handleResetFilters = useCallback(() => {
        setOffset(0);
        reset();
    }, []);
    if (!brand || !user)
        return null;
    return (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElementClassName: classNames({
            [styles.hasFilters]: (_a = filters === null || filters === void 0 ? void 0 : filters.filters) === null || _a === void 0 ? void 0 : _a.length,
        }), topElement: _jsxs("div", Object.assign({ className: styles.searchPanel }, { children: [_jsx(FiltersButton, { className: styles.filterButton, filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters }), _jsx(SearchPanel, { className: styles.search, name: 'searchText', placeholder: t('Номер телефона, ФИО, email или отсканируйте карту'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }), _jsx(CurrentUserFranchisePicker, { user: user.user })] })) }, { children: [_jsx(FiltersPanel, { filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters, hasResetButton: !!(filters === null || filters === void 0 ? void 0 : filters.filters.length), onResetFilters: handleResetFilters, segment: segment, isSegmentFiltersTouched: filters === null || filters === void 0 ? void 0 : filters.touched, onSaveSegment: (values) => __awaiter(void 0, void 0, void 0, function* () {
                    if (!(filters === null || filters === void 0 ? void 0 : filters.filters.length))
                        return;
                    if (segment === null || segment === void 0 ? void 0 : segment.id)
                        return saveSegment(values ? Object.assign(Object.assign({}, segment), values) : segment, filters.filters, !values);
                }), onCreateSegment: (newSegment) => saveSegment(newSegment, filters === null || filters === void 0 ? void 0 : filters.filters), isCreatingSegment: isAddingSegment, isSettingSegment: isSettingSegment, user: user }), _jsx(ClientsPanel, { count: count, clients: data, isLoading: isLoading, isFetching: isFetching, limit: limit, offset: offset, onChangeOffset: setOffset, onOpenClientOverview: openOverview, onOpenClientEditForm: openEditForm, onOpenClientAddForm: openAddModal, onExportXlsx: exportXlsx, onSegmentClick: (segment) => {
                    navigate(ROUTES.CLIENTS, {
                        state: { segmentId: segment },
                        replace: true,
                    });
                } }), _jsx(ClientEditModal, { isOpen: isClientModalOpen, onClose: closeClientModal, activeTab: clientModalActiveTab, onChangeTab: changeModalActiveTab, hasFormChanged: hasFormChanged, clientOverview: _jsx(ClientOverviewPanelContainer, { client: selectedClient.client, bonusHistory: selectedClient.bonusHistory, clientReservedBonuses: selectedClient.reservedBonuses || 0, user: user, currency: currency, onCorrectBonuses: () => {
                        closeClientModal();
                        openBonusModal();
                    }, onAddPurchase: (client) => {
                        closeClientModal();
                        setPurchaseFormClient(client);
                        openPurchaseForm();
                    } }), clientForm: _jsx(ClientEditForm, { selectedClient: selectedClient.client, countryCode: countryCode, extraFields: extraFields, isChildrenEnabled: isChildrenEnabled, onUpdated: closeClientModal, setHasFormChanged: setHasFormChanged, onDeleted: () => {
                        closeClientModal();
                        if (offset !== 0)
                            setOffset(0);
                        else
                            refetchClients();
                    } }) }), _jsx(FormClientBonusesModalContainer, { client: selectedClient.client, isOpen: bonusModalIsOpen, onClose: () => {
                    closeBonusModal();
                    openClientModal();
                } }), _jsx(ClientAddModal, { isOpen: addModalIsOpen, onClose: closeAddModal, countryCode: countryCode, extraFields: extraFields, isChildrenEnabled: isChildrenEnabled, onAdded: () => {
                    closeAddModal();
                    if (offset !== 0)
                        setOffset(0);
                    else
                        refetchClients();
                } }), _jsx(FormPurchaseModalContainer, { isOpen: purchaseFormOpen, onClose: () => {
                    closePurchaseForm();
                    openClientModal();
                }, currency: currency, selectedClient: purchaseFormClient })] })));
};
