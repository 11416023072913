import { getMovementStatus as status } from '@/components/RFMMovementsTable/RFMMovementStatus';
export const getTableData = (movements, chartType, selectedSegment, selectedBar) => {
    if (!selectedSegment)
        return movements;
    if (chartType === 'all') {
        if (selectedBar === 'left')
            return movements.filter((m) => m.from === selectedSegment);
        if (selectedBar === 'right')
            return movements.filter((m) => m.to === selectedSegment);
        return movements.filter((m) => m.from === selectedSegment || m.to === selectedSegment);
    }
    if (chartType === 'from' || chartType === 'to') {
        const filtered = movements.filter((m) => chartType === 'from'
            ? m.to === selectedSegment
            : m.from === selectedSegment);
        if (selectedBar === 'left')
            return filtered.filter((m) => status(m.from, m.to) === 'positive');
        if (selectedBar === 'right')
            return filtered.filter((m) => status(m.from, m.to) === 'negative');
        return filtered;
    }
    return movements;
};
