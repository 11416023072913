import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { TRIGGER_TYPE_BIRTHDAY, TRIGGER_TYPE_BONUS_ACTIVATE, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, TRIGGER_TYPE_LEVEL_UP, TRIGGER_TYPE_PURCHASE, TRIGGER_TYPE_SEGMENT_JOIN, TRIGGER_TYPE_SEGMENT_LEAVE, } from '@/const/mailing-triggers';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getClientLevelOptionsSelector, getProductsOptionsSelector, getRFMSegmentOptionsSelector, getSegmentOptionsSelector, getShopOptionsSelector, } from '@/store/selectors/filters';
import { getClientLevelOptionsThunk, getProductsBranchThunk, getProductsTreeThunk, getRFMSegmentOptionsThunk, getSegmentOptionsThunk, getShopOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
export const useTriggerFilters = (triggerType) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand, currency } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const segments = useAppSelector(getSegmentOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const products = useAppSelector(getProductsOptionsSelector);
    const shops = useAppSelector(getShopOptionsSelector);
    const clientLevels = useAppSelector(getClientLevelOptionsSelector);
    const clientAgeFilter = {
        type: 'leaf',
        id: 'clientAge',
        label: t('Исполнилось лет'),
        template: 'numRange',
        constraints: {
            minValue: 0,
            maxValue: 120,
        },
        inputPostfix: t('лет'),
    };
    const clientChildAgeFilter = {
        type: 'leaf',
        id: 'clientChildAge',
        label: t('Исполнилось лет'),
        template: 'numRange',
        constraints: {
            minValue: 0,
            maxValue: 120,
        },
        inputPostfix: t('лет'),
    };
    const clientGenderFilter = {
        type: 'leaf',
        id: 'clientGender',
        label: t('Пол'),
        template: 'checkboxes-simple',
        options: {
            0: t('Не задан'),
            1: t('Мужской'),
            2: t('Женский'),
        },
    };
    const clientChildGenderFilter = {
        type: 'leaf',
        id: 'clientChildGender',
        label: t('Пол'),
        template: 'checkboxes-simple',
        options: {
            0: t('Не задан'),
            1: t('Мужской'),
            2: t('Женский'),
        },
    };
    const clientSegmentsFilter = {
        type: 'leaf',
        id: 'clientSegments',
        label: t('REGULAR_SEGMENTS'),
        filterTitle: t('Сегменты'),
        template: 'checkboxes-segments',
        loadOptions: () => dispatch(getSegmentOptionsThunk()),
        options: segments,
    };
    const clientRfmSegmentsFilter = {
        type: 'leaf',
        id: 'clientRfmSegments',
        label: 'RFM',
        filterTitle: t('RFM сегменты'),
        template: 'checkboxes-segments',
        loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
        options: rfmSegments,
        locked: !isRFMEnabled,
    };
    const segmentsFilter = {
        type: 'branch',
        id: 'segments',
        label: t('Сегменты'),
        isInitiallyExpanded: true,
        items: [clientSegmentsFilter, clientRfmSegmentsFilter],
    };
    const purchaseItemFilters = {
        type: 'branch',
        id: 'purchase-what',
        label: t('Покупка'),
        items: [
            {
                type: 'leaf',
                id: 'purchaseItem',
                label: t('Товар'),
                template: 'checkboxes-tree-loader',
                loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                options: products,
            },
            {
                type: 'leaf',
                id: 'purchaseItemSku',
                label: t('Артикул'),
                template: 'textField',
            },
        ],
    };
    const purchaseAmountFilter = {
        type: 'leaf',
        id: 'purchaseAmount',
        label: t('Сумма покупки'),
        template: 'numRange',
        inputPostfix: currency,
        composePostfixToText: true,
    };
    const purchaseAmountForNewLevelFilter = {
        type: 'leaf',
        id: 'purchaseAmountUpToNextLevel',
        label: t('Сумма покупок до следующего уровня ПЛ'),
        filterTitle: t('до следующего уровня ПЛ'),
        template: 'numRange',
        inputPostfix: currency,
        composePostfixToText: true,
    };
    const purchaseShopFilter = {
        type: 'leaf',
        id: 'purchaseShop',
        label: t('Место покупки'),
        template: 'checkboxes-loader',
        loadOptions: () => dispatch(getShopOptionsThunk()),
        options: shops,
    };
    const bonusAmountFilter = {
        type: 'leaf',
        id: 'bonusAmount',
        label: t('Количество бонусов'),
        template: 'numRange',
    };
    const clientLevelFilter = {
        type: 'leaf',
        id: 'clientLevel',
        label: t('Уровень'),
        template: 'checkboxes-loader',
        loadOptions: () => dispatch(getClientLevelOptionsThunk()),
        options: clientLevels,
    };
    switch (Number(triggerType)) {
        case TRIGGER_TYPE_BIRTHDAY: {
            return [clientAgeFilter, clientGenderFilter];
        }
        case TRIGGER_TYPE_CHILD_BIRTHDAY: {
            return [clientChildAgeFilter, clientChildGenderFilter];
        }
        case TRIGGER_TYPE_SEGMENT_JOIN:
        case TRIGGER_TYPE_SEGMENT_LEAVE: {
            return [segmentsFilter];
        }
        case TRIGGER_TYPE_PURCHASE: {
            return [
                purchaseItemFilters,
                purchaseAmountFilter,
                purchaseAmountForNewLevelFilter,
                purchaseShopFilter,
            ];
        }
        case TRIGGER_TYPE_BONUS_ACTIVATE:
        case TRIGGER_TYPE_BONUS_EXPIRING: {
            return [bonusAmountFilter];
        }
        case TRIGGER_TYPE_LEVEL_UP: {
            return [clientLevelFilter];
        }
        default: {
            return [];
        }
    }
};
