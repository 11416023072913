var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useCreateMailingMutation, useUpdateMailingMutation, } from '@/api/query/mailings';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppDispatch } from '@/hooks/store';
import { resetEmailDesignList } from '@/store/slice/mailings';
import { convertFormValuesToMailing } from './convertFormValuesToMailing';
export const useSaveMailing = ({ type, id, flowId, onSuccess, }) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const addToast = useToastContext();
    const dispatch = useAppDispatch();
    const [createMailing, { isLoading: isCreating }] = useCreateMailingMutation();
    const [updateMailing, { isLoading: isUpdating }] = useUpdateMailingMutation();
    const saveMailing = (values, isDraft = false) => __awaiter(void 0, void 0, void 0, function* () {
        const successMessage = id ? t('Рассылка сохранена') : t('Рассылка создана');
        let result;
        const payload = convertFormValuesToMailing(type, values, isDraft);
        payload.flowId = flowId;
        if (id)
            result = yield updateMailing(payload);
        else
            result = yield createMailing(payload);
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else {
            dispatch(resetEmailDesignList());
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
    return {
        saveMailing,
        isSubmitting: isCreating || isUpdating,
        error,
    };
};
