import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AutocompleteButton } from '@/components/Autocomplete/variants/AutocompleteButton';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormOfferProductCounter as ProductCounter } from '@/forms/FormOffer/controls/FormOfferProductCounter';
import styles from './styles.module.scss';
export const FormCounterApplyOn = ({ conditionsProductCount = 0, currency, labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const applyOnOptions = [
        {
            id: 'product',
            label: t('товары'),
            renderOption: () => conditionsProductCount ? (_jsx(ProductCounter, { count: conditionsProductCount, threshold: 3 })) : (t('товары')),
            help: (_jsxs(Trans, Object.assign({ i18nKey: 'COUNTER_APPLY_ON_PRODUCT_HELP' }, { children: ["\u0412\u043E\u0437\u043D\u0430\u0433\u0440\u0430\u0436\u0434\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0432\u044B\u0434\u0430\u0432\u0430\u0442\u044C\u0441\u044F \u0437\u0430 N-\u0439 \u043A\u0443\u043F\u043B\u0435\u043D\u043D\u044B\u0439", ' ', _jsx("span", Object.assign({ className: styles.bold }, { children: "\u0442\u043E\u0432\u0430\u0440" })), " \u0438\u043B\u0438", _jsx("span", Object.assign({ className: styles.bold }, { children: "\u043D\u0430\u0431\u043E\u0440 \u0442\u043E\u0432\u0430\u0440\u043E\u0432" })), " \u2014 \u043D\u0435\u0437\u0430\u0432\u0438\u0441\u0438\u043C\u043E \u043E\u0442 \u0447\u0435\u043A\u0430. \u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440 \u0437\u0430 \u043A\u0430\u0436\u0434\u044B\u0439 3-\u0439 \u0442\u043E\u0432\u0430\u0440 \u043A\u0443\u043F\u043B\u0435\u043D\u043D\u044B\u0439 \u0432 \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u0438\u0445 \u0447\u0435\u043A\u0430\u0445 \u2014 \u0442\u043E\u0432\u0430\u0440 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A"] }))),
        },
        {
            id: 'purchase',
            label: t('покупки'),
            renderOption: () => t('покупки'),
            help: (_jsxs(Trans, Object.assign({ i18nKey: 'COUNTER_APPLY_ON_PURCHASE_HELP', values: { currency } }, { children: ["\u0412\u043E\u0437\u043D\u0430\u0433\u0440\u0430\u0436\u0434\u0435\u043D\u0438\u0435 \u0431\u0443\u0434\u0435\u0442 \u0432\u044B\u0434\u0430\u0432\u0430\u0442\u044C\u0441\u044F \u0437\u0430 N-\u044E", ' ', _jsx("span", Object.assign({ className: styles.bold }, { children: "\u043F\u043E\u043A\u0443\u043F\u043A\u0443" })), " \u0441 \u0437\u0430\u0434\u0430\u043D\u043D\u044B\u043C\u0438 \u0445\u0430\u0440\u0430\u043A\u0442\u0435\u0440\u0438\u0441\u0442\u0438\u043A\u0430\u043C\u0438. \u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440 \u0437\u0430 \u043A\u0430\u0436\u0434\u0443\u044E 3-\u044E \u043F\u043E\u043A\u0443\u043F\u043A\u0443 \u0441\u0443\u043C\u043C\u043E\u0439 \u0432\u044B\u0448\u0435 2000 \u20BD \u2014 \u0442\u043E\u0432\u0430\u0440 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A"] }))),
        },
    ];
    return (_jsx(FormFieldRow, Object.assign({ className: styles.row, label: t('Считать'), labelFor: 'counterApplyOn', labelWidth: labelWidth, labelClassName: styles.label, controlSize: '180' }, { children: _jsx(Controller, { name: 'applyOn', control: control, render: ({ field }) => (_jsx(AutocompleteButton, Object.assign({ className: styles.counterApplyOn }, field, { id: 'counterApplyOn', value: applyOnOptions.find((item) => item.id === field.value) || null, onChange: (item) => {
                    setValue('applyOn', item === null || item === void 0 ? void 0 : item.id);
                }, options: applyOnOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, getOptionHelp: (option) => option.help, renderOption: (option) => option === null || option === void 0 ? void 0 : option.renderOption(), hasResetButton: false, disablePortal: true }))) }) })));
};
