var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ELK from 'elkjs/lib/elk.bundled.js';
const elk = new ELK();
const defaultOptions = {
    'elk.algorithm': 'mrtree',
    'elk.direction': 'RIGHT',
    'elk.spacing.nodeNode': '50',
    'elk.edgeRouting': 'ORTHOGONAL',
};
export const layoutELK = (nodes, edges, interactive) => __awaiter(void 0, void 0, void 0, function* () {
    const graph = {
        id: 'trigger',
        layoutOptions: defaultOptions,
        children: nodes.map((node) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, node), { width: ((_a = node.measured) === null || _a === void 0 ? void 0 : _a.width) || 0, height: ((_b = node.measured) === null || _b === void 0 ? void 0 : _b.height) || 0, selectable: interactive && node.type === 'channel', focusable: false, deletable: false, draggable: false, connectable: false }));
        }),
        edges: edges.map((edge) => (Object.assign(Object.assign({}, edge), { sources: [edge.source], targets: [edge.target], selectable: false, focusable: false, deletable: false, draggable: false, connectable: false }))),
    };
    const layout = yield elk.layout(graph);
    if (layout.children && layout.edges) {
        layout.children.forEach((child) => {
            const node = child;
            node.position = { x: child.x || 0, y: child.y || 0 };
        });
        return {
            nodes: layout.children,
            edges: layout.edges,
        };
    }
});
