import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FlowFormHeader } from '@/components/flow/FlowFormHeader/FlowFormHeader';
import { useFlowValidation } from '@/components/flow/InteractiveFlow/hooks/useFlowValidation';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Pause } from '@/icons/Pause';
import { PlayAlt } from '@/icons/PlayAlt';
import { useDeleteFlow } from '../../Flows/useDeleteFlow';
import styles from './styles.module.scss';
import { useCreateDraft } from './useCreateDraft';
import { usePauseFlow } from './usePauseFlow';
import { usePublishFlow } from './usePublishFlow';
import { useSetFlow } from './useSetFlow';
import { useStartFlow } from './useStartFlow';
export const InteractiveFlowHeader = ({ flow, triggerType, currentNodes, currentEdges, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const { validateFlow } = useFlowValidation();
    const handleGoBack = () => {
        navigate(ROUTES.FLOWS);
    };
    const { createDraft, isCreatingDraft } = useCreateDraft({
        triggerType,
        nodes: currentNodes,
        edges: currentEdges,
        onSuccess: (id) => {
            const path = generatePath(ROUTES.FLOWS_EDIT, { flowId: id.toString() });
            navigate(path);
        },
    });
    const { updateFlow, isUpdatingFlow } = useSetFlow();
    const { deleteFlow, isDeletingFlow } = useDeleteFlow(handleGoBack);
    const { publishFlow, isPublishingFlow } = usePublishFlow();
    const { pauseFlow, isPausingFlow } = usePauseFlow();
    const { startFlow, isStartingFlow } = useStartFlow();
    const startButton = (_jsx(Tooltip, Object.assign({ title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(PlayAlt, {}), theme: 'primary', size: 'large', onClick: () => {
                if (flow)
                    startFlow(flow.id);
            }, disabled: (flow === null || flow === void 0 ? void 0 : flow.status) !== 'pause' || isStartingFlow }, { children: t('Активировать') })) })));
    const publishButton = (flow === null || flow === void 0 ? void 0 : flow.hasChanges) ? (_jsx(Tooltip, Object.assign({ title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ size: 'large', onClick: () => {
                const isValid = validateFlow();
                if (isValid && flow)
                    publishFlow(flow.id);
            }, disabled: isPublishingFlow }, { children: t('Опубликовать черновик') })) }))) : null;
    const pauseButton = (flow === null || flow === void 0 ? void 0 : flow.status) === 'active' ? (_jsx(Tooltip, Object.assign({ title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Pause, {}), size: 'large', onClick: () => {
                if (flow)
                    pauseFlow(flow.id);
            }, disabled: isPausingFlow }, { children: t('Выключить цепочку') })) }))) : null;
    return (_jsx(FlowFormHeader, { flow: flow, userHasFlowsManage: userHasFlowsManage, rightContent: _jsxs("div", Object.assign({ className: styles.toolbar }, { children: [publishButton, pauseButton, startButton] })), onCreateFlow: createDraft, isCreatingFlow: isCreatingDraft, onUpdateFlow: updateFlow, isUpdatingFlow: isUpdatingFlow, deleteButtonLabel: t('Распустить'), onDeleteFlow: deleteFlow, isDeletingFlow: isDeletingFlow, onGoBack: handleGoBack, isDraft: flow === null || flow === void 0 ? void 0 : flow.hasChanges }));
};
