export const tools = {
    text: {
        position: 2,
    },
    image: {
        position: 3,
    },
    html: {
        enabled: false,
    },
    timer: {
        enabled: true,
        properties: {
            countdown: {
                value: {
                    timezone: 'Europe/Moscow',
                },
            },
        },
    },
    video: {
        enabled: true,
    },
    social: {
        properties: {
            icons: {
                value: {
                    editor: {
                        data: {
                            customIcons: [
                                {
                                    name: 'VK',
                                    url: 'https://vk.com/',
                                    icons: {
                                        circle: 'https://s.maxma.com/m/buttons/social/circle/vk.png',
                                        'circle-black': 'https://s.maxma.com/m/buttons/social/circle-black/vk.png',
                                        'circle-white': 'https://s.maxma.com/m/buttons/social/circle-white/vk.png',
                                        rounded: 'https://s.maxma.com/m/buttons/social/rounded/vk.png',
                                        'rounded-black': 'https://s.maxma.com/m/buttons/social/rounded-black/vk.png',
                                        squared: 'https://s.maxma.com/m/buttons/social/squared/vk.png',
                                        'squared-black': 'https://s.maxma.com/m/buttons/social/squared-black/vk.png',
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
};
