import { useEffect, useState } from 'react';
const DEFAULT_DEBOUNCE_DELAY = 500;
export function useDebounce({ value, delay = DEFAULT_DEBOUNCE_DELAY, callback, }) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            if (debouncedValue !== value) {
                setDebouncedValue(value);
                if (callback)
                    callback(value);
            }
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay, callback]);
    return debouncedValue;
}
