import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { externalTooltipHandler } from '@/components/ChartTooltip/ChartTooltip';
import { useChart } from '@/hooks/useChart';
export const DoughnutChart = memo(({ labels, data, colors }) => {
    const canvas = useChart({
        type: 'doughnut',
        options: {
            elements: {
                arc: { borderWidth: 0 },
            },
            plugins: {
                legend: { display: false },
                tooltip: {
                    enabled: false,
                    external: externalTooltipHandler,
                },
            },
        },
        data: {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: colors,
                },
            ],
        },
    });
    return _jsx("canvas", { ref: canvas });
});
