export const getScheduleTypeLabel = (type) => {
    switch (type) {
        case 'daily': {
            return 'Каждый день';
        }
        case 'weekly': {
            return 'Каждую неделю';
        }
        case 'monthly': {
            return 'Каждый месяц';
        }
        case 'yearly': {
            return 'Каждый год';
        }
    }
};
