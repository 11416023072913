export const getPreviewSize = ({ previewWidth, previewHeight, imageWidth, imageHeight, maintainPreviewAspectRatio, }) => {
    if (!maintainPreviewAspectRatio)
        return { width: previewWidth, height: previewHeight };
    if (maintainPreviewAspectRatio === true ||
        maintainPreviewAspectRatio === 'width') {
        const ratio = imageWidth / imageHeight;
        return { width: previewHeight * ratio, height: previewHeight };
    }
    const ratio = imageHeight / imageWidth;
    return { width: previewWidth, height: previewWidth * ratio };
};
