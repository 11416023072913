import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getShopOptionsSelector } from '@/store/selectors/filters';
import { getShopOptionsThunk } from '@/store/thunk/filters';
export const usePromocodeConditionsFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shops = useAppSelector(getShopOptionsSelector);
    const tree = [
        {
            type: 'leaf',
            id: 'purchaseShop',
            label: t('Место продажи'),
            template: 'checkboxes-loader',
            loadOptions: () => dispatch(getShopOptionsThunk()),
            options: shops,
        },
    ];
    return tree;
};
