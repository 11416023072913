var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/Modal/Modal';
import { FormClient } from '@/forms/FormClient/FormClient';
import { User } from '@/icons/User';
import { NotificationOfClosureModalForForm } from '../NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { useAddClient } from './useAddClient';
import { useData } from './useData';
export const ClientAddModal = memo((_a) => {
    var { isOpen, onClose, onAdded } = _a, props = __rest(_a, ["isOpen", "onClose", "onAdded"]);
    const { t } = useTranslation();
    const { levels, shops } = useData();
    const { addClient, isAdding, addError } = useAddClient();
    const [hasFormChanged, setHasFormChanged] = useState(false);
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, titleIcon: _jsx(User, { size: 24 }), title: t('Добавить клиента'), isForm: true, hasNoPadding: true, size: '870', isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormClient, Object.assign({}, props, { setHasFormChanged: setHasFormChanged, levels: levels, shops: shops, onSubmit: (formValues) => __awaiter(void 0, void 0, void 0, function* () {
                const added = yield addClient(formValues);
                if (added && onAdded)
                    onAdded();
            }), isSubmitting: isAdding, error: addError })) })));
});
