import { useCallback } from 'react';
export const useHandleEdgeHover = (type, setEdges) => {
    return useCallback((e, hoveredEdge) => {
        var _a;
        if (hoveredEdge.type !== 'custom')
            return;
        if (!((_a = hoveredEdge.data) === null || _a === void 0 ? void 0 : _a.isInteractive))
            return;
        setEdges((edges) => edges.map((edge) => {
            if (edge.id === hoveredEdge.id)
                return Object.assign(Object.assign({}, edge), { data: Object.assign(Object.assign({}, (edge.data || {})), { isHovered: type === 'enter' }), animated: type === 'enter' });
            return edge;
        }));
    }, []);
};
