import { isItemActive } from './isItemActive';
export const findActiveSectionId = (nav, currentRoute) => {
    for (let i = 0; i < nav.length; i += 1) {
        const group = nav[i];
        for (let j = 0; j < group.length; j += 1) {
            const section = group[j];
            const hasActiveItem = section.items.some((i) => isItemActive(i, currentRoute)) ||
                isItemActive(section, currentRoute);
            if (hasActiveItem)
                return section.id;
        }
    }
    return nav[0][0].id;
};
