import { escape } from './html';
export const jsonHandler = (editorState, callbacks) => {
    const content = [];
    const onText = callbacks.onText || ((text) => escape(text));
    const onSpace = callbacks.onSpace || ((text) => text);
    const onLink = callbacks.onLink ||
        ((link) => `<a href="${link.url}" target="_blank" rel="noopener noreferrer nofollow">${link.text}</a>`);
    const onVariable = callbacks.onVariable;
    editorState.root.children.forEach((node) => {
        const paragraph = node;
        const line = [];
        paragraph.children.forEach((node) => {
            if (node.type === 'text') {
                const text = node;
                line.push(onText(text.text));
            }
            if (node.type === 'space') {
                const text = node;
                line.push(onSpace(text.text));
            }
            if (node.type === 'custom-link') {
                const link = node;
                line.push(onLink(link));
            }
            if (node.type === 'variable') {
                const variable = node;
                line.push(onVariable(variable));
            }
        });
        content.push(line.join(''));
    });
    return content.join('\n');
};
