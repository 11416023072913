var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api } from './';
export const walletCardsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getWalletCardList: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand-wallet/list',
            }),
            providesTags: ['WalletCardList'],
        }),
        getWalletCard: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/brand-wallet/card/${id}`,
            }),
            providesTags: ['WalletCard'],
        }),
        setWalletCard: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/brand-wallet/card/set',
                body,
            }),
            invalidatesTags: ['WalletCardList'],
        }),
        publishWalletCards: build.mutation({
            query: () => ({
                method: 'POST',
                url: '/brand-wallet/publish',
            }),
            invalidatesTags: ['WalletCardList'],
        }),
        getGeoPush: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/brand-wallet/geo/${id}`,
            }),
            providesTags: ['WalletGeo'],
        }),
        createGeoPush: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/brand-wallet/geo/add',
                body: {
                    configuration: body,
                },
            }),
            invalidatesTags: ['WalletCardList'],
        }),
        updateGeoPush: build.mutation({
            query: ({ id, payload }) => ({
                method: 'POST',
                url: `/brand-wallet/geo/${id}/set`,
                body: {
                    configuration: payload,
                },
            }),
            invalidatesTags: ['WalletCardList'],
        }),
        setGeoPushActive: build.mutation({
            query: ({ id, isActive }) => ({
                method: 'POST',
                url: `/brand-wallet/geo/${id}/set/active`,
                body: { isActive },
            }),
            onQueryStarted({ id, isActive }, { dispatch, queryFulfilled }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield queryFulfilled;
                    const wasError = 'error' in result.data;
                    if (!wasError)
                        dispatch(walletCardsApi.util.updateQueryData('getWalletCardList', undefined, (draft) => {
                            draft.walletGeos = draft.walletGeos.map((geo) => geo.id === id ? Object.assign(Object.assign({}, geo), { isActive, hasChanges: true }) : geo);
                        }));
                });
            },
        }),
        deleteGeoPush: build.mutation({
            query: ({ id }) => ({
                method: 'POST',
                url: `/brand-wallet/geo/delete/${id}`,
            }),
            invalidatesTags: ['WalletCardList'],
        }),
    }),
});
export const { useGetWalletCardListQuery, useGetWalletCardQuery, useSetWalletCardMutation, usePublishWalletCardsMutation, useGetGeoPushQuery, useCreateGeoPushMutation, useUpdateGeoPushMutation, useSetGeoPushActiveMutation, useDeleteGeoPushMutation, } = walletCardsApi;
