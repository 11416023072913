var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useRegisterImages = (uploadImage) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    return (editor) => {
        editor.registerCallback('image', (file, done) => __awaiter(void 0, void 0, void 0, function* () {
            done({ progress: 50 });
            try {
                const url = yield uploadImage(file.attachments[0]);
                done({ progress: 100, url });
            }
            catch (e) {
                let message = t('Ошибка загрузки изображения');
                if (typeof e === 'string')
                    message = e;
                if (typeof e === 'object')
                    message = (e === null || e === void 0 ? void 0 : e.message) || '';
                addToast({ type: 'error', message });
            }
        }));
    };
};
