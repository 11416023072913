import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormCounterApplyOn } from './FormCounterApplyOn';
import { FormCounterInfo } from './FormCounterInfo';
import { FormCounterName } from './FormCounterName';
import { FormCounterPeriodType } from './FormCounterPeriodType';
import { FormCounterRequiredValue } from './FormCounterRequiredValue';
import styles from './styles.module.scss';
export const FormCounter = memo(({ id, value = {}, onChange, conditionsProductCount, currency }) => {
    const methods = useForm({
        defaultValues: {
            name: value.name || '',
            requiredValue: value.requiredValue || '',
            applyOn: value.applyOn || 'product',
            periodType: value.periodType || 'period',
            periodValue: value.periodValue || '6',
            periodUnit: value.periodUnit || 'month',
        },
    });
    const labelWidth = 22;
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        methods.handleSubmit(onChange)();
    };
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("form", Object.assign({ id: id, className: styles.form, onSubmit: handleSubmit }, { children: [_jsx(FormCounterName, { labelWidth: labelWidth }), _jsx(FormCounterApplyOn, { conditionsProductCount: conditionsProductCount, currency: currency, labelWidth: labelWidth }), _jsx(FormCounterRequiredValue, { labelWidth: labelWidth }), _jsx(FormCounterPeriodType, { labelWidth: labelWidth })] })), _jsx(FormCounterInfo, {})] })) })));
});
