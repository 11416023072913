import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { SegmentFilterInput } from '@/components/SegmentFilterInput/SegmentFilterInput';
import { CHECKBOXES_HAS_SEARCH_THRESHOLD } from './constants';
import { Wrapper } from './Wrapper';
export const CheckboxesSegmentsBoth = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [draft, setDraft] = useState(item);
    useEffect(() => setDraft(Object.assign(Object.assign({}, draft), { options: item.options })), [item.options]);
    useEffect(() => {
        if (!item.options)
            item.loadOptions();
    }, []);
    const handleChangeIncludeValues = (includeValues) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { includeValues }) }));
    };
    const handleChangeExcludeValues = (excludeValues) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { excludeValues }) }));
    };
    const handleApply = () => onApply(draft);
    const hasSearch = !!item.options &&
        Object.keys(item.options).length > CHECKBOXES_HAS_SEARCH_THRESHOLD;
    const isValid = !!((_b = (_a = draft.value) === null || _a === void 0 ? void 0 : _a.includeValues) === null || _b === void 0 ? void 0 : _b.length) ||
        !!((_d = (_c = draft.value) === null || _c === void 0 ? void 0 : _c.excludeValues) === null || _d === void 0 ? void 0 : _d.length);
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, isHeaderVisible: false, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, isLoading: !item.options, count: (_f = (_e = draft.value) === null || _e === void 0 ? void 0 : _e.includeValues) === null || _f === void 0 ? void 0 : _f.length, excludeCount: (_h = (_g = draft.value) === null || _g === void 0 ? void 0 : _g.excludeValues) === null || _h === void 0 ? void 0 : _h.length }, { children: _jsx(SegmentFilterInput, { segments: item.options || [], includeIds: ((_j = draft.value) === null || _j === void 0 ? void 0 : _j.includeValues) || [], onChangeIncludeIds: handleChangeIncludeValues, excludeIds: ((_k = draft.value) === null || _k === void 0 ? void 0 : _k.excludeValues) || [], onChangeExcludeIds: handleChangeExcludeValues, hasSearch: hasSearch }) })));
});
