import { useTranslation } from 'react-i18next';
export function useDelayContent(offsetSign, offsetUnit, offsetValue) {
    const { t } = useTranslation();
    const offsetSignLabel = offsetSign.toString() === '1' ? t('через') : t('за');
    const offsetUnitLabelMap = {
        minute: t('OFFSET_VALUE_MIN', { offset: offsetValue }),
        hour: t('OFFSET_VALUE_HOUR', { offset: offsetValue }),
        day: t('OFFSET_VALUE_DAY', { offset: offsetValue }),
        month: t('OFFSET_VALUE_MONTH', { offset: offsetValue }),
    };
    const offsetUnitLabel = offsetUnitLabelMap[offsetUnit];
    return [offsetSignLabel, offsetValue, offsetUnitLabel].join(' ');
}
