import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { CheckboxSearchList } from '@/components/CheckboxSearchList/CheckboxSearchList';
import { Wrapper } from './Wrapper';
export const CheckboxesSimple = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (itemIds) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value: itemIds }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = !!((_b = (_a = draft.value) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length);
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: ((_c = draft.value) === null || _c === void 0 ? void 0 : _c.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, count: (_e = (_d = draft.value) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.length }, { children: _jsx(CheckboxSearchList, { items: Object.entries(item.options).map(([optValue, optLabel]) => ({
                id: optValue,
                label: optLabel,
            })), selectedItemIds: ((_g = (_f = draft.value) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.map((i) => i.toString())) || [], onChange: handleChangeValue }) })));
});
