import { useLocation, useParams } from 'react-router-dom';
export const useGeoPushId = (defaultGeoPushId) => {
    var _a, _b, _c;
    const location = useLocation();
    const params = useParams();
    const isClone = !!((_a = location.state) === null || _a === void 0 ? void 0 : _a.clone);
    const cloneId = isClone && ((_b = location.state) === null || _b === void 0 ? void 0 : _b.geoPushId)
        ? Number((_c = location.state) === null || _c === void 0 ? void 0 : _c.geoPushId)
        : undefined;
    const geoPushId = params.geoPushId
        ? Number(params.geoPushId)
        : defaultGeoPushId;
    return { geoPushId, cloneId };
};
