import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { RFMValueTable } from '@/components/RFMValueTable/RFMValueTable';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const RFMSegmentInfo = ({ segment, currency, shouldDisplayProfit, isLocked, }) => {
    const { t } = useTranslation();
    const { revenue = 0, profit = 0, purchasesCount = 0, arppu = 0, avgSale = 0, clientsCount = 0, clientsPercent = 0, } = segment.data || {};
    const backgroundColor = segment.darkBackground || segment.background;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("div", Object.assign({ className: styles.title }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: segment.label })), _jsx("div", { className: styles.color, style: { backgroundColor } })] })), _jsx(RFMValueTable, { groups: [
                    shouldDisplayProfit
                        ? { label: t('Прибыль'), value: profit, scale: 'money' }
                        : { label: t('Выручка'), value: revenue, scale: 'money' },
                    { label: t('Кол-во покупок'), value: purchasesCount, isLocked },
                    { label: t('ARPPU'), value: arppu, scale: 'money', isLocked },
                    { label: t('Средний чек'), value: avgSale, scale: 'money', isLocked },
                ], currency: currency, isDark: true, isCondensed: true }), _jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsx("div", Object.assign({ className: styles.clientsCount }, { children: t('CLIENTS', {
                            amount: numberRound(clientsCount),
                            count: clientsCount,
                        }) })), _jsxs("div", Object.assign({ className: styles.clientsPercent }, { children: [numberRound(clientsPercent), "%"] }))] }))] })));
};
