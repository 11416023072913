import moment from 'moment';
import { api } from './';
export const giftCardApi = api.injectEndpoints({
    endpoints: (build) => ({
        getGiftCardCount: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/gift-card/count',
                body,
            }),
            providesTags: ['GiftCardCount'],
        }),
        getGiftCardList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/gift-card/list',
                body,
            }),
            providesTags: ['GiftCardList'],
        }),
        getGiftCardOptions: build.query({
            query: () => ({
                method: 'GET',
                url: '/gift-card/options-list-digital',
            }),
            providesTags: ['GiftCardOptions'],
        }),
        deleteGiftCard: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/gift-card/delete/${id}`,
            }),
            invalidatesTags: ['GiftCardCount', 'GiftCardList', 'GiftCardOptions'],
        }),
        generateGiftCards: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/gift-card/generate',
                body,
            }),
            invalidatesTags: ['GiftCard'],
        }),
        getGiftCard: build.query({
            query: (id) => ({
                url: `/gift-card/get/${id}`,
            }),
            providesTags: ['GiftCard'],
        }),
        getGiftCardInstanceList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/gift-card/instance-list',
                body,
            }),
            providesTags: ['GiftCardInstanceList'],
        }),
        activateGiftCardInstance: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/gift-card/instance-activate/${id}`,
            }),
            invalidatesTags: ['GiftCardInstanceList'],
        }),
        toggleBlockGiftCardInstance: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/gift-card/instance-toggle-block/${id}`,
            }),
            invalidatesTags: ['GiftCardInstanceList'],
        }),
        getInitialGiftCard: build.query({
            query: () => ({
                url: '/gift-card/get-initial',
            }),
            providesTags: ['GiftCardInitial'],
            keepUnusedDataFor: 0,
        }),
        createGiftCard: build.mutation({
            query: (body) => ({
                method: 'post',
                url: '/gift-card/create',
                body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                        ? moment(body.availableFrom).format('DD.MM.YYYY')
                        : undefined, availableTo: body.availableTo
                        ? moment(body.availableTo).format('DD.MM.YYYY')
                        : undefined }),
            }),
            invalidatesTags: [
                'GiftCardCount',
                'GiftCardList',
                'GiftCardOptions',
                'GiftCard',
            ],
        }),
        updateGiftCard: build.mutation({
            query: ({ id, payload: body }) => ({
                method: 'POST',
                url: `/gift-card/update/${id}`,
                body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                        ? moment(body.availableFrom).format('DD.MM.YYYY')
                        : undefined, availableTo: body.availableTo
                        ? moment(body.availableTo).format('DD.MM.YYYY')
                        : undefined }),
            }),
            invalidatesTags: ['GiftCardList', 'GiftCardOptions', 'GiftCard'],
        }),
        getBrandGiftCardList: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/gift-cards',
            }),
            providesTags: ['BrandGiftCardList'],
        }),
    }),
});
export const selectGiftCards = (state) => giftCardApi.endpoints.getGiftCardList.select({
    periodStart: state.appPeriod.start.format(),
    periodEnd: state.appPeriod.end.format(),
    limit: 100,
    offset: 0,
    searchText: '',
})(state);
export const { useGetGiftCardCountQuery, useGetGiftCardListQuery, useGetGiftCardOptionsQuery, useDeleteGiftCardMutation, useGenerateGiftCardsMutation, useGetGiftCardQuery, useGetGiftCardInstanceListQuery, useActivateGiftCardInstanceMutation, useToggleBlockGiftCardInstanceMutation, useGetInitialGiftCardQuery, useCreateGiftCardMutation, useUpdateGiftCardMutation, useGetBrandGiftCardListQuery, } = giftCardApi;
