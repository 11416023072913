import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export const useDashboardDimensions = (isRFMEnabled = false) => {
    const { t } = useTranslation();
    return useMemo(() => [
        {
            id: 'year',
            label: t('по годам'),
        },
        {
            id: 'month',
            label: t('по месяцам'),
        },
        {
            id: 'promocode',
            label: t('по промокодам'),
        },
        {
            id: 'shop',
            label: t('по торговым точкам'),
        },
        {
            id: 'cashier',
            label: t('по кассирам'),
        },
        {
            id: 'mailing',
            label: t('по рассылкам'),
        },
        {
            id: 'gender',
            label: t('по полу'),
        },
        {
            id: 'segment',
            label: t('по сегментам'),
        },
        {
            id: 'rfm',
            label: t('по RFM сегментам'),
            locked: !isRFMEnabled,
        },
    ], [t]);
};
