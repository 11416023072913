var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useGetShopGroupListManualMutation } from '@/api/query/shop-group';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { shopGroupsSelector } from '@/store/selectors/shopGroups';
import { setShopGroups } from '@/store/slice/shopGroups';
export const useShopGroupList = () => {
    const shopGroups = useAppSelector(shopGroupsSelector);
    const dispatch = useAppDispatch();
    const [fetchShopGroups, shopGroupsQueryState] = useGetShopGroupListManualMutation();
    const refetchShopGroups = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield fetchShopGroups();
            if (!('error' in result))
                dispatch(setShopGroups({ shopGroups: result.data || [] }));
        }
        catch (_a) {
            // do nothing
        }
    });
    useEffect(() => {
        if (!shopGroups.length)
            refetchShopGroups();
    }, [shopGroups.length]);
    return { shopGroups, refetchShopGroups, shopGroupsQueryState };
};
