var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSearchCashiersMutation } from '@/api/query/brand';
export const useCashiers = () => {
    const [searchCashier] = useSearchCashiersMutation();
    return (searchText) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const cashiersData = yield searchCashier({
            searchText,
            limit: 100,
            offset: 0,
        });
        if ('error' in cashiersData)
            return [];
        return (_a = cashiersData.data) === null || _a === void 0 ? void 0 : _a.results;
    });
};
