import { useTranslation } from 'react-i18next';
import { useGetBrandLevelsQuery } from '@/api/query/brand';
import { useCurrentUser } from '@/app/useCurrentUser';
export const DEFAULT_LEVEL = 1;
export const useLevels = () => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const { data = [], isLoading, isFetching, isError, } = useGetBrandLevelsQuery(undefined, {
        skip: !brand,
    });
    const levels = data.length
        ? data.map((level) => ({ id: level.level, name: level.name }))
        : [{ id: DEFAULT_LEVEL, name: t('Карта Wallet') }];
    return {
        levels,
        isLoading,
        isFetching,
        isError,
        hasNoLevels: !data.length,
    };
};
