import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { FlowsTableEmptyIcon } from './FlowsTableEmptyIcon';
import styles from './styles.module.scss';
export const FlowsTableEmpty = ({ onCreateFlowClick, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowManage = userHasAccessTo('FLOWS_MANAGE');
    return (_jsxs("div", Object.assign({ className: styles.empty }, { children: [_jsx("div", Object.assign({ className: styles.emptyIcon }, { children: _jsx(FlowsTableEmptyIcon, {}) })), _jsx("div", Object.assign({ className: styles.emptyTitle }, { children: t('Пока не создано ни одной цепочки') })), _jsx("div", Object.assign({ className: styles.emptyDescription }, { children: t('Соберите рассылки с одним триггером в цепочку, чтобы увидеть их на холсте') })), _jsx(Tooltip, Object.assign({ title: !userHasFlowManage && t('Недоступно для вашей роли'), placement: 'bottom' }, { children: _jsx("div", { children: _jsx(Button, Object.assign({ type: 'button', icon: _jsx(Plus, {}), size: 'large', onClick: onCreateFlowClick, disabled: !userHasFlowManage }, { children: t('Собрать цепочку') })) }) }))] })));
};
