var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSendTestMessageMutation, } from '@/api/query/mailings';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useSendTestMessage = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [sendTestMessage] = useSendTestMessageMutation();
    return (type, targets, data) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        const walletImageId = ((_a = data === null || data === void 0 ? void 0 : data.push) === null || _a === void 0 ? void 0 : _a.walletImageId) || ((_c = (_b = data === null || data === void 0 ? void 0 : data.push) === null || _b === void 0 ? void 0 : _b.walletImage) === null || _c === void 0 ? void 0 : _c.id);
        const viberImageId = ((_d = data === null || data === void 0 ? void 0 : data.viber) === null || _d === void 0 ? void 0 : _d.viberImageId) || ((_f = (_e = data === null || data === void 0 ? void 0 : data.viber) === null || _e === void 0 ? void 0 : _e.viberImage) === null || _f === void 0 ? void 0 : _f.id);
        const telegramImageId = ((_g = data === null || data === void 0 ? void 0 : data.telegram) === null || _g === void 0 ? void 0 : _g.telegramImageId) || ((_j = (_h = data === null || data === void 0 ? void 0 : data.telegram) === null || _h === void 0 ? void 0 : _h.telegramImage) === null || _j === void 0 ? void 0 : _j.id);
        const bonusesReward = (_k = data === null || data === void 0 ? void 0 : data.rewards) === null || _k === void 0 ? void 0 : _k.find((i) => i.type === 'bonuses');
        const payload = {
            type,
            knownParams: {
                bonusesAmount: bonusesReward === null || bonusesReward === void 0 ? void 0 : bonusesReward.bonusesAmount,
                bonusesLifetime: bonusesReward === null || bonusesReward === void 0 ? void 0 : bonusesReward.bonusesLifetime,
            },
        };
        if (type === 'sms') {
            payload.phones = targets;
            payload.smsSenderId = (_l = data === null || data === void 0 ? void 0 : data.sms) === null || _l === void 0 ? void 0 : _l.smsSenderId;
            payload.smsText = (_m = data === null || data === void 0 ? void 0 : data.sms) === null || _m === void 0 ? void 0 : _m.smsText;
        }
        if (type === 'viber') {
            payload.phones = targets;
            payload.viberText = (_o = data === null || data === void 0 ? void 0 : data.viber) === null || _o === void 0 ? void 0 : _o.viberText;
            payload.viberButton = (_p = data === null || data === void 0 ? void 0 : data.viber) === null || _p === void 0 ? void 0 : _p.viberButton;
            payload.viberUrl = (_q = data === null || data === void 0 ? void 0 : data.viber) === null || _q === void 0 ? void 0 : _q.viberUrl;
            payload.viberImageId = viberImageId || null;
        }
        if (type === 'telegram') {
            payload.phones = targets;
            payload.telegramText = (_r = data === null || data === void 0 ? void 0 : data.telegram) === null || _r === void 0 ? void 0 : _r.telegramText;
            payload.telegramButton = (_s = data === null || data === void 0 ? void 0 : data.telegram) === null || _s === void 0 ? void 0 : _s.telegramButton;
            payload.telegramUrl = (_t = data === null || data === void 0 ? void 0 : data.telegram) === null || _t === void 0 ? void 0 : _t.telegramUrl;
            payload.telegramImageId = telegramImageId || null;
        }
        if (type === 'push') {
            payload.phones = targets;
            payload.messagingAppId = (_u = data === null || data === void 0 ? void 0 : data.push) === null || _u === void 0 ? void 0 : _u.messagingAppId;
            payload.pushText = (_v = data === null || data === void 0 ? void 0 : data.push) === null || _v === void 0 ? void 0 : _v.pushText;
            payload.walletImageId = walletImageId || null;
        }
        if (type === 'email') {
            payload.emails = targets;
            payload.emailSenderId = (_w = data === null || data === void 0 ? void 0 : data.email) === null || _w === void 0 ? void 0 : _w.emailSenderId;
            payload.emailSubject = (_x = data === null || data === void 0 ? void 0 : data.email) === null || _x === void 0 ? void 0 : _x.emailSubject;
            payload.emailBody = ((_y = data === null || data === void 0 ? void 0 : data.email) === null || _y === void 0 ? void 0 : _y.emailBody) || undefined;
        }
        const result = yield sendTestMessage(payload);
        if ('error' in result)
            throw getErrorMessage(result.error);
        else if ((_z = result.data) === null || _z === void 0 ? void 0 : _z.error)
            throw (_0 = result.data) === null || _0 === void 0 ? void 0 : _0.error;
        else
            addToast({ type: 'success', message: t('Сообщение отправлено') });
    });
};
