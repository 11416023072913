import { useEffect } from 'react';
import { useGetExportListQuery } from '@/api/query/exports';
const CHECK_AGAIN_TIMEOUT = 5000;
export const useExportList = () => {
    const { data: exports, isLoading, isFetching, refetch, requestId, } = useGetExportListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    useEffect(() => {
        const hasUnfinishedExports = exports === null || exports === void 0 ? void 0 : exports.some((item) => item.status === 0);
        const checkAgainTimeout = hasUnfinishedExports
            ? setTimeout(refetch, CHECK_AGAIN_TIMEOUT)
            : undefined;
        return () => {
            if (checkAgainTimeout)
                clearTimeout(checkAgainTimeout);
        };
    }, [exports, requestId]);
    return {
        exports,
        isLoading,
        isFetching,
    };
};
