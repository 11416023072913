import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Chevron } from '@/icons/Chevron';
import { useAutocomplete } from '../context/useAutocomplete';
import styles from '../styles.module.scss';
export const AutocompleteChevron = ({ disabled, }) => {
    const { popupOpen, isInline, getPopupIndicatorProps } = useAutocomplete();
    return (_jsx("div", Object.assign({ className: classNames(styles.icon, {
            [styles.flipped]: popupOpen,
            [styles.disabled]: disabled,
            [styles.inline]: isInline,
        }) }, getPopupIndicatorProps === null || getPopupIndicatorProps === void 0 ? void 0 : getPopupIndicatorProps(), { children: _jsx(Chevron, { fill: 'currentColor' }) })));
};
