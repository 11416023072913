export const branchIsHidden = (item, usedCodes, excludeCode) => {
    if (item.type === 'leaf')
        return false;
    if (item.type === 'custom')
        return false;
    const codes = excludeCode
        ? usedCodes.filter((code) => code !== excludeCode)
        : usedCodes;
    if (item.isHiddenIfUsed) {
        const used = codes.some((used) => { var _a; return (_a = item.isHiddenIfUsed) === null || _a === void 0 ? void 0 : _a.includes(used); });
        return used;
    }
    if (item.isHiddenIfNotUsed) {
        const notUsed = codes.every((code) => isNotUsed(item.isHiddenIfNotUsed || [], code));
        return notUsed;
    }
    return false;
};
const isNotUsed = (tests, item) => {
    for (let i = 0; i < tests.length; i++) {
        const test = tests[i];
        if (typeof test === 'string' && test !== item)
            return false;
        if (typeof test !== 'string' && !test(item))
            return false;
    }
    return true;
};
