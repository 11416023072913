import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';
import insertEmoji, { INSERT_EMOJI } from './insertEmoji';
export const EmojiPlugin = () => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(INSERT_EMOJI, insertEmoji, COMMAND_PRIORITY_LOW);
    }, [editor]);
    return null;
};
