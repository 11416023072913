import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND, } from 'lexical';
import { useEffect, useState } from 'react';
import { $isVariableNode } from '../VariablePlugin/VariableNode';
import { $isLinkNode } from './LinkNode';
import styles from './styles.module.scss';
export const useLinkPopup = () => {
    const [editor] = useLexicalComposerContext();
    const [editorIsFocused, setEditorIsFocused] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [linkActive, setLinkActive] = useState(false);
    const [linkElement, setLinkElement] = useState(null);
    const resetLinks = () => {
        const root = editor.getRootElement();
        const links = root === null || root === void 0 ? void 0 : root.querySelectorAll(`.${styles.link}`);
        links === null || links === void 0 ? void 0 : links.forEach((link) => link.classList.remove(styles.selected));
    };
    const highlightActiveLink = () => {
        linkElement === null || linkElement === void 0 ? void 0 : linkElement.classList.add(styles.selected);
    };
    useEffect(() => {
        return editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                if (!editorIsFocused)
                    return;
                resetLinks();
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    const nodes = selection.getNodes();
                    if (nodes.length === 1) {
                        const firstNode = nodes[0];
                        if ($isLinkNode(firstNode)) {
                            setTitle(firstNode.getTextContent());
                            setLink(firstNode.getURL());
                            setLinkActive(true);
                            const linkElement = editor.getElementByKey(firstNode.getKey());
                            linkElement === null || linkElement === void 0 ? void 0 : linkElement.classList.add(styles.selected);
                            setLinkElement(linkElement);
                            return;
                        }
                    }
                    for (let i = 0; i < nodes.length; i += 1)
                        if ($isVariableNode(nodes[i])) {
                            setTitle('');
                            setLink('');
                            setLinkActive(false);
                            return;
                        }
                    setTitle(selection.getTextContent());
                    setLink('');
                    setLinkActive(false);
                }
            });
        });
    }, [editor, editorIsFocused]);
    useEffect(() => {
        return editor.registerCommand(FOCUS_COMMAND, () => {
            setEditorIsFocused(true);
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(BLUR_COMMAND, (e) => {
            const relatedTarget = e.relatedTarget;
            if ((relatedTarget === null || relatedTarget === void 0 ? void 0 : relatedTarget.id) !== 'link-form-title')
                resetLinks();
            setEditorIsFocused(false);
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [editor]);
    return {
        title,
        setTitle,
        link,
        setLink,
        linkActive,
        linkElement,
        resetLinks,
        highlightActiveLink,
    };
};
