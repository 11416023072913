var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAppPeriod } from '@/hooks/useAppPeriod';
import { compileFilters } from '@/services/filters/compileFilters';
import { tableDataSelector } from '@/store/selectors/reports';
import { activeDashboardAnalyticsSelector, activeDashboardSelector, } from '@/store/selectors/reports';
import { toggleTableNode } from '@/store/slice/reports';
import { fetchTableNode, fetchTableRoot } from '@/store/thunk/reports';
import { buildFlatTableData } from './buildFlatTableData';
export const useTableData = () => {
    const dispatch = useAppDispatch();
    const { formattedStart, formattedEnd } = useAppPeriod();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const widget = useAppSelector(activeDashboardAnalyticsSelector);
    const { data: tableData } = useAppSelector(tableDataSelector);
    const [isLoadingRoot, setIsLoadingRoot] = useState(true);
    const filters = (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.filters) || [];
    const metrics = (widget === null || widget === void 0 ? void 0 : widget.configuration.metrics.map((m) => m.key)) || [];
    const dimensions = (widget === null || widget === void 0 ? void 0 : widget.configuration.dimensions.map((d) => d.key)) || [];
    const limit = (widget === null || widget === void 0 ? void 0 : widget.configuration.tableMaxResults) || 20;
    const toggleNode = (node) => __awaiter(void 0, void 0, void 0, function* () {
        const key = (node === null || node === void 0 ? void 0 : node.key) || (node === null || node === void 0 ? void 0 : node.groupKey);
        if ((node === null || node === void 0 ? void 0 : node.expand) || (node === null || node === void 0 ? void 0 : node.children)) {
            if (key)
                dispatch(toggleTableNode({ key }));
            return;
        }
        let n = node;
        const parents = [];
        while (n === null || n === void 0 ? void 0 : n.parent) {
            if (typeof n.groupKey !== 'undefined')
                parents.unshift(n.groupKey);
            n = n.parent;
        }
        yield dispatch(fetchTableNode({
            periodStart: formattedStart,
            periodEnd: formattedEnd,
            filters: compileFilters(filters),
            metrics,
            dimensions: dimensions === null || dimensions === void 0 ? void 0 : dimensions.slice(0, parents.length + 1),
            parents,
            limit,
            key,
        }));
    });
    const loadRoot = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoadingRoot(true);
        yield dispatch(fetchTableRoot({
            periodStart: formattedStart,
            periodEnd: formattedEnd,
            filters: compileFilters(filters),
            metrics,
        }));
        if (dimensions.length > 0)
            yield toggleNode();
        setIsLoadingRoot(false);
    });
    useEffect(() => {
        loadRoot();
    }, [
        formattedStart,
        formattedEnd,
        JSON.stringify(filters),
        JSON.stringify(metrics),
        JSON.stringify(dimensions),
        limit,
    ]);
    return {
        metrics,
        dimensions,
        limit,
        tableData: tableData ? buildFlatTableData(tableData) : [],
        isLoadingRoot,
        loadRoot,
        toggleNode,
    };
};
