import { api } from './';
export const flowsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getFlowCandidatesList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/flow/list/candidates',
                body,
            }),
            providesTags: ['FlowCandidatesList'],
        }),
        fetchFlowCandidatesList: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/flow/list/candidates',
                body,
            }),
        }),
        getFlowList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/flow/list',
                body,
            }),
            providesTags: ['FlowList'],
        }),
        getFlow: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/flow/${id}`,
            }),
            providesTags: ['Flow'],
        }),
        addFlow: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/flow/add',
                body,
            }),
            invalidatesTags: ['FlowList', 'FlowCandidatesList', 'Mailing'],
        }),
        setFlow: build.mutation({
            query: ({ id, title }) => ({
                method: 'POST',
                url: `/flow/set/${id}`,
                body: { title },
            }),
            invalidatesTags: ['FlowList', 'Flow'],
        }),
        deleteFlow: build.mutation({
            query: ({ id }) => ({
                method: 'POST',
                url: `/flow/delete/${id}`,
            }),
            invalidatesTags: ['FlowList', 'FlowCandidatesList', 'Mailing'],
        }),
        saveDraft: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/flow/save-draft',
                body,
            }),
        }),
        publishFlow: build.mutation({
            query: ({ id }) => ({
                method: 'POST',
                url: `/flow/publish/${id}`,
            }),
            invalidatesTags: ['Flow'],
        }),
        startFlow: build.mutation({
            query: ({ id }) => ({
                method: 'POST',
                url: `/flow/start/${id}`,
            }),
            invalidatesTags: ['Flow'],
        }),
        pauseFlow: build.mutation({
            query: ({ id }) => ({
                method: 'POST',
                url: `/flow/pause/${id}`,
            }),
            invalidatesTags: ['Flow'],
        }),
    }),
});
export const { useGetFlowCandidatesListQuery, useFetchFlowCandidatesListMutation, useGetFlowListQuery, useGetFlowQuery, useAddFlowMutation, useSetFlowMutation, useDeleteFlowMutation, useSaveDraftMutation, usePublishFlowMutation, useStartFlowMutation, usePauseFlowMutation, } = flowsApi;
