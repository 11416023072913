import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { availableRoutes } from '@/app/availableRoutes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { useCurrentUser } from '../../useCurrentUser';
const BASE_PATH_SEPARATOR = /^(\/[^\\/]+)\/[^\\/]*/;
export const Redirect = () => {
    var _a, _b;
    const location = useLocation();
    const stateRoute = useRef((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname).current;
    const { user, brand } = useCurrentUser();
    const userHasAccessTo = useUserPermissions();
    if (!user || !brand)
        return null;
    const { firstAvailableRoute, availableRoutesList } = availableRoutes(brand === null || brand === void 0 ? void 0 : brand.brand, userHasAccessTo);
    // проверям, есть ли маршрут из стейта в списке доступных маршрутов
    // проверяем по первым частям маршрута, отсекая /create/:id (например) или по всему маршруту
    const isValidStateRoute = availableRoutesList === null || availableRoutesList === void 0 ? void 0 : availableRoutesList.some((r) => {
        var _a;
        return r.route === ((_a = stateRoute === null || stateRoute === void 0 ? void 0 : stateRoute.match(BASE_PATH_SEPARATOR)) === null || _a === void 0 ? void 0 : _a[0]) ||
            r.route === stateRoute;
    });
    // если маршрут из стейта не найден, выбираем первый доступный маршрут
    const to = isValidStateRoute ? stateRoute : firstAvailableRoute;
    return _jsx(Navigate, { to: to, replace: true });
};
