import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const ClientBonusHistoryListItem = memo(({ type, date, title, bonuses, description, tooltip }) => {
    const { t, i18n } = useTranslation();
    const dateFormatted = date === 'now' ? '' : moment(date).format('DD.MM.YYYY, HH:mm');
    const dateFromNow = date === 'now'
        ? t('сейчас')
        : moment(date).locale(i18n.language).fromNow();
    const bonusesFormatted = bonuses
        ? t('HISTORY_BONUSES_AMOUNT', {
            amount: numberRound(bonuses),
            count: bonuses,
        })
        : '';
    const content = (_jsxs(_Fragment, { children: [title && (_jsxs("span", Object.assign({ className: classNames(styles.itemTitle, type && styles[type]) }, { children: [title, ' '] }))), bonusesFormatted && _jsxs("span", { children: [bonusesFormatted, " "] }), description && _jsx("span", { children: description })] }));
    return (_jsxs("li", Object.assign({ className: classNames(styles.item, type && styles[type]) }, { children: [_jsx("p", Object.assign({ className: styles.itemContent }, { children: tooltip ? (_jsx(HelpTooltip, Object.assign({ title: tooltip, PopperProps: { disablePortal: true } }, { children: content }))) : (content) })), _jsxs("div", { children: [dateFormatted && (_jsxs("span", Object.assign({ className: styles.date }, { children: [dateFormatted, ","] }))), _jsx("span", Object.assign({ className: styles.dateFromNow }, { children: dateFromNow }))] })] })));
});
