/* eslint-disable curly */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createApi, fetchBaseQuery, } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { createHref, ROUTES } from '@/app/pages/routes';
import { MAXMA_API_HOST } from '@/const/api';
import { pushError, watch401 } from '@/store/slice/httpError';
import { getHttpErrorMessage } from '@/utils/httpErrors';
export const getErrorMessage = (error) => {
    var _a;
    if (!error)
        return '';
    if (typeof error === 'string')
        return error;
    const error1 = error;
    const error2 = error;
    const error3 = error;
    if ((_a = error1 === null || error1 === void 0 ? void 0 : error1.error) === null || _a === void 0 ? void 0 : _a.message)
        return error1.error.message;
    if ((error2 === null || error2 === void 0 ? void 0 : error2.error) && typeof error2.error === 'string')
        return error2.error;
    if ((error3 === null || error3 === void 0 ? void 0 : error3.message) && typeof error3.message === 'string')
        return error3.message;
    return '';
};
export const getErrorObject = (error) => {
    var _a;
    if ((_a = error === null || error === void 0 ? void 0 : error.error) === null || _a === void 0 ? void 0 : _a.hint) {
        let route = '';
        if (error.error.objectType === 'automaticMailing')
            route = ROUTES.MAILINGS_AUTOMATIC_EDIT;
        if (error.error.objectType === 'manualMailing')
            route = ROUTES.MAILINGS_MANUAL_EDIT;
        const href = route
            ? createHref(generatePath(route, { mailingId: error.error.objectId }))
            : undefined;
        return Object.assign(Object.assign({}, error.error), { href });
    }
    return null;
};
const baseQuery = fetchBaseQuery({ baseUrl: MAXMA_API_HOST });
const enhancedBaseQuery = (args, baseQueryApi, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const result = yield baseQuery(args, baseQueryApi, extraOptions);
    // HTTP Error is an error whose status is not 2xx
    const httpError = result.error;
    // no network connection, get out early
    if ((httpError === null || httpError === void 0 ? void 0 : httpError.status) === 'FETCH_ERROR') {
        baseQueryApi.dispatch(pushError({ status: 'FETCH_ERROR' }));
        return { error: getHttpErrorMessage('FETCH_ERROR') };
    }
    // The server responds to a 500 error with HTML instead of JSON
    // so we need to get the original HTTP status
    const httpErrorStatus = (httpError === null || httpError === void 0 ? void 0 : httpError.status) === 'PARSING_ERROR'
        ? httpError.originalStatus
        : ((_b = (_a = result.meta) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.status) || 500;
    // API error is an expected error like invalid login/password or something similar
    const apiError = (_c = result.data) === null || _c === void 0 ? void 0 : _c.error;
    if (httpError)
        if (httpErrorStatus === 401) {
            // 401 errors need to be handled in a special way
            // we ignore them if the user has logged out on their own
            // we only show them if cookies are out of date
            const state = baseQueryApi.getState();
            const ignore401 = state.httpError.ignore401;
            // first we need to reset the current user cache
            baseQueryApi.dispatch(api.util.invalidateTags(['CurrentUser', 'CurrentBrand']));
            if (!ignore401) {
                // cookies are out of date, display this error using the global top alerts
                baseQueryApi.dispatch(pushError({ status: httpErrorStatus }));
            }
            else {
                // the user has logged out on their own
                baseQueryApi.dispatch(watch401());
            }
        }
        else {
            // all other HTTP errors we just display using the global top alerts
            baseQueryApi.dispatch(pushError({ status: httpErrorStatus }));
        }
    // HTTP errors have already been handled above by the global top alerts
    // get out early
    if (httpError)
        return { error: getHttpErrorMessage(httpErrorStatus) };
    // expected errors should be displayed separately
    // get out early
    if (apiError)
        return { error: apiError };
    // no errors, return request body
    return result.data;
});
export const api = createApi({
    baseQuery: enhancedBaseQuery,
    endpoints: () => ({}),
    tagTypes: [
        'CurrentUser',
        'CurrentBrand',
        'ShopList',
        'ShopOptions',
        'OperatorList',
        'ShopGroupList',
        'ShopGroupOptions',
        'GiftCardCount',
        'GiftCardList',
        'GiftCardOptions',
        'GiftCard',
        'GiftCardInstanceList',
        'GiftCardInitial',
        'BrandGiftCardList',
        'ExportList',
        'InvoiceList',
        'ManualMailingList',
        'AutoMailingList',
        'MailingStats',
        'EmailTemplates',
        'Mailing',
        'OfferCount',
        'OfferList',
        'OfferOptions',
        'Offer',
        'BrandPromocodes',
        'PromocodeCount',
        'PromocodeList',
        'Promocode',
        'ClientCount',
        'ClientList',
        'Client',
        'BrandSegments',
        'SegmentList',
        'SegmentListShort',
        'Segment',
        'SegmentAdd',
        'SegmentSet',
        'BillingStats',
        'PurchaseCount',
        'PurchaseList',
        'Purchase',
        'OrderList',
        'OrderListCancelled',
        'Order',
        'Levels',
        'RFMSegments',
        'RFMSegmentList',
        'RFMTable',
        'RFMHistory',
        'RFMMovementList',
        'ImportList',
        'Import',
        'ImportPreview',
        'CounterList',
        'WalletCardList',
        'WalletCard',
        'WalletGeo',
        'FlowCandidatesList',
        'FlowList',
        'Flow',
        'RolesList',
    ],
});
