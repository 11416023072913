import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
export const ShopGroupInputAddButton = forwardRef(({ onClick, disabled }, ref) => {
    const { t } = useTranslation();
    const [isWaiting, setIsWaiting] = useState(false);
    const handleClick = useCallback(() => {
        setIsWaiting(true);
        onClick().finally(() => {
            setIsWaiting(false);
        });
    }, [onClick]);
    return (_jsx(Button, Object.assign({ theme: 'primary', size: 'full-width', onClick: handleClick, disabled: disabled || isWaiting, type: 'button', ref: ref }, { children: t('Создать группу') })));
});
