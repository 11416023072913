import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { SegmentsTable } from './SegmentsTable';
export const SegmentsPanel = memo(({ segments = [], rfmSegments = [], total, shouldDisplayProfit, currency, isLoading, isFetching, activeTabId, isRFMEnabled, onChangeActiveTab, onSegmentClick, onEditSegment, onDeleteSegment, onDeleteSegmentClients, onGoToAllClients, }) => {
    const { t } = useTranslation();
    const tabs = [
        {
            id: 'regular',
            label: t('REGULAR_SEGMENTS'),
            count: segments.length,
        },
        {
            id: 'rfm',
            label: 'RFM',
            count: rfmSegments.length,
            isLocked: !isRFMEnabled,
        },
    ];
    return (_jsx(TabsPanel, Object.assign({ tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: onChangeActiveTab, isSkeleton: isLoading }, { children: _jsx(SegmentsTable, { segments: activeTabId === 'regular' ? segments : rfmSegments, total: total, shouldDisplayProfit: shouldDisplayProfit, currency: currency, isLoading: isFetching, hasActions: activeTabId === 'regular', onSegmentClick: onSegmentClick, onEditSegment: onEditSegment, onDeleteSegment: onDeleteSegment, onDeleteSegmentClients: onDeleteSegmentClients, onGoToAllClients: onGoToAllClients }) })));
});
