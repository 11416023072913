import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailNotClicked } from '@/icons/flow/EmailNotClicked';
import { EmailNotPurchased } from '@/icons/flow/EmailNotPurchased';
import { Plus } from '@/icons/Plus';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodeDeleteButton } from '../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodeRow } from '../helpers/NodeRow/NodeRow';
import { NodeTitle } from '../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../helpers/NodeWrapper/NodeWrapper';
import styles from './styles.module.scss';
export const ChannelReceivedNode = ({ id, data }) => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap } = useInteractiveFlow();
    const { type, conditions = [] } = data;
    const thisNode = flow.getNode(id);
    const isInvalid = !!(validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[(thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid) || '']);
    let header = '';
    let icon = _jsx(_Fragment, {});
    switch (type) {
        case 'delivered_channel': {
            header = 'Доставлено';
            icon = _jsx(EmailNotClicked, {});
            break;
        }
        case 'purchased_channel': {
            header = 'Сделал покупку';
            icon = _jsx(EmailNotPurchased, {});
            break;
        }
    }
    const handleClick = useCallback(() => {
        if (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid)
            selectedNode.selectUUID(thisNode.uuid);
    }, [thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid, selectedNode.selectUUID]);
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, Object.assign({ gap: '12', onClick: handleClick, isSelected: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid), isInvalid: isInvalid, isInteractive: true }, { children: [_jsx(NodeTitle, Object.assign({ icon: icon, hasSmallPadding: true }, { children: t(header) })), _jsxs("div", { children: [conditions.map((condition, i) => (_jsx(NodeRow, Object.assign({ nodeId: id, handleId: `${condition}-${i}` }, { children: _jsx("div", Object.assign({ className: classNames({
                                        [styles.no]: condition === 'no',
                                        [styles.yes]: condition === 'yes',
                                    }) }, { children: condition === 'no' ? t('Нет') : t('Да') })) }), condition))), !conditions.length && (_jsx(NodeRow, Object.assign({ nodeId: id, handleId: 'add', asIcon: true, hasHandlePlusButton: false }, { children: _jsx(Plus, {}) }), 'add'))] })] })), _jsx(NodeDeleteButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
