import { api } from './';
export const purchasesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPurchaseCount: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/count',
                body,
            }),
            providesTags: ['PurchaseCount'],
        }),
        getPurchaseList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/list',
                body,
            }),
            providesTags: ['PurchaseList'],
        }),
        setPurchase: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/set',
                body,
            }),
        }),
        confirmAddPurchaseTicket: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/confirm-ticket',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'Client', 'ClientList'],
        }),
        discardPurchaseTicket: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/discard-ticket',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'Client', 'ClientList'],
        }),
        updatePurchase: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/update',
                body,
            }),
            invalidatesTags: ['PurchaseList'],
        }),
        deletePurchase: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/purchase/delete',
                body,
            }),
            invalidatesTags: ['PurchaseCount'],
        }),
        getTransactionPurchase: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/purchase/get/${id}`,
            }),
        }),
    }),
});
export const { useGetPurchaseCountQuery, useGetPurchaseListQuery, useSetPurchaseMutation, useConfirmAddPurchaseTicketMutation, useDiscardPurchaseTicketMutation, useUpdatePurchaseMutation, useDeletePurchaseMutation, useGetTransactionPurchaseQuery, } = purchasesApi;
