import { jsx as _jsx } from "react/jsx-runtime";
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { Backside } from '../../sections/Backside/Backside';
import { Banner } from '../../sections/Banner/Banner';
import { Barcode } from '../../sections/Barcode/Barcode';
import { Fields } from '../../sections/Fields/Fields';
import { Head } from '../../sections/Head/Head';
import { Notify } from '../../sections/Notify/Notify';
export const Content = ({ menu }) => {
    const { formSection } = useWalletCardForm();
    if (!formSection)
        return menu;
    switch (formSection) {
        case 'head': {
            return _jsx(Head, {});
        }
        case 'banner': {
            return _jsx(Banner, {});
        }
        case 'fields': {
            return _jsx(Fields, {});
        }
        case 'notify': {
            return _jsx(Notify, {});
        }
        case 'backside': {
            return _jsx(Backside, {});
        }
        case 'barcode': {
            return _jsx(Barcode, {});
        }
        default: {
            return null;
        }
    }
};
