import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FormReportNameModal } from '@/forms/FormReportName/FormReportNameModal';
import { ReportsNav } from './ReportsNav';
import { useActiveDashboard } from './useActiveDashboard';
import { useAddDashboard } from './useAddDashboard';
import { useDashboardsList } from './useDashboardsList';
import { useDeleteDashboard } from './useDeleteDashboard';
import { useRenameDashboard } from './useRenameDashboard';
import { useSaveDashboard } from './useSaveDashboard';
export const ReportsNavContainer = () => {
    const { t } = useTranslation();
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const handleOpenModal = () => setModalIsOpened(true);
    const handleCloseModal = () => setModalIsOpened(false);
    const { selectDashboard, activeDashboard } = useActiveDashboard();
    const addDashboard = useAddDashboard();
    const deleteDashboard = useDeleteDashboard(selectDashboard, addDashboard);
    const saveDashboard = useSaveDashboard(activeDashboard);
    const { renameDashboard, isRenaming } = useRenameDashboard(activeDashboard);
    const { dashboards } = useDashboardsList(addDashboard);
    return dashboards && activeDashboard ? (_jsxs(_Fragment, { children: [_jsx(ReportsNav, { items: dashboards, activeItemId: activeDashboard.id, rightElement: _jsx(DatePeriodSelectContainer, { label: t('Расчеты за') }), onSelectItem: selectDashboard, onRenameItem: handleOpenModal, onSaveItem: saveDashboard, onDeleteItem: deleteDashboard, onAddItem: addDashboard }), _jsx(FormReportNameModal, { defaultName: activeDashboard.title, isOpen: modalIsOpened, onClose: handleCloseModal, onSubmit: (payload) => {
                    renameDashboard(payload);
                    handleCloseModal();
                }, isSubmitting: isRenaming })] })) : null;
};
