import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { DownloadAlt } from '@/icons/DownloadAlt';
import { importTypes } from './importTypes';
import styles from './styles.module.scss';
export const ImportButton = ({ onSelectImportType, }) => {
    const { t } = useTranslation();
    return (_jsx(Dropdown, Object.assign({ items: Object.entries(importTypes).map(([typeId, typeLabel]) => ({
            id: typeId,
            label: t(typeLabel),
            onClick: () => onSelectImportType === null || onSelectImportType === void 0 ? void 0 : onSelectImportType(Number(typeId)),
        })), placement: 'bottom-start' }, { children: _jsx(Button, Object.assign({ icon: _jsx(DownloadAlt, { className: styles.uploadIcon }), theme: 'primary', size: 'extra-large', hasLargeIconMargin: true }, { children: t('Загрузить данные') })) })));
};
