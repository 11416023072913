import { createContext } from 'react';
export const WalletCardFormContext = createContext({
    activeCardType: 'apple',
    setActiveCardType: () => undefined,
    formSection: null,
    setFormSection: () => undefined,
    googleCardIsFlipped: false,
    setGoogleCardIsFlipped: () => undefined,
    appleCardIsFlipped: false,
    setAppleCardIsFlipped: () => undefined,
    background: '',
    setBackground: () => undefined,
    color: '',
    setColor: () => undefined,
    labelColor: '',
    setLabelColor: () => undefined,
    appleHead: {
        logo: null,
        logoRejectReason: '',
        title: '',
        field_1_Title: '',
        field_1_Value: '',
        field_2_Title: '',
        field_2_Value: '',
    },
    setAppleHead: () => undefined,
    googleHead: {
        logo: null,
        logoRejectReason: '',
        title: '',
        programName: '',
        field_Main_Title: '',
        field_Main_Value: '',
    },
    setGoogleHead: () => undefined,
    bannerType: 'simple',
    setBannerType: () => undefined,
    bannerCounter: {
        offerId: null,
        tickImage: null,
        blankImageRejectReason: '',
        blankImage: null,
        tickImageRejectReason: '',
    },
    setBannerCounter: () => undefined,
    appleBanner: {
        image: null,
        imageRejectReason: '',
        title: '',
        titleColor: '',
        titleSize: '',
        description: '',
        descriptionColor: '',
        descriptionSize: '',
    },
    setAppleBanner: () => undefined,
    googleBanner: {
        image: null,
        imageRejectReason: '',
        backsideImage: null,
        backsideImageRejectReason: '',
        title: '',
        titleColor: '',
        titleSize: '',
        description: '',
        descriptionColor: '',
        descriptionSize: '',
    },
    setGoogleBanner: () => undefined,
    appleFields: {
        field_3_Title: '',
        field_3_Value: '',
        field_4_Title: '',
        field_4_Value: '',
        field_5_Title: '',
        field_5_Value: '',
        field_6_Title: '',
        field_6_Value: '',
    },
    setAppleFields: () => undefined,
    googleFields: {
        field_1_Title: '',
        field_1_Value: '',
        field_2_Title: '',
        field_2_Value: '',
        field_3_Title: '',
        field_3_Value: '',
    },
    setGoogleFields: () => undefined,
    notify: {
        logo: null,
        logoRejectReason: '',
        title: '',
    },
    setNotify: () => undefined,
    backside: {
        appleTitle: '',
        contacts: [{ title: '', content: '' }],
        details: [{ title: '', content: '' }],
    },
    setBackside: () => undefined,
    readyToSave: false,
    setReadyToSave: () => undefined,
    markReadyToSave: () => undefined,
    barcode: 'code128',
    setBarcode: () => undefined,
});
