import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { BreadcrumbsContext } from './BreadcrumbsContext';
import { BREADCRUMBS_MAP } from './breadcrumbsRoutes';
import styles from './styles.module.scss';
// временное решение для кейсов со страницами воллетов
const getCrumbsByRoute = (pathname) => {
    for (const key in BREADCRUMBS_MAP)
        if (matchPath(key, pathname))
            return BREADCRUMBS_MAP[key];
    return {};
};
export const Breadcrumbs = memo(() => {
    const location = useLocation();
    const { t } = useTranslation();
    const crumbsForRoute = BREADCRUMBS_MAP[location.pathname.replace(/\/\d+$/, '')] ||
        getCrumbsByRoute(location.pathname);
    const { lastCrumb } = useContext(BreadcrumbsContext);
    return (_jsxs("div", Object.assign({ className: styles.crumbs }, { children: [t(crumbsForRoute.root), crumbsForRoute.name && _jsx("span", { children: "/" }), crumbsForRoute.name && (_jsx(_Fragment, { children: crumbsForRoute.prevStepLink ? (_jsx(Link, Object.assign({ className: styles.linkCrumb, to: crumbsForRoute.prevStepLink }, { children: t(crumbsForRoute.name) }))) : (_jsx("span", { children: t(crumbsForRoute.name) })) })), lastCrumb && (_jsxs(_Fragment, { children: [_jsx("span", { children: "/" }), _jsx("span", Object.assign({ className: styles.lastCrumb }, { children: lastCrumb }))] }))] })));
});
