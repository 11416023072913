export const getClientFullName = (client) => {
    if (client && client.name) {
        let fullName = client.name;
        if (client.patronymic)
            fullName += ' ' + client.patronymic;
        if (client.surname)
            fullName += ' ' + client.surname;
        return fullName;
    }
    if (client && client.fullName)
        return client.fullName;
    return '';
};
export const buildClientFullName = (client) => {
    if (!client)
        return '';
    const { name, patronymic, surname, fullName } = client;
    const buildedFullname = [name === null || name === void 0 ? void 0 : name.trim(), patronymic === null || patronymic === void 0 ? void 0 : patronymic.trim(), surname === null || surname === void 0 ? void 0 : surname.trim()]
        .filter(Boolean)
        .join(' ');
    return buildedFullname || fullName;
};
