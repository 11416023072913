var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteShopMutation, useGetShopListQuery } from '@/api/query/shop';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialogShopDelete';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useDeleteShop = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteShop] = useDeleteShopMutation();
    const { data: shops } = useGetShopListQuery();
    return (id, name) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmTitle = (name === null || name === void 0 ? void 0 : name.trim())
            ? t('Удалить магазин {{name}}?', { name: name.trim() })
            : t('Удалить магазин?');
        const confirmAgreeLabel = t('Удалить');
        const successMessage = t('Точка продаж удалена');
        const confirmResult = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            shopToDeleteId: id,
            shops,
        });
        if (!confirmResult)
            return false;
        const result = yield deleteShop({
            id,
            replacementShopId: typeof confirmResult === 'string' ? Number(confirmResult) : undefined,
        });
        const error = getErrorMessage(result);
        if (error) {
            addToast({ type: 'error', message: error });
            return false;
        }
        else {
            addToast({ type: 'success', message: successMessage });
            return true;
        }
    });
};
