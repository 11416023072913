import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Table } from '@/components/Table/Table';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { FormRoleModal } from '@/forms/FormRole/FormRoleModal';
import { FormUsersContainer } from '@/forms/FormUsers/FormUsersContainer';
import { Gears } from '@/icons/Gears';
import { formatDate } from '@/utils/dates';
import { RightToolbar } from './RightToolbar';
import styles from './styles.module.scss';
import { UserAvailableDates } from './UserAvailableDates';
import { UserShops } from './UserShops';
const SYSTEM_ROLES = {
    ROLE_ADMIN: 'Админ',
    ROLE_CASHIER: 'Кассир',
    ROLE_CLIENT_MANAGER: 'Загрузка анкет',
    ROLE_USER: 'Менеджер',
};
export const UsersTable = memo(({ users = [], selectedUser, userModalIsOpen, roleModalIsOpen, onToggleUserModal, onToggleRoleModal, roles = [], sessionUserId, onSubmitUser, onSubmitRole, onEditUser, onDeleteUser, onDeleteRole, setSelectRole, selectRole, isLoading, isFetching, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasEdit = userHasAccessTo([
        'OPERATORS_ADMIN_MANAGE',
        'OPERATORS_MANAGE',
    ]);
    const [activeTabId, setActiveTabId] = useState('users');
    const roleModalVarinant = useRef(null);
    const handleViewRole = (role) => {
        roleModalVarinant.current = 'view';
        setSelectRole(role);
        onToggleRoleModal();
    };
    const handleEditRole = (role) => {
        setSelectRole(role);
        roleModalVarinant.current = 'edit';
        onToggleRoleModal();
    };
    const handleUserEditModal = useCallback((row) => {
        const user = users.find((u) => u.id === row.id);
        if (user)
            onEditUser(user);
    }, [users, onEditUser]);
    const columnsUsers = [
        {
            id: 'name',
            title: t('Имя'),
        },
        {
            id: 'login',
            title: t('Логин'),
        },
        {
            id: 'role',
            title: t('Роль'),
        },
        {
            id: 'shops',
            title: t('Точки продаж'),
        },
        {
            id: 'createdAt',
            title: t('Доступ с...'),
        },
        {
            id: 'lastLoginAt',
            title: t('Последняя авторизация'),
        },
        {
            id: 'skip',
        },
    ];
    const columnsRoles = [
        {
            id: 'title',
            title: t('Название'),
            className: styles.titleColumn,
        },
        {
            id: 'users',
            title: (_jsx("div", Object.assign({ className: styles.operatorsCount }, { children: t('Пользователей') }))),
            align: 'right',
        },
        {
            id: 'actions',
        },
    ];
    const rowsUsers = users.map((user) => {
        var _a;
        return ({
            id: user.id,
            cells: [
                user.name,
                user.username,
                // Для системной роли даем перевод, для кастомной - нет
                ((_a = user === null || user === void 0 ? void 0 : user.operatorRole) === null || _a === void 0 ? void 0 : _a.name) ||
                    t(SYSTEM_ROLES[user.role]) ||
                    '',
                _jsx(UserShops, { user: user }, 'shops'),
                _jsx(UserAvailableDates, { user: user }, 'user-available-dates'),
                user.lastLoginAt ? formatDate(user.lastLoginAt) : '—',
            ],
            actions: [
                {
                    id: 'edit',
                    tooltip: !userHasEdit && t('Недоступно для вашей роли'),
                    label: t('Редактировать'),
                    onClick: () => onEditUser(user),
                    isDisabled: !userHasEdit,
                },
                {
                    id: 'delete',
                    tooltip: !userHasEdit && t('Недоступно для вашей роли'),
                    label: t('Удалить'),
                    onClick: () => onDeleteUser(user),
                    isDanger: true,
                    shouldDisplay: sessionUserId !== user.id,
                    isDisabled: !userHasEdit,
                },
            ],
        });
    });
    const rowsRoles = roles.map((role) => ({
        id: role.id,
        actionsCellClassName: styles.actionsCell,
        cells: [
            _jsx("div", Object.assign({ className: styles.titleColumn }, { children: role.isSystem ? (_jsx(HelpTooltip, Object.assign({ title: t('Системная роль'), placement: 'top', Icon: _jsx(Gears, { fill: 'currentColor', size: 16 }) }, { children: role.name }), role.id)) : (role.name) }), role.name),
            _jsx("div", Object.assign({ className: styles.operatorsCount }, { children: role.operatorsCount }), role.operatorsCount),
        ],
        actions: [
            {
                id: 'watch',
                label: t('Посмотреть'),
                onClick: () => handleViewRole(role),
                shouldDisplay: role.isSystem,
            },
            {
                id: 'edit',
                tooltip: !userHasEdit && t('Недоступно для вашей роли'),
                label: t('Редактировать'),
                onClick: () => handleEditRole(role),
                shouldDisplay: !role.isSystem,
                isDisabled: !userHasEdit,
            },
            {
                id: 'copy',
                label: t('Сделать копию'),
                tooltip: !userHasEdit && t('Недоступно для вашей роли'),
                isDisabled: !userHasEdit,
                onClick: () => {
                    setSelectRole(Object.assign(Object.assign({}, role), { name: `Копия ${role.name}` }));
                    roleModalVarinant.current = 'copy';
                    onToggleRoleModal();
                },
            },
            {
                id: 'delete',
                tooltip: !userHasEdit && t('Недоступно для вашей роли'),
                label: t('Удалить'),
                isDanger: true,
                shouldDisplay: !role.isSystem,
                isDisabled: !userHasEdit,
                onClick: () => onDeleteRole(role.id, role.name, role.operatorsCount !== 0),
            },
        ],
    }));
    const rows = activeTabId === 'users' ? rowsUsers : rowsRoles;
    const columns = activeTabId === 'users' ? columnsUsers : columnsRoles;
    const handleCloseRoleModal = () => {
        onToggleRoleModal();
        setSelectRole(null);
        roleModalVarinant.current = null;
    };
    const handleSubmitRole = (role, isEdit) => {
        onSubmitRole(role, isEdit, roleModalVarinant.current === 'view');
        roleModalVarinant.current = null;
    };
    const handleDeleteRole = () => {
        if (selectRole)
            onDeleteRole(selectRole.id, selectRole.name);
        roleModalVarinant.current = null;
    };
    const handleRowClick = (row) => {
        if (activeTabId === 'users')
            return handleUserEditModal(row);
        else {
            const role = roles.find((role) => role.id === row.id);
            if (+row.id < 0)
                return handleViewRole(role);
            handleEditRole(role);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(TabsPanel, Object.assign({ tabs: [
                    {
                        id: 'users',
                        label: t('Пользователи'),
                        count: users.length,
                    },
                    {
                        id: 'roles',
                        label: t('Роли'),
                        count: roles.length,
                    },
                ], rightToolbar: _jsx(RightToolbar, { onToggleUserModal: onToggleUserModal, onToggleRoleModal: onToggleRoleModal }), isSkeleton: isLoading, activeTabId: activeTabId, onChangeActiveTab: setActiveTabId }, { children: _jsx(Table, { className: classNames(styles.table, {
                        [styles.usersTable]: activeTabId === 'users',
                    }), columns: columns, rows: rows, isLoading: isFetching, onRowClick: handleRowClick }) })), roles && (_jsx(FormUsersContainer, { isOpen: userModalIsOpen, onClose: onToggleUserModal, roles: roles, selectedUser: selectedUser, onSubmit: onSubmitUser })), _jsx(FormRoleModal, { isOpen: roleModalIsOpen, onClose: handleCloseRoleModal, onSubmit: handleSubmitRole, onDelete: handleDeleteRole, initData: selectRole, isView: roleModalVarinant.current === 'view', isEdit: roleModalVarinant.current === 'edit' }, selectRole === null || selectRole === void 0 ? void 0 : selectRole.id)] }));
});
