import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { ClientEditForm } from '@/components/ClientModal/ClientEditForm';
import { ClientEditModal } from '@/components/ClientModal/ClientEditModal';
import { ClientOverviewPanelContainer } from '@/components/ClientOverviewPanel/ClientOverviewPanelContainer';
import { Container } from '@/components/Container/Container';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { useClientModal } from '../Clients/useClientModal';
import { EventsPanel } from './EventsPanel';
import styles from './styles.module.scss';
import { useData } from './useData';
export const Events = () => {
    const userHasAccessTo = useUserPermissions();
    const { user } = useCurrentUser();
    const { client: selectedClient, openOverview, isClientModalOpen, closeClientModal, clientModalActiveTab, changeModalActiveTab, } = useClientModal();
    const { handleApplySearch, handleChangeFilters, handleNextPage, activeFilters, clientsEventList, isLoading, } = useData();
    if (!user)
        return null;
    const userHasEventsView = userHasAccessTo('CLIENTS_PURCHASES_LIST') || user.isUser;
    return userHasEventsView ? (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsxs("div", Object.assign({ className: styles.topElement }, { children: [_jsx(FiltersButton, { filtersTree: [], value: [], onUpdate: () => { } }), _jsx(SearchPanel, { className: styles.searchPanel, name: 'searchText', placeholder: '\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430', hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch })] })) }, { children: [_jsx(EventsPanel, { eventsData: clientsEventList, isLoading: isLoading, handleChangeFilters: handleChangeFilters, openOverview: openOverview, activeButtons: activeFilters, handleNextPage: handleNextPage }), _jsx(ClientEditModal, { isOpen: isClientModalOpen, onClose: closeClientModal, activeTab: clientModalActiveTab, onChangeTab: changeModalActiveTab, clientOverview: _jsx(ClientOverviewPanelContainer, { client: selectedClient.client, bonusHistory: selectedClient.bonusHistory, clientReservedBonuses: selectedClient.reservedBonuses || 0, user: user, onCorrectBonuses: () => { }, onAddPurchase: () => { } }), clientForm: _jsx(ClientEditForm, { selectedClient: selectedClient.client, onUpdated: closeClientModal, onDeleted: () => { } }) })] }))) : null;
};
