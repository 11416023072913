import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lock } from '@/icons/wallet-card/notify/Lock';
import styles from './styles.module.scss';
export const WalletCardNotifyClock = () => {
    const { i18n } = useTranslation();
    const [time, setTime] = useState(formatTime());
    const [hours, minutes] = time.split(':');
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(formatTime());
        }, (60 * 1000) / 2);
        return () => clearInterval(interval);
    }, []);
    return (_jsxs("div", Object.assign({ className: styles.clockWrapper }, { children: [_jsx("div", Object.assign({ className: styles.lock }, { children: _jsx(Lock, {}) })), _jsx("div", Object.assign({ className: styles.date }, { children: formatDate(i18n.language) })), _jsxs("div", Object.assign({ className: styles.time }, { children: [hours, _jsx("span", Object.assign({ className: styles.colon }, { children: ":" })), minutes] }))] })));
};
const formatDate = (locale) => {
    return moment().locale(locale).format('dddd, DD MMMM');
};
const formatTime = () => {
    return moment().format('HH:mm');
};
