import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@/icons/CheckCircle';
import styles from './styles.module.scss';
export const MailingRewardsMenu = memo(({ activeItem, onChangeActiveItem, offersCount, promocodesCount, friendPromocodesCount, giftCardsCount, hasBonusesApplied = false, hasPromocodeApplied = false, hasFriendPromocodeApplied = false, hasGiftCardApplied = false, hasOfferApplied = false, }) => {
    const { t } = useTranslation();
    const items = [
        {
            id: 'bonuses',
            label: 'Бонусы',
        },
        {
            id: 'offers',
            label: 'Акции',
            count: offersCount,
        },
        {
            id: 'promocodes',
            label: 'Промокоды',
            count: promocodesCount,
        },
        {
            id: 'friendPromocodes',
            label: 'Приведи друга',
            count: friendPromocodesCount,
        },
        {
            id: 'giftCards',
            label: 'Подарочные карты',
            count: giftCardsCount,
        },
    ];
    return (_jsx("ul", Object.assign({ className: styles.menu }, { children: items.map((item) => {
            const isDisabled = (item.id === 'bonuses' && hasBonusesApplied) ||
                (item.id === 'promocodes' && hasPromocodeApplied) ||
                (item.id === 'friendPromocodes' && hasFriendPromocodeApplied) ||
                (item.id === 'giftCards' && hasGiftCardApplied) ||
                (item.id === 'offers' && hasOfferApplied);
            return (_jsxs("li", Object.assign({ className: classNames(styles.menuItem, {
                    [styles.active]: item.id === activeItem,
                    [styles.disabled]: isDisabled,
                }), onClick: () => {
                    if (!isDisabled)
                        onChangeActiveItem(item.id);
                } }, { children: [_jsxs("span", Object.assign({ className: styles.menuItemLabel }, { children: [t(item.label), isDisabled && _jsx(CheckCircle, { className: styles.checkIcon })] })), !!item.count && (_jsx("span", Object.assign({ className: styles.menuItemCount }, { children: item.count })))] }), item.id));
        }) })));
});
