import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getCashierOptionsSelector, getOfferOptionsSelector, getProductsOptionsSelector, getPromocodeFriendOptionsSelector, getPromocodeOptionsSelector, getShopOptionsSelector, } from '@/store/selectors/filters';
import { getCashierOptionsThunk, getOfferOptionsThunk, getProductsBranchThunk, getProductsTreeThunk, getPromocodeFriendOptionsThunk, getPromocodeOptionsThunk, getShopOptionsThunk, } from '@/store/thunk/filters';
export const useMailingGoalFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { currency } = useCurrentUser();
    const shops = useAppSelector(getShopOptionsSelector);
    const cashiers = useAppSelector(getCashierOptionsSelector);
    const products = useAppSelector(getProductsOptionsSelector);
    const offers = useAppSelector(getOfferOptionsSelector);
    const promocodes = useAppSelector(getPromocodeOptionsSelector);
    const friendPromocodes = useAppSelector(getPromocodeFriendOptionsSelector);
    const tree = [
        {
            type: 'branch',
            id: 'purchaseSources',
            label: t('Драйверы покупки'),
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseOffer',
                    label: t('Акции'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getOfferOptionsThunk()),
                    options: offers,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeShared',
                    label: t('Промокоды'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeOptionsThunk()),
                    options: promocodes,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeFriend',
                    label: t('Приведи друга'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeFriendOptionsThunk()),
                    options: friendPromocodes,
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchases',
            label: t('Покупки'),
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseAmount',
                    label: t('Сумма'),
                    template: 'numRange',
                    constraints: {
                        minValue: 0,
                    },
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'branch',
                    id: 'purchaseWhere',
                    label: t('Где продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseShop',
                            label: t('Место продажи'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseCashier',
                            label: t('Продавец'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'purchaseWhat',
                    label: t('Что продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseItem',
                            label: t('Товар'),
                            template: 'checkboxes-tree-loader',
                            loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                            loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                            options: products,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseItemSku',
                            label: t('Артикул'),
                            template: 'textField',
                        },
                    ],
                },
            ],
        },
    ];
    return tree;
};
