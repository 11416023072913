import { jsx as _jsx } from "react/jsx-runtime";
import { useDashboardStats } from '@/app/pages/Dashboard/useDashboardStats';
import { SegmentStatsPanel } from './SegmentStatsPanel';
export const SegmentStatsPanelContainer = ({ onSegmentClick, isProfitVisible = false, currency }) => {
    const { stats: data, isLoading } = useDashboardStats();
    const stats = data === null || data === void 0 ? void 0 : data.stats;
    const statsPerSegment = data === null || data === void 0 ? void 0 : data.statsPerSegment;
    const avgAmount = (stats === null || stats === void 0 ? void 0 : stats.purchasesCountCards)
        ? Number((stats === null || stats === void 0 ? void 0 : stats.revenueCards) || 0) / ((stats === null || stats === void 0 ? void 0 : stats.purchasesCountCards) || 1)
        : 0;
    const avgExpense = (stats === null || stats === void 0 ? void 0 : stats.purchasesCountCards)
        ? Number((stats === null || stats === void 0 ? void 0 : stats.expenses) || 0) / ((stats === null || stats === void 0 ? void 0 : stats.purchasesCountCards) || 1)
        : 0;
    return (_jsx(SegmentStatsPanel, { allClientsSegment: {
            profit: Number((stats === null || stats === void 0 ? void 0 : stats.profitCards) || 0),
            revenue: Number((stats === null || stats === void 0 ? void 0 : stats.revenueCards) || 0),
            avgAmount,
            avgExpense,
        }, segments: statsPerSegment || [], currency: currency, onSegmentClick: onSegmentClick, isProfitVisible: isProfitVisible, isLoading: isLoading }));
};
