import { jsonHandler } from './jsonHandler';
export const jsonToText = (editorState) => {
    const variables = [];
    const text = jsonHandler(editorState, {
        onVariable: (variable) => {
            variables.push(variable);
            return `!${variable.id}`;
        },
    });
    return { text, variables };
};
