/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment from 'moment';
const getTrueKeys = function (object) {
    const out = [];
    Object.entries(object).forEach(([key, value]) => {
        if (value)
            out.push(key);
    });
    return out;
};
export const appendFilter = function (p, key, op, val) {
    if (op !== 'include') {
        p['_not'] = appendFilter(p['_not'] || {}, key, 'include', val);
        return p;
    }
    if (p[key]) {
        p['_and'] = appendFilter(p['_and'] || {}, key, op, val);
        return p;
    }
    p[key] = val;
    return p;
};
export const compileFilters = (filters) => {
    var _a, _b;
    if (!filters)
        return [];
    let andParts = {};
    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (filter.code === 'purchaseProduct' &&
            (filter.rawConditions.branchCheckboxes ||
                filter.rawConditions.leafCheckboxes)) {
            const val = {
                qtyLe: filter.rawConditions.qtyTo
                    ? parseFloat(filter.rawConditions.qtyTo)
                    : undefined,
                qtyGe: filter.rawConditions.qtyFrom
                    ? parseFloat(filter.rawConditions.qtyFrom)
                    : undefined,
                purchaseItemCategoryIdEq: getTrueKeys(filter.rawConditions.branchCheckboxes || {}),
                purchaseItemIdEq: getTrueKeys(filter.rawConditions.leafCheckboxes || {}),
            };
            andParts = appendFilter(andParts, 'purchaseProduct', filter.rawConditions.op, val);
            continue;
        }
        if (filter.code === 'purchaseProduct_Sku' &&
            filter.rawConditions.textValue) {
            const skuVariants = filter.rawConditions.textValue
                .trim()
                .toLowerCase()
                .split(/[\r\n]+/);
            const val = {
                qtyLe: filter.rawConditions.qtyTo
                    ? parseFloat(filter.rawConditions.qtyTo)
                    : undefined,
                qtyGe: filter.rawConditions.qtyFrom
                    ? parseFloat(filter.rawConditions.qtyFrom)
                    : undefined,
                purchaseItemSkuEq: skuVariants.length > 1 ? skuVariants : skuVariants[0],
            };
            andParts = appendFilter(andParts, 'purchaseProduct', filter.rawConditions.op, val);
            continue;
        }
        if (filter.code === 'purchaseItemAttribute') {
            const variants = getTrueKeys(filter.rawConditions.checkboxes || {});
            const val = {
                key: filter.rawConditions.key,
                valueEq: variants.length > 1 ? variants : variants[0],
            };
            andParts = appendFilter(andParts, 'purchaseItemAttribute', filter.rawConditions.op, val);
            continue;
        }
        if (filter.code === 'purchaseItemProperty') {
            const val = {};
            switch (filter.rawConditions.valueType) {
                case 1: {
                    if (filter.rawConditions.from)
                        val.decimalGe = parseFloat(filter.rawConditions.from);
                    if (filter.rawConditions.to)
                        val.decimalLe = parseFloat(filter.rawConditions.to);
                    break;
                }
                case 2: {
                    if (filter.rawConditions.from)
                        val.integerGe = parseInt(filter.rawConditions.from, 10);
                    if (filter.rawConditions.to)
                        val.integerLe = parseInt(filter.rawConditions.to, 10);
                    break;
                }
                case 3: {
                    const variants = getTrueKeys(filter.rawConditions.checkboxes || {});
                    if (variants.length > 0)
                        val.optionEq = variants.length > 1 ? variants : variants[0];
                    break;
                }
                case 4: {
                    if (filter.rawConditions.radios)
                        val.booleanEq = filter.rawConditions.radios.toString() === '1';
                    break;
                }
                case 0:
                default: {
                    if (filter.rawConditions.textValue) {
                        const variants = filter.rawConditions.textValue
                            .trim()
                            .toLowerCase()
                            .split(/[\r\n]+/);
                        val.textEq = variants.length > 1 ? variants : variants[0];
                        break;
                    }
                }
            }
            // @ts-ignore
            if (val != {}) {
                val.propertyId = filter.rawConditions.propertyId;
                andParts = appendFilter(andParts, 'purchaseItemProperty', filter.rawConditions.op, val);
            }
            continue;
        }
        if (filter.code === 'counter') {
            const val = {
                name: filter.rawConditions.name,
                requiredValue: Number(filter.rawConditions.requiredValue),
                applyOn: filter.rawConditions.applyOn,
                periodValue: filter.rawConditions.periodType === 'period'
                    ? Number(filter.rawConditions.periodValue)
                    : null,
                periodUnit: filter.rawConditions.periodType === 'period'
                    ? filter.rawConditions.periodUnit
                    : null,
            };
            andParts = appendFilter(andParts, 'counter', filter.rawConditions.op, val);
            continue;
        }
        if ((_b = (_a = filter.code).startsWith) === null || _b === void 0 ? void 0 : _b.call(_a, 'counter_')) {
            const filters = andParts;
            const op = filter.rawConditions.op;
            if (op === 'include' && !filters.counters)
                filters.counters = [];
            if (op === 'exclude' && !filters._not)
                filters._not = {};
            if (op === 'exclude' && !filters._not.counters)
                filters._not.counters = [];
            const counters = op === 'include' ? filters.counters : filters._not.counters;
            const counter = { offerId: filter.rawConditions.offerId };
            if (filter.rawConditions.from)
                counter.amountGe = parseFloat(filter.rawConditions.from);
            if (filter.rawConditions.to)
                counter.amountLe = parseFloat(filter.rawConditions.to);
            counters.push(counter);
            continue;
        }
        if (filter.rawConditions.textValue) {
            const variants = filter.rawConditions.textValue
                .trim()
                .toLowerCase()
                .split(/[\r\n]+/);
            const val = variants.length > 1 ? variants : variants[0];
            andParts = appendFilter(andParts, filter.code + 'Eq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.from) {
            const val = parseFloat(filter.rawConditions.from);
            andParts = appendFilter(andParts, filter.code + 'Ge', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.to) {
            const val = parseFloat(filter.rawConditions.to);
            andParts = appendFilter(andParts, filter.code + 'Le', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.before) {
            const val = parseFloat(filter.rawConditions.before);
            andParts = appendFilter(andParts, filter.code + 'Before', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.after) {
            const val = parseFloat(filter.rawConditions.after);
            andParts = appendFilter(andParts, filter.code + 'After', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.checkboxes) {
            const variants = getTrueKeys(filter.rawConditions.checkboxes);
            const val = variants.length > 1 ? variants : variants[0];
            andParts = appendFilter(andParts, filter.code + 'Eq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.not && filter.rawConditions.not.checkboxes) {
            const variants = getTrueKeys(filter.rawConditions.not.checkboxes);
            const val = variants.length > 1 ? variants : variants[0];
            andParts = appendFilter(andParts, filter.code + 'Eq', 'exclude', val);
        }
        if (filter.rawConditions.branchCheckboxes) {
            const variants = getTrueKeys(filter.rawConditions.branchCheckboxes);
            const val = variants.length > 1 ? variants : variants[0];
            const branchPrefix = typeof filter.rawConditions.branchPrefix !== 'undefined'
                ? filter.rawConditions.branchPrefix
                : 'CategoryId';
            andParts = appendFilter(andParts, filter.code + branchPrefix + 'Eq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.leafCheckboxes) {
            const variants = getTrueKeys(filter.rawConditions.leafCheckboxes);
            const val = variants.length > 1 ? variants : variants[0];
            const leafPrefix = typeof filter.rawConditions.leafPrefix !== 'undefined'
                ? filter.rawConditions.leafPrefix
                : 'Id';
            andParts = appendFilter(andParts, filter.code + leafPrefix + 'Eq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.radios) {
            const val = filter.rawConditions.radios;
            andParts = appendFilter(andParts, filter.code + 'Eq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.dateFrom) {
            const val = moment(filter.rawConditions.dateFrom, 'DD.MM.YYYY').startOf('day');
            if (val.isValid() && val.year() >= 1900)
                andParts = appendFilter(andParts, filter.code + 'Ge', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.dateTo) {
            const val = moment(filter.rawConditions.dateTo, 'DD.MM.YYYY').endOf('day');
            if (val.isValid() && val.year() >= 1900)
                andParts = appendFilter(andParts, filter.code + 'Le', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.day) {
            const val = parseInt(filter.rawConditions.day.toString());
            andParts = appendFilter(andParts, filter.code + 'DayEq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.month) {
            const val = parseInt(filter.rawConditions.month.toString());
            andParts = appendFilter(andParts, filter.code + 'MonthEq', filter.rawConditions.op, val);
        }
        if (filter.rawConditions.year) {
            const val = parseInt(filter.rawConditions.year.toString());
            andParts = appendFilter(andParts, filter.code + 'YearEq', filter.rawConditions.op, val);
        }
    }
    return andParts;
};
