var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Cancel } from '@/icons/Cancel';
import { Shop } from '@/icons/Shop';
import { FormPointOfSale } from './FormPointOfSale';
import styles from './styles.module.scss';
export const FormPointOfSaleModal = memo((_a) => {
    var { id = 'point-of-sale-form', isOpen, onClose, isEdit, isLoading, onDelete, error } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "isEdit", "isLoading", "onDelete", "error"]);
    const { defaultValues } = props;
    const { t } = useTranslation();
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasShopManage = userHasAccessTo([
        'SHOPS_OWN_MANAGE',
        'SHOPS_MANAGE',
    ]);
    const title = isEdit
        ? t('Редактирование точки продаж')
        : t('Новая точка продаж');
    const handleDelete = useCallback(() => {
        if (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.id)
            onDelete(defaultValues.id, defaultValues.name);
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.id]);
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: title, titleIcon: _jsx(Shop, {}), size: '660', isForm: true, footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: isEdit ? (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: !userHasShopManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, disabled: isLoading || !userHasShopManage, size: 'large' }, { children: t('Сохранить изменения') })) })), _jsx(Tooltip, Object.assign({ title: !userHasShopManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'button', onClick: handleDelete, disabled: isLoading || !userHasShopManage, icon: _jsx(Cancel, {}), theme: 'danger', size: 'large' }, { children: t('Удалить точку') })) }))] })) : (_jsx(Tooltip, Object.assign({ title: !userHasShopManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, disabled: isLoading || !userHasShopManage, theme: 'primary', size: 'extra-large' }, { children: t('Создать точку') })) }))) }))] }), isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormPointOfSale, Object.assign({ id: id, setHasFormChanged: setHasFormChanged }, props)) })));
});
