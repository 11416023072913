import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { GrowingDiscountInput } from '@/components/GrowingDiscountInput/GrowingDiscountInput';
import { DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT } from '../constants';
export const FormOfferGrowingDiscount = ({ labelWidth, currency }) => {
    const { control, formState, setValue, watch, trigger } = useFormContext();
    const { errors, isSubmitted } = formState;
    const offerType = watch('type');
    const discountType = watch('discountType');
    const shouldDisplay = offerType === 'growing_discount';
    const discountTypeOptions = [
        {
            value: DISCOUNT_TYPE_FIXED,
            label: currency,
        },
        {
            value: DISCOUNT_TYPE_PERCENT,
            label: '%',
        },
    ];
    const validate = (value = []) => {
        const undefinedAmount = value.filter((i) => !i.amount);
        if (undefinedAmount.length > 0)
            return 'required';
        if (discountType === DISCOUNT_TYPE_PERCENT) {
            const outOfRange = value.filter((i) => {
                const n = Number(i.amount);
                return n < 0 || n > 100;
            });
            if (outOfRange.length > 0)
                return 'out of range';
        }
        return true;
    };
    useEffect(() => {
        if (isSubmitted)
            trigger('growingDiscount');
    }, [isSubmitted, discountType]);
    if (shouldDisplay)
        return (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth }, { children: _jsx(Controller, { name: 'growingDiscount', control: control, rules: { validate }, render: ({ field }) => (_jsx(GrowingDiscountInput, { value: field.value || [], onChange: (growingDiscount) => {
                        setValue('growingDiscount', growingDiscount, {
                            shouldValidate: isSubmitted,
                        });
                    }, scales: discountTypeOptions, selectedScale: discountType, onChangeScale: (discountType) => {
                        setValue('discountType', discountType);
                    }, isPercentScale: discountType === DISCOUNT_TYPE_PERCENT, hasError: !!errors.growingDiscount })) }) })));
    return null;
};
