import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popper } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { HexColor } from '@/components/Input/HexColor';
import { EyeDropper } from '@/icons/EyeDropper';
import { fgColor } from '@/utils/colors';
import { getEyeDropperColor, hasEyeDropperSupport } from '@/utils/eyeDropper';
import styles from './styles.module.scss';
export const ColorInput = ({ id, type = 'text-color', iconSize, value, onChange, onFocus, onBlur, placeholder, }) => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const anchorEl = useRef(null);
    const handleOpenPopup = useCallback(() => setPopupIsOpen(true), []);
    const handleClosePopup = useCallback(() => {
        setPopupIsOpen(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [onBlur]);
    const placeholderIsColor = !!placeholder && placeholder.startsWith('#') && placeholder.length === 7;
    const iconColor = value || (placeholderIsColor ? placeholder : '');
    return (_jsxs("div", Object.assign({ className: styles.wrapper, tabIndex: -1, onFocus: handleOpenPopup, onBlur: handleClosePopup }, { children: [_jsx(HexColor, { ref: anchorEl, id: id, placeholder: placeholder, color: value, onChange: onChange, leftElement: _jsx("span", Object.assign({ className: classNames(styles.colorBadge, {
                        [styles.small]: iconSize === 'small',
                        [styles.large]: iconSize === 'large',
                    }), style: {
                        backgroundColor: iconColor || '#ffffff',
                        color: fgColor(iconColor || '#ffffff'),
                    } }, { children: type === 'text-color' ? 'T' : '' })), isActive: popupIsOpen, onFocus: onFocus }), _jsx(Popper, Object.assign({ className: styles.popper, open: popupIsOpen, anchorEl: anchorEl.current, placement: 'bottom-start', modifiers: [{ name: 'offset', options: { offset: [-4, 10] } }], disablePortal: true }, { children: _jsxs("div", Object.assign({ className: classNames(styles.pickerWrapper, {
                        [styles.hasEyeDropper]: hasEyeDropperSupport,
                    }) }, { children: [_jsx(HexColorPicker, { className: styles.picker, color: value, onChange: onChange }), hasEyeDropperSupport && (_jsx("div", Object.assign({ className: styles.eyeDropperWrapper }, { children: _jsx("button", Object.assign({ type: 'button', onMouseDown: (e) => {
                                    e.preventDefault();
                                    getEyeDropperColor().then((color) => {
                                        onChange === null || onChange === void 0 ? void 0 : onChange(color || value || '#ffffff');
                                    });
                                } }, { children: _jsx(EyeDropper, {}) })) })))] })) }))] })));
};
