var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Chevron } from '@/icons/Chevron';
import { isPresetEqualsToRange, PresetPeriod } from '@/utils/getPresetPeriod';
import styles from './styles.module.scss';
export const DatePeriodButton = forwardRef((_a, ref) => {
    var { start, end, isOpen, onClick } = _a, otherProps = __rest(_a, ["start", "end", "isOpen", "onClick"]);
    const { t } = useTranslation();
    let startContent = start ? moment(start).format('DD.MM.YYYY') : '—';
    let endContent = end ? moment(end).format('DD.MM.YYYY') : '—';
    if (startContent === endContent)
        endContent = '';
    if (isPresetEqualsToRange(PresetPeriod.Today, [start, end])) {
        startContent = t('Сегодня');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.Yesterday, [start, end])) {
        startContent = t('Вчера');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.Week, [start, end])) {
        startContent = t('последнюю неделю');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.Month, [start, end])) {
        startContent = t('последний месяц');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.Quarter, [start, end])) {
        startContent = t('последний квартал');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.Year, [start, end])) {
        startContent = t('последний год');
        endContent = '';
    }
    if (isPresetEqualsToRange(PresetPeriod.AllTime, [start, end])) {
        startContent = t('Всё время');
        endContent = '';
    }
    return (_jsxs("button", Object.assign({ ref: ref }, otherProps, { className: classNames(styles.button, {
            [styles.active]: isOpen,
        }), type: 'button', onClick: onClick }, { children: [startContent && (_jsx("span", Object.assign({ className: styles.buttonStart }, { children: startContent }))), endContent && _jsx("span", Object.assign({ className: styles.buttonEnd }, { children: endContent })), _jsx("span", Object.assign({ className: styles.buttonChevron }, { children: _jsx(Chevron, { className: classNames(styles.chevron, {
                        [styles.flipped]: isOpen,
                    }) }) }))] })));
});
