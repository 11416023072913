import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { TwoColumnLayout } from '@/components/TwoColumnLayout/TwoColumnLayout';
import { VarsPanelLeft } from './VarsPanelLeft';
import { VarsPanelLeftHeader } from './VarsPanelLeftHeader';
import { VarsPanelRight } from './VarsPanelRight';
export const VarsPanel = ({ variables, onSelectVariable, onClose, currency, }) => {
    const [selectedGroup, setSelectedGroup] = useState(variables.find((item) => 'title' in item) || null);
    return (_jsx("div", Object.assign({ onMouseDown: (e) => e.preventDefault() }, { children: _jsx(TwoColumnLayout, { left: _jsxs(_Fragment, { children: [_jsx(VarsPanelLeftHeader, {}), _jsx(VarsPanelLeft, { variables: variables, onSelectVariable: (variable) => {
                            onSelectVariable(variable);
                            if (onClose)
                                onClose();
                        }, selectedGroupTitle: (selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.title) || '', onSelectGroup: setSelectedGroup, currency: currency })] }), right: _jsx(VarsPanelRight, { variables: (selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.variables) || [], onSelectVariable: (variable, parameters) => {
                    onSelectVariable(variable, parameters);
                    if (onClose)
                        onClose();
                }, currency: currency }) }) })));
};
