import { useLocation } from 'react-router-dom';
import { useLevels } from '../WalletCards/useLevels';
import { useWalletCardList } from '../WalletCards/useWalletCardList';
import { convertWalletCardToFormValues } from './convertWalletCardToFormValues';
import { useDefaultValues } from './useDefaultValues';
export const useWalletCardData = (cardId, levelId) => {
    var _a;
    const { data, isLoading } = useWalletCardList();
    const { levels } = useLevels();
    const location = useLocation();
    const getDefaultValues = useDefaultValues();
    const defaultValuesFromState = (_a = location.state) === null || _a === void 0 ? void 0 : _a.defaultWalletCardValues;
    const selectedWalletCard = data === null || data === void 0 ? void 0 : data.walletCards.find((card) => card.id === cardId);
    const cardLevel = selectedWalletCard ? selectedWalletCard.level : levelId;
    const selectedLevel = levels.find((level) => level.id === cardLevel);
    return {
        selectedLevel,
        selectedWalletCard,
        defaultValues: selectedWalletCard
            ? convertWalletCardToFormValues(selectedWalletCard)
            : defaultValuesFromState
                ? convertWalletCardToFormValues(defaultValuesFromState)
                : getDefaultValues(levelId),
        isLoading: cardId ? isLoading : false,
    };
};
