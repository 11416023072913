import moment from 'moment-timezone';
import { useGetPromocodeQuery } from '@/api/query/promocodes';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_PERSONAL, PROMOCODE_TYPE_SHARED, } from '@/const/promocode';
export const useInitialData = (promocodeId, tz) => {
    var _a, _b;
    const { data: selectedPromocode, isLoading } = useGetPromocodeQuery({ id: promocodeId || 0 }, { skip: !promocodeId });
    let codeType;
    let availableFrom = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.availableFrom;
    let availableTo = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.availableTo;
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_SHARED)
        codeType = 'shared';
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_PERSONAL)
        codeType = 'personal';
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_FRIEND)
        codeType = 'friend';
    if (availableFrom && tz)
        availableFrom = moment(availableFrom).tz(tz).format('YYYY-MM-DD');
    if (availableTo && tz)
        availableTo = moment(availableTo).tz(tz).format('YYYY-MM-DD');
    return {
        selectedPromocode,
        defaultValues: promocodeId
            ? Object.assign(Object.assign({}, selectedPromocode), { codeType, allowedCategories: ((_a = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.allowedCategories) === null || _a === void 0 ? void 0 : _a.configuration) || [], conditions: ((_b = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.conditions) === null || _b === void 0 ? void 0 : _b.configuration) || [], availableFrom,
                availableTo }) : undefined,
        isLoading: promocodeId ? isLoading : false,
    };
};
