import { useGetDashboardChartQuery } from '@/api/query/dashboard';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useDashboardChart = (dataset1, dataset2, scale) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data: chart, isFetching } = useGetDashboardChartQuery({
        periodStart,
        periodEnd,
        dataset1,
        dataset2,
        scale,
    });
    return { chart, isLoading: isFetching };
};
