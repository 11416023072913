import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useEffect, useRef, } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const OTPInput = forwardRef(({ id, name, value = '', onChange, onFocus, onBlur, numInputs = 6, autoFocus = false, hasError = false, }, ref) => {
    const inputRefs = useRef([]);
    useEffect(() => {
        var _a;
        if (autoFocus)
            (_a = inputRefs.current[0]) === null || _a === void 0 ? void 0 : _a.focus();
    }, [autoFocus]);
    const isInputValueValid = (value) => {
        return !isNaN(Number(value)) && value.trim().length === 1;
    };
    const focusInput = (idx) => {
        var _a, _b;
        const active = Math.max(Math.min(idx, numInputs - 1), 0);
        (_a = inputRefs.current[active]) === null || _a === void 0 ? void 0 : _a.focus();
        (_b = inputRefs.current[active]) === null || _b === void 0 ? void 0 : _b.select();
    };
    // ---------------------------------------------------------------------------
    const handleInputInput = (idx, e) => {
        if (!isInputValueValid(e.target.value))
            e.target.value = '';
    };
    const handleInputChange = (idx, e) => {
        if (!isInputValueValid(e.target.value))
            return;
        const otp = value.split('');
        const char = e.target.value.trim()[0];
        if (idx <= value.length - 1) {
            otp[idx] = char;
            focusInput(idx + 1);
        }
        else {
            otp.push(char);
            focusInput(otp.length);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(otp.join(''));
    };
    const handleInputKeyDown = (idx, e) => {
        const otp = value.split('');
        if ([e.code, e.key].includes('Backspace')) {
            e.preventDefault();
            otp[idx] = '';
            onChange === null || onChange === void 0 ? void 0 : onChange(otp.join(''));
            focusInput(idx - 1);
            return;
        }
        if (e.code === 'Delete') {
            e.preventDefault();
            otp[idx] = '';
            onChange === null || onChange === void 0 ? void 0 : onChange(otp.join(''));
            return;
        }
        if (e.code === 'ArrowLeft') {
            e.preventDefault();
            focusInput(idx - 1);
            return;
        }
        if (e.code === 'ArrowRight') {
            e.preventDefault();
            focusInput(idx + 1);
            return;
        }
        if (e.key === otp[idx]) {
            e.preventDefault();
            focusInput(idx + 1);
            return;
        }
        if (e.code === 'Spacebar' ||
            e.code === 'Space' ||
            e.code === 'ArrowUp' ||
            e.code === 'ArrowDown') {
            e.preventDefault();
            return;
        }
    };
    const handleInpuPaste = (idx, e) => {
        e.preventDefault();
        const otp = [];
        const pastedData = e.clipboardData.getData('text/plain').split('');
        let next = 0;
        for (let i = 0; i < numInputs; i += 1)
            if (pastedData[i] && isInputValueValid(pastedData[i])) {
                next += 1;
                otp[next] = pastedData[i];
            }
        focusInput(next);
        onChange === null || onChange === void 0 ? void 0 : onChange(otp.join(''));
    };
    const handleInputFocus = (idx, e) => {
        e.target.select();
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    };
    const handleInputBlur = (idx, e) => {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    };
    // ---------------------------------------------------------------------------
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: new Array(numInputs).fill(null).map((_, idx) => {
            var _a;
            const otp = value.split('');
            const isFirstInput = idx === 0;
            const isFirstEmptyInput = idx === otp.length;
            const createRef = (element) => (inputRefs.current[idx] = element);
            return (_jsx(Input, { ref: isFirstEmptyInput ? mergeRefs([ref, createRef]) : createRef, id: isFirstInput ? id : undefined, name: isFirstInput ? name : undefined, wrapperClassName: styles.input, value: (_a = otp[idx]) !== null && _a !== void 0 ? _a : '', onInput: handleInputInput.bind(null, idx), onChange: handleInputChange.bind(null, idx), onFocus: handleInputFocus.bind(null, idx), onBlur: handleInputBlur.bind(null, idx), onKeyDown: handleInputKeyDown.bind(null, idx), onPaste: handleInpuPaste.bind(null, idx), autoComplete: 'off', type: 'number', inputMode: 'numeric', preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: hasError }, `input-${idx}`));
        }) })));
});
