var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './styles.module.scss';
export const TextArea = forwardRef((_a, ref) => {
    var { hasError = false, className, disabled } = _a, props = __rest(_a, ["hasError", "className", "disabled"]);
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.error]: hasError,
        }) }, { children: _jsx("div", Object.assign({ className: classNames(styles.inputWrapper, styles.textareaWrapper, {
                [styles.disabled]: disabled,
            }) }, { children: _jsx("textarea", Object.assign({ ref: ref, className: classNames(styles.input, className), disabled: disabled }, props)) })) })));
});
