import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { ARB_TYPE_APPENDS_TO_OTHER, ARB_TYPE_EXCLUSIVE, ARB_TYPE_GIVES_WAY_TO_OTHER, ARB_TYPE_TAKES_OVER_OTHER, ARB_TYPE_WINS_WHEN_MORE_PROFITABLE, } from '../constants';
export const FormOfferArbType = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const options = [
        {
            value: ARB_TYPE_EXCLUSIVE,
            label: t('единственная в чеке'),
            hint: 'если применилась такая акция, к чеку не применится никакая другая',
        },
        {
            value: ARB_TYPE_TAKES_OVER_OTHER,
            label: t('вытесняет другие'),
            hint: 'если к строке чека применилась такая акция, акция с типом «уступает другим» к этой строке не применится',
        },
        {
            value: ARB_TYPE_GIVES_WAY_TO_OTHER,
            label: t('уступает другим'),
            hint: 'применяется на те строки чека, на которые не применена акция «вытесняет другие»',
        },
        {
            value: ARB_TYPE_WINS_WHEN_MORE_PROFITABLE,
            label: t('срабатывает, если выгоднее'),
            hint: 'применяется к строке, только если дает большую скидку (или большее количество бонусов), чем уже примененная к этой строке акция',
        },
        {
            value: ARB_TYPE_APPENDS_TO_OTHER,
            label: t('суммируется с другими'),
            hint: 'применяется к строке, даже если к той же строке уже применены другие акции',
        },
    ];
    const arbType = watch('arbType');
    const getHint = () => {
        const found = options.find((i) => i.value === arbType);
        return (found === null || found === void 0 ? void 0 : found.hint) || '';
    };
    return (_jsx(FormFieldRow, Object.assign({ label: t('Принцип работы'), labelFor: 'arbType', labelWidth: labelWidth, controlSize: '250', tooltip: _jsxs(Trans, Object.assign({ i18nKey: 'OFFER_ARB_TYPE_HELP' }, { children: ["\u041F\u0440\u0430\u0432\u0438\u043B\u0430 \u043A\u043E\u043C\u0431\u0438\u043D\u0430\u0446\u0438\u0438 \u043F\u0440\u0438 \u0432\u0441\u0442\u0440\u0435\u0447\u0435 \u0441 \u0434\u0440\u0443\u0433\u0438\u043C\u0438 \u0430\u043A\u0446\u0438\u044F\u043C\u0438 \u0432 \u0447\u0435\u043A\u0435.", ' ', _jsx("a", Object.assign({ href: 'https://help.maxma.com/p/jxEViWBRO5PGtn/Akcii', target: '_blank', rel: 'noreferrer' }, { children: "\u041F\u043E\u0434\u0440\u043E\u0431\u043D\u0435\u0435 \u0442\u0443\u0442" }))] })), tooltipPlacement: 'right', help: t(getHint()), helpSize: '250' }, { children: _jsx(Controller, { name: 'arbType', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'arbType', placeholder: t('выберите принцип'), value: options.find((item) => item.value === field.value) || null, onChange: (arbType) => {
                    setValue('arbType', arbType === null || arbType === void 0 ? void 0 : arbType.value, {
                        shouldDirty: true,
                    });
                }, options: options, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: true, asSelect: true }))) }) })));
};
