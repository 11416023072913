import { jsx as _jsx } from "react/jsx-runtime";
import { BirthdateDays } from './controls/BirthdateDays';
import { CheckboxesLoader } from './controls/CheckboxesLoader';
import { CheckboxesPaginatedLoader } from './controls/CheckboxesPaginatedLoader';
import { CheckboxesSegments } from './controls/CheckboxesSegments';
import { CheckboxesSegmentsBoth } from './controls/CheckboxesSegmentsBoth';
import { CheckboxesSimple } from './controls/CheckboxesSimple';
import { CheckboxesTreeLoader } from './controls/CheckboxesTreeLoader';
import { Counter } from './controls/Counter';
import { DateControl } from './controls/Date';
import { DateRange } from './controls/DateRange';
import { EmptyControl } from './controls/EmptyControl';
import { NumRange } from './controls/NumRange';
import { Radios } from './controls/Radios';
import { TextField } from './controls/TextField';
export const FilterForm = ({ item, onApply, onBack, }) => {
    switch (item === null || item === void 0 ? void 0 : item.template) {
        case 'checkboxes-loader': {
            return _jsx(CheckboxesLoader, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'checkboxes-paginated-loader': {
            return (_jsx(CheckboxesPaginatedLoader, { onBack: onBack, item: item, onApply: onApply }));
        }
        case 'checkboxes-segments': {
            return item.useBothOperations ? (_jsx(CheckboxesSegmentsBoth, { onBack: onBack, item: item, onApply: onApply })) : (_jsx(CheckboxesSegments, { onBack: onBack, item: item, onApply: onApply }));
        }
        case 'checkboxes-simple': {
            return _jsx(CheckboxesSimple, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'checkboxes-tree-loader': {
            return (_jsx(CheckboxesTreeLoader, { onBack: onBack, item: item, onApply: onApply }));
        }
        case 'date': {
            return _jsx(DateControl, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'dateRange': {
            return _jsx(DateRange, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'numRange': {
            return _jsx(NumRange, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'birthdateDays': {
            return _jsx(BirthdateDays, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'radios': {
            return _jsx(Radios, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'textField': {
            return _jsx(TextField, { onBack: onBack, item: item, onApply: onApply });
        }
        case 'counter': {
            return _jsx(Counter, { onBack: onBack, item: item, onApply: onApply });
        }
        default: {
            return _jsx(EmptyControl, {});
        }
    }
};
