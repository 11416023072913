import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useCallback } from 'react';
import { Close } from '@/icons/Close';
import styles from './styles.module.scss';
export const FilterBadge = forwardRef(({ className, text, onClick, onClose, isActive = false, isDraft = false, isStatic = false, isUntruncated = false, }, ref) => {
    const handleClose = useCallback((e) => {
        e.stopPropagation();
        if (onClose)
            onClose();
    }, [onClose]);
    return (_jsxs("button", Object.assign({ ref: ref, className: classNames(className, styles.badge, {
            [styles.active]: isActive,
            [styles.draft]: isDraft,
            [styles.static]: isStatic,
        }), type: 'button', onClick: onClick }, { children: [typeof text === 'string' ? (_jsx("span", { className: classNames({ [styles.text]: !isUntruncated }), dangerouslySetInnerHTML: { __html: text } })) : (_jsx("span", Object.assign({ className: classNames({ [styles.text]: !isUntruncated }) }, { children: text }))), !!onClose && (_jsx("button", Object.assign({ className: styles.close, type: 'button', onClick: handleClose }, { children: _jsx(Close, {}) })))] })));
});
