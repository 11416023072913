import { createNode } from '../createNode';
export const createChannelReceivedNode = (data) => {
    return createNode({
        type: 'channel-received',
        data,
        position: { x: 0, y: 0 },
        style: { opacity: 0 },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateChannelReceivedNodeData = (data, t) => {
    const errors = {};
    if (!data.conditions.length)
        errors.conditions = t('Добавьте хотя бы одно условие');
    return errors;
};
