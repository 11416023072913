import { api } from './';
export const segmentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBrandSegments: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/segments',
            }),
            providesTags: ['BrandSegments'],
        }),
        getSegmentList: build.query({
            query: (body) => ({
                method: 'POST',
                url: `/segment/list`,
                body,
            }),
            providesTags: ['SegmentList'],
        }),
        getSegmentListShort: build.query({
            query: (body) => ({
                method: 'GET',
                url: `/segment/list/short`,
                body,
            }),
            providesTags: ['SegmentListShort'],
        }),
        getSegment: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/segment/get/${id}`,
            }),
            providesTags: ['Segment'],
        }),
        addSegment: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/segment/add',
                body,
            }),
            invalidatesTags: [
                'Segment',
                'SegmentList',
                'SegmentListShort',
                'BrandSegments',
            ],
        }),
        setSegment: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/segment/set',
                body,
            }),
            invalidatesTags: [
                'Segment',
                'SegmentList',
                'SegmentListShort',
                'BrandSegments',
            ],
        }),
        deleteSegment: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/segment/delete/${id}`,
            }),
            invalidatesTags: ['SegmentList', 'SegmentListShort', 'BrandSegments'],
        }),
        deleteSegmentClients: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/segment/delete-clients/${id}`,
            }),
            invalidatesTags: [
                'SegmentList',
                'SegmentListShort',
                'ClientCount',
                'ClientList',
            ],
        }),
    }),
});
export const { useGetBrandSegmentsQuery, useGetSegmentListQuery, useGetSegmentQuery, useAddSegmentMutation, useSetSegmentMutation, useDeleteSegmentMutation, useDeleteSegmentClientsMutation, useGetSegmentListShortQuery, } = segmentApi;
