import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { TextAlignLeft } from '@/icons/TextAlignLeft';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
export const FieldsApple = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setAppleCardIsFlipped, markReadyToSave, appleFields, setAppleFields, isElementClick, setIsElementClick, } = useWalletCardForm();
    const handleFocus = () => {
        setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (fields) => {
        setAppleFields((appleFields) => (Object.assign(Object.assign({}, appleFields), fields)));
    };
    return (_jsx("div", Object.assign({ className: styles.fieldsWrapper }, { children: ['3', '4', '5', '6'].map((fieldKey, i) => {
            const id = `apple-field-${fieldKey}`;
            const titleKey = `field_${fieldKey}_Title`;
            const valueKey = `field_${fieldKey}_Value`;
            const varsKey = `field_${fieldKey}_Vars`;
            return (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: id }, { children: [_jsx(TextAlignLeft, {}), t('поле') + ' ' + (i + 1).toString()] })), _jsx(Input, { id: id, placeholder: t('название'), value: appleFields[titleKey], onChange: (e) => {
                            handleChange({ [titleKey]: e.target.value });
                        }, autoFocus: i === 0 && !isElementClick, onFocus: handleFocus, onBlur: markReadyToSave }), _jsx(SmartInput, { placeholder: t('значение'), value: appleFields[valueKey], vars: appleFields[varsKey], onChange: (value, vars) => {
                            handleChange({
                                [valueKey]: value,
                                [varsKey]: vars,
                            });
                        }, onFocus: handleFocus, onBlur: markReadyToSave })] }), fieldKey));
        }) })));
};
