import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { dateSample } from '../../utils/dateSample';
import styles from './styles.module.scss';
export const AutocompleteDateFormats = ({ value, onChange, }) => {
    const { i18n } = useTranslation();
    const dateFormats = [
        {
            label: dateSample.format('DD.MM.YYYY'),
            value: 'DD.MM.YYYY',
        },
        {
            label: dateSample.locale(i18n.language).format('D MMMM'),
            value: 'D MMMM',
        },
        {
            label: dateSample.locale(i18n.language).format('D MMM'),
            value: 'D MMM',
        },
    ];
    return (_jsx(Autocomplete, { wrapperClassName: styles.autocomplete, options: dateFormats, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, value: dateFormats.find((df) => df.value === value) || null, onChange: onChange, hasResetButton: false, asSelect: true, disablePortal: true }, dateFormats.length));
};
export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';
