import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { GiftCardWarning } from '../../Warnings/GiftCardWarning';
import { PromocodeWarning } from '../../Warnings/PromocodeWarning';
import formStyles from '../styles.module.scss';
import { useCalculate } from '../useCalculate';
export const SMSFormText = () => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const { triggerType, selectedNode, validationErrorMap } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const calculate = useCalculate();
    const [smsText, setSmsText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _a === void 0 ? void 0 : _a.smsText) || '');
    const updateSmsText = (smsText) => {
        selectedNode.update({
            sms: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms), { smsText }),
        });
        calculate({ smsText }).then((response) => {
            selectedNode.update({
                sms: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms), { smsText, smsTextLength: response.smsTextLength, smsMessagesCount: response.smsMessagesCount }),
            });
        });
    };
    const handleUpdateSmsText = () => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _a === void 0 ? void 0 : _a.smsText) !== smsText)
            updateSmsText(smsText);
    };
    const editorContext = {
        channel: 'sms',
        triggerType: triggerType,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
    };
    return (_jsxs("div", { children: [_jsx(MessageEditor, { initialValue: smsText, onChange: setSmsText, onBlur: handleUpdateSmsText, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.smsText) }), (errors === null || errors === void 0 ? void 0 : errors.smsText) ? (_jsx("div", Object.assign({ className: formStyles.error }, { children: errors.smsText }))) : (_jsx("div", Object.assign({ className: formStyles.help }, { children: t('Длина:') +
                    ' ' +
                    (((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _b === void 0 ? void 0 : _b.smsTextLength) || 0) +
                    ` (${((_c = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.sms) === null || _c === void 0 ? void 0 : _c.smsMessagesCount) || 0} SMS)` }))), _jsx(PromocodeWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: smsText }), _jsx(GiftCardWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: smsText })] }));
};
