import moment from 'moment';
export const isItemPlanned = ({ availableFrom, }) => {
    if (!availableFrom)
        return false;
    return moment(availableFrom).isAfter();
};
export const isItemActive = ({ activeFrom, activeTo, }) => {
    if (activeFrom && !moment(activeFrom).isBefore())
        return false;
    if (activeTo && !moment(activeTo).isAfter())
        return false;
    return true;
};
