var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { WalletCardFormProvider, } from './context/WalletCardFormProvider';
import { Card } from './helpers/Card/Card';
import { Content } from './helpers/Content/Content';
import { Layout } from './helpers/Layout/Layout';
import { Menu } from './helpers/Menu/Menu';
export const WalletCardForm = (_a) => {
    var { title, cardFooter } = _a, props = __rest(_a, ["title", "cardFooter"]);
    useGetLastBreadcrumb(title);
    return (_jsx(WalletCardFormProvider, Object.assign({}, props, { children: _jsx(Layout, { content: _jsx(Content, { menu: _jsx(Menu, {}) }), card: _jsx(Card, {}), cardFooter: cardFooter }) })));
};
