import Raven from 'raven-js';
import { useEffect } from 'react';
import { useCurrentUser } from '@/app/useCurrentUser';
const isProd = process.env.NODE_ENV === 'production';
let userIsSet = false;
let brandIsSet = false;
const setUser = (user) => {
    if (!userIsSet) {
        Raven.setUserContext(user);
        userIsSet = true;
    }
};
const unsetUser = () => {
    Raven.setUserContext();
    userIsSet = false;
};
const setBrand = (brand) => {
    if (!brandIsSet) {
        Raven.setExtraContext({
            brand: {
                globalKey: brand.brand.globalKey,
                name: brand.brand.name,
            },
        });
        brandIsSet = true;
    }
};
const unsetBrand = () => {
    Raven.setExtraContext();
    brandIsSet = false;
};
export const useSentry = () => {
    const { user, brand } = useCurrentUser();
    useEffect(() => {
        if (isProd)
            if (user)
                setUser(user);
            else
                unsetUser();
    }, [user]);
    useEffect(() => {
        if (isProd)
            if (brand)
                setBrand(brand);
            else
                unsetBrand();
    }, [brand]);
};
