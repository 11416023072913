import { useGetOfferCountQuery, useGetOfferListQuery } from '@/api/query/offers';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useData = ({ searchText, limit, offset, archived, }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data: count, isLoading: countIsLoading, isFetching: countisFetching, } = useGetOfferCountQuery({ searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    const { data, isLoading: dataIsLoading, isFetching: dataIsFetching, } = useGetOfferListQuery({ searchText, periodStart, periodEnd, limit, offset, archived }, { refetchOnMountOrArgChange: true });
    const isLoading = countIsLoading || dataIsLoading;
    const isFetching = countisFetching || dataIsFetching;
    return {
        count,
        data,
        isLoading,
        isFetching,
    };
};
