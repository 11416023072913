export const buildCounters = (counters) => {
    const leaves = counters.map((counter, i) => ({
        type: 'leaf',
        id: `counter_${counter.offerId}_${i + 1}`,
        label: counter.counterName,
        template: 'numRange',
        constraints: {
            minValue: 0,
            maxValue: counter.requiredValue - 1,
        },
        inputPostfix: counter.applyOn === 'product' ? 'PRODUCT_RANGE' : 'PURCHASE_RANGE',
        pluralPostfix: true,
        composePostfixToText: true,
        showConstraintsInHelp: true,
        extra: { offerId: counter.offerId },
    }));
    return leaves;
};
