import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Warning } from '@/icons/Warning';
import styles from './styles.module.scss';
export const WalletCardFormErrors = ({ errors = [], }) => {
    const { t } = useTranslation();
    if (!errors.length)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.icon }, { children: _jsx(Warning, {}) })), _jsx("div", Object.assign({ className: styles.title }, { children: t('Пожалуйста, заполните поля') })), _jsx("ul", Object.assign({ className: styles.list }, { children: errors.map((error) => (_jsx("li", { children: _jsx("a", Object.assign({ className: styles.link, href: '#', onClick: (e) => {
                            e.preventDefault();
                            error.onClick();
                        } }, { children: t(error.message) })) }, error.id))) }))] })));
};
