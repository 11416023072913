export const TOKEN = '4975418d48482494fd143286b6302ef4a5a5bacd';
export const URL_SUGGEST_ADDRESS = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
export const OPTIONS = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${TOKEN}`,
    },
};
