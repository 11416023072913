import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ImportModal } from '@/components/ImportModal/ImportModal';
import { ROUTES } from '../routes';
import { ImportsPanel } from './ImportsPanel';
import { useAddImport } from './useAddImport';
import { useDeleteImport } from './useDeleteImport';
import { useImportsList } from './useImportsList';
export const Imports = () => {
    const navigate = useNavigate();
    const goToEdit = (data) => {
        const path = generatePath(ROUTES.IMPORTS_EDIT, {
            importId: data.id.toString(),
        });
        navigate(path);
    };
    const [selectedImportType, setSelectedImportType] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { imports, isLoading, isFetching } = useImportsList();
    const { addImport, isAdding } = useAddImport((data) => {
        setSelectedImportType(null);
        goToEdit(data);
    });
    const deleteImport = useDeleteImport();
    return (_jsxs(_Fragment, { children: [_jsx(ImportsPanel, { items: imports, isLoading: isLoading, isFetching: isFetching, onImport: setSelectedImportType, onEdit: goToEdit, onDelete: deleteImport }), _jsx(ImportModal, { isOpen: selectedImportType !== null, file: selectedFile, onSelect: (data) => {
                    if (typeof data !== 'string')
                        setSelectedFile(data);
                    if (selectedImportType !== null)
                        addImport(selectedImportType, data);
                }, onClose: () => setSelectedImportType(null), isLoading: isAdding })] }));
};
