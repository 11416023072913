import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import styles from './styles.module.scss';
export const GoogleWalletCardDetailsInfo = ({ items = [], onElementClick, clicksDisabled = false }) => {
    return (_jsx(_Fragment, { children: items.map((item, i) => {
            if (!item.title.trim() && !item.content.trim())
                return null;
            return (_jsx("div", Object.assign({ className: styles.detailsItemWrapper }, { children: _jsxs(WalletCardClickableElement, Object.assign({ className: styles.detailsItem, onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(`backside-item-${i}-title`), disabled: clicksDisabled }, { children: [_jsx("div", Object.assign({ className: styles.detailsItemTitle }, { children: item.title })), _jsx("div", { className: styles.detailsItemContent, dangerouslySetInnerHTML: {
                                __html: item.content,
                            } })] })) }), `${item.title || 'item'}-${i}`));
        }) }));
};
