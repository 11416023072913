import { useEffect, useRef } from 'react';
import { useWalletCardForm } from './useWalletCardForm';
export const WalletCardFormChangeHandler = ({ onChange }) => {
    const context = useWalletCardForm();
    const prevContext = useRef(context);
    useEffect(() => {
        if (!context.readyToSave)
            return;
        context.setReadyToSave(false);
        if (prevContext.current.appleHead !== context.appleHead ||
            prevContext.current.googleHead !== context.googleHead ||
            prevContext.current.bannerCounter !== context.bannerCounter ||
            prevContext.current.appleBanner !== context.appleBanner ||
            prevContext.current.googleBanner !== context.googleBanner ||
            prevContext.current.bannerType !== context.bannerType ||
            prevContext.current.appleFields !== context.appleFields ||
            prevContext.current.googleFields !== context.googleFields ||
            prevContext.current.notify !== context.notify ||
            prevContext.current.backside !== context.backside ||
            prevContext.current.barcode !== context.barcode ||
            prevContext.current.background !== context.background ||
            prevContext.current.color !== context.color ||
            prevContext.current.labelColor !== context.labelColor) {
            onChange === null || onChange === void 0 ? void 0 : onChange(context);
            prevContext.current = context;
        }
    }, [context.readyToSave]);
    return null;
};
