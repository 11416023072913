var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useToggleBlockGiftCardInstanceMutation } from '@/api/query/giftCard';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useToggleBlock = (card) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [toggleBlockGiftCardInstance] = useToggleBlockGiftCardInstanceMutation();
    return (instance) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const isBlocked = instance.status === 'blocked';
        const name = (_a = card === null || card === void 0 ? void 0 : card.name) === null || _a === void 0 ? void 0 : _a.trim();
        const n = instance.number;
        const confirmTitle = isBlocked
            ? name
                ? t('Разблокировать подарочную карту {{name}} №{{n}}?', { name, n })
                : t('Разблокировать подарочную карту №{{n}}?', { n })
            : name
                ? t('Заблокировать подарочную карту {{name}} №{{n}}?', { name, n })
                : t('Заблокировать подарочную карту №{{n}}?', { n });
        const confirmAgreeLabel = isBlocked
            ? t('Разблокировать карту')
            : t('Заблокировать карту');
        const successMessage = isBlocked
            ? t('Карта успешно разблокирована')
            : t('Карта успешно заблокирована');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield toggleBlockGiftCardInstance(instance.id);
        if ('error' in result)
            addToast({
                type: 'error',
                message: getErrorMessage(result.error),
            });
        else
            addToast({
                type: 'success',
                message: successMessage,
            });
    });
};
