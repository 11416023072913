import { useGetBillingStatsQuery } from '@/api/query/billing';
import { useAppSelector } from '@/hooks/store';
import { appPeriodSelector, formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useStats = () => {
    const appPeriod = useAppSelector(appPeriodSelector);
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const rangeStart = appPeriod.start.format('DD.MM.YYYY');
    const rangeEnd = appPeriod.end.format('DD.MM.YYYY');
    const range = ` ${rangeStart} — ${rangeEnd}`;
    const { data: stats, isFetching } = useGetBillingStatsQuery({ periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    return {
        stats,
        isLoading: isFetching,
        range,
    };
};
