import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { FormSegmentPopup } from '@/forms/FormSegment/FormSegmentPopup';
import { CloseCircle } from '@/icons/CloseCircle';
import { Segment } from '@/icons/filters/Segment';
import { Filters } from './Filters';
import { FiltersSegment } from './FiltersSegment';
import styles from './styles.module.scss';
export const FiltersPanel = memo(({ filtersTree, value, onUpdate, plusButtonTheme, hasResetButton = false, onResetFilters, hasHangingOperator, segment, isSegmentFiltersTouched = false, onSaveSegment, onCreateSegment, isCreatingSegment = false, isSettingSegment = false, user, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasSegmentManage = userHasAccessTo('CLIENTS_SEGMENTS_MANAGE');
    const segmentConfiguration = segment === null || segment === void 0 ? void 0 : segment.configuration.map((i) => `${i.id || i.code}-${i.text}`);
    const unsaved = value.filter((i) => !(segmentConfiguration === null || segmentConfiguration === void 0 ? void 0 : segmentConfiguration.includes(`${i.id || i.code}-${i.text}`)));
    const draftKeys = (segment === null || segment === void 0 ? void 0 : segment.id) ? unsaved.map((i) => i.id || i.code) : [];
    const resetButton = (_jsx(Tooltip, Object.assign({ title: t('Сбросить фильтры'), placement: 'top' }, { children: _jsx("button", Object.assign({ className: styles.resetButton, onClick: onResetFilters }, { children: _jsx(CloseCircle, {}) })) })));
    const createButton = !!onCreateSegment && (_jsx(FormSegmentPopup, { trigger: _jsx("div", { children: _jsx(Tooltip, Object.assign({ title: !userHasSegmentManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ disabled: !userHasSegmentManage, icon: _jsx(Segment, {}) }, { children: t('Создать сегмент') })) })) }), onSubmit: onCreateSegment, isSubmitting: isCreatingSegment }));
    const saveButton = isSegmentFiltersTouched && onSaveSegment ? (_jsx(Tooltip, Object.assign({ title: !userHasSegmentManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ disabled: !userHasSegmentManage, onClick: () => onSaveSegment() }, { children: t('Сохранить изменения') })) }))) : null;
    const actionButton = (segment === null || segment === void 0 ? void 0 : segment.id) ? saveButton : createButton;
    return (_jsx(Filters, { segment: segment && onSaveSegment ? (_jsxs(_Fragment, { children: [_jsx(FormSegmentPopup, { trigger: _jsx(FiltersSegment, { segment: segment }), defaultValues: segment, onSubmit: (values) => onSaveSegment(values), isSubmitting: isSettingSegment, isEdit: true }), _jsx("span", Object.assign({ className: styles.eq }, { children: "=" }))] })) : undefined, filtersTree: filtersTree, value: value, draftKeys: draftKeys, onUpdate: onUpdate, actions: _jsxs(_Fragment, { children: [hasResetButton && actionButton, hasResetButton && resetButton] }), buttonTheme: plusButtonTheme, hasHangingOperator: hasHangingOperator }));
});
