import { useCallback, useRef, useState } from 'react';
const DEFAULT_OPTIONS = { threshold: 1.0 };
export const useOnIntersection = ({ isFetching, hasMoreToFetch, onIntersection, options = DEFAULT_OPTIONS, }) => {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useRef();
    const handleIntersection = useCallback((elementNode) => {
        var _a;
        if (isFetching)
            return;
        // Нужно в том случае если нужно переназначать элемент
        if (observer.current)
            observer.current.disconnect();
        if (!hasMoreToFetch) {
            if (elementNode)
                (_a = observer.current) === null || _a === void 0 ? void 0 : _a.unobserve(elementNode);
            setIsIntersecting(false);
            return;
        }
        observer.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsIntersecting(true);
                onIntersection();
            }
            else
                setIsIntersecting(false);
        }, options);
        if (elementNode)
            observer.current.observe(elementNode);
    }, [isFetching, onIntersection, hasMoreToFetch, options]);
    return { isIntersecting, handleIntersection };
};
