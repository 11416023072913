import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { sidebarIsCollapsedSelector, sidebarIsHiddenSelector, } from '@/store/selectors/sidebar';
import { hideSidebar as hideSidebarAction, toggleCollapseSidebar as toggleCollapseSidebarAction, toggleHiddenSidebar as toggleHiddenSidebarAction, } from '@/store/slice/sidebar';
export const useSidebarState = () => {
    const dispatch = useAppDispatch();
    const isCollapsed = useAppSelector(sidebarIsCollapsedSelector);
    const isHidden = useAppSelector(sidebarIsHiddenSelector);
    const hideSidebar = () => dispatch(hideSidebarAction());
    const toggleCollapseSidebar = () => dispatch(toggleCollapseSidebarAction());
    const toggleHiddenSidebar = () => dispatch(toggleHiddenSidebarAction());
    return {
        isCollapsed,
        isHidden,
        hideSidebar,
        toggleCollapseSidebar,
        toggleHiddenSidebar,
    };
};
