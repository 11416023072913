import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDashboardMetrics } from './useDashboardMetrics';
export const useDashboardMetricGroups = () => {
    const { t } = useTranslation();
    const metrics = useDashboardMetrics();
    return useMemo(() => [
        {
            id: 'marketing',
            label: t `Маркетинг`,
            items: [
                {
                    id: 'discounts-group',
                    label: t `Скидки`,
                    items: [metrics.discounts, metrics.discountsProgram],
                },
                metrics.roi,
                metrics.ltv,
                metrics.lifespan,
                metrics.repeatInterval,
                metrics.repeatRate,
                metrics.visitCount,
                metrics.arppu,
            ],
        },
        {
            id: 'financial-indicators',
            label: t `Фин. показатели`,
            items: [
                metrics.expensesFull,
                metrics.expensesPlatform,
                metrics.expensesMailing,
                {
                    id: 'revenue-group',
                    label: t `Выручка`,
                    items: [metrics.revenue, metrics.revenueProgram],
                },
                {
                    id: 'markup-group',
                    label: t `Наценка`,
                    items: [metrics.markup, metrics.markupProgram],
                },
            ],
        },
        {
            id: 'purchase',
            label: t `Чек`,
            items: [
                {
                    id: 'avgSale-group',
                    label: t `Средний чек`,
                    items: [metrics.avgSale, metrics.avgSaleProgram],
                },
                {
                    id: 'purchaseCount-group',
                    label: t `Кол-во чеков`,
                    items: [metrics.purchaseCount, metrics.purchaseCountProgram],
                },
                {
                    id: 'avgItemCount-group',
                    label: t `Позиций на чек`,
                    items: [metrics.avgItemCount, metrics.avgItemCountProgram],
                },
            ],
        },
        {
            id: 'cards',
            label: t `Карты лояльности`,
            items: [
                metrics.signupCount,
                metrics.engagementRate,
                {
                    id: 'bonus-group',
                    label: t `Бонусы`,
                    items: [metrics.bonusApplied, metrics.bonusCollected],
                },
            ],
        },
    ], [t]);
};
