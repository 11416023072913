import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/Switch/Switch';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const ChannelFormStatus = () => {
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const handleUpdateStatus = (checked) => {
        selectedNode.update({
            status: checked ? 'active' : 'pause',
        });
    };
    return (_jsxs("div", Object.assign({ className: styles.activeToggle }, { children: [_jsx("span", Object.assign({ className: styles.activeToggleLabel }, { children: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.status) === 'active'
                    ? t('Включена')
                    : t('Остановлена') })), _jsx(Switch, { checked: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.status) === 'active', onChange: handleUpdateStatus })] })));
};
