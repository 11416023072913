import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Promocode as PromocodeElement } from '@/components/Promocode/Promocode';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_SHARED } from '@/const/promocode';
import { Close } from '@/icons/Close';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const Promocode = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], currency, }) => {
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'promocode')
        return null;
    const selectedPromocode = promocodes.find((p) => p.id === selectedItem.promocodeId);
    if (!selectedPromocode)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.promocode }, { children: [_jsx("span", Object.assign({ ref: triggerRef }, { children: _jsx(PromocodeElement, { code: selectedPromocode.code, isPersonal: selectedPromocode.codeType !== PROMOCODE_TYPE_SHARED, onClick: togglePopup, isActive: popupIsOpen }) })), _jsx("button", Object.assign({ type: 'button', className: styles.close, onClick: handleReset }, { children: _jsx(Close, {}) }))] })), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: selectedPromocode.codeType === PROMOCODE_TYPE_FRIEND
                    ? 'friendPromocodes'
                    : 'promocodes', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
