import isEqual from 'lodash.isequal';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAppPeriod } from '@/hooks/useAppPeriod';
import { compileFilters } from '@/services/filters/compileFilters';
import { activeDashboardAnalyticsSelector, activeDashboardSelector, chartDataSelector, } from '@/store/selectors/reports';
import { FetchStatus } from '@/store/slice';
import { fetchChartData } from '@/store/thunk/reports';
export const useChartData = (interval) => {
    const dispatch = useAppDispatch();
    const { formattedStart, formattedEnd } = useAppPeriod();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const widget = useAppSelector(activeDashboardAnalyticsSelector);
    const { data, status } = useAppSelector(chartDataSelector, isEqual);
    const filters = (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.filters) || [];
    const metrics = widget === null || widget === void 0 ? void 0 : widget.configuration.metrics;
    const currentMetrics = metrics === null || metrics === void 0 ? void 0 : metrics.map((m) => m.key);
    const visibleMetrics = metrics === null || metrics === void 0 ? void 0 : metrics.filter((m) => m.showOnChart).map((m) => m.key);
    useEffect(() => {
        dispatch(fetchChartData({
            periodStart: formattedStart,
            periodEnd: formattedEnd,
            filters: compileFilters(filters),
            metrics: currentMetrics,
            bucketSize: interval,
        }));
    }, [
        formattedStart,
        formattedEnd,
        JSON.stringify(filters),
        JSON.stringify(currentMetrics),
        interval,
    ]);
    return { data, visibleMetrics, isLoading: status === FetchStatus.Loading };
};
