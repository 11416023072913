import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ViewportPortal } from '@xyflow/react';
import classNames from 'classnames';
import { Popup } from '@/components/Popup/Popup';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { useCreateNode } from '../../hooks/useCreateNode';
import { NewStepNodeSteps } from './NewStepNodeSteps';
import { NewStepNodeTriggerFilters } from './NewStepNodeTriggerFilters';
import styles from './styles.module.scss';
export const NewStepPopup = ({ isOpen = false, onClose, anchorEl = null, triggerFilters = [], isChannelTriggers = false, isEmailTriggers = false, steps = [], }) => {
    const { newStepPopup } = useInteractiveFlow();
    const createNode = useCreateNode();
    const handleSelectStep = (step) => {
        createNode(step);
        newStepPopup.close();
    };
    return (_jsx(ViewportPortal, { children: _jsx(Popup, Object.assign({ className: classNames(styles.popup, 'nodrap', 'nopan'), arrowClassName: styles.popupArrow, isOpen: isOpen, onClose: onClose, anchorEl: anchorEl, placement: 'right', offset: [0, 4], disablePortal: true }, { children: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.noborder) }, { children: [_jsx(NewStepNodeTriggerFilters, { filters: triggerFilters, onSelectFilter: handleSelectStep, isChannelTriggers: isChannelTriggers, isEmailTriggers: isEmailTriggers }), _jsx(NewStepNodeSteps, { filters: triggerFilters, steps: steps, onSelectStep: handleSelectStep })] })) })) }));
};
