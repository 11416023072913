import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getAutomaticMailingOptionsSelector, getCashierOptionsSelector, getClientLevelOptionsSelector, getExtraFieldOptionsSelector, getManualMailingOptionsSelector, getOfferOptionsSelector, getProductsOptionsSelector, getPromocodeFriendOptionsSelector, getPromocodeOptionsSelector, getRFMSegmentOptionsSelector, getSegmentOptionsSelector, getShopOptionsSelector, } from '@/store/selectors/filters';
import { getAutomaticMailingOptionsThunk, getBrandExtraFieldOptionsThunk, getCashierOptionsThunk, getClientLevelOptionsThunk, getManualMailingOptionsThunk, getOfferOptionsThunk, getProductsBranchThunk, getProductsTreeThunk, getPromocodeFriendOptionsThunk, getPromocodeOptionsThunk, getRFMSegmentOptionsThunk, getSegmentOptionsThunk, getShopOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { buildExtraFields } from '../buildExtraFields';
export const useAnalyticsFilters = (extraFields = []) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand, currency } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const shops = useAppSelector(getShopOptionsSelector);
    const clientLevels = useAppSelector(getClientLevelOptionsSelector);
    const manualMailings = useAppSelector(getManualMailingOptionsSelector);
    const automaticMailings = useAppSelector(getAutomaticMailingOptionsSelector);
    const offers = useAppSelector(getOfferOptionsSelector);
    const promocodes = useAppSelector(getPromocodeOptionsSelector);
    const friendPromocodes = useAppSelector(getPromocodeFriendOptionsSelector);
    const cashiers = useAppSelector(getCashierOptionsSelector);
    const products = useAppSelector(getProductsOptionsSelector);
    const extraFieldOptions = useAppSelector(getExtraFieldOptionsSelector);
    const segments = useAppSelector(getSegmentOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const loadExtraFieldOptions = (fieldName) => dispatch(getBrandExtraFieldOptionsThunk({ fieldName }));
    const tree = [
        {
            type: 'branch',
            id: 'segments',
            label: t('Сегменты'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientSegments',
                    label: t('REGULAR_SEGMENTS'),
                    filterTitle: t('Сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getSegmentOptionsThunk()),
                    options: segments,
                    useBothOperations: true,
                },
                {
                    type: 'leaf',
                    id: 'clientRfmSegments',
                    label: 'RFM',
                    filterTitle: t('RFM сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                    options: rfmSegments,
                    useBothOperations: true,
                    locked: !isRFMEnabled,
                },
            ],
        },
        {
            type: 'branch',
            id: 'clients',
            label: t('Клиенты'),
            items: [
                {
                    type: 'leaf',
                    id: 'programMember',
                    label: t('Участник программы'),
                    template: 'radios',
                    options: {
                        0: t('Нет'),
                        1: t('Да, участник'),
                    },
                },
                {
                    type: 'leaf',
                    id: 'loyaltyJoinedDaysAgo',
                    label: t('Дней в программе'),
                    template: 'numRange',
                    inputPostfix: t('дней'),
                },
                {
                    type: 'leaf',
                    id: 'clientIssuerShop',
                    label: t('Место вступления'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getShopOptionsThunk()),
                    options: shops,
                    hasUndefinedOption: true,
                    undefinedOptionValue: '0',
                },
                {
                    type: 'leaf',
                    id: 'clientLevel',
                    label: t('Уровень'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getClientLevelOptionsThunk()),
                    options: clientLevels,
                },
                {
                    type: 'leaf',
                    id: 'clientGender',
                    label: t('Пол'),
                    template: 'checkboxes-simple',
                    options: {
                        0: t('Не задан'),
                        1: t('Мужской'),
                        2: t('Женский'),
                    },
                },
                {
                    type: 'leaf',
                    id: 'clientAge',
                    label: t('Возраст'),
                    template: 'numRange',
                    constraints: {
                        minValue: 0,
                        maxValue: 120,
                    },
                    inputPostfix: t('лет'),
                },
                {
                    type: 'branch',
                    id: 'extraFields',
                    label: t('Дополнительные поля'),
                    items: buildExtraFields(extraFields, extraFieldOptions, loadExtraFieldOptions),
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchaseSources',
            label: t('Драйверы покупки'),
            items: [
                {
                    type: 'branch',
                    id: 'mailing',
                    label: t('Рассылки'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseMailingManual',
                            label: t('Ручные'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getManualMailingOptionsThunk()),
                            options: manualMailings,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseMailingAuto',
                            label: t('Автоматические'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getAutomaticMailingOptionsThunk()),
                            options: automaticMailings,
                        },
                    ],
                },
                {
                    type: 'leaf',
                    id: 'purchaseOffer',
                    label: t('Акции'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getOfferOptionsThunk()),
                    options: offers,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeShared',
                    label: t('Промокоды'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeOptionsThunk()),
                    options: promocodes,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeFriend',
                    label: t('Приведи друга'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeFriendOptionsThunk()),
                    options: friendPromocodes,
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchases',
            label: t('Покупки'),
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseAmount',
                    label: t('Сумма'),
                    template: 'numRange',
                    constraints: {
                        minValue: 0,
                    },
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'branch',
                    id: 'purchaseWhere',
                    label: t('Где продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseShop',
                            label: t('Место продажи'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseCashier',
                            label: t('Продавец'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'purchaseWhat',
                    label: t('Что продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseItem',
                            label: t('Товар'),
                            template: 'checkboxes-tree-loader',
                            loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                            loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                            options: products,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseItemSku',
                            label: t('Артикул'),
                            template: 'textField',
                        },
                    ],
                },
                {
                    type: 'leaf',
                    id: 'returnPurchase',
                    label: t('Сделан возврат'),
                    template: 'checkboxes-simple',
                    options: {
                        1: t('Да'),
                    },
                },
            ],
        },
    ];
    return tree;
};
