import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Arrow } from '@/icons/Arrow';
import styles from './styles.module.scss';
export const StepButtons = ({ hasNextButton, onGoNext, hasPrevButton, onGoPrev, doneButton, }) => {
    const { t } = useTranslation();
    const nextButton = (_jsxs(Button, Object.assign({ className: styles.stepNext, type: 'button', onClick: onGoNext }, { children: [t('Далее'), _jsx(Arrow, {})] })));
    const prevButton = (_jsxs(Button, Object.assign({ className: styles.stepPrev, type: 'button', onClick: onGoPrev }, { children: [_jsx(Arrow, {}), t('Назад')] })));
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [hasPrevButton && prevButton, hasNextButton && nextButton, doneButton] })));
};
