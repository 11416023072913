import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { GoogleWalletCardBackBanner } from './GoogleWalletCardBackBanner';
import { GoogleWalletCardContacts } from './GoogleWalletCardContacts';
import { GoogleWalletCardDetailsInfo } from './GoogleWalletCardDetailsInfo';
import styles from './styles.module.scss';
export const GoogleWalletCardDetails = ({ imageUrl = '', items = [], contacts = [], onElementClick, clicksDisabled = false, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.detailsTitle }, { children: t('Сведения') })), _jsx(GoogleWalletCardBackBanner, { imageUrl: imageUrl, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsx(GoogleWalletCardDetailsInfo, { items: items, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsx(GoogleWalletCardContacts, { contacts: contacts, onElementClick: onElementClick, clicksDisabled: clicksDisabled })] }));
};
