import { api } from './';
export const brandApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBrand: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/get',
            }),
            providesTags: ['CurrentBrand'],
        }),
        getBrandProductCatalogs: build.query({
            query: () => '/brand/product-catalogs',
        }),
        getBrandLevels: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/levels',
            }),
            providesTags: ['Levels'],
        }),
        getBrandList: build.mutation({
            query: () => ({
                method: 'GET',
                url: '/brand/list',
            }),
        }),
        switchBrand: build.mutation({
            query: ({ globalKey }) => ({
                method: 'POST',
                url: `/brand/switch/${globalKey}`,
            }),
        }),
        searchCashiers: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/brand/cashiers',
                body,
            }),
        }),
    }),
});
export const { useGetBrandQuery, useGetBrandProductCatalogsQuery, useGetBrandLevelsQuery, useGetBrandListMutation, useSwitchBrandMutation, useSearchCashiersMutation, } = brandApi;
