import { jsx as _jsx } from "react/jsx-runtime";
import { WalletCardFormMenu } from '@/components/wallet-cards/WalletCardFormMenu/WalletCardFormMenu';
import { useWalletCardForm } from '../../context/useWalletCardForm';
export const Menu = () => {
    const { setFormSection, markReadyToSave, background, setBackground, color, setColor, labelColor, setLabelColor, activeCardType, setActiveCardType, setAppleCardIsFlipped, setGoogleCardIsFlipped, } = useWalletCardForm();
    const handleChangeColor = (type) => (color) => {
        if (activeCardType === 'notify')
            setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setGoogleCardIsFlipped(false);
        if (type === 'color')
            setColor(color);
        else if (type === 'label')
            setLabelColor(color);
        else
            setBackground(color);
    };
    return (_jsx(WalletCardFormMenu, { onSelectSection: setFormSection, background: background, onChangeBackground: handleChangeColor('background'), onBlurBackgroundInput: markReadyToSave, color: color, onChangeColor: handleChangeColor('color'), onBlurColorInput: markReadyToSave, labelColor: labelColor, onChangeLabelColor: handleChangeColor('label'), onBlurLabelColorInput: markReadyToSave }));
};
