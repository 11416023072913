import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
const dataCheck = (currency, value) => (value || value === 0) &&
    money({
        input: value,
        withFraction: true,
        currency,
        removeFractionZeros: true,
    });
export const TotalRowStructure = ({ totalDiscount, currency, totalAmount, bonusesCollected, paidAmount, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.totalRow }, { children: [_jsx("div", Object.assign({ className: styles.total }, { children: t('Итого') })), _jsx("div", Object.assign({ className: styles.amont }, { children: dataCheck(currency, totalAmount) })), _jsx("div", Object.assign({ className: styles.discountAmount }, { children: dataCheck(currency, totalDiscount) })), _jsx("div", Object.assign({ className: styles.totalAmount }, { children: dataCheck(currency, paidAmount) })), _jsx("div", Object.assign({ className: styles.bonusesCollected }, { children: bonusesCollected }))] })));
};
