import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isQuoteNode } from '@lexical/rich-text';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_EDITOR, SELECTION_CHANGE_COMMAND, } from 'lexical';
import { useEffect, useRef, useState } from 'react';
import { EditorToolbar as EditorToolbarComponent } from '@/components/rich-text/EditorToolbar/EditorToolbar';
import { EmojiPopup } from '../../plugins/EmojiPlugin/EmojiPopup';
import { VarsPopup } from '../../plugins/VariablePlugin/VarsPopup';
import { LinkPopup } from '../plugins/LinkPlugin/LinkPopup';
import { OptionsPopup } from './OptionsPopup';
export const Toolbar = ({ isLink, isCreateEmptyLink, variables, varsPopupType, hasSmallVarsPopupIcon = false, hasEmojiSupport, isOpenLinkPopup, handleCloseLinkPopup, isRounded, currency, linkTitle, linkHref, onChangeLinkData, handleCreateLink, onOpenLinkPopup, handleDeleteLink, }) => {
    const [editor] = useLexicalComposerContext();
    const selectFormatsMap = useRef(new Set());
    const [selectFormats, setSelectFormats] = useState(selectFormatsMap.current);
    const [isQuote, setIsQuote] = useState(false);
    const onCloseOptionsPopup = () => {
        selectFormatsMap.current = new Set();
        setSelectFormats(selectFormatsMap.current);
    };
    const formatSelectNodes = () => {
        editor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection))
                return onCloseOptionsPopup();
            const nodes = selection.getNodes();
            selectFormatsMap.current = new Set();
            nodes.forEach((node) => {
                var _a, _b, _c, _d, _e;
                ((_a = node.hasFormat) === null || _a === void 0 ? void 0 : _a.call(node, 'bold')) && selectFormatsMap.current.add('bold');
                ((_b = node.hasFormat) === null || _b === void 0 ? void 0 : _b.call(node, 'italic')) && selectFormatsMap.current.add('italic');
                ((_c = node.hasFormat) === null || _c === void 0 ? void 0 : _c.call(node, 'underline')) &&
                    selectFormatsMap.current.add('underline');
                ((_d = node.hasFormat) === null || _d === void 0 ? void 0 : _d.call(node, 'strikethrough')) &&
                    selectFormatsMap.current.add('strikethrough');
                ((_e = node.hasFormat) === null || _e === void 0 ? void 0 : _e.call(node, 'code')) && selectFormatsMap.current.add('code');
            });
            const firstNodeParents = nodes[0].getParents();
            setIsQuote(firstNodeParents === null || firstNodeParents === void 0 ? void 0 : firstNodeParents.some($isQuoteNode));
            setSelectFormats(new Set(selectFormatsMap.current));
        });
    };
    const getFormatsFromSelection = (type) => {
        selectFormatsMap.current.has(type)
            ? selectFormatsMap.current.delete(type)
            : selectFormatsMap.current.add(type);
        formatSelectNodes();
    };
    useEffect(() => {
        editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            formatSelectNodes();
            return true;
        }, COMMAND_PRIORITY_EDITOR);
    }, [editor]);
    return (_jsx(EditorToolbarComponent, { emojiPopup: hasEmojiSupport ? _jsx(EmojiPopup, {}) : undefined, varsPopup: _jsx(VarsPopup, { type: varsPopupType, variables: variables, currency: currency }), hasSmallVarsPopupIcon: hasSmallVarsPopupIcon, linkPopup: _jsx(LinkPopup, { isCreateEmptyLink: isCreateEmptyLink, handleDeleteLink: handleDeleteLink, handleCreateLink: handleCreateLink, onChangeLinkData: onChangeLinkData, onClose: handleCloseLinkPopup, linkHref: linkHref, linkTitle: linkTitle }), optionsPopup: _jsx(OptionsPopup, { setIsQuote: setIsQuote, getFormatsFromSelection: getFormatsFromSelection, isBold: selectFormats.has('bold'), isItalic: selectFormats.has('italic'), isSpoiler: selectFormats.has('code'), isUnderline: selectFormats.has('underline'), isStrikethrough: selectFormats.has('strikethrough'), isQuote: isQuote }), linkActive: isLink, isOpenLinkPopup: isOpenLinkPopup, onOpenLinkPopup: onOpenLinkPopup, onCloseLinkPopup: handleCloseLinkPopup, onCloseOptionsPopup: onCloseOptionsPopup, formatSelectNodes: formatSelectNodes, isRounded: isRounded }));
};
