import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { Container } from '@/components/Container/Container';
import { ImportButton } from '@/components/ImportsTable/ImportButton';
import { ImportsTable } from '@/components/ImportsTable/ImportsTable';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import styles from './styles.module.scss';
export const ImportsPanel = memo(({ items, isLoading, isFetching, onImport, onEdit, onDelete }) => {
    return (_jsx(Container, { children: _jsx(TabsPanel, Object.assign({ headerClassName: styles.header, rightContent: _jsx(ImportButton, { onSelectImportType: onImport }), isSkeleton: isLoading }, { children: _jsx(ImportsTable, { items: items, isLoading: isFetching, onEdit: onEdit, onDelete: onDelete }) })) }));
});
