import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Info } from '@/icons/Info';
import styles from './styles.module.scss';
export const FormMailingWarningFlow = ({ flow, labelWidth, }) => {
    const { t } = useTranslation();
    if (!flow)
        return null;
    return (_jsx("div", Object.assign({ className: classNames(styles.info, styles.warning) }, { children: _jsx(FormFieldRow, Object.assign({ labelClassName: styles.warningLabel, label: _jsx("span", Object.assign({ className: styles.warningIconWrapper }, { children: _jsx(Info, {}) })), labelWidth: labelWidth, controlClassName: styles.infoControl, alignItems: 'center' }, { children: _jsxs("div", Object.assign({ className: styles.warningListItem }, { children: [t('Чтобы изменить триггер, рассылку нужно открепить от цепочки.'), ' ', t('Остальные изменения будут отражены в цепочке'), ' ', _jsx("span", Object.assign({ className: 'bold' }, { children: flow.title }))] })) })) })));
};
