import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { generatePassword } from '@/utils/generatePassword';
import { numberRound } from '@/utils/numbers';
export const FormUsersPassword = ({ isEdit = false, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) && !isEdit ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormFieldRow, Object.assign({ controlSize: '250', help: (_a = errors.password) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.password }, { children: _jsx(Controller, { name: 'password', control: control, rules: {
                maxLength: {
                    value: 200,
                    message: t('FIELD_MAX_LENGTH_ERROR', {
                        amount: numberRound(200),
                        count: 200,
                    }),
                },
                required: {
                    value: !isEdit,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                validate: validateTrimValue,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'password', placeholder: isEdit ? '********' : t('введите пароль'), rightButton: _jsx(Button, Object.assign({ isOutlined: true, onClick: () => {
                        setValue('password', generatePassword(), {
                            shouldValidate: isSubmitted,
                        });
                    }, type: 'button' }, { children: t('сгенерировать') })), hasError: !!errors.password }))) }) })));
};
