import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { Clock } from '@/icons/flow/Clock';
import { NodeDeleteButton } from '../../helpers/NodeDeleteButton/NodeDeleteButton';
import styles from './styles.module.scss';
import { useDelayContent } from './useDelayContent';
export const DelayNode = ({ id, data, }) => {
    const { offsetSign = 0, offsetUnit = 'day', offsetValue = 1, plusButton: PlusButton, onClick, onDelete, canBeDeleted, } = data;
    const delayContent = useDelayContent(offsetSign, offsetUnit, offsetValue);
    const handleClick = useCallback(() => {
        onClick === null || onClick === void 0 ? void 0 : onClick(id);
    }, [onClick, id]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                    [styles.interactive]: !!onClick,
                    nodrag: !!onClick,
                    nopan: !!onClick,
                }), onClick: handleClick }, { children: [_jsx(Clock, {}), _jsx("span", Object.assign({ className: styles.content }, { children: delayContent }))] })), PlusButton && _jsx(PlusButton, { nodeId: id }), onDelete && (canBeDeleted === null || canBeDeleted === void 0 ? void 0 : canBeDeleted(id)) && (_jsx(NodeDeleteButton, { onClick: () => onDelete(id) })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
