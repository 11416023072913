import { t } from 'i18next';
import styles from '../styles.module.scss';
export const createHeadItems = () => [
    {
        id: 'date',
        title: t('Дата'),
        align: 'left',
        width: 80,
        className: styles.date,
    },
    {
        id: 'return-place',
        title: t('Место возврата'),
        align: 'left',
        width: 250,
        className: styles.productTitle,
    },
    {
        id: 'cashier',
        title: t('Кассир'),
        align: 'left',
        width: 110,
    },
    {
        id: 'refund-amount',
        title: t('Сумма возврата'),
        align: 'right',
        width: 140,
        className: styles.cellHeadPadRight24,
    },
    {
        id: 'minus-bonus',
        title: `- ${t('Бонусы')}`,
        align: 'right',
        width: 113,
        tooltip: 'Списание с карты бонусов, начисленных при покупке',
        className: styles.bonusHeadCell,
    },
    {
        id: 'plus-bonus',
        title: `+ ${t('Бонусы')}`,
        align: 'right',
        width: 113,
        tooltip: 'Возврат на карту бонусов, списанных при покупке',
        className: styles.bonusHeadCell,
    },
];
