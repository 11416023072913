var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteSegmentClientsMutation } from '@/api/query/segment';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { numberRound } from '@/utils/numbers';
export const useDeleteSegmentClients = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteSegmentClients] = useDeleteSegmentClientsMutation();
    return (segment) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmTitle = t('SEGMENT_CLIENTS_DELETE', {
            amount: numberRound(segment.clients),
            count: segment.clients,
            segmentName: segment.name,
        });
        const confirmText = t('Это действие необратимо.');
        const confirmAgreeLabel = t('Удалить клиентов');
        const successMessage = t('Клиенты удалены');
        const yes = yield confirm({
            confirmation: confirmTitle,
            text: confirmText,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deleteSegmentClients(segment.id);
        if ('error' in result)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else
            addToast({ type: 'success', message: successMessage });
    });
};
