const digitSpace = ' '; // <-- here is U+0202F (Narrow No-break Space)
export function numberRound(input, precision) {
    const formatted = Number(input).toFixed(precision || 0);
    return formatted.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + digitSpace);
}
export function largeNumberRound(input, options = {}) {
    let suffix = '';
    let rounded;
    const value = typeof input === 'number' ? input : input.value;
    const million = typeof input === 'number' ? 'M' : input.million || 'M';
    const thousand = typeof input === 'number' ? 'K' : input.thousand || 'K';
    const currency = typeof input === 'number' ? '' : input.currency;
    const millionThreshold = options.millionThreshold || 1000000;
    const thousandThreshold = options.thousandThreshold || 100000;
    if (value >= millionThreshold) {
        suffix = million;
        rounded = Math.round(value / 100000) / 10;
    }
    else if (value >= thousandThreshold) {
        suffix = thousand;
        rounded = Math.round(value / 100) / 10;
    }
    else
        rounded = Math.round(value);
    const formatted = rounded
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + digitSpace);
    const result = suffix ? `${formatted}${digitSpace}${suffix}` : formatted;
    return currency ? result + digitSpace + currency : result;
}
export function money({ input, withFraction = false, removeFractionZeros = true, currency, }) {
    let inputRounded = Math.round(Number(input) * 100) / 100;
    if (isNaN(inputRounded))
        return;
    inputRounded = withFraction
        ? removeFractionZeros
            ? inputRounded.toFixed(2).replace('.00', '')
            : inputRounded.toFixed(2)
        : Math.round(inputRounded);
    inputRounded = inputRounded.toString();
    const result = inputRounded.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + digitSpace);
    return currency ? result + digitSpace + currency : result;
}
export function isInt(input) {
    if (!input.trim())
        return true;
    return /^-?\d+$/.test(input);
}
export function isNumeric(input) {
    return !isNaN(+input);
}
export function isInRange(input, { minValue, maxValue, } = {}) {
    if (!input.trim())
        return '';
    if (typeof minValue !== 'undefined' && Number(input) < minValue)
        return 'Не менее {{min}}';
    if (typeof maxValue !== 'undefined' && Number(input) > maxValue)
        return 'Не более {{max}}';
    return '';
}
export function isGreater(a, b) {
    if (!a.trim() || !b.trim())
        return false;
    return Number(a) > Number(b);
}
export function formatOffset(offset) {
    if (!offset)
        return '';
    const sign = offset > 0 ? '+' : '-';
    const str = offset.toString().replace('-', '');
    return `(UTC${sign}${str.length === 1 ? '0' : ''}${str})`;
}
