import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@/icons/Dialog';
import { Plus } from '@/icons/Plus';
import { Button } from '../Button2/Button';
import styles from './styles.module.scss';
export const ListEmpty = memo(({ onAdd }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(Dialog, {}), _jsx("span", Object.assign({ className: styles.title }, { children: t('Уведомления вблизи магазинов') })), _jsx("span", Object.assign({ className: styles.caption }, { children: t('Телефон с установленной картой покажет сообщение покупателю на основе геолокации. В Apple Wallet поддерживается не более 10 точек. В GPay геопуши в настоящее время не поддерживаются.') })), _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onAdd }, { children: t('Геопуш') }))] })));
});
