import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteFreeSolo } from '@/components/Autocomplete/variants/AutocompleteFreeSolo';
import { Input } from '@/components/Input/Input';
import { Promocode } from '@/components/Promocode/Promocode';
import { PROMOCODE_TYPE_SHARED } from '@/const/promocode';
import { Close } from '@/icons/Close';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const MailingRewardsPanels = memo(({ value, onChange, offers = [], promocodes = [], giftCards = [], currency, bonusesAmountId, hasBonusesAmountError, bonusesLifetimeId, hasBonusesLifetimeError, offerExpireInDaysName, hasOfferExpireInDaysError, }) => {
    const { t } = useTranslation();
    const rewardTypes = Object.keys(value);
    const deleteReward = (keys) => {
        const newValue = Object.assign({}, value);
        keys.forEach((key) => delete newValue[key]);
        onChange(newValue);
    };
    const handleChangeBonusesAmount = (bonusesAmount) => {
        onChange(Object.assign(Object.assign({}, value), { bonusesAmount }));
    };
    const handleChangeBonusesLifetime = (e) => {
        onChange(Object.assign(Object.assign({}, value), { bonusesLifetime: e.target.value }));
    };
    const deleteOffer = (offerId) => {
        var _a;
        const offers = value.offers || [];
        const newValue = Object.assign(Object.assign({}, value), { offers: offers.filter((i) => i.id !== offerId) });
        if (!((_a = newValue.offers) === null || _a === void 0 ? void 0 : _a.length))
            delete newValue.offers;
        onChange(newValue);
    };
    const handleChangeOfferDays = (offerId, expireInDays) => {
        const offers = value.offers || [];
        const newValue = Object.assign(Object.assign({}, value), { offers: offers.map((i) => i.id === offerId ? { id: offerId, expireInDays } : i) });
        onChange(newValue);
    };
    const numberInputProps = {
        type: 'number',
        inputMode: 'numeric',
        placeholder: '-',
        preventNegativeNumbers: true,
        preventChars: ['+'],
        shouldHideSpinButtons: true,
    };
    return (_jsx(_Fragment, { children: rewardTypes.map((rewardType) => {
            var _a;
            if (rewardType === 'bonusesAmount')
                return (_jsxs(MailingRewardsPanel, Object.assign({ onClose: () => {
                        deleteReward(['bonusesAmount', 'bonusesLifetime']);
                    } }, { children: [_jsx("div", Object.assign({ className: styles.panelBonusesInput }, { children: _jsx(AutocompleteFreeSolo, Object.assign({}, numberInputProps, { id: bonusesAmountId, value: value.bonusesAmount || '', onChange: handleChangeBonusesAmount, options: ['50', '100', '150', '200', '250'], hasError: hasBonusesAmountError })) })), _jsx("div", Object.assign({ className: styles.panelBonusesLabel }, { children: t('BONUSES_EXPIRING', {
                                count: Number(value.bonusesAmount) || 0,
                            }) })), _jsx("div", Object.assign({ className: styles.panelBonusesInput }, { children: _jsx(Input, Object.assign({}, numberInputProps, { id: bonusesLifetimeId, value: value.bonusesLifetime || '', onChange: handleChangeBonusesLifetime, hasError: hasBonusesLifetimeError })) })), _jsx("div", Object.assign({ className: styles.panelBonusesLabel }, { children: t('DAYS_EXPIRING', {
                                count: Number(value.bonusesLifetime) || 0,
                            }) }))] }), rewardType));
            if (rewardType === 'promocodeId') {
                const promocode = promocodes.find((i) => i.id === value.promocodeId);
                if (promocode)
                    return (_jsxs(MailingRewardsPanel, Object.assign({ onClose: () => deleteReward(['promocodeId']) }, { children: [_jsx("span", Object.assign({ className: styles.panelPromocode }, { children: t('Промокод') })), _jsx(Promocode, { code: promocode.code, isPersonal: promocode.codeType !== PROMOCODE_TYPE_SHARED, isDark: true })] }), rewardType));
            }
            if (rewardType === 'giftCardId') {
                const giftCard = giftCards.find((i) => i.id === value.giftCardId);
                if (giftCard)
                    return (_jsxs(MailingRewardsPanel, Object.assign({ onClose: () => deleteReward(['giftCardId']) }, { children: [t('Подарочная карта'), " \u00AB", giftCard.name, "\u00BB (", money({ input: giftCard.amount, currency }), ")"] }), rewardType));
            }
            if (rewardType === 'offers')
                return (_jsx(_Fragment, { children: (_a = value.offers) === null || _a === void 0 ? void 0 : _a.map((offerData, i) => {
                        const offer = offers.find((i) => i.id === offerData.id);
                        const hasError = hasOfferExpireInDaysError === null || hasOfferExpireInDaysError === void 0 ? void 0 : hasOfferExpireInDaysError(i);
                        if (offer)
                            return (_jsxs(MailingRewardsPanel, Object.assign({ onClose: () => deleteOffer(offer.id), isOffer: true }, { children: [_jsxs("div", { children: [t('Акция'), ' ', _jsxs("span", Object.assign({ className: styles.panelOfferTitle }, { children: ["\u00AB", offer.title, "\u00BB"] }))] }), _jsxs("div", Object.assign({ className: styles.panelOfferValid }, { children: [t('действует'), _jsx("div", Object.assign({ className: styles.panelOfferInput }, { children: _jsx(Input, Object.assign({}, numberInputProps, { value: offerData.expireInDays || '', onChange: (e) => handleChangeOfferDays(offer.id, e.target.value), name: hasError ? offerExpireInDaysName : '', hasError: hasError })) })), t('MAILING_VALID_DAYS', {
                                                count: Number(offerData.expireInDays) || 0,
                                            })] })), _jsx("div", Object.assign({ className: styles.panelOfferHelp }, { children: t('Используется основной период действия акции или указанное значение') }))] }), offer.id));
                    }) }));
            return null;
        }) }));
});
const MailingRewardsPanel = ({ onClose, children, isOffer = false, }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.panel, {
            [styles.panelOffer]: isOffer,
        }) }, { children: [_jsx("div", Object.assign({ className: styles.panelContent }, { children: children })), _jsx("button", Object.assign({ className: styles.panelClose, type: 'button', onClick: onClose }, { children: _jsx(Close, {}) }))] })));
};
