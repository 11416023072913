import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useMemo } from 'react';
import { Tree } from '@/components/Tree/Tree';
export const FiltersTreeView = memo(({ filtersTree, selectedCode, disabledCodes, hiddenCodes, onChange, scrollToSelectedNode = false, }) => {
    const selectedItemIds = useMemo(() => [selectedCode], [selectedCode]);
    const handleToggleItems = useCallback((codes, item) => {
        if (codes.length > 0)
            onChange(codes[codes.length - 1], item);
    }, [onChange]);
    return (_jsx(Tree, { items: filtersTree, selectedItemIds: selectedItemIds, disabledItemIds: disabledCodes, hiddenItemIds: hiddenCodes, onToggleItems: handleToggleItems, hasAutoExpand: true, levelIndent: 15, scrollToSelectedNode: scrollToSelectedNode }));
});
