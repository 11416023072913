import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { AccordionTable } from '@/components/AccordionTable/AccordionTable';
import { OffersTabPlaceholder } from './OffersTabPlaceholder';
import { createHeadItems } from './utils/createHeadItems';
import { tableRows } from './utils/tableRows';
export const OffersTab = memo(({ data, currency }) => {
    const headItems = createHeadItems();
    return (_jsx(AccordionTable, { headItems: headItems, rows: tableRows(currency, data), Placeholder: _jsx(OffersTabPlaceholder, {}) }));
});
