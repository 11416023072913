import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Tooltip } from '@/components/Tooltip/Tooltip';
// import { Download } from '@/icons/Download'
import { Plus } from '@/icons/Plus';
import styles from './styles.module.scss';
export const RightToolbar = ({ onToggleUserModal, onToggleRoleModal, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasOperatorsManage = userHasAccessTo([
        'OPERATORS_ADMIN_MANAGE',
        'OPERATORS_MANAGE',
    ]);
    return (_jsx(_Fragment, { children: _jsx(Tooltip, Object.assign({ title: !userHasOperatorsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx("div", { children: _jsx(Dropdown, Object.assign({ items: [
                        {
                            id: 'add-user',
                            label: t('Пользователя'),
                            onClick: onToggleUserModal,
                        },
                        {
                            id: 'add-role',
                            label: t('Роль'),
                            onClick: onToggleRoleModal,
                        },
                    ], placement: 'bottom-end', offset: [3, -10], className: styles.dropdownButton }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onToggleUserModal, theme: 'primary', size: 'extra-large', disabled: !userHasOperatorsManage }, { children: t('Добавить') })) })) }) })) }));
};
