var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import MuiDrawer from '@mui/material/Drawer';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
export const NodeFormDrawer = (_a) => {
    var { open, onClose, icon, title, titleRightElement, footer, children, isLarge = false, isWide = false } = _a, otherProps = __rest(_a, ["open", "onClose", "icon", "title", "titleRightElement", "footer", "children", "isLarge", "isWide"]);
    const [isOpen, setIsOpen] = useState(open);
    useEffect(() => {
        if (open)
            setIsOpen(true);
    }, [open]);
    const handleClose = (...args) => {
        setIsOpen(false);
        setTimeout(() => {
            onClose === null || onClose === void 0 ? void 0 : onClose(...args);
        }, 200);
    };
    return (_jsxs(MuiDrawer, Object.assign({ className: classNames(styles.drawer, {
            [styles.large]: isLarge,
            [styles.wide]: isWide,
        }), open: isOpen, onClose: handleClose, disablePortal: true }, otherProps, { children: [_jsx("div", Object.assign({ className: styles.header }, { children: _jsxs("div", Object.assign({ className: styles.headerContent }, { children: [_jsxs("div", Object.assign({ className: styles.titleContent }, { children: [icon, title] })), titleRightElement] })) })), _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx("div", Object.assign({ className: styles.content }, { children: children })), !!footer && _jsx("div", Object.assign({ className: styles.footer }, { children: footer }))] }))] })));
};
