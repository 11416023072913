import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    errors: [],
    ignore401: false,
};
const httpError = createSlice({
    name: 'httpError',
    initialState,
    reducers: {
        pushError: (state, action) => {
            const sameError = state.errors.find((error) => error.status === action.payload.status && !error.isNotified);
            if (!sameError)
                state.errors.push({
                    id: state.errors.length + 1,
                    status: action.payload.status,
                    isNotified: false,
                });
        },
        closeError: (state, action) => {
            const error = state.errors.find((e) => e.id === action.payload.id);
            if (error)
                error.isNotified = true;
        },
        ignore401: (state) => {
            state.ignore401 = true;
        },
        watch401: (state) => {
            state.ignore401 = false;
        },
    },
});
export const { pushError, closeError, ignore401, watch401 } = httpError.actions;
export default httpError.reducer;
