import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { ROUTES } from '../routes';
import { ExportsPanel } from './ExportsPanel';
import { useCreateExport } from './useCreateExport';
import { useExportList } from './useExportList';
export const Exports = () => {
    const navigate = useNavigate();
    const { user } = useCurrentUser();
    const [activePanel, setActivePanel] = useState('exports');
    const { exports, isLoading, isFetching } = useExportList();
    const handleCreateExport = useCreateExport({
        onSuccess: () => setActivePanel('exports'),
    });
    const userHasAccessTo = useUserPermissions();
    const userHasExportManage = userHasAccessTo('EXPORTS_MANAGE');
    if (!user)
        return null;
    return userHasExportManage ? (_jsx(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, {}) }, { children: _jsx(ExportsPanel, { activePanel: activePanel, onChangeActivePanel: setActivePanel, items: exports || [], onExport: handleCreateExport, onGoToClients: () => navigate(ROUTES.CLIENTS), isLoading: isLoading, isFetching: isFetching }) }))) : null;
};
