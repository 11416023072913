import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { ReportMetricModal } from '@/components/ReportMetricModal/ReportMetricModal';
import { useDashboardMetrics } from '@/hooks/useDashboardMetrics';
import { Close } from '@/icons/Close';
import { Plus } from '@/icons/Plus';
import styles from './styles.module.scss';
export const AnalyticsChartPills = memo(({ items, onChangeItems, disabledItems = [], onChangeDisabledItems }) => {
    const { t } = useTranslation();
    const allMetrics = useDashboardMetrics();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
    const handleCloseModal = useCallback(() => setModalIsOpen(false), []);
    const handleToggleItem = useCallback((item) => {
        if (!onChangeDisabledItems)
            return;
        if (disabledItems.includes(item))
            onChangeDisabledItems(disabledItems.filter((i) => i !== item), item);
        else
            onChangeDisabledItems([...disabledItems, item], item);
    }, [disabledItems, onChangeDisabledItems]);
    const handleRemoveItem = useCallback((item) => {
        onChangeItems(items.filter((i) => i !== item), 'remove');
    }, [items, onChangeItems]);
    const handleChangeItems = useCallback((newItems) => {
        onChangeItems(newItems, 'edit');
        handleCloseModal();
    }, [onChangeItems]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [items.map((item) => {
                        const metric = allMetrics[item];
                        return (_jsxs("div", Object.assign({ className: styles.item, style: {
                                backgroundColor: !disabledItems.includes(metric.id)
                                    ? metric.color
                                    : undefined,
                            }, onClick: () => handleToggleItem(metric.id) }, { children: [metric.label, _jsx("button", Object.assign({ className: styles.close, type: 'button', onClick: (e) => {
                                        e.stopPropagation();
                                        handleRemoveItem(metric.id);
                                    } }, { children: _jsx(Close, {}) }))] }), metric.id));
                    }), items.length > 0 && (_jsx(Button, { type: 'button', icon: _jsx(Plus, {}), onClick: handleOpenModal })), !items.length && (_jsx(Button, Object.assign({ icon: _jsx(Plus, {}), type: 'button', onClick: handleOpenModal }, { children: t `Метрики` })))] })), _jsx(ReportMetricModal, { isOpen: modalIsOpen, onClose: handleCloseModal, value: items, onApply: handleChangeItems })] }));
});
