import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useProductFilters } from '@/services/filters/data/useProductFilters';
import { APPLY_ON_SOME_UNITS } from '../constants';
export const FormOfferItemConditions = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, watch } = useFormContext();
    const { errors, isSubmitting, isSubmitted } = formState;
    const applyOn = watch('applyOn');
    const shouldDisplay = applyOn === APPLY_ON_SOME_UNITS;
    const filtersTree = useProductFilters();
    const validate = (value) => {
        if ((isSubmitting || isSubmitted) && (!value || !value.length))
            return 'error';
        return true;
    };
    if (shouldDisplay)
        return (_jsx(FormFieldRow, Object.assign({ label: t('Выберите товар'), labelWidth: labelWidth, help: errors.itemConditions
                ? t('Выберите хотя бы один критерий применения')
                : undefined, hasError: !!errors.itemConditions, isRequired: true }, { children: _jsx(Controller, { name: 'itemConditions', control: control, rules: { validate }, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: filtersTree, value: field.value || [], onUpdate: (value) => setValue('itemConditions', value, {
                        shouldValidate: true,
                    }), hasHangingOperator: false, buttonRef: field.ref })) }) })));
    return null;
};
