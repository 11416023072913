export const COLORS = [
    '#000000',
    '#828282',
    '#CCCCCC',
    '#DFDFDF',
    '#EEEEEF',
    '#778DA3',
    '#5E7389',
    '#49596A',
    '#EECDCD',
    '#F8E6D0',
    '#FDF2D0',
    '#DCE9D5',
    '#D3DFE2',
    '#D3E1F1',
    '#D8D2E7',
    '#E6D2DB',
    '#DE9D9B',
    '#F2CDA2',
    '#FBE6A3',
    '#BCD6AC',
    '#A9C3C8',
    '#A7C4E5',
    '#B2A7D2',
    '#CEA8BC',
    '#D16D6A',
    '#ECB576',
    '#F9DA78',
    '#9DC384',
    '#80A4AE',
    '#7CA6D7',
    '#8B7DBE',
    '#B87E9E',
    '#BB271A',
    '#DA954B',
    '#EAC451',
    '#78A65A',
    '#54808C',
    '#5083C1',
    '#634FA2',
    '#9B5277',
    '#8C1A11',
    '#A96324',
    '#B89230',
    '#48752C',
    '#264E5A',
    '#25528F',
    '#321D70',
    '#6B2246',
    '#5D0E07',
    '#704216',
    '#7A611D',
    '#314D1C',
    '#18333C',
    '#173660',
    '#1E134A',
    '#46162F',
];
export const DARK_INDEXES = [0];
