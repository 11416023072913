import { useEffect, useState } from 'react';
import { useGetImportQuery, useLoadImportPreviewMutation, } from '@/api/query/import';
export const useImportData = (importId) => {
    // Import Data ---------------------------------------------------------------
    const [importData, setImportData] = useState();
    const { data: _importData, isLoading: isLoadingImportData } = useGetImportQuery(importId);
    useEffect(() => {
        if (!isLoadingImportData && !importData)
            setImportData(_importData);
    }, [isLoadingImportData]);
    // Import Preview Data -------------------------------------------------------
    const [importPreviewData, setImportPreviewData] = useState();
    const [loadPreview, { isLoading: isFetchingImportPreviewData }] = useLoadImportPreviewMutation();
    const isLoadingImportPreviewData = !importPreviewData;
    const reloadPreview = (sheet, isInitial) => {
        loadPreview({ id: importId, sheet }).then((data) => {
            var _a, _b;
            if ('error' in data)
                return;
            const importPreviewData = data.data;
            setImportPreviewData(importPreviewData);
            const skipHeader = isInitial ? importData === null || importData === void 0 ? void 0 : importData.skipHeader : null;
            const columnMapping = isInitial ? importData === null || importData === void 0 ? void 0 : importData.columnMapping : [];
            if (importData)
                setImportData(Object.assign(Object.assign({}, importData), { sheet, skipHeader: skipHeader !== null && skipHeader !== void 0 ? skipHeader : (((_a = importPreviewData === null || importPreviewData === void 0 ? void 0 : importPreviewData.settings) === null || _a === void 0 ? void 0 : _a.skipHeader) || 1), columnMapping: !(columnMapping === null || columnMapping === void 0 ? void 0 : columnMapping.length)
                        ? ((_b = importPreviewData === null || importPreviewData === void 0 ? void 0 : importPreviewData.settings) === null || _b === void 0 ? void 0 : _b.columnMapping) || []
                        : columnMapping }));
        });
    };
    useEffect(() => {
        if (importData && !importPreviewData)
            reloadPreview(importData.sheet, true);
    }, [importData]);
    // Result --------------------------------------------------------------------
    return {
        importData,
        setImportData,
        importPreviewData,
        isLoading: isLoadingImportData || isLoadingImportPreviewData,
        isFetchingImportPreviewData,
        reloadPreview,
    };
};
