import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '@/hooks/store';
import { sidebarIsCollapsedSelector, sidebarIsHiddenSelector, } from '@/store/selectors/sidebar';
import { AppHeaderContainer } from '../AppHeader/AppHeaderContainer';
import { AppSidebarContainer } from '../AppSidebar/AppSidebarContainer';
import { TopAlertContainer } from '../TopAlert/TopAlertContainer';
import styles from './styles.module.scss';
export const Layout = () => {
    const sidebarIsCollapsed = useAppSelector(sidebarIsCollapsedSelector);
    const sidebarIsHidden = useAppSelector(sidebarIsHiddenSelector);
    const [update, setUpdate] = useState(false);
    // чтобы свойсто transition появлялось только после первой отрисовки
    useEffect(() => {
        setTimeout(() => setUpdate(true));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("main", Object.assign({ className: classNames(styles.main, {
                    [styles.collapsed]: sidebarIsCollapsed,
                    [styles.moved]: !sidebarIsHidden,
                }) }, { children: [_jsx(TopAlertContainer, {}), _jsx(AppHeaderContainer, {}), _jsxs("div", Object.assign({ className: styles.row }, { children: [_jsx(AppSidebarContainer, {}), _jsx("div", Object.assign({ className: classNames(styles.content, {
                                    [styles.default]: update,
                                }) }, { children: _jsx(Outlet, {}) }))] }))] })), _jsx("div", { id: 'modal-loading' })] }));
};
