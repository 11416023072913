import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Pagination } from '@/components/Pagination/Pagination';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { PromocodesTable } from './PromocodesTable';
export const PromocodesPanel = memo(({ activeCount, archivedCount, promocodes = [], total, promocodeLabel, codeTypeShared, codeTypePersonal, shouldDisplayProfit, currency, timeZone, isLoading, isFetching, createButtonLabel, activeTabId, purchaseUrl, onChangeActiveTab, onGenerateBatch, onDeletePromocode, onOpenPurchaseFilter, onGoToCreateForm, onGoToEditForm, count, limit, offset, onChangeOffset, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasPromocodesManage = userHasAccessTo('PROMOCODES_MANAGE');
    const createButton = (_jsx(Tooltip, Object.assign({ title: !userHasPromocodesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onGoToCreateForm, theme: 'primary', size: 'extra-large', disabled: !userHasPromocodesManage }, { children: t(createButtonLabel) })) })));
    const pagination = !!count && (_jsx(Pagination, { total: count, limit: limit, offset: offset, onChangeOffset: onChangeOffset }));
    const tabs = [
        {
            id: 'active',
            label: t('Действующие'),
            count: activeCount,
        },
    ];
    if (archivedCount)
        tabs.push({
            id: 'archived',
            label: t('Архивные'),
            count: archivedCount,
        });
    return (_jsx(TabsPanel, Object.assign({ tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: onChangeActiveTab, rightContent: createButton, footer: pagination, isSkeleton: isLoading }, { children: _jsx(PromocodesTable, { promocodes: promocodes, total: total, promocodeLabel: t(promocodeLabel), codeTypeShared: codeTypeShared, codeTypePersonal: codeTypePersonal, shouldDisplayProfit: shouldDisplayProfit, currency: currency, timeZone: timeZone, isLoading: isFetching, purchaseUrl: purchaseUrl, onGenerateBatch: onGenerateBatch, onDeletePromocode: onDeletePromocode, onOpenPurchaseFilter: onOpenPurchaseFilter, onGoToEditForm: onGoToEditForm }) })));
});
