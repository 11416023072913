import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WalletCardFormChangeHandler } from './WalletCardFormChangeHandler';
import { WalletCardFormContext, } from './WalletCardFormContext';
export const WalletCardFormProvider = ({ defaultValues, onChange, counters, uploadImage, currency, countryCode, children, }) => {
    var _a, _b, _c, _d, _e;
    const location = useLocation();
    const [activeCardType, setActiveCardType] = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.walletCardActiveType) || 'apple');
    const [formSection, setFormSection] = useState(null);
    const [googleCardIsFlipped, setGoogleCardIsFlipped] = useState(false);
    const [appleCardIsFlipped, setAppleCardIsFlipped] = useState(false);
    const [background, setBackground] = useState((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.background) || '');
    const [color, setColor] = useState((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.color) || '');
    const [labelColor, setLabelColor] = useState((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.labelColor) || '');
    const [appleHead, setAppleHead] = useState(Object.assign({ logo: null, title: '', logoRejectReason: '', field_1_Title: '', field_1_Value: '', field_2_Title: '', field_2_Value: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.appleHead));
    const [googleHead, setGoogleHead] = useState(Object.assign({ logo: null, logoRejectReason: '', title: '', programName: '', field_Main_Title: '', field_Main_Value: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.googleHead));
    const [bannerType, setBannerType] = useState((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bannerType)
        ? defaultValues.bannerType
        : ((_b = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.appleBanner) === null || _b === void 0 ? void 0 : _b.title) ||
            ((_c = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.appleBanner) === null || _c === void 0 ? void 0 : _c.description) ||
            ((_d = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.googleBanner) === null || _d === void 0 ? void 0 : _d.title) ||
            ((_e = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.googleBanner) === null || _e === void 0 ? void 0 : _e.description)
            ? 'with-text'
            : 'simple');
    const [bannerCounter, setBannerCounter] = useState(Object.assign({ offerId: null, tickImage: null, tickImageRejectReason: '', blankImage: null, blankImageRejectReason: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bannerCounter));
    const [appleBanner, setAppleBanner] = useState(Object.assign({ image: null, imageRejectReason: '', title: '', titleColor: '', titleSize: '', description: '', descriptionColor: '', descriptionSize: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.appleBanner));
    const [googleBanner, setGoogleBanner] = useState(Object.assign({ image: null, imageRejectReason: '', backsideImage: null, backsideImageRejectReason: '', title: '', titleColor: '', titleSize: '', description: '', descriptionColor: '', descriptionSize: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.googleBanner));
    const [appleFields, setAppleFields] = useState(Object.assign({ field_3_Title: '', field_3_Value: '', field_4_Title: '', field_4_Value: '', field_5_Title: '', field_5_Value: '', field_6_Title: '', field_6_Value: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.appleFields));
    const [googleFields, setGoogleFields] = useState(Object.assign({ field_1_Title: '', field_1_Value: '', field_2_Title: '', field_2_Value: '', field_3_Title: '', field_3_Value: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.googleFields));
    const [notify, setNotify] = useState(Object.assign({ logo: null, logoRejectReason: '', title: '' }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.notify));
    const [backside, setBackside] = useState(Object.assign({ appleTitle: '', contacts: [{ title: '', content: '' }], details: [{ title: '', content: '' }] }, defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.backside));
    const [barcode, setBarcode] = useState((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.barcode) || 'code128');
    const [readyToSave, setReadyToSave] = useState(false);
    const [isElementClick, setIsElementClick] = useState(false);
    const value = {
        levelName: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.levelName,
        activeCardType,
        setActiveCardType,
        formSection,
        setFormSection,
        googleCardIsFlipped,
        setGoogleCardIsFlipped,
        appleCardIsFlipped,
        setAppleCardIsFlipped,
        background,
        setBackground,
        color,
        setColor,
        labelColor,
        setLabelColor,
        appleHead,
        setAppleHead,
        googleHead,
        setGoogleHead,
        bannerType,
        setBannerType,
        bannerCounter,
        setBannerCounter,
        appleBanner,
        setAppleBanner,
        googleBanner,
        setGoogleBanner,
        appleFields,
        setAppleFields,
        googleFields,
        setGoogleFields,
        notify,
        setNotify,
        backside,
        setBackside,
        barcode,
        setBarcode,
        readyToSave,
        setReadyToSave,
        markReadyToSave: () => setReadyToSave(true),
        isElementClick,
        setIsElementClick,
        counters,
        uploadImage,
        currency,
        countryCode,
    };
    return (_jsxs(WalletCardFormContext.Provider, Object.assign({ value: value }, { children: [children, _jsx(WalletCardFormChangeHandler, { onChange: onChange })] })));
};
