import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useUserPermissions } from '@/app/useUserPermissions';
import { AccentLink } from '@/components/AccentText/AccentLink';
import { AccentText } from '@/components/AccentText/AccentText';
import { money } from '@/utils/numbers';
export const TableProfitRevenueCell = memo(({ shouldDisplayProfit, profit = 0, revenue = 0, currency, href, onClick, }) => {
    const userHasAccessTo = useUserPermissions();
    const total = shouldDisplayProfit ? profit : revenue;
    const totalFormatted = money({ input: total, currency });
    const hasProfitOrRevenue = total > 0;
    const canClick = onClick &&
        hasProfitOrRevenue &&
        userHasAccessTo(['CLIENTS_PURCHASES_LIST', 'CLIENTS_PURCHASES_MANAGE']);
    const handleClick = (e) => {
        e.stopPropagation();
        if (onClick) {
            e.preventDefault();
            onClick();
        }
    };
    return canClick ? (_jsx(AccentLink, Object.assign({ href: href, accent: 'success', onClick: handleClick }, { children: totalFormatted }))) : (_jsx(AccentText, Object.assign({ accent: hasProfitOrRevenue ? 'success' : 'default' }, { children: totalFormatted })));
});
