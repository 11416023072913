var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { api } from './';
export const operatorApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOperatorList: build.query({
            query: () => '/operator/list',
            providesTags: ['OperatorList'],
        }),
        deleteOperator: build.mutation({
            query({ id }) {
                return {
                    url: `/operator/delete/${id}`,
                    method: 'POST',
                };
            },
            invalidatesTags: ['OperatorList', 'RolesList'],
        }),
        addOperator: build.mutation({
            query(body) {
                return {
                    url: `/operator/add`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['OperatorList', 'RolesList'],
        }),
        updateOperator: build.mutation({
            query(body) {
                return {
                    url: `/operator/set`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['OperatorList', 'RolesList'],
        }),
        getOperatorRoleList: build.query({
            query: () => '/operator/roles/list',
            providesTags: ['RolesList'],
        }),
        addOperatorRole: build.mutation({
            query(body) {
                return {
                    url: `/operator/roles/add`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['RolesList'],
        }),
        updateOperatorRole: build.mutation({
            query(_a) {
                var { id } = _a, body = __rest(_a, ["id"]);
                return {
                    url: `/operator/roles/${id}/set`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['RolesList'],
        }),
        deleteOperatorRole: build.mutation({
            query({ id }) {
                return {
                    url: `/operator/roles/${id}/delete`,
                    method: 'POST',
                };
            },
            invalidatesTags: ['RolesList'],
        }),
        verify2fa: build.mutation({
            query(body) {
                return {
                    url: '/operator/2fa/verify',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: (_, error) => {
                return error ? [] : ['CurrentUser', 'CurrentBrand'];
            },
        }),
        resetPassword: build.mutation({
            query({ token, newPassword }) {
                return {
                    url: `/operator/password-reset/${token}`,
                    method: 'POST',
                    body: { newPassword },
                };
            },
        }),
    }),
});
export const { useGetOperatorListQuery, useDeleteOperatorMutation, useAddOperatorMutation, useUpdateOperatorMutation, useGetOperatorRoleListQuery, useAddOperatorRoleMutation, useUpdateOperatorRoleMutation, useDeleteOperatorRoleMutation, useVerify2faMutation, useResetPasswordMutation, } = operatorApi;
