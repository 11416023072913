import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getAutomaticMailingOptionsSelector, getCashierOptionsSelector, getCategoryOptionsSelector, getManualMailingOptionsSelector, getOfferOptionsSelector, getProductPaginatedOptionsSelector, getProductsOptionsSelector, getPromocodeFriendOptionsSelector, getPromocodeOptionsSelector, getRFMSegmentOptionsSelector, getSegmentOptionsSelector, getShopOptionsSelector, } from '@/store/selectors/filters';
import { getAutomaticMailingOptionsThunk, getCashierOptionsThunk, getCategoryOptionsThunk, getManualMailingOptionsThunk, getOfferOptionsThunk, getProductOptionsThunk, getProductsBranchThunk, getProductsTreeThunk, getPromocodeFriendOptionsThunk, getPromocodeOptionsThunk, getRFMSegmentOptionsThunk, getSegmentOptionsThunk, getShopOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
export const usePurchaseFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand, currency } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const shops = useAppSelector(getShopOptionsSelector);
    const cashiers = useAppSelector(getCashierOptionsSelector);
    const products = useAppSelector(getProductsOptionsSelector);
    const categories = useAppSelector(getCategoryOptionsSelector);
    const paginatedProducts = useAppSelector(getProductPaginatedOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const segments = useAppSelector(getSegmentOptionsSelector);
    const manualMailings = useAppSelector(getManualMailingOptionsSelector);
    const automaticMailings = useAppSelector(getAutomaticMailingOptionsSelector);
    const offers = useAppSelector(getOfferOptionsSelector);
    const promocodes = useAppSelector(getPromocodeOptionsSelector);
    const friendPromocodes = useAppSelector(getPromocodeFriendOptionsSelector);
    const tree = [
        {
            type: 'branch',
            id: 'segments',
            label: t('Сегменты'),
            items: [
                {
                    type: 'leaf',
                    id: 'clientSegments',
                    label: t('REGULAR_SEGMENTS'),
                    filterTitle: t('Сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getSegmentOptionsThunk()),
                    options: segments,
                    useBothOperations: true,
                },
                {
                    type: 'leaf',
                    id: 'clientRfmSegments',
                    label: 'RFM',
                    filterTitle: t('RFM сегменты'),
                    template: 'checkboxes-segments',
                    loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                    options: rfmSegments,
                    useBothOperations: true,
                    locked: !isRFMEnabled,
                },
            ],
        },
        {
            type: 'branch',
            id: 'clients',
            label: t('Клиенты'),
            items: [
                {
                    type: 'leaf',
                    id: 'loyaltyCard',
                    label: t('Поиск по номерам карт'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'clientPhone',
                    label: t('Поиск по телефонам'),
                    template: 'textField',
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchaseSources',
            label: t('Драйверы покупки'),
            items: [
                {
                    type: 'branch',
                    id: 'mailing',
                    label: t('Рассылки'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseMailingManual',
                            label: t('Ручные'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getManualMailingOptionsThunk()),
                            options: manualMailings,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseMailingAuto',
                            label: t('Автоматические'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getAutomaticMailingOptionsThunk()),
                            options: automaticMailings,
                        },
                    ],
                },
                {
                    type: 'leaf',
                    id: 'purchaseOffer',
                    label: t('Акции'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getOfferOptionsThunk()),
                    options: offers,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeShared',
                    label: t('Промокоды'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeOptionsThunk()),
                    options: promocodes,
                },
                {
                    type: 'leaf',
                    id: 'purchasePromocodeFriend',
                    label: t('Приведи друга'),
                    template: 'checkboxes-loader',
                    loadOptions: () => dispatch(getPromocodeFriendOptionsThunk()),
                    options: friendPromocodes,
                },
            ],
        },
        {
            type: 'branch',
            id: 'purchases',
            label: t('Покупки'),
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseAmount',
                    label: t('Сумма'),
                    template: 'numRange',
                    constraints: {
                        minValue: 0,
                    },
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'branch',
                    id: 'purchaseWhere',
                    label: t('Где продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseShop',
                            label: t('Место продажи'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseCashier',
                            label: t('Продавец'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'purchaseWhat',
                    label: t('Что продано'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'purchaseItem',
                            label: t('Товар'),
                            template: 'checkboxes-tree-loader',
                            loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                            loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                            options: products,
                        },
                        {
                            type: 'leaf',
                            id: 'purchaseItemSku',
                            label: t('Артикул'),
                            template: 'textField',
                        },
                    ],
                },
            ],
        },
        {
            type: 'branch',
            id: 'returns',
            label: t('Возвраты'),
            items: [
                {
                    type: 'leaf',
                    id: 'returnPurchase',
                    label: t('Сделан возврат'),
                    template: 'checkboxes-simple',
                    options: {
                        1: t('Да'),
                    },
                },
                {
                    type: 'branch',
                    id: 'place-of-return',
                    label: t('Где возвращено'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnShop',
                            label: t('Место возврата'),
                            template: 'checkboxes-loader',
                            loadOptions: () => dispatch(getShopOptionsThunk()),
                            options: shops,
                        },
                        {
                            type: 'leaf',
                            id: 'returnCashier',
                            label: t('Кто принял'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                            options: cashiers,
                        },
                    ],
                },
                {
                    type: 'branch',
                    id: 'return-what',
                    label: t('Что возвращено'),
                    items: [
                        {
                            type: 'leaf',
                            id: 'returnItemCategory',
                            label: t('Категория'),
                            filterTitle: t('Категория в-та'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getCategoryOptionsThunk(config)),
                            options: categories,
                        },
                        {
                            type: 'leaf',
                            id: 'returnItemSku',
                            label: t('Артикул'),
                            filterTitle: t('Артикул в-та'),
                            template: 'textField',
                        },
                        {
                            type: 'leaf',
                            id: 'returnItemSku2',
                            label: t('Наименование'),
                            filterTitle: t('Наименование в-та'),
                            template: 'checkboxes-paginated-loader',
                            loadOptions: (config) => dispatch(getProductOptionsThunk(config)),
                            options: paginatedProducts,
                        },
                    ],
                },
            ],
        },
    ];
    return tree;
};
