import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { largeNumberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const PointsStatusPanelFooter = memo(({ data }) => {
    const { t } = useTranslation();
    return (_jsx("div", Object.assign({ className: styles.footer }, { children: data.map(({ color, label, tooltip, value, percentage }) => (_jsxs("div", Object.assign({ className: styles.legendItem }, { children: [_jsxs("div", Object.assign({ className: styles.legendItemLabel }, { children: [_jsx("div", { className: styles.legendItemColor, style: { backgroundColor: color } }), _jsx(HelpTooltip, Object.assign({ title: tooltip, placement: 'right', hasLightIcon: true }, { children: label }))] })), _jsxs("div", Object.assign({ className: styles.nowrap }, { children: [_jsx("span", Object.assign({ className: styles.legendItemValue }, { children: largeNumberRound({
                                value: value,
                                million: t('млн'),
                                thousand: t('тыс'),
                            }) })), ' ', _jsxs("span", Object.assign({ className: styles.legendItemPercent }, { children: [percentage, "%"] }))] }))] }), label))) })));
});
