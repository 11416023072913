import { useWalletCardList } from '../WalletCards/useWalletCardList';
export const useGeoPushData = (geoPushId) => {
    const { data, isLoading } = useWalletCardList();
    const selectedGeoPush = data === null || data === void 0 ? void 0 : data.walletGeos.find((geo) => geo.id === geoPushId);
    return {
        selectedGeoPush,
        defaultValues: convertGeoPushToFormValues(selectedGeoPush),
        isLoading: geoPushId ? isLoading : false,
    };
};
const convertGeoPushToFormValues = (selectedGeoPush) => {
    var _a, _b;
    if (!selectedGeoPush)
        return undefined;
    const lat = ((_a = selectedGeoPush === null || selectedGeoPush === void 0 ? void 0 : selectedGeoPush.latitude) === null || _a === void 0 ? void 0 : _a.toString()) || '';
    const lon = ((_b = selectedGeoPush === null || selectedGeoPush === void 0 ? void 0 : selectedGeoPush.longitude) === null || _b === void 0 ? void 0 : _b.toString()) || '';
    return {
        title: selectedGeoPush.name,
        address: {
            value: selectedGeoPush.address,
            unrestricted_value: selectedGeoPush.address,
            data: { geo_lat: lat, geo_lon: lon },
        },
        coordinates: [lat, lon].join(', '),
        message: selectedGeoPush.message,
        show: selectedGeoPush.activeFrom || selectedGeoPush.activeTo
            ? 'specified period'
            : 'always',
        showFrom: selectedGeoPush.activeFrom || undefined,
        showBy: selectedGeoPush.activeTo || undefined,
        distance: selectedGeoPush.distance
            ? selectedGeoPush.distance.toString()
            : '',
        isActive: selectedGeoPush.isActive,
    };
};
