import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { LoadMoreDataButton } from '@/components/LoadMoreDataButton/LoadMoreDataButton';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import { CheckboxTreeNode, } from './CheckboxTreeNode';
import styles from './styles.module.scss';
const deepSearch = (items, search) => {
    const result = [];
    const collect = (items) => {
        for (let i = 0; i < items.length; i += 1) {
            const matched = items[i].label
                .toLowerCase()
                .includes(search.toLowerCase());
            if (matched)
                result.push(items[i]);
            else if (items[i].items)
                collect(items[i].items);
        }
    };
    collect(items);
    return result;
};
const getAllItems = (items) => {
    const allItems = [];
    items.forEach((i) => {
        if ((i.isCatalog || i.isFlat) && i.items)
            allItems.push(...i.items);
        else
            allItems.push(i);
    });
    return allItems;
};
export const CheckboxTree = memo(({ items = [], checkedItems, onChangeCheckedItems, hasMoreData = false, isLoadingMoreData: isLoadingMoreRootData = false, onLoadMoreData, search = '', onChangeSearch, searchDebounceDelay, hasSearch = false, levelIndent = 25, }) => {
    const { t } = useTranslation();
    const handleLoadMoreRootData = useCallback(() => {
        if (onLoadMoreData)
            onLoadMoreData();
    }, [onLoadMoreData]);
    const filteredItems = search
        ? deepSearch(items, search)
        : getAllItems(items);
    const allSelected = filteredItems.length > 0 && checkedItems.length === filteredItems.length;
    const indeterminate = checkedItems.length > 0 && checkedItems.length < filteredItems.length;
    const handleChangeAll = useCallback((e) => {
        const checked = e.target.checked;
        if (checked)
            onChangeCheckedItems(filteredItems);
        else
            onChangeCheckedItems([]);
    }, [onChangeCheckedItems, items]);
    return (_jsxs("div", { children: [hasSearch && onChangeSearch && (_jsx("div", Object.assign({ className: styles.search }, { children: _jsx(SearchInput, { value: search, onChange: onChangeSearch, debounceDelay: searchDebounceDelay, placeholder: t('Найти') }) }))), _jsxs("ul", Object.assign({ className: styles.tree }, { children: [hasSearch && (_jsx("span", Object.assign({ className: styles.label }, { children: _jsx(Checkbox, Object.assign({ checked: allSelected, isIndeterminate: indeterminate, onChange: handleChangeAll, preventScrollToInput: true }, { children: t('(Выделить все)') })) }))), items.map((item) => (_jsx(CheckboxTreeNode, { item: item, level: 0, checkedItems: checkedItems, onChangeCheckedItems: onChangeCheckedItems, onLoadMoreData: onLoadMoreData, search: search, levelIndent: levelIndent }, item.id)))] })), (isLoadingMoreRootData || hasMoreData) && (_jsx(LoadMoreDataButton, { className: styles.rootLoadMoreButton, onClick: handleLoadMoreRootData, isLoading: isLoadingMoreRootData, isOutlined: isLoadingMoreRootData && !items.length }))] }));
});
