import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/store';
import { useDashboardMetrics } from '@/hooks/useDashboardMetrics';
import { activeDashboardSelector } from '@/store/selectors/reports';
import { SparklineButtonGroup } from './SparklineButtonGroup';
import { useSparklineData } from './useSparklineData';
export const SparklineButtonGroupContainer = memo(({ currency }) => {
    const { t } = useTranslation();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const { loadedData, handleUpdate, handleDelete, handleSelect } = useSparklineData();
    const allMetrics = useDashboardMetrics();
    const items = useMemo(() => {
        return loadedData.map(({ metricId, value, data }) => ({
            metric: allMetrics[metricId],
            value,
            data,
        }));
    }, [loadedData, t]);
    return activeDashboard ? (_jsx(SparklineButtonGroup, { items: items, currency: currency, onSelect: handleSelect, onChange: handleUpdate, onDelete: handleDelete, onAdd: handleUpdate })) : null;
});
