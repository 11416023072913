import { useCurrentUser } from '@/app/useCurrentUser';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
import { useTriggerFilters } from '@/services/filters/data/useTriggerFilters';
import { findFirstLeaf } from '@/services/filters/findFirstLeaf';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { createChannelNode } from '../nodes/ChannelNode/utils';
import { createChannelReceivedNode } from '../nodes/ChannelReceivedNode/utils';
import { getScheduleTypeLabel } from '../nodes/ConditionsNode/schedule';
import { createConditionNode } from '../nodes/ConditionsNode/utils';
import { createDelayNode } from '../nodes/DelayNode/utils';
import { createEmailReceivedNode } from '../nodes/EmailReceivedNode/utils';
import { useUpdateFlowWithNewNode } from './useUpdateFlowWithNewNode';
export const useCreateNode = () => {
    const { triggerFilters, emailReceivedTriggers, channelReceivedTriggers, scheduleTriggers, } = useInteractiveFlow();
    const createDelayNode = useCreateDelayNode();
    const createChannelNode = useCreateChannelNode();
    const createCascadeNode = useCreateCascadeNode();
    const createClientFilterNode = useCreateClientFilterNode();
    const createTriggerFilterNode = useCreateTriggerFilterNode();
    const createScheduleNode = useCreateScheduleNode();
    const createEmailReceivedNode = useCreateEmailReceivedNode();
    const createChannelReceivedNode = useCreateChannelReceivedNode();
    return (step) => {
        const triggerFilter = triggerFilters === null || triggerFilters === void 0 ? void 0 : triggerFilters.find((f) => f.id === step);
        const emailReceivedFilter = emailReceivedTriggers === null || emailReceivedTriggers === void 0 ? void 0 : emailReceivedTriggers.find((f) => f.id === step);
        const channelReceivedFilter = channelReceivedTriggers === null || channelReceivedTriggers === void 0 ? void 0 : channelReceivedTriggers.find((f) => f.id === step);
        const scheduleFilter = scheduleTriggers === null || scheduleTriggers === void 0 ? void 0 : scheduleTriggers.find((f) => f.id === step);
        if (step === 'delay') {
            createDelayNode();
            return;
        }
        if (['push', 'viber', 'sms', 'email', 'telegram'].includes(step)) {
            createChannelNode(step);
            return;
        }
        if (step === 'cascade') {
            createCascadeNode();
            return;
        }
        if (step === 'filter-conditions') {
            createClientFilterNode();
            return;
        }
        if (scheduleFilter) {
            createScheduleNode(scheduleFilter.id);
            return;
        }
        if (triggerFilter) {
            createTriggerFilterNode(triggerFilter.id);
            return;
        }
        if (emailReceivedFilter) {
            createEmailReceivedNode(emailReceivedFilter.id);
            return;
        }
        if (channelReceivedFilter) {
            createChannelReceivedNode(channelReceivedFilter.id);
            return;
        }
    };
};
const useCreateDelayNode = () => {
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return () => {
        const defaultData = {
            offsetSign: 1,
            offsetUnit: 'day',
            offsetValue: 1,
        };
        const newDelayNode = createDelayNode(defaultData);
        updateFlowWithNewNode(newDelayNode, true);
    };
};
const useCreateChannelNode = () => {
    const { brand } = useCurrentUser();
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return (channel) => {
        var _a, _b, _c, _d, _e, _f;
        const messagingApps = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.mailing) === null || _b === void 0 ? void 0 : _b.messagingApps) || [];
        const defaultMessagingApp = messagingApps.find((app) => app.isDefault) || messagingApps[0];
        const smsSenders = ((_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.mailing) === null || _d === void 0 ? void 0 : _d.smsSenders) || [];
        const defaultSmsSender = smsSenders.find((sender) => sender.isDefault) || smsSenders[0];
        const emailSenders = ((_f = (_e = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _e === void 0 ? void 0 : _e.mailing) === null || _f === void 0 ? void 0 : _f.emailSenders) || [];
        const defaultEmailSender = emailSenders.find((sender) => sender.isDefault) || emailSenders[0];
        const defaultData = {
            status: 'active',
            channels: [channel],
            name: '',
            push: {
                messagingAppId: defaultMessagingApp === null || defaultMessagingApp === void 0 ? void 0 : defaultMessagingApp.id,
                pushText: '',
                walletPushExpireInDays: 7,
            },
            viber: {
                viberText: '',
                viberButton: '',
                viberUrl: '',
            },
            sms: {
                smsSenderId: defaultSmsSender === null || defaultSmsSender === void 0 ? void 0 : defaultSmsSender.id,
                smsText: '',
            },
            telegram: {
                telegramText: '',
                telegramButton: '',
                telegramUrl: '',
            },
            email: {
                emailSenderId: defaultEmailSender === null || defaultEmailSender === void 0 ? void 0 : defaultEmailSender.id,
                emailSubject: '',
                includeRecommendations: false,
                recommendationType: 1,
                recommendationCount: 4,
            },
            rewards: [],
            goal: {
                goalType: 3,
                goalDaysTo: '14',
            },
        };
        const newChannelNode = createChannelNode(defaultData);
        updateFlowWithNewNode(newChannelNode, true);
    };
};
const useCreateCascadeNode = () => {
    const { brand } = useCurrentUser();
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return () => {
        var _a, _b, _c, _d;
        const messagingApps = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.mailing) === null || _b === void 0 ? void 0 : _b.messagingApps) || [];
        const defaultMessagingApp = messagingApps.find((app) => app.isDefault) || messagingApps[0];
        const smsSenders = ((_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.mailing) === null || _d === void 0 ? void 0 : _d.smsSenders) || [];
        const defaultSmsSender = smsSenders.find((sender) => sender.isDefault) || smsSenders[0];
        const defaultData = {
            status: 'active',
            channels: ['sms'],
            name: '',
            push: {
                messagingAppId: defaultMessagingApp === null || defaultMessagingApp === void 0 ? void 0 : defaultMessagingApp.id,
                pushText: '',
                walletPushExpireInDays: 7,
            },
            viber: {
                viberText: '',
                viberButton: '',
                viberUrl: '',
            },
            sms: {
                smsSenderId: defaultSmsSender === null || defaultSmsSender === void 0 ? void 0 : defaultSmsSender.id,
                smsText: '',
            },
            rewards: [],
            goal: {
                goalType: 3,
                goalDaysTo: '14',
            },
            hasCascadeEnabled: true,
            cascadeActiveChannelTab: 'sms',
        };
        const newChannelNode = createChannelNode(defaultData);
        updateFlowWithNewNode(newChannelNode, true);
    };
};
const useCreateTriggerFilterNode = () => {
    const { triggerType } = useInteractiveFlow();
    const triggerFiltersTree = useTriggerFilters(triggerType);
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return (selectedFilterCode) => {
        const selectedLeaf = findLeafByCode(triggerFiltersTree, selectedFilterCode);
        if (selectedLeaf) {
            const defaultData = {
                type: 'trigger',
                subheader: selectedLeaf.filterTitle || selectedLeaf.label,
                selectedFilterCode: selectedLeaf.code || selectedLeaf.id,
            };
            const newConditionNode = createConditionNode(defaultData);
            updateFlowWithNewNode(newConditionNode, true);
        }
    };
};
const useCreateClientFilterNode = () => {
    const filtersTree = useClientFilters();
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return () => {
        const firstLeaf = findFirstLeaf(filtersTree);
        if (firstLeaf) {
            const defaultData = {
                type: 'filter',
                subheader: firstLeaf.filterTitle || firstLeaf.label,
                selectedFilterCode: firstLeaf.code || firstLeaf.id,
            };
            const newConditionNode = createConditionNode(defaultData);
            updateFlowWithNewNode(newConditionNode, true);
        }
    };
};
const useCreateScheduleNode = () => {
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return (scheduleType) => {
        const newConditionNode = createConditionNode({
            type: 'schedule',
            subheader: getScheduleTypeLabel(scheduleType),
            selectedFilterCode: scheduleType,
        });
        updateFlowWithNewNode(newConditionNode, true);
    };
};
const useCreateEmailReceivedNode = () => {
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return (type) => {
        const defaultData = { type, conditions: [] };
        const newEmailReceivedNode = createEmailReceivedNode(defaultData);
        updateFlowWithNewNode(newEmailReceivedNode, true);
    };
};
const useCreateChannelReceivedNode = () => {
    const updateFlowWithNewNode = useUpdateFlowWithNewNode();
    return (type) => {
        const defaultData = { type, conditions: [] };
        const newEmailReceivedNode = createChannelReceivedNode(defaultData);
        updateFlowWithNewNode(newEmailReceivedNode, true);
    };
};
