import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Controls, ReactFlow, useEdgesState, useNodesState, } from '@xyflow/react';
import classNames from 'classnames';
import styles from '../styles/canvas.module.scss';
import { ChannelForm } from './forms/ChannelForm/ChannelForm';
import { ChannelReceivedForm } from './forms/ChannelReceivedForm/ChannelReceivedForm';
import { ClientFiltersForm } from './forms/ClientFiltersForm/ClientFiltersForm';
import { DelayForm } from './forms/DelayForm/DelayForm';
import { EmailReceivedForm } from './forms/EmailReceivedForm/EmailReceivedForm';
import { LoopForm } from './forms/LoopForm/LoopForm';
import { ScheduleForm } from './forms/ScheduleForm/ScheduleForm';
import { TriggerFiltersForm } from './forms/TriggerFiltersForm/TriggerFiltersForm';
import { useHandleEdgeHover } from './hooks/useHandleEdgeHover';
import { useInitialNodes } from './hooks/useInitialNodes';
import { useLayoutFlow } from './layout/useLayoutFlow';
import { edgeTypes } from './nodes/edgeTypes';
import { nodeTypes } from './nodes/nodeTypes';
export const InteractiveFlowConsumer = ({ defaultNodes = [], defaultEdges = [] }) => {
    const [nodes, , handleNodesChange] = useNodesState(defaultNodes);
    const [edges, setEdges, handleEdgesChange] = useEdgesState(defaultEdges);
    const layoutIsComplete = useLayoutFlow();
    useInitialNodes(!defaultNodes.length || !defaultEdges.length);
    return (_jsxs(_Fragment, { children: [_jsx(ReactFlow, Object.assign({ className: classNames(styles.canvas, {
                    'react-flow__complete': layoutIsComplete,
                }), nodeTypes: nodeTypes, edgeTypes: edgeTypes, nodes: nodes, edges: edges, onNodesChange: handleNodesChange, onEdgesChange: handleEdgesChange, onEdgeMouseEnter: useHandleEdgeHover('enter', setEdges), onEdgeMouseLeave: useHandleEdgeHover('leave', setEdges), nodesConnectable: false }, { children: _jsx(Controls, { showInteractive: false }) })), _jsx(LoopForm, {}), _jsx(DelayForm, {}), _jsx(ChannelForm, {}), _jsx(TriggerFiltersForm, {}), _jsx(ClientFiltersForm, {}), _jsx(ScheduleForm, {}), _jsx(ChannelReceivedForm, {}), _jsx(EmailReceivedForm, {})] }));
};
