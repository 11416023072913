import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
const getBackgroundColorForLevel = (level) => {
    if (!level)
        return '#D9E8EC';
    const levelToBgMap = {
        1: '#EEF5FA',
        2: '#FAE9FB',
        3: '#EAE1FB',
        4: '#D9E9EC',
        5: '#FCA4D2',
        0: '#B597FF', // <- 6
    };
    return levelToBgMap[level % 6];
};
export const useDefaultValues = () => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const brandName = (brand === null || brand === void 0 ? void 0 : brand.brand.name) || '';
    const appleLogo = [
        {
            id: '871a49a0-7a0c-4573-b3e0-84d31ff5a718',
            mimeType: 'image/png',
            width: 75,
            height: 75,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/8c45327e-eb30-4eed-a97a-5aad4fc45a0e/18/871a49a0-7a0c-4573-b3e0-84d31ff5a718.png',
        },
        {
            id: 'af74734a-08ee-4c47-ad02-550832e2fc81',
            mimeType: 'image/png',
            width: 150,
            height: 150,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/8c45327e-eb30-4eed-a97a-5aad4fc45a0e/81/af74734a-08ee-4c47-ad02-550832e2fc81.png',
        },
    ];
    const googleLogo = {
        id: 'fdf2c863-9bd5-4a42-9e29-3f742cf55349',
        mimeType: 'image/png',
        width: 150,
        height: 150,
        name: 'image.png',
        url: 'http://s.maxma.com/app/images/11f8a206-9503-4c27-a62b-1159532215ae/49/fdf2c863-9bd5-4a42-9e29-3f742cf55349.png',
    };
    const notifyLogo = [
        {
            id: '8aa7c2b2-c096-48e0-b648-1668735ccaed',
            mimeType: 'image/png',
            width: 100,
            height: 100,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/8c45327e-eb30-4eed-a97a-5aad4fc45a0e/ed/8aa7c2b2-c096-48e0-b648-1668735ccaed.png',
        },
        {
            id: '650ff22f-a852-4d60-8be9-f0f637149feb',
            mimeType: 'image/png',
            width: 200,
            height: 200,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/8c45327e-eb30-4eed-a97a-5aad4fc45a0e/eb/650ff22f-a852-4d60-8be9-f0f637149feb.png',
        },
    ];
    const appleBanner = [
        {
            id: '5671eda3-a41c-4956-898b-66bfe8a3cce0',
            mimeType: 'image/png',
            width: 562,
            height: 216,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/11f8a206-9503-4c27-a62b-1159532215ae/e0/5671eda3-a41c-4956-898b-66bfe8a3cce0.png',
        },
        {
            id: 'f0cd3cc2-05a4-49c8-972b-a5b48887da2b',
            mimeType: 'image/png',
            width: 1125,
            height: 433,
            name: 'image.png',
            url: 'http://s.maxma.com/app/images/11f8a206-9503-4c27-a62b-1159532215ae/2b/f0cd3cc2-05a4-49c8-972b-a5b48887da2b.png',
        },
    ];
    const googleBanner = {
        id: 'a744366c-13de-42d7-84aa-8b4eae7a6d45',
        mimeType: 'image/png',
        width: 1125,
        height: 433,
        name: 'image.png',
        url: 'http://s.maxma.com/app/images/11f8a206-9503-4c27-a62b-1159532215ae/45/a744366c-13de-42d7-84aa-8b4eae7a6d45.png',
    };
    const googleBacksideBanner = {
        id: '8f8d5be1-99e6-4aa3-adee-a7b837620e7e',
        mimeType: 'image/png',
        width: 1125,
        height: 433,
        name: 'image.png',
        url: 'http://s.maxma.com/app/images/11f8a206-9503-4c27-a62b-1159532215ae/7e/8f8d5be1-99e6-4aa3-adee-a7b837620e7e.png',
    };
    return (level) => ({
        background: getBackgroundColorForLevel(level),
        color: '#000000',
        labelColor: '#000000',
        appleHead: {
            title: brandName,
            field_1_Title: t('бонусы'),
            field_1_Value: '$',
            field_1_Vars: [{ var: 'clientBonuses', params: {} }],
            field_2_Title: '',
            field_2_Value: '',
            logo: appleLogo[1],
            logoSmallUploadResponse: appleLogo[0],
            logoUploadResponse: appleLogo[1],
            logoRejectReason: '',
        },
        googleHead: {
            title: brandName,
            programName: t('Бонусная карта'),
            field_Main_Title: '',
            field_Main_Value: '',
            logo: googleLogo,
            logoUploadResponse: googleLogo,
            logoRejectReason: '',
        },
        appleBanner: {
            title: '',
            titleColor: '',
            titleSize: '',
            description: '',
            descriptionColor: '',
            descriptionSize: '',
            image: appleBanner[1],
            imageSmallUploadResponse: appleBanner[0],
            imageUploadResponse: appleBanner[1],
            imageRejectReason: '',
        },
        googleBanner: {
            title: '',
            titleColor: '',
            titleSize: '',
            description: '',
            descriptionColor: '',
            descriptionSize: '',
            image: googleBanner,
            imageUploadResponse: googleBanner,
            imageRejectReason: '',
            backsideImage: googleBacksideBanner,
            backsideImageUploadResponse: googleBacksideBanner,
            backsideImageRejectReason: '',
        },
        appleFields: {
            field_3_Title: t('дата сгорания'),
            field_3_Value: '$',
            field_3_Vars: [
                { var: 'expressExpiredAt', params: { dateFormat: 'DD.MM.YYYY' } },
            ],
            field_4_Title: '',
            field_4_Value: '',
            field_5_Title: '',
            field_5_Value: '',
            field_6_Title: t('уровень'),
            field_6_Value: '$',
            field_6_Vars: [{ var: 'clientLevelName', params: {} }],
        },
        googleFields: {
            field_1_Title: t('бонусы'),
            field_1_Value: '$',
            field_1_Vars: [{ var: 'clientBonuses', params: {} }],
            field_2_Title: '',
            field_2_Value: '',
            field_3_Title: t('дата сгорания'),
            field_3_Value: '$',
            field_3_Vars: [
                { var: 'expressExpiredAt', params: { dateFormat: 'DD.MM.YYYY' } },
            ],
        },
        backside: {
            appleTitle: t('Бонусная карта'),
            details: [
                {
                    title: t('Номер карты'),
                    content: '$',
                    vars: [{ var: 'cardNumber', params: {} }],
                },
                {
                    title: t('Программа лояльности'),
                    content: t('Получайте кэшбек 5% со всех покупок и оплачивайте ими до 50% от стоимости товаров!'),
                },
            ],
            contacts: [
                {
                    type: 'phone',
                    title: t('Телефон'),
                    content: '8 (800) 888-88-8',
                },
                {
                    type: 'link',
                    title: t('Веб-сайт'),
                    content: 'https://www.yourdomain.com/',
                },
            ],
        },
        notify: {
            title: brandName,
            logo: notifyLogo[1],
            logoSmallUploadResponse: notifyLogo[0],
            logoUploadResponse: notifyLogo[1],
            logoRejectReason: '',
        },
    });
};
