import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormSteps } from '@/components/FormSteps/FormSteps';
import { Panel } from '@/components/Panel2/Panel';
import { TRIGGER_TYPE_SCHEDULE, TRIGGER_TYPE_SEGMENT_JOIN, } from '@/const/mailing-triggers';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { brandHasTelegramFeature } from '@/utils/brandHasTelegramFeature';
import { brandHasViberFeature } from '@/utils/brandHasViberFeature';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { FormMailingChannelTabs } from './controls/FormMailingChannelTabs';
import { OFFSET_SIGN_AT, OFFSET_SIGN_SCHEDULE, } from './controls/FormMailingOffset';
import { FormMailingFooter } from './FormMailingFooter';
import { FormMailingInfo } from './FormMailingInfo';
import { FormMailingStepEmail } from './FormMailingStepEmail';
import { FormMailingStepMain } from './FormMailingStepMain';
import { FormMailingStepPush } from './FormMailingStepPush';
import { FormMailingStepSMS } from './FormMailingStepSMS';
import { FormMailingStepTelegram } from './FormMailingStepTelegram';
import { FormMailingStepViber } from './FormMailingStepViber';
import { FormMailingWarning } from './FormMailingWarning';
import { FormMailingWarningFlow } from './FormMailingWarningFlow';
import { getDefaultSender } from './helpers/getDefaultSender';
import { useCalculate } from './helpers/useCalculate';
import styles from './styles.module.scss';
export const FormMailing = memo(({ unlayerUrl, unlayerProjectId, type, isClone, flow, channelsInput = 'tabs', onSubmit, error, isSubmitting = false, defaultValues, currency, isEdit, onDelete, onSaveDraft, brand, user, templatesData, counters = [], segments = [], offers = [], promocodes = [], giftCards = [], levels = [], emailMailings = [], calculate, onSendTestMessage, uploadImage, refreshEmailPreview, setActive, isReadOnly, stepsClassName, panelClassName, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const [savingDraft, setSavingDraft] = useState(false);
    const defaultEmailSender = getDefaultSender((_a = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _a === void 0 ? void 0 : _a.emailSenders);
    const defaultSmsSender = getDefaultSender((_b = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _b === void 0 ? void 0 : _b.smsSenders);
    const defaultMessagingApp = getDefaultSender((_c = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _c === void 0 ? void 0 : _c.messagingApps);
    const flowTriggerType = flow === null || flow === void 0 ? void 0 : flow.triggerType;
    const methods = useForm({
        defaultValues: Object.assign(Object.assign({ channels: ['sms'], recipientAllClients: false, recipientFilters: [], offsetSign: flowTriggerType === TRIGGER_TYPE_SCHEDULE
                ? OFFSET_SIGN_SCHEDULE
                : OFFSET_SIGN_AT, offsetValue: 1, offsetUnit: 'day', triggerType: flowTriggerType !== null && flowTriggerType !== void 0 ? flowTriggerType : TRIGGER_TYPE_SEGMENT_JOIN, triggerFilters: {}, triggerScheduleType: 'daily', triggerEmailReceivedType: 'not_opened', rewards: {}, schedule: '0', goalType: '3', goalDaysTo: 14, goalFilters: [], isActive: true, messagingApp: defaultMessagingApp === null || defaultMessagingApp === void 0 ? void 0 : defaultMessagingApp.id, pushText: '', walletPushExpireInDays: '7', walletImage: null, viberText: '', viberImage: null, viberButton: '', viberUrl: '', smsSender: defaultSmsSender === null || defaultSmsSender === void 0 ? void 0 : defaultSmsSender.id, smsText: '', emailSender: defaultEmailSender === null || defaultEmailSender === void 0 ? void 0 : defaultEmailSender.id, emailSubject: '', emailBody: null, includeRecommendations: false, recommendationType: 1, recommendationCount: 4 }, defaultValues), { name: isClone
                ? `${t('Копия')} ${defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.name}`
                : defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.name }),
    });
    const brandHasWalletCardSettings = typeof (brand === null || brand === void 0 ? void 0 : brand.walletCardSettings) === 'object'
        ? Object.keys(brand.walletCardSettings).length > 0
        : !!(brand === null || brand === void 0 ? void 0 : brand.walletCardSettings);
    const channelsEnabled = [
        brandHasWalletCardSettings ||
            brandHasWalletFeature(brand) ||
            ((_d = brand === null || brand === void 0 ? void 0 : brand.features) === null || _d === void 0 ? void 0 : _d.mobilePush)
            ? 'push'
            : '',
        brandHasViberFeature(brand) ? 'viber' : '',
        'sms',
        (user === null || user === void 0 ? void 0 : user.isSuperAdmin) || ((_e = brand === null || brand === void 0 ? void 0 : brand.features) === null || _e === void 0 ? void 0 : _e.emailEditor) ? 'email' : '',
        brandHasTelegramFeature(brand) ? 'telegram' : '',
    ].filter(Boolean);
    const channels = methods.watch('channels');
    const steps = [
        { id: 'main', label: t('Общее'), isEnabled: true },
        { id: 'push', label: 'Push', isEnabled: channels === null || channels === void 0 ? void 0 : channels.includes('push') },
        { id: 'viber', label: 'Viber', isEnabled: channels === null || channels === void 0 ? void 0 : channels.includes('viber') },
        { id: 'sms', label: 'SMS', isEnabled: channels === null || channels === void 0 ? void 0 : channels.includes('sms') },
        { id: 'email', label: 'Email', isEnabled: channels === null || channels === void 0 ? void 0 : channels.includes('email') },
        {
            id: 'telegram',
            label: 'Telegram',
            isEnabled: channels === null || channels === void 0 ? void 0 : channels.includes('telegram'),
        },
    ];
    const enabledSteps = steps.filter((i) => i.isEnabled);
    const [activeStepId, setActiveStepId] = useState(enabledSteps[0].id);
    const activeStepIndex = enabledSteps.findIndex((i) => i.id === activeStepId);
    const isFirstStep = activeStepIndex === 0;
    const isLastStep = activeStepIndex === enabledSteps.length - 1;
    const calculateResult = useCalculate(calculate, methods);
    const { dirtyFields, isSubmitting: isSubmittingForm } = methods.formState;
    useNotificateForUnsavedForm(!!Object.keys(dirtyFields).length && !isSubmittingForm && !savingDraft);
    const labelWidth = 27.395;
    const title = methods.watch('name') || t('Без названия');
    return (_jsxs("div", Object.assign({ className: classNames({
            [styles.channelsTabsWrapper]: channelsInput === 'tabs',
        }) }, { children: [channelsInput === 'buttons' && (_jsx("div", Object.assign({ className: classNames(styles.steps, stepsClassName) }, { children: _jsx(FormSteps, { steps: enabledSteps, activeStepId: activeStepId, onStepClick: setActiveStepId }) }))), _jsxs(FormProvider, Object.assign({}, methods, { children: [channelsInput === 'tabs' && (_jsx(FormMailingChannelTabs, { channelsEnabled: channelsEnabled, activeStep: activeStepId, onChangeActiveStep: setActiveStepId })), _jsxs("div", Object.assign({ className: classNames({
                            [styles.channelsTabsPanel]: channelsInput === 'tabs',
                        }) }, { children: [channelsInput === 'tabs' && (_jsx("h1", Object.assign({ className: classNames('page-title', styles.pageTitle) }, { children: title }))), _jsx(Panel, Object.assign({ className: panelClassName, size: channelsInput === 'buttons' ? '800' : 'full', isCentered: channelsInput === 'buttons' }, { children: _jsxs("form", Object.assign({ onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'main',
                                            }) }, { children: _jsx(FormMailingStepMain, { type: type, flow: flow, channelsEnabled: channelsEnabled, channelsInput: channelsInput, clientExtraFields: brand === null || brand === void 0 ? void 0 : brand.clientExtraFields, counters: counters, hasRecommendationsFeature: (_f = brand === null || brand === void 0 ? void 0 : brand.features) === null || _f === void 0 ? void 0 : _f.recommendations, offers: offers, promocodes: promocodes, giftCards: giftCards, levels: levels, emailMailings: emailMailings, currency: currency, timeZone: brand === null || brand === void 0 ? void 0 : brand.defaultTimezone, labelWidth: labelWidth, isEdit: isEdit }) })), _jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'push',
                                            }) }, { children: _jsx(FormMailingStepPush, { textLength: calculateResult.pushTextLength, onSendTestPush: (targets) => onSendTestMessage('push', targets, methods.getValues()), uploadImage: uploadImage, brand: brand, currency: currency, labelWidth: labelWidth }) })), _jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'viber',
                                            }) }, { children: _jsx(FormMailingStepViber, { textLength: calculateResult.viberTextLength, onSendTestViber: (targets) => onSendTestMessage('viber', targets, methods.getValues()), uploadImage: uploadImage, hasWalletFeature: brandHasWalletFeature(brand), currency: currency, labelWidth: labelWidth }) })), _jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'sms',
                                            }) }, { children: _jsx(FormMailingStepSMS, { textLength: calculateResult.smsTextLength, messagesCount: calculateResult.smsMessagesCount, onSendTestSMS: (targets) => onSendTestMessage('sms', targets, methods.getValues()), brand: brand, currency: currency, labelWidth: labelWidth }) })), _jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'email',
                                            }) }, { children: _jsx(FormMailingStepEmail, { type: type, unlayerUrl: unlayerUrl, unlayerProjectId: unlayerProjectId, brand: brand, templatesData: templatesData, uploadImage: uploadImage, refreshEmailPreview: refreshEmailPreview, currency: currency, onSendTestEmail: (targets) => onSendTestMessage('email', targets, methods.getValues()), labelWidth: labelWidth }) })), _jsx("div", Object.assign({ className: classNames(styles.stepWrapper, {
                                                [styles.hidden]: activeStepId !== 'telegram',
                                            }) }, { children: _jsx(FormMailingStepTelegram, { textLength: calculateResult.telegramTextLength, onSendTestTelegram: (targets) => onSendTestMessage('telegram', targets, methods.getValues()), uploadImage: uploadImage, hasWalletFeature: brandHasWalletFeature(brand), currency: currency, labelWidth: labelWidth }) })), _jsx(FormMailingInfo, { type: type, currency: currency, maxCost: calculateResult.maxCost || 0, clientsCount: calculateResult.clientsCount || 0, isReadOnly: isReadOnly, labelWidth: labelWidth }), _jsx(FormMailingWarningFlow, { flow: flow, labelWidth: labelWidth }), _jsx(FormMailingWarning, { type: type, segments: segments, labelWidth: labelWidth }), error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true }, { children: error }))), _jsx(FormMailingFooter, { type: type, isEdit: isEdit, isSubmitting: isSubmitting, isFirstStep: isFirstStep, isLastStep: isLastStep, isReadOnly: isReadOnly, onGoToStep: setActiveStepId, onGoNext: () => {
                                                if (activeStepIndex < enabledSteps.length - 1)
                                                    setActiveStepId(enabledSteps[activeStepIndex + 1].id);
                                            }, onGoPrev: () => {
                                                if (activeStepIndex > 0)
                                                    setActiveStepId(enabledSteps[activeStepIndex - 1].id);
                                            }, onDelete: onDelete, onSaveDraft: (data) => {
                                                setSavingDraft(true);
                                                onSaveDraft === null || onSaveDraft === void 0 ? void 0 : onSaveDraft(data);
                                            }, setActive: setActive })] })) }))] }))] }))] })));
});
