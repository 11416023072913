import { jsx as _jsx } from "react/jsx-runtime";
import { WalletCardFieldEditor } from '@/components/rich-text/WalletCardFieldEditor/WalletCardFieldEditor';
import { useWalletCardForm } from '../context/useWalletCardForm';
export const SmartInput = ({ id, placeholder, value, vars, onChange, hasAutoFocus, onFocus, onBlur, asTextInput = true, hasLinkSupport = false, hasEmojiSupport = true, }) => {
    const { currency } = useWalletCardForm();
    const handleChange = (text, vars, escapedText) => {
        onChange(escapedText, vars);
    };
    return (_jsx(WalletCardFieldEditor, { id: id, placeholder: placeholder, initialValue: value, initialVars: vars, onChange: handleChange, onFocus: onFocus, onBlur: onBlur, hasAutoFocus: hasAutoFocus, asTextInput: asTextInput, hasLinkSupport: hasLinkSupport, hasEmojiSupport: hasEmojiSupport, currency: currency }));
};
