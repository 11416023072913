var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useConfirmNavigation } from './useConfirmNavigation';
export const useNotificateForUnsavedForm = (when) => {
    const { t } = useTranslation();
    const confirmCallback = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const yes = yield confirm({
            proceedClose: true,
            agreeButtonSize: 'large',
            disagreeButtonSize: 'normal',
            agreeLabel: t('Остаться на форме'),
            disagreeLabel: t('Уйти'),
            isDangerCancel: true,
            confirmation: t('Уйти без сохранения?'),
            text: t('Если введенные данные важны, сохраните их перед переходом на другую страницу'),
        });
        return !yes;
    }), [when]);
    useEffect(() => {
        // Вызов нативного браузерного предупреждения о редиректе или обновлении страницы
        const beforeUnloadListener = (event) => {
            event.preventDefault();
            event.returnValue = '';
        };
        if (when)
            addEventListener('beforeunload', beforeUnloadListener);
        return () => {
            removeEventListener('beforeunload', beforeUnloadListener);
        };
    }, [when]);
    useConfirmNavigation(when, confirmCallback);
};
