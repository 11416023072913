import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { Button } from '@/components/Button2/Button';
import { ReportMetricModal } from '@/components/ReportMetricModal/ReportMetricModal';
import { Plus } from '@/icons/Plus';
import styles from './styles.module.scss';
export const SparklineAddButton = memo(({ onAdd }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
    const handleCloseModal = useCallback(() => setModalIsOpen(false), []);
    const handelAddMetric = useCallback((newMetricId) => {
        onAdd(newMetricId);
        handleCloseModal();
    }, [onAdd]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'button', className: styles.add, icon: _jsx(Plus, {}), onClick: handleOpenModal }), _jsx(ReportMetricModal, { isOpen: modalIsOpen, onClose: handleCloseModal, value: '', onApply: handelAddMetric })] }));
});
