import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import moment from 'moment';
import { money } from '@/utils/numbers';
export const billingPlatformToRows = (platform = [], currency) => platform.map((platformItem, index) => {
    const { priceId, moduleId, price, moduleName, billedTo, billedFrom, num, amount, moduleCode, pricePerClient, clientsTo, clientsFrom, discountAmount, discountType, } = platformItem;
    let type;
    let currentPrice;
    if (priceId === null) {
        type = t('Ежедневное списание');
        currentPrice = null;
    }
    else if (moduleCode !== 'platform') {
        type = moduleName;
        currentPrice = money({ input: price || 0, currency });
    }
    else if (pricePerClient !== null) {
        type = `${moduleName} (${t('за каждого клиента сверх тарифа')})`;
        currentPrice = money({ input: pricePerClient || 0, currency });
    }
    else if (clientsTo !== null) {
        type = `${moduleName} (${clientsFrom} – ${clientsTo})`;
        currentPrice = money({ input: price || 0, currency });
    }
    else {
        type = moduleName;
        currentPrice = money({ input: price || 0, currency });
    }
    if (discountAmount !== null && +discountAmount > 0) {
        const currencyType = discountType === 0 ? currency : '%';
        currentPrice = (_jsxs("div", { children: [_jsx("s", { children: money({
                        input: currentPrice || 0,
                        currency: currencyType,
                    }) }), "\u00A0 -", _jsx("i", { children: money({
                        input: discountAmount || 0,
                        currency: currencyType,
                    }) })] }));
    }
    return {
        id: moduleId + index,
        cells: [
            type,
            `${moment(billedFrom).format('DD.MM.YYYY')} – ${moment(billedTo).format('DD.MM.YYYY')}`,
            currentPrice,
            num,
            money({ input: amount || 0, currency }),
        ],
    };
});
