var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useAddClientMutation } from '@/api/query/client';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useAddClient = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [addClient, { isLoading }] = useAddClientMutation();
    const [error, setError] = useState('');
    const handleAddClient = (formValues) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const result = yield addClient(Object.assign(Object.assign({}, formValues), { card: ((_a = formValues.card) === null || _a === void 0 ? void 0 : _a.trim()) || null, birthdate: formValues.birthdate
                ? moment(formValues.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                : null, children: formValues.children
                ? formValues.children
                    .filter((child) => child.name || child.birthdate)
                    .map((child) => (Object.assign(Object.assign({}, child), { birthdate: child.birthdate
                        ? moment(child.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')
                        : null })))
                : [], issuerShopId: formValues.issuerShopId
                ? Number(formValues.issuerShopId)
                : undefined }));
        const error = getErrorMessage(result);
        if (error) {
            setError(error);
            return false;
        }
        else {
            setError('');
            addToast({ type: 'success', message: t('Клиент добавлен') });
            return true;
        }
    });
    return {
        addClient: handleAddClient,
        isAdding: isLoading,
        addError: error,
    };
};
