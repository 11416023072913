/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
export const AutocompleteContext = createContext({
    value: null,
    onChange: () => undefined,
    options: [],
    filteredOptions: [],
    getOptionKey: () => '',
    getOptionLabel: () => '',
    searchText: '',
    loading: false,
});
