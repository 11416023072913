import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { useDeleteNode } from '../../../hooks/useDeleteNode';
import { useNodeCanBeDeleted } from '../../../hooks/useNodeCanBeDeleted';
import styles from './styles.module.scss';
export const NodeDeleteButton = ({ nodeId, }) => {
    const nodeCanBeDeleted = useNodeCanBeDeleted();
    const deleteNode = useDeleteNode();
    if (!nodeCanBeDeleted(nodeId))
        return null;
    return (_jsx(TrashButton, { className: classNames(styles.button, 'nodrag', 'nopan'), onClick: () => deleteNode(nodeId), disableTooltip: true }));
};
