import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodeFormDrawer } from '../NodeFormDrawer/NodeFormDrawer';
import { CascadeForm } from './cascade/CascadeForm';
import { ChannelFormGoal } from './ChannelFormGoal';
import { ChannelFormIcon } from './ChannelFormIcon';
import { ChannelFormName } from './ChannelFormName';
import { ChannelFormRewards } from './ChannelFormRewards';
import { ChannelFormStatus } from './ChannelFormStatus';
import { ChannelFormTitle } from './ChannelFormTitle';
import { EmailForm } from './email/EmailForm';
import { PushForm } from './push/PushForm';
import { SMSForm } from './sms/SMSForm';
import styles from './styles.module.scss';
import { TelegramForm } from './telegram/TelegramForm';
import { useChannelFormState } from './useChannelFormState';
import { ViberForm } from './viber/ViberForm';
export const ChannelForm = () => {
    const { selectedNode } = useInteractiveFlow();
    const isOpen = selectedNode.type === 'channel';
    const { isPush, isViber, isSMS, isEmail, isTelegram, isCascade } = useChannelFormState();
    const handleClose = () => {
        selectedNode.reset();
    };
    return (_jsx(NodeFormDrawer, Object.assign({ icon: _jsx(ChannelFormIcon, {}), title: _jsx(ChannelFormTitle, {}), titleRightElement: _jsx(ChannelFormStatus, {}), footer: _jsx(ChannelFormGoal, {}), open: isOpen, onClose: handleClose, isWide: isCascade, isLarge: true }, { children: _jsxs("div", Object.assign({ className: styles.form }, { children: [_jsx(ChannelFormName, {}), !isCascade && _jsx(ChannelFormRewards, {}), isPush && _jsx(PushForm, {}), isViber && _jsx(ViberForm, {}), isSMS && _jsx(SMSForm, {}), isEmail && _jsx(EmailForm, {}), isTelegram && _jsx(TelegramForm, {}), isCascade && _jsx(CascadeForm, {})] })) })));
};
