import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import { Cake } from '@/icons/Cake';
import styles from './styles.module.scss';
export const BirthdateDaysInput = memo(({ before, onChangeBefore, after, onChangeAfter, onKeyDown }) => {
    const { t } = useTranslation();
    const handleChangeBefore = useCallback((e) => {
        onChangeBefore(e.target.value);
    }, [onChangeBefore]);
    const handleChangeAfter = useCallback((e) => {
        onChangeAfter(e.target.value);
    }, [onChangeAfter]);
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.inputWrapper }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'birthdate-days-before' }, { children: [t('BEFORE'), _jsx(Cake, {})] })), _jsx("div", Object.assign({ className: styles.input }, { children: _jsx(Input, { id: 'birthdate-days-before', value: before, onChange: handleChangeBefore, type: 'number', inputMode: 'numeric', shouldHideSpinButtons: true, step: 'any', preventChars: ['+', '.', ','], preventNegativeNumbers: true, onKeyDown: onKeyDown }) })), _jsx("div", Object.assign({ className: styles.message }, { children: t('DAYS_MESSAGE', { count: Number(before) }) }))] })), _jsxs("div", Object.assign({ className: styles.inputWrapper }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: 'birthdate-days-after' }, { children: [t('AFTER'), _jsx(Cake, {})] })), _jsx("div", Object.assign({ className: styles.input }, { children: _jsx(Input, { id: 'birthdate-days-after', value: after, onChange: handleChangeAfter, type: 'number', inputMode: 'numeric', shouldHideSpinButtons: true, step: 'any', preventChars: ['+', '.', ','], preventNegativeNumbers: true, onKeyDown: onKeyDown }) })), _jsx("div", Object.assign({ className: styles.message }, { children: t('DAYS_MESSAGE', { count: Number(after) }) }))] })), _jsx(InputInfo, { children: t('Укажите хотя бы одно значение') }), _jsx(InputInfo, { children: t('Введите нули, если нужна текущая дата/сегодня') })] }));
});
