import { cr } from '@/utils/cr';
import { roi } from '@/utils/roi';
export const buildTotal = (mailings, showProfit) => {
    const total = {
        roi: 0,
        cr: 0,
        profit: 0,
        revenue: 0,
        expenses: 0,
        goals: 0,
        sendings: 0,
    };
    for (let i = 0; i < mailings.length; i += 1) {
        const m = mailings[i];
        total.revenue += Number(m.revenue);
        total.profit += Number(m.profit);
        total.expenses += Number(m.expenses);
        total.sendings += Number(m.sendings);
        total.goals += Number(m.goals);
    }
    total.cr = cr(total.goals, total.sendings);
    total.roi = roi(showProfit ? total.profit : total.revenue, total.expenses);
    return total;
};
