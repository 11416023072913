var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { getErrorMessage } from '@/api/query';
import { useGenerateGiftCardsMutation } from '@/api/query/giftCard';
export const useGenerateGiftCards = ({ onSuccess, }) => {
    const [error, setError] = useState('');
    const [mutate, { isLoading: isSubmitting }] = useGenerateGiftCardsMutation();
    const resetError = useCallback(() => {
        setError('');
    }, []);
    const generateGiftCards = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield mutate(payload);
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else
            onSuccess();
    });
    return { generateGiftCards, isSubmitting, error, resetError };
};
