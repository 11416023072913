import { defaultDesign } from './defaultDesign';
export function loadDesign(editor, design) {
    let editorDesign = defaultDesign;
    try {
        if (design)
            editorDesign = JSON.parse(design);
    }
    catch (_a) {
        // dot nothing
    }
    editor.loadDesign(editorDesign);
}
