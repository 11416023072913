var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { largeNumberRound } from '@/utils/numbers';
import { FormClientBonuses, MAX_BONUSES, } from './FormClientBonuses';
import styles from './styles.module.scss';
export const FormClientBonusesModal = memo((_a) => {
    var { id = 'client-bonuses-form', isOpen, onClose, isSubmitting, error } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "isSubmitting", "error"]);
    const { t } = useTranslation();
    const [operation, setOperation] = useState('plus');
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const [amount, setAmount] = useState(0);
    const bonusesAmount = amount > 0 && amount <= MAX_BONUSES
        ? t('BONUSES_AMOUNT', {
            amount: largeNumberRound({
                value: amount,
                million: t('млн'),
                thousand: t('тыс'),
            }),
            count: amount,
        })
        : '';
    const buttonLabel = operation === 'plus' ? (_jsxs(_Fragment, { children: [t('Начислить'), bonusesAmount && _jsxs(_Fragment, { children: [" / +", bonusesAmount] })] })) : (_jsxs(_Fragment, { children: [t('Списать'), bonusesAmount && _jsxs(_Fragment, { children: [" / -", bonusesAmount] })] }));
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: t('Начислить, списать бонусы'), footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: operation === 'plus' ? 'default' : 'danger', disabled: isSubmitting, size: 'extra-large' }, { children: buttonLabel })) }))] }), isForm: true, size: '770', isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormClientBonuses, Object.assign({ setHasFormChanged: setHasFormChanged, id: id, onChangeOperation: setOperation, onChangeAmount: setAmount }, props)) })));
});
