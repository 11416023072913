var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
export const useConfirmNavigation = (when = true, confirm) => {
    const { navigator } = useContext(NavigationContext);
    useEffect(() => {
        const push = navigator.push;
        if (when)
            navigator.push = (...args) => __awaiter(void 0, void 0, void 0, function* () {
                const result = yield confirm();
                if (result)
                    push(...args);
            });
        else
            navigator.push = push;
        return () => {
            navigator.push = push;
        };
    }, [navigator, confirm, when]);
};
