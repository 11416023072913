import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, FORMAT_TEXT_COMMAND, TextNode, } from 'lexical';
import { useEffect } from 'react';
import { INSERT_VARIABLE, insertVariable, insertVariableText, } from './insertVariable';
import transformTextNodeToVariable from './transformTextNodeToVariable';
import { VariableNode } from './VariableNode';
export const VariablePlugin = ({ variables, method = 'transform', }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        editor.registerCommand(FORMAT_TEXT_COMMAND, (format) => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection))
                return false;
            selection.getNodes().forEach((node) => {
                if (node instanceof VariableNode)
                    node.setFormat(format);
            });
            selection.formatText(format);
            return true;
        }, COMMAND_PRIORITY_LOW);
    }, [editor]);
    useEffect(() => {
        return editor.registerCommand(INSERT_VARIABLE, method === 'transform' ? insertVariableText : insertVariable, COMMAND_PRIORITY_LOW);
    }, [editor]);
    useEffect(() => {
        if (method === 'transform')
            return editor.registerNodeTransform(TextNode, transformTextNodeToVariable(variables));
    }, [editor]);
    return null;
};
export { VariableNode };
