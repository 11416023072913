var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { getErrorMessage } from '@/api/query';
import { useSetSegmentMutation } from '@/api/query/segment';
import { addToLS, getFromLS } from '@/utils/LS';
export const useEditSegment = () => {
    const [selectedSegment, setSelectedSegment] = useState(undefined);
    const [editError, setEditError] = useState('');
    const openEditModal = useCallback((segment) => {
        setSelectedSegment(segment);
    }, []);
    const closeEditModal = useCallback(() => {
        setSelectedSegment(undefined);
    }, []);
    const [editSegmentMutation, { isLoading: isEditing }] = useSetSegmentMutation();
    const editSegment = useCallback((values) => __awaiter(void 0, void 0, void 0, function* () {
        if (!selectedSegment)
            return;
        const result = yield editSegmentMutation(Object.assign({ id: selectedSegment.id }, values));
        if ('error' in result)
            setEditError(getErrorMessage(result.error));
        else {
            const segmentFromLS = getFromLS({ key: 'clientsSegment' });
            if ((segmentFromLS === null || segmentFromLS === void 0 ? void 0 : segmentFromLS.id) === selectedSegment.id)
                addToLS({
                    key: 'clientsSegment',
                    value: result.data,
                });
            closeEditModal();
        }
    }), [selectedSegment === null || selectedSegment === void 0 ? void 0 : selectedSegment.id]);
    const closeEditError = useCallback(() => {
        setEditError('');
    }, []);
    return {
        editModalIsOpen: !!selectedSegment,
        openEditModal,
        closeEditModal,
        selectedSegment,
        editSegment,
        isEditing,
        editError,
        closeEditError,
    };
};
