import { TextNode, } from 'lexical';
import styles from './styles.module.scss';
export class LinkNode extends TextNode {
    static getType() {
        return 'custom-link';
    }
    static clone(node) {
        return new LinkNode(node.__text, node.__url, node.__key);
    }
    constructor(text, url, key) {
        super(text, key);
        this.__url = '';
        this.__url = url;
    }
    canInsertTextBefore() {
        return false;
    }
    canInsertTextAfter() {
        return false;
    }
    // DOM methods ---------------------------------------------------------------
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.className = styles.link;
        dom.dataset.href = this.__url;
        return dom;
    }
    updateDOM(prevNode, dom, config) {
        const result = super.updateDOM(prevNode, dom, config);
        if (prevNode.__url !== this.__url)
            dom.dataset.href = this.__url;
        return result;
    }
    // JSON methods --------------------------------------------------------------
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom-link', url: this.__url });
    }
    static importJSON(serializedNode) {
        const node = $createLinkNode(serializedNode.text, serializedNode.url);
        return node;
    }
    // Getters and Setters -------------------------------------------------------
    getURL() {
        const self = this.getLatest();
        return self.__url;
    }
    setURL(url) {
        const writable = this.getWritable();
        writable.__url = url;
        return writable;
    }
}
export function $createLinkNode(text, url) {
    const node = new LinkNode(text, url);
    return node;
}
export function $isLinkNode(node) {
    return node instanceof LinkNode;
}
