import * as triggers from '@/const/mailing-triggers';
import { createNode } from '../createNode';
export const createTriggerNode = (data) => {
    const isInteractive = !!data.plusButton;
    return createNode({
        type: 'trigger',
        data,
        position: {
            x: data.x || 0,
            y: data.y || 0,
        },
        style: {
            opacity: 0,
        },
        selectable: isInteractive,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const createTriggerNodeForMailing = (triggerType) => {
    return {
        id: 'trigger',
        type: 'trigger',
        data: { triggerType },
        position: { x: 0, y: 0 },
    };
};
export const TRIGGER_DEFAULT_REPEAT_MAP = {
    [triggers.TRIGGER_TYPE_SEGMENT_JOIN]: 'one-time',
    [triggers.TRIGGER_TYPE_SEGMENT_LEAVE]: 'one-time',
    [triggers.TRIGGER_TYPE_REGISTER_CLIENT]: 'one-time',
    [triggers.TRIGGER_TYPE_LEVEL_UP]: 'one-time',
    [triggers.TRIGGER_TYPE_REGISTER_WALLET_CARD]: 'one-time',
    [triggers.TRIGGER_TYPE_PURCHASE]: 'infinity',
    [triggers.TRIGGER_TYPE_RETURN]: 'infinity',
    [triggers.TRIGGER_TYPE_BONUS_ACTIVATE]: 'infinity',
    [triggers.TRIGGER_TYPE_BONUS_EXPIRING]: 'infinity',
    [triggers.TRIGGER_TYPE_ABANDONED_CART]: 'infinity',
    [triggers.TRIGGER_TYPE_PRICE_IN_CART_DROPPED]: 'infinity',
    [triggers.TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED]: 'infinity',
    [triggers.TRIGGER_TYPE_PRODUCT_VIEW]: 'infinity',
    [triggers.TRIGGER_TYPE_BIRTHDAY]: 'infinity',
    [triggers.TRIGGER_TYPE_CHILD_BIRTHDAY]: 'infinity',
    [triggers.TRIGGER_TYPE_SCHEDULE]: 'infinity',
};
export const TRIGGER_FILTERS_REQUIRED_MAP = {
    [triggers.TRIGGER_TYPE_SEGMENT_JOIN]: true,
    [triggers.TRIGGER_TYPE_SEGMENT_LEAVE]: true,
    [triggers.TRIGGER_TYPE_SCHEDULE]: true,
};
