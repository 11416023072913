export const hasEyeDropperSupport = !!window.EyeDropper;
export const getEyeDropperColor = () => {
    const eyeDropper = new window.EyeDropper();
    const promise = eyeDropper.open();
    return promise.then((result) => {
        var _a;
        return ((_a = result.sRGBHex) === null || _a === void 0 ? void 0 : _a.startsWith('rgb'))
            ? toHex(result.sRGBHex)
            : result.sRGBHex || '';
    });
};
const toHex = (rgba) => {
    const numbers = rgba.match(/\d+/g);
    if (numbers && numbers.length >= 3) {
        let hex = '#';
        for (let i = 0; i < 3; i += 1) {
            const part = Number(numbers[i]).toString(16);
            if (part.length == 1)
                hex += `0${part}`;
            else
                hex += part;
        }
        return hex;
    }
    return '';
};
