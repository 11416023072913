import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useEffect } from 'react';
export const EditorInitStatePlugin = ({ initialValue, }) => {
    const [editor] = useLexicalComposerContext();
    // Инициализация состояния редактора
    useEffect(() => {
        editor === null || editor === void 0 ? void 0 : editor.update(() => {
            const initialString = initialValue
                // Превращаем теги <tg-spoiler> в <code> который служит для телеграма скрытым текстом
                .replaceAll(/<tg-spoiler>/g, '<code>')
                .replaceAll(/<\/tg-spoiler>/g, '</code>');
            // Превращаем переносы строк в параграфы
            const parts = initialString.split('\n');
            let formattedString = '';
            parts.forEach((part, index) => {
                // Цитата это нода контейнер и не может быть вложена в параграф
                if (part.startsWith('<blockquote>')) {
                    formattedString += part;
                    return;
                }
                if (index !== parts.length - 1)
                    formattedString += '<p>' + part + '</p>';
            });
            // Парсим строку разметки в DOM документ
            const dom = new DOMParser().parseFromString(formattedString, 'text/html');
            // Генерируем узлы lexical из DOM
            const nodes = $generateNodesFromDOM(editor, dom);
            // Вставляем узлы в редактор
            $insertNodes(nodes);
        });
    }, []);
    return null;
};
