import { externalTooltipHandler } from '@/components/ChartTooltip/ChartTooltip';
export const getBaseConfig = ({ interval, crosshair, }) => ({
    type: 'line',
    options: {
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
            crosshair: Object.assign(Object.assign({}, crosshair), { zoom: Object.assign(Object.assign({}, crosshair.zoom), { enabled: true, zoomButtonText: '' }) }),
        },
        elements: {
            point: { radius: 0 },
            line: { tension: 0.2 },
        },
        scales: {
            money: {
                position: 'left',
                ticks: { display: false },
                grid: { drawTicks: false, drawBorder: false },
                suggestedMin: 0,
            },
            percent: {
                position: 'left',
                ticks: { display: false },
                grid: {
                    display: false,
                    drawTicks: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                },
                suggestedMin: 0,
                suggestedMax: 100,
            },
            count: {
                position: 'left',
                ticks: { display: false },
                grid: {
                    display: false,
                    drawTicks: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                },
                suggestedMin: 0,
            },
            x: {
                type: 'time',
                time: {
                    stepSize: 1,
                    round: interval,
                    minUnit: interval,
                    unit: interval,
                    displayFormats: {
                        day: 'D MMM',
                        week: 'D MMM',
                        month: 'MMM YYYY',
                    },
                    tooltipFormat: 'MMM D, YYYY',
                },
                ticks: {
                    callback: (value, index, ticks) => {
                        const parts = value.toString().split(/ /);
                        if (parts.length === 2) {
                            if (index === 0 || index === ticks.length - 1)
                                return parts;
                            const date = new Date(ticks[index].value);
                            if ((interval === 'day' || interval === 'week') &&
                                date.getDate() === 1)
                                return parts;
                            if (interval === 'month' && date.getMonth() === 0)
                                return parts;
                            return parts[0];
                        }
                        return value;
                    },
                },
            },
        },
    },
});
