import { useCallback, useState } from 'react';
export const useForceLayout = () => {
    const [isLayoutUpdateRequested, setIsLayoutUpdateRequested] = useState(false);
    const forceLayout = useCallback(() => {
        setIsLayoutUpdateRequested(true);
    }, []);
    const resetLayoutRequest = useCallback(() => {
        setIsLayoutUpdateRequested(false);
    }, []);
    return {
        isLayoutUpdateRequested,
        forceLayout,
        resetLayoutRequest,
    };
};
