import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { FormGeoPushFields } from './FormGeoPushFields';
import { FormGeoPushFooter } from './FormGeoPushFooter';
export const FormGeoPush = memo(({ isEdit, isShowAddressField = false, isSubmitting = false, timeZone, formValues = {
    distance: '100',
    isActive: true,
}, onSubmit, onDelete, setActive, onChangeMessage, }) => {
    const methods = useForm({ defaultValues: formValues });
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", Object.assign({ onSubmit: methods.handleSubmit(onSubmit) }, { children: [_jsx(FormFieldset, { children: _jsx(FormGeoPushFields, { isShowAddressField: isShowAddressField, defaultCoordinates: formValues === null || formValues === void 0 ? void 0 : formValues.coordinates, timeZone: timeZone, onChangeMessage: onChangeMessage }) }), _jsx(FormGeoPushFooter, { isEdit: isEdit, onDelete: onDelete, isSubmitting: isSubmitting, setActive: setActive })] })) })));
});
