import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/hooks/store';
import { addDashboard } from '@/store/slice/reports';
import { DashboardWidgetType } from '@/types/dashboard';
const createTemplate = (dimensions = []) => ({
    id: 0,
    title: 'Template',
    filters: [],
    widgets: [
        {
            type: DashboardWidgetType.KPITiles,
            configuration: {
                metrics: [
                    { key: 'revenue' },
                    { key: 'expensesFull' },
                    { key: 'avgSale' },
                    { key: 'signupCount' },
                    { key: 'engagementRate' },
                ],
            },
        },
        {
            type: DashboardWidgetType.Analytics,
            configuration: {
                metrics: [
                    { key: 'revenue', showOnChart: true },
                    { key: 'discounts', showOnChart: true },
                    { key: 'expensesFull', showOnChart: true },
                ],
                dimensions,
            },
        },
    ],
});
export const useAddDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return () => dispatch(addDashboard(Object.assign(Object.assign({}, createTemplate()), { title: t('Новый отчет') })));
};
export const useAddRFMDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return () => dispatch(addDashboard(Object.assign(Object.assign({}, createTemplate([{ key: 'rfm' }])), { title: t('RFM') })));
};
