import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteImport } from '@/app/pages/Imports/useDeleteImport';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { importTypes } from '@/components/ImportsTable/importTypes';
import { Panel } from '@/components/Panel2/Panel';
import { ROUTES } from '../routes';
import { ImportForm } from './ImportForm';
import { ImportFormFooter } from './ImportFormFooter';
import { ImportFormLoading } from './ImportFormLoading';
import { useGiftCards } from './useGiftCards';
import { useImportData } from './useImportData';
import { usePromocodes } from './usePromocodes';
import { useSaveImport } from './useSaveImport';
export const ImportFormContainer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { brand, currency } = useCurrentUser();
    const clientExtraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const importId = Number(params.importId);
    const goToList = () => {
        navigate(ROUTES.IMPORTS);
    };
    const { importData, setImportData, importPreviewData, isLoading: isLoadingImportData, isFetchingImportPreviewData, reloadPreview, } = useImportData(importId);
    const { promocodes, isLoading: isLoadingPromocodes } = usePromocodes();
    const { giftCards, isLoading: isLoadingGiftCards } = useGiftCards();
    const deleteImport = useDeleteImport({ onSuccess: goToList });
    const { saveImport, isSaving, error } = useSaveImport({
        onSuccess: goToList,
    });
    const title = importData
        ? `${importData.title} — ${importTypes[importData.type]}`
        : '';
    const isLoading = isLoadingImportData || isLoadingGiftCards || isLoadingPromocodes;
    return isLoading || !importData || !importPreviewData ? (_jsx(ImportFormLoading, {})) : (_jsx(Container, { children: _jsxs(Panel, Object.assign({ title: title, size: '900', isCentered: true }, { children: [_jsx(ImportForm, { importData: importData, importPreview: importPreviewData, giftCards: giftCards, promocodes: promocodes, onChangeImportData: setImportData, onChangeSheet: reloadPreview, clientExtraFields: clientExtraFields, currency: currency, isLoading: isFetchingImportPreviewData }), _jsx(ImportFormFooter, { error: error, isSubmitting: isSaving, onDelete: () => deleteImport(importData), onSaveDraft: () => saveImport(importData, true), onSubmit: () => saveImport(importData) })] })) }));
};
