import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { FilterBadge } from '@/components/FiltersPanel/FilterBadge';
import { GiftCard as GiftCardIcon } from '@/icons/GiftCard';
import { money } from '@/utils/numbers';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const GiftCard = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], currency, }) => {
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'gift-card')
        return null;
    const selectedGiftCard = giftCards.find((g) => g.id === selectedItem.giftCardId);
    if (!selectedGiftCard)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(FilterBadge, { ref: triggerRef, className: styles.badge, text: _jsxs("span", Object.assign({ className: styles.badgeContent }, { children: [_jsx(GiftCardIcon, {}), `${selectedGiftCard.name} (${money({
                            input: selectedGiftCard.amount,
                            currency,
                        })})`] })), onClose: handleReset, onClick: togglePopup, isActive: popupIsOpen }), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: 'giftCards', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
