import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EmailNotClickedAlt } from '@/icons/flow/EmailNotClickedAlt';
import { EmailNotOpenedAlt } from '@/icons/flow/EmailNotOpenedAlt';
import { EmailNotPurchasedAlt } from '@/icons/flow/EmailNotPurchasedAlt';
import { StartAlt } from '@/icons/flow/StartAlt';
import styles from './styles.module.scss';
export const NewStepNodeTriggerFilters = ({ filters = [], onSelectFilter, isChannelTriggers = false, isEmailTriggers = false, }) => {
    const { t } = useTranslation();
    if (!filters.length)
        return null;
    const handleSelectFilter = (filter) => (e) => {
        e.stopPropagation();
        onSelectFilter === null || onSelectFilter === void 0 ? void 0 : onSelectFilter(filter);
    };
    return (_jsxs("div", Object.assign({ className: styles.stepsWrapper }, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: isChannelTriggers || isEmailTriggers
                    ? t('Добавьте условия по рассылке')
                    : t('Добавьте условие старта') })), filters.map((filter) => {
                let icon = _jsx(StartAlt, {});
                switch (filter.id) {
                    case 'opened': {
                        icon = _jsx(EmailNotOpenedAlt, {});
                        break;
                    }
                    case 'clicked':
                    case 'delivered_channel': {
                        icon = _jsx(EmailNotClickedAlt, {});
                        break;
                    }
                    case 'purchased':
                    case 'purchased_channel': {
                        icon = _jsx(EmailNotPurchasedAlt, {});
                        break;
                    }
                }
                return (_jsxs("button", Object.assign({ className: classNames(styles.stepButton, 'nodrag', 'nopan'), type: 'button', onClick: handleSelectFilter(filter.id) }, { children: [icon, filter.label] }), filter.id));
            })] })));
};
