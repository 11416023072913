import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '@/components/DateInput/DateInput';
import { Input } from '@/components/Input/Input';
import { CloseCircle } from '@/icons/CloseCircle';
import { PlusSolid } from '@/icons/PlusSolid';
import styles from './styles.module.scss';
export const ChildInput = memo(({ id, value, onChange, onAdd, onReset }) => {
    const { t } = useTranslation();
    const defaultBirthdate = moment('01.01.2008', 'DD.MM.YYYY').toDate();
    const birthdate = (value === null || value === void 0 ? void 0 : value.birthdate)
        ? moment(value.birthdate, 'YYYY-MM-DD').toDate()
        : null;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.name }, { children: _jsx(Input, { id: id, placeholder: t('введите имя ребенка'), value: (value === null || value === void 0 ? void 0 : value.name) || '', onChange: (e) => {
                        onChange(Object.assign(Object.assign({}, value), { name: e.target.value }));
                    } }) })), _jsx("div", Object.assign({ className: styles.birthdate }, { children: _jsx(DateInput, { placeholderText: t('день рождения'), openToDate: birthdate || defaultBirthdate, selected: birthdate, onChange: (date) => {
                        onChange(Object.assign(Object.assign({}, value), { birthdate: date ? moment(date).format('YYYY-MM-DD') : '' }));
                    } }) })), _jsxs("div", Object.assign({ className: styles.buttons }, { children: [onReset && (_jsx("button", Object.assign({ type: 'button', className: classNames(styles.button, styles.reset), onClick: onReset }, { children: _jsx(CloseCircle, {}) }))), onAdd && (_jsx("button", Object.assign({ type: 'button', className: classNames(styles.button, styles.add), onClick: onAdd }, { children: _jsx(PlusSolid, {}) })))] }))] })));
});
