import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { emailDesignListSelector } from '@/store/selectors/mailings';
import { getEmailTemplatesListThunk } from '@/store/thunk/mailings';
import { useOnIntersection } from './useOnIntersection';
const RECENT_TEMPLATES_LIMIT = 30;
export const useTemplatesInfiniteScrollData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const { data, hasMoreData, lastOffset } = useAppSelector(emailDesignListSelector);
    const recentTemplatesOffset = useRef(lastOffset);
    const handleGetEmailTemplatesList = () => {
        recentTemplatesOffset.current += RECENT_TEMPLATES_LIMIT;
        setIsLoading(true);
        dispatch(getEmailTemplatesListThunk({
            limit: RECENT_TEMPLATES_LIMIT,
            offset: recentTemplatesOffset.current,
        })).then(() => {
            setIsLoading(false);
        });
    };
    const handleGetEmailTemplatesListFirstPage = () => {
        return dispatch(getEmailTemplatesListThunk({
            limit: RECENT_TEMPLATES_LIMIT,
            offset: 0,
        }));
    };
    const { handleIntersection, isIntersecting } = useOnIntersection({
        hasMoreToFetch: hasMoreData,
        isFetching: isLoading,
        onIntersection: handleGetEmailTemplatesList,
    });
    return {
        hasMoreData: hasMoreData,
        templates: (data === null || data === void 0 ? void 0 : data.templates) || [],
        recent: (data === null || data === void 0 ? void 0 : data.recent) || [],
        isIntersecting,
        handleIntersection,
        handleGetEmailTemplatesListFirstPage,
    };
};
