import { useGetRFMSegmentListQuery } from '@/api/query/rfm';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useRFMSegmentList = (searchText, enabled) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data, isLoading, isFetching } = useGetRFMSegmentListQuery({ searchText, periodStart, periodEnd }, { skip: !enabled, refetchOnMountOrArgChange: true });
    const { statsPerSegment, overallStats } = data || {};
    return {
        rfmSegments: statsPerSegment,
        rfmTotal: overallStats,
        isLoadingRFM: isLoading,
        isFetchingRFM: isFetching,
    };
};
