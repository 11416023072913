import { capitalize } from '@/utils/capitalize';
import { largeNumberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
const getOrCreateTooltip = (chart) => {
    var _a, _b;
    let tooltipEl = (_a = chart.canvas.parentNode) === null || _a === void 0 ? void 0 : _a.querySelector('div');
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add(styles.tooltip, 'chart-tooltip');
        (_b = chart.canvas.parentNode) === null || _b === void 0 ? void 0 : _b.appendChild(tooltipEl);
    }
    return tooltipEl;
};
const createTitle = (title) => {
    const text = document.createTextNode(capitalize(title));
    const element = document.createElement('div');
    element.classList.add(styles.tooltipTitle);
    element.appendChild(text);
    return element;
};
const createColorBubble = (background) => {
    const element = document.createElement('span');
    element.classList.add(styles.tooltipItemColor);
    element.style.background = background;
    return element;
};
const createLabel = (background, text) => {
    const colorBubble = createColorBubble(background);
    const label = document.createElement('span');
    label.classList.add(styles.tooltipItemLabel, 'tooltip-item-label');
    label.appendChild(document.createTextNode(text));
    const container = document.createElement('div');
    container.classList.add(styles.tooltipItemLabelWrapper, 'tooltip-item-label-wrapper');
    container.appendChild(colorBubble);
    container.appendChild(label);
    return container;
};
const createValue = (rawValue) => {
    const element = document.createElement('span');
    element.classList.add(styles.tooltipItemValue, 'tooltip-item-value');
    const formatted = typeof rawValue === 'number' ? largeNumberRound(rawValue) : rawValue + '';
    element.appendChild(document.createTextNode(formatted));
    return element;
};
const createLine = () => {
    const element = document.createElement('div');
    element.classList.add(styles.tooltipItemLine);
    return element;
};
const createItem = (background, text, rawValue) => {
    const [labelText] = text.split(':').map((i) => i.trim());
    const element = document.createElement('div');
    element.classList.add(styles.tooltipItem);
    element.appendChild(createLabel(background.toLowerCase() === '#3a3a3a' ? '#000' : background, labelText));
    element.appendChild(createValue(rawValue));
    element.appendChild(createLine());
    return element;
};
export const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }
    if (tooltip.body) {
        while (tooltipEl.firstChild)
            tooltipEl.firstChild.remove();
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines).reverse();
        const dataPoints = tooltip.dataPoints.reverse();
        const colors = tooltip.labelColors.reverse();
        titleLines.forEach((title) => {
            tooltipEl.appendChild(createTitle(title));
        });
        bodyLines.forEach((body, i) => {
            tooltipEl.appendChild(createItem(colors[i].backgroundColor.toString(), body.join(''), dataPoints[i].raw));
        });
    }
    const canvasX = chart.canvas.offsetLeft;
    const canvasY = chart.canvas.offsetTop;
    const canvasH = chart.canvas.clientHeight;
    const canvasW = chart.canvas.clientWidth;
    const canvasYCenter = canvasY + canvasH / 2;
    const offset = 20;
    const tooltipH = tooltipEl.clientHeight;
    const tooltipW = tooltipEl.clientWidth;
    chart.canvas.style.position = 'relative';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.zIndex = '100000';
    let tooltipX = 0;
    let tooltipY = 0;
    if (chart.config.type === 'doughnut') {
        tooltipX = canvasX + tooltip.caretX - (tooltipW / 2 + offset);
        tooltipY = canvasY + tooltip.caretY;
    }
    else {
        tooltipX = canvasX + tooltip.caretX - offset;
        tooltipY = canvasYCenter - (tooltipH / 2 + offset);
        if (tooltipX + tooltipW + offset > canvasX + canvasW)
            tooltipX -= tooltipW + offset * 3;
    }
    tooltipEl.style.left = tooltipX + 'px';
    tooltipEl.style.top = tooltipY + 'px';
};
