import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormOfferTitle = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState, watch } = useFormContext();
    const { errors } = formState;
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const maxLength = 200;
    const title = watch('title');
    useGetLastBreadcrumb(title);
    return (_jsx(FormFieldRow, Object.assign({ label: t('Название'), labelFor: 'title', labelWidth: labelWidth, controlSize: '400', help: ((_a = errors.title) === null || _a === void 0 ? void 0 : _a.message) ||
            t('рабочее название акции в платформе, клиент его не видит'), hasError: !!errors.title, isRequired: true }, { children: _jsx(Controller, { name: 'title', control: control, rules: {
                validate: validateTrimValue,
                maxLength: {
                    value: maxLength,
                    message: t('FIELD_MAX_LENGTH_ERROR', {
                        amount: numberRound(maxLength),
                        count: maxLength,
                    }),
                },
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'title', placeholder: t('введите название'), hasError: !!errors.title, autoFocus: true }))) }) })));
};
