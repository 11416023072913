import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@/store/slice';
import * as thunks from '@/store/thunk/reports';
import { DashboardWidgetType, } from '@/types/dashboard';
const findAnalyticsTableNode = (key, node) => {
    if (node.key === key || node.groupKey === key)
        return node;
    if (node.children)
        for (let i = 0; i < node.children.length; i += 1) {
            const found = findAnalyticsTableNode(key, node.children[i]);
            if (found !== null)
                return found;
        }
    return null;
};
const getCurrentDashboardWidget = (state, type) => {
    const list = state.dashboards.data;
    const dashboardId = state.activeDashboardId;
    const dashboard = list === null || list === void 0 ? void 0 : list.find((d) => d.id === dashboardId);
    const widget = dashboard === null || dashboard === void 0 ? void 0 : dashboard.widgets.find((w) => w.type === type);
    return { dashboard, widget };
};
const getCurrentDashboardAnalytics = (state) => {
    return getCurrentDashboardWidget(state, DashboardWidgetType.Analytics);
};
const getCurrentDashboardKPITiles = (state) => {
    return getCurrentDashboardWidget(state, DashboardWidgetType.KPITiles);
};
const getNewDashboardId = (dashboards) => {
    const ids = dashboards.map((d) => Math.abs(d.id));
    const next = Math.max(...ids);
    return -(next > 0 ? next + 1 : 1);
};
const getNewDashboardTitle = (dashboards, prefix) => {
    const occupiedTitles = dashboards.map((d) => d.title);
    let i = 1;
    for (;;) {
        const title = prefix + i.toString();
        if (occupiedTitles.indexOf(title) < 0)
            return title;
        i++;
    }
};
const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        dashboards: { status: FetchStatus.Idle, data: [] },
        dashboardsLoaded: false,
        activeDashboardId: null,
        touchedDashboardIds: [],
        sparkline: {},
        chartData: { status: FetchStatus.Idle },
        tableData: { status: FetchStatus.Idle },
    },
    reducers: {
        setActiveDashboard(state, action) {
            state.activeDashboardId = action.payload.id;
        },
        addDashboard(state, action) {
            const list = state.dashboards.data;
            const newDashboard = action.payload;
            if (list) {
                const id = getNewDashboardId(list);
                const title = getNewDashboardTitle(list, newDashboard.title + ' ');
                list.push(Object.assign(Object.assign({}, newDashboard), { id, title }));
                state.activeDashboardId = id;
            }
        },
        updateDashboardFilters(state, action) {
            const list = state.dashboards.data;
            const { dashboardId, filters } = action.payload;
            const dashboard = list === null || list === void 0 ? void 0 : list.find((d) => d.id === dashboardId);
            if (dashboard) {
                dashboard.filters = filters;
                state.touchedDashboardIds.push(dashboard.id);
            }
        },
        updateCurrentMetrics(state, action) {
            const { dashboardId, newMetricIds } = action.payload;
            const sparklineData = state.sparkline[dashboardId];
            const { dashboard, widget } = getCurrentDashboardKPITiles(state);
            if (sparklineData)
                sparklineData.currentMetrics = newMetricIds;
            if (widget)
                widget.configuration.metrics = newMetricIds.map((key) => ({ key }));
            if (dashboard)
                state.touchedDashboardIds.push(dashboard.id);
        },
        toggleChartMetrics(state, action) {
            const { key } = action.payload;
            const { dashboard, widget } = getCurrentDashboardAnalytics(state);
            const found = widget === null || widget === void 0 ? void 0 : widget.configuration.metrics.find((m) => m.key === key);
            widget === null || widget === void 0 ? void 0 : widget.configuration.metrics.forEach((m) => {
                if (m.key === key)
                    m.showOnChart = !m.showOnChart;
            });
            if (!found && widget)
                widget.configuration.metrics.push({ key, showOnChart: true });
            if (dashboard)
                state.touchedDashboardIds.push(dashboard.id);
        },
        updateChartMetrics(state, action) {
            const { keys } = action.payload;
            const { dashboard, widget } = getCurrentDashboardAnalytics(state);
            if (widget)
                widget.configuration.metrics = keys.map((key) => {
                    const m = widget.configuration.metrics.find((m) => m.key === key);
                    const showOnChart = m ? m.showOnChart : true;
                    return { key, showOnChart };
                });
            if (dashboard)
                state.touchedDashboardIds.push(dashboard.id);
        },
        updateTableSettings(state, action) {
            const { dimensions, limit } = action.payload;
            const { dashboard, widget } = getCurrentDashboardAnalytics(state);
            if (widget) {
                widget.configuration.dimensions = dimensions.map((d) => ({ key: d }));
                widget.configuration.tableMaxResults = limit;
            }
            if (dashboard)
                state.touchedDashboardIds.push(dashboard.id);
        },
        resetDashboardDraft(state, action) {
            state.touchedDashboardIds = state.touchedDashboardIds.filter((id) => id !== action.payload.id);
        },
        toggleTableNode(state, action) {
            const { key } = action.payload;
            const root = state.tableData.data;
            if (root) {
                const node = findAnalyticsTableNode(key, root);
                if (node)
                    node.expand = !node.expand;
            }
        },
    },
    extraReducers: (builder) => {
        // fetchDashboardList ------------------------------------------------------
        builder.addCase(thunks.fetchDashboardList.pending, (state) => {
            state.dashboards.status = FetchStatus.Loading;
        });
        builder.addCase(thunks.fetchDashboardList.fulfilled, (state, action) => {
            var _a;
            state.dashboards.status = FetchStatus.Finished;
            if (!state.dashboardsLoaded) {
                if (action.payload)
                    (_a = state.dashboards.data) === null || _a === void 0 ? void 0 : _a.unshift(...action.payload);
                state.dashboardsLoaded = true;
            }
            if (!state.activeDashboardId && action.payload && action.payload[0])
                state.activeDashboardId = action.payload[0].id;
        });
        builder.addCase(thunks.fetchDashboardList.rejected, (state, action) => {
            state.dashboards.status = FetchStatus.Error;
            state.dashboards.error = action.error;
        });
        // deleteDashboard ---------------------------------------------------------
        builder.addCase(thunks.deleteDashboard.fulfilled, (state, action) => {
            const deletedId = action.meta.arg.id;
            const list = state.dashboards.data;
            if (list) {
                const idx = list.findIndex((i) => i.id === deletedId);
                if (idx >= 0)
                    list.splice(idx, 1);
            }
            state.touchedDashboardIds = state.touchedDashboardIds.filter((id) => id !== deletedId);
        });
        // renameDashboard ---------------------------------------------------------
        builder.addCase(thunks.renameDashboard.fulfilled, (state, action) => {
            const renamedId = action.meta.arg.id;
            const newTitle = action.meta.arg.title;
            const list = state.dashboards.data;
            if (list) {
                const idx = list.findIndex((i) => i.id === renamedId);
                if (idx >= 0)
                    list[idx].title = newTitle;
            }
        });
        // saveDashboard -----------------------------------------------------------
        builder.addCase(thunks.saveDashboard.fulfilled, (state, action) => {
            state.touchedDashboardIds = state.touchedDashboardIds.filter((id) => id !== action.meta.arg.id);
        });
        // addDashboard ------------------------------------------------------------
        builder.addCase(thunks.addDashboard.fulfilled, (state, action) => {
            var _a;
            const dashboard = (_a = state.dashboards.data) === null || _a === void 0 ? void 0 : _a.find((d) => d.id === action.meta.arg.id);
            if (dashboard && action.payload) {
                dashboard.id = action.payload.id;
                if (state.activeDashboardId === action.meta.arg.id)
                    state.activeDashboardId = action.payload.id;
            }
            state.touchedDashboardIds = state.touchedDashboardIds.filter((id) => id !== action.meta.arg.id);
        });
        // fetchSparklineDataThunk -------------------------------------------------
        builder.addCase(thunks.fetchSparklineDataThunk.pending, (state, action) => {
            const { dashboardId, metrics } = action.meta.arg;
            if (!state.sparkline[dashboardId])
                state.sparkline[dashboardId] = {
                    currentMetrics: metrics || [],
                    singleValue: { status: FetchStatus.Loading },
                    chartData: { status: FetchStatus.Loading },
                };
            else {
                state.sparkline[dashboardId].singleValue.status = FetchStatus.Loading;
                state.sparkline[dashboardId].chartData.status = FetchStatus.Loading;
            }
        });
        builder.addCase(thunks.fetchSparklineDataThunk.fulfilled, (state, action) => {
            const { dashboardId, metrics } = action.meta.arg;
            const { singleValue, chartData } = action.payload || {};
            state.sparkline[dashboardId].currentMetrics = metrics || [];
            state.sparkline[dashboardId].singleValue.status = FetchStatus.Finished;
            state.sparkline[dashboardId].singleValue.data = singleValue;
            state.sparkline[dashboardId].chartData.status = FetchStatus.Finished;
            state.sparkline[dashboardId].chartData.data = chartData;
        });
        builder.addCase(thunks.fetchSparklineDataThunk.rejected, (state, action) => {
            const { dashboardId } = action.meta.arg;
            state.sparkline[dashboardId].singleValue.status = FetchStatus.Error;
            state.sparkline[dashboardId].singleValue.error = action.error;
            state.sparkline[dashboardId].chartData.status = FetchStatus.Error;
            state.sparkline[dashboardId].chartData.error = action.error;
        });
        // fetchChartData ----------------------------------------------------------
        builder.addCase(thunks.fetchChartData.pending, (state) => {
            state.chartData.status = FetchStatus.Loading;
        });
        builder.addCase(thunks.fetchChartData.fulfilled, (state, action) => {
            state.chartData.status = FetchStatus.Finished;
            state.chartData.data = action.payload;
        });
        builder.addCase(thunks.fetchChartData.rejected, (state, action) => {
            state.chartData.status = FetchStatus.Error;
            state.chartData.error = action.error;
        });
        // fetchTableRoot ----------------------------------------------------------
        builder.addCase(thunks.fetchTableRoot.pending, (state) => {
            state.tableData.status = FetchStatus.Loading;
            state.tableData.data = undefined;
        });
        builder.addCase(thunks.fetchTableRoot.fulfilled, (state, action) => {
            state.tableData.status = FetchStatus.Finished;
            state.tableData.data = {
                expand: false,
                label: 'Итого',
                values: action.payload,
            };
        });
        builder.addCase(thunks.fetchTableRoot.rejected, (state, action) => {
            state.tableData.status = FetchStatus.Error;
            state.tableData.error = action.error;
        });
        // fetchTableNode ----------------------------------------------------------
        builder.addCase(thunks.fetchTableNode.pending, (state, action) => {
            const { parents, key } = action.meta.arg;
            const root = state.tableData.data;
            const parent = !(parents === null || parents === void 0 ? void 0 : parents.length)
                ? root
                : findAnalyticsTableNode(key || '', root);
            if (parent) {
                parent.expand = true;
                parent.loading = true;
                parent.children = [];
            }
        });
        builder.addCase(thunks.fetchTableNode.fulfilled, (state, action) => {
            const { parents, key } = action.meta.arg;
            const root = state.tableData.data;
            const parent = !(parents === null || parents === void 0 ? void 0 : parents.length)
                ? root
                : findAnalyticsTableNode(key || '', root);
            if (parent && Array.isArray(action.payload)) {
                parent.loading = false;
                parent.children = action.payload.map((i) => (Object.assign(Object.assign({}, i), { expand: false, parent, key: [key, i.groupKey].join('-') })));
            }
        });
    },
});
export default reportsSlice.reducer;
export const { setActiveDashboard, addDashboard, updateDashboardFilters, updateCurrentMetrics, toggleChartMetrics, updateChartMetrics, updateTableSettings, resetDashboardDraft, toggleTableNode, } = reportsSlice.actions;
