import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Pills } from '@/components/Pills/Pills';
export const RFMMovementsPanelChartTypeSelect = ({ value, onChange }) => {
    const { t } = useTranslation();
    return (_jsx(Pills, { items: [
            { id: 'all', content: t('Все') },
            { id: 'to', content: t('Куда') },
            { id: 'from', content: t('Откуда') },
        ], activeItemId: value, onChangeActiveItem: onChange }));
};
