import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { TRIGGER_TYPES_MAP } from '@/const/mailing-triggers';
import { Start } from '@/icons/flow/Start';
import { NodeBody } from '../../helpers/NodeBody/NodeBody';
import { NodeTitle } from '../../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../../helpers/NodeWrapper/NodeWrapper';
import styles from './styles.module.scss';
export const TriggerNode = ({ id, data, }) => {
    const { t } = useTranslation();
    const { triggerType, triggerLabel, plusButton: PlusButton } = data;
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, Object.assign({ className: styles.wrapper }, { children: [_jsx(NodeTitle, Object.assign({ className: styles.title, icon: _jsx(Start, {}) }, { children: t('Старт') })), _jsx(NodeBody, Object.assign({ className: styles.body }, { children: triggerLabel || t(TRIGGER_TYPES_MAP[triggerType]) }))] })), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right }), PlusButton && _jsx(PlusButton, { nodeId: id, buttonIsActive: true })] }));
};
