import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, KEY_SPACE_COMMAND, TextNode } from 'lexical';
import { useEffect } from 'react';
import insertSpaceOnSpace from './insertSpaceOnSpace';
import { SpaceNode } from './SpaceNode';
import transformTextNodeToSpace from './transformTextNodeToSpace';
export const SpacePlugin = ({ method = 'transform', }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (method === 'command')
            return editor.registerCommand(KEY_SPACE_COMMAND, insertSpaceOnSpace, COMMAND_PRIORITY_LOW);
        if (method === 'transform')
            return editor.registerNodeTransform(TextNode, transformTextNodeToSpace);
    }, [editor]);
    return null;
};
export { SpaceNode };
