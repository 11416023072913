import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Button } from '@/components/Button2/Button';
import { useGetClients } from '@/hooks/useGetClients';
import { QRCode } from '@/icons/QRCode';
import { ClientWalletsLinkModal } from './ClientWalletsLinkModal';
export const ClientWalletsLinkButton = ({ getClients: providedGetClients, deviceType }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const getClients = useGetClients();
    const handleToggleModal = useCallback(() => {
        setModalIsOpen((isOpen) => !isOpen);
    }, []);
    const handleCloseModal = useCallback(() => {
        setModalIsOpen(false);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { type: 'button', icon: _jsx(QRCode, {}), onClick: handleToggleModal }), _jsx(ClientWalletsLinkModal, { isOpen: modalIsOpen, onClose: handleCloseModal, getClients: providedGetClients || getClients, deviceType: deviceType })] }));
};
