import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { Check } from '@/icons/Check';
import { Close } from '@/icons/Close';
import { WarningCircle } from '@/icons/WarningCircle';
import styles from './styles.module.scss';
export const Toast = React.memo(({ id, type = 'success', title, message, linkText, linkHref, onClose }) => {
    const [isOpened, setIsOpened] = useState(false);
    const handleClose = useCallback(() => {
        setIsOpened(false);
        setTimeout(() => onClose === null || onClose === void 0 ? void 0 : onClose(id), 150);
    }, [id, onClose]);
    useEffect(() => {
        const timeout = setTimeout(() => setIsOpened(true), 0);
        return () => clearTimeout(timeout);
    }, []);
    useEffect(() => {
        const timeout = setTimeout(handleClose, 6000);
        return () => clearTimeout(timeout);
    }, [handleClose]);
    const content = title ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: styles.message }, { children: message })), !!linkText && !!linkHref && (_jsx("a", Object.assign({ className: styles.link, href: linkHref }, { children: linkText })))] })) : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.icon }, { children: [type === 'success' && _jsx(Check, {}), type === 'error' && _jsx(WarningCircle, {})] })), _jsx("div", Object.assign({ className: styles.message }, { children: message }))] }));
    return (_jsxs("div", Object.assign({ className: classnames(styles.toast, styles[type], {
            [styles.withTitle]: !!title,
            [styles.opened]: isOpened,
        }) }, { children: [content, _jsx("button", Object.assign({ className: styles.close, type: 'button', onClick: handleClose }, { children: _jsx(Close, {}) }))] })));
});
