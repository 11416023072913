import { useTranslation } from 'react-i18next';
import { dateSample } from '../utils/dateSample';
// CLIENT ----------------------------------------------------------------------
const useClientVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('ИмяКлиента'),
            label: t('CUSTOMER_NAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_NAME'),
            key: 'clientName',
        },
        {
            id: t('ИмяОтчествоКлиента'),
            label: t('CUSTOMER_FULLNAME_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER_FULLNAME'),
            key: 'clientNameWithPatronymic',
        },
        {
            id: t('ИмяОтчествоФамилия'),
            label: t('CUSTOMER_PARAMETER'),
            sample: t('VAR_SAMPLE_CUSTOMER'),
            key: 'clientNameWithPatronymicAndSurname',
        },
    ];
};
// PROGRAM ---------------------------------------------------------------------
const useProgramVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('БонусовНаСчету'),
            label: t('кол-во бонусов на счету'),
            sample: 150,
            key: 'clientBonuses',
        },
        {
            id: t('ОжидаемыхБонусов'),
            label: t('кол-во ожидаемых бонусов'),
            sample: 200,
            key: 'clientPendingBonuses',
        },
        {
            id: t('БлижайшаяДатаСгоранияБонусов'),
            label: t('ближайшая дата сгорания бонусов'),
            sample: dateSample,
            sampleType: 'date',
            key: 'expressExpiredAt',
            parameters: [{ code: 'dateFormat', type: 'date-format' }],
        },
        {
            id: t('УровеньКлиента'),
            label: t('уровень клиента'),
            sample: 'gold',
            key: 'clientLevelName',
        },
        {
            id: t('СуммаДоУровня'),
            label: t('сумма до следующего уровня'),
            sample: 2350,
            sampleType: 'price',
            key: 'amountToNextLevel',
        },
        {
            id: t('СуммаПокупок'),
            label: t('сумма покупок'),
            sample: 4580,
            sampleType: 'price',
            key: 'totalSpentAmount',
        },
    ];
};
// PROMOCODE -------------------------------------------------------------------
const usePromocodeVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('Промокод'),
            label: t('промокод'),
            sample: 'CODE-XXX',
            key: 'promocode',
            parameters: [{ code: 'promocodeId', type: 'promocode' }],
        },
    ];
};
// COUNTER ---------------------------------------------------------------------
const useCounterVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('ПокупокНаСчетчике'),
            label: t('N покупок на счетчике'),
            sample: 3,
            key: 'counterAmount',
            parameters: [{ code: 'counterOfferId', type: 'counter' }],
        },
        {
            id: t('ПокупокДоВознаграждения'),
            label: t('N покупок до вознаграждения'),
            sample: 2,
            key: 'counterAmountToReward',
            parameters: [{ code: 'counterOfferId', type: 'counter' }],
        },
        {
            id: t('ДатаСгоранияСчетчика'),
            label: t('дата сгорания счетчика'),
            sample: dateSample,
            sampleType: 'date',
            key: 'counterExpirationDate',
            parameters: [
                { code: 'counterOfferId', type: 'counter' },
                { code: 'dateFormat', type: 'date-format' },
            ],
        },
    ];
};
// OTHER -----------------------------------------------------------------------
const useOtherVariables = () => {
    const { t } = useTranslation();
    return [
        {
            id: t('ПоследнийПуш'),
            label: t('последний пуш'),
            sample: t('WALLET_CARD_MESSAGE_EXAMPLE'),
            key: 'lastPushMessage',
        },
        {
            id: t('НомерКарты'),
            label: t('номер карты'),
            sample: '700 511 526 84 54',
            key: 'cardNumber',
        },
    ];
};
export const useWalletCardFieldEditorVariables = () => {
    const { t } = useTranslation();
    const clientVariables = useClientVariables();
    const programVariables = useProgramVariables();
    const promocodeVariables = usePromocodeVariables();
    const counterVariables = useCounterVariables();
    const otherVariables = useOtherVariables();
    return {
        groups: [
            {
                title: t('Клиент'),
                variables: clientVariables,
            },
            {
                title: t('Программа лояльности'),
                variables: programVariables,
            },
            {
                title: t('Промокод'),
                variables: promocodeVariables,
            },
            {
                title: t('Счетчик'),
                variables: counterVariables,
            },
            ...otherVariables,
        ],
        variables: [
            ...clientVariables,
            ...programVariables,
            ...promocodeVariables,
            ...counterVariables,
            ...otherVariables,
        ],
    };
};
