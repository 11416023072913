var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteFlowMutation } from '@/api/query/flows';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import styles from './styles.module.scss';
export const useDeleteFlow = (onSuccess) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteFlowMutation, { isLoading: isDeletingFlow }] = useDeleteFlowMutation();
    const deleteFlow = (flow) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmAgreeLabel = t('Распустить цепочку');
        const successMessage = t('Цепочка удалена');
        const yes = yield confirm({
            confirmation: (_jsxs(Trans, Object.assign({ i18nKey: 'DELETE_FLOW_TITLE', values: flow }, { children: ["\u0420\u0430\u0441\u043F\u0443\u0441\u0442\u0438\u0442\u044C \u0446\u0435\u043F\u043E\u0447\u043A\u0443 ", _jsx("span", Object.assign({ className: styles.title }, { children: flow.title })), "?"] }))),
            text: (_jsxs(Trans, Object.assign({ i18nKey: 'DELETE_FLOW_DESCRIPTION', values: flow }, { children: [_jsx("span", Object.assign({ className: 'bold' }, { children: flow.title })), " \u0438\u0441\u0447\u0435\u0437\u043D\u0435\u0442 \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430 \u0446\u0435\u043F\u043E\u0447\u0435\u043A, \u043D\u043E \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u0442 \u0440\u0430\u0431\u043E\u0442\u0430\u0442\u044C \u0431\u0435\u0437 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0439"] }))),
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deleteFlowMutation({ id: flow.id });
        if ('error' in result) {
            const { status } = result.error;
            if (!status)
                addToast({ type: 'error', message: getErrorMessage(result.error) });
        }
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
    });
    return { deleteFlow, isDeletingFlow };
};
