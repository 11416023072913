var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorMessage } from '@/api/query';
import { useCreateInvoiceMutation, } from '@/api/query/invoices';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useCreateInvoice = ({ onSuccess }) => {
    const addToast = useToastContext();
    const [createInvoice, { isLoading }] = useCreateInvoiceMutation();
    const handleCreateInvoice = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield createInvoice(payload);
        if ('error' in result)
            addToast({
                type: 'error',
                message: getErrorMessage(result.error),
            });
        else
            onSuccess();
    });
    return { handleCreateInvoice, isLoading };
};
