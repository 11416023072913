import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { MailingStatsModal } from '@/components/MailingStatsModal/MailingStatsModal';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { ROUTES } from '../routes';
import { buildTotal } from './buildTotal';
import { MailingPanel } from './MailingPanel';
import { useDeleteMailing } from './useDeleteMailing';
import { useMailingList } from './useMailingList';
import { useMailingModal } from './useMailingModal';
import { useOpenPurchaseFilter } from './useOpenPurchaseFilter';
export const Mailings = ({ isManual }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const { brand } = useCurrentUser();
    const shouldDisplayProfit = !!(brand === null || brand === void 0 ? void 0 : brand.brand.showProfit);
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const { mailings, isLoading, isFetching } = useMailingList(isManual, searchText);
    const total = buildTotal(mailings || [], shouldDisplayProfit);
    const deleteMailing = useDeleteMailing((brand === null || brand === void 0 ? void 0 : brand.brand.globalKey) || '');
    const { purchaseUrl, openPurchaseFilter } = useOpenPurchaseFilter(isManual);
    const { isModalOpen, openModal, closeModal, selectedMailing, stats, periodStart, periodEnd, changePeriod, isStatsLoading, } = useMailingModal();
    if (!brand)
        return null;
    return (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { label: isManual ? t('Показывать отправленные за') : undefined, leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: t('Название рассылки'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: setSearchText }) }) }, { children: [_jsx(MailingPanel, { mailings: mailings, total: total, isManual: isManual, shouldDisplayProfit: shouldDisplayProfit, currency: currency, isLoading: isLoading, isFetching: isFetching, purchaseUrl: purchaseUrl, onMailingStatsClick: openModal, onEditMailing: (mailing) => {
                    const route = isManual
                        ? ROUTES.MAILINGS_MANUAL_EDIT
                        : ROUTES.MAILINGS_AUTOMATIC_EDIT;
                    const path = generatePath(route, { mailingId: mailing.id.toString() });
                    navigate(path);
                }, onCloneMailing: (mailing) => {
                    const route = isManual
                        ? ROUTES.MAILINGS_MANUAL_CREATE
                        : ROUTES.MAILINGS_AUTOMATIC_CREATE;
                    navigate(route, {
                        state: {
                            clone: true,
                            mailingId: mailing.id,
                        },
                    });
                }, onDeleteMailing: deleteMailing, onOpenPurchaseFilter: openPurchaseFilter, onSegmentClick: (segmentId) => {
                    navigate(ROUTES.CLIENTS, { state: { segmentId } });
                }, onGoToCreateForm: () => {
                    const route = isManual
                        ? ROUTES.MAILINGS_MANUAL_CREATE
                        : ROUTES.MAILINGS_AUTOMATIC_CREATE;
                    navigate(route);
                } }), !!selectedMailing && !!stats && (_jsx(MailingStatsModal, { isOpen: isModalOpen, onClose: closeModal, periodStart: periodStart, periodEnd: periodEnd, onChangePeriod: changePeriod, mailing: selectedMailing, stats: stats, isManual: isManual, currency: currency, isLoading: isStatsLoading }))] })));
};
