var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useNavigate } from 'react-router-dom';
import { WalletCardsTable as WalletCardsTableComponent } from '@/components/wallet-cards/WalletCardsTable/WalletCardsTable';
import { ROUTES } from '../routes';
import { useCloneWalletCard } from '../WalletCardsForm/useCloneWalletCard';
import { useLevels } from './useLevels';
import { useWalletCardList } from './useWalletCardList';
export const WalletCardsTable = ({ activeCardLevel, onChangeActiveCardLevel, }) => {
    const navigate = useNavigate();
    const { levels, isFetching: isFetchingLevels } = useLevels();
    const { data, walletCards, isFetching: isFetchingCards } = useWalletCardList();
    const { cloneCard } = useCloneWalletCard();
    const isFetching = isFetchingLevels || isFetchingCards;
    return (_jsx(WalletCardsTableComponent, { levels: levels, cards: walletCards, onCreateCard: (levelId, copyFromLevelId) => __awaiter(void 0, void 0, void 0, function* () {
            if (copyFromLevelId !== undefined) {
                const newCardId = yield cloneCard(Number(levelId), Number(copyFromLevelId), data === null || data === void 0 ? void 0 : data.walletCards);
                if (newCardId !== null) {
                    const path = generatePath(ROUTES.WALLET_CARDS_EDIT, {
                        levelId: levelId.toString(),
                        cardId: newCardId.id.toString(),
                    });
                    navigate(path, {
                        state: { defaultWalletCardValues: newCardId },
                    });
                }
                return;
            }
            const path = generatePath(ROUTES.WALLET_CARDS_CREATE, {
                levelId: levelId.toString(),
            });
            if (path)
                navigate(path);
        }), onEditCard: (levelId) => {
            var _a;
            const card = walletCards.find((card) => card.levelId == levelId);
            const path = generatePath(ROUTES.WALLET_CARDS_EDIT, {
                levelId: levelId.toString(),
                cardId: ((_a = card === null || card === void 0 ? void 0 : card.id) === null || _a === void 0 ? void 0 : _a.toString()) || '',
            });
            navigate(path);
        }, isLoading: isFetching, onCardEnter: (index) => {
            if (levels[index]) {
                const selectedLevel = levels[index].id;
                if (selectedLevel)
                    onChangeActiveCardLevel(selectedLevel);
            }
        }, activeCardLevel: activeCardLevel }));
};
