var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useCreatePromocodeMutation, useUpdatePromocodeMutation, } from '@/api/query/promocodes';
import { useToastContext } from '@/components/Toast/ToastContext';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_PERSONAL, PROMOCODE_TYPE_SHARED, } from '@/const/promocode';
import { compileFilters } from '@/services/filters/compileFilters';
export const useSavePromocode = ({ id, section, onSuccess, }) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const addToast = useToastContext();
    const [createPromocode, { isLoading: isCreating }] = useCreatePromocodeMutation();
    const [updatePromocode, { isLoading: isUpdating }] = useUpdatePromocodeMutation();
    const savePromocode = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        let successMessage = id ? t('Промокод изменён') : t('Промокод создан');
        if (section === 'friend')
            successMessage = id ? t('Код изменён') : t('Код создан');
        let result;
        let codeType;
        if (payload.codeType === 'friend')
            codeType = PROMOCODE_TYPE_FRIEND;
        if (payload.codeType === 'personal')
            codeType = PROMOCODE_TYPE_PERSONAL;
        if (payload.codeType === 'shared')
            codeType = PROMOCODE_TYPE_SHARED;
        const body = Object.assign(Object.assign({}, payload), { code: payload.code.trim(), codeType, allowedCategories: {
                configuration: payload.allowedCategories,
                conditions: compileFilters(payload.allowedCategories),
            }, conditions: {
                configuration: payload.conditions,
                conditions: compileFilters(payload.conditions),
            } });
        if (id)
            result = yield updatePromocode({ id, payload: body });
        else
            result = yield createPromocode(body);
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
    return {
        savePromocode,
        isSubmitting: isCreating || isUpdating,
        error,
    };
};
