import { FetchStatus } from '@/store/slice';
export const buildProductAttributes = (productAttributesKeys, productAttributesValues, loadOptions) => {
    return (productAttributesKeys.data || []).map((attr) => {
        const { data, offset, hasMoreData, status } = productAttributesValues[attr] || {};
        const options = data === null || data === void 0 ? void 0 : data.reduce((acc, value) => {
            acc[value] = value;
            return acc;
        }, {});
        return {
            type: 'leaf',
            id: `purchaseItemAttribute.${attr}`,
            code: 'purchaseItemAttribute',
            label: attr,
            template: 'checkboxes-paginated-loader',
            loadOptions: ({ limit, offset, searchText, ids }) => loadOptions({
                limit,
                offset,
                searchText,
                attributeValues: ids === null || ids === void 0 ? void 0 : ids.map((i) => i.toString()),
                attributeKey: attr,
            }),
            options: {
                options,
                offset,
                hasMoreData,
                isLoadingMoreData: status === FetchStatus.Loading,
            },
            extra: { key: attr },
        };
    });
};
