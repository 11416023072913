var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteGiftCardMutation } from '@/api/query/giftCard';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useDeleteGiftCard = ({ onSuccess }) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteGiftCard] = useDeleteGiftCardMutation();
    return (id, name) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmTitle = name
            ? t('Удалить подарочную карту {{name}}?', { name })
            : t('Удалить подарочную карту?');
        const confirmAgreeLabel = t('Удалить карту');
        const successMessage = t('Подарочная карта удалена');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deleteGiftCard(id);
        if ('error' in result)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
};
