import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { SegmentBadge } from '@/components/SegmentBadge/SegmentBadge';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Table } from '@/components/Table/Table';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const SegmentStatsPanel = memo(({ segments, allClientsSegment, currency, onSegmentClick, isProfitVisible = false, isLoading = false, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const isSegmentLinksEnabled = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_LIST',
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const columns = useMemo(() => [
        {
            id: 'segment',
            title: t('Сегменты клиентов'),
            tooltip: t('TOOLTIP_SEGMENTS'),
        },
        {
            id: 'profit-or-revenue',
            title: isProfitVisible ? t('Прибыль') : t('Выручка'),
            align: 'right',
        },
        {
            id: 'avgAmount',
            title: t('Средний чек'),
            align: 'right',
        },
        {
            id: 'avgExpense',
            title: t('Стоимость покупателя'),
            tooltip: t('Средняя сумма затрат на получение одной покупки с применением программы лояльности. Учитываются затраты на рассылки, скидки и списание баллов'),
            align: 'right',
        },
    ], [t, isProfitVisible]);
    const rows = useMemo(() => [
        {
            id: 'all-clients',
            cells: [
                _jsx(SegmentBadge, { children: t('Все клиенты') }, 'segment'),
                money({
                    input: isProfitVisible
                        ? allClientsSegment.profit
                        : allClientsSegment.revenue,
                    currency,
                }),
                money({ input: allClientsSegment.avgAmount, currency }),
                money({ input: allClientsSegment.avgExpense, currency }),
            ],
        },
        ...segments.map((segment) => ({
            id: segment.id,
            cells: [
                _jsx(SegmentBadgeContainer, Object.assign({ onClick: isSegmentLinksEnabled
                        ? () => onSegmentClick(segment)
                        : undefined, segment: segment, isFullSize: true }, { children: segment.name }), 'segment'),
                money({
                    input: isProfitVisible ? segment.profit : segment.revenue,
                    currency,
                }),
                money({ input: segment.avgAmount, currency }),
                money({ input: segment.avgExpense, currency }),
            ],
        })),
    ], [t, segments, allClientsSegment, onSegmentClick, isProfitVisible]);
    return (_jsx(StatsPanel, Object.assign({ isLoading: isLoading }, { children: _jsx(Table, { className: styles.table, columns: columns, rows: rows, size: 'small', needAdaptive: false }) })));
});
