import { jsx as _jsx } from "react/jsx-runtime";
import { Pills } from '@/components/Pills/Pills';
import { LogoSmall as Apple } from '@/icons/wallet-card/apple/LogoSmall';
import { LogoSmall as Google } from '@/icons/wallet-card/google/LogoSmall';
import { MessageBubble } from '@/icons/wallet-card/notify/MessageBubble';
export const WalletCardPills = ({ value = 'apple', onChange, }) => {
    return (_jsx(Pills, { items: [
            { id: 'apple', content: _jsx(Apple, {}) },
            { id: 'google', content: _jsx(Google, {}) },
            { id: 'notify', content: _jsx(MessageBubble, {}) },
        ], activeItemId: value, onChangeActiveItem: onChange, asIcons: true }));
};
