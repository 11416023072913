import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useUserPermissions } from '@/app/useUserPermissions';
import { IS_SNOWY_DATES } from '@/const/general';
import { SidebarState } from '@/icons/header/SidebarState';
import { SnowDivider } from '@/icons/SnowDivider';
import { buildAppNav } from './AppNavData';
import { AppNavSectionDropdown } from './AppNavSectionDropdown';
import { filterItems } from './filterItems';
import { findActiveSectionId } from './findActiveSectionId';
import styles from './styles.module.scss';
export const AppNav = memo(({ isCollapsed = false, currentRoute, brand, onToggle, onChangeRoute }) => {
    const userHasAccessTo = useUserPermissions();
    const nav = buildAppNav(isCollapsed, brand, userHasAccessTo);
    const [activeSectionId, setActiveSectionId] = useState(findActiveSectionId(nav, currentRoute));
    useEffect(() => {
        setActiveSectionId(findActiveSectionId(nav, currentRoute));
    }, [currentRoute, isCollapsed]);
    return (_jsxs(_Fragment, { children: [_jsx("nav", Object.assign({ className: classNames(styles.nav, {
                    [styles.collapsed]: isCollapsed,
                    [styles.navMaxHeightSnowyDates]: IS_SNOWY_DATES,
                }) }, { children: nav.map((group, groupIndex) => (_jsxs("div", { children: [_jsx("ul", Object.assign({ className: styles.sections }, { children: filterItems(group).map((section) => (_jsx(AppNavSectionDropdown, { section: section, isActive: section.id === activeSectionId, onClick: !isCollapsed ? setActiveSectionId : undefined, currentRoute: currentRoute, onChangeRoute: onChangeRoute, isCollapsed: isCollapsed }, section.id))) })), groupIndex !== nav.length - 1 && (_jsx(_Fragment, { children: IS_SNOWY_DATES && !isCollapsed ? (_jsx(SnowDivider, { className: styles.snowDivider })) : (_jsx("div", { className: styles.divider })) }))] }, group[0].id))) })), _jsx("button", Object.assign({ className: classNames(styles.toggle, {
                    [styles.open]: isCollapsed,
                }), onClick: onToggle }, { children: _jsx(SidebarState, {}) }))] }));
});
