import { api } from './';
export const rfmApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBrandRFMSegments: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/rfm-segments',
            }),
            providesTags: ['RFMSegments'],
        }),
        getRFMSegmentList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/segment/list-rfm',
                body,
            }),
            providesTags: ['RFMSegmentList'],
        }),
        getRFMTable: build.query({
            query: () => ({
                method: 'POST',
                url: '/rfm/list',
            }),
            providesTags: ['RFMTable'],
        }),
        getRFMHistory: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/rfm/history',
                body,
            }),
            providesTags: ['RFMHistory'],
        }),
        getRFMMovementList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/rfm/movements',
                body,
            }),
            providesTags: ['RFMMovementList'],
        }),
    }),
});
export const { useGetBrandRFMSegmentsQuery, useGetRFMSegmentListQuery, useGetRFMTableQuery, useGetRFMHistoryQuery, useGetRFMMovementListQuery, } = rfmApi;
