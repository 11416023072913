import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ChevronLeft } from '@/icons/ChevronLeft';
import styles from './styles.module.scss';
export const PanelTitle = ({ title, onClose }) => {
    if (!title)
        return null;
    return (_jsxs("div", Object.assign({ className: classNames(styles.title, {
            [styles.closable]: !!onClose,
        }) }, { children: [onClose && (_jsx("div", Object.assign({ className: styles.closeIcon, onClick: onClose }, { children: _jsx(ChevronLeft, {}) }))), title] })));
};
