import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useNavigate } from 'react-router-dom';
import { GeopushList } from '@/components/GeopushList/GeopushList';
import { ROUTES } from '../routes';
import { useDeleteGeoPush } from './useDeleteGeoPush';
import { useToggleGeoPush } from './useToggleGeoPush';
import { useWalletCardList } from './useWalletCardList';
export const GeoPushTable = ({ activeGeoPush, onChangeActiveGeoPush, displayFetchingState = false, }) => {
    const navigate = useNavigate();
    const { walletGeos, walletGeosNotDeleted, isFetching } = useWalletCardList();
    const { toggleGeoPush } = useToggleGeoPush();
    const deleteGeoPush = useDeleteGeoPush();
    const handleAdd = () => {
        navigate(ROUTES.GEO_PUSH_CREATE);
    };
    const handleEdit = (id) => {
        const path = generatePath(ROUTES.GEO_PUSH_EDIT, {
            geoPushId: id,
        });
        navigate(path);
    };
    const handleCopy = (id) => {
        navigate(ROUTES.GEO_PUSH_CREATE, {
            state: {
                clone: true,
                geoPushId: id,
            },
        });
    };
    return (_jsx(GeopushList, { items: walletGeosNotDeleted, onAdd: handleAdd, onEdit: handleEdit, onMakeCopy: handleCopy, onDelete: deleteGeoPush, onToggle: toggleGeoPush, isLoading: displayFetchingState && isFetching, onItemEnter: (index) => {
            if (walletGeos[index])
                onChangeActiveGeoPush(walletGeos[index]);
        }, activeItemId: activeGeoPush === null || activeGeoPush === void 0 ? void 0 : activeGeoPush.id }));
};
