import { api } from './';
export const ordersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOrderList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/order/list',
                body,
            }),
            providesTags: ['OrderList'],
        }),
        getCancelledOrderList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/order/list',
                body: Object.assign(Object.assign({}, body), { filters: Object.assign(Object.assign({}, (body.filters || {})), { orderStatusEq: 2 }) }),
            }),
            providesTags: ['OrderListCancelled'],
        }),
        getOrder: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/order/get/${id}`,
            }),
            providesTags: ['Order'],
        }),
        cancelOrder: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/order/cancel',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'Order'],
        }),
        confirmOrder: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/order/confirm',
                body,
            }),
            invalidatesTags: ['PurchaseCount', 'Order'],
        }),
    }),
});
export const { useGetOrderListQuery, useGetCancelledOrderListQuery, useGetOrderQuery, useCancelOrderMutation, useConfirmOrderMutation, } = ordersApi;
