import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { dashboardListSelector } from '@/store/selectors/reports';
import { fetchDashboardList } from '@/store/thunk/reports';
export const useDashboardsList = (addItem) => {
    const dispatch = useAppDispatch();
    const { data: dashboards } = useAppSelector(dashboardListSelector);
    useEffect(() => {
        dispatch(fetchDashboardList()).then((result) => {
            const list = result.payload;
            if (!(list === null || list === void 0 ? void 0 : list.length) && !(dashboards === null || dashboards === void 0 ? void 0 : dashboards.length))
                addItem();
        });
    }, []);
    return { dashboards };
};
