import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WalletCardBarCode } from '@/components/wallet-cards/WalletCardBarCode/WalletCardBarCode';
import { WalletCardPhone } from '@/components/wallet-cards/WalletCardPhone/WalletCardPhone';
import { GoogleWalletCardBanner } from './GoogleWalletCardBanner';
import { GoogleWalletCardDetails } from './GoogleWalletCardDetails';
import { GoogleWalletCardFields } from './GoogleWalletCardFields';
import { GoogleWalletCardHeader } from './GoogleWalletCardHeader';
import { GoogleWalletCardTitle } from './GoogleWalletCardTitle';
import styles from './styles.module.scss';
export const GoogleWalletCard = ({ background, headerImageUrl = '', header = '', titleLabel = '', title = '', fields = {}, bannerImageUrl = '', bannerImageTitle = '', bannerImageTitleColor = '', bannerImageTitleSize = '', bannerImageDescription = '', bannerImageDescriptionColor = '', bannerImageDescriptionSize = '', details = [], contacts = [], backsideImageUrl = '', barCode = '', onElementClick, clicksDisabled = false, flipped = false, onChangeFlipped, counter, counterTickImageUrl, counterBlankImageUrl, }) => {
    return (_jsxs(WalletCardPhone, Object.assign({ type: 'google', backside: _jsx(GoogleWalletCardDetails, { imageUrl: backsideImageUrl, items: details, contacts: contacts, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), flipped: flipped, onChangeFlipped: onChangeFlipped, background: background }, { children: [_jsx(GoogleWalletCardHeader, { imageUrl: headerImageUrl, title: header, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx(GoogleWalletCardTitle, { titleLabel: titleLabel, title: title, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsx(GoogleWalletCardFields, { fields: fields, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), !!barCode && (_jsx(WalletCardBarCode, { card: 'google', type: barCode, onClick: !clicksDisabled ? () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('barcode') : undefined }))] })), _jsx(GoogleWalletCardBanner, { imageUrl: bannerImageUrl, imageTitle: bannerImageTitle, imageTitleColor: bannerImageTitleColor, imageTitleSize: bannerImageTitleSize, imageDescription: bannerImageDescription, imageDescriptionColor: bannerImageDescriptionColor, imageDescriptionSize: bannerImageDescriptionSize, onElementClick: onElementClick, clicksDisabled: clicksDisabled, counter: counter, counterTickImageUrl: counterTickImageUrl, counterBlankImageUrl: counterBlankImageUrl })] })));
};
