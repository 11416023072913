import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { RFMChart } from './RFMChart';
import { useChartData } from './useChartData';
export const RFMChartContainer = ({ filters, onOpenSegment, shouldDisplayProfit, isRFMEnabled, }) => {
    const [dataType, setDataType] = useState('clientsCount');
    const [chartType, setChartType] = useState('area');
    const { hasNoData, hasNoSearchResults, areaLabels, areaDatasets, bucketSize, bars, barsTotal, shouldDisplayWrongDateWarning, fixPeriod, } = useChartData(dataType, chartType, filters);
    return (_jsx(RFMChart, { dataType: dataType, onChangeDataType: setDataType, chartType: chartType, onChangeChartType: setChartType, bars: bars, barsTotal: barsTotal, areaLabels: areaLabels, areaDatasets: areaDatasets, bucketSize: bucketSize, onItemClick: onOpenSegment, shouldDisplayProfit: shouldDisplayProfit, isLocked: !isRFMEnabled, hasNoData: hasNoData, hasNoSearchResults: hasNoSearchResults, shouldDisplayWrongDateWarning: shouldDisplayWrongDateWarning, onWrongDateButtonClick: fixPeriod }));
};
