import { useGetBrandQuery } from '@/api/query/brand';
import { useGetUserQuery } from '@/api/query/user';
export const useCurrentUser = () => {
    var _a, _b, _c, _d, _e;
    const { data: user } = useGetUserQuery();
    const { data: brand } = useGetBrandQuery(undefined, {
        skip: !(user === null || user === void 0 ? void 0 : user.isLoggedIn),
    });
    const currency = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.currency) === null || _b === void 0 ? void 0 : _b.symbol) || ((_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.currency) === null || _d === void 0 ? void 0 : _d.unitShort) || '';
    const timeZone = (_e = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _e === void 0 ? void 0 : _e.defaultTimezone;
    const shouldDisplayProfit = !!(brand === null || brand === void 0 ? void 0 : brand.brand.showProfit);
    if (!(user === null || user === void 0 ? void 0 : user.isLoggedIn))
        return {
            user: undefined,
            brand: undefined,
            currency: '',
            timeZone: '',
            shouldDisplayProfit: false,
        };
    return {
        user,
        brand,
        currency,
        timeZone,
        shouldDisplayProfit,
    };
};
