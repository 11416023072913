export const translations = {
    'ru-RU': {
        'labels.merge_tags': '!Параметры',
        'labels.merge_tag_group': 'Группа параметров',
        'labels.merge_rule': 'Правило группировки',
        'labels.available_tags': 'Доступные параметры',
        'editor.button_link.label': 'Ссылка кнопки',
        'labels.stock_photos_by': 'Все изображения бесплатны',
        'labels.stock_photos_license': 'для коммерческого использования.',
        'tools.tabs.content': 'Контент',
        'tools.tabs.body': 'Настройки',
        'tools.tabs.images': 'Фото',
        'content_tools.divider': 'Линия',
        'content_tools.image': 'Графика',
        'editor.inherit_body_styles.label': 'Стандартное оформление',
        'editor.container_padding.label': 'Отступ внутри контейнера',
        'editor.alignment.label': 'Выравнивание текста',
        'editor.hide_on_desktop.label': 'Скрыть для настольных компьютеров',
        'shapes.round': 'Закруглённый',
        'buttons.apply_effects': 'Применить эффекты',
        'buttons.filter': 'Фильтры',
        'buttons.resize': 'Размер',
        'buttons.draw': 'Рисовать',
        'buttons.merge': 'Склеить',
        'buttons.stickers': 'Стикеры',
        'buttons.shapes': 'Фигуры',
        'option_groups.labels.title': 'Подписи',
        'editor.labels_color.label': 'Цвет подписей',
        'editor.labels_font.label': 'Шрифт подписей',
        'editor.labels_font_size.label': 'Размер шрифта подписей',
    },
};
