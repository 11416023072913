import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EmojiPopup as EmojiPopupComponent } from '@/components/rich-text/EmojiPopup/EmojiPopup';
import { INSERT_EMOJI } from './insertEmoji';
export const EmojiPopup = ({ onClose }) => {
    const [editor] = useLexicalComposerContext();
    const handleSelectEmoji = (emoji) => {
        editor.dispatchCommand(INSERT_EMOJI, emoji);
    };
    return (_jsx(EmojiPopupComponent, { onSelectEmoji: handleSelectEmoji, onClose: onClose }));
};
