import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TextArea } from '@/components/Input/TextArea';
export const FormUsersComment = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (_jsx(FormFieldRow, Object.assign({ label: t('Комментарий'), labelFor: 'comment', controlSize: '360', help: t('видят только администраторы') }, { children: _jsx(Controller, { name: 'comment', control: control, render: ({ field }) => (_jsx(TextArea, Object.assign({}, field, { id: 'comment', placeholder: t('введите комментарий'), rows: 3 }))) }) })));
};
