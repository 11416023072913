import { matchPath, useLocation } from 'react-router-dom';
import { ROUTE_NAMES } from './routeNames';
export const useRouteName = () => {
    const location = useLocation();
    const entries = Object.entries(ROUTE_NAMES);
    for (let i = 0; i < entries.length; i += 1) {
        const [route, routeName] = entries[i];
        const match = hasMatch(route, location.pathname);
        if (match)
            return routeName;
    }
    return '';
};
const hasMatch = (route, pathname) => {
    if (pathname.includes('geo-push') && !route.includes('geo-push'))
        return false;
    return matchPath(route, pathname);
};
