import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getProductAttributesKeysSelector, getProductPropertiesSelector, getProductsOptionsSelector, } from '@/store/selectors/filters';
import { getProductAttributesValuesSelector } from '@/store/selectors/productAttributes';
import { getProductAttributesKeysThunk, getProductAttributesValuesThunk, getProductPropertiesThunk, getProductsBranchThunk, getProductsTreeThunk, } from '@/store/thunk/filters';
import { buildProductAttributes } from '../buildProductAttributes';
import { buildProductProperties } from '../buildProductProperties';
export const useProductFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getProductPropertiesThunk());
        dispatch(getProductAttributesKeysThunk());
    }, []);
    const { currency } = useCurrentUser();
    const products = useAppSelector(getProductsOptionsSelector);
    const productProperties = useAppSelector(getProductPropertiesSelector);
    const productAttributes = useAppSelector(getProductAttributesKeysSelector);
    const productAttributesValues = useAppSelector(getProductAttributesValuesSelector);
    const tree = [
        {
            type: 'branch',
            id: 'purchaseItemBranch',
            label: t('Товар'),
            isInitiallyExpanded: true,
            items: [
                {
                    type: 'leaf',
                    id: 'purchaseItem',
                    label: t('Наименование'),
                    filterTitle: t('Товар'),
                    template: 'checkboxes-tree-loader',
                    loadTree: (config) => dispatch(getProductsTreeThunk(config)),
                    loadBranch: (config) => dispatch(getProductsBranchThunk(config)),
                    options: products,
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemSku',
                    label: t('Артикул'),
                    template: 'textField',
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemPrice',
                    label: t('Цена'),
                    template: 'numRange',
                    inputPostfix: currency,
                    composePostfixToText: true,
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemMarkup',
                    label: t('Наценка'),
                    template: 'numRange',
                    inputRightText: '%',
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemBasicDiscount',
                    label: t('Переоценка'),
                    template: 'numRange',
                    inputRightText: '%',
                },
                {
                    type: 'leaf',
                    id: 'purchaseItemDiscount',
                    label: t('Скидка кассы'),
                    template: 'numRange',
                    inputRightText: '%',
                },
                {
                    type: 'branch',
                    id: 'productProperties',
                    label: t('Свойства'),
                    items: buildProductProperties(productProperties.data || [], t),
                },
                {
                    type: 'branch',
                    id: 'productAttributes',
                    label: t('Атрибуты'),
                    items: buildProductAttributes(productAttributes, productAttributesValues, (args) => dispatch(getProductAttributesValuesThunk(args))),
                },
            ],
        },
    ];
    return tree;
};
