/* eslint-disable no-undef */

// Встроенный в Chrome стандартный Google Translate
// осуществляет изменения DOM, несовместимые с React
//
// Подробнее здесь:
// https://github.com/facebook/react/issues/11538#issuecomment-417504600

const isProd = process.env.NODE_ENV === 'production'

function fixGoogleTranslateIssue() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    const originalInsertBefore = Node.prototype.insertBefore

    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (!isProd)
          console.error('Cannot remove a child from a different parent')

        return child
      }

      return originalRemoveChild.apply(this, arguments)
    }

    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (!isProd)
          console.error(
            'Cannot insert before a reference node from a different parent',
          )

        return newNode
      }

      return originalInsertBefore.apply(this, arguments)
    }
  }
}

fixGoogleTranslateIssue()
