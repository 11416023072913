import { api } from './';
export const dashboardApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDashboardStats: build.query({
            query: (body) => ({
                method: 'post',
                url: '/dashboard/stats',
                body,
            }),
        }),
        getDashboardChart: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/dashboard/chart',
                body,
            }),
        }),
        getDashboardExports: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/dashboard/export',
                body,
            }),
        }),
    }),
});
export const { useGetDashboardChartQuery, useGetDashboardStatsQuery, useGetDashboardExportsMutation, } = dashboardApi;
