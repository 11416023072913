import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { getShopOptionsSelector } from '@/store/selectors/filters';
import { getShopOptionsThunk } from '@/store/thunk/filters';
export const useOfferConditionsFilters = (options = {}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { conditionsProductCount = 0 } = options;
    const { currency } = useCurrentUser();
    const shops = useAppSelector(getShopOptionsSelector);
    const tree = [
        {
            type: 'leaf',
            id: 'purchaseTotalAmount',
            label: t('Сумма покупки'),
            template: 'numRange',
            inputPostfix: currency,
            composePostfixToText: true,
        },
        {
            type: 'leaf',
            id: 'purchaseShop',
            label: t('Место покупки'),
            template: 'checkboxes-loader',
            loadOptions: () => dispatch(getShopOptionsThunk()),
            options: shops,
        },
        {
            type: 'leaf',
            id: 'counter',
            label: t('Счетчик'),
            template: 'counter',
            hideTitle: true,
            help: (_jsxs(Trans, Object.assign({ i18nKey: 'COUNTER_FILTER_HELP' }, { children: ["\u0414\u043B\u044F \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438 \u0430\u043A\u0446\u0438\u0439 N-\u0439 \u0442\u043E\u0432\u0430\u0440 \u0438\u043B\u0438 \u043F\u043E\u043A\u0443\u043F\u043A\u0430 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A.", ' ', _jsx("a", Object.assign({ href: 'https://help.maxma.com/', target: '_blank', rel: 'noreferrer' }, { children: "\u0418\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0438 \u043F\u043E \u043D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0435" }))] }))),
            conditionsProductCount,
        },
    ];
    return tree;
};
