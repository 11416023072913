export const fontSizeOptions = [
    { label: '12', px: '16' },
    { label: '14', px: '19' },
    { label: '16', px: '21' },
    { label: '18', px: '24' },
    { label: '20', px: '27' },
    { label: '24', px: '32' },
    { label: '28', px: '38' },
    { label: '32', px: '43' },
    { label: '36', px: '49' },
    { label: '42', px: '57' },
    { label: '48', px: '65' },
];
export const getFontSize = (fontSizeLabel) => {
    var _a;
    return (_a = fontSizeOptions.find((s) => s.label === (fontSizeLabel === null || fontSizeLabel === void 0 ? void 0 : fontSizeLabel.toString()))) === null || _a === void 0 ? void 0 : _a.px;
};
