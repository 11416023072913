var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useCorrectClientBonusesMutation } from '@/api/query/bonus';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useCorrectClientBonuses = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [correctClientBonuses, { isLoading }] = useCorrectClientBonusesMutation();
    const [error, setError] = useState('');
    const handleCorrectClientBonuses = (_a) => __awaiter(void 0, void 0, void 0, function* () {
        var { client } = _a, formValues = __rest(_a, ["client"]);
        if (!client)
            return;
        const result = yield correctClientBonuses(Object.assign(Object.assign({ clientId: client.id }, formValues), { lifetime: formValues.lifetime || '', notifyEmail: false }));
        const error = getErrorMessage(result);
        if (error) {
            setError(error);
            return false;
        }
        else {
            setError('');
            addToast({
                type: 'success',
                message: formValues.operation === 'plus'
                    ? t('Бонусы успешно начислены')
                    : t('Бонусы успешно списаны'),
            });
            return true;
        }
    });
    return {
        correctClientBonuses: handleCorrectClientBonuses,
        isLoading,
        error,
    };
};
