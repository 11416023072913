import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetCounterListQuery } from '@/api/query/counters';
import { useCurrentUser } from '@/app/useCurrentUser';
import { FilterListInput } from '@/components/FilterListInput/FilterListInput';
import { FiltersTreeSelect } from '@/components/FiltersTreeSelect/FiltersTreeSelect';
import { FilterConditionsLarge } from '@/icons/flow/FilterConditionsLarge';
import { compileFilters } from '@/services/filters/compileFilters';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { findEdgesFromNode, findNodeByUUID } from '../../nodes/utils';
import { NodeFormDrawer } from '../NodeFormDrawer/NodeFormDrawer';
import commonStyles from '../styles.module.scss';
import { FiltersWarning } from '../Warnings/FiltersWarning';
import styles from './styles.module.scss';
export const ClientFiltersForm = () => {
    var _a;
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const { brand } = useCurrentUser();
    const extraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const { data: counters = [] } = useGetCounterListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const filtersTree = useClientFilters(extraFields, counters);
    const isOpen = selectedNode.type === 'conditions' && (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) === 'filter';
    const handleChangeSelectedCode = (code) => {
        const selectedLeaf = findLeafByCode(filtersTree, code);
        if (selectedLeaf)
            selectedNode.update({
                subheader: selectedLeaf.filterTitle || selectedLeaf.label,
                selectedFilterCode: selectedLeaf.code || selectedLeaf.id,
                configuration: [],
                conditions: [],
            });
    };
    const handleUpdate = (value) => {
        selectedNode.update({
            configuration: value,
            conditions: value.map((savedFilter) => compileFilters([savedFilter])),
        });
    };
    const handleClose = () => {
        selectedNode.reset();
    };
    const thisNode = findNodeByUUID(flow, selectedNode.uuid);
    const edgesFromThisNode = findEdgesFromNode(flow, thisNode);
    const nonDeletableIdx = edgesFromThisNode
        .map((edge) => {
        var _a;
        const sourceHandleIdx = (_a = edge.sourceHandle) === null || _a === void 0 ? void 0 : _a.split('-')[1];
        return sourceHandleIdx ? Number(sourceHandleIdx) : null;
    })
        .filter(notNull);
    return (_jsxs(NodeFormDrawer, Object.assign({ icon: _jsx(FilterConditionsLarge, {}), title: t('Фильтр'), open: isOpen, onClose: handleClose, isLarge: true }, { children: [_jsx("div", Object.assign({ className: styles.selectWrapper }, { children: _jsx(FiltersTreeSelect, { filtersTree: filtersTree, selectedCode: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode) || '', onChange: handleChangeSelectedCode, isDisabled: nonDeletableIdx.length > 0 }) })), _jsx(FilterListInput, { filtersTree: filtersTree, selectedCode: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode) || '', value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration, onChange: handleUpdate, defaultOpenIdx: selectedNode.handleIdx, nonDeletableIdx: nonDeletableIdx }), (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1 && _jsx(FiltersWarning, {}), !!(errors === null || errors === void 0 ? void 0 : errors.configuration) && (_jsx("div", Object.assign({ className: classNames(commonStyles.error, commonStyles.mt12) }, { children: errors.configuration })))] })));
};
