var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useGetBrandProductCatalogsQuery } from '@/api/query/brand';
import { useGetShopTimeZonesQuery } from '@/api/query/shop';
import { FormPointOfSaleModal } from './FormPointOfSaleModal';
import { useAddShop } from './useAddShop';
import { useAddShopGroup } from './useAddShopGroup';
import { useDeleteShop } from './useDeleteShop';
import { useDeleteShopGroup } from './useDeleteShopGroup';
import { useUpdateShop } from './useUpdateShop';
import { useUpdateShopGroup } from './useUpdateShopGroup';
export const FormPointOfSaleContainer = ({ isOpen, onClose, value = {}, onDelete, onAdd, onEdit, shopGroups = [], refetchShopGroups = () => Promise.resolve(), }) => {
    var _a, _b;
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => setError(''), [isOpen]);
    const addShopGroup = useAddShopGroup(refetchShopGroups);
    const updateShopGroup = useUpdateShopGroup(refetchShopGroups);
    const deleteShopGroup = useDeleteShopGroup(refetchShopGroups);
    const addShop = useAddShop();
    const updateShop = useUpdateShop();
    const deleteShop = useDeleteShop();
    const timezoneListQuery = useGetShopTimeZonesQuery();
    const productCatalogListQuery = useGetBrandProductCatalogsQuery();
    const timezoneList = (_a = timezoneListQuery.data) !== null && _a !== void 0 ? _a : [];
    const productCatalogList = (_b = productCatalogListQuery.data) !== null && _b !== void 0 ? _b : [];
    return (_jsx(FormPointOfSaleModal, { isOpen: isOpen, onClose: onClose, isEdit: !!value.id, defaultValues: value, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            const error = values.id
                ? yield updateShop(values)
                : yield addShop(values);
            setIsLoading(false);
            if (error) {
                setError(error);
                return;
            }
            setError('');
            if (values.id)
                onEdit();
            else
                onAdd();
        }), onDelete: (id, name) => __awaiter(void 0, void 0, void 0, function* () {
            setIsLoading(true);
            const deleted = yield deleteShop(id, name);
            setIsLoading(false);
            if (deleted)
                onDelete();
        }), timezoneList: timezoneList, productCatalogList: productCatalogList, shopGroups: shopGroups, onAddShopGroup: addShopGroup, onUpdateShopGroup: updateShopGroup, onDeleteShopGroup: deleteShopGroup, error: error, isLoading: isLoading }));
};
