import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TRIGGER_TYPES_MAP } from '@/const/mailing-triggers';
import { Play } from '@/icons/Play';
import { createCellsList } from './createModalCellsList';
export const useRowsForSublistTable = (mailings = [], sublistItemAction) => {
    const { t } = useTranslation();
    const mailingsByTriggerType = useMemo(() => mailings === null || mailings === void 0 ? void 0 : mailings.reduce((acc, item) => {
        if (acc[item.triggerType]) {
            acc[item.triggerType].sublist.push(item);
            acc[item.triggerType].count += 1;
        }
        else
            acc[item.triggerType] = {
                title: t(TRIGGER_TYPES_MAP[item.triggerType]),
                channels: item.channels,
                id: item.id,
                revenue: item.revenue,
                expenses: item.expenses,
                count: 1,
                sublist: [item],
                involvement: 0,
                date: item.createdAt,
            };
        return acc;
    }, {}), [mailings]);
    const rows = createCellsList({
        isChannels: false,
        data: mailingsByTriggerType,
        hasTitle: true,
        itemPrevTitle: () => _jsx(Play, {}),
    }).map((item, index) => {
        var _a;
        const dataArray = Object.values(mailingsByTriggerType || {});
        const sublist = createCellsList({
            data: (_a = dataArray[index]) === null || _a === void 0 ? void 0 : _a.sublist,
            itemPrevTitle: sublistItemAction,
        });
        return Object.assign(Object.assign({}, item), { sublist });
    });
    return rows;
};
