import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MailingChannelsInput, } from '@/components/MailingChannelsInput/MailingChannelsInput';
export const FormMailingChannels = ({ labelWidth, channelsEnabled, }) => {
    const { t } = useTranslation();
    const { control, formState, setValue } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channelsError = errors.channels;
    const error = (channelsError === null || channelsError === void 0 ? void 0 : channelsError.message) || '';
    const validate = (value) => !(value === null || value === void 0 ? void 0 : value.length) ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormFieldRow, Object.assign({ label: t('Каналы'), labelWidth: labelWidth, controlSize: '400', help: error, hasError: !!error, isRequired: true }, { children: _jsx(Controller, { name: 'channels', control: control, rules: { validate }, render: ({ field }) => (_jsx(MailingChannelsInput, { activeChannels: field.value || [], enabledChannels: channelsEnabled || [], onChangeActiveChannels: (channels) => {
                    setValue('channels', channels, {
                        shouldValidate: isSubmitted,
                    });
                } })) }) })));
};
