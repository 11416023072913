var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useRef, useState } from 'react';
import { useGetClientsEventListMutation } from '@/api/query/client';
import { ALL_DATA_TYPES, INIT_ACTIVE_BUTTONS } from './constants';
const LIMIT_EVENTS = 40;
export const useData = () => {
    const [activeFilters, setActiveFilters] = useState(INIT_ACTIVE_BUTTONS);
    const prevPageParams = useRef({});
    const dataTypesState = useRef(ALL_DATA_TYPES);
    const [getClientsEventList, { isLoading, data }] = useGetClientsEventListMutation();
    const [searchText, setSearchText] = useState('');
    const handleApplySearch = (searchText) => {
        setSearchText(searchText);
        refetchClientsEventList();
    };
    const refetchClientsEventList = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getClientsEventList({
            dataTypes: dataTypesState.current,
            limit: LIMIT_EVENTS,
            filters: {
                phoneNumber: searchText || undefined,
            },
            // TODO: Переделать на даты с контрола
            periodStart: '2016-05-16T00:00:00+03:00',
            periodEnd: '2024-07-20T00:00:00+03:00',
        });
    });
    const handleNextPage = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getClientsEventList(Object.assign({ dataTypes: dataTypesState.current, limit: LIMIT_EVENTS, filters: {
                phoneNumber: searchText || undefined,
            }, 
            // TODO: Переделать на даты с контрола
            periodStart: '2016-05-16T00:00:00+03:00', periodEnd: '2024-07-20T00:00:00+03:00' }, prevPageParams.current));
    });
    const handleChangeFilters = (filter) => {
        // Если выбрано "Все"
        if (filter === 'all') {
            dataTypesState.current = ALL_DATA_TYPES;
            setActiveFilters(INIT_ACTIVE_BUTTONS);
            refetchClientsEventList();
            return;
        }
        // Если фильтр уже выбран
        if (activeFilters[filter]) {
            dataTypesState.current = dataTypesState.current.filter((type) => type !== filter);
            // Если все фильтры выключены включаем "Все"
            if (dataTypesState.current.length === 0) {
                dataTypesState.current = ALL_DATA_TYPES;
                setActiveFilters(INIT_ACTIVE_BUTTONS);
            }
            // Иначе выключаем один фильтр
            else
                setActiveFilters((prev) => (Object.assign(Object.assign({}, prev), { [filter]: false })));
            refetchClientsEventList();
        }
        else {
            if (activeFilters.all)
                dataTypesState.current = [filter];
            else
                dataTypesState.current = [...dataTypesState.current, filter];
            setActiveFilters((prev) => (Object.assign(Object.assign({}, prev), { [filter]: true, all: false })));
            refetchClientsEventList();
        }
    };
    useEffect(() => {
        refetchClientsEventList();
    }, []);
    // Записываем параметры предыдущей страницы которые потребуются для запроса следующей страницы
    prevPageParams.current = {
        createdAtOffset: data === null || data === void 0 ? void 0 : data.createdAtOffset,
        previousPageIds: data === null || data === void 0 ? void 0 : data.previousPageIds,
    };
    return {
        isLoading: isLoading,
        clientsEventList: Object.entries((data === null || data === void 0 ? void 0 : data.events) || {}),
        activeFilters,
        handleNextPage,
        handleApplySearch,
        handleChangeFilters,
        refetchClientsEventList,
    };
};
