import moment from 'moment';
export function registerPreviewHtml(editor) {
    editor.registerCallback('previewHtml', (params, done) => {
        const sample3Days = moment().add(3, 'days').format('D MMM').toLowerCase();
        const sample7Days = moment().add(7, 'days').format('D MMM').toLowerCase();
        // Построение превью со списком товаров.
        // Twig в данном случае не нужен, merge tags и цикл товаров заменяется на указанные значения.
        // sample из настроек mergeTags не используются
        // (в params.html передаётся шаблон с уже заменёнными тегами на sample значения и twig обёрткой для цикла).
        editor.exportHtml((data) => {
            done({ html: data.html });
        }, {
            mergeTags: {
                clientName: 'Виктория',
                clientNamePatronymic: 'Виктория Валерьевна',
                clientSurname: 'Минаева',
                clientPatronymic: 'Валерьевна',
                expiringBonusAmount: '150',
                clientBonuses: '600',
                expressExpiredAt: sample7Days,
                purchaseBonusAmount: '150',
                purchaseBonusExpiredAt: sample3Days,
                returnBonusesCancelled: '150',
                bonusAmount: '150',
                bonusExpiredAt: sample3Days,
                promocode: 'CODE-XXX',
                giftCard: 'T2FXXXX',
                walletsShortLink: 'cl7.ru/no3B',
                childName: 'Катя',
                childNameDat: 'Кате',
                childNameVin: 'Катю',
                // TODO: ссылки в превью режиме остаются как twig шаблон
                //walletsLink: '<a href="#">'
                //+ t('Установить карту Wallet')
                //+ '</a>',
                //unsubscribe: '<a href="#">'
                //+ t('отписаться')
                //+ '</a>',
                products: {
                    repeat: [
                        {
                            productTitle: 'Комбинезон детский',
                            productImage: 'https://s.maxma.com/m/product_image/product1.jpg',
                            productOldPrice: '7399',
                            productPrice: '6899',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Куртка утепленная для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product2.jpg',
                            productOldPrice: '4199',
                            productPrice: '3999',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Кофта для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product3.jpg',
                            productOldPrice: '2099',
                            productPrice: '1899',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Куртка (ветровка) для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product4.jpg',
                            productOldPrice: '4699',
                            productPrice: '4499',
                            productUrl: 'https://maxma.com/',
                        },
                    ],
                },
                recommendedProducts: {
                    repeat: [
                        {
                            productTitle: 'Комбинезон детский',
                            productImage: 'https://s.maxma.com/m/product_image/product1.jpg',
                            productOldPrice: '7399',
                            productPrice: '6899',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Куртка утепленная для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product2.jpg',
                            productOldPrice: '4199',
                            productPrice: '3999',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Кофта для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product3.jpg',
                            productOldPrice: '2099',
                            productPrice: '1899',
                            productUrl: 'https://maxma.com/',
                        },
                        {
                            productTitle: 'Куртка (ветровка) для мальчика',
                            productImage: 'https://s.maxma.com/m/product_image/product4.jpg',
                            productOldPrice: '4699',
                            productPrice: '4499',
                            productUrl: 'https://maxma.com/',
                        },
                    ],
                },
            },
        });
    });
}
