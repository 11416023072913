const cache = {};
export const getImageLightness = (imageUrl, callback) => {
    if (cache[imageUrl]) {
        callback(cache[imageUrl]);
        return;
    }
    const img = document.createElement('img');
    img.src = imageUrl;
    img.style.display = 'none';
    img.crossOrigin = 'anonymous';
    document.body.appendChild(img);
    let colorSum = 0;
    img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(img, 0, 0);
        const imageData = ctx === null || ctx === void 0 ? void 0 : ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData === null || imageData === void 0 ? void 0 : imageData.data;
        let r, g, b, avg;
        if (data)
            for (let x = 0, len = data.length; x < len; x += 4) {
                r = data[x];
                g = data[x + 1];
                b = data[x + 2];
                avg = Math.floor((r + g + b) / 3);
                colorSum += avg;
            }
        const brightness = Math.floor(colorSum / (img.width * img.height));
        cache[imageUrl] = brightness;
        callback(brightness);
    };
};
