import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { FormSegment } from './FormSegment';
import styles from './styles.module.scss';
export const FormSegmentModal = memo(({ id = 'segment-form', isOpen, onClose, error, onSubmit, isSubmitting, isEdit, defaultValues, }) => {
    const { t } = useTranslation();
    const labelWidth = 25;
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const footer = (_jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, asErrorMessage: true, asModalFooter: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', size: 'extra-large', disabled: isSubmitting }, { children: isEdit ? t('Сохранить сегмент') : t('Создать сегмент') })) }))] }));
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, footer: footer, size: '550', isForm: true, isNotificationOfClosureOpen: hasFormChanged, notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormSegment, { id: id, onSubmit: onSubmit, setHasFormChanged: setHasFormChanged, defaultValues: defaultValues }) })));
});
