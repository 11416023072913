import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export const useDashboardMetrics = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        revenue: {
            id: 'revenue',
            label: t `Выручка`,
            color: '#7ABA88',
            valueColor: '#7ABA88',
            tableColor: '#7ABA88',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_revenue'),
        },
        revenueProgram: {
            id: 'revenueProgram',
            label: t `Выручка по ПЛ`,
            color: '#75CC72',
            tableColor: '#7ABA88',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_revenueProgram'),
        },
        // deprecated
        expenses: {
            id: 'expenses',
            label: t `Расходы`,
            color: '#DA5845',
            valueColor: '#DA5845',
            tableColor: '#DA5845',
            scale: 'money',
        },
        expensesFull: {
            id: 'expensesFull',
            label: t `Расходы`,
            color: '#DA5845',
            valueColor: '#DA5845',
            tableColor: '#DA5845',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_expensesFull'),
        },
        expensesPlatform: {
            id: 'expensesPlatform',
            label: t `Затраты на платформу`,
            color: '#F15700',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_expensesPlatform'),
        },
        expensesMailing: {
            id: 'expensesMailing',
            label: t `Затраты на рассылки`,
            color: '#9A141C',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_expensesMailing'),
        },
        discounts: {
            id: 'discounts',
            label: t `Скидки`,
            color: '#B93A33',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_discounts'),
        },
        discountsProgram: {
            id: 'discountsProgram',
            label: t `Скидки по ПЛ`,
            color: '#E75F5A',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_discountsProgram'),
        },
        roi: {
            id: 'roi',
            label: 'ROI',
            color: '#9E20B2',
            scale: 'percent',
            tooltip: t('METRIC_TOOLTIP_roi'),
        },
        avgSale: {
            id: 'avgSale',
            label: t `Средний чек`,
            color: '#6F14C0',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_avgSale'),
        },
        avgSaleProgram: {
            id: 'avgSaleProgram',
            label: t `Средний чек по ПЛ`,
            color: '#8C1AF5',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_avgSaleProgram'),
        },
        avgItemCount: {
            id: 'avgItemCount',
            label: t `Позиций на чек`,
            color: '#41906B',
            scale: 'count',
            precision: 2,
            tooltip: t('METRIC_TOOLTIP_avgItemCount'),
        },
        avgItemCountProgram: {
            id: 'avgItemCountProgram',
            label: t `Позиций на чек по ПЛ`,
            color: '#5FC69A',
            scale: 'count',
            precision: 2,
            tooltip: t('METRIC_TOOLTIP_avgItemCountProgram'),
        },
        markup: {
            id: 'markup',
            label: t `Наценка`,
            color: '#75A635',
            scale: 'percent',
            tooltip: t('METRIC_TOOLTIP_markup'),
        },
        markupProgram: {
            id: 'markupProgram',
            label: t `Наценка по ПЛ`,
            color: '#A3D64F',
            scale: 'percent',
            tooltip: t('METRIC_TOOLTIP_markupProgram'),
        },
        purchaseCount: {
            id: 'purchaseCount',
            label: t `Кол-во чеков`,
            color: '#3F8CBF',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_purchaseCount'),
        },
        purchaseCountProgram: {
            id: 'purchaseCountProgram',
            label: t `Кол-во чеков по ПЛ`,
            color: '#52B5F9',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_purchaseCountProgram'),
        },
        repeatRate: {
            id: 'repeatRate',
            label: t `Процент повторных покупок`,
            color: '#B89330',
            scale: 'percent',
            tooltip: t('METRIC_TOOLTIP_repeatRate'),
        },
        signupCount: {
            id: 'signupCount',
            label: t `Рост базы`,
            color: '#DCDC43',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_signupCount'),
        },
        engagementRate: {
            id: 'engagementRate',
            label: t `Процент применения карт`,
            color: '#FFC300',
            scale: 'percent',
            tooltip: t('METRIC_TOOLTIP_engagementRate'),
        },
        bonusApplied: {
            id: 'bonusApplied',
            label: t `Бонусов списано`,
            color: '#D93224',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_bonusApplied'),
        },
        bonusCollected: {
            id: 'bonusCollected',
            label: t `Бонусов начислено`,
            color: '#F4DA7E',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_bonusCollected'),
        },
        ltv: {
            id: 'ltv',
            label: 'LTV',
            color: '#DC2FF6',
            scale: 'money',
            tooltip: t('METRIC_TOOLTIP_ltv'),
        },
        repeatInterval: {
            id: 'repeatInterval',
            label: t `Дней между покупками`,
            color: '#EAC450',
            scale: 'count',
            precision: 2,
            tooltip: t('METRIC_TOOLTIP_repeatInterval'),
        },
        lifespan: {
            id: 'lifespan',
            label: t `Срок жизни клиента`,
            color: '#E571F8',
            scale: 'count',
            tooltip: t('METRIC_TOOLTIP_lifespan'),
        },
        visitCount: {
            id: 'visitCount',
            label: t `Покупок на клиента`,
            color: '#50A970',
            scale: 'count',
            precision: 2,
            tooltip: t('METRIC_TOOLTIP_visitCount'),
        },
        arppu: {
            id: 'arppu',
            label: t `ARPPU`,
            color: '#08ADB6',
            scale: 'money',
            precision: 2,
            tooltip: t('METRIC_TOOLTIP_arppu'),
        },
    }), [t]);
};
