import { COUNTRY } from './COUNTRY';
export const formatPhoneNumber = (input, separator = '-') => {
    if (!input || typeof input !== 'string')
        return;
    let m;
    if (input.indexOf('+375') === 0)
        m = input.match(/^(.+)(\d{2})(\d{3})(\d{2})(\d{2})$/);
    else if (input.indexOf('+49') === 0)
        m = input.match(/^(\+49)(\d{3,4})(\d{3})(\d{2})(\d{2})$/);
    else
        m = input.match(/^(.+)(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (m)
        return m[1] + ' ' + m[2] + ' ' + m[3] + separator + m[4] + separator + m[5];
    return input;
};
export const getCountryCode = (phone, foundCode) => {
    for (const key in COUNTRY.overridePrefixes) {
        if (!COUNTRY.overridePrefixes.hasOwnProperty(key))
            continue;
        if (phone.indexOf(key) === 0)
            return COUNTRY.overridePrefixes[key];
    }
    return foundCode;
};
export const normalizePhone = (phone, defaultCountry) => {
    if (phone === '')
        return null;
    // remove all non-numbers
    phone = phone.replace(/[^0-9+]+/g, '');
    let phoneLen = phone.length;
    // international number
    if (phone[0] === '+') {
        phone = phone.substring(1);
        phoneLen--;
        for (const key in COUNTRY.phoneFormat) {
            if (!COUNTRY.phoneFormat.hasOwnProperty(key))
                continue;
            const f = COUNTRY.phoneFormat[key];
            if (phone.indexOf(f.prefix) === 0)
                for (let j = 0; j < f.lengths.length; j++) {
                    const l = f.lengths[j];
                    if (phoneLen === l + f.prefix.length)
                        return {
                            normalizedPhone: '+' + phone,
                            countryCode: getCountryCode(phone, key),
                        };
                }
        }
    }
    // country-specific checks
    const f = COUNTRY.phoneFormat[defaultCountry];
    if (!f)
        throw 'unknown country code=' + defaultCountry;
    const prefixMatches = phone.indexOf(f.prefix) === 0;
    for (let j = 0; j < f.lengths.length; j++) {
        const l = f.lengths[j];
        // with prefix, but no plus
        if (prefixMatches && phoneLen === l + f.prefix.length)
            return {
                normalizedPhone: '+' + phone,
                countryCode: defaultCountry,
            };
        // without prefix
        if (phoneLen === l)
            return {
                normalizedPhone: '+' + f.prefix + phone,
                countryCode: defaultCountry,
            };
        // with trunk prefix
        for (let i = 0; i < f.trunkPrefixes.length; i++) {
            const t = f.trunkPrefixes[i];
            if (phone.indexOf(t) === 0 && phoneLen === l + t.length)
                return {
                    normalizedPhone: '+' + f.prefix + phone.substring(t.length),
                    countryCode: defaultCountry,
                };
        }
    }
    return null;
};
export const isValidPhoneNumber = (phone, defaultCountry) => {
    try {
        const result = normalizePhone(phone, defaultCountry);
        return !!result;
    }
    catch (_a) {
        return false;
    }
};
