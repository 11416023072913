import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormInvoiceModal } from '@/forms/FormInvoice/FormInvoiceModal';
import { InvoicesPanel } from './InvoicesPanel';
import { useCreateInvoice } from './useCreateInvoice';
import { useInvoiceList } from './useInvoiceList';
export const BillingInvoices = () => {
    const [modalIsOpened, setModalIsOpened] = useState(false);
    const handleOpenModal = () => setModalIsOpened(true);
    const handleCloseModal = () => setModalIsOpened(false);
    const { brand } = useCurrentUser();
    const { invoices } = useInvoiceList();
    const { handleCreateInvoice, isLoading: isCreatingNewInvoice } = useCreateInvoice({
        onSuccess: handleCloseModal,
    });
    if (!brand)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(InvoicesPanel, { items: invoices, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, onRequestInvoiceClick: handleOpenModal }), _jsx(FormInvoiceModal, { isOpen: modalIsOpened, onClose: handleCloseModal, onSubmit: handleCreateInvoice, isSubmitting: isCreatingNewInvoice })] }));
};
