import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
import { billingPlatformToRows } from './utils/billingPlatformToRows';
export const BillingTable = memo(({ billing = {}, currency, isLoading }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const { t } = useTranslation();
    const billingMailingColumns = [
        {
            id: 'mailing-type',
            title: t('Расходы на рассылки'),
            className: styles.tableFristCell,
        },
        {
            id: 'price',
            title: t('Стоимость сообщения'),
            tooltip: t('Указана окончательная стоимость, вне зависимости от местонахождения клиента. Все сообщения будут отправляться от имени Вашего бренда'),
            align: 'right',
        },
        {
            id: 'num',
            title: t('Количество сообщений'),
            tooltip: t('Количество целевых действий канала, за которое списывается плата, за отчетный период. Для Email и SMS — это отправка сообщения, для Viber — факт доставки сообщения'),
            align: 'right',
        },
        {
            id: 'amount',
            title: t('Сумма'),
            align: 'right',
        },
    ];
    const billingMailingRows = [
        {
            id: 'email',
            cells: [
                'Email',
                money({ input: ((_a = billing.email) === null || _a === void 0 ? void 0 : _a.price) || 0, currency }),
                numberRound(Number(((_b = billing.email) === null || _b === void 0 ? void 0 : _b.num) || 0)),
                money({ input: ((_c = billing.email) === null || _c === void 0 ? void 0 : _c.amount) || 0, currency }),
            ],
        },
        {
            id: 'sms',
            cells: [
                'SMS',
                money({ input: ((_d = billing.sms) === null || _d === void 0 ? void 0 : _d.price) || 0, currency }),
                numberRound(Number(((_e = billing.sms) === null || _e === void 0 ? void 0 : _e.num) || 0)),
                money({ input: ((_f = billing.sms) === null || _f === void 0 ? void 0 : _f.amount) || 0, currency }),
            ],
        },
        {
            id: 'viber',
            cells: [
                'Viber',
                money({ input: ((_g = billing.viber) === null || _g === void 0 ? void 0 : _g.price) || 0, currency }),
                numberRound(Number(((_h = billing.viber) === null || _h === void 0 ? void 0 : _h.num) || 0)),
                money({ input: ((_j = billing.viber) === null || _j === void 0 ? void 0 : _j.amount) || 0, currency }),
            ],
        },
        {
            id: 'total',
            cells: [
                _jsx("b", { children: t('Подытог') }, 'row-header'),
                _jsx("span", { children: "\u2013" }, 'skip'),
                numberRound(Number(((_k = billing === null || billing === void 0 ? void 0 : billing.email) === null || _k === void 0 ? void 0 : _k.num) || 0) +
                    Number(((_l = billing === null || billing === void 0 ? void 0 : billing.sms) === null || _l === void 0 ? void 0 : _l.num) || 0) +
                    Number(((_m = billing === null || billing === void 0 ? void 0 : billing.viber) === null || _m === void 0 ? void 0 : _m.num) || 0)),
                money({
                    input: Number(((_o = billing === null || billing === void 0 ? void 0 : billing.email) === null || _o === void 0 ? void 0 : _o.amount) || 0) +
                        Number(((_p = billing === null || billing === void 0 ? void 0 : billing.sms) === null || _p === void 0 ? void 0 : _p.amount) || 0) +
                        Number(((_q = billing === null || billing === void 0 ? void 0 : billing.viber) === null || _q === void 0 ? void 0 : _q.amount) || 0),
                    currency,
                }),
            ],
        },
    ];
    const billingDailyColumns = [
        {
            id: 'type',
            title: t('Абонентская плата'),
            tooltip: t('Плата за использование платформы за выбранный период'),
            className: styles.tableFristCell,
        },
        {
            id: 'availabilityRange',
            title: t('Период действия'),
            align: 'right',
        },
        {
            id: 'price',
            title: t('Стоимость дня'),
            align: 'right',
        },
        {
            id: 'num',
            title: t('Количество дней'),
            tooltip: t('Количество дней в выбранном расчетном периоде, за которое будет начислена плата, согласно тарифу'),
            align: 'right',
        },
        {
            id: 'amount',
            title: t('Сумма'),
            align: 'right',
        },
    ];
    const billingDailyRows = billingPlatformToRows(billing === null || billing === void 0 ? void 0 : billing.platform, currency);
    return (_jsxs(_Fragment, { children: [_jsx(Table, { columns: billingMailingColumns, rows: billingMailingRows, isLoading: isLoading }), _jsx(Table, { columns: billingDailyColumns, rows: billingDailyRows, isLoading: isLoading })] }));
});
