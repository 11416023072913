import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import { formatDate } from '@/utils/dates';
import { money } from '@/utils/numbers';
import { GiftCardInstanceStatus } from './GiftCardInstanceStatus';
export const GiftCardInstancesTable = memo(({ items, currency, isLoading = false, onActivateInstance, onToggleBlockInstance, }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'number',
            title: t('Номер карты'),
        },
        {
            id: 'code',
            title: t('Код'),
        },
        {
            id: 'status',
            title: t('Статус'),
        },
        {
            id: 'activatedAt',
            title: t('Дата активации'),
        },
        {
            id: 'expiredAt',
            title: t('Дата сгорания'),
        },
        {
            id: 'amount',
            title: t('Остаток'),
            align: 'right',
        },
        {
            id: 'actions',
        },
    ], [t]);
    const rows = useMemo(() => items.map((i) => ({
        id: i.id,
        cells: [
            i.number,
            i.code,
            _jsx(GiftCardInstanceStatus, { status: i.status }, 'status'),
            i.activatedAt ? formatDate(i.activatedAt) : '—',
            i.expiredAt ? formatDate(i.expiredAt) : '—',
            money({ input: i.amount, currency }),
        ],
        actions: [
            {
                id: 'activate',
                label: t('Активировать'),
                onClick: () => onActivateInstance(i),
                shouldDisplay: !i.activatedAt,
            },
            {
                id: 'toggle-block',
                label: i.blockedAt ? t('Разблокировать') : t('Заблокировать'),
                onClick: () => onToggleBlockInstance(i),
                isDanger: true,
            },
        ],
    })), [t, items]);
    return (_jsx(Table, { columns: columns, rows: rows, isLoading: isLoading, hasEmptyState: true }));
});
