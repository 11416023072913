import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ChevronRight } from '@/icons/ChevronRight';
import { Backside } from '@/icons/wallet-card/form/Backside';
import { Banner } from '@/icons/wallet-card/form/Banner';
import { Barcode } from '@/icons/wallet-card/form/Barcode';
import { Fields } from '@/icons/wallet-card/form/Fields';
import { Head } from '@/icons/wallet-card/form/Head';
import { Notifications } from '@/icons/wallet-card/form/Notifications';
import styles from './styles.module.scss';
const iconMap = {
    head: _jsx(Head, {}),
    banner: _jsx(Banner, {}),
    fields: _jsx(Fields, {}),
    notify: _jsx(Notifications, {}),
    backside: _jsx(Backside, {}),
    barcode: _jsx(Barcode, {}),
};
const titleMap = {
    head: 'Шапка',
    banner: 'Баннер',
    fields: 'Поля',
    notify: 'Уведомления',
    backside: 'Обратная сторона',
    barcode: 'Баркод',
};
export const WalletCardFormMenuItem = ({ section, onClick, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.item, onClick: () => onClick === null || onClick === void 0 ? void 0 : onClick(section) }, { children: [_jsxs("div", Object.assign({ className: styles.itemTitle }, { children: [iconMap[section], t(titleMap[section])] })), _jsx("div", Object.assign({ className: styles.itemChevron }, { children: _jsx(ChevronRight, {}) }))] })));
};
