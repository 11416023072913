import { useTranslation } from 'react-i18next';
import { fgColor } from '@/utils/colors';
const getRFMTextColor = (background) => {
    return (fgColor(background, 'rgba(0, 0, 0, 0.6)', '#fff', 180) || 'currentColor');
};
export const useRFMSegments = (data) => {
    const { t } = useTranslation();
    const VIP = {
        id: 'VIP',
        label: t('VIP'),
        code: 'rfm_vip',
        background: '#3A3A3A',
        darkBackground: '#000',
        textColor: getRFMTextColor('#3A3A3A'),
        data: data === null || data === void 0 ? void 0 : data.VIP,
    };
    const potentialVIP = {
        id: 'potentialVIP',
        label: t('Потенциальные VIP'),
        code: 'rfm_pot_vip',
        background: '#7397AB',
        textColor: getRFMTextColor('#7397AB'),
        data: data === null || data === void 0 ? void 0 : data.potentialVIP,
    };
    const loyal = {
        id: 'loyal',
        label: t('Лояльные'),
        code: 'rfm_loyal',
        background: '#9DB2CB',
        textColor: getRFMTextColor('#9DB2CB'),
        data: data === null || data === void 0 ? void 0 : data.loyal,
    };
    const newcomers = {
        id: 'newcomers',
        label: t('Новички'),
        code: 'rfm_new',
        background: '#E0EEF1',
        textColor: getRFMTextColor('#E0EEF1'),
        data: data === null || data === void 0 ? void 0 : data.newcomers,
    };
    const leavingVIP = {
        id: 'leavingVIP',
        label: t('Уходящие VIP'),
        code: 'rfm_maybe_rtl_vip',
        background: '#95615A',
        textColor: getRFMTextColor('#95615A'),
        data: data === null || data === void 0 ? void 0 : data.leavingVIP,
    };
    const potentialLoyal = {
        id: 'potentialLoyal',
        label: t('Потенциально лояльные'),
        code: 'rfm_pot_loyal',
        background: '#DED3C6',
        textColor: getRFMTextColor('#DED3C6'),
        data: data === null || data === void 0 ? void 0 : data.potentialLoyal,
    };
    const outflowPossible = {
        id: 'outflowPossible',
        label: t('Возможный отток'),
        code: 'rfm_maybe_rtl',
        background: '#F9E8C3',
        textColor: getRFMTextColor('#F9E8C3'),
        data: data === null || data === void 0 ? void 0 : data.outflowPossible,
    };
    const outflowVIP = {
        id: 'outflowVIP',
        label: t('VIP отток'),
        code: 'rfm_rtl_vip',
        background: '#DD6B5B',
        textColor: getRFMTextColor('#DD6B5B'),
        data: data === null || data === void 0 ? void 0 : data.outflowVIP,
    };
    const outflow = {
        id: 'outflow',
        label: t('Отток'),
        code: 'rfm_rtl',
        background: '#DFBCA3',
        textColor: getRFMTextColor('#DFBCA3'),
        data: data === null || data === void 0 ? void 0 : data.outflow,
    };
    const oneTimeClients = {
        id: 'oneTimeClients',
        label: t('Разовые клиенты'),
        code: 'rfm_rtl_new',
        background: '#E5E5EB',
        textColor: getRFMTextColor('#E5E5EB'),
        data: data === null || data === void 0 ? void 0 : data.oneTimeClients,
    };
    const ultraflow = {
        id: 'ultraflow',
        label: t('Ультраотток'),
        code: 'rfm_lost',
        background: '#fff',
        textColor: 'currentColor',
        data: data === null || data === void 0 ? void 0 : data.ultraflow,
    };
    const ultraLargeSales = {
        id: 'ultraLargeSales',
        label: t('Ультрабольшие чеки'),
        background: '#fff',
        textColor: 'currentColor',
        data: data === null || data === void 0 ? void 0 : data.ultraflow,
    };
    const ultraActiveCustomers = {
        id: 'ultraActiveCustomers',
        label: t('Ультраактивные покупатели'),
        background: '#fff',
        textColor: 'currentColor',
        data: data === null || data === void 0 ? void 0 : data.ultraflow,
    };
    const possibleFraud = {
        id: 'possibleFraud',
        label: t('Возможный фрод'),
        background: '#fff',
        textColor: 'currentColor',
        data: data === null || data === void 0 ? void 0 : data.ultraflow,
    };
    const wholesalers = {
        id: 'wholesalers',
        label: t('Оптовики'),
        background: '#fff',
        textColor: 'currentColor',
        data: data === null || data === void 0 ? void 0 : data.ultraflow,
    };
    const segments = {
        VIP,
        potentialVIP,
        loyal,
        newcomers,
        leavingVIP,
        potentialLoyal,
        outflowPossible,
        outflowVIP,
        outflow,
        oneTimeClients,
        ultraflow,
        ultraLargeSales,
        ultraActiveCustomers,
        possibleFraud,
        wholesalers,
    };
    return segments;
};
