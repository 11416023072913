import * as triggers from '@/const/mailing-triggers';
import { createNode } from '../createNode';
import { createScheduleNodes } from './schedule';
// -----------------------------------------------------------------------------
export const createConditionNode = (data, hideForLayout = true) => {
    return createNode({
        type: 'conditions',
        data,
        position: {
            x: data.x || 0,
            y: data.y || 0,
        },
        style: {
            opacity: hideForLayout ? 0 : 1,
        },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
// -----------------------------------------------------------------------------
export const sortFilters = (mailings, filters, getFilters, sort = 'asc') => {
    const varsMap = {};
    for (let i = 0; i < mailings.length; i += 1) {
        const mailing = mailings[i];
        const filters = getFilters(mailing);
        for (let j = 0; j < filters.length; j += 1) {
            const { code, text } = filters[j];
            if (!varsMap[code])
                varsMap[code] = [];
            if (!varsMap[code].includes(text))
                varsMap[code].push(text);
            if (!varsMap[code].includes(mailing.id))
                varsMap[code].push(mailing.id);
        }
    }
    const sortedFilters = [...filters];
    // 1) sort alphabetically by code
    sortedFilters.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 0));
    // 2) sort by mailing count
    sortedFilters.sort((a, b) => sort === 'asc'
        ? varsMap[a.code].length - varsMap[b.code].length
        : varsMap[b.code].length - varsMap[a.code].length);
    return sortedFilters;
};
export const getFilterPath = (filters, idx, splitFilterVariantsToDifferentNodes = true) => {
    const filter = filters[idx];
    const filterKey = (filter) => filter.code + JSON.stringify(filter.rawConditions);
    return (filters.slice(0, idx).reduce((path, f) => path + filterKey(f), '') +
        (splitFilterVariantsToDifferentNodes ? filterKey(filter) : filter.code));
};
export const createFilterNodes = (branches, getFilters, createNode, sort = 'asc') => {
    const filterNodes = [];
    const filterEdges = [];
    const filterBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const initialFilters = getFilters(mailing);
                if (!initialFilters.length) {
                    if (!filterBranches[fromNodeId])
                        filterBranches[fromNodeId] = {};
                    if (!filterBranches[fromNodeId][fromHandleId])
                        filterBranches[fromNodeId][fromHandleId] = [];
                    filterBranches[fromNodeId][fromHandleId].push(mailing);
                    continue;
                }
                const sortedFilters = sortFilters(mailings, initialFilters, getFilters, sort);
                for (let j = 0; j < sortedFilters.length; j += 1) {
                    const filter = sortedFilters[j];
                    const currentPath = getFilterPath(sortedFilters, j);
                    if (!nodeMap[currentPath]) {
                        const node = createNode(filter, mailing);
                        nodeMap[currentPath] = node;
                        filterNodes.push(node);
                        if (j === 0)
                            filterEdges.push({
                                id: `from-${fromNodeId}-to-${node.id}`,
                                source: fromNodeId,
                                sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                                target: node.id,
                            });
                        else {
                            const prevPath = getFilterPath(sortedFilters, j - 1);
                            const fromNode = nodeMap[prevPath];
                            const source = fromNode.id;
                            const sourceHandle = sortedFilters[j - 1].text;
                            filterEdges.push({
                                id: `from-${source}-to-${node.id}`,
                                source,
                                sourceHandle,
                                target: node.id,
                            });
                        }
                    }
                    const node = nodeMap[currentPath];
                    if (!node.data.conditions.includes(filter.text))
                        node.data.conditions.push(filter.text);
                    if (j === sortedFilters.length - 1) {
                        if (!filterBranches[node.id])
                            filterBranches[node.id] = {};
                        if (!filterBranches[node.id][filter.text])
                            filterBranches[node.id][filter.text] = [];
                        filterBranches[node.id][filter.text].push(mailing);
                    }
                }
            }
        }
    }
    return {
        filterNodes,
        filterEdges,
        filterBranches,
    };
};
// Trigger Conditions ----------------------------------------------------------
export const createTriggerConditionNode = (filter, mailing) => {
    const code = filter.code;
    return {
        id: `trigger-filter-${code}-${mailing.id}`,
        type: 'conditions',
        data: {
            type: 'trigger',
            conditions: [],
        },
        position: { x: 0, y: 0 },
    };
};
export const getTriggerConditionFilters = (mailing) => {
    var _a;
    const configuration = ((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.configuration) || [];
    const filters = configuration;
    return filters;
};
export const createTriggerConditionNodes = (branches) => {
    const resultAsc = createFilterNodes(branches, getTriggerConditionFilters, createTriggerConditionNode, 'asc');
    const resultDesc = createFilterNodes(branches, getTriggerConditionFilters, createTriggerConditionNode, 'desc');
    const result = resultAsc.filterNodes.length < resultDesc.filterNodes.length
        ? resultAsc
        : resultDesc;
    return {
        triggerConditionNodes: result.filterNodes,
        triggerConditionEdges: result.filterEdges,
        triggerConditionBranches: result.filterBranches,
    };
};
// Recipient Filters -----------------------------------------------------------
export const createRecipientFiltersNode = (filter, mailing) => {
    const code = filter.code;
    return {
        id: `recipient-filters-${code}-${mailing.id}`,
        type: 'conditions',
        data: {
            type: 'filter',
            conditions: [],
        },
        position: { x: 0, y: 0 },
    };
};
export const getRecipientFilters = (mailing) => {
    var _a, _b;
    const configuration = ((_b = (_a = mailing.recipients) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b.configuration) || [];
    const filters = configuration;
    return filters;
};
export const createRecipientFiltersNodes = (branches) => {
    const resultAsc = createFilterNodes(branches, getRecipientFilters, createRecipientFiltersNode, 'asc');
    const resultDesc = createFilterNodes(branches, getRecipientFilters, createRecipientFiltersNode, 'desc');
    const result = resultAsc.filterNodes.length < resultDesc.filterNodes.length
        ? resultAsc
        : resultDesc;
    return {
        recipientFiltersNodes: result.filterNodes,
        recipientFiltersEdges: result.filterEdges,
        recipientFiltersBranches: result.filterBranches,
    };
};
// All Conditions --------------------------------------------------------------
export const createConditionNodes = (triggerType, branches) => {
    let conditionNodes = [];
    let conditionEdges = [];
    let conditionBranches = {};
    // 1) Trigger First
    const triggerFirst_Step1 = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodes(branches)
        : createTriggerConditionNodes(branches);
    const triggerFirst_Step2 = createRecipientFiltersNodes(triggerFirst_Step1.triggerConditionBranches);
    const triggerFirst_Nodes = [
        ...triggerFirst_Step1.triggerConditionNodes,
        ...triggerFirst_Step2.recipientFiltersNodes,
    ];
    const triggerFirst_Edges = [
        ...triggerFirst_Step1.triggerConditionEdges,
        ...triggerFirst_Step2.recipientFiltersEdges,
    ];
    const triggerFirst_Branches = triggerFirst_Step2.recipientFiltersBranches;
    // 2) Recipients First
    const recipientsFirst_Step1 = createRecipientFiltersNodes(branches);
    const recipientsFirst_Step2 = triggerType === triggers.TRIGGER_TYPE_SCHEDULE
        ? createScheduleNodes(recipientsFirst_Step1.recipientFiltersBranches)
        : createTriggerConditionNodes(recipientsFirst_Step1.recipientFiltersBranches);
    const recipientsFirst_Nodes = [
        ...recipientsFirst_Step1.recipientFiltersNodes,
        ...recipientsFirst_Step2.triggerConditionNodes,
    ];
    const recipientsFirst_Edges = [
        ...recipientsFirst_Step1.recipientFiltersEdges,
        ...recipientsFirst_Step2.triggerConditionEdges,
    ];
    const recipientsFirst_Branches = recipientsFirst_Step2.triggerConditionBranches;
    if (recipientsFirst_Nodes.length < triggerFirst_Nodes.length) {
        conditionNodes = recipientsFirst_Nodes;
        conditionEdges = recipientsFirst_Edges;
        conditionBranches = recipientsFirst_Branches;
    }
    else {
        conditionNodes = triggerFirst_Nodes;
        conditionEdges = triggerFirst_Edges;
        conditionBranches = triggerFirst_Branches;
    }
    return { conditionNodes, conditionEdges, conditionBranches };
};
