import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Cart } from '@/icons/header/Cart';
import { Plus } from '@/icons/Plus';
import { User } from '@/icons/User';
import { AppNavDropdown, } from '../AppNavDropdown/AppNavDropdown';
import styles from './styles.module.scss';
export const AppHeaderPlusButton = memo(({ onAddClient, onAddPurchase }) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasClientsManage = userHasAccessTo([
        'CLIENTS_MANAGE',
        'CLIENTS_MANAGE_OWN',
    ]);
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const items = useMemo(() => {
        const client = {
            id: 'add-client',
            icon: _jsx(User, {}),
            label: t('Клиента'),
            onClick: userHasClientsManage ? onAddClient : undefined,
            isDisabled: !userHasClientsManage,
            tooltip: !userHasClientsManage
                ? t('Недоступно для вашей роли')
                : undefined,
            hideTooltipIcon: !userHasClientsManage,
        };
        const purchase = {
            id: 'add-purchase',
            icon: _jsx(Cart, {}),
            label: t('Покупку'),
            onClick: userHasPurchasesManage ? onAddPurchase : undefined,
            isDisabled: !userHasPurchasesManage,
            tooltip: !userHasPurchasesManage
                ? t('Недоступно для вашей роли')
                : undefined,
            hideTooltipIcon: !userHasPurchasesManage,
        };
        return onAddPurchase ? [client, purchase] : [client];
    }, [t, onAddClient, onAddPurchase]);
    return (_jsx(AppNavDropdown, Object.assign({ title: t('Добавить'), items: items, placement: 'bottom-end', offset: [3, -1], onOpen: () => setHovered(true), onClose: () => setHovered(false), triggerType: 'click', leaveTouchDelay: 4000 }, { children: _jsx(Button, Object.assign({ className: styles.plus, isActive: hovered }, { children: _jsx(Plus, {}) })) })));
});
