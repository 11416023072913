var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createHref, ROUTES } from '@/app/pages/routes';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Clock } from '@/icons/Clock';
import { Lock } from '@/icons/Lock';
import { AppNavButton } from '../AppNavButton/AppNavButton';
import { isItemActive } from './isItemActive';
import styles from './styles.module.scss';
export const AppNavItem = memo((_a) => {
    var { currentRoute, onClick } = _a, item = __rest(_a, ["currentRoute", "onClick"]);
    const { name, route, href, tooltip = '' } = item;
    const { t } = useTranslation();
    const handleClick = useCallback(() => {
        if (route)
            onClick(route);
    }, [route, onClick]);
    const itemName = item.isComingSoon ? (_jsxs(_Fragment, { children: [t(name), " ", _jsx(Clock, { className: styles.clockIcon })] })) : (t(name));
    return route ? (_jsx(AppNavButton, Object.assign({ className: styles.item, href: href, isActive: isItemActive(item, currentRoute), onClick: handleClick }, { children: itemName }))) : (_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: t(tooltip), placement: 'right' }, { children: _jsx("span", { children: _jsxs(AppNavButton, Object.assign({ href: href === createHref(ROUTES.RFM) ? href : undefined, className: styles.itemLock, isActive: (name === 'Smart RFM' && href.includes(currentRoute)) || false }, { children: [t(name), _jsx("span", Object.assign({ className: styles.lockIcon }, { children: _jsx(Lock, {}) }))] })) }) })));
});
