import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WalletCardBarCode } from '@/components/wallet-cards/WalletCardBarCode/WalletCardBarCode';
import { WalletCardPhone } from '@/components/wallet-cards/WalletCardPhone/WalletCardPhone';
import { AppleWalletCardBanner } from './AppleWalletCardBanner';
import { AppleWalletCardDetails } from './AppleWalletCardDetails';
import { AppleWalletCardFields } from './AppleWalletCardFields';
import { AppleWalletCardHeader } from './AppleWalletCardHeader';
export const AppleWalletCard = ({ background, color, labelColor, headerImageUrl = '', header = '', fields = {}, bannerImageUrl = '', bannerImageTitle = '', bannerImageTitleColor = '', bannerImageTitleSize = '', bannerImageDescription = '', bannerImageDescriptionColor = '', bannerImageDescriptionSize = '', backsideTitle = '', details = [], contacts = [], barCode = '', onElementClick, clicksDisabled = false, flipped = false, onChangeFlipped, previewKey, counter, counterTickImageUrl, counterBlankImageUrl, }) => {
    return (_jsxs(WalletCardPhone, Object.assign({ cardId: 'apple-card', type: 'apple', backside: _jsx(AppleWalletCardDetails, { title: backsideTitle, details: details, contacts: contacts, previewKey: previewKey, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), flipped: flipped, onChangeFlipped: onChangeFlipped, background: background, color: color }, { children: [_jsx(AppleWalletCardHeader, { imageUrl: headerImageUrl, title: header, fields: fields, labelColor: labelColor, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsx(AppleWalletCardBanner, { imageUrl: bannerImageUrl, imageTitle: bannerImageTitle, imageTitleColor: bannerImageTitleColor, imageTitleSize: bannerImageTitleSize, imageDescription: bannerImageDescription, imageDescriptionColor: bannerImageDescriptionColor, imageDescriptionSize: bannerImageDescriptionSize, onElementClick: onElementClick, clicksDisabled: clicksDisabled, counter: counter, counterTickImageUrl: counterTickImageUrl, counterBlankImageUrl: counterBlankImageUrl }), _jsx(AppleWalletCardFields, { fields: fields, labelColor: labelColor, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), !!barCode && (_jsx(WalletCardBarCode, { card: 'apple', type: barCode, onClick: !clicksDisabled ? () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('barcode') : undefined }))] })));
};
