var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';
export const AccentText = memo((_a) => {
    var { accent = 'default', className, isBold = false, isSmall = false } = _a, props = __rest(_a, ["accent", "className", "isBold", "isSmall"]);
    return (_jsx("span", Object.assign({ className: classNames(styles.text, styles[accent], {
            [styles.bold]: isBold,
            [styles.small]: isSmall,
        }, className) }, props)));
});
