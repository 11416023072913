import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { AccordionTable } from '@/components/AccordionTable/AccordionTable';
import { dataIsOrder } from '../dataIsOrder';
import { TotalRowStructure } from './TotalRowStructure';
import { createHeadItems } from './utils/createHeadItems';
import { tableRows } from './utils/tableRows';
export const StructureTab = memo(({ data, currency }) => {
    const headItems = createHeadItems();
    return (_jsx(AccordionTable, { headItems: headItems, rows: tableRows(currency, data), AdditionalRow: _jsx(TotalRowStructure, { totalAmount: (data === null || data === void 0 ? void 0 : data.totalAmount) || undefined, bonusesCollected: (data === null || data === void 0 ? void 0 : data.bonusesCollected) || undefined, currency: currency, totalDiscount: (data === null || data === void 0 ? void 0 : data.totalDiscount) || undefined, paidAmount: dataIsOrder(data)
                ? data.remainingAmount || undefined
                : data === null || data === void 0 ? void 0 : data.paidAmount }) }));
});
