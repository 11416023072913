import moment from 'moment';
export const getPeriodFromLocation = () => {
    const hash = window.location.hash;
    const [, search] = hash.split('?');
    if (!search)
        return;
    const params = new URLSearchParams(search);
    const periodStart = params.get('periodStart');
    const periodEnd = params.get('periodEnd');
    if (periodStart && periodEnd) {
        const start = moment(periodStart, 'YYYY-MM-DD').startOf('day');
        const end = moment(periodEnd, 'YYYY-MM-DD').endOf('day');
        return { start, end };
    }
};
