import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { ChildInput } from '@/components/ChildInput/ChildInput';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Cancel } from '@/icons/Cancel';
import { Mail } from '@/icons/Mail';
import { MobilePhone } from '@/icons/MobilePhone';
import { Warning } from '@/icons/Warning';
import { emailPattern } from '@/utils/email';
import { numberRound } from '@/utils/numbers';
import { normalizePhone } from '@/utils/phoneNumber';
import styles from './styles.module.scss';
export const FormClient = memo(({ countryCode = '', shops = [], levels = [], extraFields = [], isPhoneDisabled = false, isEmailDisabled = false, isChildrenEnabled = false, maxChildCount = 10, unparsedName, defaultValues = {
    level: levels.length > 0 ? levels[0].level : undefined,
    children: isChildrenEnabled
        ? [
            { name: '', birthdate: '' },
            { name: '', birthdate: '' },
        ]
        : undefined,
    isPhoneSubscribed: true,
    isEmailSubscribed: true,
}, onSubmit, setHasFormChanged, isSubmitting = false, error = '', isEdit = false, onDelete, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, dirtyFields, isSubmitting: isSubmittingForm }, setValue, watch, } = useForm({
        defaultValues: Object.assign(Object.assign({}, defaultValues), { children: !((_a = defaultValues.children) === null || _a === void 0 ? void 0 : _a.length)
                ? [{ name: '', birthdate: '' }]
                : defaultValues.children, extraFields: Array.isArray(defaultValues.extraFields)
                ? {}
                : defaultValues.extraFields }),
    });
    const userHasAccessTo = useUserPermissions();
    const userHasClientsManage = userHasAccessTo([
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmittingForm && !!Object.keys(dirtyFields).length);
    }, [dirtyFields, isSubmitting, setHasFormChanged]);
    const card = watch('card');
    const phoneNumber = watch('phoneNumber');
    const phoneNumberIsRequired = !(card === null || card === void 0 ? void 0 : card.trim());
    const cardIsRequired = !(phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.trim());
    const validatePhoneNumber = (newPhoneNumber) => {
        if (!phoneNumber)
            return true;
        const result = normalizePhone(newPhoneNumber, countryCode);
        return !result ? t('неверный номер телефона') : true;
    };
    const childrenFieldArray = useFieldArray({
        control,
        name: 'children',
    });
    let childFields = childrenFieldArray.fields;
    const appendChild = childrenFieldArray.append;
    const removeChild = childrenFieldArray.remove;
    if (!isChildrenEnabled)
        childFields = [];
    const labelWidth = 32.5;
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(FormFieldset, Object.assign({ title: t('Личные данные'), titleOffset: labelWidth }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Имя и Отчество'), labelFor: 'name', labelWidth: labelWidth, help: ((_b = errors.name) === null || _b === void 0 ? void 0 : _b.message) || ((_c = errors.patronymic) === null || _c === void 0 ? void 0 : _c.message), hasError: !!errors.name || !!errors.patronymic }, { children: _jsxs("div", Object.assign({ className: styles.name }, { children: [_jsx(Controller, { name: 'name', control: control, rules: {
                                        maxLength: {
                                            value: 200,
                                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                                amount: numberRound(200),
                                                count: 200,
                                            }),
                                        },
                                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('NAME_PLACEHOLDER'), hasError: !!errors.name, autoFocus: true }))) }), _jsx(Controller, { name: 'patronymic', control: control, rules: {
                                        maxLength: {
                                            value: 200,
                                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                                amount: numberRound(200),
                                                count: 200,
                                            }),
                                        },
                                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'patronymic', placeholder: t('MIDDLE_NAME_PLACEHOLDER'), hasError: !!errors.patronymic }))) })] })) })), _jsx(FormFieldRow, Object.assign({ label: t('Фамилия'), labelFor: 'surname', labelWidth: labelWidth, controlSize: '200', help: ((_d = errors.surname) === null || _d === void 0 ? void 0 : _d.message) ||
                            (unparsedName ? (_jsxs("span", Object.assign({ className: styles.warning }, { children: [_jsx(Warning, {}), t('имя клиента не распределено по полям:'), unparsedName] }))) : undefined), hasError: !!errors.surname }, { children: _jsx(Controller, { name: 'surname', control: control, rules: {
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'surname', placeholder: t('LAST_NAME_PLACEHOLDER'), hasError: !!errors.surname }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Пол'), labelFor: 'gender', labelWidth: labelWidth, controlSize: '360', alignItems: 'center' }, { children: _jsx(Controller, { name: 'gender', control: control, render: ({ field }) => (_jsx(RadioGroup, Object.assign({}, field, { id: 'gender', value: field.value, onChange: (value) => setValue('gender', Number(value), { shouldDirty: true }), items: [
                                    { value: 2, label: t('женский') },
                                    { value: 1, label: t('мужской') },
                                    { value: 0, label: t('не задан') },
                                ] }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('День рождения'), labelFor: 'birthdate', labelWidth: labelWidth, controlSize: '150' }, { children: _jsx(Controller, { name: 'birthdate', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'birthdate', openToDate: field.value
                                    ? moment(field.value, 'YYYY-MM-DD').toDate()
                                    : moment('01.01.1970', 'DD.MM.YYYY').toDate(), selected: field.value
                                    ? moment(field.value, 'YYYY-MM-DD').toDate()
                                    : null, onChange: (date) => {
                                    setValue('birthdate', date ? moment(date).format('YYYY-MM-DD') : '', { shouldDirty: true });
                                } })) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Телефон'), labelFor: 'phoneNumber', labelWidth: labelWidth, controlSize: '200', help: ((_e = errors.phoneNumber) === null || _e === void 0 ? void 0 : _e.message) ||
                            (isPhoneDisabled ? (_jsxs("span", Object.assign({ className: styles.warning }, { children: [_jsx(Warning, {}), t('рассылка на этот номер прекращена из-за ошибок доставки')] }))) : undefined), hasError: !!errors.phoneNumber }, { children: _jsx(Controller, { name: 'phoneNumber', control: control, rules: {
                                required: {
                                    value: phoneNumberIsRequired,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                maxLength: {
                                    value: 30,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(30),
                                        count: 30,
                                    }),
                                },
                                validate: validatePhoneNumber,
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'phoneNumber', type: 'tel', leftIcon: _jsx(MobilePhone, {}), placeholder: t('введите номер'), hasError: !!errors.phoneNumber }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Email'), labelFor: 'email', labelWidth: labelWidth, controlSize: '200', help: ((_f = errors.email) === null || _f === void 0 ? void 0 : _f.message) ||
                            (isEmailDisabled ? (_jsxs("span", Object.assign({ className: styles.warning }, { children: [_jsx(Warning, {}), t('рассылка на эту почту прекращена из-за ошибок доставки')] }))) : undefined), hasError: !!errors.email }, { children: _jsx(Controller, { name: 'email', control: control, rules: {
                                maxLength: {
                                    value: 64,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(64),
                                        count: 64,
                                    }),
                                },
                                pattern: {
                                    value: emailPattern,
                                    message: t('неверный Email'),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'email', type: 'email', placeholder: t('name@mail.com'), leftIcon: _jsx(Mail, { className: styles.mailInputIcon }), hasError: !!errors.email }))) }) }))] })), _jsxs(FormFieldset, Object.assign({ title: t('Программа лояльности'), titleOffset: labelWidth }, { children: [levels.length > 0 && (_jsx(FormFieldRow, Object.assign({ label: t('Уровень клиента'), labelFor: 'level', labelWidth: labelWidth, controlSize: '200' }, { children: _jsx(Controller, { name: 'level', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'level', placeholder: t('выберите уровень'), value: levels.find((level) => level.level === field.value) ||
                                    null, onChange: (level) => {
                                    setValue('level', (level === null || level === void 0 ? void 0 : level.level) || null, {
                                        shouldDirty: true,
                                    });
                                }, options: levels, getOptionKey: (option) => option.level, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true }))) }) }))), !isEdit && (_jsx(FormFieldRow, Object.assign({ label: t('Место выдачи карты'), labelFor: 'issuerShopId', labelWidth: labelWidth, controlSize: '400' }, { children: _jsx(Controller, { name: 'issuerShopId', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'issuerShopId', placeholder: t('выберите место выдачи'), value: shops.find((shop) => shop.id === field.value) || null, onChange: (shop) => {
                                    setValue('issuerShopId', (shop === null || shop === void 0 ? void 0 : shop.id) || null, {
                                        shouldDirty: true,
                                    });
                                }, options: shops, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, isLarge: true }))) }) }))), _jsx(FormFieldRow, Object.assign({ label: t('Номер карты'), labelFor: 'card', labelWidth: labelWidth, controlSize: '300', help: ((_g = errors.card) === null || _g === void 0 ? void 0 : _g.message) ||
                            t('Поместите курсор в данное поле и отсканируйте карту, номер карты отобразится в поле'), helpSize: '300', hasError: !!errors.card }, { children: _jsx(Controller, { name: 'card', control: control, rules: {
                                required: {
                                    value: cardIsRequired,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                                maxLength: {
                                    value: 200,
                                    message: t('FIELD_MAX_LENGTH_ERROR', {
                                        amount: numberRound(200),
                                        count: 200,
                                    }),
                                },
                            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'card', placeholder: t('отсканируйте карту'), leftText: '#', hasError: !!errors.card }))) }) })), _jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, controlSize: '360' }, { children: _jsx(Controller, { name: 'isPhoneSubscribed', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('isPhoneSubscribed', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                } }, { children: _jsx("div", Object.assign({ className: styles.checkboxLabel }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'CLIENT_PHONE_SUBSCRIBED_MESSAGE' }, { children: ["\u0441\u043E\u0433\u043B\u0430\u0441\u0435\u043D \u043D\u0430", _jsx(MobilePhone, {}), "SMS, Push \u0438 Viber-\u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443"] })) })) }))) }) })), _jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, controlSize: '360' }, { children: _jsx(Controller, { name: 'isEmailSubscribed', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                                    setValue('isEmailSubscribed', e.target.checked, {
                                        shouldDirty: true,
                                    });
                                } }, { children: _jsx("div", Object.assign({ className: styles.checkboxLabelEmail }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'CLIENT_EMAIL_SUBSCRIBED_MESSAGE' }, { children: ["\u0441\u043E\u0433\u043B\u0430\u0441\u0435\u043D \u043D\u0430", _jsx(Mail, {}), "email-\u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443"] })) })) }))) }) }))] })), (childFields.length > 0 || extraFields.length > 0) && (_jsxs(FormFieldset, Object.assign({ title: t('Дополнительные данные'), titleOffset: labelWidth }, { children: [childFields.map((field, index) => (_jsx(FormFieldRow, Object.assign({ label: index === 0 ? t('Дети') : undefined, labelFor: index === 0 ? 'children.0' : undefined, labelWidth: labelWidth }, { children: _jsx(Controller, { name: `children.${index}`, control: control, render: ({ field }) => (_jsx(ChildInput, { id: `children.${index}`, value: field.value, onChange: (value) => {
                                    setValue(`children.${index}`, value, {
                                        shouldDirty: true,
                                    });
                                }, onReset: () => {
                                    removeChild(index);
                                    if (childFields.length === 1)
                                        appendChild({ name: '', birthdate: '' });
                                }, onAdd: childFields.length < maxChildCount &&
                                    index === childFields.length - 1
                                    ? () => appendChild({ name: '', birthdate: '' })
                                    : undefined })) }) }), field.id))), extraFields.map((extraField, i) => (_jsx(FormFieldRow, Object.assign({ label: extraField.title, labelFor: `${extraField.name}-${i}`, labelWidth: labelWidth, controlSize: '250' }, { children: _jsx(Controller, { name: `extraFields.${extraField.name}`, control: control, render: ({ field }) => {
                                if (extraField.type === 'numeric')
                                    return (_jsx(Input, Object.assign({}, field, { id: `${extraField.name}-${i}`, type: 'number', placeholder: t('введите'), shouldHideSpinButtons: true })));
                                if (extraField.type === 'static-set' && extraField.options)
                                    return (_jsx(Autocomplete, Object.assign({}, field, { id: `${extraField.name}-${i}`, placeholder: t('выберите'), value: field.value, onChange: (value) => setValue(`extraFields.${extraField.name}`, value || '', {
                                            shouldDirty: true,
                                        }), options: extraField.options, getOptionKey: (option) => option, getOptionLabel: (option) => option, asSelect: extraField.options.length < 50 })));
                                return (_jsx(Input, Object.assign({}, field, { id: `${extraField.name}-${i}`, placeholder: t('введите') })));
                            } }) }), extraField.name)))] }))), error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.actions }, { children: _jsx(_Fragment, { children: isEdit ? (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ placement: 'top', title: userHasClientsManage
                                    ? undefined
                                    : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ type: 'submit', disabled: !userHasClientsManage || isSubmitting, size: 'large' }, { children: t('Сохранить изменения') })) })), _jsx(Tooltip, Object.assign({ placement: 'top', title: userHasClientsManage
                                    ? undefined
                                    : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ type: 'button', onClick: onDelete, disabled: !userHasClientsManage || isSubmitting, icon: _jsx(Cancel, {}), theme: 'danger', size: 'large' }, { children: t('Удалить клиента') })) }))] })) : (_jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: isSubmitting, size: 'extra-large' }, { children: t('Добавить клиента') }))) }) }))] })));
});
