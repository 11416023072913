var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import moment from 'moment';
import { api } from './';
export const offersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOfferCount: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/offer/count',
                body,
            }),
            providesTags: ['OfferCount'],
        }),
        getOfferList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/offer/list',
                body,
            }),
            providesTags: ['OfferList'],
        }),
        getOfferOptions: build.query({
            query: () => ({
                method: 'GET',
                url: '/offer/options-list',
            }),
            providesTags: ['OfferOptions'],
        }),
        getOffer: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/offer/get/${id}`,
            }),
            providesTags: ['Offer'],
        }),
        createOffer: build.mutation({
            query: (_a) => {
                var { id } = _a, body = __rest(_a, ["id"]);
                return ({
                    method: 'POST',
                    url: '/offer/create',
                    body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                            ? moment(body.availableFrom).format('DD.MM.YYYY')
                            : undefined, availableTo: body.availableTo
                            ? moment(body.availableTo).format('DD.MM.YYYY')
                            : undefined }),
                });
            },
            invalidatesTags: ['OfferCount', 'OfferList', 'OfferOptions', 'Offer'],
        }),
        updateOffer: build.mutation({
            query: ({ id, payload: body }) => ({
                method: 'POST',
                url: `/offer/update/${id}`,
                body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                        ? moment(body.availableFrom).format('DD.MM.YYYY')
                        : undefined, availableTo: body.availableTo
                        ? moment(body.availableTo).format('DD.MM.YYYY')
                        : undefined }),
            }),
            invalidatesTags: ['OfferList', 'OfferOptions', 'Offer'],
        }),
        deleteOffer: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/offer/delete/${id}`,
            }),
            invalidatesTags: ['OfferCount', 'OfferList', 'OfferOptions'],
        }),
        setOfferActive: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/offer/set-active',
                body,
            }),
            invalidatesTags: ['OfferList', 'OfferOptions', 'Offer'],
        }),
    }),
});
export const { useGetOfferCountQuery, useGetOfferListQuery, useGetOfferOptionsQuery, useGetOfferQuery, useCreateOfferMutation, useUpdateOfferMutation, useDeleteOfferMutation, useSetOfferActiveMutation, } = offersApi;
