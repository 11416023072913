import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { matchPath } from 'react-router-dom';
import { FlowsPageTitle } from '@/app/pages/Flows/FlowsPageTitle';
import { GiftCardInstancesPageTitle } from '@/app/pages/GiftCardsInstances/GiftCardInstancesPageTitle';
import { RFMPageTitle } from '@/app/pages/RFM/RFMPageTitle';
import { ROUTES } from '@/app/pages/routes';
import { useRouteName } from '@/app/pages/useRouteName';
import { useCurrentUser } from '@/app/useCurrentUser';
import { BreadcrumbsContext } from '../AppHeader/Breadcrumbs/BreadcrumbsContext';
import styles from './styles.module.scss';
const WIDTH_740_LIST = [
    ROUTES.PROMOCODES_CREATE,
    ROUTES.PROMOCODES_EDIT,
    ROUTES.PROMOCODES_FRIEND_CREATE,
    ROUTES.PROMOCODES_FRIEND_EDIT,
    ROUTES.GIFT_CARDS_CREATE,
    ROUTES.GIFT_CARDS_EDIT,
];
const WIDTH_956_LIST = [
    ROUTES.WALLET_CARDS,
    ROUTES.WALLET_CARDS_CREATE,
    ROUTES.WALLET_CARDS_EDIT,
    ROUTES.GEO_PUSH_CREATE,
    ROUTES.GEO_PUSH_EDIT,
];
const NO_TITLE_LIST = [
    ROUTES.MAILINGS_AUTOMATIC_CREATE,
    ROUTES.MAILINGS_AUTOMATIC_EDIT,
    ROUTES.MAILINGS_MANUAL_CREATE,
    ROUTES.MAILINGS_MANUAL_EDIT,
];
const INLINE_TO_CALENDAR_LIST = [ROUTES.BILLING, ROUTES.EXPORTS];
export const PageTitle = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { giftCardId } = useParams();
    const { brand } = useCurrentUser();
    const { lastCrumb } = useContext(BreadcrumbsContext);
    const routeName = useRouteName();
    if (!brand)
        return null;
    const isFlowsPage = matchPath(ROUTES.FLOWS, location.pathname);
    const isRFMPage = matchPath(ROUTES.RFM, location.pathname);
    const isGiftCardInstancesPage = matchPath(ROUTES.GIFT_CARDS_INSTANCES, location.pathname) && giftCardId;
    const isInlineToCalendar = INLINE_TO_CALENDAR_LIST.find((path) => matchPath(path, location.pathname));
    const is740Width = WIDTH_740_LIST.find((path) => matchPath(path, location.pathname));
    const is900Width = !!matchPath(ROUTES.IMPORTS_EDIT, location.pathname);
    const is956Width = WIDTH_956_LIST.find((path) => matchPath(path, location.pathname));
    const isNoTitle = NO_TITLE_LIST.find((path) => matchPath(path, location.pathname));
    let titleElement;
    if (isFlowsPage)
        titleElement = _jsx(FlowsPageTitle, {});
    if (isRFMPage)
        titleElement = _jsx(RFMPageTitle, {});
    if (matchPath(ROUTES.GIFT_CARDS_INSTANCES, location.pathname) && giftCardId)
        titleElement = (_jsx(GiftCardInstancesPageTitle, { giftCardId: Number(giftCardId), currency: brand.brand.currency.symbol }));
    if (isNoTitle)
        return null;
    return (_jsx("h1", Object.assign({ className: classNames('page-title', {
            [styles.pageTitleForm]: !!lastCrumb && !isGiftCardInstancesPage,
            [styles.pageTitleForm740]: is740Width,
            [styles.pageTitleForm900]: is900Width,
            [styles.pageTitleForm956]: is956Width,
            [styles.pageTitleRFM]: isRFMPage,
            [styles.pageTitleInline]: isInlineToCalendar,
        }) }, { children: titleElement || lastCrumb || t(routeName) })));
};
