import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { Input } from '@/components/Input/Input';
import { useDebounce } from '@/hooks/useDebounce';
import styles from './styles.module.scss';
export const PriceInput = memo(({ value, onChange, currency }) => {
    const [draft, setDraft] = useState(value);
    useEffect(() => setDraft(value), [value]);
    useDebounce({
        value: draft,
        callback: onChange,
    });
    const handleChangeInput = useCallback((e) => {
        setDraft(e.target.value);
    }, []);
    return (_jsx(Input, { className: styles.input, type: 'number', inputMode: 'numeric', step: 'any', placeholder: '-', value: draft, onChange: handleChangeInput, rightText: currency, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }));
});
