var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './styles.module.scss';
export const NodeWrapper = (_a) => {
    var { className, gap = '6', isSelected = false, isInteractive = false } = _a, otherProps = __rest(_a, ["className", "gap", "isSelected", "isInteractive"]);
    return (_jsx("div", Object.assign({ className: classNames(className, styles.wrapper, styles[`gap-${gap}`], {
            [styles.selected]: isSelected,
            [styles.interactive]: isInteractive,
        }) }, otherProps)));
};
