import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WalletCardNotify } from '@/components/wallet-cards/WalletCardNotify/WalletCardNotify';
import { fileToDataURL } from '@/utils/fileToDataURL';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { focus } from './focus';
export const CardNotify = () => {
    const { t } = useTranslation();
    const { background, notify, setFormSection, setIsElementClick } = useWalletCardForm();
    const [logoImageUrl, setLogoImageUrl] = useState('');
    const onElementClick = (element) => {
        setFormSection('notify');
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(true);
        setTimeout(() => {
            switch (element) {
                case 'logo': {
                    const node = document.getElementById('notify-logo');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
                case 'title': {
                    const node = document.getElementById('notify-title');
                    node === null || node === void 0 ? void 0 : node.focus();
                    break;
                }
            }
        }, 0);
    };
    useEffect(() => {
        fileToDataURL(notify.logo).then((dataURL) => {
            setLogoImageUrl(dataURL);
        });
    }, [notify.logo]);
    useEffect(() => {
        var _a, _b, _c, _d;
        const walletCardActiveType = (_b = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.usr) === null || _b === void 0 ? void 0 : _b.walletCardActiveType;
        const walletCardActiveElement = (_d = (_c = window.history.state) === null || _c === void 0 ? void 0 : _c.usr) === null || _d === void 0 ? void 0 : _d.walletCardActiveElement;
        if (walletCardActiveType === 'notify' && walletCardActiveElement) {
            onElementClick(walletCardActiveElement);
            window.history.replaceState({}, document.title);
        }
    }, []);
    return (_jsx(WalletCardNotify, { logoImageUrl: logoImageUrl, logoBackground: background, messageTitle: notify.title, messageText: t('WALLET_CARD_MESSAGE_EXAMPLE'), onElementClick: onElementClick }));
};
