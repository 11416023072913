var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { touchedDashboardIdsSelector, touchedDashboardsSelector, } from '@/store/selectors/reports';
import { resetDashboardDraft } from '@/store/slice/reports';
import { addDashboard, saveDashboard } from '@/store/thunk/reports';
export const useSaveTouchedDashboards = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const touchedDashboardIds = useAppSelector(touchedDashboardIdsSelector);
    const touchedDashboards = useAppSelector(touchedDashboardsSelector);
    const addToast = useToastContext();
    const saveTouchedDashboards = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        for (let i = 0; i < touchedDashboards.length; i += 1) {
            const dashboard = touchedDashboards[i];
            const confirmTitle = t('Сохранить изменения в отчете {{name}}?', {
                name: dashboard.title,
            });
            const confirmAgreeLabel = t('Сохранить отчет');
            const confirmDisagreeLabel = t('Не сохранять');
            const successMessage = t('Отчет сохранен');
            const result = yield confirm({
                confirmation: confirmTitle,
                agreeLabel: confirmAgreeLabel,
                disagreeLabel: confirmDisagreeLabel,
                isDangerCancel: true,
                proceedClose: true,
            });
            if (result === 'close')
                return false;
            if (!result) {
                dispatch(resetDashboardDraft(dashboard));
                continue;
            }
            try {
                const isDraft = dashboard.id < 0;
                if (isDraft)
                    yield dispatch(addDashboard(dashboard));
                else
                    yield dispatch(saveDashboard(dashboard));
                addToast({ type: 'success', message: successMessage });
            }
            catch (error) {
                const message = typeof error === 'string'
                    ? error
                    : error === null || error === void 0 ? void 0 : error.message;
                addToast({ type: 'error', message: message || t('Произошла ошибка') });
            }
        }
        return true;
    }), [JSON.stringify(touchedDashboardIds)]);
    return { saveTouchedDashboards, touchedDashboards };
};
