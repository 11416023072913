import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Percent } from '@/icons/Percent';
import styles from './styles.module.scss';
const ContentWrapper = ({ children, link }) => {
    if (link)
        return (_jsx("a", Object.assign({ href: link, className: classNames(styles.accordionContentItem, {
                [styles.accordionContentItemLink]: link,
            }) }, { children: children })));
    return _jsx("div", Object.assign({ className: styles.accordionContentItem }, { children: children }));
};
export const AccordionContent = ({ discountList, }) => {
    const { t } = useTranslation();
    if (discountList.length)
        return (_jsx("ul", Object.assign({ className: styles.accordionContent }, { children: discountList.map(({ link, value, title, className }, index) => (_jsx("li", { children: _jsxs(ContentWrapper, Object.assign({ link: link }, { children: [_jsx(Percent, {}), _jsx("span", Object.assign({ className: styles.accordionContentItemTitle }, { children: title })), _jsx("div", { className: styles.accordionContentItemLine }), _jsx("span", Object.assign({ className: classNames(styles.accordionContentValue, className) }, { children: value }))] })) }, index))) })));
    return (_jsx("div", Object.assign({ className: styles.accordionContentPlaceholder }, { children: t('нет акций') })));
};
