export const customCss = `

.blockbuilder-tools-panel {
  background-color: #eaf4f6;
}

.tab-content {
  padding-bottom: 100px;
}

.cl-ph {
  background-color: #e6e1d8;
  color: #3a3a3a;
  padding: 1px 4px;
  border-radius: 6px;
}

`;
