var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { ImageCropInput, } from '@/components/ImageCropInput/ImageCropInput';
import { useUploadImage } from '@/hooks/useUploadImage';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const ViberFormImage = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const uploadImage = useUploadImage();
    const [error, setError] = useState('');
    const resetImage = () => {
        selectedNode.update({
            viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberImage: undefined, viberImageId: undefined, viberImageFile: undefined }),
        });
    };
    const saveUploadedImage = (file, response) => {
        selectedNode.update({
            viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberImage: response, viberImageId: response.id, viberImageFile: file }),
        });
    };
    const saveLocalFile = (file) => {
        selectedNode.update({
            viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberImage: undefined, viberImageId: undefined, viberImageFile: file }),
        });
    };
    const handleUpdateViberImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        if (!file) {
            resetImage();
            return;
        }
        const response = yield uploadImage(file);
        if (response)
            saveUploadedImage(file, response);
        else
            saveLocalFile(file);
    });
    const handleResetViberImage = (reason) => {
        setError('');
        const message = rejectUploadReason(reason, '', 'square');
        if (message)
            setError(t(message));
        resetImage();
    };
    return (_jsxs("div", { children: [_jsx(ImageCropInput, { value: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberImageFile) ||
                    ((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _b === void 0 ? void 0 : _b.viberImage) ||
                    null, onChange: handleUpdateViberImage, onReject: handleResetViberImage, previewWidth: 160, previewHeight: 160, description: t('Квадратная, до 300кб'), constraints: {
                    mimeType: 'image/*',
                    maxSize: 300000,
                    shape: 'square',
                }, outputMimeType: 'original', cropModalTitle: t('Картинка'), hasError: !!error, theme: 'outlined' }), _jsx("div", Object.assign({ className: formStyles.error }, { children: error }))] }));
};
