import isEqual from 'lodash.isequal';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAppPeriod } from '@/hooks/useAppPeriod';
import { compileFilters } from '@/services/filters/compileFilters';
import { activeDashboardKPITilesSelector, activeDashboardSelector, getSparklineData, } from '@/store/selectors/reports';
import { toggleChartMetrics, updateCurrentMetrics } from '@/store/slice/reports';
import { fetchSparklineDataThunk } from '@/store/thunk/reports';
export const useSparklineData = () => {
    const dispatch = useAppDispatch();
    const { formattedStart, formattedEnd } = useAppPeriod();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const dashboardId = (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.id) || -1;
    const widget = useAppSelector(activeDashboardKPITilesSelector);
    const loadedData = useAppSelector(getSparklineData(dashboardId), isEqual);
    const filters = (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.filters) || [];
    const currentMetrics = (widget === null || widget === void 0 ? void 0 : widget.configuration.metrics.map((m) => m.key)) || [];
    /**
     * Этот useEffect используется для первоначальной загрузки данных,
     * а также для загрузки данных, когда происходит одно из следующего:
     *  1) меняется период сравнения;
     *  2) происходит переключение текущего отчета;
     *  3) меняются фильтры текущего отчета.
     */
    useEffect(() => {
        dispatch(fetchSparklineDataThunk({
            dashboardId,
            periodStart: formattedStart,
            periodEnd: formattedEnd,
            filters: compileFilters(filters),
            metrics: currentMetrics,
        }));
    }, [
        formattedStart,
        formattedEnd,
        dashboardId,
        JSON.stringify(filters),
        JSON.stringify(currentMetrics),
    ]);
    /**
     * Этот callback используется для добавления или изменения метрик.
     * Он изменяет данные отчета в сторе редакса.
     */
    const handleUpdate = useCallback((newMetrics) => {
        const newMetricIds = newMetrics.map((i) => i.metric.id);
        dispatch(updateCurrentMetrics({ dashboardId, newMetricIds }));
    }, [dashboardId]);
    /**
     * Этот callback используется для удаления метрики.
     * Он изменяет данные отчета в сторе редакса.
     */
    const handleDelete = useCallback((newMetrics) => {
        const newMetricIds = newMetrics.map((i) => i.metric.id);
        dispatch(updateCurrentMetrics({ dashboardId, newMetricIds }));
    }, [dashboardId]);
    /**
     * Этот callback используется для добавления метрики на график.
     * Он изменяет данные отчета в сторе редакса.
     */
    const handleSelect = useCallback((key) => {
        dispatch(toggleChartMetrics({ key }));
    }, []);
    return { loadedData, handleUpdate, handleDelete, handleSelect };
};
