import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './styles.module.scss';
export const AreaChartLegend = ({ items, onItemClick, selectedItem, }) => {
    const reversed = [...items];
    reversed.reverse();
    return (_jsx("div", Object.assign({ className: styles.legend }, { children: reversed.map((item) => (_jsxs("div", Object.assign({ className: styles.legendItem, onClick: () => onItemClick(item.id) }, { children: [_jsx("div", { className: styles.legendItemColor, style: { backgroundColor: item.color } }), _jsx("div", Object.assign({ className: classNames(styles.legendItemLabel, {
                        [styles.selected]: item.id === selectedItem,
                    }) }, { children: item.label }))] }), item.id))) })));
};
