import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@/store/slice';
import * as thunks from '@/store/thunk/filters';
const productAttributesSlice = createSlice({
    name: 'productAttributes',
    initialState: { productAttributesValues: {} },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(thunks.getProductAttributesValuesThunk.pending, (state, action) => {
            const { meta } = action;
            if (!state.productAttributesValues[meta.arg.attributeKey])
                state.productAttributesValues[meta.arg.attributeKey] = {
                    status: FetchStatus.Loading,
                };
            state.productAttributesValues[meta.arg.attributeKey].status =
                FetchStatus.Loading;
        });
        builder.addCase(thunks.getProductAttributesValuesThunk.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            const { meta } = action;
            const { attributeKey, limit, offset, attributeValues } = meta.arg;
            const slice = state.productAttributesValues[attributeKey];
            slice.status = FetchStatus.Finished;
            slice.offset = offset;
            slice.hasMoreData = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.moreResults;
            if (!offset)
                slice.data = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.results;
            else
                (_c = slice.data) === null || _c === void 0 ? void 0 : _c.splice(((_d = slice.data) === null || _d === void 0 ? void 0 : _d.length) || offset, limit, ...(((_e = action.payload) === null || _e === void 0 ? void 0 : _e.results) || []));
            if (attributeValues && slice.data)
                slice.data.sort((a, b) => attributeValues.indexOf(b) - attributeValues.indexOf(a));
        });
        builder.addCase(thunks.getProductAttributesValuesThunk.rejected, (state, action) => {
            const { meta } = action;
            const { error } = action;
            state.productAttributesValues[meta.arg.attributeKey].status =
                FetchStatus.Error;
            state.productAttributesValues[meta.arg.attributeKey].error = error;
        });
    },
});
export default productAttributesSlice.reducer;
