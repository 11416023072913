import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { CheckboxSelect } from '@/components/CheckboxSelect/CheckboxSelect';
import { DateInput } from '@/components/DateInput/DateInput';
import { DaysSelect } from '@/components/DaysSelect/DaysSelect';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TimeInput } from '@/components/TimeInput/TimeInput';
import styles from '../styles.module.scss';
import { OFFSET_SIGN_SCHEDULE } from './FormMailingOffset';
export const FormMailingTriggerSchedule = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, trigger, watch } = useFormContext();
    const { errors, isSubmitted } = formState;
    const offsetSign = watch('offsetSign');
    const triggerScheduleType = watch('triggerScheduleType');
    const triggerScheduleTime = watch('triggerScheduleTime');
    const triggerScheduleTypeOptions = [
        { value: 'daily', label: t('каждый день') },
        { value: 'weekly', label: t('каждую неделю') },
        { value: 'monthly', label: t('каждый месяц') },
        { value: 'yearly', label: t('каждый год') },
    ];
    const dayOfMonthOptions = new Array(31).fill(null).map((_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
    }));
    dayOfMonthOptions.push({
        value: 32,
        label: t('последний день месяца'),
    });
    const validateTime = (value) => {
        if (!value)
            return t('FIELD_REQUIRED_ERROR');
        if (value.includes('_'))
            return t('FIELD_REQUIRED_ERROR');
        return true;
    };
    useEffect(() => {
        if (isSubmitted)
            trigger('triggerScheduleTime');
    }, [triggerScheduleTime, isSubmitted]);
    if (offsetSign !== OFFSET_SIGN_SCHEDULE)
        return null;
    return (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth }, { children: _jsxs("div", Object.assign({ className: styles.triggerScheduleRow }, { children: [_jsx(Controller, { name: 'triggerScheduleType', control: control, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerScheduleTypeInput }, { children: _jsx(Autocomplete, Object.assign({}, field, { id: 'triggerScheduleType', value: triggerScheduleTypeOptions.find((item) => item.value === field.value) || null, onChange: (value) => {
                                setValue('triggerScheduleType', value === null || value === void 0 ? void 0 : value.value);
                            }, options: triggerScheduleTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true })) }))) }), triggerScheduleType === 'weekly' && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: styles.triggerScheduleDivider }, { children: t('SEND_ON_DAYS') })), _jsx(Controller, { name: 'triggerScheduleDaysOfWeek', control: control, rules: {
                                required: {
                                    value: true,
                                    message: t('FIELD_REQUIRED_ERROR'),
                                },
                            }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerScheduleDaysOfWeekInput }, { children: _jsx(DaysSelect, { id: 'triggerScheduleDaysOfWeek', value: field.value || [], onChange: (value) => {
                                        setValue('triggerScheduleDaysOfWeek', value, {
                                            shouldValidate: isSubmitted,
                                        });
                                    }, hasReset: false, hasError: !!errors.triggerScheduleDaysOfWeek }) }))) })] })), triggerScheduleType === 'monthly' && (_jsx(Controller, { name: 'triggerScheduleDaysOfMonth', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerScheduleDaysOfMonthInput }, { children: _jsx(CheckboxSelect, { id: 'triggerScheduleDaysOfMonth', text: dayOfMonthOptions
                                .filter((i) => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a.includes(i.value); })
                                .map((i) => i.label)
                                .join(', ') || '', options: dayOfMonthOptions, value: field.value || [], onChange: (value) => {
                                setValue('triggerScheduleDaysOfMonth', value, {
                                    shouldValidate: isSubmitted,
                                });
                            }, hasResetButton: false, listHeight: 250, listWidth: 150, hasError: !!errors.triggerScheduleDaysOfMonth }) }))) })), triggerScheduleType === 'yearly' && (_jsx(_Fragment, { children: _jsx(Controller, { name: 'triggerScheduleDate', control: control, rules: {
                            required: {
                                value: true,
                                message: t('FIELD_REQUIRED_ERROR'),
                            },
                        }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerScheduleDateInput }, { children: _jsx(DateInput, { id: 'triggerScheduleDate', selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                    setValue('triggerScheduleDate', date ? moment(date).format() : '', { shouldValidate: isSubmitted });
                                }, portalId: 'form-mailing-dates-portal', hasError: !!errors.triggerScheduleDate, dateFormat: 'd MMMM', placeholderText: '', minDate: moment()
                                    .startOf('day')
                                    .set('month', 0)
                                    .set('date', 1)
                                    .toDate(), maxDate: moment()
                                    .endOf('day')
                                    .set('month', 11)
                                    .set('date', 31)
                                    .toDate(), hasDayNamesHidden: true, hasYearDropdownHidden: true, hasConstraints: true }) }))) }) })), _jsx("div", Object.assign({ className: styles.triggerScheduleDivider }, { children: triggerScheduleType === 'monthly' ? t('числа в') : t('SEND_AT') })), _jsx(Controller, { name: 'triggerScheduleTime', control: control, rules: { validate: validateTime }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerScheduleTimeInput }, { children: _jsx(TimeInput, Object.assign({}, field, { id: 'triggerScheduleTime', onAccept: (time) => {
                                setValue('triggerScheduleTime', time);
                            }, onClear: () => {
                                setValue('triggerScheduleTime', '');
                            }, hasError: !!errors.triggerScheduleTime })) }))) })] })) })));
};
