import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback } from 'react';
import { AreaChart } from '@/components/AreaChart/AreaChart';
import { HorizontalBarList, } from '@/components/HorizontalBarList/HorizontalBarList';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Lock } from '@/icons/Lock';
import { RFMChartDropdown } from './RFMChartDropdown';
import { RFMChartEmpty } from './RFMChartEmpty';
import { RFMChartPills } from './RFMChartPills';
import { RFMChartWrongDate } from './RFMChartWrongDate';
import styles from './styles.module.scss';
export const RFMChart = ({ dataType = 'clientsCount', onChangeDataType, chartType = 'area', onChangeChartType, bars = [], barsTotal = 0, areaLabels = [], areaDatasets = [], bucketSize, onItemClick, shouldDisplayProfit, isLocked, hasNoData = false, hasNoSearchResults = false, shouldDisplayWrongDateWarning = false, onWrongDateButtonClick, }) => {
    const actualLocked = isLocked && dataType !== 'clientsCount' && dataType !== 'revenue';
    const icon = actualLocked ? _jsx(Lock, { className: styles.lock }) : null;
    const shouldDisplayChart = !hasNoData && !hasNoSearchResults && !shouldDisplayWrongDateWarning;
    const handleItemClick = useCallback((id) => onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(id), [onItemClick]);
    return (_jsxs(StatsPanel, Object.assign({ className: styles.wrapper, titleClassName: styles.title, title: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.titleDropdown }, { children: [icon, _jsx(RFMChartDropdown, { activeItem: dataType, onChangeActiveItem: onChangeDataType, shouldDisplayProfit: shouldDisplayProfit })] })), _jsx(RFMChartPills, { activeItem: chartType, onChangeActiveItem: onChangeChartType })] }), bodyClassName: classNames(styles.body, styles[chartType]) }, { children: [shouldDisplayWrongDateWarning && (_jsx(RFMChartWrongDate, { chartType: chartType, onActionClick: onWrongDateButtonClick })), !shouldDisplayWrongDateWarning && hasNoData && (_jsx(RFMChartEmpty, { type: 'no-data', chartType: chartType })), !shouldDisplayWrongDateWarning && hasNoSearchResults && (_jsx(RFMChartEmpty, { type: 'no-search-results' })), shouldDisplayChart && chartType === 'bars' && (_jsx(HorizontalBarList, { type: dataType === 'arppu' || dataType === 'avgSale'
                    ? 'histogram'
                    : 'progressbar', items: bars, onItemClick: handleItemClick, total: barsTotal, totalLabel: dataType === 'arppu' || dataType === 'avgSale'
                    ? 'По всем клиентам'
                    : 'Всего', isLocked: actualLocked })), shouldDisplayChart && chartType === 'area' && (_jsx(AreaChart, { labels: areaLabels, data: areaDatasets, bucketSize: bucketSize, onLegendItemClick: handleItemClick, isLocked: actualLocked, canFilterDataSets: true }))] })));
};
