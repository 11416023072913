import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { CheckCircle } from '@/icons/CheckCircle';
import { Chevron } from '@/icons/Chevron';
import { Lock } from '@/icons/Lock';
import styles from './styles.module.scss';
const deepFind = (item, itemIds) => {
    const result = [];
    const collect = (item) => {
        var _a;
        if (!((_a = item.items) === null || _a === void 0 ? void 0 : _a.length)) {
            if (itemIds.includes(item.id))
                result.push(item);
        }
        else
            for (let i = 0; i < item.items.length; i += 1)
                collect(item.items[i]);
    };
    collect(item);
    return result;
};
export const TreeNode = memo(({ item, level, selectedItemIds, disabledItemIds, hiddenItemIds, onToggleItems, hasAutoExpand = false, levelIndent = 18, scrollToSelectedNode = false, }) => {
    var _a, _b;
    const { t } = useTranslation();
    if (hiddenItemIds === null || hiddenItemIds === void 0 ? void 0 : hiddenItemIds.includes(item.id))
        return null;
    if (item.hidden && !selectedItemIds.includes(item.id))
        return null;
    const hasChildren = !!((_a = item.items) === null || _a === void 0 ? void 0 : _a.length);
    const selected = selectedItemIds.includes(item.id);
    const selectedChildren = deepFind(item, selectedItemIds);
    const deepSelected = selectedChildren.length > 0;
    const [isExpanded, setIsExpanded] = useState((hasAutoExpand ? deepSelected : false) || !!item.isInitiallyExpanded);
    const isDisabled = !selected && ((disabledItemIds === null || disabledItemIds === void 0 ? void 0 : disabledItemIds.includes(item.id)) || !!item.disabled);
    const handleClick = (event) => {
        const selectedItem = event.currentTarget;
        if (scrollToSelectedNode)
            // нужна задержка перед раскрытием списка
            setTimeout(() => {
                selectedItem.scrollIntoView({ behavior: 'smooth' });
            });
        event.stopPropagation();
        if (item.locked)
            return;
        if (!hasChildren && !isDisabled) {
            if (selected)
                onToggleItems(selectedItemIds.filter((id) => id !== item.id), item);
            else
                onToggleItems([...selectedItemIds, item.id], item);
            return;
        }
        if (!isDisabled)
            setIsExpanded(!isExpanded);
    };
    if (item.items && !item.items.length)
        return null;
    const lockedLabel = (_jsxs("span", Object.assign({ className: styles.nowrap }, { children: [_jsx("span", Object.assign({ className: styles.wrap }, { children: item.label })), "\u00A0", _jsx(Tooltip, Object.assign({ title: _jsx("span", Object.assign({ className: styles.tooltip }, { children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') })), placement: 'right', PopperProps: { disablePortal: true } }, { children: _jsx("span", Object.assign({ className: styles.lockIcon }, { children: _jsx(Lock, {}) })) }))] })));
    const labelWithHelp = (_jsx(HelpTooltip, Object.assign({ title: item.help, placement: 'top', isSmall: true }, { children: item.label })));
    return (_jsxs("li", Object.assign({ className: classNames(styles.item, {
            [styles.expanded]: isExpanded,
        }), onClick: handleClick, "data-selected": selected }, { children: [_jsxs("span", Object.assign({ className: classNames(styles.label, {
                    [styles.parent]: hasChildren,
                    [styles.disabled]: isDisabled,
                    [styles.locked]: item.locked,
                    [styles.deepSelected]: deepSelected,
                    [styles.selected]: selected || (!isExpanded && deepSelected),
                }), style: { marginLeft: level * levelIndent } }, { children: [item.locked ? lockedLabel : item.help ? labelWithHelp : item.label, ' ', item.required && !item.locked && !isDisabled && (_jsx("span", Object.assign({ className: styles.required }, { children: "*" }))), hasChildren && !item.locked && !isDisabled && (_jsx(Chevron, { className: styles.icon, fill: 'currentColor' })), isDisabled && !item.locked && (_jsx(CheckCircle, { className: styles.disabledIcon }))] })), !!((_b = item.items) === null || _b === void 0 ? void 0 : _b.length) && (_jsx("ul", Object.assign({ className: styles.list }, { children: item.items.map((i) => (_jsx(TreeNode, { item: i, level: level + 1, selectedItemIds: selectedItemIds, disabledItemIds: disabledItemIds, onToggleItems: onToggleItems, hasAutoExpand: hasAutoExpand, levelIndent: levelIndent }, i.id))) })))] })));
});
