var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TestMessageModal } from '../modals/TestMessageModal';
import styles from '../styles.module.scss';
export const FormMailingPushTest = ({ onSendTestPush, labelWidth, }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const pushText = watch('pushText');
    const openModal = useCallback(() => setModalIsOpen(true), []);
    const closeModal = useCallback(() => setModalIsOpen(false), []);
    const send = (targets) => __awaiter(void 0, void 0, void 0, function* () {
        if (onSendTestPush) {
            setError('');
            setIsLoading(true);
            try {
                yield onSendTestPush(targets);
                closeModal();
            }
            catch (e) {
                const error = e;
                setError(error);
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(FormFieldRow, Object.assign({ className: styles.testButtonRow, labelWidth: labelWidth, controlSize: '300' }, { children: _jsx(Button, Object.assign({ type: 'button', theme: 'default', size: 'full-width', onClick: openModal, disabled: !(pushText === null || pushText === void 0 ? void 0 : pushText.trim()) }, { children: t('Отправить тестовый PUSH') })) })), _jsx(TestMessageModal, { isOpen: modalIsOpen, onClose: closeModal, type: 'push', onSendClick: send, isLoading: isLoading, error: error }, 'push')] }));
};
