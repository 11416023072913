var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useGetClients } from '@/hooks/useGetClients';
import { FormClientBonusesModal } from './FormClientBonusesModal';
import { useCorrectClientBonuses } from './useCorrectClientBonuses';
export const FormClientBonusesModalContainer = ({ isOpen, onClose, client }) => {
    const getClients = useGetClients();
    const { correctClientBonuses, isLoading, error } = useCorrectClientBonuses();
    return (_jsx(FormClientBonusesModal, { isOpen: isOpen, onClose: onClose, client: client, onSubmit: (formValues) => __awaiter(void 0, void 0, void 0, function* () {
            const success = yield correctClientBonuses(formValues);
            if (success && onClose)
                onClose();
        }), isSubmitting: isLoading, error: error, getClients: getClients }));
};
