import { api } from './';
export const shopApi = api.injectEndpoints({
    endpoints: (build) => ({
        getShopList: build.query({
            query: () => '/shop/list',
            providesTags: ['ShopList'],
        }),
        getShopOptions: build.query({
            query: () => '/shop/options-list',
            providesTags: ['ShopOptions'],
        }),
        getShopTimeZones: build.query({
            query: () => '/shop/timezones',
        }),
        addShop: build.mutation({
            query(body) {
                return {
                    url: '/shop/add',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['ShopList', 'ShopOptions'],
        }),
        updateShop: build.mutation({
            query(body) {
                return {
                    url: '/shop/set',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['ShopList', 'ShopOptions'],
        }),
        deleteShop: build.mutation({
            query({ id, replacementShopId }) {
                return {
                    url: `/shop/delete/${id}`,
                    method: 'POST',
                    body: {
                        replacementShopId,
                    },
                };
            },
            invalidatesTags: ['ShopList', 'ShopOptions'],
        }),
    }),
});
export const { useGetShopListQuery, useGetShopOptionsQuery, useGetShopTimeZonesQuery, useAddShopMutation, useUpdateShopMutation, useDeleteShopMutation, } = shopApi;
