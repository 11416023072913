export const getSegmentName = (segment) => {
    switch (segment.code) {
        case 'rfm_pot_vip': {
            return 'Потенц. VIP';
        }
        case 'rfm_pot_loyal': {
            return 'Потенц. лояльные';
        }
        case 'rfm_maybe_rtl': {
            return 'Возм. отток';
        }
        case 'rfm_rtl_new': {
            return 'Разовые';
        }
        default: {
            return segment.name;
        }
    }
};
