export const convertFormValuesToWalletCard = (values) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47, _48, _49, _50, _51, _52, _53, _54, _55, _56, _57, _58, _59, _60, _61, _62, _63, _64, _65, _66, _67, _68, _69, _70, _71, _72, _73, _74, _75, _76, _77, _78, _79, _80, _81, _82, _83, _84, _85, _86, _87, _88, _89, _90, _91;
    const appleLogo = ((_a = values === null || values === void 0 ? void 0 : values.appleHead) === null || _a === void 0 ? void 0 : _a.logo) &&
        ((_b = values === null || values === void 0 ? void 0 : values.appleHead) === null || _b === void 0 ? void 0 : _b.logoUploadResponse) &&
        ((_c = values.appleHead) === null || _c === void 0 ? void 0 : _c.logoSmallUploadResponse)
        ? [
            (_d = values === null || values === void 0 ? void 0 : values.appleHead) === null || _d === void 0 ? void 0 : _d.logoSmallUploadResponse,
            (_e = values === null || values === void 0 ? void 0 : values.appleHead) === null || _e === void 0 ? void 0 : _e.logoUploadResponse,
        ]
        : null;
    const googleLogo = ((_f = values === null || values === void 0 ? void 0 : values.googleHead) === null || _f === void 0 ? void 0 : _f.logo)
        ? ((_g = values === null || values === void 0 ? void 0 : values.googleHead) === null || _g === void 0 ? void 0 : _g.logoUploadResponse) || null
        : null;
    const appleBanner = ((_h = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _h === void 0 ? void 0 : _h.image) &&
        ((_j = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _j === void 0 ? void 0 : _j.imageUploadResponse) &&
        ((_k = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _k === void 0 ? void 0 : _k.imageSmallUploadResponse)
        ? [
            (_l = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _l === void 0 ? void 0 : _l.imageSmallUploadResponse,
            (_m = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _m === void 0 ? void 0 : _m.imageUploadResponse,
        ]
        : null;
    const googleBanner = ((_o = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _o === void 0 ? void 0 : _o.image)
        ? ((_p = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _p === void 0 ? void 0 : _p.imageUploadResponse) || null
        : null;
    const googleBacksideBanner = ((_q = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _q === void 0 ? void 0 : _q.backsideImage)
        ? ((_r = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _r === void 0 ? void 0 : _r.backsideImageUploadResponse) || null
        : null;
    const appleNotifyLogo = ((_s = values === null || values === void 0 ? void 0 : values.notify) === null || _s === void 0 ? void 0 : _s.logo) &&
        ((_t = values === null || values === void 0 ? void 0 : values.notify) === null || _t === void 0 ? void 0 : _t.logoUploadResponse) &&
        ((_u = values === null || values === void 0 ? void 0 : values.notify) === null || _u === void 0 ? void 0 : _u.logoSmallUploadResponse)
        ? [
            (_v = values === null || values === void 0 ? void 0 : values.notify) === null || _v === void 0 ? void 0 : _v.logoSmallUploadResponse,
            (_w = values === null || values === void 0 ? void 0 : values.notify) === null || _w === void 0 ? void 0 : _w.logoUploadResponse,
        ]
        : null;
    return {
        barcode: (values === null || values === void 0 ? void 0 : values.barcode) || null,
        backgroundColor: (values === null || values === void 0 ? void 0 : values.background) || '',
        textColor: (values === null || values === void 0 ? void 0 : values.color) || '',
        labelColor: (values === null || values === void 0 ? void 0 : values.labelColor) || '',
        header: {
            apple: {
                logo: appleLogo,
                title: ((_x = values === null || values === void 0 ? void 0 : values.appleHead) === null || _x === void 0 ? void 0 : _x.title) || '',
                firstField: {
                    label: ((_y = values === null || values === void 0 ? void 0 : values.appleHead) === null || _y === void 0 ? void 0 : _y.field_1_Title) || '',
                    value: ((_z = values === null || values === void 0 ? void 0 : values.appleHead) === null || _z === void 0 ? void 0 : _z.field_1_Value) || '',
                    vars: ((_0 = values === null || values === void 0 ? void 0 : values.appleHead) === null || _0 === void 0 ? void 0 : _0.field_1_Vars) || null,
                },
                secondField: {
                    label: ((_1 = values === null || values === void 0 ? void 0 : values.appleHead) === null || _1 === void 0 ? void 0 : _1.field_2_Title) || '',
                    value: ((_2 = values === null || values === void 0 ? void 0 : values.appleHead) === null || _2 === void 0 ? void 0 : _2.field_2_Value) || '',
                    vars: ((_3 = values === null || values === void 0 ? void 0 : values.appleHead) === null || _3 === void 0 ? void 0 : _3.field_2_Vars) || null,
                },
            },
            google: {
                logo: googleLogo,
                title: ((_4 = values === null || values === void 0 ? void 0 : values.googleHead) === null || _4 === void 0 ? void 0 : _4.title) || '',
                programName: ((_5 = values === null || values === void 0 ? void 0 : values.googleHead) === null || _5 === void 0 ? void 0 : _5.programName) || '',
                firstField: {
                    label: ((_6 = values === null || values === void 0 ? void 0 : values.googleHead) === null || _6 === void 0 ? void 0 : _6.field_Main_Title) || '',
                    value: ((_7 = values === null || values === void 0 ? void 0 : values.googleHead) === null || _7 === void 0 ? void 0 : _7.field_Main_Value) || '',
                    vars: ((_8 = values === null || values === void 0 ? void 0 : values.googleHead) === null || _8 === void 0 ? void 0 : _8.field_Main_Vars) || null,
                },
            },
        },
        banner: {
            type: (values === null || values === void 0 ? void 0 : values.bannerType) || 'simple',
            counter: (values === null || values === void 0 ? void 0 : values.bannerType) === 'with-counter'
                ? {
                    offerId: ((_9 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _9 === void 0 ? void 0 : _9.offerId) || null,
                    tickImage: ((_10 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _10 === void 0 ? void 0 : _10.tickImage) &&
                        ((_11 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _11 === void 0 ? void 0 : _11.tickImageUploadResponse)
                        ? (_12 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _12 === void 0 ? void 0 : _12.tickImageUploadResponse
                        : null,
                    blankImage: ((_13 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _13 === void 0 ? void 0 : _13.blankImage) &&
                        values.bannerCounter.blankImageUploadResponse
                        ? (_14 = values === null || values === void 0 ? void 0 : values.bannerCounter) === null || _14 === void 0 ? void 0 : _14.blankImageUploadResponse
                        : null,
                }
                : null,
            apple: {
                image: appleBanner,
                title: ((_15 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _15 === void 0 ? void 0 : _15.title) || '',
                titleVars: ((_16 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _16 === void 0 ? void 0 : _16.titleVars) || null,
                titleColor: ((_17 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _17 === void 0 ? void 0 : _17.titleColor) || '',
                titleSize: ((_18 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _18 === void 0 ? void 0 : _18.titleSize)
                    ? Number((_19 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _19 === void 0 ? void 0 : _19.titleSize)
                    : null,
                description: ((_20 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _20 === void 0 ? void 0 : _20.description) || '',
                descriptionVars: ((_21 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _21 === void 0 ? void 0 : _21.descriptionVars) || null,
                descriptionColor: ((_22 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _22 === void 0 ? void 0 : _22.descriptionColor) || '',
                descriptionSize: ((_23 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _23 === void 0 ? void 0 : _23.descriptionSize)
                    ? Number((_24 = values === null || values === void 0 ? void 0 : values.appleBanner) === null || _24 === void 0 ? void 0 : _24.descriptionSize)
                    : null,
            },
            google: {
                image: googleBanner,
                backsideImage: googleBacksideBanner,
                title: ((_25 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _25 === void 0 ? void 0 : _25.title) || '',
                titleVars: ((_26 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _26 === void 0 ? void 0 : _26.titleVars) || null,
                titleColor: ((_27 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _27 === void 0 ? void 0 : _27.titleColor) || '',
                titleSize: ((_28 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _28 === void 0 ? void 0 : _28.titleSize)
                    ? Number((_29 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _29 === void 0 ? void 0 : _29.titleSize)
                    : null,
                description: ((_30 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _30 === void 0 ? void 0 : _30.description) || '',
                descriptionVars: ((_31 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _31 === void 0 ? void 0 : _31.descriptionVars) || null,
                descriptionColor: ((_32 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _32 === void 0 ? void 0 : _32.descriptionColor) || '',
                descriptionSize: ((_33 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _33 === void 0 ? void 0 : _33.descriptionSize)
                    ? Number((_34 = values === null || values === void 0 ? void 0 : values.googleBanner) === null || _34 === void 0 ? void 0 : _34.descriptionSize)
                    : null,
            },
        },
        fields: {
            apple: {
                first: {
                    label: ((_35 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _35 === void 0 ? void 0 : _35.field_3_Title) || '',
                    value: ((_36 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _36 === void 0 ? void 0 : _36.field_3_Value) || '',
                    vars: ((_37 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _37 === void 0 ? void 0 : _37.field_3_Vars) || null,
                },
                second: {
                    label: ((_38 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _38 === void 0 ? void 0 : _38.field_4_Title) || '',
                    value: ((_39 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _39 === void 0 ? void 0 : _39.field_4_Value) || '',
                    vars: ((_40 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _40 === void 0 ? void 0 : _40.field_4_Vars) || null,
                },
                third: {
                    label: ((_41 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _41 === void 0 ? void 0 : _41.field_5_Title) || '',
                    value: ((_42 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _42 === void 0 ? void 0 : _42.field_5_Value) || '',
                    vars: ((_43 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _43 === void 0 ? void 0 : _43.field_5_Vars) || null,
                },
                fourth: {
                    label: ((_44 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _44 === void 0 ? void 0 : _44.field_6_Title) || '',
                    value: ((_45 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _45 === void 0 ? void 0 : _45.field_6_Value) || '',
                    vars: ((_46 = values === null || values === void 0 ? void 0 : values.appleFields) === null || _46 === void 0 ? void 0 : _46.field_6_Vars) || null,
                },
            },
            google: {
                first: {
                    label: ((_47 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _47 === void 0 ? void 0 : _47.field_1_Title) || '',
                    value: ((_48 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _48 === void 0 ? void 0 : _48.field_1_Value) || '',
                    vars: ((_49 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _49 === void 0 ? void 0 : _49.field_1_Vars) || null,
                },
                second: {
                    label: ((_50 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _50 === void 0 ? void 0 : _50.field_2_Title) || '',
                    value: ((_51 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _51 === void 0 ? void 0 : _51.field_2_Value) || '',
                    vars: ((_52 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _52 === void 0 ? void 0 : _52.field_2_Vars) || null,
                },
                third: {
                    label: ((_53 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _53 === void 0 ? void 0 : _53.field_3_Title) || '',
                    value: ((_54 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _54 === void 0 ? void 0 : _54.field_3_Value) || '',
                    vars: ((_55 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _55 === void 0 ? void 0 : _55.field_3_Vars) || null,
                },
                fourth: ((_56 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _56 === void 0 ? void 0 : _56.field_4_Title) ||
                    ((_57 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _57 === void 0 ? void 0 : _57.field_4_Value)
                    ? {
                        label: ((_58 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _58 === void 0 ? void 0 : _58.field_4_Title) || '',
                        value: ((_59 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _59 === void 0 ? void 0 : _59.field_4_Value) || '',
                        vars: ((_60 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _60 === void 0 ? void 0 : _60.field_4_Vars) || null,
                    }
                    : null,
                fifth: ((_61 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _61 === void 0 ? void 0 : _61.field_5_Title) ||
                    ((_62 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _62 === void 0 ? void 0 : _62.field_5_Value)
                    ? {
                        label: ((_63 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _63 === void 0 ? void 0 : _63.field_5_Title) || '',
                        value: ((_64 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _64 === void 0 ? void 0 : _64.field_5_Value) || '',
                        vars: ((_65 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _65 === void 0 ? void 0 : _65.field_5_Vars) || null,
                    }
                    : null,
                sixth: ((_66 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _66 === void 0 ? void 0 : _66.field_6_Title) ||
                    ((_67 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _67 === void 0 ? void 0 : _67.field_6_Value)
                    ? {
                        label: ((_68 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _68 === void 0 ? void 0 : _68.field_6_Title) || '',
                        value: ((_69 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _69 === void 0 ? void 0 : _69.field_6_Value) || '',
                        vars: ((_70 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _70 === void 0 ? void 0 : _70.field_6_Vars) || null,
                    }
                    : null,
                seventh: ((_71 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _71 === void 0 ? void 0 : _71.field_7_Title) ||
                    ((_72 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _72 === void 0 ? void 0 : _72.field_7_Value)
                    ? {
                        label: ((_73 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _73 === void 0 ? void 0 : _73.field_7_Title) || '',
                        value: ((_74 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _74 === void 0 ? void 0 : _74.field_7_Value) || '',
                        vars: ((_75 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _75 === void 0 ? void 0 : _75.field_7_Vars) || null,
                    }
                    : null,
                eighth: ((_76 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _76 === void 0 ? void 0 : _76.field_8_Title) ||
                    ((_77 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _77 === void 0 ? void 0 : _77.field_8_Value)
                    ? {
                        label: ((_78 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _78 === void 0 ? void 0 : _78.field_8_Title) || '',
                        value: ((_79 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _79 === void 0 ? void 0 : _79.field_8_Value) || '',
                        vars: ((_80 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _80 === void 0 ? void 0 : _80.field_8_Vars) || null,
                    }
                    : null,
                ninth: ((_81 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _81 === void 0 ? void 0 : _81.field_9_Title) ||
                    ((_82 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _82 === void 0 ? void 0 : _82.field_9_Value)
                    ? {
                        label: ((_83 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _83 === void 0 ? void 0 : _83.field_9_Title) || '',
                        value: ((_84 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _84 === void 0 ? void 0 : _84.field_9_Value) || '',
                        vars: ((_85 = values === null || values === void 0 ? void 0 : values.googleFields) === null || _85 === void 0 ? void 0 : _85.field_9_Vars) || null,
                    }
                    : null,
            },
        },
        backside: {
            title: ((_86 = values === null || values === void 0 ? void 0 : values.backside) === null || _86 === void 0 ? void 0 : _86.appleTitle) || '',
            fields: ((_88 = (_87 = values === null || values === void 0 ? void 0 : values.backside) === null || _87 === void 0 ? void 0 : _87.details) === null || _88 === void 0 ? void 0 : _88.map((item) => ({
                label: item.title,
                value: item.content,
                vars: item.vars || null,
            }))) || [],
            contacts: ((_90 = (_89 = values === null || values === void 0 ? void 0 : values.backside) === null || _89 === void 0 ? void 0 : _89.contacts) === null || _90 === void 0 ? void 0 : _90.map((item) => ({
                label: item.title,
                value: item.type === 'phone'
                    ? `tel:${item.content}`
                    : item.type === 'email'
                        ? `mailto:${item.content}`
                        : item.content,
                vars: null,
            }))) || [],
        },
        notification: {
            title: ((_91 = values === null || values === void 0 ? void 0 : values.notify) === null || _91 === void 0 ? void 0 : _91.title) || '',
            appleLogo: appleNotifyLogo,
        },
    };
};
