import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { externalTooltipHandler } from '@/components/ChartTooltip/ChartTooltip';
import { useChart } from '@/hooks/useChart';
import { largeNumberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
const baseConfig = (isLocked) => ({
    type: 'line',
    options: {
        maintainAspectRatio: false,
        elements: {
            point: { radius: 0 },
            line: { tension: 0.11 },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: { display: false },
                ticks: { color: '#9B9B9B' },
            },
            y: {
                stacked: true,
                grid: { color: '#DFDFDF' },
                ticks: {
                    display: !isLocked,
                    color: '#9B9B9B',
                    callback: (value) => {
                        return largeNumberRound(Number(value), {
                            thousandThreshold: 1000,
                        });
                    },
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: { display: false },
            crosshair: {
                line: { color: '#9B9B9B' },
                snap: { enabled: true },
                zoom: {
                    enabled: true,
                    zoomboxBackgroundColor: 'rgba(230,225,216,0.2)',
                    zoomboxBorderColor: '#e6e1d8',
                    zoomButtonClass: 'reset-zoom-btn',
                    zoomButtonText: '',
                },
            },
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
        },
    },
});
export const AreaChartChart = memo(({ labels, data, bucketSize, isLocked = false }) => {
    const { i18n } = useTranslation();
    const shortMonth = (d) => moment(d)
        .locale(i18n.language)
        .format('MMM')
        .toLowerCase()
        .replace('.', '');
    const day = (d) => moment(d).locale(i18n.language).format('DD.MM.YYYY');
    const formatLabel = (d) => bucketSize === 'month' ? shortMonth(d) : day(d);
    const canvas = useChart(Object.assign(Object.assign({}, baseConfig(isLocked)), { data: {
            labels: labels.map((label) => typeof label === 'string' ? label : formatLabel(label)),
            datasets: data.map((item) => ({
                label: item.label,
                data: item.data,
                borderWidth: 1,
                borderColor: '#fff',
                backgroundColor: item.color,
                fill: true,
            })),
        } }));
    return (_jsx("div", Object.assign({ className: styles.chart }, { children: _jsx("canvas", { ref: canvas }) })));
});
