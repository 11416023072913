import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export const useSegmentConfiguration = (segment) => {
    const { t } = useTranslation();
    return useMemo(() => {
        if ((segment === null || segment === void 0 ? void 0 : segment.type) !== 'rfm')
            return segment === null || segment === void 0 ? void 0 : segment.configuration;
        switch (segment === null || segment === void 0 ? void 0 : segment.code) {
            case 'rfm_vip':
                return [
                    { text: t('Покупают часто и много') },
                    { text: t('Последняя покупка была недавно') },
                ];
            case 'rfm_rtl_vip':
                return [
                    { text: t('Покупали часто и много') },
                    { text: t('Последняя покупка была очень давно') },
                ];
            case 'rfm_maybe_rtl_vip':
                return [
                    { text: t('Покупали часто и много') },
                    { text: t('Последняя покупка была давно') },
                ];
            case 'rfm_pot_vip':
                return [
                    {
                        text: t('Покупают довольно часто с небольшими или средними чеками'),
                    },
                    { text: t('Последняя покупка была недавно') },
                ];
            case 'rfm_loyal':
                return [
                    { text: t('Покупают иногда с небольшими или средними чеками') },
                    { text: t('Последняя покупка была недавно') },
                ];
            case 'rfm_pot_loyal':
                return [
                    {
                        text: t('Покупали довольно часто с небольшими или средними чеками'),
                    },
                    { text: t('Последняя покупка была давно') },
                ];
            case 'rfm_maybe_rtl':
                return [
                    { text: t('Сделали мало покупок') },
                    { text: t('Последняя покупка была давно') },
                ];
            case 'rfm_rtl':
                return [
                    {
                        text: t('Покупали довольно часто с небольшими или средними чеками'),
                    },
                    { text: t('Последняя покупка была очень давно') },
                ];
            case 'rfm_new':
                return [
                    { text: t('Сделали мало покупок') },
                    { text: t('Последняя покупка была недавно') },
                ];
            case 'rfm_rtl_new':
                return [
                    { text: t('Сделали мало покупок') },
                    { text: t('Последняя покупка была очень давно') },
                ];
            case 'rfm_lost':
                return [{ text: t('Последняя покупка была слишком давно') }];
            default:
                return segment === null || segment === void 0 ? void 0 : segment.configuration;
        }
    }, [segment]);
};
