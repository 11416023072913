import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import { Toast } from './Toast';
export const ToastContext = createContext(() => { });
let toastCounter = 0;
export const ToastContextProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const addToast = useCallback((toast) => {
        setToasts((items) => [...items, Object.assign(Object.assign({}, toast), { id: toastCounter++ })]);
    }, [setToasts]);
    const handleToastClose = useCallback((id) => {
        setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    }, []);
    const simpleToasts = toasts.filter((t) => !t.title);
    const complexToases = toasts.filter((t) => !!t.title);
    return (_jsxs(ToastContext.Provider, Object.assign({ value: addToast }, { children: [children, createPortal(_jsx("div", Object.assign({ className: styles.simpleWrapper }, { children: simpleToasts.map((toast) => (_jsx(Toast, Object.assign({}, toast, { onClose: handleToastClose }), toast.id))) })), document.body), createPortal(_jsx("div", Object.assign({ className: styles.complexWrapper }, { children: complexToases.map((toast) => (_jsx(Toast, Object.assign({}, toast, { onClose: handleToastClose }), toast.id))) })), document.body)] })));
};
export const useToastContext = () => {
    return useContext(ToastContext);
};
