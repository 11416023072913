import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from '@/store/slice';
import * as thunks from '@/store/thunk/mailings';
import { processSimpleThunks } from '@/utils/reducers';
const initEmailDesignList = {
    status: FetchStatus.Idle,
    lastOffset: 0,
    hasMoreData: true,
};
const mailingsSlice = createSlice({
    name: 'mailings',
    initialState: {
        emailDesignList: initEmailDesignList,
        emailTemplateData: { status: FetchStatus.Idle },
    },
    reducers: {
        resetEmailDesignList(state) {
            state.emailDesignList = initEmailDesignList;
        },
    },
    extraReducers: (builder) => {
        processSimpleThunks(builder, {
            emailTemplateData: thunks.getTemplateDataThunk,
        });
        builder.addCase(thunks.getEmailTemplatesListThunk.pending, (state) => {
            state.emailDesignList.status = FetchStatus.Loading;
        });
        builder.addCase(thunks.getEmailTemplatesListThunk.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e, _f;
            if (!state.emailDesignList.data)
                state.emailDesignList.data = {
                    hasMoreData: false,
                    recent: [],
                    templates: [],
                };
            state.emailDesignList.status = FetchStatus.Finished;
            // Пагинация работает только для массива recent,
            // поэтому суммируем результаты последовательности запросов
            if (action.payload)
                if ((_b = (_a = state.emailDesignList) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.recent)
                    state.emailDesignList.data.recent.push(...action.payload.recent);
                else
                    state.emailDesignList.data.recent = (_c = action.payload) === null || _c === void 0 ? void 0 : _c.recent;
            // также актуализируем флаг hasMoreData
            state.emailDesignList.data.hasMoreData =
                ((_d = action.payload) === null || _d === void 0 ? void 0 : _d.hasMoreData) || false;
            // остальные шаблоны записываем один раз
            if (!state.emailDesignList.data.templates.length)
                state.emailDesignList.data.templates = ((_e = action.payload) === null || _e === void 0 ? void 0 : _e.templates) || [];
            state.emailDesignList.lastOffset = action.meta.arg.offset || 0;
            state.emailDesignList.hasMoreData = ((_f = action.payload) === null || _f === void 0 ? void 0 : _f.hasMoreData) || false;
        });
        builder.addCase(thunks.getEmailTemplatesListThunk.rejected, (state, action) => {
            state.emailDesignList.status = FetchStatus.Error;
            state.emailDesignList.error = action.error;
        });
    },
});
export const { resetEmailDesignList } = mailingsSlice.actions;
export default mailingsSlice.reducer;
