import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { paymentPostponedUntilSelector } from '@/store/selectors/billing';
import { postponePayment } from '@/store/thunk/billing';
export const usePostponePayment = () => {
    const dispatch = useAppDispatch();
    const paymentPostponedUntil = useAppSelector(paymentPostponedUntilSelector);
    const handlePostponePayment = useCallback(() => {
        dispatch(postponePayment());
    }, []);
    return {
        paymentPostponedUntil,
        handlePostponePayment,
    };
};
