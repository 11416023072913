/* eslint-disable curly */

import * as helpers from 'chart.js/dist/helpers.esm'

var defaultOptions = {
  line: {
    color: '#F66',
    width: 1,
    dashPattern: [],
  },
  zoom: {
    enabled: true,
    zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
    zoomboxBorderColor: '#48F',
    zoomButtonText: 'Reset Zoom',
    zoomButtonClass: 'reset-zoom',
    minRange: 0,
  },
  snap: {
    enabled: false,
  },
  callbacks: {},
}

var CrosshairPlugin = {
  id: 'crosshair',

  afterInit: function (chart) {
    if (!chart.config.options.scales.x) {
      return
    }

    var xScaleType = chart.config.options.scales.x.type
    if (
      xScaleType !== 'linear' &&
      xScaleType !== 'time' &&
      xScaleType !== 'category' &&
      xScaleType !== 'logarithmic'
    ) {
      return
    }

    if (chart.options.plugins.crosshair === undefined) {
      chart.options.plugins.crosshair = defaultOptions
    }

    chart.crosshair = {
      visible: false,
      x: null,
      originalXRange: null,
      dragStarted: false,
      dragStartX: null,
      dragEndX: null,
      reset: function () {
        this.resetZoom(chart, true)
      }.bind(this),
    }
  },

  destroy: function () {
    undefined
  },

  getOption: function (chart, category, name) {
    return helpers.valueOrDefault(
      chart.options.plugins.crosshair[category]
        ? chart.options.plugins.crosshair[category][name]
        : undefined,
      defaultOptions[category][name],
    )
  },

  getXScale: function (chart) {
    return chart.data.datasets.length
      ? chart.scales[chart.getDatasetMeta(0).xAxisID]
      : null
  },

  getYScale: function (chart) {
    return chart.scales[chart.getDatasetMeta(0).yAxisID]
  },

  afterEvent: function (chart, args) {
    if (!chart.crosshair) {
      return
    }

    let e = args.event

    if (
      e.type !== 'mousemove' &&
      e.type !== 'mouseup' &&
      e.type !== 'mousedown' &&
      e.type !== 'mouseout' &&
      e.type !== 'mousein' &&
      e.type !== 'click'
    ) {
      return
    }

    // nothing actually changed
    if (e.type === 'mousemove' && chart.crosshair.x === e.x) {
      return
    }

    var xScale = this.getXScale(chart)
    if (!xScale) {
      return
    }

    var xScaleMin = xScale.getPixelForValue(xScale.min)
    var xScaleMax = xScale.getPixelForValue(xScale.max)

    // update crosshair position
    chart.crosshair.visible =
      e.type !== 'mouseout' && e.x >= xScaleMin && e.x <= xScaleMax
    chart.crosshair.x = e.x

    // handle drag to zoom
    var zoomEnabled = this.getOption(chart, 'zoom', 'enabled')
    if (!zoomEnabled) {
      return
    }

    // fix for Safari
    var isPressed = !!(e.native.buttons === undefined
      ? e.native.which
      : e.native.buttons)
    if (e.native.type === 'mouseup') {
      isPressed = false
    }

    // press button
    if (isPressed && !chart.crosshair.dragStarted) {
      chart.crosshair.dragStartX = e.x
      chart.crosshair.dragStarted = true
    }

    // release button
    if (!isPressed && chart.crosshair.dragStarted) {
      chart.crosshair.dragStarted = false

      var zoomRangePixels = Math.abs(
        chart.crosshair.dragStartX - chart.crosshair.dragEndX,
      )
      if (zoomRangePixels > 1) {
        var start = xScale.getValueForPixel(chart.crosshair.dragStartX)
        var end = xScale.getValueForPixel(chart.crosshair.dragEndX)
        var zoomRange = Math.abs(start - end)
        var minRange = this.getOption(chart, 'zoom', 'minRange')
        if (!minRange || zoomRange >= minRange) {
          this.doZoom(chart, start, end)
        }
      }
    }

    // dragging
    if (isPressed && chart.crosshair.dragStarted) {
      chart.crosshair.dragEndX = e.x
      if (chart.crosshair.dragEndX < xScaleMin) {
        chart.crosshair.dragEndX = xScaleMin
      }
      if (chart.crosshair.dragEndX > xScaleMax) {
        chart.crosshair.dragEndX = xScaleMax
      }
    }

    // hide selection area on moving mouse out of chart
    if (chart.crosshair.dragStarted && e.type === 'mouseout') {
      chart.crosshair.dragEndX = chart.crosshair.dragStartX
    }

    args.changed = true
  },

  afterDraw: function (chart) {
    if (!chart.crosshair) {
      return true
    }

    if (chart.crosshair.visible && !chart.crosshair.dragStarted) {
      this.drawTraceLine(chart)
    }

    if (chart.crosshair.dragStarted) {
      this.drawZoombox(chart)
    }

    return true
  },

  beforeTooltipDraw: function (chart, args) {
    if (!chart.crosshair) {
      return true
    }

    // suppress tooltips on dragging
    args.cancelable = true
    return !chart.crosshair.dragStarted
  },

  resetZoom: function (chart) {
    // reset to original xRange
    if (chart.crosshair.originalXRange) {
      chart.options.scales.x.min = chart.crosshair.originalXRange.min
      chart.options.scales.x.max = chart.crosshair.originalXRange.max
      chart.crosshair.originalXRange = null
    }

    if (chart.crosshair.button && chart.crosshair.button.parentNode) {
      chart.crosshair.button.parentNode.removeChild(chart.crosshair.button)
      chart.crosshair.button = false
    }

    chart.update()
  },

  doZoom: function (chart, start, end) {
    // swap start/end if user dragged from right to left
    if (start > end) {
      var tmp = start
      start = end
      end = tmp
    }

    // notify delegate
    var beforeZoomCallback = this.getOption(chart, 'callbacks', 'beforeZoom')
    if (beforeZoomCallback && !beforeZoomCallback(start, end)) {
      return false
    }

    if (!chart.crosshair.originalXRange) {
      chart.crosshair.originalXRange = {
        min: chart.options.scales.x.min,
        max: chart.options.scales.x.max,
      }
    }

    if (!chart.crosshair.button) {
      // add restore zoom button
      var button = document.createElement('button')

      var buttonText = this.getOption(chart, 'zoom', 'zoomButtonText')
      var buttonClass = this.getOption(chart, 'zoom', 'zoomButtonClass')

      var buttonLabel = document.createTextNode(buttonText)
      button.appendChild(buttonLabel)
      button.className = buttonClass
      button.addEventListener(
        'click',
        function () {
          this.resetZoom(chart)
        }.bind(this),
      )
      chart.canvas.parentNode.prepend(button)
      chart.crosshair.button = button
    }

    // set axis scale
    chart.options.scales.x.min = start
    chart.options.scales.x.max = end

    var afterZoomCallback = this.getOption(chart, 'callbacks', 'afterZoom')
    if (afterZoomCallback) {
      afterZoomCallback(start, end)
    }

    chart.update()
  },

  drawZoombox: function (chart) {
    // zero-width box
    if (chart.crosshair.dragEndX === chart.crosshair.dragStartX) {
      return
    }

    var yScale = this.getYScale(chart)
    var yScaleMin = yScale.getPixelForValue(yScale.min)
    var yScaleMax = yScale.getPixelForValue(yScale.max)

    var borderColor = this.getOption(chart, 'zoom', 'zoomboxBorderColor')
    var fillColor = this.getOption(chart, 'zoom', 'zoomboxBackgroundColor')

    chart.ctx.beginPath()
    chart.ctx.rect(
      chart.crosshair.dragStartX,
      yScaleMax,
      chart.crosshair.dragEndX - chart.crosshair.dragStartX,
      yScaleMin - yScaleMax,
    )
    chart.ctx.lineWidth = 1
    chart.ctx.strokeStyle = borderColor
    chart.ctx.fillStyle = fillColor
    chart.ctx.fill()
    chart.ctx.fillStyle = ''
    chart.ctx.stroke()
    chart.ctx.closePath()
  },

  drawTraceLine: function (chart) {
    var lineWidth = this.getOption(chart, 'line', 'width')
    var color = this.getOption(chart, 'line', 'color')
    var dashPattern = this.getOption(chart, 'line', 'dashPattern')
    var snapEnabled = this.getOption(chart, 'snap', 'enabled')

    var lineX = chart.crosshair.x

    if (snapEnabled && chart._active.length) {
      lineX = chart._active[0].element.x
    }

    var yScale = this.getYScale(chart)

    chart.ctx.beginPath()
    chart.ctx.setLineDash(dashPattern)
    chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max))
    chart.ctx.lineWidth = lineWidth
    chart.ctx.strokeStyle = color
    chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min))
    chart.ctx.stroke()
    chart.ctx.setLineDash([])
  },
}

export default CrosshairPlugin
