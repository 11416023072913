import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { SegmentList } from '@/components/SegmentList/SegmentList';
import { Table } from '@/components/Table/Table';
import { TableEditRowButton } from '@/components/TableEditRowButton/TableEditRowButton';
import { Mail } from '@/icons/Mail';
import { MobilePhone } from '@/icons/MobilePhone';
import { getClientFullName } from '@/utils/getClientFullName';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import styles from './styles.module.scss';
export const ClientsTable = memo(({ clients = [], isLoading, onOpenClientOverview, onOpenClientEditForm, onSegmentClick, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const isUserCanEdit = userHasAccessTo([
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const columns = [
        {
            id: 'joinedAt',
            title: t('Дата вступления'),
            tooltip: t('Дата вступления клиента в клуб'),
        },
        {
            id: 'phone',
            title: t('Телефон'),
        },
        {
            id: 'name',
            title: t('Имя клиента'),
        },
        {
            id: 'bonuses',
            title: t('Бонусы'),
            tooltip: t('Указывается доступная сумма бонусов на счету клиента, а также сумма еще не подтвержденных бонусов (значение в скобках)'),
        },
        {
            id: 'email',
            title: t('Email'),
        },
        {
            id: 'isMailingsAllowed',
            title: t('Подписки'),
            tooltip: t('Статус согласия на получение SMS и/или Email рассылок'),
        },
        {
            id: 'segments',
            title: t('Сегменты'),
            tooltip: t('Обозначение принадлежности клиента к сегменту; сегментация нужна для персонализации рассылок, а также построения аналитики'),
        },
    ];
    if (isUserCanEdit)
        columns.push({
            id: 'edit-button',
            align: 'right',
        });
    const rows = clients.map((client) => {
        const row = {
            id: client.id,
            cells: [
                _jsxs(Fragment, { children: [(client.status === 3 || client.status === 4) && (_jsx("div", Object.assign({ className: styles.blocked }, { children: t('Заблокирован') }))), _jsxs("div", { children: [_jsx("div", { children: moment(client.joinedAt).format('DD.MM.YYYY') }), _jsx("div", { children: moment(client.joinedAt).format('HH:mm') })] })] }, 'joinedAt'),
                _jsx(Fragment, { children: _jsx("span", Object.assign({ className: styles.nowrap }, { children: formatPhoneNumber(client.phoneNumber) })) }, 'phone'),
                getClientFullName(client),
                _jsxs(Fragment, { children: [client.bonuses, client.pendingBonuses > 0 && (_jsxs("span", { children: [" (+", client.pendingBonuses, ")"] }))] }, 'bonuses'),
                client.email,
                _jsxs("div", Object.assign({ className: styles.mailingsContainer }, { children: [client.isPhoneSubscribed && _jsx(MobilePhone, {}), client.isEmailSubscribed && _jsx(Mail, {})] }), 'mailings'),
                _jsx(SegmentList, { segments: client.segments, onSegmentClick: onSegmentClick }, 'segments'),
            ],
        };
        if (isUserCanEdit)
            row.cells.push(_jsx(TableEditRowButton, { onClick: () => onOpenClientEditForm(client.id) }, 'edit-button'));
        return row;
    });
    return (_jsx(Table, { className: styles.table, onRowClick: (row) => onOpenClientOverview(Number(row.id)), isLoading: isLoading, columns: columns, rows: rows, hasEmptyState: true }));
});
