import { t } from 'i18next';
import { offersIsOrder } from '../../dataIsOrder';
export const createOffersList = (data) => {
    const offersObject = ((data === null || data === void 0 ? void 0 : data.offers) || []).map((item) => {
        const offers = offersIsOrder(item) ? item.orderOffers : item.purchaseOffers;
        return {
            title: item.title,
            value: offers.map(({ amount, bonusesCollected, item }) => {
                var _a;
                return ({
                    amount: amount || '0',
                    productTitle: ((_a = item.product) === null || _a === void 0 ? void 0 : _a.title) || t('без названия'),
                    count: item.itemCount || 0,
                    bonusesCollected: bonusesCollected || 0,
                });
            }),
        };
    });
    // Добавляем сумму скидок всех товаров с конкретной акцией
    const offers = offersObject === null || offersObject === void 0 ? void 0 : offersObject.map((offer) => (Object.assign(Object.assign({}, offer), { discountSum: offer.value.reduce((acc, { amount }) => acc + +(amount || ''), 0), isMoney: true, bonusesCollected: offer.value.reduce((acc, { bonusesCollected }) => acc + (bonusesCollected || 0), 0) })));
    return offers;
};
