import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Pagination } from '@/components/Pagination/Pagination';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { OffersTable } from './OffersTable';
export const OffersPanel = memo(({ activeCount, archivedCount, count, offers = [], total, shouldDisplayProfit, currency, timeZone, isLoading, isFetching, activeTabId, purchaseUrl, onChangeActiveTab, onDeleteOffer, onOpenPurchaseFilter, onGoToCreateForm, onGoToEditForm, limit, offset, onChangeOffset, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasOffersManage = userHasAccessTo('OFFERS_MANAGE');
    const createButton = (_jsx(Tooltip, Object.assign({ title: userHasOffersManage ? undefined : t('Недоступно для вашей роли') }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onGoToCreateForm, theme: 'primary', size: 'extra-large', disabled: !userHasOffersManage }, { children: t('Создать акцию') })) })));
    const pagination = !!count && (_jsx(Pagination, { total: count, limit: limit, offset: offset, onChangeOffset: onChangeOffset }));
    const tabs = [
        {
            id: 'active',
            label: t('Действующие'),
            count: activeCount,
        },
    ];
    if (archivedCount)
        tabs.push({
            id: 'archived',
            label: t('Архивные'),
            count: archivedCount,
        });
    return (_jsx(TabsPanel, Object.assign({ tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: onChangeActiveTab, rightContent: createButton, footer: pagination, isSkeleton: isLoading }, { children: _jsx(OffersTable, { offers: offers, total: total, shouldDisplayProfit: shouldDisplayProfit, currency: currency, timeZone: timeZone, isLoading: isFetching, purchaseUrl: purchaseUrl, onEditOffer: onGoToEditForm, onDeleteOffer: onDeleteOffer, onOpenPurchaseFilter: onOpenPurchaseFilter }) })));
});
