import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { useDeleteShop } from '@/forms/FormPointOfSale/useDeleteShop';
import { capitalize } from '@/utils/capitalize';
import styles from './styles.module.scss';
export const useTableData = ({ shopGroups, shops, onEdit, }) => {
    const { t } = useTranslation();
    const convertShopGroupToTableRow = useConvertShopGroupToTableRow();
    const convertShopToTableRow = useConvertShopToTableRow(onEdit);
    const columns = useMemo(() => [
        { id: 'name', title: t('Название'), description: t('Код') },
        { id: 'region', title: t('Регион') },
        { id: 'tz', title: t('Часовой пояс') },
        { id: 'createdAt', title: t('Дата добавления') },
        { id: 'actions' },
    ].filter((i) => i !== null), [t]);
    const rows = useMemo(() => {
        const tableBodies = {};
        shopGroups.forEach((shopGroup) => {
            const items = shops.filter((shop) => shop.shopGroupId === +shopGroup.id);
            if (!items.length)
                return;
            const groupBody = `shop-group-${shopGroup.id}`;
            const groupShopsBody = `shop-group-${shopGroup.id}-shops`;
            tableBodies[groupBody] = { rows: [], childGroupId: groupShopsBody };
            tableBodies[groupBody].rows.push(convertShopGroupToTableRow(shopGroup));
            tableBodies[groupShopsBody] = { type: 'sub-group', rows: [] };
            items.forEach((shop) => {
                tableBodies[groupShopsBody].rows.push(convertShopToTableRow(shop));
            });
        });
        const noGroupItems = shops.filter((shop) => !shop.shopGroupId);
        if (noGroupItems.length) {
            const groupBody = 'shop-group-no-group';
            const groupShopsBody = 'shop-group-no-group-shops';
            tableBodies[groupBody] = { rows: [], childGroupId: groupShopsBody };
            tableBodies[groupBody].rows.push({
                id: 'no-group',
                cells: [capitalize(t('вне групп')), '', '', '', null],
            });
            tableBodies[groupShopsBody] = { type: 'sub-group', rows: [] };
            noGroupItems.forEach((shop) => {
                tableBodies[groupShopsBody].rows.push(convertShopToTableRow(shop));
            });
        }
        return tableBodies;
    }, [shopGroups, shops, onEdit, t]);
    return { columns: columns, rows };
};
const useConvertShopGroupToTableRow = () => {
    return (shopGroup) => {
        return {
            id: shopGroup.id,
            cells: [
                shopGroup.name,
                '',
                '',
                moment(shopGroup.createdAt).format('DD.MM.YYYY'),
                null,
            ],
        };
    };
};
const useConvertShopToTableRow = (onEdit) => {
    const { t } = useTranslation();
    const deleteShop = useDeleteShop();
    const userHasAccessTo = useUserPermissions();
    const userHasShopManage = userHasAccessTo([
        'SHOPS_OWN_MANAGE',
        'SHOPS_MANAGE',
    ]);
    return (shop) => {
        var _a, _b;
        return {
            id: shop.id,
            cells: [
                _jsxs(React.Fragment, { children: [shop.name, _jsx("div", Object.assign({ className: styles.description }, { children: shop.code }))] }, shop.id),
                ((_a = shop.timezone) === null || _a === void 0 ? void 0 : _a.region) || '–',
                ((_b = shop.timezone) === null || _b === void 0 ? void 0 : _b.tz) || '–',
                moment(shop.createdAt).format('DD.MM.YYYY'),
            ],
            actions: [
                {
                    id: 'edit',
                    tooltip: !userHasShopManage && t('Недоступно для вашей роли'),
                    label: t('Редактировать'),
                    onClick: () => onEdit(shop),
                    isDisabled: !userHasShopManage,
                },
                {
                    id: 'delete',
                    tooltip: !userHasShopManage && t('Недоступно для вашей роли'),
                    label: t('Удалить'),
                    onClick: () => {
                        deleteShop(shop.id, shop.name);
                    },
                    isDanger: true,
                    isDisabled: !userHasShopManage,
                },
            ],
        };
    };
};
