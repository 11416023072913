import moment from 'moment';
export var PresetPeriod;
(function (PresetPeriod) {
    PresetPeriod["Today"] = "today";
    PresetPeriod["Yesterday"] = "yesterday";
    PresetPeriod["Week"] = "week";
    PresetPeriod["Month"] = "month";
    PresetPeriod["Quarter"] = "quarter";
    PresetPeriod["Year"] = "year";
    PresetPeriod["AllTime"] = "alltime";
})(PresetPeriod || (PresetPeriod = {}));
export const getPresetPeriod = (period) => {
    let start = moment().startOf('day');
    let end = moment().endOf('day');
    switch (period) {
        case PresetPeriod.Yesterday: {
            start = moment().startOf('day').subtract(1, 'day');
            end = moment().endOf('day').subtract(1, 'day');
            break;
        }
        case PresetPeriod.Week: {
            start = moment().startOf('day').subtract(7, 'day');
            break;
        }
        case PresetPeriod.Month: {
            start = moment().startOf('day').subtract(1, 'month');
            break;
        }
        case PresetPeriod.Quarter: {
            start = moment().startOf('day').subtract(3, 'month');
            break;
        }
        case PresetPeriod.Year: {
            start = moment().startOf('day').subtract(12, 'month');
            break;
        }
        case PresetPeriod.AllTime: {
            start = moment('2017-01-01');
            break;
        }
    }
    return [start, end];
};
export const isPresetEqualsToRange = (preset, range) => {
    const [presetStart, presetEnd] = getPresetPeriod(preset);
    const [start, end] = range;
    return (moment(start).format('DD.MM.YYYY') === presetStart.format('DD.MM.YYYY') &&
        moment(end).format('DD.MM.YYYY') === presetEnd.format('DD.MM.YYYY'));
};
