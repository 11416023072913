import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Popup } from '@/components/Popup/Popup';
import { Chevron } from '@/icons/Chevron';
import styles from './styles.module.scss';
import { VarItem } from './VarItem';
export const VarGroup = ({ group, onItemClick, currency, }) => {
    const ref = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    return (_jsx(_Fragment, { children: _jsxs("li", Object.assign({ ref: ref, className: classNames(styles.varItem, {
                [styles.active]: popupIsOpen,
            }), onMouseEnter: () => setPopupIsOpen(true), onMouseLeave: () => setPopupIsOpen(false) }, { children: [group.title, _jsx("span", Object.assign({ className: styles.chevron }, { children: _jsx(Chevron, { fill: 'currentColor' }) })), _jsx(Popup, Object.assign({ className: styles.nestedPopup, isOpen: popupIsOpen, anchorEl: ref.current, onClose: () => setPopupIsOpen(false), placement: 'right-start', offset: [0, -20], disablePortal: true, isTransparent: true }, { children: _jsx("div", Object.assign({ className: styles.varGroupWrapper }, { children: _jsx("ul", Object.assign({ className: classNames(styles.varList, styles.varGroup) }, { children: group.variables.map((v) => (_jsx(VarItem, { item: v, onClick: onItemClick, currency: currency }, v.id))) })) })) }))] })) }));
};
