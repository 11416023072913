import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { TopAlert } from './TopAlert';
import { usePostponePayment } from './usePostponePayment';
export const TopAlertContainer = () => {
    const navigate = useNavigate();
    const { user, brand } = useCurrentUser();
    const { paymentPostponedUntil, handlePostponePayment } = usePostponePayment();
    if (!brand || !user)
        return null;
    return (_jsx(TopAlert, { brand: brand.brand, balance: brand.balance, user: user, onGoToBalance: () => navigate(brand.brand.settings.inn ? ROUTES.BILLING_INVOICES : ROUTES.BILLING), paymentPostponedUntil: paymentPostponedUntil, onPostponePayment: handlePostponePayment }));
};
