import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { AnalyticsTableModal } from '@/components/AnalyticsTableModal/AnalyticsTableModal';
import { AnalyticsTable } from './AnalyticsTable';
import { useTableData } from './useTableData';
import { useUpdateTableSettings } from './useUpdateTableSettings';
export const AnalyticsTableContainer = ({ currency, onSegmentClick, isRFMEnabled }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
    const handleCloseModal = useCallback(() => setModalIsOpen(false), []);
    const { metrics, dimensions, limit, tableData, isLoadingRoot, toggleNode } = useTableData();
    const updateTableSettings = useUpdateTableSettings();
    return (_jsxs(_Fragment, { children: [_jsx(AnalyticsTable, { selectedMetricKeys: metrics, data: tableData, dimensions: dimensions, currency: currency, isLoadingRoot: isLoadingRoot, onToggleNode: toggleNode, onGroupingClick: handleOpenModal, onSegmentClick: onSegmentClick }), _jsx(AnalyticsTableModal, { selectedDimensions: dimensions, selectedMaxCount: limit, isOpen: modalIsOpen, onClose: handleCloseModal, onApply: updateTableSettings, isRFMEnabled: isRFMEnabled })] }));
};
