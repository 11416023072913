import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { FormInvoice } from './FormInvoice';
import styles from './styles.module.scss';
export const FormInvoiceModal = memo(({ id = 'invoice-form', isOpen, onClose, error, onSubmit, isSubmitting }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), controlClassName: classNames(styles.errorText), asErrorMessage: true, asModalFooter: true, hasLabelHidden: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', disabled: isSubmitting, size: 'large' }, { children: t('Выставить счет') })) }))] }), size: '550', hasNoPadding: true }, { children: _jsx("div", Object.assign({ className: styles.body }, { children: _jsx(FormInvoice, { id: id, onSubmit: onSubmit }) })) })));
});
