import { useMemo } from 'react';
import { useGetRFMMovementListQuery } from '@/api/query/rfm';
import { useAppSelector } from '@/hooks/store';
import { compileFilters } from '@/services/filters/compileFilters';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useMovementList = (rawFilters) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const filters = useMemo(() => compileFilters(rawFilters || []), [JSON.stringify(rawFilters)]);
    const { data: movements = [], isLoading, isFetching, } = useGetRFMMovementListQuery({
        periodStart: periodStart,
        periodEnd: periodEnd,
        filters,
    });
    return { movements, isLoading, isFetching };
};
