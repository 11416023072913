export const RFMMovementStatus = {
    'rfm_vip--rfm_rtl_vip': 'negative',
    'rfm_vip--rfm_maybe_rtl_vip': 'negative',
    'rfm_pot_vip--rfm_vip': 'positive',
    'rfm_pot_vip--rfm_pot_loyal': 'negative',
    'rfm_pot_vip--rfm_rtl': 'negative',
    'rfm_pot_vip--rfm_rtl_vip': 'negative',
    'rfm_pot_vip--rfm_maybe_rtl_vip': 'negative',
    'rfm_loyal--rfm_vip': 'positive',
    'rfm_loyal--rfm_pot_vip': 'positive',
    'rfm_loyal--rfm_rtl_vip': 'negative',
    'rfm_loyal--rfm_pot_loyal': 'negative',
    'rfm_loyal--rfm_rtl': 'negative',
    'rfm_new--rfm_vip': 'positive',
    'rfm_new--rfm_pot_vip': 'positive',
    'rfm_new--rfm_loyal': 'positive',
    'rfm_new--rfm_maybe_rtl_vip': 'negative',
    'rfm_new--rfm_rtl_vip': 'negative',
    'rfm_new--rfm_rtl': 'negative',
    'rfm_new--rfm_pot_loyal': 'negative',
    'rfm_new--rfm_maybe_rtl': 'negative',
    'rfm_new--rfm_rtl_new': 'negative',
    'rfm_maybe_rtl_vip--rfm_vip': 'positive',
    'rfm_maybe_rtl_vip--rfm_rtl_vip': 'negative',
    'rfm_maybe_rtl_vip--rfm_maybe_rtl_vip': 'negative',
    'rfm_pot_loyal--rfm_vip': 'positive',
    'rfm_pot_loyal--rfm_pot_vip': 'positive',
    'rfm_pot_loyal--rfm_loyal': 'positive',
    'rfm_pot_loyal--rfm_maybe_rtl_vip': 'positive',
    'rfm_pot_loyal--rfm_rtl': 'negative',
    'rfm_pot_loyal--rfm_rtl_vip': 'negative',
    'rfm_pot_loyal--rfm_pot_loyal': 'negative',
    'rfm_maybe_rtl--rfm_vip': 'positive',
    'rfm_maybe_rtl--rfm_pot_vip': 'positive',
    'rfm_maybe_rtl--rfm_loyal': 'positive',
    'rfm_maybe_rtl--rfm_pot_loyal': 'positive',
    'rfm_maybe_rtl--rfm_maybe_rtl_vip': 'positive',
    'rfm_maybe_rtl--rfm_rtl_vip': 'negative',
    'rfm_maybe_rtl--rfm_rtl': 'negative',
    'rfm_maybe_rtl--rfm_rtl_new': 'negative',
    'rfm_rtl_vip--rfm_vip': 'positive',
    'rfm_rtl_vip--rfm_maybe_rtl_vip': 'positive',
    'rfm_rtl--rfm_vip': 'positive',
    'rfm_rtl--rfm_pot_vip': 'positive',
    'rfm_rtl--rfm_loyal': 'positive',
    'rfm_rtl--rfm_maybe_rtl_vip': 'positive',
    'rfm_rtl--rfm_pot_loyal': 'positive',
    'rfm_rtl--rfm_rtl_vip': 'positive',
    'rfm_rtl_new--rfm_maybe_rtl': 'positive',
    'rfm_rtl_new--rfm_new': 'positive',
    'rfm_rtl_new--rfm_loyal': 'positive',
    'rfm_rtl_new--rfm_pot_vip': 'positive',
    'rfm_rtl_new--rfm_vip': 'positive',
    'rfm_rtl_new--rfm_maybe_rtl_vip': 'positive',
    'rfm_rtl_new--rfm_rtl_vip': 'positive',
    'rfm_rtl_new--rfm_pot_loyal': 'positive',
    'rfm_rtl_new--rfm_rtl': 'positive',
};
export const getMovementStatus = (from, to) => {
    const fromCode = typeof from === 'string' ? from : from.code;
    const toCode = typeof to === 'string' ? to : to.code;
    const key = `${fromCode}--${toCode}`;
    return RFMMovementStatus[key];
};
