import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { APPLY_ON_ALL_UNITS, APPLY_ON_CONDITION_UNITS, APPLY_ON_SOME_UNITS, } from '../constants';
import styles from '../styles.module.scss';
export const FormOfferEachN = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const offerType = watch('type');
    const applyOn = watch('applyOn');
    const eachN = watch('eachN');
    const shouldDisplay = (applyOn === APPLY_ON_SOME_UNITS ||
        applyOn === APPLY_ON_ALL_UNITS ||
        applyOn === APPLY_ON_CONDITION_UNITS) &&
        offerType !== 'growing_discount';
    const options = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20];
    if (shouldDisplay)
        return (_jsx(FormFieldRow, Object.assign({ className: styles.checkboxRow, labelWidth: labelWidth, alignItems: 'center', asCheckbox: true }, { children: _jsx(Controller, { name: 'eachNOpted', control: control, render: ({ field }) => (_jsxs(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                        setValue('eachNOpted', e.target.checked);
                    }, preventInnerClicks: true }, { children: [t('на каждый'), _jsx(Autocomplete, { value: eachN || null, onChange: (eachN) => {
                                setValue('eachN', eachN);
                                setValue('eachNOpted', true);
                            }, options: options, getOptionKey: (option) => option, getOptionLabel: (option) => option.toString(), hasResetButton: false, minListWidth: 70, asSelect: true, isInline: true }), t('-й товар в чеке')] }))) }) })));
    return null;
};
