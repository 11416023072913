import { useTranslation } from 'react-i18next';
import { convertLeafToSavedFilter } from '@/services/filters/convertLeafToSavedFilter';
const str = (value) => {
    if (typeof value === 'undefined')
        return '';
    return value + '';
};
export const useBuildRFMConditions = () => {
    const { t } = useTranslation();
    return (rawConditions, currency) => {
        const conditions = rawConditions;
        const filters = [];
        if ('purchaseTotalAmountGe' in conditions ||
            'purchaseTotalAmountLe' in conditions) {
            const purchaseTotalAmount = {
                type: 'leaf',
                id: 'purchaseTotalAmount',
                label: t('Сумма покупок'),
                template: 'numRange',
                inputPostfix: currency,
                composePostfixToText: true,
                value: {
                    operator: 'include',
                    min: str(conditions.purchaseTotalAmountGe),
                    max: str(conditions.purchaseTotalAmountLe),
                },
            };
            filters.push(convertLeafToSavedFilter(purchaseTotalAmount));
        }
        if ('purchaseAvgDaysBetweenGe' in conditions ||
            'purchaseAvgDaysBetweenLe' in conditions) {
            const purchaseTotalAmount = {
                type: 'leaf',
                id: 'purchaseAvgDaysBetween',
                label: t('Дней между покупками'),
                template: 'numRange',
                inputPostfix: t('дней'),
                value: {
                    operator: 'include',
                    min: str(conditions.purchaseAvgDaysBetweenGe),
                    max: str(conditions.purchaseAvgDaysBetweenLe),
                },
            };
            filters.push(convertLeafToSavedFilter(purchaseTotalAmount));
        }
        if ('clientPurchasesCountGe' in conditions ||
            'clientPurchasesCountLe' in conditions) {
            const purchaseTotalAmount = {
                type: 'leaf',
                id: 'clientPurchasesCount',
                label: t('Всего покупок (для RFM)'),
                template: 'numRange',
                value: {
                    operator: 'include',
                    min: str(conditions.clientPurchasesCountGe),
                    max: str(conditions.clientPurchasesCountLe),
                },
            };
            filters.push(convertLeafToSavedFilter(purchaseTotalAmount));
        }
        return { filters, touched: false };
    };
};
