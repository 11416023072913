import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { ArrowUp } from '@/icons/ArrowUp';
import styles from './styles.module.scss';
export const StatsMetric = memo(({ title, tooltip, value, description, descriptionArrow, footerTitle, footerValue, background, isLoading = false, }) => {
    return (_jsx(StatsPanel, Object.assign({ className: classNames(styles.panel, {
            [styles.green]: background === 'green',
            [styles.red]: background === 'red',
        }), isLoading: isLoading }, { children: _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx("h4", Object.assign({ className: styles.title }, { children: tooltip ? (_jsx(HelpTooltip, Object.assign({ title: tooltip, placement: 'top', hasCurrentColor: !!background }, { children: title }))) : (title) })), _jsx("div", { className: styles.value, dangerouslySetInnerHTML: { __html: value } }), description && (_jsxs("div", Object.assign({ className: styles.description }, { children: [description, descriptionArrow === 'up' && (_jsx("span", Object.assign({ className: styles.arrow }, { children: _jsx(ArrowUp, {}) }))), descriptionArrow === 'down' && (_jsx("span", Object.assign({ className: classNames(styles.arrow, styles.down) }, { children: _jsx(ArrowUp, {}) })))] }))), _jsxs("div", Object.assign({ className: classNames(styles.footer, styles.nowrap) }, { children: [_jsx("span", Object.assign({ className: styles.wrap }, { children: footerTitle })), _jsx(_Fragment, { children: "\u00A0" }), _jsx("span", Object.assign({ className: styles.badge }, { children: footerValue }))] }))] })) })));
});
