const isScriptInjected = (url) => {
    const scripts = document.querySelectorAll('script');
    let injected = false;
    scripts.forEach((script) => {
        if (script.src.includes(url))
            injected = true;
    });
    return injected;
};
export const loadScript = (url, callback) => {
    if (!isScriptInjected(url)) {
        const script = document.createElement('script');
        script.setAttribute('src', url);
        script.onload = () => {
            callback();
        };
        document.head.appendChild(script);
    }
    else
        callback();
};
