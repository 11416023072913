import { useReactFlow } from '@xyflow/react';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { TRIGGER_FILTERS_REQUIRED_MAP } from '../nodes/TriggerNode/utils';
import { nodeHasOutputEdges } from '../nodes/utils';
export const useNodeCanBeDeleted = () => {
    const flow = useReactFlow();
    const { triggerType } = useInteractiveFlow();
    return (nodeId) => {
        const node = flow.getNode(nodeId);
        if (!node)
            return false;
        switch (node.type) {
            case 'trigger':
            case 'loop':
            case 'new-step': {
                return false;
            }
            case 'delay':
            case 'email-received':
            case 'channel-received': {
                return true;
            }
            case 'channel': {
                return !nodeHasOutputEdges(flow, node);
            }
            case 'conditions': {
                const data = node.data;
                if (data.type === 'filter')
                    return true;
                if (data.type === 'trigger' || data.type === 'schedule') {
                    const isRequred = TRIGGER_FILTERS_REQUIRED_MAP[triggerType];
                    if (isRequred)
                        return !nodeHasOutputEdges(flow, node);
                    return true;
                }
                return false;
            }
            default: {
                return false;
            }
        }
    };
};
