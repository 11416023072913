import { api } from './';
export const countersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCounterList: build.query({
            query: () => ({
                method: 'GET',
                url: '/offer/counters-list',
            }),
            providesTags: ['CounterList'],
        }),
    }),
});
export const { useGetCounterListQuery } = countersApi;
