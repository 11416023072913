var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { useToastContext } from '@/components/Toast/ToastContext';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Magic } from '@/icons/Magic';
import { Draft } from '@/icons/status/Draft';
import { Sending } from '@/icons/status/Sending';
import styles from './styles.module.scss';
export const WalletCardPublish = ({ isFirstPublish = false, walletCardsDefined = false, walletGeosDefined = false, hasChangesWalletCards = false, hasChangesWalletGeos = false, hasErrors = false, onPublish, }) => {
    const { t } = useTranslation();
    const [isPublishing, setIsPublishing] = useState(false);
    const addToast = useToastContext();
    const userHasAccessTo = useUserPermissions();
    const userHasWalletCardsManage = userHasAccessTo('WALLET_CARDS_MANAGE');
    const handlePublish = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsPublishing(true);
        let errorMessage = '';
        try {
            errorMessage = onPublish ? yield onPublish() : '';
        }
        catch (e) {
            errorMessage = 'Не удалось опубликовать карты';
        }
        if (!errorMessage)
            addToast({
                type: 'success',
                message: t('Карты опубликованы'),
            });
        else if (!['validation-errors', 'server-errors'].includes(errorMessage))
            addToast({
                type: 'error',
                message: t(errorMessage),
            });
        setIsPublishing(false);
    });
    const publishButton = (_jsx(Tooltip, Object.assign({ title: !userHasWalletCardsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsxs(Button, Object.assign({ className: classNames({ [styles.loading]: isPublishing }), theme: 'primary', size: 'full-width', disabled: !userHasWalletCardsManage || !walletCardsDefined || isPublishing, onClick: handlePublish }, { children: [isPublishing && _jsx(Sending, { className: styles.loader }), t('Опубликовать все карты')] })) })));
    if (hasErrors)
        return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: _jsx(Button, Object.assign({ theme: 'primary', size: 'full-width', disabled: true, onClick: handlePublish }, { children: t('Опубликовать все карты') })) })));
    if (isFirstPublish) {
        let title = '';
        if (!walletCardsDefined)
            title = 'Настройте уровни всех карт и геопуши';
        if (walletCardsDefined && !walletGeosDefined)
            title = 'Не забудьте про геопуши';
        if (walletCardsDefined && walletGeosDefined)
            title = 'Все настроено!';
        let description = '';
        if (walletCardsDefined)
            description =
                'Карты, уже установленные у клиентов, получат обновление в течение суток';
        return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(Magic, {}), title && _jsx("div", Object.assign({ className: styles.title }, { children: t(title) })), publishButton, description && (_jsx("div", Object.assign({ className: styles.description }, { children: t(description) })))] })));
    }
    if (hasChangesWalletCards || hasChangesWalletGeos) {
        let title = '';
        if (hasChangesWalletCards && hasChangesWalletGeos)
            title = 'Изменения в картах и геопушах';
        if (hasChangesWalletCards && !hasChangesWalletGeos)
            title = 'Изменения в картах';
        if (!hasChangesWalletCards && hasChangesWalletGeos)
            title = 'Изменения в геопушах';
        const description = 'Карты, уже установленные у клиентов, получат обновление в течение суток';
        return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(Magic, {}), title && (_jsxs("div", Object.assign({ className: styles.title }, { children: [_jsx(Draft, {}), t(title)] }))), publishButton, description && (_jsx("div", Object.assign({ className: styles.description }, { children: t(description) })))] })));
    }
    return null;
};
