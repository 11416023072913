import { $createParagraphNode, $createTextNode, } from 'lexical';
import { $createSpaceNode } from '../plugins/SpacePlugin/SpaceNode';
import { $createVariableNode } from '../plugins/VariablePlugin/VariableNode';
import { textHandler } from './textHandler';
export const textToNodes = (text, variables) => {
    const nodes = [];
    let parNode = null;
    const appendNode = (node) => {
        if (parNode)
            parNode.append(node);
        else
            nodes.push(node);
    };
    textHandler(text, variables, {
        onLine: (index) => {
            if (index !== 0) {
                parNode = $createParagraphNode();
                nodes.push(parNode);
            }
            else
                parNode = null;
        },
        onVariable: (variable) => {
            const varNode = $createVariableNode(variable);
            appendNode(varNode);
        },
        onText: (text) => {
            const textNode = $createTextNode(text);
            appendNode(textNode);
        },
        onSpace: () => {
            const spaceNode = $createSpaceNode();
            appendNode(spaceNode);
        },
    });
    return nodes;
};
