import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { AutoMailingsFlowForm } from './auto-mailings-form/AutoMailingsFlowForm';
import { InteractiveFlowForm } from './interactive-form/InteractiveFlowForm';
import { useGlobalUI } from './useGlobalUI';
import { useSelectedFlow } from './useSelectedFlow';
export const FlowsForm = () => {
    var _a;
    useGlobalUI();
    const { brand } = useCurrentUser();
    const location = useLocation();
    const from = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from;
    const { selectedFlow, isLoadingSelectedFlow } = useSelectedFlow();
    if (!brand)
        return null;
    if (isLoadingSelectedFlow)
        return null;
    if ((selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.config) || from === 'flow-trigger-modal')
        return _jsx(InteractiveFlowForm, { selectedFlow: selectedFlow });
    return _jsx(AutoMailingsFlowForm, { selectedFlow: selectedFlow });
};
