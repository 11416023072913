import { t } from 'i18next';
import styles from '../styles.module.scss';
export const createHeadItems = () => [
    {
        id: 'empty',
        title: '',
        align: 'center',
        width: 6,
    },
    {
        id: 'name',
        title: t('Акция'),
        align: 'left',
        width: 250,
    },
    {
        id: 'discount-sum',
        title: t('Сумма скидок'),
        align: 'right',
        width: 146,
        className: styles.cellHeadPadRight24,
    },
    {
        id: 'bonus',
        title: `+ ${t('Бонусы')}`,
        align: 'right',
        width: 103,
        className: styles.cellHeadPadRight24,
    },
];
