var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorMessage } from '@/api/query';
import { useDiscardPurchaseTicketMutation } from '@/api/query/purchases';
export const useDiscardPurchaseTicket = () => {
    const [discardTicket] = useDiscardPurchaseTicketMutation();
    const handleDiscardPurchaseTicket = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield discardTicket(payload);
        const error = getErrorMessage(result);
        if (error)
            return error;
    });
    return handleDiscardPurchaseTicket;
};
