import { createSlice } from '@reduxjs/toolkit';
import { getPeriodFromLocation } from '@/utils/getPeriodFromLocation';
import { getPresetPeriod, PresetPeriod } from '@/utils/getPresetPeriod';
const fromLocation = getPeriodFromLocation();
const initialState = {
    start: (fromLocation === null || fromLocation === void 0 ? void 0 : fromLocation.start) || getPresetPeriod(PresetPeriod.Month)[0],
    end: (fromLocation === null || fromLocation === void 0 ? void 0 : fromLocation.end) || getPresetPeriod(PresetPeriod.Month)[1],
    pagination: {
        purchases: { limit: 100, offset: 0 },
        orders: { limit: 100, offset: 0 },
        ordersCancelled: { limit: 100, offset: 0 },
    },
};
const appPeriodSlice = createSlice({
    name: 'appPeriod',
    initialState,
    reducers: {
        changePeriod: (state, action) => {
            state.start = action.payload.start;
            state.end = action.payload.end;
            state.pagination.purchases.offset = 0;
            state.pagination.orders.offset = 0;
            state.pagination.ordersCancelled.offset = 0;
        },
        changeOffset: (state, action) => {
            const { list, offset } = action.payload;
            state.pagination[list].offset = offset;
        },
    },
});
export const { changePeriod, changeOffset } = appPeriodSlice.actions;
export default appPeriodSlice.reducer;
