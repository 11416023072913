import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InteractiveFlow } from '@/components/flow/InteractiveFlow/InteractiveFlow';
import { InteractiveFlowConsumer } from '@/components/flow/InteractiveFlow/InteractiveFlowConsumer';
import { InteractiveFlowHeader } from './InteractiveFlowHeader';
import styles from './styles.module.scss';
import { useUpdateDraft } from './useUpdateDraft';
export const InteractiveFlowForm = ({ selectedFlow, }) => {
    var _a, _b, _c, _d, _e, _f;
    const location = useLocation();
    const trigger = (_a = location.state) === null || _a === void 0 ? void 0 : _a.trigger;
    const triggerType = (selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.triggerType) || trigger || 0;
    const triggerLabel = (_b = location.state) === null || _b === void 0 ? void 0 : _b.triggerLabel;
    const [currentNodes, setCurrentNodes] = useState(((_c = selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.config) === null || _c === void 0 ? void 0 : _c.nodes) || []);
    const [currentEdges, setCurrentEdges] = useState(((_d = selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.config) === null || _d === void 0 ? void 0 : _d.edges) || []);
    const { updateDraft, changedFlow } = useUpdateDraft();
    return (_jsx(InteractiveFlow, Object.assign({ triggerType: triggerType, triggerLabel: triggerLabel, onChange: (nodes, edges) => {
            setCurrentNodes(nodes);
            setCurrentEdges(edges);
            if (selectedFlow)
                updateDraft(selectedFlow, nodes, edges);
        } }, { children: _jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(InteractiveFlowHeader, { flow: changedFlow || selectedFlow, currentNodes: currentNodes, currentEdges: currentEdges, triggerType: triggerType }), _jsx(InteractiveFlowConsumer, { defaultNodes: clearDefaultNodes((_e = selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.config) === null || _e === void 0 ? void 0 : _e.nodes), defaultEdges: clearDefaultEdges((_f = selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.config) === null || _f === void 0 ? void 0 : _f.edges) })] })) })));
};
const clearDefaultNodes = (nodes) => {
    return cloneDeep(nodes === null || nodes === void 0 ? void 0 : nodes.map((node) => (Object.assign(Object.assign({}, node), { style: { opacity: 0 } }))));
};
const clearDefaultEdges = (edges) => {
    return cloneDeep(edges === null || edges === void 0 ? void 0 : edges.map((edge) => (Object.assign(Object.assign({}, edge), { style: { opacity: 0 } }))));
};
