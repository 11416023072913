import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { forwardRef, useCallback, useState } from 'react';
import { Button } from '@/components/Button2/Button';
import { Plus } from '@/icons/Plus';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { useAvailableSteps, useAvailableTriggers, } from '../../../hooks/useAvailableSteps';
import { NewStepPopup } from '../../../nodes/NewStepNode/NewStepPopup';
import { findNodeById, flowHasNewStepNode } from '../../utils';
import styles from './styles.module.scss';
export const EdgePlusButton = ({ edgeId = '', buttonIsVisible = false, x, y, }) => {
    const flow = useReactFlow();
    const { newStepPopup } = useInteractiveFlow();
    const [plusButton, setPlusButton] = useState(null);
    const clickedEdge = flow.getEdge(edgeId);
    const sourceNode = findNodeById(flow, clickedEdge === null || clickedEdge === void 0 ? void 0 : clickedEdge.source);
    const targetNode = findNodeById(flow, clickedEdge === null || clickedEdge === void 0 ? void 0 : clickedEdge.target);
    const availableTriggerFilters = useAvailableTriggers(sourceNode, targetNode);
    const availableSteps = useAvailableSteps(sourceNode, targetNode);
    if (flowHasNewStepNode(flow))
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(NewStepPopup, { isOpen: newStepPopup.isOpenForEdge(edgeId), onClose: newStepPopup.close, anchorEl: plusButton, triggerFilters: availableTriggerFilters.availableTriggerFilters, isChannelTriggers: availableTriggerFilters.isChannelTriggers, isEmailTriggers: availableTriggerFilters.isEmailTriggers, steps: availableSteps }), _jsx(PlusButton, { ref: setPlusButton, isVisible: buttonIsVisible, x: x, y: y, isPinned: newStepPopup.isOpenForEdge(edgeId), onClick: () => newStepPopup.toggleForEdge(edgeId) })] }));
};
const PlusButton = forwardRef(({ isVisible = false, x, y, isPinned = false, onClick }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = useCallback(() => setIsHovered(true), []);
    const handleMouseLeave = useCallback(() => setIsHovered(false), []);
    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick === null || onClick === void 0 ? void 0 : onClick();
    }, [onClick]);
    return (_jsx(Button, { ref: ref, className: classNames(styles.button, {
            [styles.visible]: isVisible,
            [styles.pinned]: isPinned,
        }, 'nodrag', 'nopan'), style: {
            transform: isVisible || isPinned
                ? isHovered || isPinned
                    ? `translate(-50%, -50%) translate(${x}px, ${y}px) scale(1)`
                    : `translate(-50%, -50%) translate(${x}px, ${y}px) scale(0.7)`
                : `translate(-50%, -50%) translate(${x}px, ${y}px) scale(0)`,
        }, type: 'button', theme: 'primary', icon: _jsx(Plus, {}), isActive: isPinned, onClick: handleClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }));
});
