var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import styles from './styles.module.scss';
export const Button = forwardRef((_a, ref) => {
    var { theme = 'default', size = 'normal', isOutlined = false, icon, className, children, isActive = false, hasIconMargin = true, hasLargeIconMargin = false } = _a, props = __rest(_a, ["theme", "size", "isOutlined", "icon", "className", "children", "isActive", "hasIconMargin", "hasLargeIconMargin"]);
    return (_jsxs("button", Object.assign({ ref: ref, className: classNames(styles.button, styles[theme], styles[size], {
            [styles.outlined]: isOutlined,
            [styles.active]: isActive,
            [styles.withIcon]: icon && children,
            [styles.onlyIcon]: icon && !children,
            [styles.noIconMargin]: !hasIconMargin,
            [styles.largeIconMargin]: hasLargeIconMargin,
        }, className) }, props, { children: [icon && _jsx("span", Object.assign({ className: styles.icon }, { children: icon })), children] })));
});
