var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSendTestMessageMutation, } from '@/api/query/mailings';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useSendTestMessage = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [sendTestMessage] = useSendTestMessageMutation();
    return (type, targets, values) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        let walletImageId = !(values.walletImage instanceof File)
            ? (_a = values.walletImage) === null || _a === void 0 ? void 0 : _a.id
            : undefined;
        if ((_b = values.walletImageData) === null || _b === void 0 ? void 0 : _b.id)
            walletImageId = values.walletImageData.id;
        let viberImageId = !(values.viberImage instanceof File)
            ? (_c = values.viberImage) === null || _c === void 0 ? void 0 : _c.id
            : undefined;
        if ((_d = values.viberImageData) === null || _d === void 0 ? void 0 : _d.id)
            viberImageId = values.viberImageData.id;
        let telegramImageId = !(values.telegramImage instanceof File)
            ? (_e = values.telegramImage) === null || _e === void 0 ? void 0 : _e.id
            : undefined;
        if ((_f = values.telegramImageData) === null || _f === void 0 ? void 0 : _f.id)
            telegramImageId = values.telegramImageData.id;
        const payload = {
            type,
            knownParams: {
                bonusesAmount: (_g = values.rewards) === null || _g === void 0 ? void 0 : _g.bonusesAmount,
                bonusesLifetime: (_h = values.rewards) === null || _h === void 0 ? void 0 : _h.bonusesLifetime,
            },
        };
        if (type === 'sms') {
            payload.phones = targets;
            payload.smsSenderId = values.smsSender;
            payload.smsText = values.smsText;
        }
        if (type === 'viber') {
            payload.phones = targets;
            payload.viberText = values.viberText;
            payload.viberButton = values.viberButton;
            payload.viberUrl = values.viberUrl;
            payload.viberImageId = viberImageId || null;
        }
        if (type === 'telegram') {
            payload.phones = targets;
            payload.telegramText = values.telegramText;
            payload.telegramButton = values.telegramButton;
            payload.telegramUrl = values.telegramUrl;
            payload.telegramImageId = telegramImageId || null;
        }
        if (type === 'push') {
            payload.phones = targets;
            payload.messagingAppId = values.messagingApp;
            payload.pushText = values.pushText;
            payload.walletImageId = walletImageId || null;
        }
        if (type === 'email') {
            payload.emails = targets;
            payload.emailSenderId = values.emailSender;
            payload.emailSubject = values.emailSubject;
            payload.emailBody = values.emailBody || undefined;
        }
        const result = yield sendTestMessage(payload);
        if ('error' in result)
            throw getErrorMessage(result.error);
        else if ((_j = result.data) === null || _j === void 0 ? void 0 : _j.error)
            throw (_k = result.data) === null || _k === void 0 ? void 0 : _k.error;
        else
            addToast({ type: 'success', message: t('Сообщение отправлено') });
    });
};
