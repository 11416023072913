import { useTranslation } from 'react-i18next';
export const useBuildOptions = () => {
    const { t } = useTranslation();
    const buildOptionsItem = (start) => {
        const list = [];
        for (let i = start; i < start + 5; i += 1) {
            const value = i;
            const title = i === start
                ? t('GROWING_DISCOUNT_START', { start })
                : t('GROWING_DISCOUNT_RANGE', { start, end: i });
            // i === start ? `на ${start}-й товар` : `с ${start} по ${i} товар`
            list.push({ value, title });
        }
        list.push({ value: 0, title: t('GROWING_DISCOUNT_FURTHER', { n: start }) });
        // list.push({ value: 0, title: `на ${start}-й и далее` })
        return list;
    };
    const buildOptions = (value, changeValue) => {
        const options = [];
        const newValue = [];
        options.push(buildOptionsItem(1));
        for (let i = 0; i < value.length; i += 1) {
            const optionsItem = buildOptionsItem(value[i].to + 1);
            options.push(optionsItem);
            newValue.push({ to: value[i].to });
            if (i < value.length - 1) {
                const next = value[i + 1].to;
                if (!next)
                    break;
                const optionsItemHasNext = optionsItem
                    .map((item) => item.value)
                    .includes(next);
                if (!optionsItemHasNext)
                    break;
            }
        }
        const last = newValue[newValue.length - 1];
        const newValueHasTail = last.to === 0;
        if (!newValueHasTail)
            newValue.push({ to: 0 });
        for (let i = 0; i < newValue.length; i += 1)
            if (value[i])
                newValue[i].amount = value[i].amount;
        if (changeValue)
            changeValue(newValue);
        return options;
    };
    return buildOptions;
};
