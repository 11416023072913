import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { DateInput } from '@/components/DateInput/DateInput';
import { normalizePeriod } from '@/utils/normalizePeriod';
import { dateValidator } from './dateValidator';
import styles from './styles.module.scss';
export const DatePeriodInputs = memo(({ start, end, onChange }) => {
    const handleChangeStart = (newStart) => {
        if (newStart && end) {
            onChange(...normalizePeriod(newStart, end));
            return;
        }
        onChange(newStart, end);
    };
    const handleChangeEnd = (newEnd) => {
        if (start && newEnd) {
            onChange(...normalizePeriod(start, newEnd));
            return;
        }
        onChange(start, newEnd);
    };
    const startErrorMessage = dateValidator(start);
    const endErrorMessage = dateValidator(end);
    return (_jsx("div", Object.assign({ className: styles.inputsWrapper }, { children: _jsxs("div", Object.assign({ className: styles.inputs }, { children: [_jsxs("div", Object.assign({ className: styles.input }, { children: [_jsx(DateInput, { selected: start, onChange: handleChangeStart, selectsStart: true, startDate: start, endDate: end, hasIcon: false, hasCalendarHidden: true, enableTabLoop: false, hasError: !!startErrorMessage }), startErrorMessage && (_jsx("div", Object.assign({ className: styles.errorMessage }, { children: startErrorMessage })))] })), _jsx("div", Object.assign({ className: styles.inputDivider }, { children: "\u2014" })), _jsxs("div", Object.assign({ className: styles.input }, { children: [_jsx(DateInput, { selected: end, onChange: handleChangeEnd, selectsEnd: true, startDate: start, endDate: end, minDate: start, hasIcon: false, hasCalendarHidden: true, enableTabLoop: false, hasError: !!endErrorMessage }), endErrorMessage && (_jsx("div", Object.assign({ className: styles.errorMessage }, { children: endErrorMessage })))] }))] })) })));
});
