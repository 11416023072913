import { notNull } from '@/utils/notNull';
export const convertToInputValue = (pills) => {
    const bonusesItem = pills.find((i) => i.type === 'bonuses');
    const promocodeItem = pills.find((i) => i.type === 'promocode');
    const giftCardItem = pills.find((i) => i.type === 'gift-card');
    const offerItems = pills.filter((i) => i.type === 'offer');
    return {
        bonusesAmount: bonusesItem === null || bonusesItem === void 0 ? void 0 : bonusesItem.bonusesAmount,
        bonusesLifetime: bonusesItem === null || bonusesItem === void 0 ? void 0 : bonusesItem.bonusesLifetime,
        promocodeId: promocodeItem === null || promocodeItem === void 0 ? void 0 : promocodeItem.promocodeId,
        giftCardId: giftCardItem === null || giftCardItem === void 0 ? void 0 : giftCardItem.giftCardId,
        offers: offerItems,
    };
};
export const onlyOne = (value, selectedItemIdx) => {
    const idx = selectedItemIdx === undefined ? value.length - 1 : selectedItemIdx;
    if (value[idx].type === 'promocode')
        return value
            .map((item, i) => {
            if (item.type === 'promocode' && i !== idx)
                return null;
            return item;
        })
            .filter(notNull);
    if (value[idx].type === 'gift-card')
        return value
            .map((item, i) => {
            if (item.type === 'gift-card' && i !== idx)
                return null;
            return item;
        })
            .filter(notNull);
    return value;
};
