export const ROUTES = {
    ROOT: '/',
    DASHBOARD: '/app/dashboard',
    RFM: '/app/rfm',
    REPORTS: '/app/reports',
    EXPORTS: '/app/exports',
    IMPORTS: '/app/import',
    IMPORTS_EDIT: '/app/import/create/:importId',
    CLIENTS: '/app/clients',
    SEGMENTS: '/app/clients/segments',
    EVENTS: '/app/clients/events',
    PURCHASES: '/app/purchases',
    MAILINGS_MANUAL: '/app/mailing/manual',
    MAILINGS_MANUAL_CREATE: '/app/mailing/manual/create',
    MAILINGS_MANUAL_EDIT: '/app/mailing/manual/create/:mailingId',
    MAILINGS_AUTOMATIC: '/app/mailing/automatic',
    MAILINGS_AUTOMATIC_CREATE: '/app/mailing/automatic/create',
    MAILINGS_AUTOMATIC_EDIT: '/app/mailing/automatic/create/:mailingId',
    FLOWS: '/app/flows',
    FLOWS_CREATE: '/app/flows/create',
    FLOWS_EDIT: '/app/flows/create/:flowId',
    WALLET_CARDS: '/app/wallet-cards',
    WALLET_CARDS_CREATE: '/app/wallet-cards/:levelId/create',
    WALLET_CARDS_EDIT: '/app/wallet-cards/:levelId/edit/:cardId',
    GEO_PUSH_CREATE: '/app/wallet-cards/geo-push/create',
    GEO_PUSH_EDIT: '/app/wallet-cards/geo-push/edit/:geoPushId',
    OFFERS: '/app/offer',
    OFFERS_CREATE: '/app/offer/create',
    OFFERS_EDIT: '/app/offer/create/:offerId',
    PROMOCODES: '/app/promocode',
    PROMOCODES_CREATE: '/app/promocode/create',
    PROMOCODES_EDIT: '/app/promocode/create/:promocodeId',
    PROMOCODES_FRIEND: '/app/promocode-friend',
    PROMOCODES_FRIEND_CREATE: '/app/promocode/create-friend',
    PROMOCODES_FRIEND_EDIT: '/app/promocode/create-friend/:promocodeId',
    GIFT_CARDS: '/app/gift-cards',
    GIFT_CARDS_CREATE: '/app/gift-cards/create',
    GIFT_CARDS_EDIT: '/app/gift-cards/create/:giftCardId',
    GIFT_CARDS_INSTANCES: '/app/gift-cards/instances/:giftCardId',
    BILLING: '/app/billing',
    BILLING_INVOICES: '/app/billing/invoices',
    SETTINGS_USERS: '/app/settings/users',
    SETTINGS_SHOPS: '/app/settings/shops',
    LOGIN: '/app/login/signin',
    PASSWORD_RESET: '/app/password-reset/:token/:expirationDate',
};
export const createHref = (route) => {
    return `/#${route}`;
};
