import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormMailingInfo = ({ type, currency, clientsCount, maxCost, isReadOnly, labelWidth, }) => {
    const { t } = useTranslation();
    const costMessage = (_jsxs(_Fragment, { children: [t('COST_UP_TO'), ' ', _jsx("span", Object.assign({ className: styles.strong }, { children: money({ input: maxCost || 0, currency }) })), type === 'auto' ? _jsxs(_Fragment, { children: [" ", t('в неделю')] }) : ''] }));
    const costTooltip = (_jsx(HelpTooltip, Object.assign({ title: t('Рассчитано приблизительно на основе средней стоимости отправки, количества получателей и длины сообщения. Фактические затраты могут отличаться.'), placement: 'right', hasCurrentColor: true }, { children: costMessage })));
    const costRow = (_jsx(FormFieldRow, Object.assign({ labelClassName: styles.infoLabel, label: t('Прогнозируемые затраты'), labelWidth: labelWidth, controlClassName: styles.infoControl }, { children: _jsx("div", Object.assign({ className: styles.costRow }, { children: maxCost ? costTooltip : _jsx("span", Object.assign({ className: styles.strong }, { children: "\u2013" })) })) })));
    const clientsMessage = (_jsx("span", Object.assign({ className: styles.strong }, { children: t('CLIENTS_WILL_BE_RECEIVED', {
            amount: numberRound(clientsCount || 0),
            count: clientsCount || 0,
        }) })));
    const clientsRow = (_jsx(FormFieldRow, Object.assign({ className: styles.clientsRowWrapper, labelClassName: styles.infoLabel, label: t('Получат'), labelWidth: labelWidth, controlClassName: styles.infoControl }, { children: _jsx("div", Object.assign({ className: styles.clientsRow }, { children: clientsCount ? (clientsMessage) : (_jsx("span", Object.assign({ className: styles.strong }, { children: "\u2013" }))) })) })));
    if (type === 'auto')
        return _jsx("div", Object.assign({ className: styles.info }, { children: costRow }));
    if (type === 'manual' && !isReadOnly)
        return (_jsxs("div", Object.assign({ className: styles.info }, { children: [clientsRow, costRow] })));
    return null;
};
