import { useCallback, useState } from 'react';
import { useGetClientQuery } from '@/api/query/client';
import { useModalLoading } from '@/hooks/useModalLoading';
export const useClientModal = () => {
    var _a;
    const [clientId, setClientId] = useState(undefined);
    const [activeTab, setActiveTab] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { data: client } = useGetClientQuery({ id: clientId || 0 }, { skip: !clientId });
    const isLoadingClient = clientId !== ((_a = client === null || client === void 0 ? void 0 : client.client) === null || _a === void 0 ? void 0 : _a.id);
    useModalLoading(isLoadingClient);
    const openOverview = useCallback((clientId) => {
        setClientId(clientId);
        setActiveTab('overview');
        setIsOpen(true);
    }, []);
    const openEditForm = useCallback((clientId) => {
        setClientId(clientId);
        setActiveTab('edit');
        setIsOpen(true);
    }, []);
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    return {
        client: (client || {}),
        openOverview,
        openEditForm,
        isClientModalOpen: !isLoadingClient && !!client && isOpen,
        openClientModal: openModal,
        closeClientModal: closeModal,
        clientModalActiveTab: activeTab,
        changeModalActiveTab: setActiveTab,
    };
};
