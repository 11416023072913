import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MUISwitch from '@mui/material/Switch';
import classNames from 'classnames';
import { useCallback } from 'react';
import styles from './styles.module.scss';
export const Switch = ({ className, label = '', labelWrapperClassName, labelClassName, checked = false, disabled = false, isLoading = false, onChange, }) => {
    const handleChange = useCallback((e) => {
        if (onChange)
            onChange(e.target.checked);
    }, [onChange]);
    const checkbox = (_jsx(MUISwitch, { className: classNames(styles.switch, className), focusVisibleClassName: '.Mui-focusVisible', checked: checked, onChange: handleChange, disabled: disabled, disableRipple: true }));
    return label ? (_jsxs("label", Object.assign({ className: classNames(styles.labelWrapper, { [styles.loading]: isLoading }, labelWrapperClassName) }, { children: [checkbox, _jsx("div", Object.assign({ className: classNames(styles.label, labelClassName) }, { children: label }))] }))) : (checkbox);
};
