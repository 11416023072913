import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FiltersPopup } from '@/components/FiltersPopup/FiltersPopup';
import { Plus } from '@/icons/Plus';
import { branchIsHidden } from '@/services/filters/branchIsHidden';
import { convertLeafToSavedFilter } from '@/services/filters/convertLeafToSavedFilter';
import { getLeavesCount } from '@/services/filters/getLeavesCount';
import { injectSavedFiltersToTree } from '@/services/filters/injectSavedFiltersToTree';
import { FilterBadge } from './FilterBadge';
import styles from './styles.module.scss';
export const Filters = ({ className, segment, actions, filtersTree, value, draftKeys = [], onUpdate, customBadges = [], onRemoveCustomBadge, buttonTheme = 'primary', shouldHideIfEmpty = true, shouldDisplayActions = true, shouldDisplayFilterBadges = true, hasError = false, hasOperators = true, hasHangingOperator = true, buttonRef, rightPanelClassName, selectedCode, badgeClassName, }) => {
    const { t } = useTranslation();
    const [addButtonIsActive, setAddButtonIsActive] = useState(false);
    const [activeBadgeId, setActiveBadgeId] = useState('');
    const filtersTreeWithData = injectSavedFiltersToTree(filtersTree, value);
    const filterBadges = value.map((item) => item.id || item.code);
    const leaves = shouldDisplayFilterBadges ? value : [];
    const isFullSelected = value.length === getLeavesCount(filtersTree);
    const handleAddFilter = (leaf) => {
        const newValue = [...value, convertLeafToSavedFilter(leaf)];
        onUpdate(newValue);
    };
    const handleChangeFilter = (leaf, index) => {
        const newValue = [...value];
        newValue.splice(index, 1, convertLeafToSavedFilter(leaf));
        onUpdate(newValue);
    };
    const handleRemoveFilter = (removeKey) => {
        const newValue = value.filter((savedFilter) => {
            const key = savedFilter.id || savedFilter.code;
            return removeKey !== key;
        });
        onUpdate(newValue);
    };
    const panelIsHidden = shouldHideIfEmpty && !leaves.length;
    const popupIsVisible = shouldHideIfEmpty
        ? leaves.length > 0
        : shouldDisplayActions;
    const actionsContent = popupIsVisible ? (_jsxs("div", Object.assign({ className: styles.actions }, { children: [!isFullSelected && (_jsx(FiltersPopup, { trigger: _jsx("div", { children: _jsx(Button, { ref: buttonRef, type: 'button', icon: _jsx(Plus, {}), isActive: addButtonIsActive, theme: hasError ? 'danger' : buttonTheme }) }), filtersTree: filtersTreeWithData, disabledCodes: filterBadges, hiddenCodes: filtersTreeWithData
                    .filter((i) => branchIsHidden(i, filterBadges))
                    .map((i) => i.id), onApply: handleAddFilter, onToggle: setAddButtonIsActive, rightPanelClassName: rightPanelClassName, selectedCode: selectedCode })), leaves.length > 0 ||
                customBadges.filter((cb) => !cb.isNonEditable).length > 0
                ? actions
                : undefined] }))) : null;
    return (_jsxs("div", Object.assign({ className: classNames(styles.filters, { [styles.hidden]: panelIsHidden }, className) }, { children: [segment, customBadges.map((bagde, index) => {
                const isLast = index === customBadges.length - 1 && !leaves.length;
                const shouldHideLastOperator = isLast && !hasHangingOperator;
                const shouldHideAllOperators = !hasOperators;
                return (_jsxs("div", Object.assign({ className: styles.customBadge }, { children: [_jsx(FilterBadge, { className: badgeClassName, text: bagde.label, onClose: bagde.isNonEditable
                                ? undefined
                                : () => onRemoveCustomBadge === null || onRemoveCustomBadge === void 0 ? void 0 : onRemoveCustomBadge(bagde.id), isUntruncated: bagde.isUntruncated, isStatic: true }), shouldHideLastOperator || shouldHideAllOperators ? null : (_jsx("span", Object.assign({ className: styles.and }, { children: t('и') }))), isLast && actionsContent] }), bagde.id));
            }), leaves.map((leaf, index) => {
                const key = leaf.id || leaf.code || '';
                const isLast = index === leaves.length - 1;
                const shouldHideLastOperator = isLast && !hasHangingOperator;
                const shouldHideAllOperators = !hasOperators;
                return (_jsxs("div", Object.assign({ className: styles.leaf }, { children: [_jsx(FiltersPopup, { trigger: _jsx(FilterBadge, { className: badgeClassName, text: leaf.text || '', onClose: () => handleRemoveFilter(key), isActive: key === activeBadgeId, isDraft: draftKeys.includes(key) }), filtersTree: filtersTreeWithData, selectedCode: key, disabledCodes: filterBadges.filter((id) => id !== key), hiddenCodes: filtersTreeWithData
                                .filter((i) => branchIsHidden(i, filterBadges, key))
                                .map((i) => i.id), onApply: (leaf) => handleChangeFilter(leaf, index), onToggle: (isOpen) => setActiveBadgeId(isOpen ? key : ''), rightPanelClassName: rightPanelClassName }), shouldHideLastOperator || shouldHideAllOperators ? null : (_jsx("span", Object.assign({ className: styles.and }, { children: t('и') }))), isLast && actionsContent] }), key));
            }), !customBadges.length && !leaves.length && actionsContent] })));
};
