import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { AreaChart } from '@/icons/AreaChart';
import { HorizontalBars } from '@/icons/HorizontalBars';
import styles from './styles.module.scss';
export const RFMChartWrongDate = ({ chartType, onActionClick, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: styles.empty }, { children: [_jsx("div", Object.assign({ className: styles.emptyIcon }, { children: chartType === 'area' ? _jsx(AreaChart, {}) : _jsx(HorizontalBars, {}) })), _jsx("div", Object.assign({ className: styles.emptyTitle }, { children: t('Недостаточно данных') })), _jsx("div", Object.assign({ className: styles.emptyDescription }, { children: t('Пожалуйста, установите начальную дату не ранее 8 июля 2023') })), _jsx("div", Object.assign({ className: styles.emptyAction }, { children: _jsx(Button, Object.assign({ onClick: onActionClick }, { children: t('RFM_CHART_SET_DATE') })) }))] })));
};
