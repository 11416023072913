import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { useOptions } from './useOptions';
export const ImportMappingSelect = ({ type, value, clientExtraFields = [], onChange, }) => {
    const { i18n } = useTranslation();
    const options = useOptions(type, clientExtraFields);
    return (_jsx(_Fragment, { children: _jsx(Autocomplete, { value: options.find((item) => item.id === value) || null, onChange: (option) => {
                if (option && !option.isGroup)
                    onChange(option.id);
            }, options: options, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.title, isOptionGroup: (option) => option.isGroup, isOptionGroupItem: (option) => !option.isGroup, hasResetButton: false, asSelect: true, minListWidth: 290, maxListHeight: 400 }, i18n.language) }));
};
