import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popper } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { useAutocomplete } from '../context/useAutocomplete';
import styles from '../styles.module.scss';
import { AutocompleteItem } from './AutocompleteItem';
export const AutocompleteListBox = () => {
    var _a;
    const { t } = useTranslation();
    const { value, getOptionKey, getOptionHelp, renderOption, minListWidth, maxListHeight, disablePortal, popupOpen, wrapperRef, loading, searchText, filteredOptions, getListboxProps, getOptionProps, } = useAutocomplete();
    const loader = (_jsx("li", Object.assign({ className: classNames(styles.item, styles.static, {
            [styles.loading]: loading,
        }) }, { children: loading ? t('Загружается...') : t('Ничего не найдено') })));
    const renderItem = (option, index) => {
        return (_jsx(AutocompleteItem, { option: option, index: index }, getOptionKey(option)));
    };
    const renderCustomItem = (option, index) => {
        const help = getOptionHelp === null || getOptionHelp === void 0 ? void 0 : getOptionHelp(option);
        const isSelected = !!value && getOptionKey(value) === getOptionKey(option);
        const itemContent = help ? (_jsx(HelpTooltip, Object.assign({ className: styles.helpTooltipIcon, tooltipClassName: styles.helpTooltip, title: help, placement: 'top', PopperProps: { disablePortal: true }, hasLightIcon: true }, { children: renderOption === null || renderOption === void 0 ? void 0 : renderOption(option, searchText) }))) : (renderOption === null || renderOption === void 0 ? void 0 : renderOption(option, searchText));
        return (_createElement("li", Object.assign({}, getOptionProps === null || getOptionProps === void 0 ? void 0 : getOptionProps({ option, index }), { className: classNames(styles.item, {
                [styles.selected]: isSelected,
            }), key: getOptionKey(option) }), itemContent));
    };
    return (_jsx(Popper, Object.assign({ className: styles.popper, open: !!popupOpen, anchorEl: wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current, modifiers: [{ name: 'offset', options: { offset: [-4, 7] } }], placement: 'bottom-start', disablePortal: disablePortal }, { children: _jsx("div", Object.assign({ className: styles.listWrapper, style: { minWidth: minListWidth || ((_a = wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) } }, { children: _jsxs("ul", Object.assign({ className: styles.list, style: maxListHeight ? { maxHeight: maxListHeight } : undefined }, getListboxProps === null || getListboxProps === void 0 ? void 0 : getListboxProps(), { children: [!filteredOptions.length && loader, filteredOptions.map(renderOption ? renderCustomItem : renderItem)] })) })) })));
};
