var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useGetBrandListMutation, useSwitchBrandMutation, } from '@/api/query/brand';
import { useModalLoading } from '@/hooks/useModalLoading';
export const useSwitchBrand = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [getBrands, { isLoading, data: brands = [] }] = useGetBrandListMutation();
    const [mutate, { isLoading: isSwitchingBrand }] = useSwitchBrandMutation();
    const openModal = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getBrands();
        setIsOpen(true);
    });
    const closeModal = () => {
        setIsOpen(false);
    };
    useModalLoading(isLoading);
    const switchBrand = (brand) => __awaiter(void 0, void 0, void 0, function* () {
        yield mutate({ globalKey: brand.globalKey });
        window.location.reload();
    });
    return {
        isOpen,
        openModal,
        closeModal,
        brands,
        switchBrand,
        isSwitchingBrand,
    };
};
