import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { appPeriodSelector } from '@/store/selectors/appPeriod';
import { changePeriod } from '@/store/slice/appPeriod';
import { DatePeriodSelect } from './DatePeriodSelect';
export const DatePeriodSelectContainer = ({ onChange, label, leftElement }) => {
    const dispatch = useAppDispatch();
    const appPeriod = useAppSelector(appPeriodSelector);
    return (_jsx(DatePeriodSelect, { label: label, leftElement: leftElement, start: appPeriod.start.toDate(), end: appPeriod.end.toDate(), onChange: (newStart, newEnd) => {
            if (newStart && newEnd) {
                const start = moment(newStart).startOf('day');
                const end = moment(newEnd).endOf('day');
                dispatch(changePeriod({ start, end }));
                if (onChange)
                    onChange(start, end);
            }
        } }));
};
