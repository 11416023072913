import { ChannelNode } from './ChannelNode/ChannelNode';
import { ChannelReceivedNode } from './ChannelReceivedNode/ChannelReceivedNode';
import { ConditionsNode } from './ConditionsNode/ConditionsNode';
import { DelayNode } from './DelayNode/DelayNode';
import { EmailReceivedNode } from './EmailReceivedNode/EmailReceivedNode';
import { LoopNode } from './LoopNode/LoopNode';
import { NewStepNode } from './NewStepNode/NewStepNode';
import { TriggerNode } from './TriggerNode/TriggerNode';
export const nodeTypes = {
    trigger: TriggerNode,
    conditions: ConditionsNode,
    channel: ChannelNode,
    delay: DelayNode,
    'email-received': EmailReceivedNode,
    'channel-received': ChannelReceivedNode,
    loop: LoopNode,
    'new-step': NewStepNode,
};
