import { findLeafByCode } from './findLeafByCode';
import { injectSavedFilterToLeaf } from './injectSavedFilterToLeaf';
export const injectSavedFiltersToTree = (filtersTree, savedFilters) => {
    const newFiltersTree = [...filtersTree];
    for (let i = 0; i < savedFilters.length; i += 1) {
        const key = savedFilters[i].id || savedFilters[i].code;
        const leaf = findLeafByCode(newFiltersTree, key);
        if (leaf)
            injectSavedFilterToLeaf(leaf, savedFilters[i]);
    }
    return newFiltersTree;
};
