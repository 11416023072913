import { useReactFlow } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { validateFlow as validateFlowHelper } from '../nodes/validation';
export const useFlowValidation = () => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { setValidationErrorMap } = useInteractiveFlow();
    const validateFlow = () => {
        const errorMap = validateFlowHelper(flow, t);
        setValidationErrorMap === null || setValidationErrorMap === void 0 ? void 0 : setValidationErrorMap(errorMap || undefined);
        return !errorMap;
    };
    return { validateFlow };
};
