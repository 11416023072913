import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Download } from '@/icons/Download';
import { Plus } from '@/icons/Plus';
import { usePurchasesPage } from './context/usePurchasesPage';
export const PurchasesToolbar = () => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasExportManage = userHasAccessTo('EXPORTS_MANAGE');
    const userHasPurchasesManage = userHasAccessTo('CLIENTS_PURCHASES_MANAGE');
    const { modals, exportData, tabs } = usePurchasesPage();
    if (tabs.activeTab !== 'purchases')
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: !userHasExportManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Download, {}), title: t('Выгрузить в Excel'), onClick: exportData.exportPurchases, hasIconMargin: false, disabled: !userHasExportManage }, { children: "XLS" })) })), _jsx(Tooltip, Object.assign({ title: !userHasPurchasesManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: modals.purchases.addModal.open, theme: 'primary', size: 'extra-large', disabled: !userHasPurchasesManage }, { children: t('Добавить покупку') })) }))] }));
};
