var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteClientMutation } from '@/api/query/client';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { buildClientFullName } from '@/utils/getClientFullName';
export const useDeleteClient = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteClient, { isLoading }] = useDeleteClientMutation();
    const handleDeleteClient = (client) => __awaiter(void 0, void 0, void 0, function* () {
        const name = buildClientFullName(client);
        const confirmTitle = name
            ? t('Удалить клиента {{name}}?', { name })
            : t('Удалить клиента?');
        const confirmAgreeLabel = t('Удалить клиента');
        const successMessage = t('Клиент удалён');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return false;
        const result = yield deleteClient({ clientId: client.id });
        if ('error' in result) {
            addToast({ type: 'error', message: getErrorMessage(result.error) });
            return false;
        }
        else {
            addToast({ type: 'success', message: successMessage });
            return true;
        }
    });
    return {
        deleteClient: handleDeleteClient,
        isDeleting: isLoading,
    };
};
