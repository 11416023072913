import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { VarGroup } from './VarGroup';
import { VarItem } from './VarItem';
export const VarsPopup = ({ variables, onSelectVariable, onClose, currency, }) => {
    const { t } = useTranslation();
    const handleSelectVariable = (variable) => {
        onSelectVariable(variable);
        if (onClose)
            onClose();
    };
    return (_jsxs("div", Object.assign({ className: styles.varListWrapper, onMouseDown: (e) => {
            e.preventDefault();
            e.stopPropagation();
        } }, { children: [_jsx("div", Object.assign({ className: styles.varListHeader }, { children: t('Выберите параметр') })), _jsx("div", Object.assign({ className: styles.varListDescription }, { children: t('При отправке вместо него подставятся конкретные данные для клиента') })), _jsx("ul", Object.assign({ className: styles.varList }, { children: variables.map((item) => {
                    if ('title' in item)
                        return (_jsx(VarGroup, { group: item, onItemClick: handleSelectVariable, currency: currency }, item.title));
                    if ('id' in item)
                        return (_jsx(VarItem, { item: item, onClick: handleSelectVariable, currency: currency }, item.id));
                }) }))] })));
};
