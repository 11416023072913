import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBarList, } from '@/components/HorizontalBarList/HorizontalBarList';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Female } from '@/icons/Female';
import { Male } from '@/icons/Male';
import { calculatePercent } from '@/utils/calculatePercent';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const DemographicsPanel = memo(({ genderFemale, genderMale, age15to19, age20to24, age25to29, age30to34, age35to39, age40to44, age45to49, age50to54, age55, isLoading = false, }) => {
    const { t } = useTranslation();
    const totalWithAge = age15to19 +
        age20to24 +
        age25to29 +
        age30to34 +
        age35to39 +
        age40to44 +
        age45to49 +
        age50to54 +
        age55;
    const totalWithGender = genderFemale + genderMale;
    const femalePercent = calculatePercent(genderFemale, totalWithGender);
    const malePercent = calculatePercent(genderMale, totalWithGender);
    const title = t('Демография');
    const color = '#987BDD';
    const bars = [
        {
            id: '15—19',
            label: '15—19',
            color,
            value: age15to19,
            percent: calculatePercent(age15to19, totalWithAge, 0),
        },
        {
            id: '20—24',
            label: '20—24',
            color,
            value: age20to24,
            percent: calculatePercent(age20to24, totalWithAge, 0),
        },
        {
            id: '25—29',
            label: '25—29',
            color,
            value: age25to29,
            percent: calculatePercent(age25to29, totalWithAge, 0),
        },
        {
            id: '30—34',
            label: '30—34',
            color,
            value: age30to34,
            percent: calculatePercent(age30to34, totalWithAge, 0),
        },
        {
            id: '35—39',
            label: '35—39',
            color,
            value: age35to39,
            percent: calculatePercent(age35to39, totalWithAge, 0),
        },
        {
            id: '40—44',
            label: '40—44',
            color,
            value: age40to44,
            percent: calculatePercent(age40to44, totalWithAge, 0),
        },
        {
            id: '45—49',
            label: '45—49',
            color,
            value: age45to49,
            percent: calculatePercent(age45to49, totalWithAge, 0),
        },
        {
            id: '50—54',
            label: '50—54',
            color,
            value: age50to54,
            percent: calculatePercent(age50to54, totalWithAge, 0),
        },
        {
            id: '55+',
            label: '55+',
            color,
            value: age55,
            percent: calculatePercent(age55, totalWithAge, 0),
        },
    ];
    return (_jsxs(StatsPanel, Object.assign({ title: title, isLoading: isLoading }, { children: [_jsxs("div", Object.assign({ className: styles.gender }, { children: [_jsxs("div", Object.assign({ className: styles.genderItem }, { children: [_jsx(Male, {}), _jsxs("span", Object.assign({ className: styles.genderText }, { children: [_jsx("span", Object.assign({ className: styles.value }, { children: numberRound(genderMale) })), _jsxs("span", Object.assign({ className: styles.percent }, { children: [malePercent, "%"] }))] }))] })), _jsxs("div", Object.assign({ className: styles.genderItem }, { children: [_jsx(Female, {}), _jsxs("span", Object.assign({ className: styles.genderText }, { children: [_jsx("span", Object.assign({ className: styles.value }, { children: numberRound(genderFemale) })), _jsxs("span", Object.assign({ className: styles.percent }, { children: [femalePercent, "%"] }))] }))] }))] })), _jsx("div", Object.assign({ className: styles.list }, { children: _jsx(HorizontalBarList, { items: bars }) }))] })));
});
