import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGetRFMTableQuery } from '@/api/query/rfm';
import { RFMThresholdPanel } from './RFMThresholdPanel';
export const RFMThresholdPanelContainer = ({ currency, isRFMEnabled }) => {
    const { t } = useTranslation();
    const { data } = useGetRFMTableQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const threshold = (data === null || data === void 0 ? void 0 : data.threshold) || {
        frequency1: 0,
        frequency2: 0,
        monetary1: 0,
        monetary2: 0,
        recency1: 0,
        recency2: 0,
        riskRecency: 0,
    };
    const recency1 = Number(threshold.recency1);
    const recency2 = Number(threshold.recency2);
    const riskRecency = Number(threshold.riskRecency);
    const frequency1 = Number(threshold.frequency1);
    const frequency2 = Number(threshold.frequency2);
    const monetary1 = Number(threshold.monetary1);
    const monetary2 = Number(threshold.monetary2);
    return (_jsx(RFMThresholdPanel, { recency: [
            { label: t('Недавно'), to: recency2 },
            { label: t('Давно'), from: recency2, to: recency1 },
            { label: t('Очень давно'), from: recency1 },
            { label: t('Ультраотток'), from: riskRecency },
        ], frequency: [
            { label: t('Редко'), to: frequency1 },
            { label: t('Иногда'), from: frequency1, to: frequency2 },
            { label: t('Часто'), from: frequency2 },
        ], monetary: [
            { label: t('Маленькая'), to: monetary1 },
            { label: t('Средняя'), from: monetary1, to: monetary2 },
            { label: t('Большая'), from: monetary2 },
        ], currency: currency, isLocked: !isRFMEnabled }));
};
