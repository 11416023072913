import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { LinkPopupLayout } from '@/components/rich-text/LinkPopup/LinkPopupLayout';
import { normalizeLink } from '@/components/rich-text/LinkPopup/normalizeLink';
import { isValidURL } from '@/utils/url';
export const LinkPopup = ({ isCreateEmptyLink, linkHref, linkTitle, handleDeleteLink, handleCreateLink, onChangeLinkData, onClose, }) => {
    const [fieldsError, setFieldsError] = useState({
        title: false,
        href: false,
    });
    const setFieldError = (key, value) => {
        setFieldsError((prevValue) => (Object.assign(Object.assign({}, prevValue), { [key]: value })));
    };
    const handleSubmitLink = () => {
        var _a, _b;
        let success = true;
        if (!linkTitle.trim()) {
            (_a = document.getElementById('link-form-title')) === null || _a === void 0 ? void 0 : _a.focus();
            setFieldError('title', true);
            success = false;
        }
        if (!isValidURL(linkHref)) {
            success && ((_b = document.getElementById('link-form-link')) === null || _b === void 0 ? void 0 : _b.focus());
            setFieldError('href', true);
            success = false;
        }
        if (!success)
            return;
        handleCreateLink();
        onClose();
    };
    const handleInputKeyUp = (e) => {
        if (e.code === 'Enter')
            handleSubmitLink();
    };
    const handleInputLinkTitle = (event) => {
        onChangeLinkData(event.target.value, 'title');
        if (event.target.value && fieldsError.title)
            setFieldError('title', false);
    };
    const handleInputLinkHref = (event) => {
        const normalizedLink = normalizeLink(event === null || event === void 0 ? void 0 : event.target.value) || '';
        onChangeLinkData(normalizedLink, 'href');
        if (event.target.value && fieldsError.href)
            setFieldError('href', false);
    };
    return (_jsx(LinkPopupLayout, { titleInputDisabled: !isCreateEmptyLink, linkTitleError: fieldsError.title, linkHrefError: fieldsError.href, handleInputKeyUp: handleInputKeyUp, linkHref: linkHref, linkTitle: linkTitle, handleCreateLink: handleSubmitLink, handleDeleteLink: () => {
            handleDeleteLink();
            onClose();
        }, handleInputLinkHref: handleInputLinkHref, handleInputLinkTitle: handleInputLinkTitle }));
};
