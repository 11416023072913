import { FetchStatus } from '@/store/slice';
import { capitalize } from '@/utils/capitalize';
import { notNull } from '@/utils/notNull';
export const getShopOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.shopOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.name })), {});
};
export const getClientLevelOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.clientLevelOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.level]: curr.name })), {});
};
export const getPromocodeFriendOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.promocodeFriendOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.code })), {});
};
export const getPromocodeOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.promocodeOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.code })), {});
};
export const getAutomaticMailingOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.automaticMailingOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.name })), {});
};
export const getManualMailingOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.manualMailingOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.name })), {});
};
export const getOfferOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.offerOptions.data) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.id]: curr.title })), {});
};
export const getSegmentOptionsSelector = (state) => {
    var _a;
    return (_a = state.filters.segmentOptions.data) === null || _a === void 0 ? void 0 : _a.map((segment) => ({
        id: segment.id.toString(),
        label: segment.name,
        color: segment.color,
    }));
};
export const getRFMSegmentOptionsSelector = (state) => {
    var _a, _b;
    return (_b = (_a = state.filters.rfmSegmentOptions.data) === null || _a === void 0 ? void 0 : _a.filter((segment) => segment.type === 'rfm')) === null || _b === void 0 ? void 0 : _b.map((segment, i) => ({
        id: segment.id ? `${segment.id}` : `${segment.name}-segment-${i}`,
        label: segment.name,
        color: segment.color,
        type: segment.type,
        code: segment.code,
    }));
};
export const getProductPropertiesSelector = (state) => {
    return state.filters.productProperties;
};
export const getProductAttributesKeysSelector = (state) => {
    return state.filters.productAttributesKeys;
};
export const getCashierOptionsSelector = (state) => {
    const { data, offset, hasMoreData, status } = state.filters.cashierOptions;
    const options = data === null || data === void 0 ? void 0 : data.filter((item) => !!item.code).reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.code]: curr.name })), {});
    return {
        options,
        offset,
        hasMoreData,
        isLoadingMoreData: status === FetchStatus.Loading,
    };
};
export const getCategoryOptionsSelector = (state) => {
    const { data, offset, hasMoreData, status } = state.filters.categoryOptions;
    const options = data === null || data === void 0 ? void 0 : data.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.externalId]: curr.title })), {});
    return {
        options,
        offset,
        hasMoreData,
        isLoadingMoreData: status === FetchStatus.Loading,
    };
};
export const getProductPaginatedOptionsSelector = (state) => {
    const { data, offset, hasMoreData, status } = state.filters.productPaginatedOptions;
    const options = data === null || data === void 0 ? void 0 : data.reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr.externalId]: curr.title })), {});
    return {
        options,
        offset,
        hasMoreData,
        isLoadingMoreData: status === FetchStatus.Loading,
    };
};
export const getProductsOptionsSelector = (state) => {
    return (state.filters.productOptions.data || {
        items: [],
        isLoadingMoreData: true,
    });
};
export const getExtraFieldOptionsSelector = (state) => {
    const { data = {} } = state.filters.extraFields;
    const result = {};
    Object.entries(data).forEach(([key, options]) => {
        result[key] = options
            .filter((opt) => !!opt)
            .reduce((prev, curr) => (Object.assign(Object.assign({}, prev), { [curr]: curr })), {});
    });
    return result;
};
export const getShopGroupOptionsSelector = (savedFilters = [], t = (message) => message) => (state) => {
    const shopGroupOptions = state.filters.shopGroupOptions.data || [];
    const checkedItemIds = savedFilters
        .filter((filter) => filter.code.toLowerCase().endsWith('shop'))
        .flatMap((filter) => Object.keys(filter.rawConditions.leafCheckboxes || {}))
        .map((key) => Number(key));
    const convertShop = (shop) => {
        return {
            id: shop.id.toString(),
            label: shop.name,
            items: null,
        };
    };
    const convertShopGroup = (shopGroup) => {
        const shopsInThisGroup = shopGroup.shops || [];
        if (!shopsInThisGroup.length)
            return null;
        return {
            id: shopGroup.id ? shopGroup.id.toString() : 'outside-groups',
            label: shopGroup.id ? shopGroup.name : capitalize(t('вне групп')),
            items: shopsInThisGroup.map(convertShop),
            hasMoreData: false,
            defaultExpanded: !shopGroup.id ||
                shopsInThisGroup.some((shop) => checkedItemIds.includes(shop.id)),
            isCategory: true,
            isFlat: !shopGroup.id,
        };
    };
    const tree = {
        items: shopGroupOptions.map(convertShopGroup).filter(notNull),
    };
    return tree;
};
