import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ColorInput } from '@/components/ColorInput/ColorInput';
import { FontSizeSelect } from '@/components/FontSizeSelect/FontSizeSelect';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { fontSizeOptions } from '../../helpers/FontSizeOptions';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
import { BannerApple } from './BannerApple';
export const BannerAppleText = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setAppleCardIsFlipped, markReadyToSave, isElementClick, setIsElementClick, appleBanner, setAppleBanner, } = useWalletCardForm();
    const handleFocus = () => {
        setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (banner) => {
        setAppleBanner((appleBanner) => (Object.assign(Object.assign({}, appleBanner), banner)));
    };
    return (_jsxs("div", Object.assign({ className: styles.bannerWrapper }, { children: [_jsx(BannerApple, {}), _jsxs("div", Object.assign({ className: classNames(styles.control, styles.smallGap) }, { children: [_jsx("label", Object.assign({ className: styles.label, htmlFor: 'apple-banner-title' }, { children: t('Текст на баннере') })), _jsxs("div", Object.assign({ className: styles.bannerTextInputWrapper }, { children: [_jsx("div", Object.assign({ className: styles.bannerTextInput }, { children: _jsx(SmartInput, { id: 'apple-banner-title', value: appleBanner.title, vars: appleBanner.titleVars, onChange: (value, vars) => {
                                        handleChange({
                                            title: value,
                                            titleVars: vars,
                                        });
                                    }, placeholder: t('верхняя строка'), onFocus: handleFocus, onBlur: markReadyToSave, hasAutoFocus: !isElementClick }) })), _jsx("div", Object.assign({ className: styles.bannerColorInput }, { children: _jsx(ColorInput, { id: 'apple-banner-title-color', placeholder: '#FFFFFF', value: appleBanner.titleColor, onChange: (titleColor) => {
                                        handleChange({ titleColor });
                                    }, onFocus: handleFocus, onBlur: markReadyToSave }) })), _jsx(FontSizeSelect, { sizes: fontSizeOptions, value: appleBanner.titleSize, onChange: (size) => {
                                    handleChange({ titleSize: size.label });
                                    markReadyToSave();
                                }, onFocus: handleFocus })] })), _jsxs("div", Object.assign({ className: styles.bannerTextInputWrapper }, { children: [_jsx("div", Object.assign({ className: styles.bannerTextInput }, { children: _jsx(SmartInput, { id: 'apple-banner-description', value: appleBanner.description, vars: appleBanner.descriptionVars, onChange: (value, vars) => {
                                        handleChange({
                                            description: value,
                                            descriptionVars: vars,
                                        });
                                    }, placeholder: t('нижняя строка'), onFocus: handleFocus, onBlur: markReadyToSave }) })), _jsx("div", Object.assign({ className: styles.bannerColorInput }, { children: _jsx(ColorInput, { id: 'apple-banner-description-color', placeholder: '#FFFFFF', value: appleBanner.descriptionColor, onChange: (descriptionColor) => {
                                        handleChange({ descriptionColor });
                                    }, onFocus: handleFocus, onBlur: markReadyToSave }) })), _jsx(FontSizeSelect, { sizes: fontSizeOptions, value: appleBanner.descriptionSize, onChange: (size) => {
                                    handleChange({ descriptionSize: size.label });
                                    markReadyToSave();
                                }, onFocus: handleFocus })] }))] }))] })));
};
