import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Modal } from '@/components/Modal/Modal';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import styles from './styles.module.scss';
export const SwitchBrandModal = ({ brands = [], onSelectBrand, currentBrandGlobalKey, isLoading = false, isOpen, onClose, }) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState('');
    const filteredBrands = brands.filter((brand) => brand.name.toLowerCase().includes(query.trim().toLowerCase()));
    return (_jsxs(Modal, Object.assign({ className: classNames({ [styles.loading]: isLoading }), isOpen: isOpen, onClose: onClose, size: '550' }, { children: [_jsx(SearchInput, { value: query, onChange: setQuery, placeholder: t('Поищи'), debounceDelay: 0, hasAutoFocus: true }), filteredBrands.length > 0 ? (_jsx("div", Object.assign({ className: styles.brands }, { children: filteredBrands.map((brand) => (_jsx(Button, Object.assign({ className: classNames({ [styles.loading]: isLoading }), onClick: () => onSelectBrand === null || onSelectBrand === void 0 ? void 0 : onSelectBrand(brand), theme: brand.globalKey === currentBrandGlobalKey
                        ? 'primary'
                        : 'default', disabled: isLoading }, { children: brand.name }), brand.globalKey))) }))) : (_jsx("div", Object.assign({ className: styles.empty }, { children: t('Ничего не найдено') })))] })));
};
