var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Input } from '@/components/Input/Input';
import styles from '../styles.module.scss';
import { AutocompleteChevron } from './AutocompleteChevron';
import { AutocompleteHelperTooltip } from './AutocompleteHelperTooltip';
import { AutocompleteListBox } from './AutocompleteListBox';
import { AutocompleteResetButton } from './AutocompleteResetButton';
import { useAutocompleteInput } from './useAutocompleteInput';
export const AutocompleteInput = (_a) => {
    var { className, disabled, onKeyDown } = _a, props = __rest(_a, ["className", "disabled", "onKeyDown"]);
    const { context, inputProps, handleClick, handleFocus, handleMouseDown } = useAutocompleteInput('input');
    const { id, value, hasResetButton, asSelect, isLarge, isInline, wrapperRef, popupOpen, getRootProps, } = context;
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.hasResetButton]: !!value && hasResetButton,
            [styles.large]: isLarge,
            [styles.inline]: isInline,
        }, className), ref: wrapperRef }, { children: [_jsx("div", Object.assign({ className: styles.root }, getRootProps === null || getRootProps === void 0 ? void 0 : getRootProps(), { children: _jsx(Input, Object.assign({}, props, inputProps, { id: id, onClick: handleClick, onMouseDown: asSelect ? undefined : handleMouseDown, onFocus: asSelect ? undefined : handleFocus, onKeyDown: (e) => {
                        if (!popupOpen)
                            onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(e);
                    }, className: styles.input, disabled: disabled, type: asSelect ? 'button' : 'text', rightElement: _jsxs(_Fragment, { children: [_jsx(AutocompleteHelperTooltip, { disabled: disabled, onClick: handleClick }), _jsx(AutocompleteChevron, { disabled: disabled }), _jsx(AutocompleteListBox, {})] }), isInline: isInline, inputWrapperClassName: styles.inputWrapper })) })), _jsx(AutocompleteResetButton, {})] })));
};
