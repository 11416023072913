import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { CheckboxTree, } from '@/components/CheckboxTree/CheckboxTree';
import { Wrapper } from './Wrapper';
export const CheckboxesTreeLoader = memo(({ item, onApply, onBack, limit = 50 }) => {
    var _a, _b, _c, _d, _e, _f;
    const [operator, setOperator] = useState(((_a = item.value) === null || _a === void 0 ? void 0 : _a.operator) || 'include');
    const [search, setSearch] = useState(((_b = item.value) === null || _b === void 0 ? void 0 : _b.search) || '');
    const [checked, setChecked] = useState([
        ...(((_d = (_c = item.value) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.branches) || []).map((id) => ({
            id,
            isCategory: true,
        })),
        ...(((_f = (_e = item.value) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.leaves) || []).map((id) => ({ id })),
    ]);
    const branches = checked.filter((i) => i.isCategory).map((i) => i.id);
    const leaves = checked.filter((i) => !i.isCategory).map((i) => i.id);
    const { rootId, items, offset = 0, hasMoreData = false, isLoadingMoreData = false, } = item.options;
    const loadData = useCallback((offset, parentId) => {
        item.loadBranch({
            parentId: parentId || rootId,
            categoryIds: branches,
            ids: leaves,
            searchText: search,
            limit,
            offset,
        });
    }, [rootId, checked, search]);
    const handleLoadMoreData = useCallback((item, reset) => {
        if (!item) {
            loadData(offset + limit);
            return;
        }
        loadData(typeof item.offset === 'undefined' || reset ? 0 : item.offset + limit, item.branchId);
    }, [offset, loadData]);
    useEffect(() => {
        item.loadTree({
            branchIds: branches,
            leafIds: leaves,
            searchText: search,
            limit,
        });
    }, [search]);
    const handleApply = () => onApply(Object.assign(Object.assign({}, item), { value: Object.assign(Object.assign({}, item.value), { operator,
            search, value: {
                branches,
                leaves,
            } }) }));
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: operator, onChangeOperator: setOperator, onApplyClick: handleApply, isApplyButtonDisabled: !checked.length, isLoading: !items, count: checked.length }, { children: _jsx(CheckboxTree, { items: items, checkedItems: checked, onChangeCheckedItems: setChecked, hasMoreData: hasMoreData, isLoadingMoreData: isLoadingMoreData, onLoadMoreData: handleLoadMoreData, search: search, onChangeSearch: setSearch, hasSearch: true }) })));
});
