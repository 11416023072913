import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-no-target-blank */
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Help } from '@/icons/Help';
import { Lock } from '@/icons/Lock';
import { Check } from '@/icons/nav/Check';
import { ExternalLink } from '@/icons/nav/ExternalLink';
import styles from './styles.module.scss';
export const AppNavDropdownItem = memo(({ item, onClick }) => {
    const { t } = useTranslation();
    const handleClick = useCallback((e) => {
        if (e.ctrlKey)
            return;
        if (!item.isExternal)
            e.preventDefault();
        if (item.onClick)
            item.onClick();
        if (onClick)
            onClick();
    }, [item.isExternal, item.onClick, onClick]);
    let leftContent;
    if (item.isActive)
        leftContent = _jsx(Check, {});
    if (!item.isActive && item.left)
        leftContent = item.left;
    const lockedLabel = (_jsxs(_Fragment, { children: [item.label, _jsx(Tooltip, Object.assign({ title: _jsx("span", Object.assign({ className: styles.lockTooltip }, { children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') })), placement: 'right' }, { children: _jsx("span", Object.assign({ className: styles.lockIcon }, { children: _jsx(Lock, {}) })) }))] }));
    const link = (_jsxs("a", Object.assign({ className: classNames(styles.item, {
            [styles.disabled]: item.isDisabled,
        }), href: item.href, target: item.isExternal ? '_blank' : undefined, rel: item.isExternal ? 'noreferrer' : undefined, onClick: handleClick }, { children: [_jsxs("span", Object.assign({ className: classNames(styles.label, {
                    [styles.withIcon]: !!item.icon,
                    [styles.withLeftContent]: !!item.left,
                }) }, { children: [item.icon && _jsx("span", Object.assign({ className: styles.icon }, { children: item.icon })), item.isLocked ? lockedLabel : item.label, item.isExternal && (_jsx(ExternalLink, { className: styles.externalLinkIcon })), item.tooltip && !item.hideTooltipIcon && (_jsx(Help, { className: styles.helpIcon }))] })), leftContent] })));
    return (_jsx("li", { children: item.tooltip ? (_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: t(item.tooltip), placement: 'right' }, { children: link }))) : (link) }, item.id));
});
