import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { money } from '@/utils/numbers';
import { AccordionContent } from '../AccordionContent';
import styles from '../styles.module.scss';
import { createOffersList } from './createOffersList';
export const tableRows = (currency, data) => {
    if (data) {
        const offersList = createOffersList(data);
        return offersList.map((item, index) => {
            const currentCurrency = item.isMoney ? currency : undefined;
            return {
                id: index + 1,
                notExpand: false,
                cells: [
                    {
                        title: '',
                    },
                    {
                        title: (_jsx("div", Object.assign({ className: styles.productTitle }, { children: _jsxs("span", { children: [" ", item.title] }) }))),
                        content: (_jsx(AccordionContent, { offersList: item.value, currency: currentCurrency })),
                    },
                    {
                        title: money({
                            input: String(item.discountSum),
                            withFraction: true,
                            removeFractionZeros: true,
                            currency: currentCurrency,
                        }),
                        className: styles.discountCell,
                    },
                    {
                        title: item.bonusesCollected || '',
                        className: styles.bonusCell,
                    },
                ],
            };
        });
    }
    return [];
};
