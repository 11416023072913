import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import styles from './styles.module.scss';
export var ExportTemplateType;
(function (ExportTemplateType) {
    ExportTemplateType[ExportTemplateType["CLIENT"] = 0] = "CLIENT";
    ExportTemplateType[ExportTemplateType["PURCHASE"] = 1] = "PURCHASE";
    ExportTemplateType[ExportTemplateType["CLIENT_BY_OPERATOR"] = 2] = "CLIENT_BY_OPERATOR";
    ExportTemplateType[ExportTemplateType["CLIENT_CHILDREN"] = 3] = "CLIENT_CHILDREN";
    ExportTemplateType[ExportTemplateType["BONUS_HISTORY"] = 5] = "BONUS_HISTORY";
    ExportTemplateType[ExportTemplateType["ACCOUNTING"] = 7] = "ACCOUNTING";
    ExportTemplateType[ExportTemplateType["OPERATOR_AUDIT"] = 10] = "OPERATOR_AUDIT";
})(ExportTemplateType || (ExportTemplateType = {}));
export const ExportTemplatesTable = memo(({ onExport, onGoToClients }) => {
    const { t } = useTranslation();
    const templates = [
        {
            type: ExportTemplateType.CLIENT,
            name: 'Список клиентов',
            description: 'Детальная информация о клиентах за выбранный период',
            actions: [
                {
                    id: 'go-to-clients',
                    label: 'Применить фильтры',
                    onClick: onGoToClients,
                },
            ],
        },
        {
            type: ExportTemplateType.CLIENT_CHILDREN,
            name: 'Список клиентов и их детей',
            description: 'Базовая информация о клиенте, совмещенная с именем и датой рождения его детей',
        },
        {
            type: ExportTemplateType.PURCHASE,
            name: 'Список покупок',
            description: 'Детальная информация о продажах за выбранный период',
        },
        {
            type: ExportTemplateType.CLIENT_BY_OPERATOR,
            name: 'Загрузка клиентов по операторам',
            description: 'Информация о количестве и качестве загруженных операторами анкет',
        },
        {
            type: ExportTemplateType.BONUS_HISTORY,
            name: 'Движение бонусов',
            description: 'Детальная информация об изменениях в бонусах за выбранный период',
        },
        {
            type: ExportTemplateType.ACCOUNTING,
            name: 'Взаиморасчеты',
            description: 'Отчет, позволяющий произвести взаиморасчеты между торговыми точками по начисленным и списанным бонусам, а также погашенным подарочным картам',
        },
        {
            type: ExportTemplateType.OPERATOR_AUDIT,
            name: 'Действия пользователей платформы',
            description: 'Действия пользователей в платформе, такие как создание, изменение или удаление клиентов, акций, рассылок и так далее',
        },
    ];
    const columns = useMemo(() => [
        { id: 'name', title: t('Название') },
        { id: 'description' },
        { id: 'actions' },
    ], [t]);
    const rows = useMemo(() => templates.map(({ type, name, description, actions = [] }) => ({
        id: type,
        cells: [
            t(name),
            _jsx("span", Object.assign({ className: styles.description }, { children: t(description) }), 'description'),
        ],
        actions: [
            ...actions.map((action) => (Object.assign(Object.assign({}, action), { label: typeof action.label === 'string'
                    ? t(action.label)
                    : action.label }))),
            {
                id: 'export',
                label: t('Выгрузить в Excel'),
                onClick: () => onExport(type),
            },
        ],
    })), [templates, t]);
    return _jsx(Table, { needAdaptive: false, columns: columns, rows: rows });
});
