import { jsx as _jsx } from "react/jsx-runtime";
import { WalletCardFormSection } from '@/components/wallet-cards/WalletCardFormSection/WalletCardFormSection';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { BannerApple } from './BannerApple';
import { BannerAppleText } from './BannerAppleText';
import { BannerCounter } from './BannerCounter';
import { BannerGoogleText } from './BannerGoogleText';
export const Banner = () => {
    const { bannerType } = useWalletCardForm();
    return (_jsx(WalletCardFormSection, { top: bannerType === 'with-counter' ? _jsx(BannerCounter, {}) : undefined, apple: bannerType === 'simple' || bannerType === 'with-counter' ? (_jsx(BannerApple, {})) : (_jsx(BannerAppleText, {})), google: _jsx(BannerGoogleText, { hasTextInputs: bannerType === 'with-text' }) }));
};
