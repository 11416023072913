export const convertWalletCardToFormValues = (card) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16;
    const background = (_a = card.configuration) === null || _a === void 0 ? void 0 : _a.backgroundColor;
    const color = (_b = card.configuration) === null || _b === void 0 ? void 0 : _b.textColor;
    const labelColor = (_c = card.configuration) === null || _c === void 0 ? void 0 : _c.labelColor;
    const header = (_d = card.configuration) === null || _d === void 0 ? void 0 : _d.header;
    const banner = (_e = card.configuration) === null || _e === void 0 ? void 0 : _e.banner;
    const fields = (_f = card.configuration) === null || _f === void 0 ? void 0 : _f.fields;
    const notification = (_g = card.configuration) === null || _g === void 0 ? void 0 : _g.notification;
    const backside = (_h = card.configuration) === null || _h === void 0 ? void 0 : _h.backside;
    const defaultValues = {
        background,
        color,
        labelColor,
        appleHead: {
            logo: ((_j = header === null || header === void 0 ? void 0 : header.apple.logo) === null || _j === void 0 ? void 0 : _j[1]) || null,
            logoSmallUploadResponse: (_k = header === null || header === void 0 ? void 0 : header.apple.logo) === null || _k === void 0 ? void 0 : _k[0],
            logoUploadResponse: (_l = header === null || header === void 0 ? void 0 : header.apple.logo) === null || _l === void 0 ? void 0 : _l[1],
            logoRejectReason: '',
            logoLoading: false,
            title: (header === null || header === void 0 ? void 0 : header.apple.title) || '',
            field_1_Title: (header === null || header === void 0 ? void 0 : header.apple.firstField.label) || '',
            field_1_Value: (header === null || header === void 0 ? void 0 : header.apple.firstField.value) || '',
            field_1_Vars: (header === null || header === void 0 ? void 0 : header.apple.firstField.vars) || undefined,
            field_2_Title: (header === null || header === void 0 ? void 0 : header.apple.secondField.label) || '',
            field_2_Value: (header === null || header === void 0 ? void 0 : header.apple.secondField.value) || '',
            field_2_Vars: (header === null || header === void 0 ? void 0 : header.apple.secondField.vars) || undefined,
        },
        googleHead: {
            logo: (header === null || header === void 0 ? void 0 : header.google.logo) || null,
            logoUploadResponse: (header === null || header === void 0 ? void 0 : header.google.logo) || undefined,
            logoRejectReason: '',
            logoLoading: false,
            title: (header === null || header === void 0 ? void 0 : header.google.title) || '',
            programName: (header === null || header === void 0 ? void 0 : header.google.programName) || '',
            field_Main_Title: (header === null || header === void 0 ? void 0 : header.google.firstField.label) || '',
            field_Main_Value: (header === null || header === void 0 ? void 0 : header.google.firstField.value) || '',
            field_Main_Vars: (header === null || header === void 0 ? void 0 : header.google.firstField.vars) || undefined,
        },
        bannerType: banner === null || banner === void 0 ? void 0 : banner.type,
        bannerCounter: {
            offerId: ((_m = banner === null || banner === void 0 ? void 0 : banner.counter) === null || _m === void 0 ? void 0 : _m.offerId) || null,
            tickImage: ((_o = banner === null || banner === void 0 ? void 0 : banner.counter) === null || _o === void 0 ? void 0 : _o.tickImage) || null,
            tickImageUploadResponse: ((_p = banner === null || banner === void 0 ? void 0 : banner.counter) === null || _p === void 0 ? void 0 : _p.tickImage) || undefined,
            tickImageRejectReason: '',
            tickImageLoading: false,
            blankImage: ((_q = banner === null || banner === void 0 ? void 0 : banner.counter) === null || _q === void 0 ? void 0 : _q.blankImage) || null,
            blankImageUploadResponse: ((_r = banner === null || banner === void 0 ? void 0 : banner.counter) === null || _r === void 0 ? void 0 : _r.blankImage) || undefined,
            blankImageRejectReason: '',
            blankImageLoading: false,
        },
        appleBanner: {
            image: ((_s = banner === null || banner === void 0 ? void 0 : banner.apple.image) === null || _s === void 0 ? void 0 : _s[1]) || null,
            imageSmallUploadResponse: (_t = banner === null || banner === void 0 ? void 0 : banner.apple.image) === null || _t === void 0 ? void 0 : _t[0],
            imageUploadResponse: (_u = banner === null || banner === void 0 ? void 0 : banner.apple.image) === null || _u === void 0 ? void 0 : _u[1],
            imageRejectReason: '',
            imageLoading: false,
            title: (banner === null || banner === void 0 ? void 0 : banner.apple.title) || '',
            titleVars: (banner === null || banner === void 0 ? void 0 : banner.apple.titleVars) || undefined,
            titleColor: (banner === null || banner === void 0 ? void 0 : banner.apple.titleColor) || '',
            titleSize: ((banner === null || banner === void 0 ? void 0 : banner.apple.titleSize) || '').toString(),
            description: (banner === null || banner === void 0 ? void 0 : banner.apple.description) || '',
            descriptionVars: (banner === null || banner === void 0 ? void 0 : banner.apple.descriptionVars) || undefined,
            descriptionColor: (banner === null || banner === void 0 ? void 0 : banner.apple.descriptionColor) || '',
            descriptionSize: ((banner === null || banner === void 0 ? void 0 : banner.apple.descriptionSize) || '').toString(),
        },
        googleBanner: {
            image: (banner === null || banner === void 0 ? void 0 : banner.google.image) || null,
            imageUploadResponse: (banner === null || banner === void 0 ? void 0 : banner.google.image) || undefined,
            imageRejectReason: '',
            imageLoading: false,
            backsideImage: (banner === null || banner === void 0 ? void 0 : banner.google.backsideImage) || null,
            backsideImageUploadResponse: (banner === null || banner === void 0 ? void 0 : banner.google.backsideImage) || undefined,
            backsideImageRejectReason: '',
            backsideImageLoading: false,
            title: (banner === null || banner === void 0 ? void 0 : banner.google.title) || '',
            titleVars: (banner === null || banner === void 0 ? void 0 : banner.google.titleVars) || undefined,
            titleColor: (banner === null || banner === void 0 ? void 0 : banner.google.titleColor) || '',
            titleSize: ((banner === null || banner === void 0 ? void 0 : banner.google.titleSize) || '').toString(),
            description: (banner === null || banner === void 0 ? void 0 : banner.google.description) || '',
            descriptionVars: (banner === null || banner === void 0 ? void 0 : banner.google.descriptionVars) || undefined,
            descriptionColor: (banner === null || banner === void 0 ? void 0 : banner.google.descriptionColor) || '',
            descriptionSize: ((banner === null || banner === void 0 ? void 0 : banner.google.descriptionSize) || '').toString(),
        },
        appleFields: {
            field_3_Title: (fields === null || fields === void 0 ? void 0 : fields.apple.first.label) || '',
            field_3_Value: (fields === null || fields === void 0 ? void 0 : fields.apple.first.value) || '',
            field_3_Vars: (fields === null || fields === void 0 ? void 0 : fields.apple.first.vars) || undefined,
            field_4_Title: (fields === null || fields === void 0 ? void 0 : fields.apple.second.label) || '',
            field_4_Value: (fields === null || fields === void 0 ? void 0 : fields.apple.second.value) || '',
            field_4_Vars: (fields === null || fields === void 0 ? void 0 : fields.apple.second.vars) || undefined,
            field_5_Title: (fields === null || fields === void 0 ? void 0 : fields.apple.third.label) || '',
            field_5_Value: (fields === null || fields === void 0 ? void 0 : fields.apple.third.value) || '',
            field_5_Vars: (fields === null || fields === void 0 ? void 0 : fields.apple.third.vars) || undefined,
            field_6_Title: (fields === null || fields === void 0 ? void 0 : fields.apple.fourth.label) || '',
            field_6_Value: (fields === null || fields === void 0 ? void 0 : fields.apple.fourth.value) || '',
            field_6_Vars: (fields === null || fields === void 0 ? void 0 : fields.apple.fourth.vars) || undefined,
        },
        googleFields: {
            field_1_Title: (fields === null || fields === void 0 ? void 0 : fields.google.first.label) || '',
            field_1_Value: (fields === null || fields === void 0 ? void 0 : fields.google.first.value) || '',
            field_1_Vars: (fields === null || fields === void 0 ? void 0 : fields.google.first.vars) || undefined,
            field_2_Title: (fields === null || fields === void 0 ? void 0 : fields.google.second.label) || '',
            field_2_Value: (fields === null || fields === void 0 ? void 0 : fields.google.second.value) || '',
            field_2_Vars: (fields === null || fields === void 0 ? void 0 : fields.google.second.vars) || undefined,
            field_3_Title: (fields === null || fields === void 0 ? void 0 : fields.google.third.label) || '',
            field_3_Value: (fields === null || fields === void 0 ? void 0 : fields.google.third.value) || '',
            field_3_Vars: (fields === null || fields === void 0 ? void 0 : fields.google.third.vars) || undefined,
            field_4_Title: ((_v = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _v === void 0 ? void 0 : _v.label) || undefined,
            field_4_Value: ((_w = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _w === void 0 ? void 0 : _w.value) || undefined,
            field_4_Vars: ((_x = fields === null || fields === void 0 ? void 0 : fields.google.fourth) === null || _x === void 0 ? void 0 : _x.vars) || undefined,
            field_5_Title: ((_y = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _y === void 0 ? void 0 : _y.label) || undefined,
            field_5_Value: ((_z = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _z === void 0 ? void 0 : _z.value) || undefined,
            field_5_Vars: ((_0 = fields === null || fields === void 0 ? void 0 : fields.google.fifth) === null || _0 === void 0 ? void 0 : _0.vars) || undefined,
            field_6_Title: ((_1 = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _1 === void 0 ? void 0 : _1.label) || undefined,
            field_6_Value: ((_2 = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _2 === void 0 ? void 0 : _2.value) || undefined,
            field_6_Vars: ((_3 = fields === null || fields === void 0 ? void 0 : fields.google.sixth) === null || _3 === void 0 ? void 0 : _3.vars) || undefined,
            field_7_Title: ((_4 = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _4 === void 0 ? void 0 : _4.label) || undefined,
            field_7_Value: ((_5 = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _5 === void 0 ? void 0 : _5.value) || undefined,
            field_7_Vars: ((_6 = fields === null || fields === void 0 ? void 0 : fields.google.seventh) === null || _6 === void 0 ? void 0 : _6.vars) || undefined,
            field_8_Title: ((_7 = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _7 === void 0 ? void 0 : _7.label) || undefined,
            field_8_Value: ((_8 = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _8 === void 0 ? void 0 : _8.value) || undefined,
            field_8_Vars: ((_9 = fields === null || fields === void 0 ? void 0 : fields.google.eighth) === null || _9 === void 0 ? void 0 : _9.vars) || undefined,
            field_9_Title: ((_10 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _10 === void 0 ? void 0 : _10.label) || undefined,
            field_9_Value: ((_11 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _11 === void 0 ? void 0 : _11.value) || undefined,
            field_9_Vars: ((_12 = fields === null || fields === void 0 ? void 0 : fields.google.ninth) === null || _12 === void 0 ? void 0 : _12.vars) || undefined,
        },
        notify: {
            title: (notification === null || notification === void 0 ? void 0 : notification.title) || '',
            logo: ((_13 = notification === null || notification === void 0 ? void 0 : notification.appleLogo) === null || _13 === void 0 ? void 0 : _13[1]) || null,
            logoSmallUploadResponse: (_14 = notification === null || notification === void 0 ? void 0 : notification.appleLogo) === null || _14 === void 0 ? void 0 : _14[0],
            logoUploadResponse: (_15 = notification === null || notification === void 0 ? void 0 : notification.appleLogo) === null || _15 === void 0 ? void 0 : _15[1],
            logoRejectReason: '',
            logoLoading: false,
        },
        backside: {
            appleTitle: (backside === null || backside === void 0 ? void 0 : backside.title) || '',
            contacts: (backside === null || backside === void 0 ? void 0 : backside.contacts.length)
                ? backside === null || backside === void 0 ? void 0 : backside.contacts.map(convertContact)
                : [{ title: '', content: '' }],
            details: (backside === null || backside === void 0 ? void 0 : backside.fields.length)
                ? backside === null || backside === void 0 ? void 0 : backside.fields.map(convertDetailsItem)
                : [{ title: '', content: '' }],
        },
        barcode: ((_16 = card.configuration) === null || _16 === void 0 ? void 0 : _16.barcode) || '',
    };
    return defaultValues;
};
const convertContact = (contact) => {
    var _a, _b, _c;
    return {
        title: contact.label || '',
        content: ((_a = contact.value) === null || _a === void 0 ? void 0 : _a.replace('tel:', '').replace('mailto:', '')) || '',
        type: ((_b = contact.value) === null || _b === void 0 ? void 0 : _b.startsWith('tel:'))
            ? 'phone'
            : ((_c = contact.value) === null || _c === void 0 ? void 0 : _c.startsWith('mailto:'))
                ? 'email'
                : 'link',
    };
};
const convertDetailsItem = (item) => {
    return {
        title: item.label || '',
        content: item.value || '',
        vars: item.vars || undefined,
    };
};
