import { useGetGiftCardQuery, useGetInitialGiftCardQuery, } from '@/api/query/giftCard';
export const useInitialData = (giftCardId) => {
    const { data: initialGiftCard, isUninitialized: isUninitializedInitialGiftCard, isFetching: isLoadingInitialGiftCard, } = useGetInitialGiftCardQuery(undefined, {
        skip: !!giftCardId,
    });
    const { data: selectedGiftCard, isUninitialized: isUninitializedSelectedGiftCard, isFetching: isLoadingSelectedGiftCard, } = useGetGiftCardQuery(giftCardId || 0, {
        skip: !giftCardId,
    });
    return {
        defaultValues: (giftCardId
            ? selectedGiftCard
            : initialGiftCard),
        isLoading: giftCardId
            ? isUninitializedSelectedGiftCard || isLoadingSelectedGiftCard
            : isUninitializedInitialGiftCard || isLoadingInitialGiftCard,
    };
};
