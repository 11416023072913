import { api } from './';
export const clientApi = api.injectEndpoints({
    endpoints: (build) => ({
        getClientCount: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/client/count',
                body,
            }),
            providesTags: ['ClientCount'],
        }),
        getClientList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/client/list',
                body,
            }),
            providesTags: ['ClientList'],
        }),
        searchClients: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/client/list',
                body,
            }),
        }),
        getClient: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/client/${id}/get`,
            }),
            providesTags: ['Client'],
        }),
        addClient: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/client/add',
                body,
            }),
            invalidatesTags: ['ClientCount'],
        }),
        updateClient: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: `/client/${body.id}/set`,
                body,
            }),
            invalidatesTags: ['Client', 'ClientList'],
        }),
        deleteClient: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/client/delete',
                body,
            }),
            invalidatesTags: ['ClientCount'],
        }),
        blockClient: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/client/block',
                body,
            }),
            invalidatesTags: ['Client', 'ClientList'],
        }),
        unblockClient: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/client/unblock',
                body,
            }),
            invalidatesTags: ['Client', 'ClientList'],
        }),
        getClientsEventList: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: `/client/event/list`,
                body,
            }),
        }),
    }),
});
export const { useGetClientCountQuery, useGetClientListQuery, useSearchClientsMutation, useGetClientQuery, useAddClientMutation, useUpdateClientMutation, useDeleteClientMutation, useBlockClientMutation, useUnblockClientMutation, useGetClientsEventListMutation, } = clientApi;
