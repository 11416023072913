var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useNavigate } from 'react-router';
import { getErrorMessage } from '@/api/query';
import { useGetDashboardExportsMutation } from '@/api/query/dashboard';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppSelector } from '@/hooks/store';
import { useAppPeriod } from '@/hooks/useAppPeriod';
import { compileFilters } from '@/services/filters/compileFilters';
import { activeDashboardAnalyticsSelector, activeDashboardSelector, } from '@/store/selectors/reports';
import { ROUTES } from '../routes';
export const useExportReport = (bucketSize) => {
    const navigate = useNavigate();
    const addToast = useToastContext();
    const [createExport] = useGetDashboardExportsMutation();
    const { formattedStart, formattedEnd } = useAppPeriod();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const widget = useAppSelector(activeDashboardAnalyticsSelector);
    const filters = (activeDashboard === null || activeDashboard === void 0 ? void 0 : activeDashboard.filters) || [];
    const currentMetrics = (widget === null || widget === void 0 ? void 0 : widget.configuration.metrics.map((m) => m.key)) || [];
    const dimensions = (widget === null || widget === void 0 ? void 0 : widget.configuration.dimensions.map((d) => d.key)) || [];
    const payload = {
        bucketSize,
        dimensions,
        metrics: currentMetrics,
        periodEnd: formattedEnd,
        periodStart: formattedStart,
        filters: compileFilters(filters),
        limit: widget === null || widget === void 0 ? void 0 : widget.configuration.tableMaxResults,
    };
    return () => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield createExport(payload);
        if ('error' in result)
            addToast({
                type: 'error',
                message: getErrorMessage(result.error),
            });
        else
            navigate(ROUTES.EXPORTS);
    });
};
