import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { FiltersPanel } from '@/components/FiltersPanel/FiltersPanel';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useAnalyticsFilters } from '@/services/filters/data/useAnalyticsFilters';
import { activeDashboardSelector } from '@/store/selectors/reports';
import { updateDashboardFilters } from '@/store/slice/reports';
export const AnalyticsFilters = ({ extraFields, }) => {
    const dispatch = useAppDispatch();
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const filtersTree = useAnalyticsFilters(extraFields);
    if (!activeDashboard)
        return null;
    const handleUpdateFilters = (filters) => dispatch(updateDashboardFilters({ dashboardId: activeDashboard.id, filters }));
    const handleResetFilters = () => dispatch(updateDashboardFilters({ dashboardId: activeDashboard.id, filters: [] }));
    const hasValue = activeDashboard.filters.length > 0;
    return (_jsx(_Fragment, { children: hasValue ? (_jsx(FiltersPanel, { filtersTree: filtersTree, value: activeDashboard.filters, onUpdate: handleUpdateFilters, plusButtonTheme: 'default', hasResetButton: true, onResetFilters: handleResetFilters, hasHangingOperator: false })) : (_jsx(FiltersButton, { filtersTree: filtersTree, value: activeDashboard.filters, onUpdate: handleUpdateFilters })) }));
};
