import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { Check } from '@/icons/Check';
import { fgColor } from '@/utils/colors';
import { COLORS } from './COLORS';
import styles from './styles.module.scss';
const ColorRadio = memo(({ value, onChange }) => {
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: COLORS.map((color) => (_jsxs("div", Object.assign({ className: classNames(styles.buttonWrapper, {
                [styles.selected]: color === value,
            }), style: { color: fgColor(color) } }, { children: [_jsx(Check, { className: styles.check, width: 16, height: 12 }), _jsx("input", { className: styles.button, type: 'radio', name: 'color', checked: color === value, onChange: () => onChange(color), style: { backgroundColor: color } })] }), color))) })));
});
export { ColorRadio, COLORS };
