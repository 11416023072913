import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/Switch/Switch';
import { ANALYTICS_FIELDS, BILLING_FIELDS, CLIENTS_FIELDS, SECTIONS, SETTINGS_FIELDS, TOOLS_FIELDS, } from './constants';
import styles from './styles.module.scss';
const findFieldMatch = (formValues, fieldsArray) => {
    return fieldsArray.some((field) => !!formValues[field]);
};
const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element)
        element.scrollIntoView({ behavior: 'smooth' });
};
export const Switchers = memo(({ isView, sections, defaultValues, setSections, setValue }) => {
    const { t } = useTranslation();
    const switchDuration = useRef(true);
    const isAnalyticsOnly = !sections.clients &&
        !sections.tools &&
        !sections.billing &&
        !sections.settings;
    const handleSwitchSection = (section) => {
        let falsySectionsCount = 0;
        for (const key in sections) {
            const currentSection = sections[key];
            if (!currentSection)
                falsySectionsCount += 1;
        }
        // если мы отключаем последний тогл то включаем тогл аналитики
        if (falsySectionsCount === 4 && sections[section])
            setSections((prevValue) => (Object.assign(Object.assign({}, prevValue), { [section]: !prevValue[section], analytics: true })));
        else
            setSections((prevValue) => (Object.assign(Object.assign({}, prevValue), { [section]: !prevValue[section] })));
    };
    useEffect(() => {
        // Если есть дефолтные данные то включаем секции поля которых есть в дефолтных данных
        if (defaultValues) {
            if (findFieldMatch(defaultValues, ANALYTICS_FIELDS))
                handleSwitchSection('analytics');
            if (findFieldMatch(defaultValues, CLIENTS_FIELDS))
                handleSwitchSection('clients');
            if (findFieldMatch(defaultValues, BILLING_FIELDS))
                handleSwitchSection('billing');
            if (findFieldMatch(defaultValues, SETTINGS_FIELDS))
                handleSwitchSection('settings');
            if (findFieldMatch(defaultValues, TOOLS_FIELDS))
                handleSwitchSection('tools');
        }
        // иначе включаем обязательную секцию аналитики
        else
            handleSwitchSection('analytics');
        // возвращаем задержку для тоглов по умолчанию
        setTimeout(() => {
            switchDuration.current = false;
        });
    }, []);
    return (_jsx(_Fragment, { children: SECTIONS.map(({ id, label }) => {
            const sectionId = id;
            return (_jsxs("div", Object.assign({ className: styles.switchWrapper }, { children: [_jsx(Switch, { className: classNames({
                            [styles.switch]: switchDuration.current,
                        }), checked: sections[id], disabled: isView || (id === 'analytics' && isAnalyticsOnly), onChange: () => {
                            sections[id] &&
                                setValue('hasSwitches', true);
                            handleSwitchSection(sectionId);
                            setTimeout(() => scrollToSection(sectionId));
                        } }), _jsx("button", Object.assign({ type: 'button', onClick: () => scrollToSection(sectionId), className: classNames(styles.switchLabel, {
                            [styles.switchLabelChecked]: sections[sectionId],
                            [styles.switchLabelActive]: sections[sectionId],
                        }) }, { children: t(label) }))] }), sectionId));
        }) }));
});
