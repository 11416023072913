import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import styles from './styles.module.scss';
export const ImportFormFooter = ({ error, isSubmitting = false, onDelete, onSaveDraft, onSubmit, }) => {
    const { t } = useTranslation();
    const deleteButton = (_jsx(TrashButton, { tooltip: t('Удалить импорт'), onClick: onDelete }));
    const saveDraftButton = (_jsx(Button, Object.assign({ type: 'button', onClick: onSaveDraft, disabled: isSubmitting, isOutlined: true }, { children: t('Сохранить черновик') })));
    const submitButton = (_jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: isSubmitting, size: 'large', onClick: onSubmit }, { children: t('Загрузить') })));
    return (_jsxs("div", Object.assign({ className: styles.footer }, { children: [error && (_jsx(FormFieldRow, Object.assign({ controlClassName: styles.error, hasLabelHidden: true, asErrorMessage: true }, { children: error }))), _jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsxs("div", Object.assign({ className: styles.toolbar }, { children: [deleteButton, saveDraftButton] })), submitButton] }))] })));
};
