var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { InputButton } from '@/components/Input/InputButton';
import styles from '../styles.module.scss';
import { AutocompleteChevron } from './AutocompleteChevron';
import { AutocompleteHelperTooltip } from './AutocompleteHelperTooltip';
import { AutocompleteListBox } from './AutocompleteListBox';
import { AutocompleteResetButton } from './AutocompleteResetButton';
import { useAutocompleteInput } from './useAutocompleteInput';
export const AutocompleteButton = (_a) => {
    var { className, disabled, onKeyDown } = _a, props = __rest(_a, ["className", "disabled", "onKeyDown"]);
    const { context, handleClick, buttonProps } = useAutocompleteInput('button');
    const { id, value, hasResetButton, isLarge, isInline, wrapperRef, popupOpen, getRootProps, getOptionLabel, renderOption = getOptionLabel, renderValue = renderOption, } = context;
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.hasResetButton]: !!value && hasResetButton,
            [styles.large]: isLarge,
            [styles.inline]: isInline,
        }, className), ref: wrapperRef }, { children: [_jsx("div", Object.assign({ className: styles.root }, getRootProps === null || getRootProps === void 0 ? void 0 : getRootProps(), { children: _jsx(InputButton, Object.assign({}, props, buttonProps, { id: id, onClick: handleClick, onKeyDown: (e) => {
                        if (!popupOpen)
                            onKeyDown === null || onKeyDown === void 0 ? void 0 : onKeyDown(e);
                    }, className: classNames(styles.input, styles.buttonInput), disabled: disabled, type: 'button', rightElement: _jsxs(_Fragment, { children: [_jsx(AutocompleteHelperTooltip, { disabled: disabled, onClick: handleClick }), _jsx(AutocompleteChevron, { disabled: disabled }), _jsx(AutocompleteListBox, {})] }), isInline: isInline }, { children: renderValue === null || renderValue === void 0 ? void 0 : renderValue(value, '') })) })), _jsx(AutocompleteResetButton, {})] })));
};
