import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useDashboardChart } from '@/app/pages/Dashboard/useDashboardChart';
import { useDashboardStats } from '@/app/pages/Dashboard/useDashboardStats';
import { DependencePanel } from './DependencePanel';
export const DependencePanelContainer = ({ currency, isProfitVisible }) => {
    const [scale, setScale] = useState('day');
    const [leftSource, setLeftSource] = useState('revenue');
    const [rightSource, setRightSource] = useState('expenses');
    const { chart: data, isLoading: isLoadingChart } = useDashboardChart(leftSource, rightSource, scale.charAt(0));
    const { stats, isLoading: isLoadingStats } = useDashboardStats();
    const getDatasetTotal = (dataset) => {
        switch (dataset) {
            case 'revenue':
                return Number((stats === null || stats === void 0 ? void 0 : stats.stats.revenueCards) || 0);
            case 'profit':
                return Number((stats === null || stats === void 0 ? void 0 : stats.stats.profitCards) || 0);
            case 'cards':
                return (stats === null || stats === void 0 ? void 0 : stats.stats.cardsCount) || 0;
            case 'purchases':
                return (stats === null || stats === void 0 ? void 0 : stats.stats.purchasesCountCards) || 0;
            case 'expenses':
                return Number((stats === null || stats === void 0 ? void 0 : stats.stats.expenses) || 0);
            default:
                return 0;
        }
    };
    const dates = useMemo(() => (data === null || data === void 0 ? void 0 : data.labels) || [], [JSON.stringify(data)]);
    const leftAxis = useMemo(() => ({
        data: (data === null || data === void 0 ? void 0 : data.data[0]) || [],
        color: '#80b1da',
        tickColor: '#80b1da',
    }), [JSON.stringify(data)]);
    const rightAxis = useMemo(() => ({
        data: (data === null || data === void 0 ? void 0 : data.data[1]) || [],
        color: '#7277cf',
        tickColor: '#7277cf',
    }), [JSON.stringify(data)]);
    return (_jsx(DependencePanel, { selectedScaleId: scale, onChangeScale: setScale, selectedLeftSourceId: leftSource, onChangeLeftSource: setLeftSource, leftSourceTotal: getDatasetTotal(leftSource), selectedRightSourceId: rightSource, onChangeRightSource: setRightSource, rightSourceTotal: getDatasetTotal(rightSource), dates: dates, leftAxis: leftAxis, rightAxis: rightAxis, currency: currency, isProfitVisible: isProfitVisible, isLoading: isLoadingChart || isLoadingStats }));
};
