import { useGetWalletCardListQuery, } from '@/api/query/wallet-cards';
export const useWalletCardList = () => {
    var _a;
    const { data, isLoading, isFetching, isError } = useGetWalletCardListQuery();
    const walletCards = ((_a = data === null || data === void 0 ? void 0 : data.walletCards) === null || _a === void 0 ? void 0 : _a.map((card) => (Object.assign({ id: card.id, levelId: card.level, status: card.hasChanges ? 'draft' : 'done' }, getInstallationsCount(card.level, data))))) || [];
    const walletGeos = [...((data === null || data === void 0 ? void 0 : data.walletGeos) || [])].sort((a, b) => a.id - b.id);
    const walletGeosNotDeleted = walletGeos === null || walletGeos === void 0 ? void 0 : walletGeos.filter((geoPush) => !geoPush.isDeleted);
    return {
        data,
        walletCards,
        walletGeos,
        walletGeosNotDeleted,
        isLoading,
        isFetching,
        isError,
    };
};
const getInstallationsCount = (levelId, data) => {
    var _a;
    const cardData = (_a = data === null || data === void 0 ? void 0 : data.cardInstallationsCount) === null || _a === void 0 ? void 0 : _a.find((item) => item.level === levelId);
    const ios = cardData === null || cardData === void 0 ? void 0 : cardData.apple_cards;
    const android = cardData === null || cardData === void 0 ? void 0 : cardData.android_cards;
    const total = ios === undefined && android === undefined
        ? undefined
        : (ios || 0) + (android || 0);
    return { ios, android, total };
};
