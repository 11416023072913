import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { RFMLockedAlert } from '@/components/RFMLockedAlert/RFMLockedAlert';
import { BurgerMenu } from '@/icons/BurgerMenu';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { AppHeaderBalance } from './AppHeaderBalance';
import { AppHeaderPlusButton } from './AppHeaderPlusButton';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import styles from './styles.module.scss';
import { useAppHeaderState } from './useAppHeaderState';
export const AppHeader = memo(({ balance, brand, isCollapsed, isHidden, onToggleHidden, onGoToBalance, onAddClient, onAddPurchase, }) => {
    const location = useLocation();
    const isRFMPage = matchPath(ROUTES.RFM, location.pathname);
    const isRFMEnabled = brandHasRFMFeature(brand);
    const { hidden } = useAppHeaderState();
    return (_jsxs(_Fragment, { children: [isRFMPage && !isRFMEnabled && (_jsx("div", Object.assign({ className: classNames(styles.alert, {
                    [styles.collapsed]: isCollapsed,
                    [styles.hidden]: isHidden,
                    [styles['not-visible']]: hidden,
                }) }, { children: _jsx(RFMLockedAlert, {}) }))), _jsxs("header", Object.assign({ id: 'app-header', className: classNames(styles.header, {
                    [styles.collapsed]: isCollapsed,
                    [styles.hidden]: isHidden,
                    [styles['not-visible']]: hidden,
                }) }, { children: [_jsxs("div", Object.assign({ className: styles.left }, { children: [_jsx("button", Object.assign({ className: styles.toggle, onClick: onToggleHidden }, { children: _jsx(BurgerMenu, {}) })), _jsx(Breadcrumbs, {})] })), _jsxs("div", Object.assign({ className: classNames(styles.right, {
                            [styles.rightHide]: !isHidden,
                        }) }, { children: [_jsx(AppHeaderBalance, { balance: brand.balance, currency: brand.currency.unitShort, forecastDays: balance.forecastDays, onGoToBalance: onGoToBalance }), _jsx(AppHeaderPlusButton, { onAddClient: onAddClient, onAddPurchase: onAddPurchase })] }))] }))] }));
});
