import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/Badge/Badge';
export const GiftCardInstanceStatus = memo(({ status }) => {
    const { t } = useTranslation();
    let type = 'default';
    if (status === 'active')
        type = 'success';
    if (status === 'blocked')
        type = 'danger';
    if (status === 'expired' || status === 'burned' || status === 'redeemed')
        type = 'warning';
    return (_jsx(Badge, Object.assign({ type: type, isRounded: false }, { children: t(`gift_status_${status}`) })));
});
