import { api } from './';
export const importApi = api.injectEndpoints({
    endpoints: (build) => ({
        getImportList: build.query({
            query: () => ({
                method: 'GET',
                url: '/import/list',
            }),
            providesTags: ['ImportList'],
        }),
        getImport: build.query({
            query: (id) => ({
                method: 'GET',
                url: `/import/get/${id}`,
            }),
            providesTags: ['Import'],
        }),
        getImportPreview: build.query({
            query: ({ id, sheet }) => ({
                method: 'GET',
                url: `/import/preview/${id}/${sheet}`,
            }),
            providesTags: ['ImportPreview'],
        }),
        loadImportPreview: build.mutation({
            query: ({ id, sheet }) => ({
                method: 'GET',
                url: `/import/preview/${id}/${sheet}`,
            }),
        }),
        deleteImport: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/import/delete/${id}`,
            }),
            invalidatesTags: ['ImportList'],
        }),
        addImport: build.mutation({
            query: ({ file, type, title }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', type.toString());
                formData.append('title', title);
                return {
                    method: 'POST',
                    url: '/import/add',
                    body: formData,
                };
            },
            invalidatesTags: ['ImportList'],
        }),
        saveImport: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: `/import/set/${body.id}`,
                body,
            }),
            invalidatesTags: ['ImportList', 'Import', 'ImportPreview'],
        }),
    }),
});
export const { useGetImportListQuery, useGetImportQuery, useGetImportPreviewQuery, useLoadImportPreviewMutation, useDeleteImportMutation, useAddImportMutation, useSaveImportMutation, } = importApi;
