var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useCopyToClipboard = ({ showToast }) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    return (text) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield navigator.clipboard.writeText(text);
            if (showToast)
                addToast({
                    type: 'success',
                    message: t('Скопировано в буфер обмена'),
                });
        }
        catch (e) {
            // do nothing
        }
    });
};
