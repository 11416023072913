import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const AccordionContent = ({ currency, offersList, }) => {
    const { t } = useTranslation();
    return (_jsx("ul", Object.assign({ className: styles.accordionContent }, { children: offersList.map(({ amount, count, productTitle, bonusesCollected }, index) => {
            const gridTemplateColumns = +amount && bonusesCollected ? '1fr 1fr 90px' : '1fr 90px';
            return (_jsxs("li", Object.assign({ className: styles.accordionContentItem }, { children: [_jsx("div", Object.assign({ className: styles.accordionContentItemTitle }, { children: _jsx("span", { children: productTitle }) })), _jsxs("div", Object.assign({ className: styles.accordionContentItemValues, style: { gridTemplateColumns } }, { children: [_jsx("div", Object.assign({ className: styles.accordionContentCount }, { children: _jsx("span", { children: `${count} ${t('шт')}.` }) })), !!+amount && (_jsx("div", Object.assign({ className: classNames(styles.accordionContentValue, {
                                    [styles.bonusCell]: !currency,
                                }) }, { children: _jsx("span", { children: currency
                                        ? money({
                                            input: String(amount),
                                            withFraction: true,
                                            removeFractionZeros: true,
                                            currency,
                                        })
                                        : amount }) }))), !!bonusesCollected && (_jsx("div", Object.assign({ className: styles.bonusCell }, { children: _jsx("span", { children: `+ ${t('BONUSES_AMOUNT', {
                                        amount: bonusesCollected,
                                        count: bonusesCollected,
                                    })}` }) })))] }))] }), index));
        }) })));
};
