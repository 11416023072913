import { calculatePercent } from '@/utils/calculatePercent';
export const clearMovements = (movements, options = {}) => {
    let result = [...movements];
    if (options.removeUltraflow)
        result = removeUltraflow(result);
    if (options.removeSelfMovements)
        result = removeSelfMovements(result);
    if (options.removeEmptyMovements)
        result = removeEmptyMovements(result);
    if (result.length !== movements.length)
        result = recalculatePercents(result);
    return result;
};
const removeUltraflow = (movements) => movements.filter((m) => m.from !== 'rfm_lost' && m.to !== 'rfm_lost');
const removeSelfMovements = (movements) => movements.filter((m) => m.from !== m.to);
const removeEmptyMovements = (movements) => movements.filter((m) => m.from && m.to);
const recalculatePercents = (movements) => {
    const total = movements.map((m) => m.count).reduce((p, c) => p + c, 0);
    return movements.map((m) => (Object.assign(Object.assign({}, m), { percent: calculatePercent(m.count, total) })));
};
