import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { CheckboxGroup } from '@/components/CheckboxGroup/CheckboxGroup';
import { Modal } from '@/components/Modal/Modal';
import { SortableList } from '@/components/SortableList/SortableList';
import { useDashboardDimensions, } from '@/hooks/useDashboardDimensions';
import styles from './styles.module.scss';
export const AnalyticsTableModal = memo(({ isOpen, onClose, selectedDimensions: defaultValue, selectedMaxCount: defaultMaxCount, onApply, isRFMEnabled, }) => {
    const { t } = useTranslation();
    const dimensions = useDashboardDimensions(isRFMEnabled);
    const [selectedDimensions, setSelectedDimensions] = useState(defaultValue);
    const [maxCount, setMaxCount] = useState(defaultMaxCount.toString());
    useEffect(() => {
        setSelectedDimensions(defaultValue);
    }, [defaultValue]);
    const sortableItems = useMemo(() => {
        return selectedDimensions.map((id) => dimensions.find((i) => i.id === id));
    }, [selectedDimensions]);
    const handleSort = useCallback((sortedItems) => {
        setSelectedDimensions(sortedItems.map(({ id }) => id));
    }, []);
    const handleReset = useCallback(() => setSelectedDimensions([]), []);
    const handleApply = useCallback(() => {
        onApply(selectedDimensions, Number(maxCount));
        if (onClose)
            onClose();
    }, [selectedDimensions, maxCount, onClose]);
    const footer = (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.maxCountSelect }, { children: [_jsx("span", { children: t('Отображать') }), _jsx("div", Object.assign({ className: styles.select }, { children: _jsx(Autocomplete, { value: maxCount, onChange: setMaxCount, options: ['20', '50', '100', '200', '500'], getOptionKey: (option) => option, getOptionLabel: (option) => option, hasResetButton: false, asSelect: true }) })), _jsx("span", { children: t('результатов') })] })), _jsx(Button, Object.assign({ className: styles.resetButton, onClick: handleReset, disabled: !selectedDimensions.length, size: 'large', isOutlined: true }, { children: t `Сбросить` })), _jsx(Button, Object.assign({ onClick: handleApply, size: 'extra-large' }, { children: t `Применить к отчету` }))] }));
    return (_jsx(Modal, Object.assign({ className: styles.modal, title: t('Сгруппировать показатели метрик'), isOpen: isOpen, onClose: onClose, footerClassName: styles.footer, footer: footer, size: '770', hasNoPadding: true }, { children: _jsxs("div", Object.assign({ className: styles.body }, { children: [_jsx("div", Object.assign({ className: styles.checkboxes }, { children: _jsx(CheckboxGroup, { items: dimensions, selectedItems: selectedDimensions, onChange: setSelectedDimensions }) })), _jsx("div", Object.assign({ className: styles.sortable }, { children: sortableItems.length > 0 ? (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.sortableTitle }, { children: t('Вложенность групп') })), _jsx("div", Object.assign({ className: styles.sortableDescription }, { children: t('перетащите строку чтобы поменять вложенность') })), _jsx(SortableList, { items: sortableItems, onChange: handleSort })] })) : (_jsx("div", Object.assign({ className: styles.empty }, { children: t('Выберите критерии группировки слева') }))) }))] })) })));
});
