import { generatePath, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { publishButtonClickedSelector } from '@/store/selectors/wallet';
import { clickPublishButton } from '@/store/slice/wallet';
import { ROUTES } from '../routes';
import { useWalletCardList } from './useWalletCardList';
export const useWalletCardPublishErrors = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const publishButtonClicked = useAppSelector(publishButtonClickedSelector);
    const { data } = useWalletCardList();
    const cards = (data === null || data === void 0 ? void 0 : data.walletCards) || [];
    const errors = [];
    for (let i = 0; i < cards.length; i += 1) {
        const card = cards[i];
        const path = generatePath(ROUTES.WALLET_CARDS_EDIT, {
            levelId: card.level,
            cardId: card.id,
        });
        const handleClick = (type, element) => {
            navigate(path, {
                state: {
                    walletCardActiveType: type,
                    walletCardActiveElement: element,
                },
                preventScrollReset: true,
            });
        };
        if (!((_b = (_a = card.configuration) === null || _a === void 0 ? void 0 : _a.header) === null || _b === void 0 ? void 0 : _b.google.logo))
            errors.push({
                id: 'header.google.logo-' + card.id,
                message: 'логотип Google',
                onClick: () => handleClick('google', 'logo'),
            });
        if (!((_d = (_c = card.configuration) === null || _c === void 0 ? void 0 : _c.header) === null || _d === void 0 ? void 0 : _d.google.title))
            errors.push({
                id: 'header.google.title-' + card.id,
                message: 'заголовок карты Google',
                onClick: () => handleClick('google', 'header-title'),
            });
        if (!((_f = (_e = card.configuration) === null || _e === void 0 ? void 0 : _e.header) === null || _f === void 0 ? void 0 : _f.google.programName))
            errors.push({
                id: 'header.google.programName-' + card.id,
                message: 'название карты Google',
                onClick: () => handleClick('google', 'title'),
            });
        if (!((_h = (_g = card.configuration) === null || _g === void 0 ? void 0 : _g.backside) === null || _h === void 0 ? void 0 : _h.title))
            errors.push({
                id: 'backside.title-' + card.id,
                message: 'заголовок',
                onClick: () => handleClick('apple', 'apple-details-title'),
            });
        if (!((_l = (_k = (_j = card.configuration) === null || _j === void 0 ? void 0 : _j.notification) === null || _k === void 0 ? void 0 : _k.appleLogo) === null || _l === void 0 ? void 0 : _l[1]))
            errors.push({
                id: 'notifications.logo-' + card.id,
                message: 'логотип уведомления',
                onClick: () => handleClick('notify', 'logo'),
            });
        if (!((_p = (_o = (_m = card.configuration) === null || _m === void 0 ? void 0 : _m.notification) === null || _o === void 0 ? void 0 : _o.title) === null || _p === void 0 ? void 0 : _p.trim()))
            errors.push({
                id: 'notifications.title-' + card.id,
                message: 'название бренда',
                onClick: () => handleClick('notify', 'title'),
            });
        if (((_r = (_q = card.configuration) === null || _q === void 0 ? void 0 : _q.banner) === null || _r === void 0 ? void 0 : _r.type) === 'with-counter' &&
            !((_s = card.configuration.banner.counter) === null || _s === void 0 ? void 0 : _s.offerId))
            errors.push({
                id: 'banner.counter-' + card.id,
                message: 'счетчик',
                onClick: () => handleClick('apple', 'banner-counter'),
            });
    }
    const toggleShouldDisplayErrors = () => {
        dispatch(clickPublishButton());
    };
    return {
        errors,
        shouldDisplayErrors: publishButtonClicked && errors.length > 0,
        toggleShouldDisplayErrors,
    };
};
