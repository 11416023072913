import { api } from './';
export const purchasesApi = api.injectEndpoints({
    endpoints: (build) => ({
        searchProductPurchaseOptions: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/product/list',
                body,
            }),
        }),
    }),
});
export const { useSearchProductPurchaseOptionsMutation } = purchasesApi;
