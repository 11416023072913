import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import styles from '../styles.module.scss';
export const FormMailingEmailSender = ({ brand, labelWidth, }) => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const emailSenders = ((_a = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _a === void 0 ? void 0 : _a.emailSenders) || [];
    const shouldDisplaySelect = emailSenders.length > 1;
    const emailFromName = ((_b = emailSenders[0]) === null || _b === void 0 ? void 0 : _b.name) || ((_c = brand === null || brand === void 0 ? void 0 : brand.settings) === null || _c === void 0 ? void 0 : _c.emailFromName) || '';
    const emailFrom = ((_d = emailSenders[0]) === null || _d === void 0 ? void 0 : _d.email) || ((_e = brand === null || brand === void 0 ? void 0 : brand.settings) === null || _e === void 0 ? void 0 : _e.emailFrom) || '';
    const senderSelect = (_jsx(Controller, { name: 'emailSender', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'emailSender', value: emailSenders.find((item) => item.id === field.value) || null, onChange: (sender) => {
                setValue('emailSender', sender === null || sender === void 0 ? void 0 : sender.id, { shouldDirty: true });
            }, options: emailSenders, getOptionKey: (option) => option.id, getOptionLabel: (option) => `${option.name} <${option.email}>`, hasResetButton: false, asSelect: true }))) }));
    const senderStatic = (_jsxs("div", Object.assign({ className: styles.sender }, { children: [emailFromName, " <", emailFrom, ">"] })));
    return (_jsx(FormFieldRow, Object.assign({ label: t('От кого'), labelFor: 'emailSender', labelWidth: labelWidth, controlSize: '450' }, { children: shouldDisplaySelect ? senderSelect : senderStatic })));
};
