import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import styles from './styles.module.scss';
export const WalletCardsPanel = ({ activeTabId = 'cards-table', onChangeActiveTab, geoPushCount = 0, isLoading, isEmpty, children, }) => {
    const { t } = useTranslation();
    const tabs = [
        { id: 'cards-table', label: t('Уровни карт') },
        { id: 'geo-push-table', label: t('Геопуши'), count: geoPushCount },
    ];
    return (_jsx(TabsPanel, Object.assign({ className: styles.panel, bodyClassName: classNames(styles.body, {
            [styles.empty]: isEmpty,
        }), tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: (activeTabId) => onChangeActiveTab === null || onChangeActiveTab === void 0 ? void 0 : onChangeActiveTab(activeTabId), isContentSkeleton: isLoading }, { children: children })));
};
