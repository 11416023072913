import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { TRIGGER_TYPE_PRICE_IN_CART_DROPPED, TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED, } from '@/const/mailing-triggers';
import { Cart } from '@/icons/header/Cart';
import { Star } from '@/icons/Star';
import styles from '../styles.module.scss';
export const FormMailingEmailDroppedPrice = () => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const triggerType = watch('triggerType');
    if (triggerType !== TRIGGER_TYPE_PRICE_IN_CART_DROPPED &&
        triggerType !== TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED)
        return null;
    const icon = triggerType === TRIGGER_TYPE_PRICE_IN_CART_DROPPED ? (_jsx(Cart, { className: styles.icon })) : (_jsx(Star, { className: classNames(styles.icon, styles.start) }));
    const text = triggerType === TRIGGER_TYPE_PRICE_IN_CART_DROPPED
        ? t('Блок товаров со сниженной ценой в корзине')
        : t('Блок товаров со сниженной ценой в избранном');
    return (_jsx("div", Object.assign({ className: styles.emailBlockPanel }, { children: _jsxs(Checkbox, Object.assign({ align: 'center', checked: true, disabled: true }, { children: [icon, text, _jsx(HelpTooltip, { className: styles.emailBlockTooltip, title: t('EMAIL_INCLUDE_DROPPED_PRICE_ITEMS'), placement: 'right' })] })) })));
};
