import { useState } from 'react';
import { useRFMShopFilters } from '@/services/filters/data/useRFMShopFilters';
import { addToLS, getFromLS } from '@/utils/LS';
export const useFilters = () => {
    const filtersFromLS = getFromLS({ key: 'RFMFilters' });
    const [filters, setFilters] = useState(filtersFromLS || []);
    const saveFilters = (newFilters) => {
        addToLS({ key: 'RFMFilters', value: newFilters });
        setFilters(newFilters);
    };
    const filtersTree = useRFMShopFilters();
    return { filtersTree, filters, saveFilters };
};
