const find = (item, predicate, result) => {
    if (item.type === 'leaf' && predicate(item))
        result.push(item);
    if (item.type === 'branch')
        for (let i = 0; i < item.items.length; i += 1)
            find(item.items[i], predicate, result);
};
export const findLeaves = (tree, predicate) => {
    const result = [];
    for (let i = 0; i < tree.length; i += 1)
        find(tree[i], predicate, result);
    return result;
};
