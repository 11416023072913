import { t } from 'i18next';
import styles from '../styles.module.scss';
export const createHeadItems = () => [
    {
        id: 'number',
        title: '№',
        align: 'center',
        width: 27,
    },
    {
        id: 'name',
        title: t('Наименование'),
        width: 250,
        align: 'left',
    },
    {
        id: 'price-before-discount',
        title: t('Цена до скидки'),
        width: 126,
        className: styles.priceBeforeDiscountCellHead,
    },
    {
        id: 'empty2',
        title: '',
        width: 21,
        align: 'center',
    },
    {
        id: 'quantity',
        title: t('Кол-во'),
        width: 65,
        className: styles.countCellHead,
        align: 'left',
    },
    {
        id: 'discount',
        title: t('Скидка'),
        width: 98,
        className: styles.cellHeadPadRight24,
    },
    {
        id: 'price',
        title: t('Стоимость'),
        width: 107,
        className: styles.priceCellHead,
    },
    {
        id: 'bonus',
        title: `+ ${t('Бонусы')}`,
        width: 103,
        className: styles.cellHeadPadRight24,
    },
];
