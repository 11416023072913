export const COUNTRY = {
    phoneFormat: {
        af: { prefix: '93', lengths: [9], trunkPrefixes: ['0'] },
        ax: { prefix: '358', lengths: [6, 7, 8], trunkPrefixes: [] },
        al: { prefix: '355', lengths: [9], trunkPrefixes: ['0'] },
        dz: { prefix: '213', lengths: [9], trunkPrefixes: [] },
        as: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ad: { prefix: '376', lengths: [6], trunkPrefixes: [] },
        ao: { prefix: '244', lengths: [9], trunkPrefixes: [] },
        ai: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ag: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ar: { prefix: '54', lengths: [10], trunkPrefixes: ['0'] },
        am: { prefix: '374', lengths: [8], trunkPrefixes: [] },
        aw: { prefix: '297', lengths: [7], trunkPrefixes: [] },
        au: { prefix: '61', lengths: [9], trunkPrefixes: ['0'] },
        at: { prefix: '43', lengths: [10, 11, 12, 13, 14], trunkPrefixes: ['0'] },
        az: { prefix: '994', lengths: [9], trunkPrefixes: ['8'] },
        bs: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        bh: { prefix: '973', lengths: [8], trunkPrefixes: [] },
        bd: { prefix: '880', lengths: [8, 9, 10], trunkPrefixes: ['0'] },
        bb: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        by: { prefix: '375', lengths: [9], trunkPrefixes: ['8', '80'] },
        be: { prefix: '32', lengths: [9, 8], trunkPrefixes: ['0'] },
        bz: { prefix: '501', lengths: [7], trunkPrefixes: [] },
        bj: { prefix: '229', lengths: [8], trunkPrefixes: [] },
        bm: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        bt: { prefix: '975', lengths: [8], trunkPrefixes: [] },
        bo: { prefix: '591', lengths: [8], trunkPrefixes: [] },
        ba: { prefix: '387', lengths: [8], trunkPrefixes: ['0'] },
        bw: { prefix: '267', lengths: [8], trunkPrefixes: [] },
        br: { prefix: '55', lengths: [10, 11], trunkPrefixes: ['0'] },
        bn: { prefix: '673', lengths: [7], trunkPrefixes: [] },
        bg: { prefix: '359', lengths: [8, 9], trunkPrefixes: ['0'] },
        bf: { prefix: '226', lengths: [8], trunkPrefixes: [] },
        bi: { prefix: '257', lengths: [8], trunkPrefixes: [] },
        kh: { prefix: '855', lengths: [8, 9], trunkPrefixes: ['0'] },
        cm: { prefix: '237', lengths: [9], trunkPrefixes: [] },
        ca: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        cv: { prefix: '238', lengths: [7], trunkPrefixes: [] },
        ky: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        cf: { prefix: '236', lengths: [8], trunkPrefixes: [] },
        td: { prefix: '235', lengths: [8], trunkPrefixes: [] },
        cl: { prefix: '56', lengths: [9], trunkPrefixes: [] },
        cn: { prefix: '86', lengths: [11], trunkPrefixes: ['0'] },
        co: { prefix: '57', lengths: [10], trunkPrefixes: [] },
        km: { prefix: '269', lengths: [7], trunkPrefixes: [] },
        cg: { prefix: '242', lengths: [9], trunkPrefixes: [] },
        cd: { prefix: '243', lengths: [9], trunkPrefixes: [] },
        ck: { prefix: '682', lengths: [5], trunkPrefixes: [] },
        cr: { prefix: '506', lengths: [8], trunkPrefixes: [] },
        ci: { prefix: '225', lengths: [8], trunkPrefixes: [] },
        hr: { prefix: '385', lengths: [8, 9], trunkPrefixes: ['0'] },
        cu: { prefix: '53', lengths: [8], trunkPrefixes: [] },
        cy: { prefix: '357', lengths: [8], trunkPrefixes: ['0'] },
        cz: { prefix: '420', lengths: [9], trunkPrefixes: [] },
        dk: { prefix: '45', lengths: [8], trunkPrefixes: [] },
        dj: { prefix: '253', lengths: [8], trunkPrefixes: [] },
        dm: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        do: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ec: { prefix: '593', lengths: [9], trunkPrefixes: [] },
        eg: { prefix: '20', lengths: [10, 8], trunkPrefixes: ['0'] },
        sv: { prefix: '503', lengths: [8], trunkPrefixes: [] },
        gq: { prefix: '240', lengths: [9], trunkPrefixes: [] },
        er: { prefix: '291', lengths: [7], trunkPrefixes: [] },
        ee: { prefix: '372', lengths: [7, 8], trunkPrefixes: [] },
        et: { prefix: '251', lengths: [9], trunkPrefixes: [] },
        fk: { prefix: '500', lengths: [5], trunkPrefixes: [] },
        fo: { prefix: '298', lengths: [6], trunkPrefixes: [] },
        fj: { prefix: '679', lengths: [7], trunkPrefixes: [] },
        fi: { prefix: '358', lengths: [9, 10], trunkPrefixes: ['0'] },
        fr: { prefix: '33', lengths: [9], trunkPrefixes: ['0'] },
        gf: { prefix: '594', lengths: [9], trunkPrefixes: [] },
        pf: { prefix: '689', lengths: [8], trunkPrefixes: [] },
        ga: { prefix: '241', lengths: [8], trunkPrefixes: [] },
        gm: { prefix: '220', lengths: [7], trunkPrefixes: [] },
        ge: { prefix: '995', lengths: [9], trunkPrefixes: ['0'] },
        de: { prefix: '49', lengths: [10, 11], trunkPrefixes: ['0'] },
        gh: { prefix: '233', lengths: [9], trunkPrefixes: [] },
        gi: { prefix: '350', lengths: [8], trunkPrefixes: [] },
        gr: { prefix: '30', lengths: [10], trunkPrefixes: [] },
        gl: { prefix: '299', lengths: [6], trunkPrefixes: [] },
        gd: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        gp: { prefix: '590', lengths: [9], trunkPrefixes: [] },
        gu: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        gt: { prefix: '502', lengths: [8], trunkPrefixes: [] },
        gn: { prefix: '224', lengths: [9], trunkPrefixes: [] },
        gw: { prefix: '245', lengths: [7], trunkPrefixes: [] },
        gy: { prefix: '592', lengths: [7], trunkPrefixes: [] },
        ht: { prefix: '509', lengths: [8], trunkPrefixes: [] },
        hn: { prefix: '504', lengths: [8], trunkPrefixes: [] },
        hk: { prefix: '852', lengths: [8], trunkPrefixes: [] },
        hu: { prefix: '36', lengths: [9], trunkPrefixes: ['06'] },
        is: { prefix: '354', lengths: [7], trunkPrefixes: [] },
        in: { prefix: '91', lengths: [10], trunkPrefixes: ['0'] },
        id: { prefix: '62', lengths: [9, 10, 11, 12], trunkPrefixes: ['0'] },
        ir: { prefix: '98', lengths: [10], trunkPrefixes: ['0'] },
        iq: { prefix: '964', lengths: [10], trunkPrefixes: [] },
        ie: { prefix: '353', lengths: [9], trunkPrefixes: ['0'] },
        il: { prefix: '972', lengths: [9], trunkPrefixes: ['0'] },
        it: { prefix: '39', lengths: [9, 10], trunkPrefixes: [] },
        jm: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        jp: { prefix: '81', lengths: [10], trunkPrefixes: ['0'] },
        jo: { prefix: '962', lengths: [9], trunkPrefixes: ['0'] },
        kz: { prefix: '7', lengths: [10], trunkPrefixes: ['8'] },
        ke: { prefix: '254', lengths: [9], trunkPrefixes: ['0'] },
        ki: { prefix: '686', lengths: [5], trunkPrefixes: [] },
        kr: { prefix: '82', lengths: [9, 10], trunkPrefixes: ['0'] },
        kw: { prefix: '965', lengths: [8], trunkPrefixes: [] },
        kg: { prefix: '996', lengths: [9], trunkPrefixes: [] },
        la: { prefix: '856', lengths: [10], trunkPrefixes: ['0'] },
        lv: { prefix: '371', lengths: [8], trunkPrefixes: ['8'] },
        lb: { prefix: '961', lengths: [7, 8], trunkPrefixes: [] },
        ls: { prefix: '266', lengths: [8], trunkPrefixes: [] },
        lr: { prefix: '231', lengths: [7, 8], trunkPrefixes: [] },
        ly: { prefix: '218', lengths: [9], trunkPrefixes: [] },
        li: { prefix: '423', lengths: [7], trunkPrefixes: [] },
        lt: { prefix: '370', lengths: [8], trunkPrefixes: ['8', '0'] },
        lu: { prefix: '352', lengths: [9], trunkPrefixes: [] },
        mo: { prefix: '853', lengths: [8], trunkPrefixes: [] },
        mk: { prefix: '389', lengths: [8], trunkPrefixes: ['0'] },
        mg: { prefix: '261', lengths: [9], trunkPrefixes: [] },
        mw: { prefix: '265', lengths: [9], trunkPrefixes: [] },
        my: { prefix: '60', lengths: [9, 10, 8], trunkPrefixes: ['0'] },
        mv: { prefix: '960', lengths: [7], trunkPrefixes: [] },
        ml: { prefix: '223', lengths: [8], trunkPrefixes: [] },
        mt: { prefix: '356', lengths: [8], trunkPrefixes: [] },
        mh: { prefix: '692', lengths: [7], trunkPrefixes: [] },
        mq: { prefix: '596', lengths: [9], trunkPrefixes: [] },
        mr: { prefix: '222', lengths: [8], trunkPrefixes: [] },
        mu: { prefix: '230', lengths: [8], trunkPrefixes: [] },
        yt: { prefix: '262', lengths: [9], trunkPrefixes: [] },
        mx: { prefix: '52', lengths: [10, 11], trunkPrefixes: ['01'] },
        fm: { prefix: '691', lengths: [7], trunkPrefixes: [] },
        md: { prefix: '373', lengths: [8], trunkPrefixes: ['0'] },
        mc: { prefix: '377', lengths: [8, 9], trunkPrefixes: [] },
        mn: { prefix: '976', lengths: [8], trunkPrefixes: ['01', '02'] },
        me: { prefix: '382', lengths: [8], trunkPrefixes: ['0'] },
        ms: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ma: { prefix: '212', lengths: [9], trunkPrefixes: ['0'] },
        mz: { prefix: '258', lengths: [9], trunkPrefixes: [] },
        mm: { prefix: '95', lengths: [8, 9, 10], trunkPrefixes: ['0'] },
        na: { prefix: '264', lengths: [9], trunkPrefixes: [] },
        nr: { prefix: '674', lengths: [7], trunkPrefixes: [] },
        np: { prefix: '977', lengths: [10], trunkPrefixes: ['0'] },
        nl: { prefix: '31', lengths: [9], trunkPrefixes: ['0'] },
        nc: { prefix: '687', lengths: [6], trunkPrefixes: [] },
        nz: { prefix: '64', lengths: [8, 9, 10], trunkPrefixes: ['0'] },
        ni: { prefix: '505', lengths: [8], trunkPrefixes: [] },
        ne: { prefix: '227', lengths: [8], trunkPrefixes: [] },
        ng: { prefix: '234', lengths: [10], trunkPrefixes: ['0'] },
        nu: { prefix: '683', lengths: [4], trunkPrefixes: [] },
        nf: { prefix: '672', lengths: [5], trunkPrefixes: [] },
        mp: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        no: { prefix: '47', lengths: [8], trunkPrefixes: [] },
        om: { prefix: '968', lengths: [8], trunkPrefixes: [] },
        pk: { prefix: '92', lengths: [10], trunkPrefixes: ['0'] },
        pw: { prefix: '680', lengths: [7], trunkPrefixes: [] },
        ps: { prefix: '970', lengths: [9], trunkPrefixes: [] },
        pa: { prefix: '507', lengths: [8], trunkPrefixes: [] },
        pg: { prefix: '675', lengths: [8], trunkPrefixes: [] },
        py: { prefix: '595', lengths: [9], trunkPrefixes: [] },
        pe: { prefix: '51', lengths: [9, 8], trunkPrefixes: ['0'] },
        ph: { prefix: '63', lengths: [10], trunkPrefixes: ['0'] },
        pl: { prefix: '48', lengths: [9], trunkPrefixes: [] },
        pt: { prefix: '351', lengths: [9], trunkPrefixes: [] },
        pr: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        qa: { prefix: '974', lengths: [8], trunkPrefixes: [] },
        re: { prefix: '262', lengths: [9], trunkPrefixes: [] },
        ro: { prefix: '40', lengths: [9], trunkPrefixes: ['0'] },
        ru: { prefix: '7', lengths: [10], trunkPrefixes: ['8'] },
        rw: { prefix: '250', lengths: [9], trunkPrefixes: ['0'] },
        sh: { prefix: '290', lengths: [4], trunkPrefixes: [] },
        kn: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        lc: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        pm: { prefix: '508', lengths: [6], trunkPrefixes: [] },
        vc: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        ws: { prefix: '685', lengths: [7], trunkPrefixes: [] },
        sm: { prefix: '378', lengths: [10], trunkPrefixes: [] },
        st: { prefix: '239', lengths: [7], trunkPrefixes: [] },
        sa: { prefix: '966', lengths: [9], trunkPrefixes: ['0'] },
        sn: { prefix: '221', lengths: [9], trunkPrefixes: [] },
        rs: { prefix: '381', lengths: [8, 9], trunkPrefixes: ['0'] },
        sc: { prefix: '248', lengths: [7], trunkPrefixes: [] },
        sl: { prefix: '232', lengths: [8], trunkPrefixes: [] },
        sg: { prefix: '65', lengths: [8], trunkPrefixes: [] },
        sx: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        sk: { prefix: '421', lengths: [9], trunkPrefixes: ['0'] },
        si: { prefix: '386', lengths: [8], trunkPrefixes: ['0'] },
        sb: { prefix: '677', lengths: [7], trunkPrefixes: [] },
        so: { prefix: '252', lengths: [8], trunkPrefixes: [] },
        za: { prefix: '27', lengths: [9], trunkPrefixes: ['0'] },
        ss: { prefix: '211', lengths: [9], trunkPrefixes: [] },
        es: { prefix: '34', lengths: [9], trunkPrefixes: [] },
        lk: { prefix: '94', lengths: [9], trunkPrefixes: ['0'] },
        sd: { prefix: '249', lengths: [9], trunkPrefixes: ['0'] },
        sr: { prefix: '597', lengths: [7], trunkPrefixes: [] },
        sj: { prefix: '47', lengths: [8], trunkPrefixes: [] },
        se: { prefix: '46', lengths: [9], trunkPrefixes: ['0'] },
        ch: { prefix: '41', lengths: [9], trunkPrefixes: ['0'] },
        sy: { prefix: '963', lengths: [9], trunkPrefixes: [] },
        tw: { prefix: '886', lengths: [9], trunkPrefixes: ['0'] },
        tj: { prefix: '992', lengths: [9], trunkPrefixes: ['8'] },
        tz: { prefix: '255', lengths: [9], trunkPrefixes: ['0'] },
        th: { prefix: '66', lengths: [9], trunkPrefixes: ['0'] },
        tl: { prefix: '670', lengths: [8], trunkPrefixes: [] },
        tg: { prefix: '228', lengths: [8], trunkPrefixes: [] },
        tk: { prefix: '690', lengths: [4], trunkPrefixes: [] },
        to: { prefix: '676', lengths: [5], trunkPrefixes: [] },
        tt: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        tn: { prefix: '216', lengths: [8], trunkPrefixes: [] },
        tr: { prefix: '90', lengths: [10], trunkPrefixes: ['0'] },
        tm: { prefix: '993', lengths: [8], trunkPrefixes: ['8'] },
        tc: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        tv: { prefix: '688', lengths: [5], trunkPrefixes: [] },
        ug: { prefix: '256', lengths: [9], trunkPrefixes: [] },
        ua: { prefix: '380', lengths: [9], trunkPrefixes: ['0'] },
        ae: { prefix: '971', lengths: [9], trunkPrefixes: ['0'] },
        gb: { prefix: '44', lengths: [10], trunkPrefixes: ['0'] },
        us: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        uy: { prefix: '598', lengths: [8], trunkPrefixes: [] },
        uz: { prefix: '998', lengths: [9], trunkPrefixes: ['8'] },
        vu: { prefix: '678', lengths: [7], trunkPrefixes: [] },
        ve: { prefix: '58', lengths: [10], trunkPrefixes: ['0'] },
        vn: { prefix: '84', lengths: [8, 9], trunkPrefixes: ['0'] },
        vg: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        vi: { prefix: '1', lengths: [10], trunkPrefixes: ['1'] },
        wf: { prefix: '681', lengths: [6], trunkPrefixes: [] },
        ye: { prefix: '967', lengths: [9], trunkPrefixes: [] },
        zm: { prefix: '260', lengths: [9], trunkPrefixes: [] },
        zw: { prefix: '263', lengths: [9], trunkPrefixes: ['0'] },
    },
    overridePrefixes: {
        '1': 'us',
        '79': 'ru',
        '77': 'kz',
        '47': 'no',
        '358': 'fi',
    },
};
