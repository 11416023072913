import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WarningCircle } from '@/icons/WarningCircle';
import styles from './styles.module.scss';
export const GiftCardWarning = ({ rewards, text, }) => {
    const { t } = useTranslation();
    const giftCardReward = rewards === null || rewards === void 0 ? void 0 : rewards.find((r) => r.type === 'gift-card');
    const giftCardPH = '!' + t('КодПодарочнойКарты');
    if (giftCardReward && (text === null || text === void 0 ? void 0 : text.indexOf(giftCardPH)) === -1)
        return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.giftCard) }, { children: [_jsx("div", Object.assign({ className: styles.icon }, { children: _jsx(WarningCircle, {}) })), _jsxs("div", Object.assign({ className: styles.text }, { children: [t('Не забудьте вставить параметр'), ' ', _jsx("span", Object.assign({ className: styles.bold }, { children: t('код подарочной карты') }))] }))] })));
    return null;
};
