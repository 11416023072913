import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_LOW, INSERT_PARAGRAPH_COMMAND, KEY_ENTER_COMMAND, } from 'lexical';
import { useEffect } from 'react';
import insertParagraph from './insertParagraph';
import insertParagraphOnEnter from './insertParagraphOnEnter';
import styles from './styles.module.scss';
export const ParagraphPlugin = ({ isEnabled = true, }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (isEnabled)
            return editor.registerCommand(KEY_ENTER_COMMAND, insertParagraphOnEnter, COMMAND_PRIORITY_LOW);
    }, [editor, isEnabled]);
    useEffect(() => {
        return editor.registerCommand(INSERT_PARAGRAPH_COMMAND, insertParagraph, COMMAND_PRIORITY_LOW);
    }, [editor]);
    return null;
};
export { styles as paragraphPluginStyles };
