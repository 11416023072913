import Cropper from 'cropperjs';
import { useEffect, useRef } from 'react';
export const useCropper = (options) => {
    const imageRef = useRef(null);
    useEffect(() => {
        if (!imageRef.current || imageRef.current.dataset.initialized)
            return;
        const element = imageRef.current;
        element.dataset.initialized = 'true';
        const cropper = getCropper(options)(element, options);
        return () => {
            cropper.destroy();
            element.dataset.initialized = '';
        };
    }, []);
    return { imageRef };
};
// -----------------------------------------------------------------------------
const getCropper = (options) => {
    if (options.shape)
        return createShapeCropper;
    if (typeof options.w === 'number')
        return createFixedCropper;
    return createDynamicCropper;
};
// -----------------------------------------------------------------------------
const defaultCropperOptions = {
    viewMode: 1,
    guides: false,
    center: false,
    highlight: false,
    background: false,
    movable: false,
    rotatable: false,
    scalable: false,
    zoomable: false,
    zoomOnTouch: false,
    zoomOnWheel: false,
    toggleDragModeOnDblclick: false,
};
const createShapeCropper = (element, options) => {
    const { isRound, onCrop, shape, mimeType } = options;
    const MIN_SIZE = 50;
    const cropper = new Cropper(element, Object.assign(Object.assign({}, defaultCropperOptions), { initialAspectRatio: shape === 'square' ? 1 : undefined, aspectRatio: shape === 'square' ? 1 : undefined, autoCropArea: 1, minCropBoxHeight: MIN_SIZE, minCropBoxWidth: MIN_SIZE, crop(e) {
            const w = Math.round(e.detail.width);
            const h = Math.round(e.detail.height);
            const x = e.detail.x;
            const y = e.detail.y;
            const cropData = {
                width: w,
                height: h,
                x,
                y,
            };
            onCrop({
                getPreviewURL: () => {
                    return getPreviewURL(element, cropData, w, h, isRound);
                },
                getPreviewFile: (fileName) => {
                    return getPreviewFile(element, cropData, w, h, fileName, isRound, mimeType);
                },
                getPreviewSize: () => ({ width: w, height: h }),
                drawPreviewOnCanvas: (canvasID) => {
                    drawPreviewOnCanvas(element, cropData, w, h, canvasID, isRound);
                },
            });
        } }));
    return cropper;
};
const createFixedCropper = (element, options) => {
    const { w: providedWidth, h, isRound, onCrop, mimeType } = options;
    const w = providedWidth;
    let lastX = undefined;
    let lastY = undefined;
    const cropper = new Cropper(element, Object.assign(Object.assign({}, defaultCropperOptions), { aspectRatio: w / h, crop(e) {
            let changed = false;
            let width = Math.round(e.detail.width);
            let height = Math.round(e.detail.height);
            if (width < w) {
                changed = true;
                width = w;
            }
            if (height < h) {
                changed = true;
                height = h;
            }
            if (changed)
                cropper.setData({ x: lastX, y: lastY, width, height });
            else {
                lastX = e.detail.x;
                lastY = e.detail.y;
            }
            const cropData = {
                x: lastX === undefined ? e.detail.x : lastX,
                y: lastY === undefined ? e.detail.y : lastY,
                width,
                height,
            };
            onCrop({
                getPreviewURL: () => {
                    return getPreviewURL(element, cropData, w, h, isRound);
                },
                getPreviewFile: (fileName) => {
                    return getPreviewFile(element, cropData, w, h, fileName, isRound, mimeType);
                },
                getPreviewSize: () => ({ width: w, height: h }),
                drawPreviewOnCanvas: (canvasID) => {
                    drawPreviewOnCanvas(element, cropData, w, h, canvasID, isRound);
                },
            });
        } }));
    return cropper;
};
const createDynamicCropper = (element, options) => {
    const { w: providedWidth, h, isRound, onCrop, mimeType } = options;
    const [minW, maxW] = providedWidth;
    const minAspectRatio = minW / h;
    const maxAspectRatio = maxW / h;
    let lastX = undefined;
    let lastY = undefined;
    const cropper = new Cropper(element, Object.assign(Object.assign({}, defaultCropperOptions), { ready() {
            const containerData = cropper.getContainerData();
            const cropBoxData = cropper.getCropBoxData();
            const aspectRatio = cropBoxData.width / cropBoxData.height;
            if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
                const newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);
                cropper.setCropBoxData({
                    left: (containerData.width - newCropBoxWidth) / 2,
                    width: newCropBoxWidth,
                });
            }
        },
        crop(e) {
            let changed = false;
            let width = e.detail.width;
            let height = e.detail.height;
            if (width < minW) {
                width = minW;
                changed = true;
            }
            if (height < h) {
                height = h;
                changed = true;
            }
            let aspectRatio = width / height;
            if (aspectRatio < minAspectRatio) {
                changed = true;
                width = height * minAspectRatio;
                aspectRatio = width / height;
            }
            else if (aspectRatio > maxAspectRatio) {
                changed = true;
                width = height * maxAspectRatio;
                aspectRatio = width / height;
            }
            if (changed)
                cropper.setData({ x: lastX, y: lastY, width, height });
            else {
                lastX = e.detail.x;
                lastY = e.detail.y;
            }
            const cropData = {
                x: lastX === undefined ? e.detail.x : lastX,
                y: lastY === undefined ? e.detail.y : lastY,
                width,
                height,
            };
            onCrop({
                getPreviewURL: () => {
                    return getPreviewURL(element, cropData, h * aspectRatio, h, isRound);
                },
                getPreviewFile: (fileName) => {
                    return getPreviewFile(element, cropData, h * aspectRatio, h, fileName, isRound, mimeType);
                },
                getPreviewSize: () => ({ width: h * aspectRatio, height: h }),
                drawPreviewOnCanvas: (canvasID) => {
                    drawPreviewOnCanvas(element, cropData, h * aspectRatio, h, canvasID, isRound);
                },
            });
        } }));
    return cropper;
};
const renderImageOnCanvas = (image, data, cropWidth, cropHeight, canvas, isRound) => {
    const { x, y, width, height } = data;
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, x, y, width, height, 0, 0, cropWidth, cropHeight);
    if (isRound && ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.globalCompositeOperation = 'destination-in';
        ctx.beginPath();
        ctx.arc(cropWidth / 2, cropHeight / 2, Math.min(cropWidth, cropHeight) / 2, 0, 2 * Math.PI, true);
        ctx.fill();
    }
};
const getPreviewURL = (image, data, cropWidth, cropHeight, isRound) => {
    const canvas = document.createElement('canvas');
    renderImageOnCanvas(image, data, cropWidth, cropHeight, canvas, isRound);
    const url = canvas.toDataURL();
    return url;
};
const drawPreviewOnCanvas = (image, data, cropWidth, cropHeight, canvasID, isRound) => {
    const canvas = document.getElementById(canvasID);
    if (canvas)
        renderImageOnCanvas(image, data, cropWidth, cropHeight, canvas, isRound);
};
const getPreviewFile = (image, data, cropWidth, cropHeight, fileName, isRound, mimeType) => new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    renderImageOnCanvas(image, data, cropWidth, cropHeight, canvas, isRound);
    canvas.toBlob((blob) => {
        if (blob) {
            const ext = (mimeType === null || mimeType === void 0 ? void 0 : mimeType.replace('image/', '')) || 'png';
            const name = fileName || `image.${ext}`;
            const file = new File([blob], name);
            resolve(file);
        }
        else
            reject();
    }, mimeType);
});
