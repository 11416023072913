import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { ClientAddModal } from '@/components/ClientModal/ClientAddModal';
import { FormPurchaseModalContainer } from '@/forms/FormPurchase/FormPurchaseModalContainer';
import { useCurrentUser } from '../../useCurrentUser';
import { useSidebarState } from '../AppSidebar/useSidebarState';
import { AppHeader } from './AppHeader';
import { useClientForm } from './useClientForm';
import { usePurchaseForm } from './usePurchaseForm';
export const AppHeaderContainer = memo(() => {
    const navigate = useNavigate();
    const { user, brand } = useCurrentUser();
    const { toggleHiddenSidebar, isCollapsed, isHidden } = useSidebarState();
    const { clientFormOpen, openClientForm, closeClientForm } = useClientForm();
    const { purchaseFormOpen, openPurchaseForm, closePurchaseForm } = usePurchaseForm();
    if (!brand || !user)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(AppHeader, { balance: brand.balance, brand: brand.brand, isCollapsed: isCollapsed, isHidden: isHidden, onToggleHidden: toggleHiddenSidebar, onGoToBalance: () => navigate(ROUTES.BILLING), onAddClient: openClientForm, onAddPurchase: openPurchaseForm }), _jsx(ClientAddModal, { isOpen: clientFormOpen, onClose: closeClientForm, countryCode: brand.brand.defaultCountry, extraFields: brand.brand.clientExtraFields, isChildrenEnabled: brand.brand.features.children, onAdded: closeClientForm }), _jsx(FormPurchaseModalContainer, { isOpen: purchaseFormOpen, onClose: closePurchaseForm, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort })] }));
});
