import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { useEffect, useState } from 'react';
export const Timer = ({ className, expiredAt, onExpire, }) => {
    const now = moment().utc();
    const end = moment(expiredAt).utc();
    const [duration, setDuration] = useState(moment.duration(end.diff(now)));
    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment().utc();
            if (now.isBefore(end))
                setDuration(moment.duration(end.diff(now)));
            else {
                onExpire === null || onExpire === void 0 ? void 0 : onExpire();
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [expiredAt]);
    const minutes = duration.minutes();
    const m = minutes <= 9 ? `0${minutes}` : minutes;
    const seconds = duration.seconds();
    const s = seconds <= 9 ? `0${seconds}` : seconds;
    return _jsx("span", Object.assign({ className: className }, { children: `${m}:${s}` }));
};
