import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxSearchList, } from '@/components/CheckboxSearchList/CheckboxSearchList';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import { Tabs } from '@/components/Tabs2/Tabs';
import styles from './styles.module.scss';
export const SegmentFilterInput = memo(({ segments, includeIds, onChangeIncludeIds, excludeIds = [], onChangeExcludeIds, hasSearch, hasTabs = true, }) => {
    const { t } = useTranslation();
    const [activeTabId, setActiveTabId] = useState('include');
    const [includeSearch, setIncludeSearch] = useState('');
    const [excludeSearch, setExcludeSearch] = useState('');
    const tabs = [
        {
            id: 'include',
            label: t('Входит'),
            count: includeIds.length,
        },
        {
            id: 'exclude',
            label: t('Не входит'),
            count: excludeIds.length,
        },
    ];
    const checkboxes = segments.map((segment) => ({
        id: segment.id,
        label: segment.label,
        view: (_jsx(SegmentBadgeContainer, Object.assign({ segment: segment, placement: 'right' }, { children: segment.label }))),
    }));
    return (_jsxs("div", { children: [hasTabs && (_jsx("div", Object.assign({ className: styles.tabs }, { children: _jsx(Tabs, { tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: setActiveTabId }) }))), activeTabId === 'include' && (_jsx(CheckboxSearchList, { items: checkboxes, selectedItemIds: includeIds, disabledItemIds: excludeIds, onChange: onChangeIncludeIds, search: includeSearch, onChangeSearch: setIncludeSearch, searchDebounceDelay: 0, hasSearch: hasSearch })), activeTabId === 'exclude' && onChangeExcludeIds && (_jsx(CheckboxSearchList, { items: checkboxes, selectedItemIds: excludeIds, disabledItemIds: includeIds, onChange: onChangeExcludeIds, search: excludeSearch, onChangeSearch: setExcludeSearch, searchDebounceDelay: 0, hasSearch: hasSearch }))] }));
});
