import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Done } from '@/icons/status/Done';
import { Draft } from '@/icons/status/Draft';
import styles from './styles.module.scss';
export const WalletCardsTableLevelName = ({ name, status }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.levelName, {
            [styles.active]: status === 'draft' || status === 'done',
        }) }, { children: [status === 'done' && _jsx(Done, { type: 'single' }), status === 'draft' && _jsx(Draft, {}), status === 'empty' && _jsx("div", { className: styles.emptyStatus }), name] })));
};
