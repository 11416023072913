import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const ClientBonusPanel = memo(({ className, bonuses, type, isMuted = false }) => {
    const { t } = useTranslation();
    let title;
    switch (type) {
        case 'account': {
            title = t('Бонусов на счету');
            break;
        }
        case 'pending': {
            title = t('Подтверждается');
            break;
        }
        case 'applied': {
            title = t('Использовано');
            break;
        }
        case 'expired': {
            title = t('Сгорело');
            break;
        }
    }
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.muted]: isMuted,
        }, className) }, { children: _jsxs("div", { children: [_jsx("h4", Object.assign({ className: styles.title }, { children: title })), _jsx("div", Object.assign({ className: classNames(styles.bonuses, styles[type]) }, { children: numberRound(bonuses) }))] }) })));
});
