import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Close } from '@/icons/Close';
import { useConditionsProductFilters } from '@/services/filters/data/useConditionsProductFilters';
import styles from '../styles.module.scss';
export const FormOfferConditionsProduct = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const { fields, append, update, remove } = useFieldArray({
        control,
        name: 'conditionsProduct',
    });
    return (_jsxs(_Fragment, { children: [_jsx(FormFieldRow, Object.assign({ className: classNames(styles.conditionsRow, {
                    [styles.smallMargin]: !!fields.length,
                }), label: t('В чеке есть'), labelWidth: labelWidth }, { children: !fields.length ? (_jsx(AddItemInput, { onAdd: append })) : (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: styles.allProductsLabel }, { children: [t('все указанные товары'), ":"] })), fields.map((item, index) => (_jsx(ChangeItemInput, { item: item, index: index, onRemove: remove, onUpdate: update }, item.id)))] })) })), fields.length > 0 && (_jsx(FormFieldRow, Object.assign({ className: styles.conditionsRow, label: t('Добавить товар'), labelWidth: labelWidth }, { children: _jsx(AddItemInput, { onAdd: append }) })))] }));
};
const AddItemInput = ({ onAdd }) => {
    const filtersTree = useConditionsProductFilters();
    return (_jsx(FiltersInput, { filtersTree: filtersTree, value: [], onUpdate: (filters) => onAdd({ filters }) }));
};
const ChangeItemInput = ({ item, index, onRemove, onUpdate }) => {
    const { t } = useTranslation();
    const filtersTree = useConditionsProductFilters();
    return (_jsxs("div", Object.assign({ className: styles.productWrapper }, { children: [_jsxs("div", Object.assign({ className: styles.productHeader }, { children: [_jsxs("div", Object.assign({ className: styles.productTitle }, { children: [_jsx("div", Object.assign({ className: classNames(styles.productCounter, {
                                    [styles.productCounterMedium]: index >= 9,
                                    [styles.productCounterLarge]: index >= 99,
                                }) }, { children: index + 1 })), _jsx("div", { children: t('товар') })] })), _jsx("button", Object.assign({ className: styles.productClose, type: 'button', onClick: () => onRemove(index) }, { children: _jsx(Close, {}) }))] })), _jsx(FiltersInput, { filtersTree: filtersTree, value: item.filters, onUpdate: (filters) => {
                    if (!filters.length)
                        onRemove(index);
                    else
                        onUpdate(index, { filters });
                }, buttonTheme: 'default', hasResetButton: false, hasOperators: false })] })));
};
