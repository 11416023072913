import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { VarsPanel as VarsPanelComponent, } from '@/components/rich-text/VarsPanel/VarsPanel';
import { VarsPopup as VarsPopupComponent } from '@/components/rich-text/VarsPopup/VarsPopup';
import { INSERT_VARIABLE } from './insertVariable';
import { acceptCounters, acceptDateFormats, acceptPromocodes, } from './Variable';
export const VarsPopup = ({ type = 'popup', variables, currency, onClose, }) => {
    const [editor] = useLexicalComposerContext();
    const handleSelectVariable = (variable, parameters) => {
        const hasCounters = acceptCounters(variable.parameters);
        const hasPromocodes = acceptPromocodes(variable.parameters);
        const hasDateFormats = acceptDateFormats(variable.parameters);
        const params = {};
        if (hasCounters && (parameters === null || parameters === void 0 ? void 0 : parameters.counter))
            params.counterOfferId = parameters.counter.offerId.toString();
        if (hasPromocodes && (parameters === null || parameters === void 0 ? void 0 : parameters.promocode))
            params.promocodeId = parameters.promocode.id.toString();
        if (hasDateFormats && (parameters === null || parameters === void 0 ? void 0 : parameters.dateFormat))
            params.dateFormat = parameters.dateFormat;
        editor.dispatchCommand(INSERT_VARIABLE, Object.assign(Object.assign({}, variable), { params }));
    };
    const popup = (_jsx(VarsPopupComponent, { variables: variables, onSelectVariable: handleSelectVariable, currency: currency, onClose: onClose }));
    const panel = (_jsx(VarsPanelComponent, { variables: variables, onSelectVariable: handleSelectVariable, currency: currency, onClose: onClose }));
    return type === 'popup' ? popup : panel;
};
