import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberRangeInput } from '@/components/NumberRangeInput/NumberRangeInput';
import { isGreater, isInRange, isNumeric } from '@/utils/numbers';
import { Wrapper } from './Wrapper';
export const NumRange = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeMin = (min) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { min }) }));
    };
    const handleChangeMax = (max) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { max }) }));
    };
    const handleApply = () => onApply(draft);
    const min = ((_a = draft.value) === null || _a === void 0 ? void 0 : _a.min) !== undefined ? String((_b = draft.value) === null || _b === void 0 ? void 0 : _b.min) : '';
    const max = ((_c = draft.value) === null || _c === void 0 ? void 0 : _c.max) !== undefined ? String((_d = draft.value) === null || _d === void 0 ? void 0 : _d.max) : '';
    let minError = !isNumeric(min) ? 'Только числа' : '';
    let maxError = !isNumeric(max) ? 'Только числа' : '';
    if (!minError)
        minError = isInRange(min, draft.constraints);
    if (!maxError)
        maxError = isInRange(max, draft.constraints);
    if (!minError && !maxError && isGreater(min, max)) {
        minError = 'Ошибка ввода';
        maxError = 'Ошибка ввода';
    }
    const isValid = (min.trim() !== '' || max.trim() !== '') && !minError && !maxError;
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && isValid)
            handleApply();
    };
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: ((_e = draft.value) === null || _e === void 0 ? void 0 : _e.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid }, { children: _jsx(NumberRangeInput, { min: min, minError: t(minError, {
                min: (_f = draft.constraints) === null || _f === void 0 ? void 0 : _f.minValue,
                max: (_g = draft.constraints) === null || _g === void 0 ? void 0 : _g.maxValue,
            }), onChangeMin: handleChangeMin, max: max, maxError: t(maxError, {
                min: (_h = draft.constraints) === null || _h === void 0 ? void 0 : _h.minValue,
                max: (_j = draft.constraints) === null || _j === void 0 ? void 0 : _j.maxValue,
            }), onChangeMax: handleChangeMax, inputPostfix: item.inputPostfix, pluralPostfix: item.pluralPostfix, inputRightText: item.inputRightText, helpConstraints: item.showConstraintsInHelp ? item.constraints : undefined, onKeyDown: handleKeyDown }) })));
});
