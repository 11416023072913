import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import styles from './styles.module.scss';
export const GoogleWalletCardBackBanner = ({ imageUrl = '', onElementClick, clicksDisabled = false }) => {
    if (!imageUrl)
        return null;
    return (_jsx(WalletCardClickableElement, { className: classNames(styles.detailsImage, {
            [styles.clicksDisabled]: clicksDisabled,
        }), style: { backgroundImage: `url(${imageUrl})` }, type: 'google-back-banner', onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('backside-banner'), disabled: clicksDisabled }));
};
