import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { FormSegmentModal } from '@/forms/FormSegment/FormSegmentModal';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { ROUTES } from '../routes';
import { SegmentsPanel } from './SegmentsPanel';
import { useDeleteSegment } from './useDeleteSegment';
import { useDeleteSegmentClients } from './useDeleteSegmentClients';
import { useEditSegment } from './useEditSegment';
import { useRFMSegmentList } from './useRFMSegmentList';
import { useSegmentList } from './useSegmentList';
export const Segments = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [activeTabId, setActiveTabId] = useState('regular');
    const { brand, user } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const { rfmSegments, rfmTotal, isLoadingRFM, isFetchingRFM } = useRFMSegmentList(searchText, isRFMEnabled);
    const { segments, total, isLoading, isFetching } = useSegmentList(searchText);
    const deleteSegment = useDeleteSegment();
    const deleteSegmentClients = useDeleteSegmentClients();
    const editSegmentController = useEditSegment();
    if (!brand || !user)
        return null;
    return (_jsxs(Container, Object.assign({ notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: t('Название сегмента'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: setSearchText }) }) }, { children: [_jsx(SegmentsPanel, { segments: segments, shouldDisplayProfit: brand.brand.showProfit, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, onSegmentClick: (segment) => {
                    navigate(ROUTES.CLIENTS, {
                        state: { segmentId: segment.id, conditions: segment.conditions },
                    });
                }, rfmSegments: rfmSegments, total: activeTabId === 'regular' ? total : rfmTotal, isLoading: isLoading || isLoadingRFM, isFetching: activeTabId === 'regular' ? isFetching : isFetchingRFM, activeTabId: activeTabId, onChangeActiveTab: setActiveTabId, onEditSegment: editSegmentController.openEditModal, onDeleteSegment: deleteSegment, onDeleteSegmentClients: deleteSegmentClients, onGoToAllClients: () => navigate(ROUTES.CLIENTS), isRFMEnabled: isRFMEnabled }), _jsx(FormSegmentModal, { isOpen: editSegmentController.editModalIsOpen, onClose: editSegmentController.closeEditModal, defaultValues: editSegmentController.selectedSegment, onSubmit: editSegmentController.editSegment, isSubmitting: editSegmentController.isEditing, error: editSegmentController.editError, isEdit: true })] })));
};
