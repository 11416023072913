import moment from 'moment';
import { parseDaysNumber } from '@/components/DaysSelect/parseDaysNumber';
import * as triggers from '@/const/mailing-triggers';
import { OFFSET_SIGN_SCHEDULE } from '@/forms/FormMailing/controls/FormMailingOffset';
export const convertMailingToFormValues = (mailing, isClone) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const channels = [];
    if (mailing.channels.push)
        channels.push('push');
    if (mailing.channels.viber)
        channels.push('viber');
    if (mailing.channels.sms)
        channels.push('sms');
    if (mailing.channels.email)
        channels.push('email');
    if (mailing.channels.telegram)
        channels.push('telegram');
    const rewards = {};
    if (mailing.bonuses && mailing.bonuses > 0)
        rewards.bonusesAmount = mailing.bonuses.toString();
    if (mailing.bonuses && mailing.bonuses > 0 && mailing.expireInDays)
        rewards.bonusesLifetime = mailing.expireInDays.toString();
    if ((_a = mailing.promocode) === null || _a === void 0 ? void 0 : _a.id)
        rewards.promocodeId = mailing.promocode.id;
    if ((_b = mailing.giftCard) === null || _b === void 0 ? void 0 : _b.id)
        rewards.giftCardId = mailing.giftCard.id;
    if (mailing.offers && mailing.offers.length)
        rewards.offers = mailing.offers.map((mo) => {
            var _a;
            return ({
                id: mo.offer.id,
                expireInDays: (_a = mo.expireInDays) === null || _a === void 0 ? void 0 : _a.toString(),
            });
        });
    let offsetSign = (_c = mailing.offsetSign) === null || _c === void 0 ? void 0 : _c.toString();
    const triggerType = mailing.triggerType || triggers.TRIGGER_TYPE_SEGMENT_JOIN;
    const triggerFilters = ((_d = mailing.triggerConditions) === null || _d === void 0 ? void 0 : _d.configuration)
        ? { [triggerType]: (_e = mailing.triggerConditions) === null || _e === void 0 ? void 0 : _e.configuration }
        : {};
    let triggerScheduleType = 'daily';
    let triggerScheduleTime;
    let triggerScheduleDaysOfWeek;
    let triggerScheduleDaysOfMonth;
    let triggerScheduleDate;
    if (triggerType === triggers.TRIGGER_TYPE_SCHEDULE) {
        offsetSign = OFFSET_SIGN_SCHEDULE;
        triggerScheduleType = (_g = (_f = mailing.triggerConditions) === null || _f === void 0 ? void 0 : _f.conditions) === null || _g === void 0 ? void 0 : _g.scheduleType;
        triggerScheduleTime = (_j = (_h = mailing.triggerConditions) === null || _h === void 0 ? void 0 : _h.conditions) === null || _j === void 0 ? void 0 : _j.scheduleTime;
        triggerScheduleDaysOfMonth =
            (_l = (_k = mailing.triggerConditions) === null || _k === void 0 ? void 0 : _k.conditions) === null || _l === void 0 ? void 0 : _l.scheduleDays;
        triggerScheduleDate = (_o = (_m = mailing.triggerConditions) === null || _m === void 0 ? void 0 : _m.conditions) === null || _o === void 0 ? void 0 : _o.scheduleDate;
        const days = (_q = (_p = mailing.triggerConditions) === null || _p === void 0 ? void 0 : _p.conditions) === null || _q === void 0 ? void 0 : _q.scheduleWeekdays;
        triggerScheduleDaysOfWeek = parseDaysNumber(days);
    }
    let triggerEmailReceivedType = 'not_opened';
    let triggerEmailReceivedMailingId;
    if (triggerType === triggers.TRIGGER_TYPE_EMAIL_DELIVERED) {
        triggerEmailReceivedType = (_s = (_r = mailing.triggerConditions) === null || _r === void 0 ? void 0 : _r.conditions) === null || _s === void 0 ? void 0 : _s.ifMailing;
        triggerEmailReceivedMailingId =
            (_u = (_t = mailing.triggerConditions) === null || _t === void 0 ? void 0 : _t.conditions) === null || _u === void 0 ? void 0 : _u.mailingBrandEq;
    }
    const values = {
        id: isClone ? undefined : mailing.id,
        name: mailing.name,
        channels,
        recipientAllClients: (_v = mailing.recipients) === null || _v === void 0 ? void 0 : _v.all,
        recipientFilters: ((_x = (_w = mailing.recipients) === null || _w === void 0 ? void 0 : _w.filters) === null || _x === void 0 ? void 0 : _x.configuration) || [],
        // trigger (automatic only)
        offsetSign,
        offsetValue: mailing.offsetValue || 1,
        offsetUnit: mailing.offsetUnit || 'day',
        triggerType,
        triggerFilters,
        repeat: mailing.repeat,
        // trigger schedule
        triggerScheduleType,
        triggerScheduleTime,
        triggerScheduleDaysOfWeek,
        triggerScheduleDaysOfMonth,
        triggerScheduleDate,
        // trigger email received
        triggerEmailReceivedType,
        triggerEmailReceivedMailingId,
        // schedule (manual only)
        schedule: mailing.scheduledAt ? '1' : '0',
        scheduleDate: mailing.scheduledAt,
        scheduleTime: mailing.scheduledAt
            ? moment(mailing.scheduledAt).format('HH:mm')
            : undefined,
        // rewards
        rewards,
        // push
        messagingApp: (_y = mailing.messagingApp) === null || _y === void 0 ? void 0 : _y.id,
        pushText: mailing.rawPushBody || '',
        walletPushExpireInDays: typeof mailing.walletPushExpireInDays === 'number'
            ? mailing.walletPushExpireInDays.toString()
            : '',
        walletImage: mailing.walletImage || null,
        // viber
        viberText: mailing.rawViberBody || '',
        viberButton: mailing.viberButton || '',
        viberUrl: mailing.viberUrl || '',
        viberImage: mailing.viberImage || null,
        // sms
        smsSender: (_z = mailing.smsSender) === null || _z === void 0 ? void 0 : _z.id,
        smsText: mailing.rawSmsBody || '',
        // email
        emailSender: (_0 = mailing.emailSender) === null || _0 === void 0 ? void 0 : _0.id,
        emailSubject: mailing.rawEmailSubject || mailing.emailSubject || '',
        emailBody: mailing.emailBody || null,
        emailDesign: mailing.emailDesign || null,
        emailPreviewImageUrl: mailing.emailPreviewImageUrl || null,
        includeRecommendations: !!mailing.recommendationType,
        recommendationType: mailing.recommendationType || 1,
        recommendationCount: mailing.recommendationCount || 4,
        // telegram
        telegramText: mailing.rawTelegramBody || '',
        telegramButton: mailing.telegramButton || '',
        telegramUrl: mailing.telegramUrl || '',
        telegramImage: mailing.telegramImage || null,
        goalType: mailing.goalType || mailing.goalType === 0
            ? mailing.goalType.toString()
            : '3',
        goalDaysTo: mailing.goalDaysTo || 14,
        goalFilters: ((_1 = mailing.goalConditions) === null || _1 === void 0 ? void 0 : _1.configuration) || [],
        isActive: mailing.isActive,
    };
    return values;
};
