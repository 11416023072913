import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalTypeaheadMenuPlugin, useBasicTypeaheadTriggerMatch, } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from '../../VarsPopup/styles.module.scss';
import { TypeaheadItem } from '../../VarsPopup/TypeaheadItem';
import { $createVariableNode } from '../VariablePlugin/VariableNode';
import { VariableTypeaheadOption } from './VariableTypeaheadOption';
const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const TRIGGERS = ['!'].join('');
const VALID_CHARS = '[^' + TRIGGERS + PUNCTUATION + '\\s]';
const VALID_JOINS = '(?:' + '\\.[ |$]|' + ' |' + '[' + PUNCTUATION + ']|' + ')';
const LENGTH_LIMIT = 75;
const VariableSignRegex = new RegExp('(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    VALID_JOINS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$');
const SUGGESTION_LIST_LENGTH_LIMIT = undefined;
const TRIGGER_LENGTH_LIMIT = 0;
function checkForVariableSign(text) {
    const match = VariableSignRegex.exec(text);
    if (match !== null) {
        const maybeLeadingWhitespace = match[1];
        const matchingString = match[3];
        if (matchingString.length >= TRIGGER_LENGTH_LIMIT)
            return {
                leadOffset: match.index + maybeLeadingWhitespace.length,
                matchingString,
                replaceableString: match[2],
            };
    }
    return null;
}
export function VariableTypeaheadPlugin({ variables, onChangeResultsCount, isEnabled = true, }) {
    const [editor] = useLexicalComposerContext();
    const [queryString, setQueryString] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const results = variables.filter((v) => v.label.toLowerCase().includes((queryString === null || queryString === void 0 ? void 0 : queryString.toLowerCase()) || ''));
    const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
        minLength: 0,
    });
    const options = useMemo(() => results
        .map((result) => new VariableTypeaheadOption(result))
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT), [results]);
    const onSelectOption = useCallback((selectedOption, nodeToReplace, closeMenu) => {
        editor.update(() => {
            const variableNode = $createVariableNode(selectedOption.variable);
            if (nodeToReplace)
                nodeToReplace.replace(variableNode);
            closeMenu();
        });
    }, [editor]);
    const checkForMatch = useCallback((text) => {
        const editorState = editor.getEditorState();
        const selection = editorState._selection;
        const selectionNodes = selection === null || selection === void 0 ? void 0 : selection.getNodes();
        if (selectionNodes === null || selectionNodes === void 0 ? void 0 : selectionNodes.length) {
            const currentNode = selectionNodes[0];
            const prevNode = currentNode.getPreviousSibling();
            if ((prevNode === null || prevNode === void 0 ? void 0 : prevNode.getType()) === 'variable')
                return null;
        }
        const varMatch = checkForVariableSign(text);
        const slashMatch = checkForSlashTriggerMatch(text, editor);
        return !slashMatch && varMatch ? varMatch : null;
    }, [checkForSlashTriggerMatch, editor]);
    useEffect(() => {
        onChangeResultsCount(isOpen ? results.length : 0);
    }, [onChangeResultsCount, isOpen, results.length]);
    if (!isEnabled)
        return null;
    return (_jsx(LexicalTypeaheadMenuPlugin, { onQueryChange: setQueryString, onSelectOption: onSelectOption, triggerFn: checkForMatch, options: options, menuRenderFn: (anchorElementRef, { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }) => anchorElementRef.current && results.length
            ? ReactDOM.createPortal(_jsx("ul", Object.assign({ className: styles.typeahead }, { children: options.map((option, i) => (_jsx(TypeaheadItem, { option: option, isSelected: selectedIndex === i, query: queryString || '', onClick: () => {
                        setHighlightedIndex(i);
                        selectOptionAndCleanUp(option);
                    }, onMouseEnter: () => {
                        setHighlightedIndex(i);
                    } }, option.key))) })), anchorElementRef.current)
            : null, onOpen: () => setIsOpen(true), onClose: () => setIsOpen(false) }));
}
