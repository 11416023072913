var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const getImageDataURL = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('error', reject);
        reader.addEventListener('abort', reject);
        reader.addEventListener('load', () => __awaiter(void 0, void 0, void 0, function* () {
            const url = reader.result;
            resolve(url);
        }));
        reader.readAsDataURL(file);
    });
};
const getImageDimension = (dataURL) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('error', reject);
        image.addEventListener('abort', reject);
        image.addEventListener('load', () => {
            resolve([image, image.width, image.height]);
        });
        image.src = dataURL;
    });
};
const renderImageOnCanvas = (image, width, height, cropWidth, cropHeight) => {
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');
    ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, 0, 0, width, height, 0, 0, cropWidth, cropHeight);
    return canvas;
};
const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                const file = new File([blob], 'image.png');
                resolve(file);
            }
            else
                reject();
        });
    });
};
export const scaleImage = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const dataURL = yield getImageDataURL(file);
    const [image, width, height] = yield getImageDimension(dataURL);
    const canvas = renderImageOnCanvas(image, width, height, width / 2, height / 2);
    const result = yield canvasToBlob(canvas);
    return result;
});
