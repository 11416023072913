var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { FormClient } from '@/forms/FormClient/FormClient';
import { useData } from './useData';
import { useDeleteClient } from './useDeleteClient';
import { useUpdateClient } from './useUpdateClient';
export const ClientEditForm = memo((_a) => {
    var { selectedClient, onUpdated, onDeleted } = _a, props = __rest(_a, ["selectedClient", "onUpdated", "onDeleted"]);
    const { levels, shops } = useData();
    const { updateClient, isUpdating, updateError } = useUpdateClient();
    const { deleteClient, isDeleting } = useDeleteClient();
    return (_jsx(FormClient, Object.assign({}, props, { levels: levels, shops: shops, isPhoneDisabled: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.isPhoneDisabled, isEmailDisabled: selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.isEmailDisabled, defaultValues: selectedClient, unparsedName: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.fullName) && !selectedClient.name
            ? selectedClient.fullName
            : undefined, onSubmit: (formValues) => __awaiter(void 0, void 0, void 0, function* () {
            if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.id) {
                const updated = yield updateClient(selectedClient, formValues);
                if (updated && onUpdated)
                    onUpdated();
            }
        }), isSubmitting: isUpdating || isDeleting, error: updateError, isEdit: true, onDelete: () => __awaiter(void 0, void 0, void 0, function* () {
            if (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.id) {
                const deleted = yield deleteClient(selectedClient);
                if (deleted && onDeleted)
                    onDeleted();
            }
        }) })));
});
