var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { AutocompleteInput, } from '../../variants/AutocompleteInput';
import { OPTIONS, URL_SUGGEST_ADDRESS } from './constants';
export const AutocompleteDadataAddress = memo((props) => {
    const getOptionKey = (option) => option === null || option === void 0 ? void 0 : option.unrestricted_value;
    const getOptionLabel = (option) => option.value;
    const getAddreses = (searchString) => __awaiter(void 0, void 0, void 0, function* () {
        const options = Object.assign(Object.assign({}, OPTIONS), { body: JSON.stringify({
                query: searchString,
                locations: [
                    {
                        country: '*',
                    },
                ],
            }) });
        const response = yield fetch(URL_SUGGEST_ADDRESS, options);
        const json = yield response.json();
        const addresses = json.suggestions;
        return addresses;
    });
    return (_jsx(AutocompleteInput, Object.assign({}, props, { getOptionKey: getOptionKey, getOptionLabel: getOptionLabel, options: getAddreses, isLarge: true })));
});
