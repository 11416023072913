import { useTranslation } from 'react-i18next';
export const useTriggersSchedule = () => {
    const { t } = useTranslation();
    return [
        {
            id: 'daily',
            label: t('Каждый день'),
        },
        {
            id: 'weekly',
            label: t('Каждую неделю'),
        },
        {
            id: 'monthly',
            label: t('Каждый месяц'),
        },
        {
            id: 'yearly',
            label: t('Каждый год'),
        },
    ];
};
