export const buildExtraFields = (extraFields, extraFieldOptions, loadExtraFieldOptions) => {
    const leaves = [];
    for (let i = 0; i < extraFields.length; i += 1) {
        const field = extraFields[i];
        const leaf = {
            type: 'leaf',
            id: `x_${field.name}`,
            label: field.title,
        };
        if (field.type === 'set' || field.type === 'static-set')
            leaves.push(Object.assign(Object.assign({}, leaf), { template: 'checkboxes-loader', loadOptions: () => loadExtraFieldOptions(field.name), options: extraFieldOptions[field.name] }));
        if (field.type === 'numeric')
            leaves.push(Object.assign(Object.assign({}, leaf), { template: 'numRange' }));
        if (field.type === 'text')
            leaves.push(Object.assign(Object.assign({}, leaf), { template: 'textField' }));
    }
    return leaves;
};
