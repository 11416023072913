import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { DragDrop } from '../../helpers/DragDrop';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
export const BacksideDetails = () => {
    const { t } = useTranslation();
    const [reordered, setReordered] = useState(0);
    const { activeCardType, setActiveCardType, setAppleCardIsFlipped, setGoogleCardIsFlipped, markReadyToSave, backside, setBackside, setIsElementClick, } = useWalletCardForm();
    const handleFocus = () => {
        if (activeCardType === 'notify')
            setActiveCardType('apple');
        setAppleCardIsFlipped(true);
        setGoogleCardIsFlipped(true);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (index, newItem) => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { details: backside.details.map((item, i) => i === index ? newItem : item) })));
    };
    const handleReorder = (details) => {
        setReordered((reordered) => reordered + 1);
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { details })));
        markReadyToSave();
        handleFocus();
    };
    return (_jsx(DragDrop, { draggableClassName: styles.detailsItem, items: backside.details, getItemKey: (item, i) => `item-${i}`, renderItem: (item, i) => {
            return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: styles.detailsNumber }, { children: [i + 1, "."] })), _jsxs("div", Object.assign({ className: styles.detailsControls }, { children: [_jsx(Input, { id: `backside-item-${i}-title`, placeholder: t('название'), value: item.title, onChange: (e) => {
                                    handleChange(i, Object.assign(Object.assign({}, item), { title: e.target.value }));
                                }, onFocus: handleFocus, onBlur: markReadyToSave }), _jsx(SmartInput, { id: `backside-item-${i}-content`, placeholder: t('значение'), value: item.content, vars: item.vars, onChange: (value, vars) => {
                                    handleChange(i, Object.assign(Object.assign({}, item), { content: value, vars: vars }));
                                }, onFocus: handleFocus, onBlur: markReadyToSave, asTextInput: false, hasLinkSupport: true }, reordered)] }))] }));
        }, createItem: () => ({ title: '', content: '' }), onReorder: handleReorder }));
};
