import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { WalletCardFormLayout } from '@/components/wallet-cards/WalletCardFormLayout/WalletCardFormLayout';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { BannerDropdown } from './BannerDropdown';
import styles from './styles.module.scss';
export const Layout = ({ content, card, cardFooter, }) => {
    const { t } = useTranslation();
    const { activeCardType, setActiveCardType, formSection, setFormSection } = useWalletCardForm();
    let title = undefined;
    switch (formSection) {
        case 'head': {
            title = t('Шапка');
            break;
        }
        case 'banner': {
            title = (_jsxs("div", Object.assign({ className: styles.bannerDropdown }, { children: [t('Баннер'), _jsx(BannerDropdown, {})] })));
            break;
        }
        case 'fields': {
            title = t('Поля');
            break;
        }
        case 'notify': {
            title = t('Уведомления');
            break;
        }
        case 'backside': {
            title = t('Обратная сторона');
            break;
        }
        case 'barcode': {
            title = t('Баркод');
            break;
        }
    }
    const handleGoNext = () => {
        if (formSection === 'head')
            setFormSection('banner');
        if (formSection === 'banner')
            setFormSection('fields');
        if (formSection === 'fields')
            setFormSection('barcode');
        if (formSection === 'barcode')
            setFormSection('backside');
        if (formSection === 'backside')
            setFormSection('notify');
    };
    const handleGoPrev = () => {
        if (formSection === 'notify')
            setFormSection('backside');
        if (formSection === 'backside')
            setFormSection('barcode');
        if (formSection === 'barcode')
            setFormSection('fields');
        if (formSection === 'fields')
            setFormSection('banner');
        if (formSection === 'banner')
            setFormSection('head');
        if (formSection === 'head')
            setFormSection(null);
    };
    const handleDone = () => {
        setFormSection(null);
    };
    return (_jsx(WalletCardFormLayout, { title: title, content: content, card: card, cardType: activeCardType, onChangeCardType: setActiveCardType, cardFooter: cardFooter, onGoBack: () => setFormSection(null), onGoNext: [null, 'notify'].includes(formSection) ? undefined : handleGoNext, onGoPrev: [null, 'head'].includes(formSection) ? undefined : handleGoPrev, onDone: formSection === 'notify' ? handleDone : undefined }));
};
