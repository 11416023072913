import { DAYS } from './DaysSelect';
export const parseDaysNumber = (days) => {
    if (!days)
        return [];
    return [
        days & DAYS.WEEKDAY_MON ? DAYS.WEEKDAY_MON : null,
        days & DAYS.WEEKDAY_TUE ? DAYS.WEEKDAY_TUE : null,
        days & DAYS.WEEKDAY_WED ? DAYS.WEEKDAY_WED : null,
        days & DAYS.WEEKDAY_THU ? DAYS.WEEKDAY_THU : null,
        days & DAYS.WEEKDAY_FRI ? DAYS.WEEKDAY_FRI : null,
        days & DAYS.WEEKDAY_SAT ? DAYS.WEEKDAY_SAT : null,
        days & DAYS.WEEKDAY_SUN ? DAYS.WEEKDAY_SUN : null,
    ].filter(Boolean);
};
