import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useEffect, useRef } from 'react';
import { Input } from '@/components/Input/Input';
import { Chevron } from '@/icons/Chevron';
import { CloseCircle } from '@/icons/CloseCircle';
import styles from './styles.module.scss';
export const ShopGroupInputTrigger = forwardRef(({ id, isOpen, value, onClick, onReset, onInputInitialized, hasError = false, placeholder, }, ref) => {
    const input = useRef(null);
    useEffect(() => {
        if (input.current)
            onInputInitialized === null || onInputInitialized === void 0 ? void 0 : onInputInitialized(input.current);
    }, []);
    const hasValue = !!value;
    return (_jsxs("div", Object.assign({ className: classNames(styles.resetWrapper, {
            [styles.hasResetButton]: hasValue,
        }), ref: ref }, { children: [_jsxs("div", Object.assign({ className: styles.chevronWrapper }, { children: [_jsx(Input, { id: id, className: styles.input, type: 'button', value: value, onClick: onClick, hasError: hasError, ref: input, isActive: isOpen, placeholder: placeholder }), _jsx(Chevron, { className: classNames(styles.chevron, {
                            [styles.flipped]: isOpen,
                        }), fill: 'currentColor' })] })), hasValue && (_jsx("button", Object.assign({ className: styles.reset, type: 'button', onClick: onReset }, { children: _jsx(CloseCircle, {}) })))] })));
});
