import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormUsersUsername = ({ isEdit, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const { errors } = formState;
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) && !isEdit ? t('FIELD_REQUIRED_ERROR') : true;
    return (_jsx(FormFieldRow, Object.assign({ label: t('Логин'), labelFor: 'username', controlSize: '250', help: (_a = errors.username) === null || _a === void 0 ? void 0 : _a.message, isRequired: true, hasError: !!errors.username }, { children: _jsx(Controller, { name: 'username', control: control, rules: {
                maxLength: {
                    value: 200,
                    message: t('FIELD_MAX_LENGTH_ERROR', {
                        amount: numberRound(200),
                        count: 200,
                    }),
                },
                required: {
                    value: true,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                pattern: {
                    value: /^[A-Z0-9@._+-]+$/i,
                    message: t('только латиница'),
                },
                validate: validateTrimValue,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'username', placeholder: t('введите логин'), hasError: !!errors.username, autoFocus: true }))) }) })));
};
