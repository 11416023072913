var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { NodePlusButton } from '../NodePlusButton/NodePlusButton';
import styles from './styles.module.scss';
export const NodeRow = (_a) => {
    var { className, children, nodeId, handleId, onClick, asIcon = false, hasHandlePlusButton = true } = _a, otherProps = __rest(_a, ["className", "children", "nodeId", "handleId", "onClick", "asIcon", "hasHandlePlusButton"]);
    const flow = useReactFlow();
    const hasOutputEdges = flow
        .getEdges()
        .some((e) => e.source === nodeId && e.sourceHandle === handleId);
    return (_jsxs("div", Object.assign({ className: classNames('react-flow__row', className, styles.row, {
            [styles.interactive]: !!onClick,
            nodrag: !!onClick,
            nopan: !!onClick,
            [styles.icon]: asIcon,
        }), onClick: onClick }, otherProps, { children: [_jsx("div", Object.assign({ className: styles.content }, { children: children })), !!handleId && (_jsxs(_Fragment, { children: [hasHandlePlusButton && (_jsx(NodePlusButton, { nodeId: nodeId, handleId: handleId })), _jsx(Handle, { className: classNames('flow-handle', styles.handle, {
                            'flow-handle-hidden': !hasOutputEdges,
                        }), id: handleId, type: 'source', position: Position.Right })] }))] })));
};
