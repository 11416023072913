import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { pct } from '@/utils/calculatePercent';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const HorizontalBarList = ({ type = 'progressbar', items, onItemClick, total, totalLabel = 'Всего', isLocked = false, }) => {
    const { t } = useTranslation();
    const max = Math.max(...items.map((i) => i.value));
    return (_jsxs("div", Object.assign({ className: classNames({
            [styles.locked]: isLocked,
        }) }, { children: [items.map((item) => {
                var _a;
                const percent = type === 'progressbar' ? item.percent : pct(item.value, max);
                const compositionBar = (_a = item.composition) === null || _a === void 0 ? void 0 : _a.filter(({ value }) => value > 0);
                return (_jsxs("div", Object.assign({ className: classNames(styles.item, {
                        [styles.empty]: !item.percent,
                        [styles.interactive]: !!onItemClick,
                    }), onClick: () => onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick(item.id) }, { children: [_jsx("div", Object.assign({ className: styles.label }, { children: item.label })), (compositionBar === null || compositionBar === void 0 ? void 0 : compositionBar.length) ? (_jsx("div", Object.assign({ className: classNames(styles.compositeBarContainer, {
                                [styles.oneBar]: compositionBar.length === 1,
                            }) }, { children: compositionBar.map(({ id, tooltip, percent }, index) => (_jsx(Tooltip, Object.assign({ placement: 'top', title: tooltip }, { children: _jsx("div", { className: classNames(styles.bar, {
                                        [styles.compositeBarFirst]: compositionBar.length > 1 && index === 0,
                                        [styles.compositeBarLast]: compositionBar.length > 1 &&
                                            index === compositionBar.length - 1,
                                    }), style: {
                                        width: percent <= 100 ? `${percent}%` : '100%',
                                    } }) }), id))) }))) : (_jsx("div", { className: styles.bar, style: {
                                backgroundColor: item.color,
                                width: percent <= 100 ? `${percent}%` : '100%',
                            } })), _jsx("div", { className: styles.line }), _jsxs("div", Object.assign({ className: styles.values }, { children: [_jsx("div", Object.assign({ className: styles.value }, { children: numberRound(item.value) })), type === 'progressbar' && (_jsxs("div", Object.assign({ className: styles.percent }, { children: [item.percent, "%"] })))] }))] }), item.id));
            }), typeof total !== 'undefined' && (_jsxs("div", Object.assign({ className: styles.total }, { children: [_jsx("div", Object.assign({ className: styles.totalLabel }, { children: t(totalLabel) })), _jsx("div", Object.assign({ className: styles.totalValue }, { children: numberRound(total) }))] })))] })));
};
