var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useCreateGeoPushMutation, useUpdateGeoPushMutation, } from '@/api/query/wallet-cards';
import { useToastContext } from '@/components/Toast/ToastContext';
import { convertCoordinatesToArray } from '@/forms/FormGeoPush/convertCoordinatesToArray';
export const useSaveGeoPush = ({ id, onSuccess, }) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const addToast = useToastContext();
    const [createGeoPush, { isLoading: isCreating }] = useCreateGeoPushMutation();
    const [updateGeoPush, { isLoading: isUpdating }] = useUpdateGeoPushMutation();
    const saveGeoPush = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const successMessage = id ? t('Геопуш изменен') : t('Геопуш создан');
        let result;
        const payload = convertFormValuesToGeoPush(values);
        if (id)
            result = yield updateGeoPush({ id, payload });
        else
            result = yield createGeoPush(payload);
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
    return {
        saveGeoPush,
        isSubmitting: isCreating || isUpdating,
        error,
    };
};
const convertFormValuesToGeoPush = (values) => {
    var _a;
    const coordinates = convertCoordinatesToArray(values.coordinates);
    return {
        name: values.title,
        address: ((_a = values.address) === null || _a === void 0 ? void 0 : _a.value) || '',
        latitude: coordinates[0],
        longitude: coordinates[1],
        message: values.message,
        distance: values.distance ? Number(values.distance) : null,
        activeFrom: values.show !== 'always' && values.showFrom
            ? moment(values.showFrom).format('DD.MM.YYYY')
            : null,
        activeTo: values.show !== 'always' && values.showBy
            ? moment(values.showBy).format('DD.MM.YYYY')
            : null,
        isActive: values.isActive,
    };
};
