import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '@/components/Popup/Popup';
import themeStyles from '../../TelegramTextEditor/themeStyles.module.scss';
import { AutocompleteCounters } from './AutocompleteCounters';
import { AutocompleteDateFormats } from './AutocompleteDateFormats';
import { AutocompletePromocodes } from './AutocompletePromocodes';
import styles from './styles.module.scss';
import { acceptCounters, acceptDateFormats, acceptPromocodes, } from './Variable';
export const VariableComponent = ({ formats, payload = {}, children, onSetParam, }) => {
    const { t } = useTranslation();
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const triggerRef = useRef(null);
    const chevron = popupIsOpen ? chevronUp : chevronDown;
    const { parameters = [], params } = payload;
    const isInteractive = parameters.length > 0;
    const hasCounters = acceptCounters(parameters);
    const hasPromocodes = acceptPromocodes(parameters);
    const hasDateFormats = acceptDateFormats(parameters);
    const isInvalid = (params) => (hasCounters && !(params === null || params === void 0 ? void 0 : params.counterOfferId)) ||
        (hasPromocodes && !(params === null || params === void 0 ? void 0 : params.promocodeId)) ||
        (hasDateFormats && !(params === null || params === void 0 ? void 0 : params.dateFormat));
    const handleTogglePopup = useCallback(() => {
        if (isInteractive)
            setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    }, [isInteractive]);
    const handleClosePopup = useCallback(() => {
        setPopupIsOpen(false);
    }, []);
    const formatStylesList = {};
    for (const formatType in formats)
        if (formats[formatType])
            formatStylesList[themeStyles[formatType]] = true;
    return (_jsxs(_Fragment, { children: [_jsxs("span", Object.assign({ className: classNames(styles.variable, Object.assign({ [styles.interactive]: isInteractive, [styles.active]: popupIsOpen, [styles.invalid]: isInvalid(params), [themeStyles.underlineStrikethrough]: formats.strikethrough && formats.underline, [themeStyles.hidden]: formats.code }, formatStylesList)), onClick: handleTogglePopup, ref: triggerRef }, { children: [children, isInteractive && chevron] })), _jsx(Popup, Object.assign({ isOpen: popupIsOpen, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, onClose: handleClosePopup, placement: 'bottom-start', offset: [0, 10], isRounded: true }, { children: _jsxs("div", Object.assign({ className: styles.popupContent }, { children: [hasPromocodes && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.paramLabel }, { children: t('Промокод') })), _jsx(AutocompletePromocodes, { value: (params === null || params === void 0 ? void 0 : params.promocodeId) || '', onChange: (promocode) => {
                                        const value = (promocode === null || promocode === void 0 ? void 0 : promocode.id.toString()) || '';
                                        onSetParam === null || onSetParam === void 0 ? void 0 : onSetParam('promocodeId', value);
                                        if (!isInvalid(Object.assign(Object.assign({}, params), { promocodeId: value })))
                                            handleClosePopup();
                                    } })] })), hasCounters && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.paramLabel }, { children: t('Счетчик') })), _jsx(AutocompleteCounters, { value: (params === null || params === void 0 ? void 0 : params.counterOfferId) || '', onChange: (counter) => {
                                        const value = (counter === null || counter === void 0 ? void 0 : counter.offerId.toString()) || '';
                                        onSetParam === null || onSetParam === void 0 ? void 0 : onSetParam('counterOfferId', value);
                                        if (!isInvalid(Object.assign(Object.assign({}, params), { counterOfferId: value })))
                                            handleClosePopup();
                                    } })] })), hasDateFormats && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.paramLabel }, { children: t('DATE_FORMAT_LABEL') })), _jsx(AutocompleteDateFormats, { value: (params === null || params === void 0 ? void 0 : params.dateFormat) || '', onChange: (dateFormat) => {
                                        const value = (dateFormat === null || dateFormat === void 0 ? void 0 : dateFormat.value) || '';
                                        onSetParam === null || onSetParam === void 0 ? void 0 : onSetParam('dateFormat', value);
                                        if (!isInvalid(Object.assign(Object.assign({}, params), { dateFormat: value })))
                                            handleClosePopup();
                                    } })] }))] })) }))] }));
};
const chevronDown = (_jsx("svg", Object.assign({ xmlns: 'http://www.w3.org/2000/svg', width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', className: styles.chevron }, { children: _jsx("path", { d: 'M4 8.20703L6.27506 10.4821C6.44165 10.6487 6.71174 10.6487 6.87833 10.4821L9.15339 8.20703', stroke: 'currentColor', strokeWidth: '1.5', strokeLinejoin: 'round' }) })));
const chevronUp = (_jsx("svg", Object.assign({ xmlns: 'http://www.w3.org/2000/svg', width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', className: styles.chevron }, { children: _jsx("path", { d: 'M9.15332 9.60693L6.87826 7.33187C6.71167 7.16529 6.44158 7.16529 6.27499 7.33187L3.99994 9.60693', stroke: 'currentColor', strokeWidth: '1.5', strokeLinejoin: 'round' }) })));
