import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Promocode } from '@/components/Promocode/Promocode';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { TableRowIcon } from '@/components/TableRowIcon/TableRowIcon';
import { getAvailabilityRange } from '@/utils/getAvailabilityRange';
import { isItemPlanned } from '@/utils/isItemPlanned';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import styles from './styles.module.scss';
export const PromocodesTable = memo(({ promocodes = [], total, promocodeLabel, codeTypeShared, codeTypePersonal, shouldDisplayProfit, currency, timeZone, isLoading, purchaseUrl, onGenerateBatch, onDeletePromocode, onOpenPurchaseFilter, onGoToEditForm, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasPromocodesManage = userHasAccessTo('PROMOCODES_MANAGE');
    const userHasExportsManage = userHasAccessTo('EXPORTS_MANAGE');
    const columns = [
        {
            id: 'title',
            title: promocodeLabel,
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t(`TOOLTIP_ROI_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            tooltip: t(shouldDisplayProfit
                ? 'TOOLTIP_PROMOCODE_PROFIT'
                : 'TOOLTIP_PROMOCODE_REVENUE'),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма применённых скидок, списанных бонусов и затрат на рассылки, в которых распространялся этот код'),
            align: 'right',
        },
        {
            id: 'count',
            title: t('Покупок'),
            tooltip: t('TOOLTIP_PROMOCODE_COUNT'),
            align: 'right',
        },
        {
            id: 'orderCount',
            title: t('Заказов'),
            tooltip: t('TOOLTIP_PROMOCODE_OFFER_COUNT'),
            align: 'right',
        },
        {
            id: 'availabilityRange',
            title: t('Период действия'),
            align: 'right',
        },
        {
            id: 'actions',
        },
    ];
    const rows = promocodes.map((code) => ({
        id: code.id,
        cells: [
            _jsxs("span", Object.assign({ className: styles.nowrap }, { children: [code.isActive === false && (_jsx(TableRowIcon, { title: t('Остановлен'), icon: 'pause' })), code.isActive !== false && isItemPlanned(code) && (_jsx(TableRowIcon, { title: t('Запланирован'), icon: 'planned' })), _jsx(Promocode, { code: code.code, isPersonal: code.codeType !== codeTypeShared })] }), 'code'),
            numberRound(roi(shouldDisplayProfit ? code.profit : code.revenue, code.expenses)) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(code.profit), revenue: Number(code.revenue), currency: currency, href: purchaseUrl, onClick: () => onOpenPurchaseFilter(code) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(code.expenses), currency: currency }, 'expenses'),
            numberRound(code.count),
            numberRound(code.orderCount || 0),
            getAvailabilityRange(code, timeZone, t('не ограничен')),
        ],
        actions: [
            {
                id: 'edit',
                label: t('Редактировать'),
                onClick: () => onGoToEditForm(code.id),
                isDisabled: !userHasPromocodesManage,
                tooltip: !userHasPromocodesManage && t('Недоступно для вашей роли'),
            },
            {
                id: 'generateBatch',
                label: t('Сгенерировать коды'),
                isDisabled: !userHasPromocodesManage || !userHasExportsManage,
                tooltip: (!userHasPromocodesManage || !userHasExportsManage) &&
                    t('Недоступно для вашей роли'),
                shouldDisplay: code.codeType === codeTypePersonal,
                onClick: () => onGenerateBatch(code),
            },
            {
                id: 'delete',
                label: t('Удалить'),
                onClick: () => onDeletePromocode(code),
                isDanger: true,
                isDisabled: !userHasPromocodesManage,
                tooltip: !userHasPromocodesManage && t('Недоступно для вашей роли'),
            },
        ],
    }));
    const totalRow = !total
        ? undefined
        : [
            {
                id: 'total',
                isTotal: true,
                cells: [
                    _jsx(Fragment, { children: _jsx("b", { children: t('Итого за период') }) }, 'total'),
                    numberRound(roi(shouldDisplayProfit ? total.profit : total.revenue, total.expenses)) + '%',
                    _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: total.profit, revenue: total.revenue, currency: currency }, 'profitOrRevenue'),
                    _jsx(Expenses, { expenses: total.expenses, currency: currency }, 'expenses'),
                    numberRound(total.count),
                    '',
                    '',
                ],
            },
        ];
    const handleRowClick = useCallback((row) => onGoToEditForm(Number(row.id)), [onGoToEditForm]);
    return (_jsx(Table, { columns: columns, rows: rows, total: totalRow, isLoading: isLoading, hasEmptyState: true, onRowClick: handleRowClick }));
});
