import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { EventsTable } from './EventsTable';
import styles from './styles.module.scss';
export const EventsPanel = memo(({ isLoading = false, eventsData, activeButtons, handleChangeFilters, openOverview, handleNextPage, }) => {
    const { t } = useTranslation();
    return (_jsx(TabsPanel, Object.assign({ headerClassName: styles.headerTabsPanel, footer: _jsx(Button, Object.assign({ className: styles.nextPageButton, onClick: handleNextPage }, { children: "\u2192" })), rightToolbar: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.all,
                    }), isActive: activeButtons.all, onClick: () => !activeButtons.all && handleChangeFilters('all') }, { children: t('Все') })), _jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.bonus,
                    }), isActive: activeButtons.bonus, onClick: () => handleChangeFilters('bonus') }, { children: t('Бонусы') })), _jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.mailing,
                    }), isActive: activeButtons.mailing, onClick: () => handleChangeFilters('mailing') }, { children: t('Рассылки') })), _jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.purchase,
                    }), isActive: activeButtons.purchase, onClick: () => handleChangeFilters('purchase') }, { children: t('Покупки') })), _jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.loyalty,
                    }), isActive: activeButtons.loyalty, onClick: () => handleChangeFilters('loyalty') }, { children: t('Сегменты') })), _jsx(Button, Object.assign({ className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.client_update,
                    }), isActive: activeButtons.client_update, onClick: () => handleChangeFilters('client_update') }, { children: t('Другое') }))] }), isContentSkeleton: isLoading }, { children: _jsx(EventsTable, { openOverview: openOverview, isLoading: isLoading, eventsData: eventsData }) })));
});
