import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { Plus } from '@/icons/Plus';
import { TextAlignLeft } from '@/icons/TextAlignLeft';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { DragDrop } from '../../helpers/DragDrop';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
export const FieldsGoogle = () => {
    const { t } = useTranslation();
    const [reordered, setReordered] = useState(0);
    const { setActiveCardType, setGoogleCardIsFlipped, markReadyToSave, googleFields, setGoogleFields, setIsElementClick, } = useWalletCardForm();
    const handleFocus = () => {
        setActiveCardType('google');
        setGoogleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (fields) => {
        setGoogleFields((googleFields) => (Object.assign(Object.assign({}, googleFields), fields)));
    };
    const firstRow = ['1', '2', '3'];
    const secondRow = ['4', '5', '6'];
    const thirdRow = ['7', '8', '9'];
    const hasField = (fieldKey) => {
        const titleKey = `field_${fieldKey}_Title`;
        const valueKey = `field_${fieldKey}_Value`;
        return (googleFields[titleKey] !== undefined ||
            googleFields[valueKey] !== undefined);
    };
    const hasSecondRow = secondRow.some(hasField);
    const hasThirdRow = thirdRow.some(hasField);
    const renderRow = (row) => row.map((fieldKey, i) => {
        const id = `google-field-${fieldKey}`;
        const titleKey = `field_${fieldKey}_Title`;
        const valueKey = `field_${fieldKey}_Value`;
        const varsKey = `field_${fieldKey}_Vars`;
        return (_jsxs("div", Object.assign({ className: styles.control }, { children: [_jsxs("label", Object.assign({ className: styles.label, htmlFor: id }, { children: [_jsx(TextAlignLeft, {}), t('поле') + ' ' + (i + 1).toString()] })), _jsx(Input, { id: id, placeholder: t('название'), value: googleFields[titleKey], onChange: (e) => {
                        handleChange({ [titleKey]: e.target.value });
                    }, onFocus: handleFocus, onBlur: markReadyToSave }, titleKey + reordered), _jsx(SmartInput, { placeholder: t('значение'), value: googleFields[valueKey] || '', vars: googleFields[varsKey], onChange: (value, vars) => {
                        handleChange({
                            [valueKey]: value,
                            [varsKey]: vars,
                        });
                    }, onFocus: handleFocus, onBlur: markReadyToSave }, valueKey + reordered)] }), fieldKey));
    });
    const addRow = () => {
        let id = '';
        if (!hasSecondRow) {
            id = 'google-field-4';
            markReadyToSave();
            setGoogleFields((fields) => (Object.assign(Object.assign({}, fields), { field_4_Title: '', field_4_Value: '', field_5_Title: '', field_5_Value: '', field_6_Title: '', field_6_Value: '' })));
        }
        else if (!hasThirdRow) {
            id = 'google-field-7';
            markReadyToSave();
            setGoogleFields((fields) => (Object.assign(Object.assign({}, fields), { field_7_Title: '', field_7_Value: '', field_8_Title: '', field_8_Value: '', field_9_Title: '', field_9_Value: '' })));
        }
        setTimeout(() => {
            const element = document.getElementById(id);
            element === null || element === void 0 ? void 0 : element.focus();
        }, 0);
    };
    const handleReorder = (rows) => {
        handleFocus();
        setReordered((reordered) => reordered + 1);
        const fields = {
            field_1_Title: '',
            field_1_Value: '',
            field_2_Title: '',
            field_2_Value: '',
            field_3_Title: '',
            field_3_Value: '',
        };
        if (rows[0].every((n) => !n)) {
            setGoogleFields(fields);
            markReadyToSave();
            return;
        }
        const tk = (n) => `field_${n}_Title`;
        const valk = (n) => `field_${n}_Value`;
        const vark = (n) => `field_${n}_Vars`;
        for (let i = 0; i < rows.length; i += 1) {
            const [a1, a2, a3] = [i * 3 + 1, i * 3 + 2, i * 3 + 3].map((n) => n + '');
            const [b1, b2, b3] = rows[i];
            fields[tk(a1)] = googleFields[tk(b1)];
            fields[valk(a1)] = googleFields[valk(b1)];
            fields[vark(a1)] = googleFields[vark(b1)];
            fields[tk(a2)] = googleFields[tk(b2)];
            fields[valk(a2)] = googleFields[valk(b2)];
            fields[vark(a2)] = googleFields[vark(b2)];
            fields[tk(a3)] = googleFields[tk(b3)];
            fields[valk(a3)] = googleFields[valk(b3)];
            fields[vark(a3)] = googleFields[vark(b3)];
        }
        setGoogleFields(fields);
        markReadyToSave();
    };
    return (_jsxs("div", { children: [_jsx(DragDrop, { draggableClassName: classNames(styles.fieldsWrapper, styles.google), items: [
                    firstRow,
                    hasSecondRow ? secondRow : null,
                    hasThirdRow ? thirdRow : null,
                ].filter(Boolean), getItemKey: (item) => item.join(), renderItem: (item) => {
                    return _jsx(_Fragment, { children: renderRow(item) });
                }, createItem: () => ['', '', ''], onReorder: handleReorder }), _jsx(Button, Object.assign({ type: 'button', icon: _jsx(Plus, {}), disabled: hasSecondRow && hasThirdRow, onClick: addRow, isOutlined: true }, { children: t('Добавить ряд полей') }))] }));
};
