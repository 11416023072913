import { createSlice } from '@reduxjs/toolkit';
const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        collapsed: true,
        hidden: false,
    },
    reducers: {
        toggleCollapseSidebar(state) {
            state.collapsed = !state.collapsed;
            state.hidden = false;
        },
        toggleHiddenSidebar(state) {
            state.hidden = !state.hidden;
            state.collapsed = false;
        },
        hideSidebar(state) {
            state.hidden = true;
        },
        collapseSidebar(state) {
            state.collapsed = true;
        },
        expandSidebar(state) {
            state.collapsed = false;
        },
    },
});
export default sidebarSlice.reducer;
export const { toggleCollapseSidebar, toggleHiddenSidebar, hideSidebar, collapseSidebar, expandSidebar, } = sidebarSlice.actions;
