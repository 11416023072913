import { validateChannelNodeData } from './ChannelNode/utils';
import { validateChannelReceivedNodeData } from './ChannelReceivedNode/utils';
import { validateConditionsNodeData } from './ConditionsNode/utils';
import { validateEmailReceivedNodeData } from './EmailReceivedNode/utils';
export const validateFlow = (flow, t, onlyNodes) => {
    const nodes = flow.getNodes();
    const errorMap = {};
    nodes.forEach((node) => {
        const nodeWithUUID = node;
        if (onlyNodes && !onlyNodes.includes(nodeWithUUID.uuid))
            return;
        if (node.type === 'channel') {
            const data = node.data;
            const errors = validateChannelNodeData(data, t);
            if (Object.keys(errors).length > 0)
                errorMap[nodeWithUUID.uuid] = errors;
        }
        if (node.type === 'conditions') {
            const data = node.data;
            const errors = validateConditionsNodeData(data, t);
            if (Object.keys(errors).length > 0)
                errorMap[nodeWithUUID.uuid] = errors;
        }
        if (node.type === 'channel-received') {
            const data = node.data;
            const errors = validateChannelReceivedNodeData(data, t);
            if (Object.keys(errors).length > 0)
                errorMap[nodeWithUUID.uuid] = errors;
        }
        if (node.type === 'email-received') {
            const data = node.data;
            const errors = validateEmailReceivedNodeData(data, t);
            if (Object.keys(errors).length > 0)
                errorMap[nodeWithUUID.uuid] = errors;
        }
    });
    if (Object.keys(errorMap).length > 0)
        return errorMap;
    return null;
};
