import { api } from './';
export const invoicesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getInvoiceList: build.query({
            query: () => ({
                method: 'POST',
                url: '/billing/invoices',
            }),
            providesTags: ['InvoiceList'],
        }),
        createInvoice: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/billing/create-invoice',
                body,
            }),
            invalidatesTags: ['InvoiceList'],
        }),
    }),
});
export const { useGetInvoiceListQuery, useCreateInvoiceMutation } = invoicesApi;
