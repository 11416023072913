import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormPurchaseModalContainer } from '@/forms/FormPurchase/FormPurchaseModalContainer';
import { usePurchasesPage } from './context/usePurchasesPage';
export const PurchasesAddModal = () => {
    const { currency } = useCurrentUser();
    const { data, pagination, modals } = usePurchasesPage();
    return (_jsx(FormPurchaseModalContainer, { isOpen: modals.purchases.addModal.isOpen, onClose: (result) => {
            modals.purchases.addModal.close();
            if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.deleted))
                if (pagination.purchases.offset !== 0)
                    pagination.purchases.resetOffset();
                else
                    data.purchases.refetch();
        }, currency: currency }));
};
