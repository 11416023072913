import { escapedTextToJSON } from '../utils/escapedTextToJSON';
import { jsonToSampleText } from '../utils/jsonToSampleText';
import { useWalletCardFieldEditorVariables } from './useWalletCardFieldEditorVariables';
export const useGenerateSampleText = (clientLevelName = '', currency, renderCurrencySymbol) => {
    const { variables } = useWalletCardFieldEditorVariables();
    const generateSampleText = (text, vars) => {
        if (!(text === null || text === void 0 ? void 0 : text.trim()))
            return text || '';
        const json = escapedTextToJSON(text, variables, vars || []);
        const sample = jsonToSampleText(json, variables, { clientLevelName }, renderCurrencySymbol ? currency : undefined);
        return sample;
    };
    return generateSampleText;
};
