import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { money } from '@/utils/numbers';
import { AccordionContent } from '../AccordionContent';
import styles from '../styles.module.scss';
import { createSpecificationsList } from './createSpecificationsList';
export const tableRows = (currency, data) => {
    if (data) {
        const specificationsList = createSpecificationsList(data);
        const specificationsTableRows = specificationsList === null || specificationsList === void 0 ? void 0 : specificationsList.map((item, index) => {
            const row = {
                id: index + 1,
                cells: [
                    {
                        title: `${index + 1}.`,
                    },
                    {
                        title: (_jsxs("div", Object.assign({ className: styles.productTitle }, { children: [_jsx("span", { children: item.title }), _jsx("span", Object.assign({ className: styles.productSku }, { children: item.sku }))] }))),
                        content: _jsx(AccordionContent, { specificationsList: item.value }),
                    },
                ],
            };
            const addAdditionalColumn = (colName) => {
                if (specificationsList.find(({ additionalItems }) => additionalItems[colName]))
                    row === null || row === void 0 ? void 0 : row.cells.push({ title: item.additionalItems[colName] });
            };
            addAdditionalColumn('бренд');
            addAdditionalColumn('размер');
            addAdditionalColumn('цвет');
            row === null || row === void 0 ? void 0 : row.cells.push({
                title: money({
                    input: String(item.price),
                    withFraction: true,
                    removeFractionZeros: true,
                    currency,
                }) || '',
            });
            return row;
        });
        return specificationsTableRows;
    }
    return [];
};
