export const goToStepWithError = (formState, goToStep, setFocus) => {
    var _a;
    const { errors } = formState;
    if (errors.name ||
        errors.channels ||
        errors.recipientFilters ||
        errors.triggerScheduleDaysOfWeek ||
        errors.triggerScheduleDaysOfMonth ||
        errors.triggerScheduleDate ||
        errors.triggerScheduleTime ||
        errors.triggerEmailReceivedMailingId ||
        errors.triggerFilters ||
        errors.rewards ||
        errors.scheduleDate ||
        errors.scheduleTime ||
        errors.goalDaysTo) {
        goToStep('main');
        const rewardsError = (_a = errors.rewards) === null || _a === void 0 ? void 0 : _a.message;
        if (errors.name)
            setFocus('name');
        else if (errors.triggerScheduleDaysOfWeek)
            setFocus('triggerScheduleDaysOfWeek');
        else if (errors.triggerScheduleDaysOfMonth)
            setFocus('triggerScheduleDaysOfMonth');
        else if (errors.triggerScheduleDate)
            setFocus('triggerScheduleDate');
        else if (errors.triggerScheduleTime)
            setFocus('triggerScheduleTime');
        else if (errors.triggerEmailReceivedMailingId)
            setFocus('triggerEmailReceivedMailingId');
        else if (rewardsError === null || rewardsError === void 0 ? void 0 : rewardsError.includes('bonusesAmount')) {
            const input = document.getElementById('rewards.bonusesAmount');
            input === null || input === void 0 ? void 0 : input.focus();
        }
        else if (rewardsError === null || rewardsError === void 0 ? void 0 : rewardsError.includes('bonusesLifetime')) {
            const input = document.getElementById('rewards.bonusesLifetime');
            input === null || input === void 0 ? void 0 : input.focus();
        }
        else if (rewardsError === null || rewardsError === void 0 ? void 0 : rewardsError.includes('offer')) {
            const input = document.querySelector('[name="rewards.offers.expireInDays"]');
            input === null || input === void 0 ? void 0 : input.focus();
        }
        else if (errors.scheduleDate)
            setFocus('scheduleDate');
        else if (errors.scheduleTime)
            setFocus('scheduleTime');
        else if (errors.goalDaysTo)
            setFocus('goalDaysTo');
        return;
    }
    if (errors.pushText || errors.walletImage || errors.walletPushExpireInDays) {
        goToStep('push');
        if (errors.walletPushExpireInDays)
            setFocus('walletPushExpireInDays');
        return;
    }
    if (errors.viberText ||
        errors.viberImage ||
        errors.viberButton ||
        errors.viberUrl) {
        goToStep('viber');
        if (errors.viberButton)
            setFocus('viberButton');
        else if (errors.viberUrl)
            setFocus('viberUrl');
        return;
    }
    if (errors.emailSubject ||
        errors.emailBody ||
        errors.recommendationCount ||
        errors.emailDesign) {
        goToStep('email');
        if (errors.recommendationCount)
            setFocus('recommendationCount');
        return;
    }
    if (errors.telegramText ||
        errors.telegramImage ||
        errors.telegramButton ||
        errors.telegramUrl) {
        goToStep('telegram');
        if (errors.telegramButton)
            setFocus('telegramButton');
        else if (errors.telegramUrl)
            setFocus('telegramUrl');
        return;
    }
};
