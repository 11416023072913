import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useGenerateSampleText } from '@/components/rich-text/WalletCardFieldEditor/useGenerateSampleText';
import { GoogleWalletCard } from '@/components/wallet-cards/GoogleWalletCard/GoogleWalletCard';
import { fileToDataURL } from '@/utils/fileToDataURL';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { getFontSize } from '../../helpers/FontSizeOptions';
import { focus } from './focus';
export const CardGoogle = () => {
    var _a, _b;
    const { levelName = '', bannerType, background, googleHead, googleBanner, googleFields, backside, barcode, setFormSection, googleCardIsFlipped, setGoogleCardIsFlipped, setIsElementClick, currency, counters, bannerCounter, } = useWalletCardForm();
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [backsideImageUrl, setBacksideImageUrl] = useState('');
    useEffect(() => {
        fileToDataURL(googleHead.logo).then((dataURL) => {
            setHeaderImageUrl(dataURL);
        });
    }, [googleHead.logo]);
    useEffect(() => {
        fileToDataURL(googleBanner.image).then((dataURL) => {
            setBannerImageUrl(dataURL);
        });
    }, [googleBanner.image]);
    useEffect(() => {
        fileToDataURL(googleBanner.backsideImage).then((dataURL) => {
            setBacksideImageUrl(dataURL);
        });
    }, [googleBanner.backsideImage]);
    const onElementClick = (element) => {
        if (['logo', 'header-title', 'title-label', 'title'].includes(element))
            setFormSection('head');
        else if (['banner'].includes(element))
            setFormSection('banner');
        else if (['backside-banner'].includes(element))
            setFormSection('banner');
        else if (element.startsWith('field-'))
            setFormSection('fields');
        else if (element.startsWith('backside-'))
            setFormSection('backside');
        else if (element === 'barcode')
            setFormSection('barcode');
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(true);
        setTimeout(() => {
            if (element.startsWith('field-')) {
                const node = document.getElementById(`google-${element}`);
                focus(node);
                return;
            }
            if (element !== 'backside-banner' && element.startsWith('backside-')) {
                const node = document.getElementById(element);
                focus(node);
                return;
            }
            switch (element) {
                case 'logo': {
                    const node = document.getElementById('google-head-logo');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
                case 'header-title': {
                    const node = document.getElementById('google-head-title');
                    focus(node);
                    break;
                }
                case 'title-label': {
                    const node = document.getElementById('google-head-main-field');
                    focus(node);
                    break;
                }
                case 'title': {
                    const node = document.getElementById('google-head-program-name');
                    focus(node);
                    break;
                }
                case 'banner': {
                    const node = document.getElementById('google-banner');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
                case 'backside-banner': {
                    const node = document.getElementById('google-backside-banner');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
            }
        }, 0);
    };
    const generateSampleText = useGenerateSampleText(levelName, currency);
    useEffect(() => {
        var _a, _b, _c, _d;
        const walletCardActiveType = (_b = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.usr) === null || _b === void 0 ? void 0 : _b.walletCardActiveType;
        const walletCardActiveElement = (_d = (_c = window.history.state) === null || _c === void 0 ? void 0 : _c.usr) === null || _d === void 0 ? void 0 : _d.walletCardActiveElement;
        if (walletCardActiveType === 'google' && walletCardActiveElement) {
            onElementClick(walletCardActiveElement);
            window.history.replaceState({}, document.title);
        }
    }, []);
    return (_jsx(GoogleWalletCard, { background: background, headerImageUrl: headerImageUrl, header: googleHead.title, title: googleHead.programName, fields: {
            'field-1': {
                title: googleFields.field_1_Title,
                value: generateSampleText(googleFields.field_1_Value, googleFields.field_1_Vars),
            },
            'field-2': {
                title: googleFields.field_2_Title,
                value: generateSampleText(googleFields.field_2_Value, googleFields.field_2_Vars),
            },
            'field-3': {
                title: googleFields.field_3_Title,
                value: generateSampleText(googleFields.field_3_Value, googleFields.field_3_Vars),
            },
            'field-4': {
                title: googleFields.field_4_Title,
                value: generateSampleText(googleFields.field_4_Value, googleFields.field_4_Vars),
            },
            'field-5': {
                title: googleFields.field_5_Title,
                value: generateSampleText(googleFields.field_5_Value, googleFields.field_5_Vars),
            },
            'field-6': {
                title: googleFields.field_6_Title,
                value: generateSampleText(googleFields.field_6_Value, googleFields.field_6_Vars),
            },
            'field-7': {
                title: googleFields.field_7_Title,
                value: generateSampleText(googleFields.field_7_Value, googleFields.field_7_Vars),
            },
            'field-8': {
                title: googleFields.field_8_Title,
                value: generateSampleText(googleFields.field_8_Value, googleFields.field_8_Vars),
            },
            'field-9': {
                title: googleFields.field_9_Title,
                value: generateSampleText(googleFields.field_9_Value, googleFields.field_9_Vars),
            },
        }, details: backside.details.map((item) => ({
            title: item.title,
            content: generateSampleText(item.content, item.vars),
        })), contacts: backside.contacts, backsideImageUrl: backsideImageUrl, bannerImageUrl: bannerImageUrl, bannerImageTitle: bannerType === 'with-text'
            ? generateSampleText(googleBanner.title, googleBanner.titleVars)
            : undefined, bannerImageTitleSize: getFontSize(googleBanner.titleSize), bannerImageTitleColor: googleBanner.titleColor, bannerImageDescription: bannerType === 'with-text'
            ? generateSampleText(googleBanner.description, googleBanner.descriptionVars)
            : undefined, bannerImageDescriptionColor: googleBanner.descriptionColor, bannerImageDescriptionSize: getFontSize(googleBanner.descriptionSize), barCode: barcode, onElementClick: onElementClick, flipped: googleCardIsFlipped, onChangeFlipped: setGoogleCardIsFlipped, counter: bannerType === 'with-counter'
            ? counters === null || counters === void 0 ? void 0 : counters.find((counter) => counter.offerId === bannerCounter.offerId)
            : undefined, counterTickImageUrl: bannerCounter.tickImage && 'url' in bannerCounter.tickImage
            ? bannerCounter.tickImage.url
            : bannerCounter.tickImage instanceof File
                ? (_a = bannerCounter.tickImageUploadResponse) === null || _a === void 0 ? void 0 : _a.url
                : undefined, counterBlankImageUrl: bannerCounter.blankImage && 'url' in bannerCounter.blankImage
            ? bannerCounter.blankImage.url
            : bannerCounter.blankImage instanceof File
                ? (_b = bannerCounter.blankImageUploadResponse) === null || _b === void 0 ? void 0 : _b.url
                : undefined }));
};
