var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useLocation, useNavigate } from 'react-router-dom';
import { getErrorMessage } from '@/api/query';
import { useLogoutMutation } from '@/api/query/user';
import { ROUTES } from '@/app/pages/routes';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppDispatch } from '@/hooks/store';
import { userLogout } from '@/store/actions/userLogout';
import { ignore401 } from '@/store/slice/httpError';
export const useLogout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const addToast = useToastContext();
    const [mutate] = useLogoutMutation();
    const logout = () => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(ignore401());
        const result = yield mutate();
        const error = getErrorMessage(result);
        if (error)
            addToast({ type: 'error', message: error });
        else {
            dispatch(userLogout());
            navigate(ROUTES.LOGIN, { state: { from: location }, replace: true });
        }
    });
    return { logout };
};
