import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { externalTooltipHandler } from '@/components/ChartTooltip/ChartTooltip';
import { useChart } from '@/hooks/useChart';
import { largeNumberRound } from '@/utils/numbers';
const getBaseConfig = (leftAxisTickColor, rightAxisTickColor, hasLeftAxisGrid = true, hasRightAxisGrid = true, hasXAxisGrid = true) => ({
    type: 'line',
    options: {
        elements: {
            point: { radius: 0 },
            line: { tension: 0.1 },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            leftAxis: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    color: leftAxisTickColor,
                    callback: (value) => {
                        return largeNumberRound(Number(value), {
                            thousandThreshold: 1000,
                        });
                    },
                },
                grid: { display: hasLeftAxisGrid },
            },
            rightAxis: {
                type: 'linear',
                display: true,
                position: 'right',
                ticks: {
                    color: rightAxisTickColor,
                    callback: (value) => {
                        return largeNumberRound(Number(value), {
                            thousandThreshold: 1000,
                        });
                    },
                },
                grid: { display: hasRightAxisGrid },
            },
            x: {
                grid: { display: hasXAxisGrid },
            },
        },
        plugins: {
            legend: { display: false },
            crosshair: {
                line: { color: '#9B9B9B' },
                snap: { enabled: true },
            },
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
            // tooltip: {
            //   callbacks: {
            //     label(tooltipItem) {
            //       const label = tooltipItem.dataset.label || ''
            //       const value = largeNumberRound(Number(tooltipItem.raw as number), {
            //         thousandThreshold: 1000,
            //       })
            //       return `${label}: ${value}`
            //     },
            //   },
            // },
        },
    },
});
export const TwoAxisLineChart = memo(({ labels, leftAxis, rightAxis, hasLeftAxisGrid = true, hasRightAxisGrid = true, hasXAxisGrid = true, }) => {
    const canvas = useChart(Object.assign(Object.assign({}, getBaseConfig(leftAxis.tickColor, rightAxis.tickColor, hasLeftAxisGrid, hasRightAxisGrid, hasXAxisGrid)), { data: {
            labels,
            datasets: [
                {
                    yAxisID: 'leftAxis',
                    label: leftAxis.label,
                    data: leftAxis.data,
                    borderColor: leftAxis.color,
                    backgroundColor: leftAxis.color,
                },
                {
                    yAxisID: 'rightAxis',
                    label: rightAxis.label,
                    data: rightAxis.data,
                    borderColor: rightAxis.color,
                    backgroundColor: rightAxis.color,
                },
            ],
        } }));
    return _jsx("canvas", { ref: canvas });
});
