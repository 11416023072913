var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCalculateMailingMutation, } from '@/api/query/mailings';
export const useCalculate = () => {
    const [calculate] = useCalculateMailingMutation();
    return (data) => __awaiter(void 0, void 0, void 0, function* () {
        const payload = {
            type: 1,
            recipients: {
                all: true,
                segments: [],
                filters: { conditions: {}, configuration: [] },
            },
            excludeRecipients: { segments: [] },
            pushText: data.pushText,
            viberText: data.viberText,
            smsText: data.smsText,
            telegramText: data.telegramText,
            channels: {
                push: !!data.pushText,
                viber: !!data.viberText,
                sms: !!data.smsText,
                telegram: !!data.telegramText,
                email: false,
            },
        };
        const result = yield calculate(payload);
        if ('data' in result)
            return result.data;
        return {};
    });
};
