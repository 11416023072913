import { convertFormValuesToWalletCard } from '../WalletCardsForm/convertFormValuesToWalletCard';
import { useDefaultValues } from '../WalletCardsForm/useDefaultValues';
export const useDefaultActiveWalletCard = (level) => {
    const getDefaultValues = useDefaultValues();
    const defaultCardConfiguration = convertFormValuesToWalletCard(getDefaultValues(level));
    return {
        id: -1,
        level,
        configuration: defaultCardConfiguration,
    };
};
