var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Help } from '@/icons/Help';
import styles from './styles.module.scss';
export const HelpTooltip = memo((_a) => {
    var { className, children, Icon, tooltipClassName, isSmall = false, hasCurrentColor = false, hasLightIcon = false, title, html } = _a, props = __rest(_a, ["className", "children", "Icon", "tooltipClassName", "isSmall", "hasCurrentColor", "hasLightIcon", "title", "html"]);
    const [isActive, setIsActive] = useState(false);
    return (_jsxs("span", Object.assign({ className: styles.noWrap }, { children: [children && _jsx("span", Object.assign({ className: styles.wrap }, { children: children })), (title || html) && (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Tooltip, Object.assign({ className: classNames(styles.tooltip, { [styles.small]: isSmall }, tooltipClassName), popperClassName: styles.popper, title: title, html: html, onOpen: () => setIsActive(true), onClose: () => setIsActive(false) }, props, { children: _jsx("span", Object.assign({ className: classNames(styles.help, {
                                [styles.active]: isActive,
                                [styles.currentColor]: hasCurrentColor,
                                [styles.light]: hasLightIcon,
                            }, className) }, { children: Icon || _jsx(Help, {}) })) }))] }))] })));
});
