var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteGeoPushMutation } from '@/api/query/wallet-cards';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useDeleteGeoPush = ({ onSuccess, } = {}) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteGeoPush] = useDeleteGeoPushMutation();
    return (id) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmTitle = t('Удалить геопуш?');
        const confirmAgreeLabel = t('Удалить');
        const successMessage = t('Геопуш удален');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deleteGeoPush({ id });
        const errorMessage = getErrorMessage(result);
        if (errorMessage)
            addToast({ type: 'error', message: errorMessage });
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
    });
};
