import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Input } from '@/components/Input/Input';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import { TShirt } from '@/icons/TShirt';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const EmailFormRecommendations = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const { brand } = useCurrentUser();
    const recomCount = ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.recommendationCount) || 0;
    const includeRecommendations = (_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _b === void 0 ? void 0 : _b.includeRecommendations;
    const hasRecommendationsFeature = (_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.features) === null || _d === void 0 ? void 0 : _d.recommendations;
    const shouldDisplayForm = hasRecommendationsFeature && includeRecommendations;
    const recommendationTypeOptions = [
        { value: 1, label: t('NEW_PRODUCTS', { count: recomCount }) },
        { value: 2, label: t('LAST_VIEWED_PRODUCTS', { count: recomCount }) },
        { value: 3, label: t('BESTSELLERS', { count: recomCount }) },
    ];
    const handleUpdateIncludeRecommendations = (e) => {
        selectedNode.update({
            email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { includeRecommendations: e.target.checked }),
        });
    };
    const handleUpdateRecommendationCount = (e) => {
        var _a;
        const newValue = Number(e.target.value);
        if (!newValue)
            e.target.value = '1';
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.recommendationCount) !== newValue)
            selectedNode.update({
                email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { recommendationCount: newValue || 1 }),
            });
    };
    const handleUpdateRecommendationType = (recommendationType) => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.recommendationType) !== (recommendationType === null || recommendationType === void 0 ? void 0 : recommendationType.value))
            selectedNode.update({
                email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { recommendationType: recommendationType === null || recommendationType === void 0 ? void 0 : recommendationType.value }),
            });
    };
    const lockIcon = (_jsx(Tooltip, Object.assign({ title: _jsx("span", Object.assign({ className: styles.tooltip }, { children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') })), placement: 'right' }, { children: _jsx("span", Object.assign({ className: styles.lock }, { children: _jsx(Lock, {}) })) })));
    return (_jsxs("div", Object.assign({ className: styles.emailBlockPanel }, { children: [_jsxs("div", Object.assign({ className: styles.emailBlockCheckboxWrapper }, { children: [_jsxs(Checkbox, Object.assign({ className: classNames(styles.recommendationCheckbox, {
                            [styles.disabled]: !hasRecommendationsFeature,
                        }), align: 'center', checked: hasRecommendationsFeature ? includeRecommendations : false, disabled: !hasRecommendationsFeature, onChange: handleUpdateIncludeRecommendations }, { children: [_jsx(TShirt, { className: styles.icon }), t('Товарные рекомендации')] })), !hasRecommendationsFeature ? (lockIcon) : (_jsx(HelpTooltip, { className: styles.emailBlockTooltip, title: t('EMAIL_INCLUDE_ITEMS_LIST'), placement: 'right' }))] })), shouldDisplayForm && (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.emailBlockTitle }, { children: t('Показывать в блоке') })), _jsxs("div", Object.assign({ className: styles.recommendations }, { children: [_jsx("div", Object.assign({ className: styles.recommendationCountInput }, { children: _jsx(Input, { defaultValue: (_e = selectedNodeData.email) === null || _e === void 0 ? void 0 : _e.recommendationCount, onBlur: handleUpdateRecommendationCount, type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true }) })), _jsx("div", Object.assign({ className: styles.recommendationTypeInput }, { children: _jsx(Autocomplete, { value: recommendationTypeOptions.find((item) => { var _a; return item.value === ((_a = selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.recommendationType); }) || null, onChange: handleUpdateRecommendationType, options: recommendationTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }) }))] })), _jsx("div", Object.assign({ className: styles.emailBlockHelp }, { children: t('Добавьте блок в тело письма в редакторе') }))] }))] })));
};
