import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT } from '../constants';
import styles from '../styles.module.scss';
export const FormOfferType = ({ labelWidth, hasDiscountOfferFeature, currency, onOfferTypeChange, }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, watch } = useFormContext();
    const { errors } = formState;
    const offerType = watch('type');
    const discountAmount = watch('discountAmount');
    const discountType = watch('discountType');
    const type = watch('type');
    const isDiscountLimit = discountType === DISCOUNT_TYPE_PERCENT &&
        (type === 'growing_discount' || type === 'discount');
    // Тип вознаграждения скидка(discount), растущая скидка(growing_discount) - проценты ограничены 100%.
    // в остальных случаях использования процентов и рублей - огрничение 99999999
    const validate = (value) => {
        if (!value)
            return 'required';
        if (value < 0)
            return 'out of range';
        if (isDiscountLimit && value > 100)
            return 'out of range';
        if (!isDiscountLimit && value > 99999999)
            return 'out of range';
    };
    const offerTypeOptions = [
        {
            value: 'discount',
            label: t('скидка'),
            isActive: hasDiscountOfferFeature,
        },
        {
            value: 'growing_discount',
            label: t('растущая скидка'),
            isActive: hasDiscountOfferFeature,
        },
        {
            value: 'bonus',
            label: t('бонусы'),
            isActive: true,
        },
        {
            value: 'fixed_price',
            label: t('фиксированная цена'),
            isActive: hasDiscountOfferFeature,
        },
        {
            value: 'free_product',
            label: t('бесплатный товар'),
            isActive: true,
        },
    ].filter((item) => item.isActive);
    const discountTypeOptions = [
        {
            value: DISCOUNT_TYPE_FIXED,
            label: offerType === 'bonus'
                ? t('BONUSES_LABEL', { count: discountAmount || 0 })
                : currency,
        },
        {
            value: DISCOUNT_TYPE_PERCENT,
            label: '%',
        },
    ];
    return (_jsx(FormFieldRow, Object.assign({ label: t('Тип'), labelFor: 'type', labelWidth: labelWidth, isRequired: true, className: styles.typeFieldRow }, { children: _jsxs("div", Object.assign({ className: styles.typeRow }, { children: [_jsx("div", Object.assign({ className: styles.typeSelect }, { children: _jsx(Controller, { name: 'type', control: control, render: ({ field }) => (_createElement(Autocomplete, Object.assign({}, field, { key: Boolean(hasDiscountOfferFeature).toString(), id: 'type', value: offerTypeOptions.find((item) => item.value === field.value) ||
                                null, onChange: (offerType) => {
                                setValue('type', offerType === null || offerType === void 0 ? void 0 : offerType.value, {
                                    shouldDirty: true,
                                });
                                onOfferTypeChange === null || onOfferTypeChange === void 0 ? void 0 : onOfferTypeChange(offerType === null || offerType === void 0 ? void 0 : offerType.value);
                            }, options: offerTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, asSelect: true, hasResetButton: false }))) }) })), offerType !== 'growing_discount' && offerType !== 'free_product' && (_jsx(Controller, { name: 'discountAmount', control: control, rules: { validate }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.discountInput }, { children: _jsx(Input, Object.assign({}, field, { id: 'discountAmount', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.discountAmount ||
                                !!(field.value && validate(field.value)), preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: offerType === 'fixed_price' ? currency : undefined })) }))) })), (offerType === 'discount' || offerType === 'bonus') && (_jsx(Controller, { name: 'discountType', control: control, render: ({ field }) => (_createElement(Autocomplete, Object.assign({}, field, { key: offerType, id: 'discountType', className: styles.discountTypeSelect, value: discountTypeOptions.find((item) => item.value === field.value) || null, onChange: (discountType) => {
                            setValue('discountType', discountType === null || discountType === void 0 ? void 0 : discountType.value, {
                                shouldDirty: true,
                            });
                        }, options: discountTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, minListWidth: offerType === 'bonus' ? 100 : undefined }))) }))] })) })));
};
