import { createSlice } from '@reduxjs/toolkit';
const headerSlice = createSlice({
    name: 'header',
    initialState: { hidden: false },
    reducers: {
        hideHeader(state) {
            state.hidden = true;
        },
        showHeader(state) {
            state.hidden = false;
        },
    },
});
export default headerSlice.reducer;
export const { hideHeader, showHeader } = headerSlice.actions;
