var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { NotificationOfClosureModalForForm } from '@/components/NotificationOfClosureModalForForm/NotificationOfClosureModalForForm';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { FormRole } from './FormRole';
import styles from './styles.module.scss';
import { defaultValuesFormater } from './utils/defaultValuesFormater';
import { roleDataSubmitFormater } from './utils/roleDataSubmitFormater';
export const FormRoleModal = memo((_a) => {
    var { isOpen, isLoading, isEdit, isView, id = 'role-form', initData, onClose, onSubmit, onDelete } = _a, props = __rest(_a, ["isOpen", "isLoading", "isEdit", "isView", "id", "initData", "onClose", "onSubmit", "onDelete"]);
    const { t } = useTranslation();
    const [hasFormChanged, setHasFormChanged] = useState(false);
    // Если на сабмите не было пермишенов, то показываем предупреждение
    // Если есть предупреждение и было изменено поле после этого то предупреждение удаляется
    const [formHasPermissions, setFormHasPermissions] = useState(false);
    const [currentNameOfRole, setCurrentNameOfRole] = useState('');
    const userHasAccessTo = useUserPermissions();
    const userHasOperatorsManage = userHasAccessTo([
        'OPERATORS_ADMIN_MANAGE',
        'OPERATORS_MANAGE',
    ]);
    const handleSubmit = (data, sections) => {
        const formattedData = roleDataSubmitFormater(data, isView, sections);
        if (!formattedData.permissions.length)
            return setFormHasPermissions(true);
        onSubmit(Object.assign({ id: initData === null || initData === void 0 ? void 0 : initData.id }, formattedData), isEdit);
        setFormHasPermissions(false);
        setCurrentNameOfRole('');
    };
    const modalTitle = isView
        ? t('Просмотр роли')
        : isEdit
            ? t('Редактирование роли')
            : t('Создание роли');
    const defaultValues = defaultValuesFormater(initData);
    return (_jsx(Modal, Object.assign({ isOnMountFocus: isView, isOpen: isOpen, isNotificationOfClosureOpen: !isView && hasFormChanged, onClose: () => {
            onClose && onClose();
            setFormHasPermissions(false);
        }, title: _jsx("div", Object.assign({ className: styles.modalTitle }, { children: currentNameOfRole || modalTitle })), size: '840', isForm: true, hasNoPadding: true, className: styles.modalWrapper, bodyClassName: styles.modalBody, footer: _jsxs(_Fragment, { children: [formHasPermissions && (_jsx(FormFieldRow, Object.assign({ asErrorMessage: true, asModalFooter: true }, { children: 'Добавьте хотя бы один доступ' }))), _jsxs("div", Object.assign({ className: styles.footer }, { children: [(isEdit || isView) && (_jsx("span", Object.assign({ className: styles.userCounter }, { children: (initData === null || initData === void 0 ? void 0 : initData.operatorsCount)
                                ? t('ROLE_FORM_MODAL_COUNTER', {
                                    count: initData === null || initData === void 0 ? void 0 : initData.operatorsCount,
                                    amount: initData === null || initData === void 0 ? void 0 : initData.operatorsCount,
                                })
                                : t('Роль ни на кого не назначена') }))), !isView && (_jsx(_Fragment, { children: isEdit ? (_jsxs(_Fragment, { children: [(initData === null || initData === void 0 ? void 0 : initData.operatorsCount) === 0 && (_jsx(Tooltip, Object.assign({ title: !userHasOperatorsManage &&
                                            t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ onClick: onDelete, theme: 'danger', disabled: !userHasOperatorsManage || isLoading, size: 'large', type: 'button' }, { children: t('Удалить роль') })) }))), _jsx(Tooltip, Object.assign({ title: !userHasOperatorsManage &&
                                            t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', disabled: !userHasOperatorsManage ||
                                                isLoading ||
                                                !hasFormChanged, size: 'extra-large' }, { children: t('Сохранить') })) }))] })) : (_jsx(Tooltip, Object.assign({ title: !userHasOperatorsManage &&
                                    t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', disabled: !userHasOperatorsManage || isLoading, size: 'extra-large' }, { children: t('Создать роль') })) }))) }))] }))] }), notificationOfClosure: _jsx(NotificationOfClosureModalForForm, {}) }, { children: _jsx(FormRole, Object.assign({ isView: isView, setHasFormChanged: setHasFormChanged, id: id, onSubmit: handleSubmit, setFormHasPermissions: setFormHasPermissions, setCurrentNameOfRole: setCurrentNameOfRole, defaultValues: defaultValues }, props), defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.name) })));
});
