import { useEffect } from 'react';
export const AutoFocusPlugin = ({ isEnabled = false, }) => {
    useEffect(() => {
        if (!isEnabled)
            return;
        setTimeout(() => {
            ;
            document.querySelector('.lexical-editor').focus();
        }, 1);
    }, []);
    return null;
};
