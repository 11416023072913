import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const FormCounterPeriod = () => {
    const { t } = useTranslation();
    const { control, setValue, formState, watch } = useFormContext();
    const { errors } = formState;
    const periodType = watch('periodType');
    const periodValue = watch('periodValue');
    const periodValueInput = (_jsx(Controller, { name: 'periodValue', control: control, rules: {
            required: periodType === 'period'
                ? { value: true, message: t('FIELD_REQUIRED_ERROR') }
                : undefined,
            min: periodType === 'period'
                ? { value: 1, message: t('MIN_ERROR_MESSAGE', { amount: 1 }) }
                : undefined,
        }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'periodValue', type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.periodValue, disabled: periodType === 'any' }))) }));
    const periodUnitOptions = [
        {
            id: 'day',
            label: t('IN_DAYS', { count: Number(periodValue) }),
        },
        {
            id: 'month',
            label: t('IN_MONTHS', { count: Number(periodValue) }),
        },
    ];
    const periodUnitSelect = (_jsx(Controller, { name: 'periodUnit', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'periodUnit', value: periodUnitOptions.find((item) => item.id === field.value) || null, onChange: (item) => {
                setValue('periodUnit', item === null || item === void 0 ? void 0 : item.id);
            }, options: periodUnitOptions, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, disabled: periodType === 'any', disablePortal: true }))) }));
    return (_jsxs("div", Object.assign({ className: styles.period }, { children: [_jsx("div", Object.assign({ className: styles.periodValueInput }, { children: periodValueInput })), _jsx("div", Object.assign({ className: styles.periodUnitSelect }, { children: periodUnitSelect }))] })));
};
