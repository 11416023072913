import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { highlightText } from '@/utils/highlightText';
import styles from '../styles.module.scss';
import { AutocompleteInput } from '../variants/AutocompleteInput';
export const AutocompletePurchaseItems = memo(({ id, value, onChange, getItems, hasError, shouldLoadInitialData }) => {
    const { t } = useTranslation();
    const getOptionKey = (option) => (option === null || option === void 0 ? void 0 : option.id) || 0;
    const getOptionLabel = (option) => (option === null || option === void 0 ? void 0 : option.title) || '';
    const renderOption = (option, searchText) => {
        if (option) {
            const title = highlightText(option.title, searchText);
            const sku = highlightText(option.sku, searchText);
            return (_jsxs("div", Object.assign({ className: styles.purchaseItem }, { children: [title ? (_jsx("span", { dangerouslySetInnerHTML: { __html: title } })) : (_jsx("span", Object.assign({ className: styles.placeholder }, { children: t('Без наименования') }))), sku && _jsx("small", { dangerouslySetInnerHTML: { __html: sku } })] })));
        }
        return null;
    };
    return (_jsx(AutocompleteInput, { className: styles.purchaseItemsInput, id: id, value: value, onChange: onChange, options: getItems, getOptionKey: getOptionKey, getOptionLabel: getOptionLabel, renderOption: renderOption, placeholder: t('выберите товар'), hasError: hasError, shouldLoadInitialData: shouldLoadInitialData, hasResetButton: false }));
});
