import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwitchBrandModal } from '@/components/SwitchBrandModal/SwitchBrandModal';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useCurrentUser } from '../../useCurrentUser';
import { AppSidebar } from './AppSidebar';
import { useLogout } from './useLogout';
import { useSidebarState } from './useSidebarState';
import { useSwitchBrand } from './useSwitchBrand';
export const AppSidebarContainer = memo(() => {
    const { i18n } = useTranslation();
    const { user, brand } = useCurrentUser();
    const { isCollapsed, isHidden, toggleCollapseSidebar, hideSidebar, toggleHiddenSidebar, } = useSidebarState();
    const { logout } = useLogout();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (window.screen.width < 744) {
            toggleCollapseSidebar();
            hideSidebar();
            return;
        }
        if (window.screen.width > 1280) {
            toggleCollapseSidebar();
            return;
        }
    }, []);
    useMediaQuery('(max-width: 744px)', (mql) => {
        if (!mql.matches && isHidden)
            toggleCollapseSidebar();
        if (mql.matches && !isHidden)
            toggleHiddenSidebar();
    }, [isHidden]);
    useMediaQuery('(max-width: 1280px)', (mql) => {
        if ((mql.matches && !isCollapsed) || (isCollapsed && !mql.matches))
            toggleCollapseSidebar();
    }, [isCollapsed]);
    const handleChangeRoute = (route) => {
        if (window.screen.width < 744)
            hideSidebar();
        navigate(route);
    };
    const handleSwitchLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    const { isOpen, openModal, closeModal, brands, switchBrand, isSwitchingBrand, } = useSwitchBrand();
    if (!user || !brand)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(AppSidebar, { currentRoute: location.pathname, user: user, brand: brand.brand, isCollapsed: isCollapsed, isHidden: isHidden, onChangeRoute: handleChangeRoute, onLogout: logout, onSwitchLanguage: handleSwitchLanguage, onSwitchBrand: openModal, onToggle: toggleCollapseSidebar, onToggleHidden: toggleHiddenSidebar }), _jsx(SwitchBrandModal, { isOpen: isOpen, onClose: closeModal, brands: brands, onSelectBrand: switchBrand, currentBrandGlobalKey: brand.brand.globalKey, isLoading: isSwitchingBrand })] }));
});
