import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Fragment, memo } from 'react';
import { useState } from 'react';
import { CloseBackside } from '@/icons/wallet-card/apple/CloseBackside';
import styles from './styles.module.scss';
export const AccordionSublistTableRow = memo(({ sublistItemLeftOffset, row, headItems }) => {
    var _a;
    const [isOpenedItem, setIsOpenedItem] = useState(false);
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.open]: isOpenedItem && !row.notExpand,
            [styles.wrapperExpand]: !row.notExpand,
        }) }, { children: [_jsx("div", Object.assign({ className: styles.row, onClick: (event) => {
                    event.stopPropagation();
                    if (!row.notExpand)
                        setIsOpenedItem((prevValue) => !prevValue);
                } }, { children: row.cells.map((cell, index) => {
                    var _a, _b, _c;
                    return (_jsxs("div", Object.assign({ style: {
                            width: (_a = headItems[index]) === null || _a === void 0 ? void 0 : _a.width,
                            textAlign: (_b = headItems[index]) === null || _b === void 0 ? void 0 : _b.align,
                        }, className: classNames(styles.cell, cell === null || cell === void 0 ? void 0 : cell.className, (_c = headItems[index]) === null || _c === void 0 ? void 0 : _c.className, {
                            [styles.title]: index === 0,
                        }) }, { children: [cell.title, index === 0 && (_jsx(CloseBackside, { size: 16, color: 'currentColor', className: classNames(styles.icon, {
                                    [styles.expandingIcon]: isOpenedItem,
                                    [styles.notExpandingIcon]: !isOpenedItem,
                                }) }))] }), 'cell' + index));
                }) })), isOpenedItem && _jsx("div", { className: styles.divider }), ((_a = row.sublist) === null || _a === void 0 ? void 0 : _a.length) &&
                row.sublist.map((sublistItem, index) => {
                    var _a;
                    return (_jsxs(Fragment, { children: [_jsx("div", Object.assign({ style: {
                                    marginLeft: sublistItemLeftOffset,
                                }, className: classNames(styles.cellContent, styles.row, {
                                    [styles.expanded]: isOpenedItem,
                                }) }, { children: sublistItem.cells.map((cell, index) => {
                                    var _a, _b, _c;
                                    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ style: {
                                                width: (_a = headItems[index]) === null || _a === void 0 ? void 0 : _a.width,
                                                textAlign: (_b = headItems[index]) === null || _b === void 0 ? void 0 : _b.align,
                                            }, className: classNames(styles.cell, cell === null || cell === void 0 ? void 0 : cell.className, (_c = headItems[index]) === null || _c === void 0 ? void 0 : _c.className) }, { children: cell.title }), 'cell' + index) }));
                                }) })), isOpenedItem &&
                                row.sublist &&
                                ((_a = row.sublist) === null || _a === void 0 ? void 0 : _a.length) - 1 !== index && (_jsx("div", { className: styles.divider, style: {
                                    marginLeft: sublistItemLeftOffset,
                                } }))] }, 'content' + index));
                })] }), row.id));
});
