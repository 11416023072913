var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useAddSegmentMutation, useSetSegmentMutation, } from '@/api/query/segment';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { compileFilters } from '@/services/filters/compileFilters';
export const useSaveSegment = ({ onSuccess, }) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [addSegment, { isLoading: isAdding }] = useAddSegmentMutation();
    const [setSegment, { isLoading: isSetting }] = useSetSegmentMutation();
    const saveSegment = (segment, filters, showWarning = true) => __awaiter(void 0, void 0, void 0, function* () {
        const successMessage = t('Сегмент сохранен');
        if (segment.id && showWarning) {
            const confirmTitle = t('Перезаписать фильтры сегмента {{segmentName}}?', {
                segmentName: segment.name,
            });
            const confirmAgreeLabel = t('Перезаписать');
            const yes = yield confirm({
                confirmation: confirmTitle,
                agreeLabel: confirmAgreeLabel,
                isDanger: true,
            });
            if (!yes)
                return;
        }
        const payload = {
            name: segment.name || '',
            color: segment.color || '',
            conditions: compileFilters(filters || []),
            configuration: filters || [],
        };
        const result = yield (segment.id
            ? setSegment(Object.assign({ id: segment.id }, payload))
            : addSegment(payload));
        if ('error' in result)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess(result.data);
        }
    });
    return { saveSegment, isAdding, isSetting };
};
