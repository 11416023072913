import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const FormCounterRequiredValue = ({ labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.row, styles.requiredValueRow), label: t('Купить'), labelFor: 'requiredValue', labelWidth: labelWidth, labelClassName: styles.label, controlSize: '100', help: (_a = errors.requiredValue) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.requiredValue }, { children: _jsx(Controller, { name: 'requiredValue', control: control, rules: {
                required: {
                    value: true,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                min: {
                    value: 1,
                    message: t('MIN_ERROR_MESSAGE', { amount: 1 }),
                },
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'requiredValue', rightText: t('TIMES', { count: Number(field.value) }), type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.requiredValue }))) }) })));
};
