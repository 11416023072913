import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
export const FormMailingViberText = ({ length = 0, hasWalletFeature, labelWidth, currency, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, watch, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const isRequired = channels === null || channels === void 0 ? void 0 : channels.includes('viber');
    const validateTrimValue = (value) => {
        return isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    };
    const editorContext = {
        channel: 'viber',
        triggerType: watch('triggerType'),
        rewards: watch('rewards'),
        hasWalletFeature,
    };
    return (_jsx(FormFieldRow, Object.assign({ label: t('Сообщение'), labelFor: 'viberText', labelWidth: labelWidth, controlSize: '300', help: ((_a = errors.viberText) === null || _a === void 0 ? void 0 : _a.message) || t('Длина:') + ' ' + length + '/1000', helpSize: '300', hasError: !!errors.viberText, tooltip: t('Любые символы юникода (кириллица тоже). Рекомендуемая длина < 400 символов'), tooltipPlacement: 'top', isRequired: isRequired }, { children: _jsx(Controller, { name: 'viberText', control: control, rules: {
                validate: { required: validateTrimValue },
            }, render: ({ field }) => (_jsx(MessageEditor, { initialValue: field.value || '', onChange: (text) => {
                    setValue('viberText', text, {
                        shouldValidate: isSubmitted,
                        shouldDirty: true,
                    });
                }, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasError: !!errors.viberText, hasAutoFocus: true })) }) })));
};
