/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchStatus } from '@/store/slice';
export const processSimpleThunk = ({ builder, stateKey, thunk, }) => {
    builder.addCase(thunk.pending, (state) => {
        state[stateKey].status = FetchStatus.Loading;
    });
    builder.addCase(thunk.fulfilled, (state, action) => {
        state[stateKey].status = FetchStatus.Finished;
        state[stateKey].data = action.payload;
    });
    builder.addCase(thunk.rejected, (state, action) => {
        const { error } = action;
        state[stateKey].status = FetchStatus.Error;
        state[stateKey].error = error;
    });
};
export const processSimpleThunks = (builder, thunks) => {
    Object.entries(thunks).forEach(([stateKey, thunk]) => {
        processSimpleThunk({ builder, stateKey, thunk });
    });
};
export const processPaginatedThunk = ({ builder, stateKey, thunk, sortKey, }) => {
    builder.addCase(thunk.pending, (state) => {
        state[stateKey].status = FetchStatus.Loading;
    });
    builder.addCase(thunk.fulfilled, (state, action) => {
        var _a, _b, _c, _d, _e;
        const { meta } = action;
        const { limit, offset, ids } = meta.arg;
        const slice = state[stateKey];
        slice.status = FetchStatus.Finished;
        // запоминаем текущее смещение
        slice.offset = offset;
        // если есть еще данные, мы должны показать пользователю кнопку "Загрузить еще"
        slice.hasMoreData = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.moreResults;
        // если это первая страница, просто кладем ее в стор
        if (!offset)
            slice.data = (_b = action.payload) === null || _b === void 0 ? void 0 : _b.results;
        // если же это не первая страница, то кладем ее в стор по смещению
        else
            (_c = slice.data) === null || _c === void 0 ? void 0 : _c.splice(((_d = slice.data) === null || _d === void 0 ? void 0 : _d.length) || offset, limit, ...(((_e = action.payload) === null || _e === void 0 ? void 0 : _e.results) || []));
        // если были выбраны какие-то элементы списка, нужно двинуть их вверх
        if (ids && slice.data)
            slice.data.sort((a, b) => ids.indexOf(b[sortKey]) - ids.indexOf(a[sortKey]));
    });
    builder.addCase(thunk.rejected, (state, action) => {
        const { error } = action;
        state[stateKey].status = FetchStatus.Error;
        state[stateKey].error = error;
    });
};
export const processPaginatedThunks = (builder, thunks) => {
    Object.entries(thunks).forEach(([stateKey, { thunk, sortKey }]) => {
        processPaginatedThunk({ builder, stateKey, thunk, sortKey });
    });
};
