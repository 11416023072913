import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const PushFormMessagingApp = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const { brand } = useCurrentUser();
    const messagingApps = ((_b = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.mailing) === null || _b === void 0 ? void 0 : _b.messagingApps) || [];
    const shouldDisplay = ((_d = (_c = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _c === void 0 ? void 0 : _c.features) === null || _d === void 0 ? void 0 : _d.mobilePush) && messagingApps.length > 1;
    const handleUpdateMessagingApp = (app) => {
        selectedNode.update({
            push: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push), { messagingAppId: app === null || app === void 0 ? void 0 : app.id }),
        });
    };
    if (!shouldDisplay)
        return null;
    return (_jsxs("div", { children: [_jsx("label", Object.assign({ className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-messaging-app' }, { children: t('Приложение') })), _jsx(Autocomplete, { id: 'channel-form-messaging-app', value: messagingApps.find((item) => { var _a; return item.id === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.push) === null || _a === void 0 ? void 0 : _a.messagingAppId); }) || null, onChange: handleUpdateMessagingApp, options: messagingApps, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true })] }));
};
