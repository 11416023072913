var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import styles from './styles.module.scss';
export const FormGeoPushFooter = ({ isEdit, onDelete, isSubmitting, setActive, }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext();
    const [isLoading, setIsLoading] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasWalletCardsManage = userHasAccessTo('WALLET_CARDS_MANAGE');
    const checked = watch('isActive');
    const handleChange = (checked) => __awaiter(void 0, void 0, void 0, function* () {
        let ok = true;
        if (setActive) {
            setIsLoading(true);
            ok = yield setActive(checked);
            setIsLoading(false);
        }
        if (ok)
            setValue('isActive', checked);
    });
    return (_jsxs("div", Object.assign({ className: styles.actions }, { children: [_jsxs("div", Object.assign({ className: styles.toolbar }, { children: [isEdit && (_jsx(TrashButton, { tooltip: userHasWalletCardsManage
                            ? t('Удалить геопуш')
                            : t('Недоступно для вашей роли'), onClick: onDelete, disabled: !userHasWalletCardsManage })), _jsx(Tooltip, Object.assign({ title: !userHasWalletCardsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx("div", { children: _jsx(Switch, { label: checked ? t('Включен') : t('Выключен'), checked: checked, onChange: handleChange, disabled: !userHasWalletCardsManage || isLoading }) }) }))] })), _jsx(Tooltip, Object.assign({ title: !userHasWalletCardsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ type: 'submit', theme: 'primary', disabled: !userHasWalletCardsManage || isSubmitting, size: 'large' }, { children: t('Сохранить') })) }))] })));
};
