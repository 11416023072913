import { $getSelection, $isRangeSelection, createCommand, } from 'lexical';
export const INSERT_EMOJI = createCommand();
function insertEmoji(emoji, editor) {
    editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection))
            selection.insertText(emoji);
    });
    return true;
}
export default insertEmoji;
