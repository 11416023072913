import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { Panel } from '@/components/Panel2/Panel';
import { WalletCardContainer } from '@/components/wallet-cards/WalletCardContainer/WalletCardContainer';
import { FormGeoPush } from '@/forms/FormGeoPush/FormGeoPush';
import { ROUTES } from '../routes';
import { ActiveCard } from '../WalletCards/ActiveCard';
import { useDeleteGeoPush } from '../WalletCards/useDeleteGeoPush';
import { WalletCardPublish } from '../WalletCards/WalletCardPublish';
import { useCardType } from './useCardType';
import { useGeoPushData } from './useGeoPushData';
import { useGeoPushId } from './useGeoPushId';
import { useSaveGeoPush } from './useSaveGeoPush';
import { useSetGeoPushActive } from './useSetGeoPushActive';
export const WalletGeoPushForm = ({ defaultGeoPushId, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand, timeZone } = useCurrentUser();
    const { cardType, setCardType } = useCardType();
    const goToList = () => navigate(ROUTES.WALLET_CARDS, {
        state: { walletCardsPageActiveTab: 'geo-push-table' },
    });
    const { geoPushId, cloneId } = useGeoPushId(defaultGeoPushId);
    const { defaultValues, isLoading } = useGeoPushData(cloneId || geoPushId);
    const setGeoPushActive = useSetGeoPushActive(geoPushId);
    const deleteGeoPush = useDeleteGeoPush({ onSuccess: goToList });
    const { saveGeoPush, isSubmitting, error } = useSaveGeoPush({
        id: geoPushId,
        onSuccess: goToList,
    });
    const [cardMessage, setCardMessage] = useState();
    if (!brand || isLoading)
        return null;
    const isShowAddressField = ['br', 'ru', 'kz'].includes(brand.brand.defaultCountry);
    return (_jsx(Container, { children: _jsx(WalletCardContainer, Object.assign({ cardType: cardType, onChangeCardType: setCardType, card: _jsx(ActiveCard, { cardType: cardType, notifyMessageText: cardMessage !== null && cardMessage !== void 0 ? cardMessage : ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.message) || t('GEO_PUSH_MESSAGE_PLACEHOLDER')) }), cardFooter: _jsx(WalletCardPublish, {}) }, { children: _jsx(Panel, Object.assign({ title: t('Геопуш'), size: 'full', onClose: goToList }, { children: _jsx(FormGeoPush, { isEdit: !!geoPushId, isShowAddressField: isShowAddressField, formValues: defaultValues, onSubmit: saveGeoPush, isSubmitting: isSubmitting, error: error, timeZone: timeZone, onDelete: () => {
                        if (geoPushId)
                            deleteGeoPush(geoPushId);
                    }, setActive: setGeoPushActive, onChangeMessage: setCardMessage }) })) })) }));
};
