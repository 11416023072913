import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import { exactMatch, highlightText } from '@/utils/highlightText';
import { useAutocomplete } from '../context/useAutocomplete';
import styles from '../styles.module.scss';
export const AutocompleteItem = ({ option, index, }) => {
    const { t } = useTranslation();
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const openTooltip = useCallback(() => setTooltipIsOpen(true), []);
    const closeTooltip = useCallback(() => setTooltipIsOpen(false), []);
    const { getOptionLabel, getOptionHighlight = getOptionLabel, getOptionHelp, isOptionGroup, isOptionGroupItem, isOptionDisabled, isOptionLocked, asSelect, searchText, getOptionProps, } = useAutocomplete();
    const label = getOptionLabel(option);
    const highlight = asSelect
        ? exactMatch((getOptionHighlight === null || getOptionHighlight === void 0 ? void 0 : getOptionHighlight(option)) || '', searchText)
        : highlightText((getOptionHighlight === null || getOptionHighlight === void 0 ? void 0 : getOptionHighlight(option)) || '', searchText);
    const help = getOptionHelp === null || getOptionHelp === void 0 ? void 0 : getOptionHelp(option);
    const isGroup = isOptionGroup === null || isOptionGroup === void 0 ? void 0 : isOptionGroup(option);
    const isGroupItem = isOptionGroupItem === null || isOptionGroupItem === void 0 ? void 0 : isOptionGroupItem(option);
    const isDisabled = isOptionDisabled === null || isOptionDisabled === void 0 ? void 0 : isOptionDisabled(option);
    const isLocked = isOptionLocked === null || isOptionLocked === void 0 ? void 0 : isOptionLocked(option);
    const otherProps = getOptionProps === null || getOptionProps === void 0 ? void 0 : getOptionProps({ option, index });
    const ref = otherProps.ref;
    const lockedLabel = (_jsxs("span", Object.assign({ className: styles.nowrap, onMouseEnter: openTooltip, onMouseLeave: closeTooltip }, { children: [_jsx("span", Object.assign({ className: styles.wrap }, { children: label })), "\u00A0", _jsx(Tooltip, Object.assign({ title: _jsx("span", Object.assign({ className: styles.tooltip }, { children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') })), placement: 'right', open: tooltipIsOpen }, { children: _jsx("span", Object.assign({ className: styles.lockIcon }, { children: _jsx(Lock, {}) })) }))] })));
    const labelWithHelp = (_jsx(HelpTooltip, Object.assign({ tooltipClassName: styles.helpTooltip, title: help, placement: 'top', PopperProps: { disablePortal: true }, hasLightIcon: true }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: highlight } }) })));
    const group = (_jsx("li", Object.assign({ ref: ref, className: classNames(styles.item, styles.group, {
            [styles.disabled]: isDisabled,
        }) }, { children: isLocked ? lockedLabel : label })));
    let itemContent = isLocked ? lockedLabel : undefined;
    if (!isLocked && help)
        itemContent = labelWithHelp;
    const item = (_jsx("li", Object.assign({ ref: ref, className: classNames(styles.item, {
            [styles.groupItem]: isGroupItem,
            [styles.disabled]: isDisabled,
            [styles.locked]: isLocked,
        }) }, (isDisabled || isLocked ? {} : otherProps), { dangerouslySetInnerHTML: isLocked || help ? undefined : { __html: highlight } }, { children: itemContent })));
    return isGroup ? group : item;
};
