import { api } from './';
export const imageApi = api.injectEndpoints({
    endpoints: (build) => ({
        uploadImage: build.mutation({
            query: ({ file, forEmail }) => {
                const formData = new FormData();
                formData.append('file', file);
                if (forEmail)
                    formData.append('forEmail', 'true');
                return {
                    method: 'POST',
                    url: '/image/upload',
                    body: formData,
                };
            },
        }),
    }),
});
export const { useUploadImageMutation } = imageApi;
