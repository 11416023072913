import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Container } from '@/components/Container/Container';
import { usePurchasesPage } from './context/usePurchasesPage';
import { OrderCancelledDetailsModal, OrderDetailsModal, } from './OrderDetailsModal';
import { PurchasesAddModal } from './PurchasesAddModal';
import { PurchasesDetailsModal } from './PurchasesDetailsModal';
import { PurchasesFilters } from './PurchasesFilters';
import { PurchasesPanel } from './PurchasesPanel';
import { PurchasesSearch } from './PurchasesSearch';
import styles from './styles.module.scss';
export const PurchasesContainer = () => {
    const { filters } = usePurchasesPage();
    return (_jsxs(Container, Object.assign({ topElement: _jsx(PurchasesSearch, {}), topElementClassName: classNames({
            [styles.hasFilters]: filters.filters.length,
        }), notMobileRightPadding: true }, { children: [_jsx(PurchasesFilters, {}), _jsx(PurchasesPanel, {}), _jsx(PurchasesDetailsModal, {}), _jsx(PurchasesAddModal, {}), _jsx(OrderDetailsModal, {}), _jsx(OrderCancelledDetailsModal, {})] })));
};
