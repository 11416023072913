import axios from 'axios';
import { MAXMA_API_HOST } from '@/const/api';
const responseSuccessHandler = (response) => {
    // https://bitbucket.org/weloyal/app-frontend/src/0f71104d3345ccb49e58168412a8e6acd18eaf99/LAYOUT-1/STANDARD/assets/js/main.js#lines-123
    const { data } = response;
    if (data.success)
        return response;
    return Promise.reject(data.error);
};
const responseErrorHandler = (error) => {
    // TODO: https://bitbucket.org/weloyal/app-frontend/src/0f71104d3345ccb49e58168412a8e6acd18eaf99/LAYOUT-1/STANDARD/assets/js/main.js#lines-138
    // TODO: https://bitbucket.org/weloyal/app-frontend/src/0f71104d3345ccb49e58168412a8e6acd18eaf99/LAYOUT-1/STANDARD/assets/js/main.js#lines-230
    return Promise.reject(error);
};
const maxmaApi = axios.create({
    baseURL: MAXMA_API_HOST,
    timeout: 5000,
    headers: {},
});
maxmaApi.interceptors.response.use(responseSuccessHandler, responseErrorHandler);
export { maxmaApi };
