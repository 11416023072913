import { useTranslation } from 'react-i18next';
import { createHref, ROUTES } from '@/app/pages/routes';
import { addToLS } from '@/utils/LS';
export const useOpenPurchaseFilter = () => {
    var _a;
    const { t } = useTranslation();
    const purchaseUrl = createHref(ROUTES.PURCHASES);
    const openPurchaseFilter = (client, isReturn) => {
        const filters = [];
        if (client.phoneNumber)
            filters.push({
                code: 'clientPhone',
                text: t('Номер телефона') + ': ' + client.phoneNumber,
                rawConditions: {
                    op: 'include',
                    textValue: client.phoneNumber,
                },
            });
        else if (client.card)
            filters.push({
                code: 'loyaltyCard',
                text: t('№ карты') + ': ' + client.card,
                rawConditions: {
                    op: 'include',
                    textValue: client.card,
                },
            });
        else
            return;
        if (isReturn)
            filters.push({
                code: 'returnPurchase',
                text: t('Сделан возврат') + ': ' + t('Да'),
                rawConditions: {
                    op: 'include',
                    checkboxes: { 1: true },
                },
            });
        addToLS({ key: 'purchaseFilters', value: { filters, touched: true } });
        window.open(purchaseUrl, '_blank');
    };
    const origin = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin;
    return {
        purchaseUrl: purchaseUrl.replace(origin, ''),
        openPurchaseFilter,
    };
};
