import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback } from 'react';
import { HighlightText } from '@/components/HighlightText/HighlightText';
import { Pencil } from '@/icons/Pencil';
import styles from './styles.module.scss';
export const ShopGroupInputItem = ({ shopGroup, isSelected, onSelect, onSelectToChange, search, }) => {
    const handleSelectToChange = useCallback((e) => {
        e.stopPropagation();
        onSelectToChange(shopGroup.id);
    }, [shopGroup.id, onSelectToChange]);
    const handleSelect = useCallback(() => {
        onSelect(shopGroup.id);
    }, [shopGroup.id, onSelect]);
    return (_jsxs("div", Object.assign({ className: classNames(styles.item, {
            [styles.selected]: !(search === null || search === void 0 ? void 0 : search.trim()) && isSelected,
        }), onClick: handleSelect }, { children: [_jsx("div", Object.assign({ className: styles.itemName }, { children: _jsx(HighlightText, { text: shopGroup.name, search: search }) })), _jsx("button", Object.assign({ className: styles.itemEditButton, type: 'button', onClick: handleSelectToChange }, { children: _jsx(Pencil, {}) }))] })));
};
