import { escape } from './html';
import { jsonHandler } from './jsonHandler';
export const jsonToEscapedText = (editorState) => {
    const variables = [];
    const text = jsonHandler(editorState, {
        onText: (rawText) => {
            const text = escape(rawText);
            return text.replace(/\\/g, '\\\\').replace(/\$/g, '\\$$');
        },
        onVariable: (variable) => {
            variables.push(variable);
            return '$';
        },
    });
    return { text, variables };
};
