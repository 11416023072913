import { numberRound } from '@/utils/numbers';
import { createNode } from '../createNode';
export const createChannelNode = (data) => {
    return createNode({
        type: 'channel',
        data,
        position: { x: 0, y: 0 },
        style: { opacity: 0 },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const validateChannelNodeData = (data, t) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    const errors = {};
    const nameMaxLength = 200;
    // 1) name
    if (!((_a = data.name) === null || _a === void 0 ? void 0 : _a.trim()))
        errors.name = t('FIELD_REQUIRED_ERROR');
    else if (data.name.length > nameMaxLength)
        errors.name = t('FIELD_MAX_LENGTH_ERROR', {
            amount: numberRound(nameMaxLength),
            count: nameMaxLength,
        });
    if ((_b = data.channels) === null || _b === void 0 ? void 0 : _b.includes('push')) {
        // 2) push text
        if (!((_d = (_c = data.push) === null || _c === void 0 ? void 0 : _c.pushText) === null || _d === void 0 ? void 0 : _d.trim()))
            errors.pushText = t('FIELD_REQUIRED_ERROR');
        // 3) push image expire (days)
        if ((_e = data.push) === null || _e === void 0 ? void 0 : _e.walletImageId)
            if (!data.push.walletPushExpireInDays)
                errors.walletPushExpireInDays = t('FIELD_REQUIRED_ERROR_SHORT');
            else {
                const n = Number(data.push.walletPushExpireInDays);
                const min = 1;
                const max = 30;
                if (n < min)
                    errors.walletPushExpireInDays = t('MIN_ERROR_MESSAGE', {
                        amount: min,
                    });
                else if (n > max)
                    errors.walletPushExpireInDays = t('MAX_ERROR_MESSAGE', {
                        amount: max,
                    });
            }
    }
    if ((_f = data.channels) === null || _f === void 0 ? void 0 : _f.includes('viber')) {
        // 4) viber text
        if (!((_h = (_g = data.viber) === null || _g === void 0 ? void 0 : _g.viberText) === null || _h === void 0 ? void 0 : _h.trim()))
            errors.viberText = t('FIELD_REQUIRED_ERROR');
        const isRequiredButton = !!((_j = data.viber) === null || _j === void 0 ? void 0 : _j.viberImageId) || !!((_k = data.viber) === null || _k === void 0 ? void 0 : _k.viberUrl);
        // 5) viber button
        if (isRequiredButton && !((_m = (_l = data.viber) === null || _l === void 0 ? void 0 : _l.viberButton) === null || _m === void 0 ? void 0 : _m.trim()))
            errors.viberButton = t('FIELD_REQUIRED_ERROR');
        const isRequiredUrl = !!((_o = data.viber) === null || _o === void 0 ? void 0 : _o.viberImageId) || !!((_p = data.viber) === null || _p === void 0 ? void 0 : _p.viberButton);
        // 6) viber url
        if (isRequiredUrl && !((_r = (_q = data.viber) === null || _q === void 0 ? void 0 : _q.viberUrl) === null || _r === void 0 ? void 0 : _r.trim()))
            errors.viberUrl = t('FIELD_REQUIRED_ERROR');
    }
    // 7) sms text
    if ((_s = data.channels) === null || _s === void 0 ? void 0 : _s.includes('sms'))
        if (!((_u = (_t = data.sms) === null || _t === void 0 ? void 0 : _t.smsText) === null || _u === void 0 ? void 0 : _u.trim()))
            errors.smsText = t('FIELD_REQUIRED_ERROR');
    if ((_v = data.channels) === null || _v === void 0 ? void 0 : _v.includes('telegram')) {
        // 8) telegram text
        if (!((_x = (_w = data.telegram) === null || _w === void 0 ? void 0 : _w.telegramText) === null || _x === void 0 ? void 0 : _x.trim()))
            errors.telegramText = t('FIELD_REQUIRED_ERROR');
        const isRequiredButton = !!((_y = data.telegram) === null || _y === void 0 ? void 0 : _y.telegramUrl);
        // 9) telegram button
        if (isRequiredButton && !((_0 = (_z = data.telegram) === null || _z === void 0 ? void 0 : _z.telegramButton) === null || _0 === void 0 ? void 0 : _0.trim()))
            errors.telegramButton = t('FIELD_REQUIRED_ERROR');
        const isRequiredUrl = !!((_1 = data.telegram) === null || _1 === void 0 ? void 0 : _1.telegramButton);
        // 10) telegram url
        if (isRequiredUrl && !((_3 = (_2 = data.telegram) === null || _2 === void 0 ? void 0 : _2.telegramUrl) === null || _3 === void 0 ? void 0 : _3.trim()))
            errors.telegramUrl = t('FIELD_REQUIRED_ERROR');
    }
    if ((_4 = data.channels) === null || _4 === void 0 ? void 0 : _4.includes('email')) {
        // 11) email subject
        if (!((_6 = (_5 = data.email) === null || _5 === void 0 ? void 0 : _5.emailSubject) === null || _6 === void 0 ? void 0 : _6.trim()))
            errors.emailSubject = t('FIELD_REQUIRED_ERROR');
        // 12) email body
        if (!((_8 = (_7 = data.email) === null || _7 === void 0 ? void 0 : _7.emailBody) === null || _8 === void 0 ? void 0 : _8.trim()))
            errors.emailBody = t('FIELD_REQUIRED_ERROR');
        else {
            const body = data.email.emailBody;
            const link = /{{\s*brand\.settings\.landingBase\s*}}\/m\/unsub\/{{\s*msgid\s*}}/;
            const variable = '!' + t('Отписаться');
            if (!link.test(body) && body.indexOf(variable) === -1)
                errors.emailBody = t('MAILING.CREATE.MISSING_UNSUB_LINK');
        }
    }
    // 13) cascade body
    if (data.hasCascadeEnabled && !((_9 = data.channels) === null || _9 === void 0 ? void 0 : _9.length))
        errors.channels = t('добавьте хотя бы один канал');
    return errors;
};
