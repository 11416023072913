import { DashboardWidgetType, } from '@/types/dashboard';
export const getSparklineData = (dashboardId) => (state) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const currentMetrics = ((_a = state.reports.sparkline[dashboardId]) === null || _a === void 0 ? void 0 : _a.currentMetrics) || [];
    const singleValue = ((_d = (_c = (_b = state.reports.sparkline[dashboardId]) === null || _b === void 0 ? void 0 : _b.singleValue) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) || {};
    const chartData = ((_g = (_f = (_e = state.reports.sparkline[dashboardId]) === null || _e === void 0 ? void 0 : _e.chartData) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.data) || [];
    return currentMetrics.map((metricId) => ({
        metricId,
        value: singleValue[metricId] || 0,
        data: chartData.map((date) => date[metricId]),
    }));
};
export const tableDataSelector = (state) => {
    return state.reports.tableData;
};
export const dashboardListSelector = (state) => {
    return state.reports.dashboards;
};
export const activeDashboardIdSelector = (state) => {
    return state.reports.activeDashboardId;
};
export const activeDashboardSelector = (state) => {
    const id = state.reports.activeDashboardId;
    const list = state.reports.dashboards.data;
    return list === null || list === void 0 ? void 0 : list.find((i) => i.id === id);
};
export const activeDashboardKPITilesSelector = (state) => {
    const id = state.reports.activeDashboardId;
    const list = state.reports.dashboards.data;
    const dashboard = list === null || list === void 0 ? void 0 : list.find((i) => i.id === id);
    const widget = dashboard === null || dashboard === void 0 ? void 0 : dashboard.widgets.find((w) => w.type === DashboardWidgetType.KPITiles);
    return widget;
};
export const activeDashboardAnalyticsSelector = (state) => {
    const id = state.reports.activeDashboardId;
    const list = state.reports.dashboards.data;
    const dashboard = list === null || list === void 0 ? void 0 : list.find((i) => i.id === id);
    const widget = dashboard === null || dashboard === void 0 ? void 0 : dashboard.widgets.find((w) => w.type === DashboardWidgetType.Analytics);
    return widget;
};
export const chartDataSelector = (state) => {
    return state.reports.chartData;
};
export const touchedDashboardIdsSelector = (state) => {
    return state.reports.touchedDashboardIds;
};
export const touchedDashboardsSelector = (state) => {
    const ids = state.reports.touchedDashboardIds;
    const dashboards = state.reports.dashboards.data || [];
    return dashboards.filter((d) => ids.includes(d.id));
};
