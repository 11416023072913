import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import styles from '../styles.module.scss';
export const FormUsersRole = ({ roles }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const roleListOptions = roles.map((role) => ({
        value: +role.id,
        label: role.name,
    }));
    return (_jsx(FormFieldRow, Object.assign({ label: t('Роль (уровень доступа)'), labelFor: 'role', controlSize: '250', help: (_a = errors.operatorRoleId) === null || _a === void 0 ? void 0 : _a.message, isRequired: true, hasError: !!errors.operatorRoleId }, { children: _jsx(Controller, { name: 'operatorRoleId', rules: {
                required: {
                    value: true,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
            }, control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ className: styles.shortAutocomplete }, field, { id: 'operatorRoleId', placeholder: t('выберите роль'), value: roleListOptions.find((option) => option.value === +field.value) ||
                    null, onChange: (role) => {
                    setValue('operatorRoleId', role === null || role === void 0 ? void 0 : role.value, {
                        shouldValidate: isSubmitted,
                        shouldDirty: true,
                    });
                }, options: roleListOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasError: !!errors.operatorRoleId, asSelect: true }))) }) })));
};
