import { $getSelection, $insertNodes, $isRangeSelection, } from 'lexical';
import { $createLinkNode, $isLinkNode } from './LinkNode';
export const updateLink = (title, url, editor) => {
    editor.update(() => {
        const selection = $getSelection();
        let updated = false;
        if ($isRangeSelection(selection)) {
            const nodes = selection.getNodes();
            if (nodes.length === 1) {
                const firstNode = nodes[0];
                if ($isLinkNode(firstNode)) {
                    firstNode.setTextContent(title);
                    firstNode.setURL(url);
                    updated = true;
                }
            }
        }
        if (!updated) {
            const node = $createLinkNode(title, url);
            $insertNodes([node]);
        }
        editor.focus();
    });
};
