var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { CloseCircle } from '@/icons/CloseCircle';
import styles from './styles.module.scss';
export const EmailEditorReset = ({ onReset, }) => {
    const { t } = useTranslation();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const confirmTitle = t('Удалить дизайн и содержимое письма?');
        const confirmAgreeLabel = t('Удалить');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            agreeButtonSize: 'extra-large',
            isDanger: true,
            hasIcon: false,
        });
        if (!yes)
            return;
        onReset();
    });
    return (_jsx("button", Object.assign({ className: styles.reset, type: 'button', onClick: handleClick }, { children: _jsx(CloseCircle, {}) })));
};
