import { useGetUserQuery } from '@/api/query/user';
export const useUserPermissions = () => {
    var _a, _b;
    const { data: user } = useGetUserQuery();
    if (!user)
        return () => false;
    const userPermissions = ((_b = (_a = user === null || user === void 0 ? void 0 : user.user) === null || _a === void 0 ? void 0 : _a.operatorRole) === null || _b === void 0 ? void 0 : _b.permissions.reduce((acc, permission) => {
        acc[permission] = true;
        return acc;
    }, {})) || {};
    const userHasAccessTo = (permissionType) => {
        if (permissionType instanceof Array)
            return permissionType.some((role) => !!userPermissions[role]);
        else
            return !!userPermissions[permissionType];
    };
    return userHasAccessTo;
};
