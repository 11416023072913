export const fgColor = (bgColor, dark, light, threshold = 216) => {
    if (!bgColor)
        return;
    const darkColor = dark || '#3a3a3a';
    const lightColor = light || '#fff';
    let color = bgColor;
    if (bgColor.startsWith('#'))
        if (bgColor.length === 4)
            color = bgColor.slice(1).replace(/(.)/g, '$1$1');
        else
            color = bgColor.substring(1, 7);
    const red = parseInt(color.substring(0, 2), 16); // hexToR
    const green = parseInt(color.substring(2, 4), 16); // hexToG
    const blue = parseInt(color.substring(4, 6), 16); // hexToB
    if (red && green && blue)
        return red * 0.299 + green * 0.587 + blue * 0.114 > threshold
            ? darkColor
            : lightColor;
    else
        return lightColor;
};
