import { api } from './';
export const exportsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getExportList: build.query({
            query: () => ({
                url: '/report/list',
            }),
            providesTags: ['ExportList'],
        }),
        createExport: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/report/add',
                body,
            }),
            invalidatesTags: ['ExportList'],
        }),
    }),
});
export const { useGetExportListQuery, useCreateExportMutation } = exportsApi;
