import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Table } from '@/components/Table/Table';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
import { WalletCardsTableCreateButton as CreateButton } from './WalletCardsTableCreateButton';
import { WalletCardsTableLevelName as LevelName } from './WalletCardsTableLevelName';
export const WalletCardsTable = ({ levels, cards = [], onEditCard, onCreateCard, isLoading, onCardEnter, onCardLeave, activeCardLevel, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasWalletCardsManage = userHasAccessTo('WALLET_CARDS_MANAGE');
    const columns = [
        { id: 'level', title: t('Уровень') },
        { id: 'ios', title: t('iOS'), align: 'right' },
        { id: 'android', title: t('Android'), align: 'right' },
        { id: 'total', title: t('Всего'), align: 'right' },
        { id: 'actions' },
    ].filter(Boolean);
    const rows = levels.map((level) => {
        const levelCard = cards.find((c) => c.levelId === level.id);
        const levelName = level.name;
        const levelStatus = (levelCard === null || levelCard === void 0 ? void 0 : levelCard.status) || (cards.length > 0 ? 'empty' : undefined);
        const ios = (levelCard === null || levelCard === void 0 ? void 0 : levelCard.ios) ? (numberRound(levelCard.ios)) : (_jsx("span", Object.assign({ className: styles.muted }, { children: "\u2013" })));
        const android = (levelCard === null || levelCard === void 0 ? void 0 : levelCard.android) ? (numberRound(levelCard.android)) : (_jsx("span", Object.assign({ className: styles.muted }, { children: "\u2013" })));
        const total = (levelCard === null || levelCard === void 0 ? void 0 : levelCard.total) ? (numberRound(levelCard.total)) : (_jsx("span", Object.assign({ className: styles.muted }, { children: "\u2013" })));
        const cardCreateButton = (_jsx(CreateButton, { copyFrom: levels.filter((level) => cards.some((card) => card.levelId === level.id)), onCreate: () => onCreateCard === null || onCreateCard === void 0 ? void 0 : onCreateCard(level.id), onCopy: (copyFromId) => onCreateCard === null || onCreateCard === void 0 ? void 0 : onCreateCard(level.id, copyFromId) }, 'actions'));
        const cardActions = [
            {
                id: 'edit',
                label: t('Редактировать'),
                onClick: () => onEditCard === null || onEditCard === void 0 ? void 0 : onEditCard(level.id),
                isDisabled: !userHasWalletCardsManage,
                tooltip: !userHasWalletCardsManage && t('Недоступно для вашей роли'),
            },
        ];
        return {
            id: level.id,
            cells: [
                _jsx(LevelName, { name: levelName, status: levelStatus }, 'level'),
                levelCard ? ios : null,
                levelCard ? android : null,
                levelCard ? total : null,
                levelCard ? null : { content: cardCreateButton, colspan: 4 },
            ].filter(Boolean),
            actions: levelCard ? cardActions : undefined,
            isActive: level.id === activeCardLevel,
        };
    });
    return (_jsx("div", Object.assign({ className: styles.table }, { children: _jsx(Table, { columns: columns, rows: rows, cellClassNames: { [columns.length - 1]: styles.noPadding }, isLoading: isLoading, onRowEnter: onCardEnter, onRowLeave: onCardLeave, onRowClick: (row) => {
                const levelCard = cards.find((c) => c.levelId === row.id);
                if (levelCard)
                    onEditCard === null || onEditCard === void 0 ? void 0 : onEditCard(row.id);
                else
                    onCreateCard === null || onCreateCard === void 0 ? void 0 : onCreateCard(row.id);
            } }) })));
};
