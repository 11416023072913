import { createNode } from '../createNode';
export const createLoopNode = (data) => {
    return createNode({
        type: 'loop',
        data,
        position: { x: 0, y: 0 },
        style: { opacity: 0 },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
