import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const RFMValueTable = ({ title, groups, currency, isDark = false, isCondensed = false, isLocked = false, }) => {
    const { t } = useTranslation();
    const formatValue = (value, scale) => {
        if (scale === 'money')
            return money({ input: value, currency });
        if (scale === 'days')
            return t('DAYS_RANGE', {
                count: value,
                amount: numberRound(value),
            });
        if (scale === 'purchases')
            return t('PURCHASES_RANGE', {
                count: value,
                amount: numberRound(value),
            });
        return numberRound(value);
    };
    const formatRange = (from, to, scale) => {
        if (scale === 'money')
            return (t('от') +
                ' ' +
                formatValue(from, 'money') +
                ' ' +
                t('до') +
                ' ' +
                formatValue(to, 'money'));
        if (scale === 'days' || scale === 'purchases')
            return (t('от') +
                ' ' +
                formatValue(from) +
                ' ' +
                t('до') +
                ' ' +
                formatValue(to, scale));
        return from + '–' + to;
    };
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.dark]: isDark,
            [styles.condensed]: isCondensed,
            [styles.locked]: isLocked,
        }) }, { children: [!!title && _jsx("div", Object.assign({ className: styles.title }, { children: title })), _jsx("ul", Object.assign({ className: styles.list }, { children: groups.map((group) => {
                    const label = group.label;
                    let value = '';
                    if (typeof group.value !== 'undefined')
                        value = formatValue(group.value, group.scale) || '';
                    else {
                        const from = typeof group.from !== 'undefined' ? group.from : null;
                        const to = typeof group.to !== 'undefined' ? group.to : null;
                        if (from !== null && to === null)
                            value = t('от') + ' ' + formatValue(from, group.scale);
                        if (to !== null && from === null)
                            value = t('до') + ' ' + formatValue(to, group.scale);
                        if (from !== null && to !== null)
                            value = formatRange(from, to, group.scale);
                    }
                    return (_jsxs("li", Object.assign({ className: classNames(styles.group, {
                            [styles.locked]: group.isLocked,
                        }) }, { children: [_jsx("div", { className: styles.line }), _jsx("span", { children: label }), _jsx("span", Object.assign({ className: styles.value }, { children: value }))] }), label));
                }) }))] })));
};
