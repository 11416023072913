import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { TableDropdown, } from '@/components/TableDropdown/TableDropdown';
import { Gears } from '@/icons/Gears';
import styles from './styles.module.scss';
export const TableHeaderDropdown = memo(({ items }) => {
    const [isActive, setIsActive] = useState(false);
    return (_jsx(TableDropdown, Object.assign({ items: items, offset: [0, -10], onOpen: () => setIsActive(true), onClose: () => setIsActive(false) }, { children: _jsx("button", Object.assign({ className: classNames(styles.button, {
                [styles.active]: isActive,
            }) }, { children: _jsx(Gears, {}) })) })));
});
