export const focus = (element) => {
    var _a, _b;
    if (element) {
        (_a = element.focus) === null || _a === void 0 ? void 0 : _a.call(element);
        (_b = element.scrollIntoView) === null || _b === void 0 ? void 0 : _b.call(element, {
            behavior: 'auto',
            block: 'center',
        });
    }
};
