import { api } from './';
export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query({
            query: () => ({
                method: 'GET',
                url: '/user/get',
            }),
            providesTags: ['CurrentUser'],
        }),
        login: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/user/login',
                body,
            }),
            invalidatesTags: (_, error) => {
                return error ? [] : ['CurrentUser', 'CurrentBrand'];
            },
        }),
        logout: build.mutation({
            query: () => ({
                method: 'POST',
                url: '/user/logout',
            }),
            invalidatesTags: (_, error) => {
                return error ? [] : ['CurrentUser', 'CurrentBrand'];
            },
        }),
    }),
});
export const { useGetUserQuery, useLoginMutation, useLogoutMutation } = userApi;
