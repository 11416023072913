import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { LinkPopupLayout } from './LinkPopupLayout';
import { normalizeLink } from './normalizeLink';
export const LinkPopup = ({ title, link, onChangeTitle, onChangeLink, onChange, onDelete, onClose, }) => {
    const [titleError, setTitleError] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const handleChange = () => {
        var _a, _b;
        let success = true;
        if (!link.trim() || !normalizeLink(link)) {
            setLinkError(true);
            success = false;
            (_a = document.getElementById('link-form-link')) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (!title.trim()) {
            setTitleError(true);
            success = false;
            (_b = document.getElementById('link-form-title')) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if (success) {
            onChange(title, normalizeLink(link));
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
    };
    const handleDelete = () => {
        onDelete();
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleInputKeyUp = (event) => {
        if (event.code === 'Enter')
            handleChange();
    };
    const handleInputLinkTitle = (event) => {
        onChangeTitle(event.target.value);
        setTitleError(false);
    };
    const handleInputLinkHref = (event) => {
        onChangeLink(event.target.value);
        setLinkError(false);
    };
    return (_jsx(LinkPopupLayout, { linkHrefError: linkError, linkTitleError: titleError, linkHref: link, linkTitle: title, handleCreateLink: handleChange, handleDeleteLink: handleDelete, handleInputLinkHref: handleInputLinkHref, handleInputLinkTitle: handleInputLinkTitle, handleInputKeyUp: handleInputKeyUp }));
};
