var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteClients } from '@/components/Autocomplete/instances/AutocompleteClients';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Input } from '@/components/Input/Input';
import { TextArea } from '@/components/Input/TextArea';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { MobilePhone } from '@/icons/MobilePhone';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const MAX_BONUSES = 99999999;
export const FormClientBonuses = memo(({ id, client, setHasFormChanged, onSubmit, onChangeOperation, onChangeAmount, getClients = () => __awaiter(void 0, void 0, void 0, function* () { return []; }), shouldLoadInitialClientData = false, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, isSubmitted, isSubmitting, dirtyFields }, setValue, watch, } = useForm({
        defaultValues: {
            client: client || null,
            operation: 'plus',
            lifetime: '0',
            notifyPhone: !!(client === null || client === void 0 ? void 0 : client.phoneNumber),
        },
    });
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [dirtyFields, isSubmitting, setHasFormChanged]);
    const lifetimeOptions = [
        { value: '0', label: t('как у обычных бонусов') },
        { value: '1', label: t('1 день') },
        { value: '3', label: t('3 дня') },
        { value: '7', label: t('1 неделя') },
        { value: '14', label: t('2 недели') },
        { value: '30', label: t('1 месяц') },
        { value: '90', label: t('3 месяца') },
        { value: '180', label: t('6 месяцев') },
        { value: '365', label: t('1 год') },
    ];
    const clientValue = watch('client');
    const operation = watch('operation');
    const amount = watch('amount');
    const isAllowedPhoneNotification = !!(clientValue === null || clientValue === void 0 ? void 0 : clientValue.phoneNumber);
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    useEffect(() => {
        if (onChangeOperation)
            onChangeOperation(operation);
        if (operation !== 'plus')
            setValue('notifyPhone', false);
    }, [operation]);
    useEffect(() => {
        if (!(clientValue === null || clientValue === void 0 ? void 0 : clientValue.phoneNumber))
            setValue('notifyPhone', false);
    }, [clientValue === null || clientValue === void 0 ? void 0 : clientValue.phoneNumber]);
    useEffect(() => {
        if (onChangeAmount)
            onChangeAmount(amount);
    }, [amount]);
    return (_jsxs("form", Object.assign({ id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(FormFieldRow, Object.assign({ label: t('Для клиента'), labelFor: 'client', controlSize: '400', help: (_a = errors.client) === null || _a === void 0 ? void 0 : _a.message, isRequired: true, hasError: !!errors.client }, { children: _jsx(Controller, { name: 'client', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(AutocompleteClients, { id: 'client', value: field.value, onChange: (value) => setValue('client', value, {
                            shouldValidate: isSubmitted,
                        }), getClients: getClients, hasError: !!errors.client, shouldLoadInitialData: shouldLoadInitialClientData })) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Тип операции'), labelFor: 'operation', controlSize: '300', alignItems: 'center' }, { children: _jsx(Controller, { name: 'operation', control: control, render: ({ field }) => (_jsx(RadioGroup, Object.assign({}, field, { id: 'operation', value: field.value, onChange: (value) => setValue('operation', value.toString()), items: [
                            { value: 'plus', label: t('начислить') },
                            { value: 'minus', label: t('списать') },
                        ] }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Количество бонусов'), labelFor: 'amount', controlSize: '150', help: (_b = errors.amount) === null || _b === void 0 ? void 0 : _b.message, isRequired: true, hasError: !!errors.amount }, { children: _jsx(Controller, { name: 'amount', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        pattern: {
                            value: /^\d*[1-9]\d*$/,
                            message: t('только числа'),
                        },
                        min: {
                            value: 1,
                            message: t('BONUSES_AMOUNT_MIN_ERROR_MESSAGE', {
                                amount: numberRound(1),
                                count: 1,
                            }),
                        },
                        max: {
                            value: MAX_BONUSES.toString(),
                            message: t('BONUSES_AMOUNT_MAX_ERROR_MESSAGE', {
                                amount: numberRound(MAX_BONUSES),
                                count: MAX_BONUSES,
                            }),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'amount', placeholder: t('введите число'), type: 'number', inputMode: 'numeric', hasError: !!errors.amount, shouldHideSpinButtons: true, autoFocus: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Срок действия бонусов'), labelFor: 'lifetime', controlSize: '250', help: (_c = errors.lifetime) === null || _c === void 0 ? void 0 : _c.message, isRequired: true, hasError: !!errors.lifetime }, { children: _jsx(Controller, { name: 'lifetime', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'lifetime', placeholder: t('выберите срок действия'), value: lifetimeOptions.find((option) => option.value === field.value) || null, onChange: (option) => {
                            setValue('lifetime', (option === null || option === void 0 ? void 0 : option.value) || null, {
                                shouldValidate: isSubmitted,
                            });
                        }, options: lifetimeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasError: !!errors.lifetime, asSelect: true }))) }) })), _jsx(FormFieldRow, Object.assign({ label: t('Комментарий'), labelFor: 'comment', controlSize: '400', help: ((_d = errors.comment) === null || _d === void 0 ? void 0 : _d.message) ||
                    t('только для пользователей платформы, клиент его не видит'), isRequired: true, hasError: !!errors.comment }, { children: _jsx(Controller, { name: 'comment', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        maxLength: {
                            value: 600,
                            message: t('FIELD_MAX_LENGTH_ERROR', {
                                amount: numberRound(600),
                                count: 600,
                            }),
                        },
                        validate: validateTrimValue,
                    }, render: ({ field }) => (_jsx(TextArea, Object.assign({}, field, { id: 'comment', placeholder: t('например, почему бонусный баланс был изменен'), rows: 3, hasError: !!errors.comment }))) }) })), _jsx(FormFieldRow, Object.assign({ controlSize: '400', helpSize: '300', asCheckbox: true }, { children: _jsx(Controller, { name: 'notifyPhone', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                            setValue('notifyPhone', e.target.checked);
                        }, disabled: operation !== 'plus' || !isAllowedPhoneNotification }, { children: _jsx("div", Object.assign({ className: styles.checkboxLabel }, { children: _jsxs(Trans, Object.assign({ i18nKey: 'BONUSES_NOTIFY_PHONE_MESSAGE' }, { children: ["\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043A\u043B\u0438\u0435\u043D\u0442\u0443", _jsx(MobilePhone, { className: styles.icon }), "SMS-\u0443\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u0435", _jsx(HelpTooltip, { tooltipClassName: styles.tooltipContent, title: t('Формат сообщения: Имя, вам начислено N бонусов (всего на счету N). Хорошего дня!'), placement: 'right', isSmall: true })] })) })) }))) }) }))] })));
});
