import { jsx as _jsx } from "react/jsx-runtime";
import { Pills } from '@/components/Pills/Pills';
import { AreaChart } from '@/icons/AreaChart';
import { HorizontalBars } from '@/icons/HorizontalBars';
export const RFMChartPills = ({ activeItem, onChangeActiveItem, }) => {
    return (_jsx(Pills, { items: [
            { id: 'bars', content: _jsx(HorizontalBars, {}) },
            { id: 'area', content: _jsx(AreaChart, {}) },
        ], activeItemId: activeItem, onChangeActiveItem: (id) => onChangeActiveItem === null || onChangeActiveItem === void 0 ? void 0 : onChangeActiveItem(id), asIcons: true }));
};
