import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RFMMovementsChart } from '@/components/RFMMovementsChart/RFMMovementsChart';
import { RFMMovementsTable } from '@/components/RFMMovementsTable/RFMMovementsTable';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { clearMovements } from './clearMovements';
import { getChartData } from './getChartData';
import { getTableData } from './getTableData';
import { RFMMovementsPanelChartTypeSelect as TypeSelect } from './RFMMovementsPanelChartTypeSelect';
import { RFMMovementsPanelEmpty } from './RFMMovementsPanelEmpty';
import styles from './styles.module.scss';
export const RFMMovementsPanel = ({ rfmSegments: rawRfmSegments = [], movements: rawMovements = [], isLocked, sortMovements = true, hasNoData = false, hasNoSearchResult = false, removeUltraflow = true, removeSelfMovements = true, removeEmptyMovements = true, shouldUseTotalChartMax = true, }) => {
    const { t } = useTranslation();
    let rfmSegments = rawRfmSegments;
    if (removeUltraflow)
        rfmSegments = rawRfmSegments.filter((s) => s.code !== 'rfm_lost');
    const movements = clearMovements(rawMovements, {
        removeUltraflow,
        removeSelfMovements,
        removeEmptyMovements,
    });
    const [chartType, setChartType] = useState('all');
    const [selectedSegment, setSelectedSegment] = useState();
    const [selectedBar, setSelectedBar] = useState();
    const handleChangeChartType = (type) => {
        setChartType(type);
        setSelectedSegment(undefined);
        setSelectedBar(undefined);
    };
    const handleChangeSelectedSegment = (code) => {
        setSelectedSegment(code);
        setSelectedBar(undefined);
    };
    const handleChangeSelectedBar = (code, bar) => {
        setSelectedSegment(code);
        setSelectedBar(bar);
    };
    const title = (_jsxs(_Fragment, { children: [t('Перетоки'), _jsx(TypeSelect, { value: chartType, onChange: handleChangeChartType })] }));
    const chart = (_jsx(RFMMovementsChart, { type: chartType, rfmSegments: rfmSegments, data: getChartData(rfmSegments, movements, chartType, shouldUseTotalChartMax), selectedRFMSegment: selectedSegment, onChangeSelectedRFMSegment: handleChangeSelectedSegment, selectedRFMSegmentBar: selectedBar, onChangeSelectedRFMSegmentBar: handleChangeSelectedBar, sortSegments: sortMovements, isLocked: isLocked }));
    const table = (_jsx(RFMMovementsTable, { className: styles.table, rfmSegments: rfmSegments, movements: getTableData(movements, chartType, selectedSegment, selectedBar), sortMovements: sortMovements, isLocked: isLocked }));
    if (hasNoData || hasNoSearchResult)
        return (_jsx(StatsPanel, Object.assign({ title: title, titleClassName: styles.title }, { children: _jsx(RFMMovementsPanelEmpty, { type: hasNoSearchResult ? 'no-search-results' : 'no-data' }) })));
    return (_jsxs(StatsPanel, Object.assign({ title: title, titleClassName: styles.title, bodyClassName: styles.wrapper }, { children: [_jsx("div", Object.assign({ className: styles.chart }, { children: chart })), table] })));
};
