export const normalizeLink = (link) => {
    let normalized = link;
    if (!normalized.startsWith('http')) {
        normalized = normalized.replace(/^[/:]*/g, '');
        normalized = `https://${normalized}`;
    }
    try {
        new URL(normalized);
        return normalized;
    }
    catch (e) {
        return null;
    }
};
