import { useTranslation } from 'react-i18next';
import { useMessageEditorVariables, } from '@/components/rich-text/MessageEditor/useMessageEditorVariables';
const useVariables = (options) => {
    const { t } = useTranslation();
    const { variables } = useMessageEditorVariables(options);
    const variableIds = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = {};
    const groupNames = {
        client: t('Клиент'),
        bonus: t('Бонусы'),
        products: t('Товары по триггеру'),
        recommendedProducts: t('Рекомендуемые товары'),
    };
    const groupRules = {
        products: {
            repeat: {
                name: t('Повторять для каждого товара'),
                before: '{% for product in products %}',
                after: '{% endfor %}',
            },
        },
        recommendedProducts: {
            repeat: {
                name: t('Повторять для каждого товара'),
                before: '{% for product in recommendedProducts %}',
                after: '{% endfor %}',
            },
        },
    };
    for (let i = 0; i < variables.length; i += 1) {
        const v = variables[i];
        variableIds.push(v.id);
        const tag = {
            name: '!' + v.id,
            value: '!' + v.id,
            sample: v.sample,
        };
        if (v.group && !result[v.group]) {
            result[v.group] = {
                name: groupNames[v.group],
                mergeTags: {},
            };
            if (groupRules[v.group])
                result[v.group].rules = groupRules[v.group];
        }
        if (v.group && v.key)
            result[v.group].mergeTags[v.key] = tag;
        if (!v.group && v.key)
            result[v.key] = tag;
    }
    return { result, variableIds };
};
const useLinks = (options) => {
    const { t } = useTranslation();
    const { hasWalletFeature } = options;
    const links = {};
    if (hasWalletFeature)
        links['walletsLink'] = {
            name: t('Установить карту Wallet'),
            value: '<a href="{{ walletsLink }}">' + t('Установить карту Wallet') + '</a>',
        };
    links['unsubscribe'] = {
        name: t('Отписаться'),
        value: '<a href="{{ brand.settings.landingBase }}/m/unsub/{{ msgid }}">' +
            t('отписаться') +
            '</a>',
    };
    links['confirm'] = {
        name: t('Подтвердить подписку'),
        value: '<a href="{{ brand.settings.landingBase }}/m/confirm/{{ msgid }}">' +
            t('Подтвердить подписку') +
            '</a>',
    };
    links['webversion'] = {
        name: t('Открыть в браузере'),
        value: '<a href="{{ brand.settings.landingBase }}/m/webversion/{{ msgid }}">' +
            t('Открыть в браузере') +
            '</a>',
    };
    return links;
};
export const useUnlayerMergeTags = (options) => {
    const { t } = useTranslation();
    const { result: variables, variableIds } = useVariables(options);
    const links = useLinks(options);
    const result = variables;
    result['links'] = {
        name: t('Служебные ссылки'),
        mergeTags: links,
    };
    const mergeTags = JSON.parse(JSON.stringify(result));
    return { mergeTags, variableIds };
};
