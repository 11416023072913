import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { BirthdateDaysInput } from '@/components/BirthdateDaysInput/BirthdateDaysInput';
import { Wrapper } from './Wrapper';
export const BirthdateDays = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeBefore = (before) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { before }) }));
    };
    const handleChangeAfter = (after) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { after }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = !!((_b = (_a = draft.value) === null || _a === void 0 ? void 0 : _a.before) === null || _b === void 0 ? void 0 : _b.trim()) || !!((_d = (_c = draft.value) === null || _c === void 0 ? void 0 : _c.after) === null || _d === void 0 ? void 0 : _d.trim());
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && isValid)
            handleApply();
    };
    const before = ((_e = draft.value) === null || _e === void 0 ? void 0 : _e.before) || '';
    const after = ((_f = draft.value) === null || _f === void 0 ? void 0 : _f.after) || '';
    return (_jsx(Wrapper, Object.assign({ onBack: onBack, label: item.label, operator: ((_g = draft.value) === null || _g === void 0 ? void 0 : _g.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid }, { children: _jsx(BirthdateDaysInput, { before: before, onChangeBefore: handleChangeBefore, after: after, onChangeAfter: handleChangeAfter, onKeyDown: handleKeyDown }) })));
});
