export const textHandler = (text, variables, callbacks) => {
    const { onLine, onVariable, onLink, onSpace, onText } = callbacks;
    const lines = text.split('\n');
    for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        const textAndLinks = findLinks(line);
        const words = onLink ? splitOnWords(textAndLinks) : line.split(' ');
        onLine(i);
        for (let j = 0; j < words.length; j += 1) {
            const word = words[j];
            if (word) {
                const link = onLink ? extractLink(word) : null;
                if (link)
                    onLink === null || onLink === void 0 ? void 0 : onLink(link.text, link.link);
                else {
                    const parts = findVariables(word, variables);
                    for (let k = 0; k < parts.length; k += 1) {
                        const part = parts[k];
                        const v = isVariable(part, variables);
                        if (v)
                            onVariable(v);
                        else
                            onText(part);
                    }
                }
            }
            if (onLink)
                if (j !== words.length - 1 &&
                    !words[j + 1].startsWith('<a') &&
                    !words[j].endsWith('</a>'))
                    onSpace();
            if (!onLink)
                if (j !== words.length - 1)
                    onSpace();
        }
    }
};
const findLinks = (text) => {
    const result = [];
    let subject = text;
    const re = /<a(.*?)<\/a>/;
    let match = subject.match(re);
    while ((match === null || match === void 0 ? void 0 : match.index) !== undefined) {
        const link = match[0];
        const index = match.index;
        result.push(subject.slice(0, index));
        result.push(link);
        subject = subject.slice(index + link.length);
        match = subject.match(re);
    }
    if (subject)
        result.push(subject);
    return result.length ? result.filter(Boolean) : [text];
};
const splitOnWords = (parts) => {
    const words = [];
    for (let i = 0; i < parts.length; i += 1) {
        const part = parts[i];
        if (part.startsWith('<a'))
            words.push(part);
        else
            words.push(...part.split(' '));
    }
    return words;
};
const extractLink = (word) => {
    if (!word.startsWith('<a'))
        return null;
    const textMath = word.match(/>(.*?)</);
    const linkMatch = word.match(/href="(.*?)"/);
    if (textMath && linkMatch) {
        const text = textMath[1];
        const link = linkMatch[1];
        if (text && link)
            return { text, link };
    }
    return null;
};
const findVariables = (text, variables) => {
    const varIds = variables.map((v) => `!${v.id}`);
    const varRe = new RegExp(`(${varIds.join('|')})`);
    return text.split(varRe).filter(Boolean);
};
const isVariable = (text, variables) => {
    const variable = variables.find((v) => `!${v.id}` === text);
    return variable || null;
};
