import { textToJSON } from './textToJSON';
export const escapedTextToJSON = (text, variables, vars) => {
    let varIdx = 0;
    let replaced = '';
    for (let i = 0; i < text.length; i += 1) {
        if (text[i] === '$') {
            const prev1 = text[i - 1] || '';
            const prev2 = text[i - 2] || '';
            const isVar = prev2 + prev1 === '\\\\' || prev1 !== '\\';
            if (isVar) {
                const v = vars[varIdx++] || null;
                const variable = variables.find((vi) => vi.key === (v === null || v === void 0 ? void 0 : v.var));
                if (variable) {
                    variable.params = v === null || v === void 0 ? void 0 : v.params;
                    replaced += `!${variable.id}`;
                    continue;
                }
            }
        }
        replaced += text[i];
    }
    replaced = replaced.replace(/\\\$/g, '$').replace(/\\\\/g, '\\');
    return textToJSON(replaced, variables);
};
