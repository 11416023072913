import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { MailingsForm } from '@/app/pages/MailingsForm/MailingsForm';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Drawer } from '@/components/Drawer/Drawer';
import { FlowFormHeader } from '@/components/flow/FlowFormHeader/FlowFormHeader';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { useDeleteFlow } from '../../Flows/useDeleteFlow';
import { useAddFlow } from './useAddFlow';
import { useSetFlow } from './useSetFlow';
export const AutoMailingsFlowHeader = ({ flow, triggerType, mailings, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const userHasAccessTo = useUserPermissions();
    const userHasMailingsManage = userHasAccessTo('AUTO_MAILINGS_MANAGE');
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const [addMode, setAddMode] = useState(false);
    const handleGoBack = () => {
        var _a;
        if (((_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === 'auto-mailings-list')
            navigate(ROUTES.MAILINGS_AUTOMATIC);
        else
            navigate(ROUTES.FLOWS);
    };
    const { createFlow, isCreatingFlow } = useAddFlow({
        onSuccess: (id) => {
            var _a;
            const path = generatePath(ROUTES.FLOWS_EDIT, { flowId: id.toString() });
            navigate(path, {
                state: { mailingIds: (_a = location.state) === null || _a === void 0 ? void 0 : _a.mailingIds },
            });
        },
    });
    const handleCreateFlow = (title) => {
        createFlow(title, triggerType, mailings.filter((m) => m.triggerType === triggerType).map((m) => m.id));
    };
    const { deleteFlow, isDeletingFlow } = useDeleteFlow(handleGoBack);
    const { updateFlow, isUpdatingFlow } = useSetFlow();
    const primaryButton = (_jsx(Tooltip, Object.assign({ title: !userHasMailingsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), theme: 'primary', size: 'large', onClick: () => setAddMode(true), disabled: !userHasMailingsManage }, { children: t('Добавить рассылку') })) })));
    return (_jsxs(_Fragment, { children: [_jsx(FlowFormHeader, { flow: flow, userHasFlowsManage: userHasFlowsManage, rightContent: primaryButton, onCreateFlow: handleCreateFlow, isCreatingFlow: isCreatingFlow, onUpdateFlow: updateFlow, isUpdatingFlow: isUpdatingFlow, deleteButtonLabel: t('Распустить'), onDeleteFlow: deleteFlow, isDeletingFlow: isDeletingFlow, onGoBack: handleGoBack }), _jsx(Drawer, Object.assign({ open: addMode, onClose: () => setAddMode(false), anchor: 'left' }, { children: addMode && (_jsx(MailingsForm, { flow: flow, onGoBack: () => setAddMode(false), isStandalone: true })) }))] }));
};
