import { useGetSegmentListQuery } from '@/api/query/segment';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useSegmentList = (searchText) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data, isLoading, isFetching } = useGetSegmentListQuery({ searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    const { statsPerSegment, overallStats } = data || {};
    return {
        segments: statsPerSegment,
        total: overallStats,
        isLoading,
        isFetching,
    };
};
