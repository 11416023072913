import Popper from '@mui/material/Popper';
import { styled } from '@mui/system';
export const StyledPopper = styled(Popper) `
  /* z-index для совместимости с контентом ангуляра .main-content  */
  z-index: 11111;
  border: 1px solid rgb(0 0 0 / 15%);
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  font-size: 12px;

  --border-color: #d9d9d9;

  .arrow,
  .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  .arrow {
    border-width: 11px;
  }

  .arrow:after {
    content: '';
    border-width: 10px;
  }

  &[data-popper-placement*='top'] > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: var(--border-color);
    border-bottom-width: 0;
  }

  &[data-popper-placement*='top'] > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: ' ';
    border-top-color: #fff;
    border-bottom-width: 0;
  }

  &[data-popper-placement*='top-start'] > .arrow {
    left: 16px;
  }

  &[data-popper-placement*='top-end'] > .arrow {
    left: auto;
    right: 6px;
  }

  &[data-popper-placement*='right'] > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: var(--border-color);
    border-left-width: 0;
  }

  &[data-popper-placement*='right'] > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: ' ';
    border-right-color: #fff;
    border-left-width: 0;
  }

  &[data-popper-placement*='bottom'] > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: var(--border-color);
  }

  &[data-popper-placement*='bottom'] > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: ' ';
    border-top-width: 0;
    border-bottom-color: #fff;
  }

  &[data-popper-placement*='bottom-start'] > .arrow {
    left: 16px;
  }

  &[data-popper-placement*='bottom-end'] > .arrow {
    left: auto;
    right: 6px;
  }

  &[data-popper-placement*='left'] > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: var(--border-color);
  }

  &[data-popper-placement*='left'] > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: ' ';
    border-right-width: 0;
    border-left-color: #fff;
  }

  /* HACK: для совместимости со старой вёрсткой выпадаек */
  .dropdown-menu {
    display: block;
    position: relative;
    border: none;
    margin: 0;
  }
`;
