import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { sidebarIsCollapsedSelector } from '@/store/selectors/sidebar';
import { hideHeader, showHeader } from '@/store/slice/header';
import { collapseSidebar, expandSidebar } from '@/store/slice/sidebar';
export const useGlobalUI = () => {
    const dispatch = useAppDispatch();
    const sidebarIsCollapsed = useAppSelector(sidebarIsCollapsedSelector);
    useEffect(() => {
        dispatch(hideHeader());
        dispatch(collapseSidebar());
        return () => {
            dispatch(showHeader());
            if (!sidebarIsCollapsed)
                dispatch(expandSidebar());
        };
    }, []);
};
