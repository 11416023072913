import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/hooks/store';
import { appPeriodSelector } from '@/store/selectors/appPeriod';
import { addToLS } from '@/utils/LS';
import { createHref, ROUTES } from '../routes';
export const useOpenPurchaseFilter = () => {
    var _a;
    const { t } = useTranslation();
    const period = useAppSelector(appPeriodSelector);
    const purchaseUrl = createHref(ROUTES.PURCHASES) +
        '?periodStart=' +
        period.start.format('YYYY-MM-DD') +
        '&periodEnd=' +
        period.end.format('YYYY-MM-DD');
    const openPurchaseFilter = (mailings) => {
        const text = mailings.length > 1
            ? `${t('Авторассылки')}: ${mailings[0].name}<i class="op-or2">или</i><i class="op-elipsis"></i> <i class="op-count">+${mailings.length - 1}</i>`
            : `${t('Авторассылки')}: ${mailings[0].name}`;
        const checkboxes = {};
        mailings.forEach((m) => (checkboxes[m.id] = true));
        const filters = [
            {
                code: 'purchaseMailingAuto',
                text,
                rawConditions: { op: 'include', checkboxes },
            },
        ];
        addToLS({ key: 'purchaseFilters', value: { filters, touched: true } });
        window.open(purchaseUrl, '_blank');
    };
    const origin = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin;
    return {
        purchaseUrl: purchaseUrl.replace(origin, ''),
        openPurchaseFilter,
    };
};
