import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { GiftCardWarning } from '../../Warnings/GiftCardWarning';
import { PromocodeWarning } from '../../Warnings/PromocodeWarning';
import formStyles from '../styles.module.scss';
import { useCalculate } from '../useCalculate';
export const ViberFormText = () => {
    var _a, _b;
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const { triggerType, selectedNode, validationErrorMap } = useInteractiveFlow();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const selectedNodeData = selectedNode.getData();
    const calculate = useCalculate();
    const [viberText, setViberText] = useState(((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberText) || '');
    const updateViberText = (viberText) => {
        selectedNode.update({
            viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberText }),
        });
        calculate({ viberText }).then((response) => {
            selectedNode.update({
                viber: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber), { viberText, viberTextLength: response.viberTextLength }),
            });
        });
    };
    const handleUpdateViberText = () => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _a === void 0 ? void 0 : _a.viberText) !== viberText)
            updateViberText(viberText);
    };
    const editorContext = {
        channel: 'viber',
        triggerType: triggerType,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
    };
    return (_jsxs("div", { children: [_jsx(MessageEditor, { initialValue: viberText, onChange: setViberText, onBlur: handleUpdateViberText, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.viberText) }), (errors === null || errors === void 0 ? void 0 : errors.viberText) ? (_jsx("div", Object.assign({ className: formStyles.error }, { children: errors.viberText }))) : (_jsx("div", Object.assign({ className: formStyles.help }, { children: t('Длина:') +
                    ' ' +
                    (((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.viber) === null || _b === void 0 ? void 0 : _b.viberTextLength) || 0) +
                    '/1000' }))), _jsx(PromocodeWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: viberText }), _jsx(GiftCardWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: viberText })] }));
};
