var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useAddImportMutation } from '@/api/query/import';
import { useToastContext } from '@/components/Toast/ToastContext';
const createFileFromString = (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    return new File([blob], '__buffer.csv');
};
export const useAddImport = (onSuccess) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [addImport, { isLoading }] = useAddImportMutation();
    const handleAddImport = (type, data) => __awaiter(void 0, void 0, void 0, function* () {
        const title = typeof data === 'string'
            ? t('Загрузка из буфера обмена')
            : t('Загрузка из') + ' ' + data.name;
        const file = typeof data === 'string' ? createFileFromString(data) : data;
        const result = yield addImport({
            type,
            title,
            file,
        });
        if ('error' in result)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result.data);
    });
    return { addImport: handleAddImport, isAdding: isLoading };
};
