import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Warning } from '@/icons/Warning';
import { pct } from '@/utils/calculatePercent';
import { numberRound } from '@/utils/numbers';
import { goalDescriptions } from './goalDescriptions';
import styles from './styles.module.scss';
export const MailingStatsTable = memo(({ mailing, stats, isManual, currency, isLoading }) => {
    const { t } = useTranslation();
    const total = stats.totals;
    const channels = Object.keys(stats.channels);
    const columns = [
        {
            id: 'channels',
            title: t('Каналы'),
            tooltip: t('TOOLTIP_MAILING_CHANNELS'),
        },
        isManual
            ? {
                id: 'waiting',
                title: t('Ожидают'),
                align: 'right',
                tooltip: (_jsxs(_Fragment, { children: [t('Сообщения могут ожидать отправки, если они:'), _jsxs("ul", Object.assign({ className: styles.waitingTooltip }, { children: [_jsx("li", { children: t('находятся в очереди и будут обработаны в ближайшее время;') }), _jsx("li", { children: t('отложены на утро с учетом часового пояса получателя.') })] }))] })),
            }
            : null,
        {
            id: 'sent',
            title: t('Отправлено'),
            align: 'right',
        },
        {
            id: 'delivered',
            title: t('Доставлено'),
            align: 'right',
        },
        {
            id: 'opens',
            title: 'OR',
            align: 'right',
            tooltip: t('Open Rate — количество просмотренных сообщений, а также доля просмотренных от числа доставленных.'),
        },
        {
            id: 'clicks',
            title: 'CTOR',
            align: 'right',
            tooltip: t('Click-to-Open Rate — количество сообщений, в которых получатель перешел по ссылке, а также доля таких сообщений от числа просмотренных.'),
        },
        {
            id: 'unsubs',
            title: t('Отписок'),
            tooltip: t('Количество сообщений, в которых получатель нажал «отписаться» или «спам», а также доля таких сообщений от количества доставленных.'),
            align: 'right',
        },
        {
            id: 'cr',
            title: t('CR'),
            tooltip: t('TOOLTIP_MAILING_POPUP_CR', {
                goalDescr: t(goalDescriptions[mailing.goalType]),
            }),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма затрат по всем каналам рассылки'),
            align: 'right',
        },
    ].filter((i) => i !== null);
    const channelNames = {
        email: t('Email'),
        sms: t('SMS'),
        viber: t('Viber'),
        push: t('Push'),
        telegram: t('Telegram'),
    };
    const rows = channels.map((channel) => {
        const rowData = stats.channels[channel];
        const delivered = rowData.delivered !== null ? pct(rowData.delivered, rowData.sent) : null;
        const unsubs = rowData.delivered !== null
            ? pct(rowData.unsubs, rowData.delivered)
            : null;
        const opens = rowData.opens !== null
            ? pct(rowData.opens, rowData.delivered || 0)
            : null;
        const clicks = rowData.clicks !== null
            ? pct(rowData.clicks, rowData.opens !== null && +rowData.opens > 0
                ? rowData.opens
                : rowData.delivered || 0)
            : null;
        return {
            id: channel,
            cells: [
                _jsx("div", Object.assign({ className: styles.channelWrapper }, { children: _jsx("span", Object.assign({ className: classNames(styles.channelName) }, { children: channelNames[channel] })) }), 'channel'),
                isManual ? _jsx(_Fragment, { children: rowData.queued }) : null,
                _jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: +rowData.sent > 0,
                    }) }, { children: [+rowData.failed > 0 && (_jsx(Tooltip, Object.assign({ title: t('TOOLTIP_FAILED_COUNT', {
                                amount: numberRound(rowData.failed),
                                count: Number(rowData.failed),
                            }), placement: 'top' }, { children: _jsxs("span", { children: [_jsx(Warning, { className: styles.warning, width: 13, height: 13 }), ' '] }) }))), numberRound(rowData.sent), +rowData.sent > 0 && (_jsxs("div", Object.assign({ className: styles.info }, { children: [pct(rowData.sent, total.sent), "%"] })))] }), 'sent'),
                rowData.delivered !== null ? (_jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: Number(rowData.delivered) > 0,
                    }) }, { children: [rowData.delivered, _jsxs("div", Object.assign({ className: styles.info }, { children: [delivered, "%"] }))] }), 'delivered')) : (_jsx(_Fragment, { children: "\u2013" })),
                opens !== null ? (_jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: Number(rowData.delivered) > 0,
                    }) }, { children: [rowData.opens, _jsxs("div", Object.assign({ className: styles.info }, { children: [opens, "%"] }))] }), 'opens')) : (_jsx(_Fragment, { children: "\u2013" })),
                clicks !== null ? (_jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: Number(rowData.delivered) > 0,
                    }) }, { children: [rowData.clicks, _jsxs("div", Object.assign({ className: styles.info }, { children: [clicks, "%"] }))] }), 'clicks')) : (_jsx(_Fragment, { children: "\u2013" })),
                _jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: Number(rowData.delivered) > 0,
                    }) }, { children: [numberRound(rowData.unsubs), _jsxs("div", Object.assign({ className: styles.info }, { children: [unsubs, "%"] }))] }), 'unsubs'),
                _jsxs(_Fragment, { children: [rowData.cr, "%"] }),
                rowData.expenses !== null ? (_jsxs("div", Object.assign({ className: classNames({
                        [styles.infoWrapper]: +rowData.sent > 0,
                    }) }, { children: [_jsx(Expenses, { expenses: Number(rowData.expenses), currency: currency }, 'expenses'), +rowData.sent > 0 && (_jsxs("div", Object.assign({ className: styles.info }, { children: [pct(rowData.expenses, total.expenses), "%"] })))] }), 'expenses')) : (_jsx(_Fragment, { children: "\u2013" })),
            ].filter((i) => i !== null),
        };
    });
    const totalRow = [
        {
            id: 'total',
            isTotal: true,
            cells: [
                _jsx(_Fragment, { children: t('Итого') }),
                isManual ? _jsx(_Fragment, { children: total.queued }) : null,
                numberRound(total.sent),
                _jsxs(_Fragment, { children: [pct(total.delivered, total.sent), "%"] }),
                _jsxs(_Fragment, { children: [pct(total.opens, total.delivered), "%"] }),
                _jsxs(_Fragment, { children: [pct(total.clicks, +total.opens > 0 ? total.opens : total.delivered), "%"] }),
                numberRound(total.unsubs),
                _jsxs(_Fragment, { children: [total.cr, "%"] }),
                _jsx(Expenses, { expenses: Number(total.expenses), currency: currency }, 'expenses'),
            ].filter((i) => i !== null),
        },
    ];
    return (_jsx(Table, { className: styles.table, columns: columns, rows: rows, total: totalRow, isLoading: isLoading, isFixed: true }));
});
