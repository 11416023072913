import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterBadge } from '@/components/FiltersPanel/FilterBadge';
import { BonusMovement } from '@/icons/BonusMovement';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const BonusesAmount = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], currency, }) => {
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'bonuses')
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(FilterBadge, { ref: triggerRef, className: styles.badge, text: _jsxs("span", Object.assign({ className: styles.badgeContent }, { children: [_jsx(BonusMovement, { fill: '#3a3a3a' }), selectedItem.bonusesAmount, ' ', t('BONUSES_LABEL', {
                            count: Number(selectedItem.bonusesAmount) || 0,
                        })] })), onClose: handleReset, onClick: togglePopup, isActive: popupIsOpen }), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: 'bonuses', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
