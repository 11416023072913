import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useChart } from '@/hooks/useChart';
import styles from './styles.module.scss';
const baseConfig = {
    type: 'line',
    options: {
        animation: false,
        responsive: false,
        layout: {
            padding: { left: 7, bottom: 7 },
        },
        scales: {
            yAxis: { display: false },
            xAxis: { display: false },
        },
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
        },
    },
};
export const SparklineChart = memo(({ data, color = '#dfdfdf', width = 180, height = 50 }) => {
    const canvas = useChart(Object.assign(Object.assign({}, baseConfig), { data: {
            labels: data.map((_, i) => (i + 1).toString()),
            datasets: [
                {
                    yAxisID: 'yAxis',
                    xAxisID: 'xAxis',
                    data,
                    borderColor: color,
                    backgroundColor: color,
                    borderWidth: 4,
                    cubicInterpolationMode: 'monotone',
                    pointRadius: 0,
                },
            ],
        } }));
    return (_jsx("canvas", { ref: canvas, className: styles.chart, style: { width, height } }));
});
