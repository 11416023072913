import { api } from './';
export const billingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBillingStats: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/billing/stats',
                body,
            }),
            providesTags: ['BillingStats'],
        }),
    }),
});
export const { useGetBillingStatsQuery } = billingApi;
