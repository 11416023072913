import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
export const FormUsersCopyUserData = () => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const copyToClipboard = useCopyToClipboard({ showToast: true });
    const password = watch('password');
    const username = watch('username');
    const userDataForClipboard = username && password
        ? `${t('Логин')}: ${username} ${t('Пароль')}: ${password}`
        : '';
    return (_jsx(FormFieldRow, Object.assign({ controlSize: '250' }, { children: _jsx(Button, Object.assign({ size: 'full-width', disabled: !userDataForClipboard, type: 'button', onClick: () => copyToClipboard(userDataForClipboard) }, { children: t('Скопировать данные для входа') })) })));
};
