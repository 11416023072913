import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Table } from '@/components/Table/Table';
import { EVENT_BONUS_TYPES, EVENT_CLIENT_UPDATE_TYPES, EVENT_LOYALTY_TYPES, EVENT_MAILING_TYPES, EVENT_PURCHASE_TYPES, } from './constants';
import styles from './styles.module.scss';
export const getEventTitle = (eventGroup, params) => {
    if (params)
        switch (eventGroup) {
            case 'purchase': {
                return EVENT_PURCHASE_TYPES[params === null || params === void 0 ? void 0 : params.eventType];
            }
            case 'bonus': {
                return EVENT_BONUS_TYPES[params === null || params === void 0 ? void 0 : params.operation];
            }
            case 'client_update': {
                return EVENT_CLIENT_UPDATE_TYPES[params === null || params === void 0 ? void 0 : params.operation];
            }
            case 'loyalty': {
                return EVENT_LOYALTY_TYPES[params === null || params === void 0 ? void 0 : params.eventType];
            }
            case 'mailing': {
                return EVENT_MAILING_TYPES[params.type];
            }
        }
    return { title: '', tooltipTitle: '' };
};
// TODO: Убрать когда появятся настоящие id пользователей
const MOKK_CLIENT_ID = 53322;
export const EventsTable = memo(({ isLoading, eventsData, openOverview }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'date',
        },
        {
            id: 'time',
            title: t('Дата'),
        },
        {
            id: 'event-icon',
        },
        {
            id: 'event',
            title: t('Событие'),
        },
        {
            id: 'clients',
            title: t('Клиенты'),
        },
    ], [t]);
    const rows = eventsData.flatMap(([date, item]) => item
        // TODO: Уточнить может ли быть на бою событие без параметров
        .filter((event) => event.params)
        .map((event, index) => {
        const { title, color, tooltipTitle, Icon } = getEventTitle(event.eventGroup, event.params);
        const isTooltip = event.params.referralPurchaseClientId || event.params.operatorId;
        const tooltip = t(tooltipTitle || '', {
            operatorName: event.params.operatorName,
            referralPurchaseClientName: event.params.referralPurchaseClientName,
        });
        return {
            id: event.id,
            cells: [
                index === 0 ? (_jsx("span", Object.assign({ className: styles.eventsDate }, { children: date }))) : null,
                moment(event.createdAt).format('HH:mm'),
                _jsx(_Fragment, { children: Icon }),
                _jsxs("div", Object.assign({ className: styles.titleWrapper }, { children: [_jsx("span", { className: classNames(styles.eventTitle, {
                                [styles.negativeEvent]: color === 'red',
                                [styles.positiveEvent]: color === 'green',
                                [styles.neutralEvent]: color === 'gray',
                            }), dangerouslySetInnerHTML: {
                                __html: t(title, {
                                    // TODO: получить ссылки/id с бэка
                                    linkHref: 'ссылка',
                                    emailHref: 'cсылкаEmail',
                                    emailName: 'Название Email',
                                    segmentName: 'Название сегмента',
                                    level: 12,
                                    mailingName: 'Название рассылки',
                                    family: event.params.family && event.params.family === 'ios'
                                        ? 'Apple'
                                        : 'Google',
                                }),
                            } }), isTooltip && (_jsx(HelpTooltip, { className: styles.tooltipIcon, tooltipClassName: styles.tooltip, title: tooltip, size: 'medium', placement: 'top', hasLightIcon: true }, 'tooltip'))] }), 'title'),
                _jsx("button", Object.assign({ onClick: () => openOverview(MOKK_CLIENT_ID), className: styles.clientButton }, { children: event.params.clientName }), 'client'),
            ],
        };
    }));
    return (_jsx(Table, { className: styles.table, cellClassNames: {
            0: styles.eventsDateCell,
            1: styles.eventsTimeCell,
            2: styles.eventIconCell,
            3: styles.eventTitleCell,
        }, needAdaptive: false, isLoading: isLoading, rows: rows, columns: columns }));
});
