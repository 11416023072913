import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useChannelFormState } from './useChannelFormState';
export const ChannelFormTitle = () => {
    const { t } = useTranslation();
    const { isCascade, isPush, isSMS, isViber, isEmail, isTelegram } = useChannelFormState();
    let title = 'Push';
    if (isPush)
        title = 'Push';
    else if (isSMS)
        title = 'SMS';
    else if (isViber)
        title = 'Viber';
    else if (isEmail)
        title = 'Email';
    else if (isTelegram)
        title = 'Telegram';
    else if (isCascade)
        title = t('Каскад');
    return _jsx(_Fragment, { children: title });
};
