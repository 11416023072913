import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TRIGGER_TYPE_BIRTHDAY, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, } from '@/const/mailing-triggers';
import styles from '../styles.module.scss';
export const FormMailingOffsetHelp = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const offsetSign = watch('offsetSign');
    const offsetValue = watch('offsetValue') || 0;
    const offsetUnit = watch('offsetUnit');
    const triggerType = watch('triggerType');
    let offsetUnitText = '';
    if (offsetUnit === 'minute')
        offsetUnitText = t('OFFSET_VALUE_MIN', { offset: offsetValue });
    if (offsetUnit === 'hour')
        offsetUnitText = t('OFFSET_VALUE_HOUR', { offset: offsetValue });
    if (offsetUnit === 'day')
        offsetUnitText = t('OFFSET_VALUE_DAY', { offset: offsetValue });
    if (offsetUnit === 'month')
        offsetUnitText = t('OFFSET_VALUE_MONTH', { offset: offsetValue });
    let triggerText = '';
    if (triggerType === TRIGGER_TYPE_BIRTHDAY)
        triggerText = t('дня рождения');
    if (triggerType === TRIGGER_TYPE_CHILD_BIRTHDAY)
        triggerText = t('дня рождения ребенка');
    if (triggerType === TRIGGER_TYPE_BONUS_EXPIRING)
        triggerText = t('сгорания бонусов');
    const startText = t('Не сработает для тех, кто вступил в программу лояльности менее чем');
    const text = `${startText} ${t('за')} ${offsetValue} ${offsetUnitText} ${t('до')} ${triggerText}`;
    if (offsetSign === '-1' && offsetValue)
        return (_jsx(FormFieldRow, Object.assign({ labelWidth: labelWidth, controlSize: '320' }, { children: _jsx("p", Object.assign({ className: styles.offsetHelp }, { children: text })) })));
    return null;
};
