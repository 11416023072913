var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useCallback, useState } from 'react';
import { ShopGroupInputForm } from './ShopGroupInputForm';
import { ShopGroupInputList } from './ShopGroupInputList';
import { ShopGroupInputPopup } from './ShopGroupInputPopup';
import { ShopGroupInputTrigger } from './ShopGroupInputTrigger';
export const ShopGroupInput = ({ id, value, onChange, shopGroups, onAddGroup, onUpdateGroup, onDeleteGroup, hasError, placeholder, }) => {
    const [contentWidth, setContentWidth] = useState(0);
    const handleInputInitialized = useCallback((input) => {
        setContentWidth(input.clientWidth);
    }, []);
    const [selectedToChangeGroupId, setSelectedToChangeGroupId] = useState(null);
    const selectedToChangeGroup = shopGroups.find((group) => group.id === selectedToChangeGroupId);
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        setSelectedToChangeGroupId(null);
    }, []);
    const handleToggle = useCallback(() => {
        setIsOpen((isOpen) => !isOpen);
        setSelectedToChangeGroupId(null);
    }, []);
    const handleChange = useCallback((shopGroupId) => {
        onChange(shopGroupId);
        handleClose();
    }, [onChange]);
    const handleReset = useCallback(() => {
        onChange(null);
        handleClose();
    }, [onChange]);
    const handleAddGroup = useCallback((groupName) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const newGroup = yield onAddGroup(groupName);
            handleChange(newGroup.id);
        }
        catch (_a) {
            // do nothing
        }
    }), [onAddGroup]);
    const handleUpdateGroup = useCallback((groupId, groupName) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield onUpdateGroup(groupId, groupName);
            setSelectedToChangeGroupId(null);
        }
        catch (_b) {
            // do nothing
        }
    }), [onUpdateGroup]);
    const handleDeleteGroup = useCallback((groupId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield onDeleteGroup(groupId);
            setSelectedToChangeGroupId(null);
            if (groupId === value)
                onChange(null);
        }
        catch (_c) {
            // do nothing
        }
    }), [onDeleteGroup, onChange]);
    const handleStopEscape = useCallback((e) => {
        if (e.code === 'Escape') {
            e.stopPropagation();
            handleClose();
        }
    }, [handleClose]);
    const valueGroup = shopGroups.find((group) => group.id === value);
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: handleClose }, { children: _jsx(ShopGroupInputPopup, Object.assign({ isOpen: isOpen, onOpen: handleOpen, onClose: handleClose, content: _jsx("div", Object.assign({ onKeyDown: handleStopEscape }, { children: selectedToChangeGroup ? (_jsx(ShopGroupInputForm, { shopGroup: selectedToChangeGroup, onUpdateShopGroup: handleUpdateGroup, onDeleteShopGroup: handleDeleteGroup, width: contentWidth })) : (_jsx(ShopGroupInputList, { shopGroups: shopGroups, selectedShopGroupId: value, onSelectShopGroup: handleChange, onSelectShopGroupToChange: setSelectedToChangeGroupId, onAddGroup: handleAddGroup, width: contentWidth, height: 250 })) })) }, { children: _jsx(ShopGroupInputTrigger, { id: id, isOpen: isOpen, value: (valueGroup === null || valueGroup === void 0 ? void 0 : valueGroup.name) || '', onClick: handleToggle, onReset: handleReset, onInputInitialized: handleInputInitialized, hasError: hasError, placeholder: placeholder }) })) })));
};
