import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { EmailNotClicked } from '@/icons/flow/EmailNotClicked';
import { EmailNotOpened } from '@/icons/flow/EmailNotOpened';
import { EmailNotPurchased } from '@/icons/flow/EmailNotPurchased';
import { NodeDeleteButton } from '../../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodeRow } from '../../helpers/NodeRow/NodeRow';
import { NodeTitle } from '../../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../../helpers/NodeWrapper/NodeWrapper';
import styles from './styles.module.scss';
export const EmailReceivedNode = ({ id, data }) => {
    const { t } = useTranslation();
    const { type, hasYesCondition, plusButton, onDelete, canBeDeleted } = data;
    let header = '';
    let icon = _jsx(_Fragment, {});
    switch (type) {
        case 'not_opened': {
            header = 'Открыл письмо?';
            icon = _jsx(EmailNotOpened, {});
            break;
        }
        case 'not_clicked': {
            header = 'Перешел по ссылке?';
            icon = _jsx(EmailNotClicked, {});
            break;
        }
        case 'not_purchased': {
            header = 'Сделал покупку';
            icon = _jsx(EmailNotPurchased, {});
            break;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, Object.assign({ gap: '12' }, { children: [_jsx(NodeTitle, Object.assign({ icon: icon, hasSmallPadding: true }, { children: t(header) })), _jsxs("div", { children: [_jsx(NodeRow, Object.assign({ nodeId: id, handleId: 'no', plusButton: plusButton }, { children: _jsx("div", Object.assign({ className: styles.no }, { children: t('нет') })) })), hasYesCondition && (_jsx(NodeRow, Object.assign({ nodeId: id, handleId: 'yes', plusButton: plusButton }, { children: _jsx("div", Object.assign({ className: styles.yes }, { children: t('да') })) })))] })] })), onDelete && (canBeDeleted === null || canBeDeleted === void 0 ? void 0 : canBeDeleted(id)) && (_jsx(NodeDeleteButton, { onClick: () => onDelete(id) })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
