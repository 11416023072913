var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { dashboardListSelector } from '@/store/selectors/reports';
import { deleteDashboard } from '@/store/thunk/reports';
export const useDeleteDashboard = (selectItem, addItem) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { data: dashboards = [] } = useAppSelector(dashboardListSelector);
    const addToast = useToastContext();
    return (id) => __awaiter(void 0, void 0, void 0, function* () {
        const item = dashboards.find((i) => i.id === id);
        const idx = dashboards.findIndex((i) => i.id === id);
        if (!item)
            return;
        const confirmTitle = t('Удалить отчет {{name}}?', { name: item.title });
        const confirmAgreeLabel = t('Удалить отчет');
        const successMessage = t('Отчет удален');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        try {
            const isDraft = id < 0;
            yield dispatch(deleteDashboard({ id, isSaved: !isDraft }));
            if (!isDraft)
                addToast({ type: 'success', message: successMessage });
            const prev = dashboards[idx - 1];
            const next = dashboards[idx + 1];
            if (prev)
                selectItem(prev.id);
            else if (next)
                selectItem(next.id);
            else
                addItem();
        }
        catch (error) {
            const message = typeof error === 'string'
                ? error
                : error === null || error === void 0 ? void 0 : error.message;
            addToast({ type: 'error', message: message || t('Произошла ошибка') });
        }
    });
};
