import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ChannelSMS } from '@/icons/ChannelSMS';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { FormMailingSMSSender } from './controls/FormMailingSMSSender';
import { FormMailingSMSTest } from './controls/FormMailingSMSTest';
import { FormMailingSMSText } from './controls/FormMailingSMSText';
import styles from './styles.module.scss';
export const FormMailingStepSMS = ({ textLength, messagesCount, onSendTestSMS, brand, currency, labelWidth, }) => {
    return (_jsxs(FormFieldset, Object.assign({ title: _jsxs("div", Object.assign({ className: styles.channelTitle }, { children: [_jsx("div", Object.assign({ className: classNames(styles.channel, styles.sms) }, { children: _jsx(ChannelSMS, {}) })), "SMS"] })), titleOffset: labelWidth }, { children: [_jsx(FormMailingSMSSender, { brand: brand, labelWidth: labelWidth }), _jsx(FormMailingSMSText, { length: textLength, messages: messagesCount, hasWalletFeature: brandHasWalletFeature(brand), currency: currency, labelWidth: labelWidth }), _jsx(FormMailingSMSTest, { onSendTestSMS: onSendTestSMS, labelWidth: labelWidth })] })));
};
