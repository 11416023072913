import { useEffect, useState } from 'react';
import { useDebounce } from '@/hooks/useDebounce';
export const useCalculate = (handler, methods) => {
    const { watch, getValues } = methods;
    const [calculateResult, setCalculateResult] = useState({});
    const recipientAllClients = watch('recipientAllClients');
    const recipientFilters = watch('recipientFilters');
    const triggerFilters = watch('triggerFilters');
    const triggerType = watch('triggerType');
    const pushText = watch('pushText');
    const viberText = watch('viberText');
    const telegramText = watch('telegramText');
    const smsText = watch('smsText');
    useDebounce({
        value: pushText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: viberText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: smsText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useDebounce({
        value: telegramText || '',
        callback: () => {
            handler(getValues()).then(setCalculateResult);
        },
    });
    useEffect(() => {
        handler(getValues()).then(setCalculateResult);
    }, [
        recipientAllClients,
        JSON.stringify(recipientFilters),
        JSON.stringify(triggerFilters),
        triggerType,
    ]);
    return calculateResult;
};
