import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { WalletCardFormErrors } from '@/components/wallet-cards/WalletCardFormErrors/WalletCardFormErrors';
import { WalletCardPublish as WalletCardPublishComponent } from '@/components/wallet-cards/WalletCardPublish/WalletCardPublish';
import { useWalletCardPublish } from './useWalletCardPublish';
import { useWalletCardPublishErrors } from './useWalletCardPublishErrors';
import { useWalletCardPublishInfo } from './useWalletCardPublishInfo';
export const WalletCardPublish = () => {
    const { publish } = useWalletCardPublish();
    const { errors, shouldDisplayErrors, toggleShouldDisplayErrors } = useWalletCardPublishErrors();
    const handlePublish = () => {
        toggleShouldDisplayErrors();
        if (errors.length)
            return Promise.resolve('validation-errors');
        return publish();
    };
    const { isFirstPublish, walletCardsDefined, walletGeosDefined, hasChangesWalletCards, hasChangesWalletGeos, isLoadingCards, } = useWalletCardPublishInfo();
    if (isLoadingCards)
        return null;
    return (_jsxs(_Fragment, { children: [shouldDisplayErrors && _jsx(WalletCardFormErrors, { errors: errors }), _jsx(WalletCardPublishComponent, { isFirstPublish: isFirstPublish, walletCardsDefined: walletCardsDefined, walletGeosDefined: walletGeosDefined, hasChangesWalletCards: hasChangesWalletCards, hasChangesWalletGeos: hasChangesWalletGeos, hasErrors: shouldDisplayErrors, onPublish: handlePublish })] }));
};
