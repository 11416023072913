var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { Popper } from '@mui/material';
import classNames from 'classnames';
import { useRef } from 'react';
import { Input } from '@/components/Input/Input';
import { Chevron } from '@/icons/Chevron';
import styles from '../styles.module.scss';
export const AutocompleteFreeSolo = (_a) => {
    var _b;
    var { id, className, disabled, value, onChange, options, minListWidth } = _a, props = __rest(_a, ["id", "className", "disabled", "value", "onChange", "options", "minListWidth"]);
    const wrapperRef = useRef(null);
    const { popupOpen: _popupOpen, getRootProps, getInputProps, getListboxProps, getOptionProps, getPopupIndicatorProps, groupedOptions, } = useAutocomplete({
        id,
        value,
        options,
        freeSolo: true,
        onInputChange: (_, value) => {
            onChange(value);
        },
        onChange: (_, value) => {
            onChange(value || '', true);
        },
    });
    const filteredOptions = groupedOptions;
    const popupOpen = _popupOpen && filteredOptions.length > 0;
    const listBox = (_jsx(Popper, Object.assign({ className: styles.popper, open: popupOpen, anchorEl: wrapperRef.current, modifiers: [{ name: 'offset', options: { offset: [0, 7] } }] }, { children: _jsx("div", Object.assign({ className: styles.listWrapper, style: { minWidth: minListWidth || ((_b = wrapperRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth) } }, { children: _jsx("ul", Object.assign({ className: styles.list }, getListboxProps(), { children: filteredOptions.map((option, index) => {
                    return (_jsx("li", Object.assign({ className: styles.item }, getOptionProps({ option, index }), { children: option }), option));
                }) })) })) })));
    const icon = (_jsx("div", Object.assign({ className: classNames(styles.icon, {
            [styles.flipped]: popupOpen,
            [styles.disabled]: disabled,
        }) }, getPopupIndicatorProps(), { children: _jsx(Chevron, { fill: 'currentColor' }) })));
    return (_jsx("div", Object.assign({ className: classNames(styles.wrapper, className), ref: wrapperRef }, { children: _jsx("div", Object.assign({ className: classNames(styles.input, styles.freeSolo) }, getRootProps(), { children: _jsx(Input, Object.assign({}, props, getInputProps(), { disabled: disabled, rightElement: _jsxs(_Fragment, { children: [icon, listBox] }) })) })) })));
};
