import moment from 'moment';
import { DAYS } from '@/components/DaysSelect/DaysSelect';
import { parseDaysNumber } from '@/components/DaysSelect/parseDaysNumber';
import { formatDay } from '@/utils/dates';
export const getScheduleNodeHeader = (type) => {
    switch (type) {
        case 'daily': {
            return 'Каждый день';
        }
        case 'weekly': {
            return 'Каждую неделю';
        }
        case 'monthly': {
            return 'Каждый месяц';
        }
        case 'yearly': {
            return 'Каждый год';
        }
    }
};
const DAYS_MAP = {
    [DAYS.WEEKDAY_MON]: 1,
    [DAYS.WEEKDAY_TUE]: 2,
    [DAYS.WEEKDAY_WED]: 3,
    [DAYS.WEEKDAY_THU]: 4,
    [DAYS.WEEKDAY_FRI]: 5,
    [DAYS.WEEKDAY_SAT]: 6,
    [DAYS.WEEKDAY_SUN]: 7,
};
export const createScheduleCondition = (options) => {
    const { scheduleType, scheduleTime, scheduleWeekdays, scheduleDays, scheduleDate, } = options;
    switch (scheduleType) {
        case 'daily': {
            return `в ${scheduleTime}`;
        }
        case 'weekly': {
            const days = parseDaysNumber(scheduleWeekdays);
            const dayLabels = days.map((d) => formatDay(DAYS_MAP[d], 'ru', days.length > 1));
            return `${dayLabels.join(', ')} в ${scheduleTime}`;
        }
        case 'monthly': {
            return `${scheduleDays.join(', ')} числа в ${scheduleTime}`;
        }
        case 'yearly': {
            const date = moment(scheduleDate).locale('ru').format('D MMMM');
            return `${date} в ${scheduleTime}`;
        }
    }
};
export const createScheduleNode = (type, mailing) => {
    return {
        id: `trigger-schedule-${type}-${mailing.id}`,
        type: 'conditions',
        data: {
            type: 'schedule',
            header: getScheduleNodeHeader(type),
            conditions: [],
        },
        position: { x: 0, y: 0 },
    };
};
export const createScheduleNodes = (branches) => {
    var _a;
    const triggerConditionNodes = [];
    const triggerConditionEdges = [];
    const triggerConditionBranches = {};
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const nodeMap = {};
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                const options = (((_a = mailing.triggerConditions) === null || _a === void 0 ? void 0 : _a.conditions) ||
                    {});
                const type = options.scheduleType;
                if (!nodeMap[type]) {
                    const node = createScheduleNode(type, mailing);
                    nodeMap[type] = node;
                    triggerConditionNodes.push(node);
                    triggerConditionEdges.push({
                        id: `from-${fromNodeId}-to-${node.id}`,
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: node.id,
                    });
                }
                const node = nodeMap[type];
                const condition = createScheduleCondition(options);
                if (!node.data.conditions.includes(condition))
                    node.data.conditions.push(condition);
                if (!triggerConditionBranches[node.id])
                    triggerConditionBranches[node.id] = {};
                if (!triggerConditionBranches[node.id][condition])
                    triggerConditionBranches[node.id][condition] = [];
                triggerConditionBranches[node.id][condition].push(mailing);
            }
        }
    }
    return {
        triggerConditionNodes,
        triggerConditionEdges,
        triggerConditionBranches,
    };
};
