import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Pagination } from '@/components/Pagination/Pagination';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { GiftCardsTable } from './GiftCardsTable';
export const GiftCardsPanel = memo(({ count, items, total, currency, isProfitVisible, isLoading = false, isFetching = false, onGoToCreateForm, onGoToEditForm, onGoToInstances, onExportCard, onDeleteCard, limit, offset, onChangeOffset, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasGiftCardsManage = userHasAccessTo([
        'GIFTCARDS_MANAGE',
        'GIFTCARDS_INSTANCES_MANAGE',
    ]);
    const createButton = (_jsx(Tooltip, Object.assign({ title: !userHasGiftCardsManage && t('Недоступно для вашей роли'), placement: 'top' }, { children: _jsx(Button, Object.assign({ icon: _jsx(Plus, {}), onClick: onGoToCreateForm, theme: 'primary', size: 'extra-large', disabled: !userHasGiftCardsManage }, { children: t('Создать карту') })) })));
    const pagination = !!count && (_jsx(Pagination, { total: count, limit: limit, offset: offset, onChangeOffset: onChangeOffset }));
    return (_jsx(TabsPanel, Object.assign({ tabs: [
            {
                id: 'gift-cards',
                label: t('GIFT_CARDS', { count }),
            },
        ], rightContent: createButton, footer: pagination, isSkeleton: isLoading }, { children: _jsx(GiftCardsTable, { items: items, total: total, currency: currency, isProfitVisible: isProfitVisible, isLoading: isFetching, onCardNameClick: onGoToInstances, onCardEditClick: onGoToEditForm, onCardExportClick: onExportCard, onCardDeleteClick: onDeleteCard }) })));
});
