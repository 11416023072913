import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Email } from '@/icons/flow/Email';
import { Pause } from '@/icons/flow/Pause';
import { Push } from '@/icons/flow/Push';
import { SMS } from '@/icons/flow/SMS';
import { Telegram } from '@/icons/flow/Telegram';
import { Viber } from '@/icons/flow/Viber';
import { NodeBody } from '../../helpers/NodeBody/NodeBody';
import { NodeDeleteButton } from '../../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodeTitle } from '../../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../../helpers/NodeWrapper/NodeWrapper';
import styles from './styles.module.scss';
export const ChannelNode = ({ id, data, selected, }) => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { channels, content, status = 'active', hasOutput = false, plusButton: PlusButton, onClick, onDelete, canBeDeleted, } = data;
    const isPush = channels.length === 1 && channels[0] === 'push';
    const isSMS = channels.length === 1 && channels[0] === 'sms';
    const isViber = channels.length === 1 && channels[0] === 'viber';
    const isEmail = channels.length === 1 && channels[0] === 'email';
    const isTelegram = channels.length === 1 && channels[0] === 'telegram';
    const isCascade = channels.length > 1;
    let title = 'Push';
    let icon = _jsx(Push, {});
    if (isPush) {
        title = 'Push';
        icon = _jsx(Push, {});
    }
    else if (isSMS) {
        title = 'SMS';
        icon = _jsx(SMS, {});
    }
    else if (isViber) {
        title = 'Viber';
        icon = _jsx(Viber, {});
    }
    else if (isEmail) {
        title = 'Email';
        icon = _jsx(Email, {});
    }
    else if (isTelegram) {
        title = 'Telegram';
        icon = _jsx(Telegram, {});
    }
    else if (isCascade) {
        title = t('Каскад');
        icon = (_jsx("div", Object.assign({ className: styles.cascadeIcon }, { children: channels.map((channel, i) => {
                return (_jsxs("div", Object.assign({ style: { left: -8 * i } }, { children: [channel === 'push' && _jsx(Push, {}, 'push'), channel === 'sms' && _jsx(SMS, {}, 'sms'), channel === 'viber' && _jsx(Viber, {}, 'viber'), channel === 'email' && _jsx(Email, {}, 'email')] }), channel + i));
            }) })));
    }
    const handleClick = useCallback(() => {
        onClick === null || onClick === void 0 ? void 0 : onClick(id);
    }, [id, onClick]);
    const hasOutputEdges = flow.getEdges().filter((e) => e.source === id).length > 0;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames({
                    [styles.cascadeWrapper]: isCascade,
                    [styles.selected]: !onClick && selected,
                }) }, { children: [_jsxs(NodeWrapper, Object.assign({ className: classNames(styles.wrapper, {
                            [styles.push]: isPush,
                            [styles.sms]: isSMS,
                            [styles.viber]: isViber,
                            [styles.email]: isEmail,
                            [styles.telegram]: isTelegram,
                            [styles.selected]: !onClick && selected,
                        }), isSelected: !onClick && selected, isInteractive: true, onClick: handleClick }, { children: [_jsxs(NodeTitle, Object.assign({ className: styles.title, iconClassName: classNames({
                                    [styles.telegramIcon]: isTelegram,
                                }), icon: icon, isMuted: status === 'pause' }, { children: [_jsx("span", Object.assign({ className: styles.cascadeTitle, style: { left: -8 * (channels.length - 1) } }, { children: title })), status === 'pause' && _jsx(Pause, {})] })), _jsx(NodeBody, Object.assign({ className: styles.body }, { children: content }))] })), _jsx("div", { className: styles.cascadeBorder })] })), PlusButton && _jsx(PlusButton, { nodeId: id }), onDelete && (canBeDeleted === null || canBeDeleted === void 0 ? void 0 : canBeDeleted(id)) && (_jsx(NodeDeleteButton, { onClick: () => onDelete(id) })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', {
                    'flow-handle-cascade': isCascade,
                }), type: 'target', position: Position.Left }), _jsx(Handle, { className: classNames('flow-handle', {
                    'flow-handle-cascade': isCascade,
                    'flow-handle-hidden': !hasOutput || !hasOutputEdges,
                }), type: 'source', position: Position.Right })] }));
};
