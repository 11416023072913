import { createSlice } from '@reduxjs/toolkit';
const shopGroupsSliceInitialState = {
    shopGroups: [],
};
const shopGroupsSlice = createSlice({
    name: 'shopGroups',
    initialState: shopGroupsSliceInitialState,
    reducers: {
        setShopGroups: (state, action) => {
            state.shopGroups = action.payload.shopGroups;
        },
    },
});
export default shopGroupsSlice.reducer;
export const { setShopGroups } = shopGroupsSlice.actions;
