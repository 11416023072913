var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './styles.module.scss';
export const NodeTitle = (_a) => {
    var { className, children, icon, iconClassName, hasSmallPadding = false, isMuted = false } = _a, otherProps = __rest(_a, ["className", "children", "icon", "iconClassName", "hasSmallPadding", "isMuted"]);
    return (_jsxs("div", Object.assign({ className: classNames(className, styles.title, {
            [styles.smallPadding]: hasSmallPadding,
            [styles.muted]: isMuted,
        }) }, otherProps, { children: [!!icon && (_jsx("span", Object.assign({ className: classNames(styles.icon, iconClassName) }, { children: icon }))), _jsx("span", Object.assign({ className: styles.content }, { children: children }))] })));
};
