import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { cloneElement, memo, useCallback, useState } from 'react';
import { AppNavDropdownItem } from './AppNavDropdownItem';
import styles from './styles.module.scss';
export const AppNavDropdown = memo(({ title, className, items, offset, preventOverflow, placement = 'right', triggerType = 'hover', children, onOpen, onClose, leaveDelay, leaveTouchDelay, enterTouchDelay, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => {
        setIsOpen(true);
        if (onOpen)
            onOpen();
    }, [onOpen]);
    const handleClose = useCallback(() => {
        setIsOpen(false);
        if (onClose)
            onClose();
    }, [onClose]);
    const handleClick = useCallback((e) => {
        e.preventDefault();
        isOpen ? handleClose() : handleOpen();
    }, [isOpen, handleOpen, handleClose]);
    const popperModifiers = [
        { name: 'offset', options: { offset } },
        { name: 'preventOverflow', options: { padding: preventOverflow } },
    ];
    const list = items.length ? (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.arrow }), _jsxs("ul", Object.assign({ className: styles.menu }, { children: [title && _jsx("li", Object.assign({ className: styles.title }, { children: title })), items
                        .filter((item) => item.shouldDisplay !== false)
                        .map((item) => (_jsx(AppNavDropdownItem, { item: item, onClick: handleClose }, item.id)))] }))] })) : (_jsx(_Fragment, {}));
    return (_jsx(ClickAwayListener, Object.assign({ onClickAway: handleClose }, { children: _jsx(Tooltip, Object.assign({ open: isOpen, PopperProps: {
                className: classNames(styles.popup, className),
                modifiers: popperModifiers,
                onTouchEnd: (e) => e.stopPropagation(),
            }, placement: placement, title: list, disableFocusListener: triggerType === 'click', disableHoverListener: triggerType === 'click', onOpen: handleOpen, onClose: handleClose, leaveDelay: leaveDelay, enterTouchDelay: enterTouchDelay, leaveTouchDelay: leaveTouchDelay }, { children: triggerType === 'click'
                ? cloneElement(children, { onClick: handleClick })
                : children })) })));
});
