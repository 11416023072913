export const buildFlatTableData = (node) => {
    const result = [];
    const buildForNode = (node, level) => {
        var _a;
        result.push({ node, level });
        if (node.expand)
            (_a = node.children) === null || _a === void 0 ? void 0 : _a.forEach((child) => {
                buildForNode(child, level + 1);
            });
    };
    buildForNode(node, 0);
    return result;
};
