export const APPLY_ON_SOME_UNITS = 0;
export const APPLY_ON_PURCHASE = 1;
export const APPLY_ON_SET = 2;
export const APPLY_ON_ALL_UNITS = 3;
export const APPLY_ON_CONDITION_UNITS = 4;
export const ARB_TYPE_EXCLUSIVE = 2;
export const ARB_TYPE_TAKES_OVER_OTHER = 3;
export const ARB_TYPE_GIVES_WAY_TO_OTHER = 4;
export const ARB_TYPE_WINS_WHEN_MORE_PROFITABLE = 1;
export const ARB_TYPE_APPENDS_TO_OTHER = 5;
export const LIMIT_TYPE_NO_LIMIT = 0;
export const LIMIT_TYPE_WITH_LIMIT = 1;
export const LIMIT_PERIOD_DAY = 0;
export const LIMIT_PERIOD_WEEK = 1;
export const LIMIT_PERIOD_OFFER = 2;
export const DISCOUNT_TYPE_FIXED = 0;
export const DISCOUNT_TYPE_PERCENT = 1;
