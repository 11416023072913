export function getBestAggInterval({ periodStart, periodEnd, isSparkline, }) {
    const start = new Date(periodStart);
    const end = new Date(periodEnd);
    const range = (end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24;
    if (range <= 21)
        return 'day';
    else if (range < 60)
        return isSparkline ? 'week' : 'day';
    else if (range <= 90)
        return 'week';
    else if (range < 365)
        return isSparkline ? 'month' : 'week';
    return 'month';
}
