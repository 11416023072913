import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterForm } from '@/components/FilterForm/FilterForm';
import { Popup } from '@/components/Popup/Popup';
import { Plus } from '@/icons/Plus';
import { Reset } from '@/icons/Reset';
import { convertLeafToSavedFilter } from '@/services/filters/convertLeafToSavedFilter';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import { injectSavedFilterToLeaf } from '@/services/filters/injectSavedFilterToLeaf';
import { ScheduleFilterForm } from './ScheduleFilterForm';
import styles from './styles.module.scss';
export const FilterListInputItemButton = ({ filtersTree = [], isSchedule = false, selectedCode, value, onChange, onReset, defaultOpen = false, isResetDisabled, }) => {
    const { t } = useTranslation();
    const formTrigger = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const selectedLeaf = findLeafByCode(filtersTree, selectedCode);
    useEffect(() => {
        if (defaultOpen && !popupIsOpen)
            setTimeout(() => {
                setPopupIsOpen(true);
            }, 250);
    }, [defaultOpen]);
    const leafWithValue = selectedLeaf
        ? Object.assign({}, selectedLeaf) : null;
    if (leafWithValue && value)
        injectSavedFilterToLeaf(leafWithValue, value);
    const handleTogglePopup = useCallback(() => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    }, []);
    const handleChange = useCallback((leaf) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(convertLeafToSavedFilter(leaf));
        setPopupIsOpen(false);
    }, [onChange]);
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: styles.description }, { children: t('Если значение совпадает с...') })), _jsxs("div", Object.assign({ className: styles.buttonWrapper }, { children: [_jsx("button", Object.assign({ className: classNames(styles.formButton, {
                            [styles.empty]: !value,
                            [styles.active]: popupIsOpen,
                        }), ref: formTrigger, type: 'button', onClick: handleTogglePopup, dangerouslySetInnerHTML: value
                            ? {
                                __html: isSchedule
                                    ? value.text
                                    : getConditionText(value.text),
                            }
                            : undefined }, { children: !value ? (_jsxs(_Fragment, { children: [_jsx(Plus, { size: 18 }), t('Значение')] })) : undefined })), value && (_jsx("button", Object.assign({ className: styles.resetButton, type: 'button', disabled: isResetDisabled, onClick: onReset }, { children: _jsx(Reset, {}) })))] })), _jsx(Popup, Object.assign({ className: styles.popup, isOpen: popupIsOpen, anchorEl: formTrigger === null || formTrigger === void 0 ? void 0 : formTrigger.current, onClose: handleTogglePopup, placement: 'bottom-start', offset: [-20, 6], isRounded: true }, { children: _jsx("div", Object.assign({ className: styles.form }, { children: !isSchedule ? (_jsx(FilterForm, { item: value ? leafWithValue : selectedLeaf, onApply: handleChange })) : (_jsx(ScheduleFilterForm, { value: value, onChange: (value) => {
                            onChange === null || onChange === void 0 ? void 0 : onChange(value);
                            setPopupIsOpen(false);
                        }, scheduleType: selectedCode })) })) }))] }));
};
const getConditionText = (condition) => {
    const colon = condition.indexOf(':');
    return condition.slice(colon + 1).trim();
};
