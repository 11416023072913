import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { Panel } from '@/components/Panel2/Panel';
import { FormGiftCard } from '@/forms/FormGiftCard/FormGiftCard';
import { useDeleteGiftCard } from '../GiftCards/useDeleteGiftCard';
import { ROUTES } from '../routes';
import { useInitialData } from './useInitialData';
import { useSaveGiftCard } from './useSaveGiftCard';
export const GiftCardsForm = () => {
    const { brand } = useCurrentUser();
    const params = useParams();
    const navigate = useNavigate();
    const giftCardId = params.giftCardId ? Number(params.giftCardId) : undefined;
    const onGoToList = () => navigate(ROUTES.GIFT_CARDS);
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort);
    const { defaultValues, isLoading } = useInitialData(giftCardId);
    const deleteCard = useDeleteGiftCard({
        onSuccess: onGoToList,
    });
    const { saveGiftCard, isSubmitting, error } = useSaveGiftCard({
        id: giftCardId,
        onSuccess: onGoToList,
    });
    if (!brand)
        return null;
    return isLoading ? null : (_jsx(Container, { children: _jsx(Panel, Object.assign({ size: '740', isCentered: true }, { children: _jsx(FormGiftCard, { onSubmit: saveGiftCard, error: error, isSubmitting: isSubmitting, defaultValues: defaultValues, currency: currency, isEdit: !!giftCardId, onDelete: () => {
                    if (giftCardId)
                        deleteCard(giftCardId, defaultValues.name);
                } }) })) }));
};
