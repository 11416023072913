import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/utils/dates';
import styles from './styles.module.scss';
export const UserAvailableDates = ({ user, }) => {
    const { t } = useTranslation();
    const accessExpired = moment(user.availableTo).isBefore(moment());
    return (_jsxs("div", { children: [_jsx("div", { children: formatDate(user.createdAt) }), !!user.availableTo && (_jsxs("div", Object.assign({ className: classNames(styles.description, {
                    [styles.danger]: accessExpired,
                }) }, { children: [accessExpired ? t('Истёк') : t('до'), ' ', moment(user.availableTo).format('DD.MM.YYYY')] })))] }));
};
