import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { WalletCardContainer } from '@/components/wallet-cards/WalletCardContainer/WalletCardContainer';
import { WalletCardsPanel } from '@/components/wallet-cards/WalletCardsPanel/WalletCardsPanel';
import { ActiveCard } from './ActiveCard';
import { GeoPushTable } from './GeoPushTable';
import { useLevels } from './useLevels';
import { useWalletCardList } from './useWalletCardList';
import { WalletCardPublish } from './WalletCardPublish';
import { WalletCardsTable } from './WalletCardsTable';
export const WalletCards = () => {
    var _a, _b;
    const { brand } = useCurrentUser();
    const location = useLocation();
    const { levels, isLoading: isLoadingLevels, isError: isLevelsError, } = useLevels();
    const { data, walletGeosNotDeleted, isLoading: isLoadingCards, isError: isCardsError, } = useWalletCardList();
    const isLoading = isLoadingLevels || isLoadingCards;
    const isError = isLevelsError || isCardsError;
    const [activeTabId, setActiveTabId] = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.walletCardsPageActiveTab) || 'cards-table');
    const [cardType, setCardType] = useState('apple');
    const [activeCardLevel, setActiveCardLevel] = useState(undefined);
    const [activeGeoPush, setActiveGeoPush] = useState(undefined);
    const handleChangeActiveTab = (tab) => {
        if (tab === 'cards-table' && cardType === 'notify')
            setCardType('apple');
        if (tab === 'geo-push-table')
            setCardType('notify');
        setActiveTabId(tab);
    };
    useEffect(() => {
        window.history.replaceState({}, document.title);
    }, []);
    if (!brand)
        return null;
    return (_jsx(Container, { children: _jsx(WalletCardContainer, Object.assign({ cardType: cardType, onChangeCardType: setCardType, card: _jsx(ActiveCard, { cardType: cardType, cardLevel: activeCardLevel, notifyMessageText: activeTabId === 'geo-push-table'
                    ? (activeGeoPush === null || activeGeoPush === void 0 ? void 0 : activeGeoPush.message) || ((_b = data === null || data === void 0 ? void 0 : data.walletGeos[0]) === null || _b === void 0 ? void 0 : _b.message)
                    : undefined }), cardFooter: _jsx(WalletCardPublish, {}) }, { children: _jsxs(WalletCardsPanel, Object.assign({ activeTabId: activeTabId, onChangeActiveTab: handleChangeActiveTab, geoPushCount: walletGeosNotDeleted === null || walletGeosNotDeleted === void 0 ? void 0 : walletGeosNotDeleted.length, isEmpty: activeTabId === 'geo-push-table' && !(walletGeosNotDeleted === null || walletGeosNotDeleted === void 0 ? void 0 : walletGeosNotDeleted.length), isLoading: isLoading }, { children: [!isError && activeTabId === 'cards-table' && (_jsx(WalletCardsTable, { activeCardLevel: activeCardLevel !== undefined ? activeCardLevel : levels[0].id, onChangeActiveCardLevel: setActiveCardLevel })), !isError && activeTabId === 'geo-push-table' && (_jsx(GeoPushTable, { activeGeoPush: activeGeoPush || (data === null || data === void 0 ? void 0 : data.walletGeos[0]), onChangeActiveGeoPush: setActiveGeoPush }))] })) })) }));
};
