import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import styles from './styles.module.scss';
const clone = () => {
    const card = document.getElementById('apple-card');
    const preview = document.getElementById('apple-card-preview');
    if (card && preview) {
        const clone = card.cloneNode(true);
        clone.setAttribute('id', '');
        clone.style.position = 'absolute';
        clone.style.left = '50%';
        clone.style.transform = 'scale(0.4) translate(-50%)';
        clone.style.transformOrigin = '0 0';
        clone.style.border = '1px solid rgb(0 0 0 / 13%)';
        clone.style.boxShadow = '0 0 4px 0 rgb(0 0 0 / 25%)';
        clone.style.width = '230px';
        preview.innerHTML = '';
        preview.appendChild(clone);
    }
};
const cloneTimeout = 100;
export const AppleWalletCardPreview = () => {
    useEffect(() => {
        setTimeout(() => clone(), cloneTimeout);
    }, []);
    return _jsx("div", { id: 'apple-card-preview', className: styles.previewWrapper });
};
