import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { FontSize } from '@/icons/FontSize';
export const FontSizeSelect = ({ sizes = [], value, onChange, onFocus, }) => {
    return (_jsx(Dropdown, { title: _jsx(FontSize, {}), items: sizes.map((size) => ({
            id: size.label,
            label: size.label,
            isSelected: size.label === value,
            onClick: () => onChange === null || onChange === void 0 ? void 0 : onChange(size),
        })), placement: 'bottom-start', highlightSelectedItem: true, onFocus: onFocus }));
};
