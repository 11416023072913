var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import IMask from 'imask';
import { memo, useEffect, useRef } from 'react';
import { Input } from '@/components/Input/Input';
import { Time } from '@/icons/Time';
export const TimeInput = memo((_a) => {
    var { onAccept, onComplete, onClear } = _a, props = __rest(_a, ["onAccept", "onComplete", "onClear"]);
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current)
            return;
        const mask = IMask(ref.current, {
            mask: 'HH:MM',
            blocks: {
                HH: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 23,
                    maxLength: 2,
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 0,
                    to: 59,
                    maxLength: 2,
                },
            },
            overwrite: true,
            lazy: false,
        });
        mask.on('accept', () => {
            if (onAccept)
                onAccept(mask.value);
            if (onClear && mask.value === '__:__')
                onClear();
        });
        mask.on('complete', () => {
            if (onComplete)
                onComplete(mask.value);
        });
        return () => mask.destroy();
    }, []);
    return (_jsx(Input, Object.assign({ ref: ref, leftIcon: _jsx(Time, {}), placeholder: '__:__' }, props)));
});
