import { jsx as _jsx } from "react/jsx-runtime";
import { useGetGiftCardOptionsQuery } from '@/api/query/giftCard';
import { useGetOfferOptionsQuery } from '@/api/query/offers';
import { useGetBrandPromocodesQuery } from '@/api/query/promocodes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { MailingRewardsPills } from '@/components/MailingRewardsPills/MailingRewardsPills';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
export const ChannelFormRewards = () => {
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const { currency } = useCurrentUser();
    const { data: offers = [] } = useGetOfferOptionsQuery();
    const { data: promocodes = [] } = useGetBrandPromocodesQuery();
    const { data: giftCards = [] } = useGetGiftCardOptionsQuery();
    const handleUpdateRewards = (rewards) => {
        selectedNode.update({ rewards });
    };
    return (_jsx("div", { children: _jsx(MailingRewardsPills, { value: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || [], onChange: handleUpdateRewards, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }) }));
};
