import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FileUploadInput } from '@/components/FileUploadInput/FileUploadInput';
import { TextArea } from '@/components/Input/TextArea';
import { Modal } from '@/components/Modal/Modal';
import { Arrow } from '@/icons/Arrow';
import styles from './styles.module.scss';
export const ImportModal = ({ isOpen, file, onSelect, onClose, isLoading, }) => {
    const { t } = useTranslation();
    const [isBuffer, setIsBuffer] = useState(false);
    const [text, setText] = useState('');
    const [fileError, setFileError] = useState('');
    const footer = isBuffer ? (_jsxs("div", Object.assign({ className: styles.footer }, { children: [_jsx(Button, Object.assign({ type: 'button', icon: _jsx(Arrow, {}), onClick: () => setIsBuffer(false) }, { children: t('Назад') })), _jsx(Button, Object.assign({ type: 'button', theme: 'primary', size: 'large', onClick: () => {
                    onSelect === null || onSelect === void 0 ? void 0 : onSelect(text);
                }, disabled: isLoading }, { children: t('Загрузить') }))] }))) : (_jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'button', onClick: () => setIsBuffer(true) }, { children: t('Вставить из буфера обмена') })) })));
    return (_jsx(Modal, Object.assign({ title: t('Загрузить данные'), isOpen: isOpen, onClose: () => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
            setText('');
            setFileError('');
        }, footer: footer, size: '550' }, { children: _jsx("div", { children: isBuffer ? (_jsx(TextArea, { className: styles.textArea, placeholder: t('Вставьте данные'), value: text, onChange: (e) => setText(e.target.value), disabled: isLoading, autoFocus: true })) : (_jsxs("div", { children: [_jsx(FileUploadInput, { placeholder: t('Поместите файл'), description: t('XLS, CSV, до 15 Mб'), width: 502, height: 124, filename: file === null || file === void 0 ? void 0 : file.name, constraints: {
                            mimeType: 'application/octet-stream',
                            extensions: ['.xlsx', '.csv', '.tsv'],
                            maxSize: 15000000, // 15 MB
                        }, hasError: !!fileError, hasResetButton: false, onChange: (file) => {
                            if (file)
                                onSelect === null || onSelect === void 0 ? void 0 : onSelect(file);
                        }, onReject: (reason) => {
                            switch (reason) {
                                case 'file-invalid-type': {
                                    setFileError('Необходим формат .xlsx, .csv или .tsv');
                                    break;
                                }
                                case 'file-too-large': {
                                    setFileError('Слишком большой файл');
                                    break;
                                }
                                default: {
                                    setFileError('Неверный файл');
                                }
                            }
                        }, isLoading: isLoading }), fileError && _jsx("div", Object.assign({ className: styles.error }, { children: fileError }))] })) }) })));
};
