import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailingsForm } from '@/app/pages/MailingsForm/MailingsForm';
import { ROUTES } from '@/app/pages/routes';
import { Drawer } from '@/components/Drawer/Drawer';
import { AutoMailingsFlow } from '@/components/flow/AutoMailingsFlow/AutoMailingsFlow';
import { AutoMailingsFlowHeader } from './AutoMailingsFlowHeader';
import styles from './styles.module.scss';
import { useData } from './useData';
export const AutoMailingsFlowForm = ({ selectedFlow, }) => {
    const navigate = useNavigate();
    const { triggerType, mailings = [], isLoading } = useData(selectedFlow);
    const [activeMailing, setActiveMailing] = useState(null);
    useEffect(() => {
        if (!isLoading && !mailings.length)
            navigate(ROUTES.FLOWS);
    }, [isLoading, mailings.length]);
    const handleMailingClick = useCallback((mailing) => {
        setActiveMailing(mailing);
    }, []);
    const handleCloseDrawer = useCallback(() => {
        setActiveMailing(null);
    }, []);
    if (isLoading)
        return null;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(AutoMailingsFlowHeader, { flow: selectedFlow, triggerType: triggerType, mailings: mailings }), _jsx(AutoMailingsFlow, { mailings: mailings, onMailingClick: selectedFlow ? handleMailingClick : undefined, fitView: true }), _jsx(Drawer, Object.assign({ open: !!activeMailing, onClose: handleCloseDrawer, anchor: 'left' }, { children: !!activeMailing && (_jsx(MailingsForm, { mailingId: activeMailing.id, onGoBack: handleCloseDrawer, isStandalone: true })) }))] })));
};
