import * as triggers from '@/const/mailing-triggers';
import { createNode } from '../createNode';
export const createLoopNode = (data, hideForLayout = true) => {
    return createNode({
        type: 'loop',
        data,
        position: {
            x: data.x || 0,
            y: data.y || 0,
        },
        style: {
            opacity: hideForLayout ? 0 : 1,
        },
        selectable: true,
        focusable: false,
        deletable: false,
        draggable: false,
        connectable: false,
    });
};
export const createLoopNodeForMailing = (mailing) => {
    return {
        id: `loop-${mailing.id}`,
        type: 'loop',
        data: {},
        position: { x: 0, y: 0 },
    };
};
export const createLoopNodes = (branches, triggerType) => {
    const loopNodes = [];
    const loopEdges = [];
    const loopBranches = {};
    if (Number(triggerType) === triggers.TRIGGER_TYPE_SCHEDULE)
        return {
            loopNodes,
            loopEdges,
            loopBranches: branches,
        };
    for (const fromNodeId of Object.keys(branches)) {
        const handles = branches[fromNodeId];
        for (const fromHandleId of Object.keys(handles)) {
            const mailings = handles[fromHandleId];
            const thisBranchNodes = [];
            for (let i = 0; i < mailings.length; i += 1) {
                const mailing = mailings[i];
                if (mailing.repeat) {
                    if (!loopBranches[fromNodeId])
                        loopBranches[fromNodeId] = {};
                    if (!loopBranches[fromNodeId][fromHandleId])
                        loopBranches[fromNodeId][fromHandleId] = [];
                    loopBranches[fromNodeId][fromHandleId].push(mailing);
                    continue;
                }
                let loopNode = thisBranchNodes.find((n) => n.type === 'loop');
                if (!loopNode) {
                    loopNode = createLoopNodeForMailing(mailing);
                    loopNodes.push(loopNode);
                    thisBranchNodes.push(loopNode);
                    loopEdges.push({
                        id: `from-${fromNodeId}-to-${loopNode.id}`,
                        source: fromNodeId,
                        sourceHandle: fromHandleId !== 'no-handle' ? fromHandleId : undefined,
                        target: loopNode.id,
                    });
                }
                if (!loopBranches[loopNode.id])
                    loopBranches[loopNode.id] = {};
                if (!loopBranches[loopNode.id]['no-handle'])
                    loopBranches[loopNode.id]['no-handle'] = [];
                loopBranches[loopNode.id]['no-handle'].push(mailing);
            }
        }
    }
    return { loopNodes, loopEdges, loopBranches };
};
