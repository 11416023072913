import { useInteractiveFlow } from '../../context/useInteractiveFlow';
export const useChannelFormState = () => {
    const { selectedNode } = useInteractiveFlow();
    const data = selectedNode.getData() || {};
    const { channels = [], hasCascadeEnabled = false } = data;
    const isCascade = hasCascadeEnabled;
    const isPush = !isCascade && channels.length === 1 && channels[0] === 'push';
    const isSMS = !isCascade && channels.length === 1 && channels[0] === 'sms';
    const isViber = !isCascade && channels.length === 1 && channels[0] === 'viber';
    const isEmail = !isCascade && channels.length === 1 && channels[0] === 'email';
    const isTelegram = !isCascade && channels.length === 1 && channels[0] === 'telegram';
    return {
        isCascade,
        isPush,
        isSMS,
        isViber,
        isEmail,
        isTelegram,
    };
};
