import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LinkPopup as LinkPopupComponent } from '../../LinkPopup/LinkPopup';
import { deleteLink } from './deleteLink';
import { updateLink } from './updateLink';
export const LinkPopup = ({ title, link, onChangeTitle, onChangeLink, onClose, }) => {
    const [editor] = useLexicalComposerContext();
    const handleChange = (title, url) => {
        updateLink(title, url, editor);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleDelete = () => {
        deleteLink(editor);
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    return (_jsx(LinkPopupComponent, { title: title, link: link, onChangeTitle: onChangeTitle, onChangeLink: onChangeLink, onChange: handleChange, onDelete: handleDelete, onClose: onClose }));
};
