import { createSerializedVariableNode } from '../plugins/VariablePlugin/VariableNode';
import { unescape } from './html';
import { textHandler } from './textHandler';
const createRoot = () => {
    return {
        type: 'root',
        children: [],
        direction: null,
        format: '',
        indent: 0,
        version: 1,
    };
};
const createParagraph = () => {
    return {
        type: 'paragraph',
        children: [],
        direction: null,
        format: '',
        indent: 0,
        version: 1,
    };
};
const createText = (text) => {
    return {
        detail: 0,
        format: 0,
        mode: 'normal',
        style: '',
        text,
        type: 'text',
        version: 1,
    };
};
const createSpace = () => {
    return {
        detail: 0,
        format: 0,
        mode: 'token',
        style: '',
        text: ' ',
        type: 'space',
        version: 1,
    };
};
const createLink = (text, url) => {
    return {
        detail: 0,
        format: 0,
        mode: 'normal',
        style: '',
        text,
        url,
        type: 'custom-link',
        version: 1,
    };
};
export const textToJSON = (text, variables) => {
    const json = {
        root: createRoot(),
    };
    if (!text) {
        json.root.children.push(createParagraph());
        return json;
    }
    const appendNode = (node) => {
        const last = json.root.children[json.root.children.length - 1];
        const paragraph = last;
        paragraph.children.push(node);
    };
    textHandler(text, variables, {
        onLine: () => {
            json.root.children.push(createParagraph());
        },
        onVariable: (variable) => {
            const varNode = createSerializedVariableNode(variable);
            appendNode(varNode);
        },
        onLink: (text, url) => {
            const linkNode = createLink(text, url);
            appendNode(linkNode);
        },
        onText: (text) => {
            const textNode = createText(unescape(text));
            appendNode(textNode);
        },
        onSpace: () => {
            const spaceNode = createSpace();
            appendNode(spaceNode);
        },
    });
    return json;
};
