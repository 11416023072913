import { useLevels } from './useLevels';
import { useWalletCardList } from './useWalletCardList';
export const useWalletCardPublishInfo = () => {
    var _a;
    const { levels } = useLevels();
    const { data, isLoading: isLoadingCards } = useWalletCardList();
    const isFirstPublish = !(data === null || data === void 0 ? void 0 : data.publishedAt);
    const configuredCards = ((_a = data === null || data === void 0 ? void 0 : data.walletCards) === null || _a === void 0 ? void 0 : _a.filter((card) => Boolean(card.configuration))) || [];
    const walletCardsDefined = levels.length <= configuredCards.length;
    const hasChangesWalletCards = configuredCards.some((card) => card.hasChanges);
    const walletGeosDefined = (data === null || data === void 0 ? void 0 : data.walletGeos.some((geo) => geo.isActive && !geo.isDeleted)) || false;
    const hasChangesWalletGeos = (data === null || data === void 0 ? void 0 : data.walletGeos.some((geo) => geo.hasChanges)) || false;
    return {
        isFirstPublish,
        walletCardsDefined,
        walletGeosDefined,
        hasChangesWalletCards,
        hasChangesWalletGeos,
        isLoadingCards,
    };
};
