import { useNodesInitialized, useReactFlow } from '@xyflow/react';
import { useEffect, useState } from 'react';
import { layoutELK } from './layoutELK';
export const useGraphLayout = ({ fitView, interactive, }) => {
    const flow = useReactFlow();
    const initialized = useNodesInitialized();
    const [needLayout, setNeedLayout] = useState(true);
    const [layoutIsComplete, setLayoutIsComplete] = useState(false);
    useEffect(() => {
        if (!initialized) {
            setNeedLayout(true);
            setLayoutIsComplete(false);
        }
    }, [initialized]);
    if (initialized && needLayout) {
        setNeedLayout(false);
        const nodes = flow.getNodes();
        const edges = flow.getEdges();
        layoutELK(nodes, edges, interactive).then((layout) => {
            if (layout) {
                flow.setNodes(layout.nodes);
                flow.setEdges(layout.edges);
                if (fitView)
                    window.requestAnimationFrame(() => {
                        flow.fitView({ maxZoom: 1 });
                    });
            }
            setLayoutIsComplete(true);
        });
    }
    return layoutIsComplete;
};
