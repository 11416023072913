import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { useEffect } from 'react';
export const FocusPlugin = ({ onFocus, onBlur, }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerCommand(FOCUS_COMMAND, () => {
            onFocus === null || onFocus === void 0 ? void 0 : onFocus();
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [onFocus]);
    useEffect(() => {
        return editor.registerCommand(BLUR_COMMAND, () => {
            onBlur === null || onBlur === void 0 ? void 0 : onBlur();
            return false;
        }, COMMAND_PRIORITY_LOW);
    }, [onBlur]);
    return null;
};
