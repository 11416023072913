import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useGenerateSampleText } from '@/components/rich-text/WalletCardFieldEditor/useGenerateSampleText';
import { AppleWalletCard } from '@/components/wallet-cards/AppleWalletCard/AppleWalletCard';
import { fileToDataURL } from '@/utils/fileToDataURL';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { getFontSize } from '../../helpers/FontSizeOptions';
import { focus } from './focus';
export const CardApple = () => {
    var _a, _b;
    const { levelName = '', bannerType, background, color, labelColor, appleHead, appleBanner, appleFields, backside, barcode, setFormSection, appleCardIsFlipped, setAppleCardIsFlipped, setIsElementClick, currency, counters, bannerCounter, } = useWalletCardForm();
    const [headerImageUrl, setHeaderImageUrl] = useState('');
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    useEffect(() => {
        fileToDataURL(appleHead.logo).then((dataURL) => {
            setHeaderImageUrl(dataURL);
        });
    }, [appleHead.logo]);
    useEffect(() => {
        fileToDataURL(appleBanner.image).then((dataURL) => {
            setBannerImageUrl(dataURL);
        });
    }, [appleBanner.image]);
    const onElementClick = (element) => {
        if (['logo', 'header-title', 'field-1', 'field-2'].includes(element))
            setFormSection('head');
        else if (['banner', 'banner-counter'].includes(element))
            setFormSection('banner');
        else if (element.startsWith('field-'))
            setFormSection('fields');
        else if (element.startsWith('backside-') ||
            element === 'apple-details-title')
            setFormSection('backside');
        else if (element === 'barcode')
            setFormSection('barcode');
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(true);
        setTimeout(() => {
            if (element.startsWith('field-')) {
                const node = document.getElementById(`apple-${element}`);
                focus(node);
                return;
            }
            if (element.startsWith('backside-')) {
                const node = document.getElementById(element);
                focus(node);
                return;
            }
            switch (element) {
                case 'logo': {
                    const node = document.getElementById('apple-head-logo');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
                case 'header-title': {
                    const node = document.getElementById('apple-head-title');
                    focus(node);
                    break;
                }
                case 'banner': {
                    const node = document.getElementById('apple-banner');
                    focus(node === null || node === void 0 ? void 0 : node.parentElement);
                    break;
                }
                case 'apple-details-title': {
                    const node = document.getElementById('apple-details-title');
                    focus(node);
                    break;
                }
                case 'banner-counter': {
                    const node = document.getElementById('banner-counter');
                    focus(node);
                    break;
                }
            }
        }, 0);
    };
    const generateSampleText = useGenerateSampleText(levelName, currency);
    useEffect(() => {
        var _a, _b, _c, _d;
        const walletCardActiveType = (_b = (_a = window.history.state) === null || _a === void 0 ? void 0 : _a.usr) === null || _b === void 0 ? void 0 : _b.walletCardActiveType;
        const walletCardActiveElement = (_d = (_c = window.history.state) === null || _c === void 0 ? void 0 : _c.usr) === null || _d === void 0 ? void 0 : _d.walletCardActiveElement;
        if (walletCardActiveType === 'apple' && walletCardActiveElement) {
            onElementClick(walletCardActiveElement);
            window.history.replaceState({}, document.title);
        }
    }, []);
    return (_jsx(AppleWalletCard, { background: background, color: color, labelColor: labelColor, headerImageUrl: headerImageUrl, header: appleHead.title, fields: {
            'field-1': {
                title: appleHead.field_1_Title,
                value: generateSampleText(appleHead.field_1_Value, appleHead.field_1_Vars),
            },
            'field-2': {
                title: appleHead.field_2_Title,
                value: generateSampleText(appleHead.field_2_Value, appleHead.field_2_Vars),
            },
            'field-3': {
                title: appleFields.field_3_Title,
                value: generateSampleText(appleFields.field_3_Value, appleFields.field_3_Vars),
            },
            'field-4': {
                title: appleFields.field_4_Title,
                value: generateSampleText(appleFields.field_4_Value, appleFields.field_4_Vars),
            },
            'field-5': {
                title: appleFields.field_5_Title,
                value: generateSampleText(appleFields.field_5_Value, appleFields.field_5_Vars),
            },
            'field-6': {
                title: appleFields.field_6_Title,
                value: generateSampleText(appleFields.field_6_Value, appleFields.field_6_Vars),
            },
        }, bannerImageUrl: bannerImageUrl, bannerImageTitle: bannerType === 'with-text'
            ? generateSampleText(appleBanner.title, appleBanner.titleVars)
            : undefined, bannerImageTitleColor: appleBanner.titleColor, bannerImageTitleSize: getFontSize(appleBanner.titleSize), bannerImageDescription: bannerType === 'with-text'
            ? generateSampleText(appleBanner.description, appleBanner.descriptionVars)
            : undefined, bannerImageDescriptionColor: appleBanner.descriptionColor, bannerImageDescriptionSize: getFontSize(appleBanner.descriptionSize), backsideTitle: backside.appleTitle, details: backside.details.map((item) => ({
            title: item.title,
            content: generateSampleText(item.content, item.vars),
        })), contacts: backside.contacts, barCode: barcode, onElementClick: onElementClick, flipped: appleCardIsFlipped, onChangeFlipped: setAppleCardIsFlipped, counter: bannerType === 'with-counter'
            ? counters === null || counters === void 0 ? void 0 : counters.find((counter) => counter.offerId === bannerCounter.offerId)
            : undefined, counterTickImageUrl: bannerCounter.tickImage && 'url' in bannerCounter.tickImage
            ? bannerCounter.tickImage.url
            : bannerCounter.tickImage instanceof File
                ? (_a = bannerCounter.tickImageUploadResponse) === null || _a === void 0 ? void 0 : _a.url
                : undefined, counterBlankImageUrl: bannerCounter.blankImage && 'url' in bannerCounter.blankImage
            ? bannerCounter.blankImage.url
            : bannerCounter.blankImage instanceof File
                ? (_b = bannerCounter.blankImageUploadResponse) === null || _b === void 0 ? void 0 : _b.url
                : undefined }));
};
