import moment from 'moment';
import { api } from './';
export const promocodesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPromocodeCount: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/promocode/count',
                body,
            }),
            providesTags: ['PromocodeCount'],
        }),
        getPromocodeList: build.query({
            query: (body) => ({
                method: 'POST',
                url: '/promocode/list',
                body,
            }),
            providesTags: ['PromocodeList'],
        }),
        getBrandPromocodes: build.query({
            query: () => ({
                method: 'GET',
                url: '/brand/promocodes',
            }),
            providesTags: ['BrandPromocodes'],
        }),
        getPromocode: build.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/promocode/get/${id}`,
            }),
            providesTags: ['Promocode'],
        }),
        createPromocode: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/promocode/create',
                body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                        ? moment(body.availableFrom).format('DD.MM.YYYY')
                        : undefined, availableTo: body.availableTo
                        ? moment(body.availableTo).format('DD.MM.YYYY')
                        : undefined }),
            }),
            invalidatesTags: [
                'PromocodeCount',
                'PromocodeList',
                'BrandPromocodes',
                'Promocode',
            ],
        }),
        updatePromocode: build.mutation({
            query: ({ id, payload: body }) => ({
                method: 'POST',
                url: `/promocode/update/${id}`,
                body: Object.assign(Object.assign({}, body), { availableFrom: body.availableFrom
                        ? moment(body.availableFrom).format('DD.MM.YYYY')
                        : undefined, availableTo: body.availableTo
                        ? moment(body.availableTo).format('DD.MM.YYYY')
                        : undefined }),
            }),
            invalidatesTags: ['PromocodeList', 'BrandPromocodes', 'Promocode'],
        }),
        deletePromocode: build.mutation({
            query: (id) => ({
                method: 'POST',
                url: `/promocode/delete/${id}`,
            }),
            invalidatesTags: ['PromocodeCount', 'PromocodeList', 'BrandPromocodes'],
        }),
        setPromocodeActive: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/promocode/set-active',
                body,
            }),
            invalidatesTags: ['PromocodeList', 'BrandPromocodes', 'Promocode'],
        }),
        generatePromocodes: build.mutation({
            query: (body) => ({
                method: 'POST',
                url: '/promocode/generate',
                body,
            }),
        }),
    }),
});
export const { useGetPromocodeCountQuery, useGetPromocodeListQuery, useGetBrandPromocodesQuery, useGetPromocodeQuery, useCreatePromocodeMutation, useUpdatePromocodeMutation, useDeletePromocodeMutation, useSetPromocodeActiveMutation, useGeneratePromocodesMutation, } = promocodesApi;
