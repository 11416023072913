import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import React, { memo } from 'react';
import styles from './styles.module.scss';
export const FormSteps = memo(({ steps, activeStepId, onStepClick }) => {
    const activeStepIndex = steps.findIndex((step) => step.id === activeStepId);
    return (_jsx("div", Object.assign({ className: styles.wrapper }, { children: steps.map((step, index) => (_jsxs(React.Fragment, { children: [_jsxs("button", Object.assign({ className: classNames(styles.step, {
                        [styles.active]: index <= activeStepIndex,
                        [styles.selected]: index === activeStepIndex,
                    }), onClick: () => onStepClick(step.id) }, { children: [_jsx("span", Object.assign({ className: styles.index }, { children: index + 1 })), _jsx("span", { children: step.label })] })), index !== steps.length - 1 && (_jsx("div", { className: classNames(styles.divider, {
                        [styles.active]: index < activeStepIndex,
                    }) }))] }, step.id))) })));
});
