import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { AreaChartChart } from './AreaChartChart';
import { AreaChartLegend } from './AreaChartLegend';
import styles from './styles.module.scss';
export const AreaChart = memo(({ labels, data, bucketSize, onLegendItemClick, isLocked = false, canFilterDataSets = false, }) => {
    const [selectedDataSet, setSelectedDataSet] = useState('');
    const handleItemClick = useCallback((id) => {
        setSelectedDataSet((selected) => (selected === id ? '' : id));
        onLegendItemClick(id);
    }, [onLegendItemClick]);
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
            [styles.locked]: isLocked,
        }) }, { children: [_jsx(AreaChartChart, { labels: labels, data: canFilterDataSets && selectedDataSet
                    ? data.filter((item) => item.id === selectedDataSet)
                    : data, bucketSize: bucketSize, isLocked: isLocked }), _jsx(AreaChartLegend, { items: data, onItemClick: handleItemClick, selectedItem: canFilterDataSets ? selectedDataSet : undefined })] })));
});
