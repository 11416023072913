import { maxmaApi } from '@/api';
export function getManualMailingOptionListApi() {
    return maxmaApi.get('/mailing/options-list-manual');
}
export function getAutomaticMailingOptionListApi() {
    return maxmaApi.get('/mailing/options-list-automatic');
}
export function getEmailTemplatesList(payload) {
    return maxmaApi.post('/mailing/get-email-design-list', payload);
}
export function getTemplateData(payload) {
    return maxmaApi.post('/mailing/get-email-design', payload);
}
