import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { useOptions } from './useOptions';
export const MailingTriggerTypeInput = ({ id, value, onChange, isBeforeEvents = false, hasRecommendationsFeature = false, hasLevelsFeature = false, isDisabled = false, }) => {
    const options = useOptions(isBeforeEvents, hasRecommendationsFeature, hasLevelsFeature);
    const key = `${isBeforeEvents} ${hasRecommendationsFeature}`;
    return (_jsx(Autocomplete, { id: id, value: options.find((item) => item.id === value) || null, onChange: (option) => {
            if (option === null || option === void 0 ? void 0 : option.isGroupItem)
                onChange(option.id);
        }, options: options, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, isOptionGroup: (option) => option.isGroup, isOptionGroupItem: (option) => option.isGroupItem, isOptionDisabled: (option) => !!option.isDisabled, isOptionLocked: (option) => !!option.isLocked, hasResetButton: false, asSelect: true, shouldHideOverflow: true, disabled: isDisabled }, key));
};
