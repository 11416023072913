import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FilterConditionsAlt } from '@/icons/flow/FilterConditionsAlt';
import { Start } from '@/icons/flow/Start';
import { Plus } from '@/icons/Plus';
import { NodeBody } from '../../helpers/NodeBody/NodeBody';
import { NodeDeleteButton } from '../../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodeRow } from '../../helpers/NodeRow/NodeRow';
import { NodeTitle } from '../../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../../helpers/NodeWrapper/NodeWrapper';
import styles from './styles.module.scss';
export const ConditionsNode = ({ id, data, }) => {
    const { t } = useTranslation();
    const { type, header: providedHeader, conditions, conditionsData, onConditionClick, onAddCondition, plusButton, onDelete, canBeDeleted, } = data;
    const icon = ['trigger', 'schedule'].includes(type) ? (_jsx(Start, {})) : (_jsx(FilterConditionsAlt, {}));
    const content = type === 'schedule' ? '' : getNodeHeader(conditions);
    const header = type === 'trigger' ? 'Условие старта' : 'Фильтр';
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, Object.assign({ className: styles.wrapper, gap: '12' }, { children: [_jsx(NodeTitle, Object.assign({ icon: icon, hasSmallPadding: true }, { children: t(providedHeader || header) })), !!content && (_jsx(NodeBody, Object.assign({ hasSmallPadding: true, movedDown: true }, { children: t(content) }))), _jsxs("div", { children: [conditions.map((condition, i) => {
                                const savedFilter = conditionsData === null || conditionsData === void 0 ? void 0 : conditionsData[i];
                                const handleId = savedFilter
                                    ? `${savedFilter.code}-${i}`
                                    : condition;
                                const handleClick = () => onConditionClick === null || onConditionClick === void 0 ? void 0 : onConditionClick(id, handleId, savedFilter, i);
                                return (_jsx(NodeRow, Object.assign({ nodeId: id, handleId: handleId, onClick: onConditionClick ? handleClick : undefined, plusButton: plusButton }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                            __html: type === 'schedule'
                                                ? condition
                                                : getConditionText(condition),
                                        } }) }), handleId));
                            }), onAddCondition && (_jsx(NodeRow, Object.assign({ onClick: () => onAddCondition === null || onAddCondition === void 0 ? void 0 : onAddCondition(id), asIcon: true }, { children: _jsx(Plus, {}) }), 'add'))] })] })), onDelete && (canBeDeleted === null || canBeDeleted === void 0 ? void 0 : canBeDeleted(id)) && (_jsx(NodeDeleteButton, { onClick: () => onDelete(id) })), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
const getNodeHeader = (conditions) => {
    const first = conditions[0];
    const colon = first.indexOf(':');
    return first.slice(0, colon);
};
const getConditionText = (condition) => {
    const colon = condition.indexOf(':');
    return condition.slice(colon + 1).trim();
};
