import { useGetGiftCardCountQuery, useGetGiftCardListQuery, } from '@/api/query/giftCard';
export const useData = ({ searchText, periodStart, periodEnd, limit, offset, }) => {
    const { data: count, isLoading: countIsLoading, isFetching: countIsFetching, } = useGetGiftCardCountQuery({ searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    const { data, isLoading: dataIsLoading, isFetching: dataIsFetching, } = useGetGiftCardListQuery({ searchText, periodStart, periodEnd, limit, offset }, { refetchOnMountOrArgChange: true });
    const isLoading = countIsLoading || dataIsLoading;
    const isFetching = countIsFetching || dataIsFetching;
    return {
        count,
        data,
        isLoading,
        isFetching,
    };
};
