import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ChannelTelegram } from '@/icons/ChannelTelegram';
import { FormMailingTelegramButton } from './controls/FormMailingTelegramButton';
import { FormMailingTelegramImage } from './controls/FormMailingTelegramImage';
import { FormMailingTelegramTest } from './controls/FormMailingTelegramTest';
import { FormMailingTelegramText } from './controls/FormMailingTelegramText';
import { FormMailingTelegramURL } from './controls/FormMailingTelegramURL';
import styles from './styles.module.scss';
export const FormMailingStepTelegram = ({ textLength, onSendTestTelegram, uploadImage, hasWalletFeature, currency, labelWidth, }) => {
    return (_jsxs(FormFieldset, Object.assign({ title: _jsxs("div", Object.assign({ className: styles.channelTitle }, { children: [_jsx("div", Object.assign({ className: classNames(styles.channel, styles.telegram) }, { children: _jsx(ChannelTelegram, { size: 12 }) })), "Telegram"] })), titleOffset: labelWidth }, { children: [_jsx(FormMailingTelegramImage, { uploadImage: uploadImage, labelWidth: labelWidth }), _jsx(FormMailingTelegramText, { length: textLength, hasWalletFeature: hasWalletFeature, currency: currency, labelWidth: labelWidth }), _jsx(FormMailingTelegramButton, { labelWidth: labelWidth }), _jsx(FormMailingTelegramURL, { labelWidth: labelWidth }), _jsx(FormMailingTelegramTest, { onSendTestTelegram: onSendTestTelegram, labelWidth: labelWidth })] })));
};
