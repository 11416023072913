import { t } from 'i18next';
import moment from 'moment';
const MIN_PERIOD_DATE = moment('1900-01-01T00:00:00+00:00');
const MAX_PERIOD_DATE = moment('2100-01-01T00:00:00+00:00');
export const dateValidator = (date) => {
    if (date) {
        const formatDate = moment(date);
        if (formatDate.isBefore(MIN_PERIOD_DATE))
            return t('От 02.01.1900');
        if (formatDate.endOf('day').isAfter(MAX_PERIOD_DATE))
            return t('До 31.12.2099');
    }
};
