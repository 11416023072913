export const inverse = (matrix) => {
    var _a;
    const columnCount = ((_a = matrix[0]) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const result = [];
    let currentColumn = 0;
    while (currentColumn < columnCount) {
        result.push([]);
        for (let i = 0; i < matrix.length; i += 1) {
            const row = matrix[i];
            result[currentColumn].push(row[currentColumn]);
        }
        currentColumn += 1;
    }
    return result;
};
export const reorder = (list, start, end) => {
    const result = Array.from(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);
    return result;
};
export const remove = (list, index) => {
    const result = Array.from(list);
    result.splice(index, 1);
    return result;
};
export const isEqual = (list1 = [], list2 = []) => {
    const a = [...list1].sort().join(',');
    const b = [...list2].sort().join(',');
    return a === b;
};
