import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import styles from './styles.module.scss';
export const StatsPanel = memo(({ className, titleClassName, title, bodyId, bodyClassName, tooltip, hasBorders = true, isLoading = false, children, }) => {
    return (_jsxs("div", Object.assign({ className: classNames(styles.panel, {
            [styles.bordered]: hasBorders,
            [styles.loading]: isLoading,
        }, className) }, { children: [!!title && (_jsx("h4", Object.assign({ className: classNames(styles.title, titleClassName) }, { children: tooltip ? (_jsx(HelpTooltip, Object.assign({ title: tooltip, hasLightIcon: true, placement: 'top' }, { children: title }))) : (title) }))), _jsx("div", Object.assign({ id: bodyId, className: classNames(styles.body, bodyClassName) }, { children: children }))] })));
});
