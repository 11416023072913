var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { money } from '@/utils/numbers';
import { FormGiftCardGenerate, } from './FormGiftCardGenerate';
import styles from './styles.module.scss';
export const FormGiftCardGenerateModal = memo((_a) => {
    var { id = 'generate-gift-card-form', isOpen, onClose, card, currency, isSubmitting, error } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "card", "currency", "isSubmitting", "error"]);
    const { t } = useTranslation();
    const [generateType, setGenerateType] = useState(card.generatedCount > 0 ? '0' : '1');
    const footer = (_jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, Object.assign({ className: classNames(styles.message, styles.error), controlClassName: styles.errorText, asErrorMessage: true, asModalFooter: true, hasLabelHidden: true }, { children: error }))), _jsx("div", Object.assign({ className: styles.footer }, { children: _jsx(Button, Object.assign({ type: 'submit', form: id, theme: 'primary', size: 'large', disabled: isSubmitting }, { children: generateType === '0'
                        ? t('Выгрузить')
                        : t('Сгенерировать и выгрузить') })) }))] }));
    return (_jsx(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, title: t('GIFT_CARD_GENERATE_MODAL_TITLE', {
            giftCard: {
                name: card.name,
                amount: money({
                    input: card.amount,
                    currency,
                    withFraction: true,
                }),
            },
        }), footerClassName: styles.modalFooter, footer: footer, size: '550', hasNoPadding: true, isForm: true }, { children: _jsx(FormGiftCardGenerate, Object.assign({ id: id, generatedCount: card.generatedCount, onChangeGenerateType: setGenerateType }, props)) })));
});
