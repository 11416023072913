import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { RepeatInfinity } from '@/icons/flow/RepeatInfinity';
import { RepeatOneTime } from '@/icons/flow/RepeatOneTime';
import styles from './styles.module.scss';
export const LoopNode = ({ id, data, }) => {
    const { t } = useTranslation();
    const { repeat = 'one-time', onClick, plusButton: PlusButton } = data;
    const handleClick = useCallback(() => {
        onClick === null || onClick === void 0 ? void 0 : onClick(id);
    }, [onClick, id]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ className: styles.tooltip, title: repeat === 'one-time'
                    ? t('Ветка сработает только один раз на клиента')
                    : t('Ветка сработает при каждой активации триггера'), PopperProps: { disablePortal: true } }, { children: _jsxs("div", Object.assign({ className: classNames(styles.wrapper, {
                        [styles.interactive]: !!onClick,
                        nodrag: !!onClick,
                        nopan: !!onClick,
                    }), onClick: handleClick }, { children: [repeat === 'one-time' ? _jsx(RepeatOneTime, {}) : _jsx(RepeatInfinity, {}), _jsx("span", Object.assign({ className: styles.content }, { children: repeat === 'one-time' ? t('1 раз') : t('каждый раз') }))] })) })), PlusButton && _jsx(PlusButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target', 'flow-handle-target-delay'), type: 'target', position: Position.Left }), _jsx(Handle, { className: 'flow-handle', type: 'source', position: Position.Right })] }));
};
