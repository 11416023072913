import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useDashboardMetrics } from '@/hooks/useDashboardMetrics';
import { AnalyticsChart } from './AnalyticsChart';
import { useChartData } from './useChartData';
export const AnalyticsChartContainer = memo(({ interval }) => {
    const metrics = useDashboardMetrics();
    const { data = [], visibleMetrics = [], isLoading } = useChartData(interval);
    const labels = data.map((item) => item.date || '');
    const datasets = visibleMetrics.map((key) => {
        if (!metrics[key])
            return null;
        return {
            label: metrics[key].label,
            color: metrics[key].color,
            scale: metrics[key].scale,
            data: data.map((item) => item[key] !== null ? Number(item[key]) : null),
        };
    });
    const datasetsOnChart = isLoading
        ? []
        : datasets.filter(Boolean);
    return (_jsx(AnalyticsChart, { labels: labels, datasets: datasetsOnChart, interval: interval }));
});
