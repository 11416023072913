import { useGetPurchaseCountQuery } from '@/api/query/purchases';
import { useAppSelector } from '@/hooks/store';
import { formattedEndSelector, formattedStartSelector, } from '@/store/selectors/appPeriod';
export const useCount = ({ filters, searchText, }) => {
    const periodStart = useAppSelector(formattedStartSelector);
    const periodEnd = useAppSelector(formattedEndSelector);
    const { data, isLoading, isFetching, refetch } = useGetPurchaseCountQuery({ filters, searchText, periodStart, periodEnd }, { refetchOnMountOrArgChange: true });
    return {
        purchase: (data === null || data === void 0 ? void 0 : data.purchase) || 0,
        order: (data === null || data === void 0 ? void 0 : data.order) || 0,
        orderCancelled: (data === null || data === void 0 ? void 0 : data.orderCancelled) || 0,
        isLoading,
        isFetching,
        refetch,
    };
};
