import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { Info } from '@/icons/Info';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormPromocodeGenerate = memo(({ id, onSubmit }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            num: 100,
        },
    });
    return (_jsxs("form", Object.assign({ className: styles.wrapper, id: id, onSubmit: handleSubmit(onSubmit) }, { children: [_jsx(FormFieldRow, Object.assign({ className: classNames(styles.numRow, styles.transparent), label: t('Количество'), labelFor: 'num', labelClassName: styles.numRowLabel, controlSize: '200', controlClassName: styles.numRowControl, help: ((_a = errors.num) === null || _a === void 0 ? void 0 : _a.message) ||
                    t('PROMOCODE_GENERATE_HELP', {
                        from: 1,
                        to: numberRound(1000000),
                    }), helpClassName: !errors.num ? styles.numRowHelp : undefined, hasError: !!errors.num, isRequired: true }, { children: _jsx(Controller, { name: 'num', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 1,
                            message: t('MIN_ERROR_MESSAGE', { amount: 1 }),
                        },
                        max: {
                            value: 1000000,
                            message: t('MAX_ERROR_MESSAGE', {
                                amount: numberRound(1000000),
                            }),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'num', type: 'number', inputMode: 'numeric', placeholder: t('введите количество кодов'), preventChars: [',', '.'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.num, autoFocus: true }))) }) })), _jsxs("div", Object.assign({ className: classNames(styles.alert) }, { children: [_jsx("div", Object.assign({ className: styles.alertIcon }, { children: _jsx(Info, {}) })), t('PROMOCODE_GENERATE_REPORT_NOTICE')] }))] })));
});
