import { useTranslation } from 'react-i18next';
import * as triggers from '@/const/mailing-triggers';
import { useTriggerFilters } from '@/services/filters/data/useTriggerFilters';
import { findLeaves } from '@/services/filters/findLeaves';
const FILTER_LABEL_MAP = {
    [triggers.TRIGGER_TYPE_SEGMENT_JOIN]: {
        clientSegments: 'Попал в сегмент',
        clientRfmSegments: 'Попал в RFM-сегмент',
    },
    [triggers.TRIGGER_TYPE_SEGMENT_LEAVE]: {
        clientSegments: 'Покинул сегмент',
        clientRfmSegments: 'Покинул RFM-сегмент',
    },
};
export const useTriggers = (triggerType) => {
    const { t } = useTranslation();
    const filtersTree = useTriggerFilters(triggerType);
    const triggerFilters = findLeaves(filtersTree, () => true);
    return triggerFilters.map((filter) => {
        var _a;
        const label = ((_a = FILTER_LABEL_MAP[triggerType]) === null || _a === void 0 ? void 0 : _a[filter.id])
            ? t(FILTER_LABEL_MAP[triggerType][filter.id])
            : filter.label;
        return Object.assign(Object.assign({}, filter), { label });
    });
};
