import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Check } from '@/icons/Check';
import { Close } from '@/icons/Close';
import { fgColor } from '@/utils/colors';
import styles from './styles.module.scss';
export const ReportMetricModalItem = memo(({ metric, isSelected, onClick, tooltipDelay = 1500 }) => {
    const style = isSelected
        ? {
            '--selected-item-bg': metric.color,
            '--selected-item-color': fgColor(metric.color),
        }
        : undefined;
    return (_jsx("div", Object.assign({ className: styles.item }, { children: _jsx(Tooltip, Object.assign({ title: metric.tooltip ? (_jsxs("div", Object.assign({ className: styles.tooltip }, { children: [_jsx("div", Object.assign({ className: styles.tooltipTitle }, { children: metric.label })), _jsx("div", { dangerouslySetInnerHTML: { __html: metric.tooltip } })] }))) : undefined, theme: 'light', size: 'large', placement: 'left', enterDelay: tooltipDelay, enterNextDelay: 0 }, { children: _jsxs("div", Object.assign({ className: classNames(styles.itemTitle, {
                    [styles.selected]: isSelected,
                }), style: style, onClick: onClick }, { children: [metric.label, _jsx("div", Object.assign({ className: classNames(styles.itemIcon, styles.check) }, { children: _jsx(Check, {}) })), _jsx("div", Object.assign({ className: classNames(styles.itemIcon, styles.close) }, { children: _jsx(Close, {}) }))] })) })) })));
});
