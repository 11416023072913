import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { availableRoutes } from '@/app/availableRoutes';
import { createHref } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { IS_SNOWY_DATES } from '@/const/general';
import { Logo as LogoBase } from '@/icons/logo/Logo';
import { LogoSmall } from '@/icons/logo/LogoSmall';
import { LogoSnowy } from '@/icons/logo/LogoSnowy';
import { AppNav } from '../AppNav/AppNav';
import { AppSidebarUser } from '../AppSidebarUser/AppSidebarUser';
import styles from './styles.module.scss';
const Logo = IS_SNOWY_DATES ? (_jsx(LogoSnowy, { className: styles.normal })) : (_jsx(LogoBase, { className: styles.normal }));
export const AppSidebar = memo(({ currentRoute, user, brand, isCollapsed, isHidden, onChangeRoute, onLogout, onSwitchLanguage, onSwitchBrand, onToggle, onToggleHidden, }) => {
    const userHasAccessTo = useUserPermissions();
    const { firstAvailableRoute } = availableRoutes(brand, userHasAccessTo);
    return (_jsxs("div", Object.assign({ className: classNames(styles.sidebar, {
            [styles.collapsed]: isCollapsed,
            [styles.hidden]: isHidden,
        }) }, { children: [_jsxs("a", Object.assign({ className: styles.logo, href: createHref(firstAvailableRoute) }, { children: [Logo, _jsx(LogoSmall, { className: styles.small })] })), _jsx(AppNav, { isCollapsed: isCollapsed, currentRoute: currentRoute, brand: brand, onChangeRoute: onChangeRoute, onToggle: onToggle, onToggleHidden: onToggleHidden }), _jsx(AppSidebarUser, { user: user, isCollapsed: isCollapsed, isHidden: isHidden, onLogout: onLogout, onSwitchLanguage: onSwitchLanguage, onSwitchBrand: onSwitchBrand }), _jsx("div", { className: styles.shadow })] })));
});
