import { useNodesInitialized, useReactFlow } from '@xyflow/react';
import { useEffect, useRef, useState } from 'react';
import { useInteractiveFlow } from '../context/useInteractiveFlow';
import { layoutFlow } from './elkLayout';
export const useLayoutFlow = () => {
    const flow = useReactFlow();
    const initialized = useNodesInitialized();
    const { layout } = useInteractiveFlow();
    const isFirstLayout = useRef(true);
    const [needLayout, setNeedLayout] = useState(true);
    const [layoutIsComplete, setLayoutIsComplete] = useState(false);
    useEffect(() => {
        if (!initialized) {
            setNeedLayout(true);
            setLayoutIsComplete(false);
        }
    }, [initialized]);
    useEffect(() => {
        if (layout.isLayoutUpdateRequested && initialized) {
            setNeedLayout(true);
            setLayoutIsComplete(false);
            layout.resetLayoutRequest();
        }
    }, [layout.isLayoutUpdateRequested]);
    if (initialized && needLayout) {
        setNeedLayout(false);
        const nodes = flow.getNodes();
        const edges = flow.getEdges();
        layoutFlow(nodes, edges).then((layout) => {
            if (layout) {
                flow.setNodes(layout.nodes);
                flow.setEdges(layout.edges);
                const fitView = layout.nodes.length <= 3;
                if (fitView || isFirstLayout.current)
                    window.requestAnimationFrame(() => {
                        flow.fitView({ maxZoom: 1 });
                    });
                isFirstLayout.current = false;
            }
            setLayoutIsComplete(true);
        });
    }
    return layoutIsComplete;
};
