import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TRIGGER_TYPE_EMAIL_DELIVERED } from '@/const/mailing-triggers';
import styles from '../styles.module.scss';
import { OFFSET_SIGN_SCHEDULE } from './FormMailingOffset';
export const FormMailingTriggerEmailReceived = ({ emailMailings = [], isEdit, labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, formState, setValue, watch } = useFormContext();
    const { errors, isSubmitted } = formState;
    const offsetSign = watch('offsetSign');
    const triggerType = watch('triggerType');
    const triggerEmailReceivedMailingId = watch('triggerEmailReceivedMailingId');
    const triggerEmailReceivedMailing = emailMailings.find((m) => m.id === triggerEmailReceivedMailingId);
    const triggerEmailReceivedTypeOptions = [
        { value: 'not_opened', label: t('не открыли письмо') },
        { value: 'not_clicked', label: t('не перешли по ссылке в письме') },
        { value: 'not_purchased', label: t('не купили после перехода по ссылке') },
    ];
    if (offsetSign === OFFSET_SIGN_SCHEDULE ||
        triggerType !== TRIGGER_TYPE_EMAIL_DELIVERED)
        return null;
    return (_jsx(FormFieldRow, Object.assign({ label: t('если'), labelFor: 'triggerEmailReceivedType', labelWidth: labelWidth, help: (_a = errors.triggerEmailReceivedMailingId) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.triggerEmailReceivedMailingId, isRequired: true }, { children: _jsxs("div", Object.assign({ className: styles.triggerEmailReceivedRow }, { children: [_jsx(Controller, { name: 'triggerEmailReceivedType', control: control, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerEmailReceivedTypeInput }, { children: _jsx(Autocomplete, Object.assign({}, field, { id: 'triggerEmailReceivedType', value: triggerEmailReceivedTypeOptions.find((item) => item.value === field.value) || null, onChange: (value) => {
                                setValue('triggerEmailReceivedType', value === null || value === void 0 ? void 0 : value.value);
                            }, options: triggerEmailReceivedTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, minListWidth: 310, asSelect: true })) }))) }), _jsx(Controller, { name: 'triggerEmailReceivedMailingId', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx("div", Object.assign({ className: styles.triggerEmailReceivedMailingIdInput }, { children: _jsx(Autocomplete, Object.assign({}, field, { id: 'triggerEmailReceivedMailingId', placeholder: t('выберите рассылку'), value: emailMailings.find((item) => item.id === field.value) || null, onChange: (value) => {
                                setValue('triggerEmailReceivedMailingId', value === null || value === void 0 ? void 0 : value.id, {
                                    shouldValidate: isSubmitted,
                                });
                            }, options: emailMailings, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasError: !!errors.triggerEmailReceivedMailingId, hasResetButton: false, disabled: isEdit &&
                                typeof (triggerEmailReceivedMailing === null || triggerEmailReceivedMailing === void 0 ? void 0 : triggerEmailReceivedMailing.flowId) === 'number' })) }))) })] })) })));
};
