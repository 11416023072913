import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styles from './styles.module.scss';
import { TreeNode } from './TreeNode';
export const Tree = memo(({ items, selectedItemIds, disabledItemIds, hiddenItemIds, onToggleItems, hasAutoExpand, levelIndent, scrollToSelectedNode = false, }) => {
    const customs = items.filter((i) => i.type === 'custom');
    const otherItems = items.filter((i) => i.type !== 'custom');
    const renderTreeNode = (item) => (_jsx(TreeNode, { item: item, level: 0, selectedItemIds: selectedItemIds, disabledItemIds: disabledItemIds, hiddenItemIds: hiddenItemIds, onToggleItems: onToggleItems, hasAutoExpand: hasAutoExpand, levelIndent: levelIndent, scrollToSelectedNode: scrollToSelectedNode }, item.id));
    return (_jsxs("ul", Object.assign({ className: styles.tree }, { children: [customs.map((item) => renderTreeNode(item)), customs.length > 0 && _jsx("div", { className: styles.divider }), otherItems.map((item) => renderTreeNode(item))] })));
});
