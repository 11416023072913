import { jsx as _jsx } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { TRIGGER_TYPE_SCHEDULE } from '@/const/mailing-triggers';
import { validateFlow } from '../nodes/validation';
import { useForceLayout } from './hooks/useForceLayout';
import { useNewStepPopup } from './hooks/useNewStepPopup';
import { useSelectedNode } from './hooks/useSelectedNode';
import { useTriggers } from './hooks/useTriggers';
import { useTriggersChannelReceived } from './hooks/useTriggersChannelReceived';
import { useTriggersEmailReceived } from './hooks/useTriggersEmailReceived';
import { useTriggersSchedule } from './hooks/useTriggersSchedule';
import { useValidationErrorMap } from './hooks/useValidationErrorMap';
import { InteractiveFlowContext } from './InteractiveFlowContext';
export const InteractiveFlowProvider = ({ triggerType, triggerLabel, onChange, children }) => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const triggerFilters = useTriggers(triggerType);
    const emailReceivedTriggers = useTriggersEmailReceived();
    const channelReceivedTriggers = useTriggersChannelReceived();
    const scheduleTriggers = useTriggersSchedule();
    const { validationErrorMap, setValidationErrorMap } = useValidationErrorMap();
    const handleChange = (reset) => {
        if (reset)
            onChange === null || onChange === void 0 ? void 0 : onChange([], []);
        else
            setTimeout(() => {
                onChange === null || onChange === void 0 ? void 0 : onChange(flow.getNodes(), flow.getEdges());
                if (validationErrorMap) {
                    const newErrorMap = validateFlow(flow, t, Object.keys(validationErrorMap));
                    setValidationErrorMap(newErrorMap || undefined);
                }
            }, 10);
    };
    const newStepPopup = useNewStepPopup();
    const selectedNode = useSelectedNode(handleChange);
    const layout = useForceLayout();
    return (_jsx(InteractiveFlowContext.Provider, Object.assign({ value: {
            triggerType,
            triggerLabel,
            triggerFilters: triggerType === TRIGGER_TYPE_SCHEDULE
                ? scheduleTriggers
                : triggerFilters,
            scheduleTriggers,
            emailReceivedTriggers,
            channelReceivedTriggers,
            newStepPopup,
            selectedNode,
            layout,
            onChange: handleChange,
            validationErrorMap,
            setValidationErrorMap,
        } }, { children: children })));
};
