import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MailingChannelsArcs } from './MailingChannelsArcs';
import { MailingChannelsTab } from './MailingChannelsTab';
import styles from './styles.module.scss';
const channelLabels = {
    push: 'Push',
    viber: 'Viber',
    sms: 'SMS',
    email: 'Email',
    telegram: 'Telegram',
};
const channelOrder = [
    'push',
    'viber',
    'sms',
    'email',
    'telegram',
];
export const MailingChannelsTabs = memo(({ activeChannels, enabledChannels, soonChannels = [], onChangeActiveChannels, activeTab = 'main', onTabClick, error, }) => {
    const { t } = useTranslation();
    const toggleChannel = (channel) => (isActive) => {
        if (isActive)
            onChangeActiveChannels([...activeChannels, channel]);
        else
            onChangeActiveChannels(activeChannels.filter((c) => c !== channel));
    };
    const renderChannels = (channels) => channels.map((channel) => (_jsx("li", { children: _jsx(MailingChannelsTab, { className: styles[channel], buttonClassName: classNames({
                [styles.active]: activeTab === channel,
            }), label: channelLabels[channel], onChange: toggleChannel(channel), onClick: () => onTabClick(channel), isActive: activeChannels.includes(channel), isLocked: !enabledChannels.includes(channel), isSoon: soonChannels.includes(channel) }) }, channel)));
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(MailingChannelsArcs, { activeChannels: activeChannels, enabledChannels: enabledChannels, soonChannels: soonChannels }), _jsxs("ul", Object.assign({ className: styles.list }, { children: [_jsx("li", { children: _jsx("div", Object.assign({ className: styles.item }, { children: _jsx("button", Object.assign({ className: classNames({
                                    [styles.active]: activeTab === 'main',
                                }), onClick: () => onTabClick('main') }, { children: t('Настройки') })) })) }), renderChannels(channelOrder.filter((channel) => enabledChannels.includes(channel))), renderChannels(channelOrder.filter((channel) => !enabledChannels.includes(channel)))] })), error && _jsx("div", Object.assign({ className: styles.error }, { children: error }))] })));
});
