import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { TableSkeleton } from '@/components/TableSkeleton/TableSkeleton';
import { Tabs } from '@/components/Tabs2/Tabs';
import styles from './styles.module.scss';
export const TabsPanel = memo((_a) => {
    var _b;
    var { tabs = [], activeTabId = (_b = tabs[0]) === null || _b === void 0 ? void 0 : _b.id, onChangeActiveTab, className, headerClassName, bodyClassName, rightContent, rightToolbar, footer, children, isSkeleton = false, isContentSkeleton = false, } = _a;
    if (isSkeleton)
        return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, styles.skeleton) }, { children: [_jsx("div", Object.assign({ className: styles.header }, { children: _jsx(Skeleton, { size: '180' }) })), _jsx("div", Object.assign({ className: styles.body }, { children: _jsx(TableSkeleton, {}) }))] })));
    return (_jsxs("div", Object.assign({ className: classNames(styles.wrapper, className) }, { children: [_jsxs("div", Object.assign({ className: classNames(styles.header, headerClassName) }, { children: [_jsx(Tabs, { tabs: tabs, activeTabId: activeTabId, onChangeActiveTab: onChangeActiveTab }), rightContent, rightToolbar && _jsx("div", Object.assign({ className: styles.toolbar }, { children: rightToolbar }))] })), _jsx("div", Object.assign({ className: classNames(styles.body, bodyClassName) }, { children: isContentSkeleton ? _jsx(TableSkeleton, {}) : children })), _jsx("div", Object.assign({ className: styles.footer }, { children: footer }))] })));
});
